import { Button, Stack, TextField } from "@mui/material";
import React, { useEffect } from "react";
import Popover from "@mui/material/Popover";
import { SketchPicker } from "react-color";

export default function ColorPallete(props) {
  const {
    children,
    currentPallete,
    isNewPallette = false,
    addBrandPallete,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [colorPaletteName, setColorPaletteName] = React.useState(
    currentPallete?.name ?? ""
  );
  const [color, setColor] = React.useState("#000000");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSave = () => {
    let finalPallete;
    if (!isNewPallette) {
      finalPallete = {
        _id: currentPallete?._id,
        colorPaletteName: currentPallete?.colorPaletteName,
        color,
      };
    } else {
      finalPallete = {
        colorPaletteName,
        color,
      };
    }

    addBrandPallete(finalPallete);
    setColorPaletteName("");
    setColor("#000000");
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setColorPaletteName(currentPallete?.colorPaletteName);
  }, [currentPallete]);

  return (
    <>
      <Stack onClick={handleClick}>{children}</Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Stack
          sx={{
            padding: "40px",
            gap: "20px",
          }}
        >
          <TextField
            label="Pallete Name"
            variant="outlined"
            placeholder="Enter Pallete Name"
            onChange={(e) => {
              setColorPaletteName(e.target.value);
            }}
            value={colorPaletteName}
            disabled={!isNewPallette}
          />

          <SketchPicker
            color={color}
            onChange={(e) => {
              setColor(e.hex);
            }}
            width="250px"
          />
          <Button
            variant="contained"
            disabled={!colorPaletteName}
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </Popover>
    </>
  );
}
