const UpgradePlan = ({ isActive }) => {
  // const color = isActive ? "#7167FB" : "#858383";
  const color = "#CC3300";
  return (
    <svg width={17} height={13} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.46 0c3.177 0 5.787 2.349 6.228 5.4h1.872L13.41 9l-3.15-3.6h2.088A3.995 3.995 0 0 0 8.46 2.295a3.997 3.997 0 0 0-3.186 1.602L3.735 2.142A6.268 6.268 0 0 1 8.46 0ZM8.1 12.096c-3.168 0-5.787-2.349-6.228-5.4H0l3.15-3.6c1.053 1.197 2.097 2.403 3.15 3.6H4.212A3.995 3.995 0 0 0 8.1 9.801a3.997 3.997 0 0 0 3.186-1.602l1.539 1.755A6.255 6.255 0 0 1 8.1 12.096Z"
        fill={color}
      />
    </svg>
  );
};

export default UpgradePlan;
