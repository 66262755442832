import CreativeGroupsScreen from "./CreativeGroupsScreen";
import GroupContentScreen from "./GroupsContentScreen";

import CongratulationScreen from "./StatusesScreens/CongratulationScreen";
import LoadingScreen from "./StatusesScreens/LoadingScreen";
import ProcessingScreen from "./StatusesScreens/ProcessingScreen";
import VerifiedScreen from "./StatusesScreens/VerifiedScreen";

const OnboardingScreens = {
  CreativeGroupsScreen,
  GroupContentScreen,
};

const OnboardingStatusesScreens = {
  CongratulationScreen,
  LoadingScreen,
  ProcessingScreen,
  VerifiedScreen,
};

export { OnboardingScreens, OnboardingStatusesScreens };
