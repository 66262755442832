const EmptyData = (props) => {
  const { customClass = "", imageUrl = "/empty.png" } = props;
  const { title = "No data found", subTitle = "" } = props;
  return (
    <div
      className={`flex flex-col items-center justify-center ${customClass}`}
      {...props}
    >
      <div className="mb-4 flex items-center justify-center">
        <img width="100px" src={imageUrl} alt="" loading="lazy" />
      </div>
      <div className="mb-4 flex items-center text-2xl justify-center text-[#233A5C] opacity-100 sm:mb-0">
        <h6 className="text-sm">{title}</h6>
      </div>
      {subTitle && (
        <div className="mb-2 flex items-center justify-center opacity-100 sm:mb-0">
          <h3 className="text-gray-500">{subTitle}</h3>
        </div>
      )}
    </div>
  );
};

export default EmptyData;
