import React from "react";
import {
  Button,
  Dialog,
  Typography,
  useMediaQuery,
  DialogActions,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "../../../redux/slices/modal.slices";
import { AutoCompleteWithTags } from "../../common/molecules";
import useManualTextTagging from "./useManualTextTagging";
import { Stack } from "@mui/system";
import { Close } from "@mui/icons-material";

export default function ManualTextTaggingModal(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { open, type, payload } = useSelector((state) => state?.modal);
  const { primaryBtnText = "Save", dangerMode = false } = payload || {};

  const { state, handleTagsChange, handlePrimaryAction, handleClose } =
    useManualTextTagging(payload, props?.initGroup, props?.postOnboarding);

  function handleSave() {
    handleClose();
    handlePrimaryAction && handlePrimaryAction(payload?.group);
  }
  return (
    <Dialog
      open={open && type === MODALTYPES.MANUAL_TEXT_TAGGING_MODAL}
      fullScreen={fullScreen}
      onClose={handleClose}
      maxWidth
      key={payload?.group?._id}
    >
      <div
        style={{
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
          width: "100%",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h2">Text Tagging</Typography>
          <Close onClick={handleClose} />
        </Stack>

        <div style={{ display: "flex", gap: "5%" }}>
          <div style={{ maxWidth: "400px" }}>
            <Typography variant="body1">{payload?.group?.groupName}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "488px",
              gap: "16px",
              justifyContent: "center",
            }}
          >
            <AutoCompleteWithTags
              label="Labels"
              values={state?.labels}
              handleTagsChange={handleTagsChange}
              onTagUpdate={(tags) =>
                handleTagsChange({ name: "labels", value: tags })
              }
            />
            <AutoCompleteWithTags
              label="Colors"
              values={state?.colors}
              handleTagsChange={handleTagsChange}
              onTagUpdate={(tags) =>
                handleTagsChange({ name: "colors", value: tags })
              }
            />
            <AutoCompleteWithTags
              label="Content Themes"
              values={state?.contentThemes}
              handleTagsChange={handleTagsChange}
              onTagUpdate={(tags) =>
                handleTagsChange({ name: "contentThemes", value: tags })
              }
            />
            <AutoCompleteWithTags
              label="Sentiments"
              values={state?.sentiments}
              handleTagsChange={handleTagsChange}
              onTagUpdate={(tags) =>
                handleTagsChange({ name: "sentiments", value: tags })
              }
            />
            <AutoCompleteWithTags
              label="Entities"
              values={state?.entities}
              handleTagsChange={handleTagsChange}
              onTagUpdate={(tags) =>
                handleTagsChange({ name: "entities", value: tags })
              }
            />
            <AutoCompleteWithTags
              label="Brands"
              values={state?.brands}
              handleTagsChange={handleTagsChange}
              onTagUpdate={(tags) =>
                handleTagsChange({ name: "brands", value: tags })
              }
            />
            <AutoCompleteWithTags
              label="Persons"
              values={state?.persons}
              handleTagsChange={handleTagsChange}
              onTagUpdate={(tags) =>
                handleTagsChange({ name: "persons", value: tags })
              }
            />
            <AutoCompleteWithTags
              label="Discounts"
              values={state?.discounts}
              handleTagsChange={handleTagsChange}
              onTagUpdate={(tags) =>
                handleTagsChange({ name: "discounts", value: tags })
              }
            />
            <AutoCompleteWithTags
              label="Keywords"
              values={state?.keywords}
              handleTagsChange={handleTagsChange}
              onTagUpdate={(tags) =>
                handleTagsChange({ name: "keywords", value: tags })
              }
            />
            <DialogActions
              style={{
                display: "flex",
                padding: "16px 24px 0 16px",
                gap: "40px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  textTransform: "capitalize",
                  fontWeight: "400",
                }}
                color={dangerMode ? "error" : "primary"}
                onClick={handleSave}
              >
                {primaryBtnText}
              </Button>
            </DialogActions>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
