import * as React from "react";

const TagAnalytics = ({ isActive }) => {
  const color = isActive ? "#7167FB" : "#858383";

  return (
    <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.75 13.668c-.413 0-.456-.457-.75-.75-.293-.294-.75-.337-.75-.75v-10.5c0-.413.457-.456.75-.75.294-.294.337-.75.75-.75h10.5c.412 0 .456.456.75.75.293.294.75.337.75.75v10.5c0 .413-.457.456-.75.75-.294.293-.338.75-.75.75H1.75Zm0-1.5h10.5v-10.5H1.75v10.5Zm1.5-1.5h1.5v-3.75h-1.5v3.75Zm6 0h1.5v-7.5h-1.5v7.5Zm-3 0h1.5v-2.25h-1.5v2.25Zm0-3.75h1.5v-1.5h-1.5v1.5Zm-4.5 5.25v-10.5 10.5Z"
        fill={color}
      />
    </svg>
  );
};

export default TagAnalytics;
