import { Grid, Tooltip, Typography } from "@mui/material";
import { Trash2 } from "lucide-react";
import {
  Select as ShadSelect,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";
import React from "react";
import AutoComplete from "../AutoCompleteWithTags/AutoComplete";

const ExtraFilters = (props) => {
  const {
    state,
    handleExtraFilterSelectUpdate,
    handleSearchMotionFilters,
    handleUpdateSearchExtraFilter,
    smartSuggestions,
    isSmartSuggestionLoading,
    handleAggregateLogicChangeExtraFilters,
    handleAddNewFilter,
    handleRemoveNewFilter,
    fields = [
      "Ad-Name",
      "Ad-set-Name",
      "Campaign Name",
      "Group Name",
      "Audience Segment",
    ],
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
      },
    },
  };

  return (
    <>
      {state?.extraFilters?.map((row, index) => {
        return (
          <>
            <div className="flex items-center gap-x-2 mb-5">
              {index === 0 ? (
                <Typography>Where,</Typography>
              ) : (
                <Typography>
                  {
                    <Tooltip
                      arrow
                      title="Changing aggreagtion logic, you have to start from start again as this is common logic between all the fields selected"
                    >
                      <ShadSelect
                        className=""
                        value={state?.extraFiltersAggregator}
                        onValueChange={(val) =>
                          handleAggregateLogicChangeExtraFilters(val)
                        }
                      >
                        <SelectTrigger className="w-[80px]">
                          <span className="text-gray-500">
                            {state?.extraFiltersAggregator
                              ? state?.extraFiltersAggregator
                              : "Aggregator"}
                          </span>
                        </SelectTrigger>
                        <SelectContent className="w-8">
                          {["AND", "OR"].map((logic) => (
                            <SelectItem
                              className="cursor-pointer"
                              value={logic}
                            >
                              {logic}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </ShadSelect>
                    </Tooltip>
                  }
                </Typography>
              )}
              <div>
                <ShadSelect
                  value={row?.type}
                  onValueChange={(val) =>
                    handleExtraFilterSelectUpdate(index, val, "type")
                  }
                >
                  <SelectTrigger className="">
                    <span className="text-gray-500">
                      {row?.type ? fields[row?.type - 1] : "Metric"}
                    </span>
                  </SelectTrigger>
                  <SelectContent>
                    {fields?.map((curr, index) => {
                      return (
                        <SelectItem
                          className="cursor-pointer"
                          value={index + 1}
                        >
                          {curr}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </ShadSelect>
              </div>
              <div>
                <ShadSelect
                  className="w-[60px]"
                  value={row?.condition}
                  onValueChange={(val) =>
                    handleExtraFilterSelectUpdate(index, val, "condition")
                  }
                >
                  <SelectTrigger>
                    <span className="text-gray-500">
                      {row?.condition === 1 ? "contains" : "not-contains"}
                    </span>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem className="cursor-pointer" value={1}>
                      Contains
                    </SelectItem>
                    <SelectItem className="cursor-pointer" value={0}>
                      Not-Contains
                    </SelectItem>
                  </SelectContent>
                </ShadSelect>
              </div>
              <Grid item xs={6}>
                <div
                  style={{
                    display: "flex",
                    width: "full",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <AutoComplete
                      label="Tags(type something and wait for realtime suggestions)"
                      size="small"
                      values={row?.tags}
                      handleSearchTags={handleSearchMotionFilters}
                      insightFilterType={row?.type}
                      isInsights={true}
                      onTagUpdate={(tags) => {
                        handleUpdateSearchExtraFilter(index, tags, "tags");
                      }}
                      extraFiltersSuggestions={
                        isSmartSuggestionLoading
                          ? ["Loading..."]
                          : smartSuggestions
                      }
                      isSmartSuggestionLoading={isSmartSuggestionLoading}
                      fullWidth
                    />
                  </div>
                  <div>
                    {index !== 0 && (
                      <div className="flex items-center mt-2">
                        <Trash2
                          onClick={() => handleRemoveNewFilter(index)}
                          className={`p-1 text-red-500 cursor-pointer ${
                            index === 0 ? "opacity-30 cursor-not-allowed" : ""
                          }`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </div>
          </>
        );
      })}
    </>
  );
};

export default ExtraFilters;
