import { Typography } from "@mui/material";

const { useNavigate } = require("react-router-dom");
const { BackIcon } = require("../../../../assets/icons/react");

const BackButton = (props) => {
  const { to, from, btnText = "Back" } = props;
  const navigate = useNavigate();

  function handleBack() {
    if (to) {
      if (from) navigate(to, { state: { from } });
      else {
        navigate(to);
      }
    } else {
      navigate(-1);
    }
  }
  return (
    <div
      //navigate back to previous page

      style={{
        cursor: "pointer",
        display: "flex",
        gap: "16px",
        alignItems: "center",
      }}
    >
      <BackIcon onClick={handleBack} />
      <Typography
        sx={{ cursor: "default", fontSize: "18px", fontWeight: "600" }}
      >
        {btnText}
      </Typography>
    </div>
  );
};

export default BackButton;
