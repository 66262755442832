import { useState, useEffect } from "react";

export default function useReactPlayer(modalPlayer, timeslot) {
  const [playing, setPlaying] = useState(false);

  const [stopAt, setStopAt] = useState({ type: "second", value: 3 });
  const handleProgress = (state) => {
    if (playing) {
      if (stopAt.type === "second" && state.playedSeconds >= stopAt.value) {
        setPlaying(false);
      }

      if (stopAt.type === "%" && state.played >= stopAt.value) {
        setPlaying(false);
      }

      switch (timeslot?.toString()) {
        case "0": //0-3 seconds
          if (state.playedSeconds >= 3) {
            setPlaying(false);
          }
          break;

        case "1": //first 25%
          if (state.played >= 0.25) {
            setPlaying(false);
          }
          break;

        case "2": //only second 25%
          if (state.played >= 0.5) {
            setPlaying(false);
          }
          break;

        case "3": //only third 25%
          if (state.played >= 0.75) {
            setPlaying(false);
          }
          break;

        case "4": //last 25%
          if (state.played >= 1) {
            setPlaying(false);
          }
          break;

        default:
          break;
      }
    }
  };

  function handleSeekMouseDown(e) {
    //reset to current slot time
    // handleTimeSlotChange();
  }

  const onChangePlayerState = () => {
    setPlaying(true);
  };

  const handleTimeSlotChange = () => {
    modalPlayer?.current?.seekTo(0);
    setPlaying(false);
    let timeSlotStr = timeslot.toString();
    switch (timeSlotStr) {
      case "0":
        setStopAt({ type: "second", value: 3 });
        break;

      case "1":
        setStopAt({ type: "%", value: 0.25 });
        break;

      case "2":
        modalPlayer?.current?.seekTo(0.25);
        setStopAt({ type: "%", value: 0.5 });
        break;

      case "3":
        modalPlayer?.current?.seekTo(0.5);
        setStopAt({ type: "%", value: 0.75 });
        break;

      case "4":
        modalPlayer?.current?.seekTo(0.75);
        setStopAt({ type: "", value: "" });
        break;

      case "5":
        modalPlayer?.current?.seekTo(0);
        setStopAt({ type: "", value: "" });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    handleTimeSlotChange();
  }, [timeslot]);

  return {
    playing,
    handleProgress,
    handleTimeSlotChange,
    onChangePlayerState,
    handleSeekMouseDown,
  };
}
