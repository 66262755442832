import TWTooltip from "components/common/tooltip/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { twMerge } from "tailwind-merge";
import { toBlob } from "html-to-image";
import { GROUP_BY_COLUMNS, REPORTABLE_TYPE } from "utils/constants";
import { getColorForProgress, storageUrlToCdn } from "utils/helper";
import { useCallback, useRef } from "react";
import { Tooltip, linearProgressClasses } from "@mui/material";
import { toast } from "react-toastify";
import { Gauge } from "@/components/ui/gauge";
import { BorderLinearProgress, DarkTooltip } from "../ColorTable/ColorTable";
import { AD_BREAKDOWN_FIELDS } from "config/statics";

const { default: EmptyData } = require("components/common/empty/EmptyData");
const { Image, Video, Copy } = require("lucide-react");

const NewInsightsCardView = (props) => {
  const {
    rowSelection,
    sortedData,
    selectedKPIs,
    handleGroupIdCHange,
    isCompareView,
    filterAndOptions,
    doesShowColor,
    setIsCopying,
    reportableType,
    handleCreativeInsight,
  } = props;
  const { groupIds } = useSelector((state) => state.compare);
  const insightRefs = useRef(new Map());
  const dispatch = useDispatch();

  const kpiObjArr = selectedKPIs.map((metric) => {
    return filterAndOptions?.kpi?.find((kpi) => kpi?.kpi === metric);
  });

  const handleEditTags = (asset, type) => {
    const payload = {
      groupName: asset.groupName,
      groupId: asset._id,
    };
    if (type === "VIDEO") {
      payload.videoUrl = storageUrlToCdn(asset.thumbnailUrl);
    } else {
      payload.imageUrl = storageUrlToCdn(asset.thumbnailUrl);
    }
    dispatch(
      setModal({
        type:
          type === "IMAGE"
            ? MODALTYPES.IMAGE_TAGGING_MODAL
            : MODALTYPES.VIDEO_TAGGING_MODAL,
        open: true,
        payload,
      })
    );
  };

  const handleCopyToClipboard = useCallback(async (index) => {
    setIsCopying(true);
    const ref = insightRefs.current.get(index);
    if (ref) {
      const elementsToHide = ref.querySelectorAll(".rm-for-screenshot");
      const elementsToShow = ref.querySelectorAll(".show-for-screenshot");
      elementsToHide.forEach((element) => {
        element.style.display = "none";
      });
      elementsToShow.forEach((element) => {
        element.style.display = "flex";
      });
      try {
        const blob = await toBlob(ref, { cacheBust: true });
        if (blob) {
          const item = { [blob.type]: blob };
          const clipboardItem = new ClipboardItem(item);
          await navigator.clipboard.write([clipboardItem]);
          toast("Data Copied To Clipboard.", {
            type: "success",
            toastId: "kasmdkamdkmaskdmaks13",
          });
        } else {
          throw new Error("Blob is undefined or null");
        }
      } catch (error) {
        toast("Failed to copy data to clipboard.", { type: "error" });
      } finally {
        elementsToHide.forEach((element) => {
          element.style.display = "block";
        });
        elementsToShow.forEach((element) => {
          element.style.display = "none";
        });
        setIsCopying(false);
      }
    }
  }, []);

  return (
    <>
      {Object.keys(rowSelection)?.length ? (
        sortedData?.length ? (
          <div className="grid sm:grid-cols-4 gap-2 grid-cols-2 text-xs">
            {sortedData?.map((data, index) => {
              const { index: tableIndex, original: insight = {} } = data;
              return (
                rowSelection[tableIndex] && (
                  <div
                    key={tableIndex}
                    className={twMerge(
                      "bg-white w-full dark:bg-gray-800 rounded-lg border border-[#7167FB] shadow-lg",
                      groupIds.includes(insight._id) && "border-4 rounded-xl"
                    )}
                    ref={(el) => insightRefs.current.set(index, el)}
                  >
                    <div className="relative">
                      {insight["objectType"] === "url" &&
                      reportableType !== REPORTABLE_TYPE.AD_BREAKDOWN ? (
                        <img
                          src={insight?.thumbnailUrl}
                          alt=""
                          onClick={() => {
                            dispatch(
                              setModal({
                                type: MODALTYPES.MEDIA_ASSET_PREVIEW,
                                open: true,
                                payload: {
                                  groupId: insight?._id,
                                  handleCreativeInsight: handleCreativeInsight,
                                },
                              })
                            );
                          }}
                          className="object-fit w-full h-68 rounded-t-[7.12px] aspect-square cursor-pointer"
                        />
                      ) : (
                        <div className="flex items-center justify-center w-full sm:h-72 h-64 p-4 bg-[#F3F3F3] rounded-t-lg">
                          {GROUP_BY_COLUMNS.filter((key) =>
                            insight.hasOwnProperty(key)
                          ).map((key) => (
                            <TWTooltip
                              message={insight[key]}
                              tooltipCondition={insight[key].length > 120}
                            >
                              <p className="text-center line-clamp-6">
                                {reportableType === REPORTABLE_TYPE.AD_BREAKDOWN
                                  ? insight[AD_BREAKDOWN_FIELDS[1]]
                                  : insight[key]}
                              </p>
                            </TWTooltip>
                          ))}
                        </div>
                      )}
                      {isCompareView && insight["objectType"] === "url" ? (
                        <div class="flex items-center">
                          <input
                            type="radio"
                            multiple={true}
                            checked={groupIds.includes(insight._id)}
                            onClick={() => handleGroupIdCHange(insight._id)}
                            class="absolute right-1 outline-none top-1 w-6 h-6 cursor-pointer"
                          />
                        </div>
                      ) : (
                        <>
                          {insight["objectType"] === "url" && (
                            <button
                              disabled={isCompareView}
                              onClick={() =>
                                handleEditTags(insight, insight["type"])
                              }
                              class="absolute rm-for-screenshot font-medium top-1 right-1 flex items-center justify-center px-2 py-1 bg-white bg-opacity-90 border border-gray-300 rounded-md cursor-pointer group shadow-sm text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-700 transition duration-300 ease-in-out"
                            >
                              Edit Tags
                            </button>
                          )}
                        </>
                      )}
                      {insight["objectType"] === "url" && (
                        <button class="absolute rm-for-screenshot top-1 left-1 flex items-center justify-center px-2 py-1 bg-white bg-opacity-90 border border-gray-300 rounded-md cursor-pointer group shadow-sm text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-700 transition duration-300 ease-in-out">
                          {insight["type"] === "IMAGE" ? <Image /> : <Video />}
                        </button>
                      )}

                      <div
                        className="absolute rm-for-screenshot right-1 bottom-1 flex items-center justify-center px-2 py-1 bg-white bg-opacity-90 border border-gray-300 rounded-md cursor-pointer group shadow-sm text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-700 transition duration-300 ease-in-out"
                        onClick={() => {
                          handleCopyToClipboard(index);
                        }}
                      >
                        <button>
                          <Copy />
                        </button>
                      </div>
                    </div>
                    <div className="p-2 text-xs font-medium">
                      <div className="space-y-3">
                        <div
                          key={"ADS_INFO"}
                          className="flex justify-between items-center"
                        >
                          <p className="text-[#6B7280] capitalize">No of Ads</p>
                          <span className="inline-block bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-400 py-1 px-3 rounded-full text-xs">
                            {insight?.totalNoOfAds}
                          </span>
                        </div>
                        <div className="flex flex-col gap-y-3">
                          {kpiObjArr.map((obj, index) => {
                            const resolvedColor =
                              insight[obj?.kpi]?.display?.color &&
                              insight[obj?.kpi]?.display?.color !== "#ffffff"
                                ? insight[obj?.kpi]?.display?.color
                                : "rgb(229 231 235)";

                            return (
                              <Tooltip
                                placement="left-end"
                                title={
                                  obj?.label?.length > 12 ? obj?.label : null
                                }
                                arrow
                              >
                                <div
                                  key={index}
                                  className="flex justify-between items-center"
                                >
                                  <p
                                    className="text-[#6B7280]
                               w-[50%] capitalize text-ellipsis"
                                  >
                                    {obj?.label?.length > 12
                                      ? obj?.label?.slice(0, 12) + "..."
                                      : obj?.label}
                                  </p>
                                  {insight[obj?.kpi]?.display?.type ===
                                  "progressBar" ? (
                                    <DarkTooltip
                                      arrow
                                      placement="top"
                                      title={
                                        <div className="w-full h-full flex flex-col gap-y-2 justify-center">
                                          <Gauge
                                            value={
                                              insight[obj?.kpi]?.display?.value
                                            }
                                            size="medium"
                                            showValue={true}
                                            color={getColorForProgress(
                                              insight[obj?.kpi]?.display?.value
                                            )}
                                            label={obj?.kpi?.toUpperCase()}
                                          />
                                          <div className="bg-[#232323] p-2 mt-1 text-sm font-medium">
                                            <span>
                                              {
                                                insight[obj?.kpi]?.display
                                                  ?.tooltip?.[0]?.text
                                              }
                                            </span>{" "}
                                            {insight[obj?.kpi]?.display
                                              ?.tooltip?.[1]?.text && (
                                              <span
                                                style={{
                                                  color:
                                                    insight[obj?.kpi]?.display
                                                      ?.tooltip?.[1]?.color,
                                                }}
                                              >
                                                {
                                                  insight[obj?.kpi]?.display
                                                    ?.tooltip?.[1]?.text
                                                }
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      }
                                    >
                                      <div className="flex gap-x-2 w-full justify-end items-center">
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={
                                            insight[obj?.kpi]?.display?.value
                                          }
                                          sx={{
                                            [`& .${linearProgressClasses.bar}`]:
                                              {
                                                borderRadius: 5,
                                                zIndex: -1000,
                                                backgroundColor:
                                                  getColorForProgress(
                                                    insight[obj?.kpi]?.display
                                                      ?.value
                                                  ),
                                              },
                                            width: "37%",
                                            height: "6px",
                                          }}
                                        />
                                        <span>
                                          {insight[obj?.kpi]?.display?.value}
                                          {insight[obj?.kpi]?.display?.unit}
                                        </span>
                                      </div>
                                    </DarkTooltip>
                                  ) : (
                                    <span
                                      className="inline-block text-xs  dark:bg-gray-700 text-gray-600 dark:text-gray-400 py-1 px-3 rounded-full font-medium"
                                      style={{
                                        backgroundColor: doesShowColor(
                                          insight[obj?.kpi]?.display?.colorType
                                        )
                                          ? resolvedColor
                                          : "rgb(229 231 235)",
                                      }}
                                    >
                                      {insight[obj?.kpi]?.unit
                                        ? insight[obj?.kpi]?.display?.value +
                                          " " +
                                          insight[obj?.kpi]?.display?.unit
                                        : insight[obj?.kpi]?.display?.value +
                                            " " +
                                            insight[obj?.kpi]?.display?.unit ||
                                          "NA"}
                                    </span>
                                  )}
                                </div>
                              </Tooltip>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="hidden show-for-screenshot mt-2 ml-2 h-12 items-center gap-2">
                      <img src="/logo192.png" alt="" className="h-4" />
                      <p className="text-[7px]">
                        Created using www.thestoryteller.tech
                      </p>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        ) : (
          "No Data"
        )
      ) : (
        <EmptyData
          customClass="mt-10"
          imageUrl="/choice.png"
          title="Select Ad Groups"
        />
      )}
    </>
  );
};

export default NewInsightsCardView;
