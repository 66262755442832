import React from "react";

const Cancel = ({
  height = "14",
  width = "14",
  style = {},
  onClickHandler = () => {},
  color = "black",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClickHandler}
    >
      <path d="M18 6L6 18" stroke={color} strokeWidth="2" />
      <path d="M6 6L18 18" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default Cancel;
