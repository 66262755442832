import React, { useMemo, useState } from "react";
import { Tooltip as MTooltip } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { GROUP_BY_COLUMNS, REPORTABLE_TYPE } from "utils/constants";
import { storageUrlToCdn } from "utils/helper";
import {
  AD_BREAKDOWN_FIELDS,
  AD_BREAKDOWN_FIELDS_REVERSE,
} from "config/statics";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { useDispatch } from "react-redux";
import _ from "lodash";

const CustomTooltip = ({ active, payload, originalData }) => {
  if (active && payload && payload.length) {
    const data = originalData[payload[0].payload.index];
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "white",
          padding: "5px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      >
        {Object.entries(data).map(([key, value]) => {
          if (key === "thumbnailUrl" || key === "index" || key === "groupId")
            return null;
          if (key === AD_BREAKDOWN_FIELDS[1])
            return (
              <p key={key}>{`${
                AD_BREAKDOWN_FIELDS_REVERSE[AD_BREAKDOWN_FIELDS[1]]
              }: ${value}`}</p>
            );
          return <p key={key}>{`${key}: ${value}`}</p>;
        })}
      </div>
    );
  }

  return null;
};

export default function BarChartMultiYAxis({
  insightsData,
  selectedMetrics,
  rowSelection,
  filterAndOptions,
  reportableType,
  handleCreativeInsight,
}) {
  const [legendClicks, setLegendClicks] = useState([]);
  const keysObj = {};
  const dispatch = useDispatch();

  const columnKey = useMemo(() => {
    const objectType = insightsData[0]?.original?.objectType;
    if (
      reportableType !== REPORTABLE_TYPE.AD_BREAKDOWN &&
      objectType === "url"
    ) {
      return "thumbnailUrl";
    }
    return reportableType === REPORTABLE_TYPE.AD_BREAKDOWN
      ? AD_BREAKDOWN_FIELDS[1]
      : GROUP_BY_COLUMNS.find((key) =>
          insightsData[0]?.original?.hasOwnProperty(key)
        ) || "thumbnailUrl";
  }, [insightsData]);

  const kpiObjArr = selectedMetrics.map((metric) => {
    return filterAndOptions?.kpi?.find((kpi) => kpi?.kpi === metric);
  });

  const originalChartData = insightsData
    .filter((item) => rowSelection[item.index])
    .map((item, index) => {
      const { original: insight = {} } = item;
      kpiObjArr.forEach((metric) => {
        keysObj[metric.label] = insight[metric.kpi]?.display?.value ?? 0;
      });
      return {
        ...keysObj,
        index,
        groupId: item?.original?._id,
        [columnKey]: insight[columnKey],
        thumbnailUrl:
          columnKey === "thumbnailUrl"
            ? storageUrlToCdn(insight.thumbnailUrl)
            : insight[columnKey],
      };
    });

  // calculating the max value for each key to normalize
  const maxValues = {};
  Object.keys(keysObj).forEach((key) => {
    maxValues[key] = Math.max(...originalChartData.map((data) => data[key]));
  });

  const normalizedData = originalChartData.map((data) => {
    const normalizedData = { ...data };
    Object.keys(keysObj).forEach((key) => {
      normalizedData[key] = (data[key] / maxValues[key]) * 100;
    });
    return normalizedData;
  });

  const CustomXAxisLabel = ({ x, y, payload }) => {
    const imageSrc = payload.value;
    const yOffset = 30;
    const clipPathId = `avatar-clip-${Math.random().toString(36).slice(2, 9)}`;
    const charLimit = 20;

    return (
      <g transform={`translate(0, ${yOffset})`}>
        <defs>
          <clipPath id={clipPathId}>
            <circle cx={x} cy={y} r={25} />
          </clipPath>
        </defs>
        {columnKey !== "thumbnailUrl" ? (
          <MTooltip arrow title={payload?.value}>
            <text x={x - 25} y={y} className="flex text-wrap">
              {payload?.value?.length < charLimit
                ? payload.value
                : payload?.value?.substring(0, charLimit) + "..."}
            </text>
          </MTooltip>
        ) : (
          <image
            style={{
              cursor: "pointer",
              clipPath: `url(#${clipPathId})`,
            }}
            onClick={() => {
              if (
                originalChartData?.[payload?.index]?.groupId &&
                handleCreativeInsight
              ) {
                dispatch(
                  setModal({
                    type: MODALTYPES.MEDIA_ASSET_PREVIEW,
                    open: true,
                    payload: {
                      groupId: originalChartData?.[payload?.index]?.groupId,
                      handleCreativeInsight: handleCreativeInsight,
                    },
                  })
                );
              }
            }}
            x={x - 25}
            y={y - 25}
            width={50}
            height={50}
            href={imageSrc}
          />
        )}
      </g>
    );
  };

  const SELECTED_METRIC_COLOR = {
    1: "#EF4444",
    2: "#F59E0B",
    3: "#7F9CF5",
    4: "#F472B6",
    5: "#34D399",
    6: "#8B5CF6",
    7: "#60A5FA",
  };

  const renderCustomizedLabel = ({ x, y, width, height, index, dataKey }) => {
    const originalValue = originalChartData[index][dataKey];
    let adjustedY = y - 10;
    const keysArray = _.keys(originalChartData[index]);
    let index2 = _.indexOf(keysArray, dataKey);
    if (index2 % 2 === 0) {
      index2 = 0;
    } else {
      index2 = 1;
    }
    return (
      <text
        style={{
          marginBottom: "20px",
        }}
        x={x + width / 2}
        y={adjustedY - index2 * 11}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {originalValue.toFixed(0)}
      </text>
    );
  };

  const handleLegendClick = (name) => {
    let _new = null;
    if (legendClicks?.includes(name)) {
      _new = legendClicks?.filter((item) => item !== name);
    } else {
      _new = [...legendClicks, name];
    }

    setLegendClicks(_new);
  };

  return (
    <div className="h-[27rem]">
      <ResponsiveContainer
        width={
          originalChartData?.length > 10
            ? originalChartData?.length * 10 + "%"
            : "100%"
        }
      >
        <BarChart width={700} data={normalizedData}>
          <CartesianGrid strokeDasharray="3 3" />

          <Tooltip
            content={<CustomTooltip originalData={originalChartData} />}
          />
          <Legend
            verticalAlign="top"
            wrapperStyle={{
              paddingBottom: "36px",
            }}
            formatter={(value, entry) => {
              return (
                <span
                  style={{
                    color: entry.color,
                    cursor: "pointer",
                    marginBottom: "20px",
                  }}
                  onClick={() => handleLegendClick(value)}
                >
                  {value}
                </span>
              );
            }}
          />

          <XAxis
            dataKey="thumbnailUrl"
            interval={0}
            tick={<CustomXAxisLabel />}
            height={80}
          />
          {Object.entries(keysObj).map(([key], index) => {
            let hide = null;
            if (legendClicks?.includes(key)) hide = true;
            return (
              <Bar
                dataKey={key}
                key={key}
                fill={SELECTED_METRIC_COLOR[index + 1]}
                yAxisId="right"
                barSize={15}
                name={key}
                hide={hide}
              >
                <LabelList
                  dataKey={key}
                  position="insideRight"
                  className="z-[9999]"
                  content={(props) =>
                    renderCustomizedLabel({ ...props, dataKey: key })
                  }
                />
              </Bar>
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
