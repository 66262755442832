import { SELECTION_TYPE_MAPPING_REVERSE } from "config/statics";
import { CalendarDays } from "lucide-react";
import moment from "moment";
import React from "react";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import "./style.scss";
import { DarkTooltip } from "screens/Insights/Components/ColorTable/ColorTable";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";

const NewDateRangePicker = (props) => {
  const {
    date,
    onDateDaysChange,
    handleUpdateSelectionType,
    groupIndex,
    fIndex,
  } = props;

  const shortcutsItems = [
    {
      label: "Last 24 hours",
      getValue: () => {
        const start = moment().subtract(1, "days");
        const end = moment();
        return [start, end];
      },
    },
    {
      label: "Last 7 days",
      getValue: () => {
        const start = moment().subtract(7, "days");
        const end = moment();
        return [start, end];
      },
      onClick: (text, e) => {},
    },
    {
      label: "Last 14 days",
      getValue: () => {
        const start = moment().subtract(14, "days");
        const end = moment();
        return [start, end];
      },
    },
    {
      label: "Last 30 days",
      getValue: () => {
        const start = moment().subtract(30, "days");
        const end = moment();
        return [start, end];
      },
    },
    {
      label: "Last 90 days",
      getValue: () => {
        const start = moment().subtract(60, "days");
        const end = moment();
        return [start, end];
      },
    },
    {
      label: "Last 365 days",
      getValue: () => {
        const start = moment().subtract(365, "days");
        const end = moment();
        return [start, end];
      },
    },
  ];
  return (
    <div className="h-10">
      <DarkTooltip
        title={"Select the date range that you would like to compare"}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateRangePicker
            size="small"
            className="cursor-pointer transition-all duration-300 py-2 pl-3 w-full border-gray-300 dark:bg-slate-800 dark:text-white/80 dark:border-slate-600 rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white disabled:opacity-40 disabled:cursor-not-allowed outline-none"
            label={
              date?.selectionType && date?.selectionType !== 4
                ? SELECTION_TYPE_MAPPING_REVERSE[date?.selectionType]
                : null
            }
            value={[moment(date.startDate), moment(date.endDate)]}
            slots={{ field: SingleInputDateRangeField, fieldSeparator: "to" }}
            onChange={(value, ctx) => {
              onDateDaysChange(
                {
                  startDate: value[0].format("YYYY-MM-DD"),
                  endDate: value[1].format("YYYY-MM-DD"),
                },
                groupIndex,
                fIndex
              );
              if (ctx?.shortcut?.label) {
                handleUpdateSelectionType(
                  ctx?.shortcut?.label,
                  groupIndex,
                  fIndex
                );
              }
            }}
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
              actionBar: { actions: [] },
              textField: {
                size: "small",
                variant: "outlined",
                InputProps: {
                  placeholder: "Select date range",
                  sx: {
                    fontSize: "0.75rem",
                    height: "2.5rem",
                    width: "100%",
                    marginRight: "10px",
                  },
                  endAdornment: (
                    <CalendarDays className="text-slate-400 ml-2" />
                  ),
                },
              },
            }}
            calendars={2}
          />
        </LocalizationProvider>
      </DarkTooltip>
    </div>
  );
};

export default NewDateRangePicker;
