import React from "react";

export default function OpenEye() {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.28126 8C8.28126 8.69619 8.55783 9.36387 9.05011 9.85616C9.54239 10.3484 10.2101 10.625 10.9063 10.625C11.6025 10.625 12.2701 10.3484 12.7624 9.85616C13.2547 9.36387 13.5313 8.69619 13.5313 8C13.5313 7.30381 13.2547 6.63613 12.7624 6.14384C12.2701 5.65156 11.6025 5.375 10.9063 5.375C10.2101 5.375 9.54239 5.65156 9.05011 6.14384C8.55783 6.63613 8.28126 7.30381 8.28126 8ZM21.0828 7.39531C18.861 2.71484 15.5024 0.359375 11 0.359375C6.49533 0.359375 3.13908 2.71484 0.917201 7.39766C0.828081 7.58637 0.78186 7.79247 0.78186 8.00117C0.78186 8.20987 0.828081 8.41598 0.917201 8.60469C3.13908 13.2852 6.49767 15.6406 11 15.6406C15.5047 15.6406 18.861 13.2852 21.0828 8.60234C21.2633 8.22266 21.2633 7.78203 21.0828 7.39531ZM10.9063 12.125C8.62814 12.125 6.78126 10.2781 6.78126 8C6.78126 5.72188 8.62814 3.875 10.9063 3.875C13.1844 3.875 15.0313 5.72188 15.0313 8C15.0313 10.2781 13.1844 12.125 10.9063 12.125Z"
        fill="#1C1E20"
        fillOpacity="0.7"
      />
    </svg>
  );
}
