import * as React from "react";

const BackIcon = (props) => (
  <svg
    width={34}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={34} height={34} rx={2.345} fill="#f1f5f9" />
    <path
      d="M18.822 12.31 13.963 17l4.857 4.69"
      stroke="#0f172a"
      strokeWidth={1.172}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BackIcon;
