import React, { useState } from "react";
import { Dialog, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "../../../redux/slices/modal.slices";
import ForwardIcon from "@mui/icons-material/Forward";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

const MoveToGroupsModal = (props) => {
  const { modalType, primaryAction } = props;
  const { open, type, payload } = useSelector((state) => state?.modal);
  const {
    primaryActionPayload,
    groupName,
    url,
    groupThumbnailUrl,
    suggestedGroups,
  } = payload || {};
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [grpName, setGrpName] = useState(groupName);

  const dispatch = useDispatch();

  function handleClose() {
    setGrpName(null);
    dispatch(setModal(modalInitState));
  }

  function handlePrimaryAction(groupId) {
    setGrpName(null);
    primaryAction &&
      primaryAction({ ...primaryActionPayload, groupId, groupName: grpName });
  }

  function handleOnDragEnd(result) {
    if (result?.destination?.droppableId === "new-group") {
      dispatch(
        setModal({
          type: MODALTYPES.UNGROUPED_CREATE_NEW_GROUP,
          open: true,
          payload: {
            id: primaryActionPayload?.id,
          },
        })
      );
    } else {
      const choosenGroup = suggestedGroups[result?.destination?.droppableId];
      handlePrimaryAction(choosenGroup?.groupId);
    }
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Dialog
        onClose={handleClose}
        fullScreen={fullScreen}
        maxWidth="xl"
        fullWidth={false}
        style={{ border: "2px solid red;" }}
        open={open && modalType === type}
        aria-labelledby="responsive-dialog-title"
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{ padding: "15px" }}
        >
          <Typography
            sx={{
              color: "grey",
              fontStyle: "italic",
            }}
          >{`(Please drag and drop the media asset into choice of group) `}</Typography>
          <CloseIcon sx={{ cursor: "pointer" }} onClick={() => handleClose()} />
        </Stack>
        <div
          style={{
            padding: "32px",
            display: "flex",
            flexDirection: "row",
            gap: "40px",
          }}
        >
          {url && groupThumbnailUrl && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Droppable droppableId="droppable-group">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Draggable key={"GROUP"} draggableId={"GROUP"} index={0}>
                        {(provided) => (
                          <img
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            alt="GROUP CONTENT"
                            src={url}
                            height={150}
                            width={150}
                          />
                        )}
                      </Draggable>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <Stack alignItems={"center"} justifyContent={"center"}>
                  <ForwardIcon
                    verticalAlign="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: 50,
                      height: 50,
                      transform: "rotate(90deg)",
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  {suggestedGroups?.map((suggested, index) => (
                    <Droppable droppableId={`${index}`} key={index}>
                      {(provided) => (
                        <Stack
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          direction="row"
                          sx={{
                            padding: "10px",
                            border: "1px dashed black",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            alt="Suggested"
                            src={suggested?.groupThumbnailUrl}
                            height={150}
                            width={150}
                          />
                          {provided.placeholder}
                        </Stack>
                      )}
                    </Droppable>
                  ))}
                  <Droppable droppableId="new-group">
                    {(provided) => (
                      <Stack
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        direction="row"
                        sx={{
                          padding: "10px",
                          border: "1px dashed black",
                          borderRadius: "10px",
                          backgroundColor: "lightgrey",
                          textAlign: "center",
                          height: "180px",
                          width: "180px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body1">
                          Create new group
                        </Typography>
                        <AddIcon sx={{ width: "100", height: "100" }} />
                        {provided.placeholder}
                      </Stack>
                    )}
                  </Droppable>
                </Stack>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </DragDropContext>
  );
};

export default MoveToGroupsModal;
