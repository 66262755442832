import {
  Button,
  Radio,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ContainerWithLabel } from "components/common/atoms";

import { PillProgressIndicator } from "components/common/molecules";

import PublicContainer from "components/layouts/PublicContainer";
import useAppState from "hooks/useAppState";
import useAxios from "hooks/useAxios";
import useUser from "hooks/useUser";
import { API_ROUTES, APP_ROUTES } from "routes";

import styles from "./styles.module.scss";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RolesOptions = [
  { id: "1", value: "Brand Owner", label: "Brand Owner" },
  {
    id: "2",
    value: "Media Buyer/Performance Marketer",
    label: "Media Buyer/Performance Marketer",
  },
  { id: "3", value: "Content Marketer", label: "Content Marketer" },
  { id: "4", value: "Other", label: "Other" },
];
export default function RoleSelectScreen() {
  const [selectedRole, setSelectedRole] = React.useState(RolesOptions[0]);
  const [hearAboutUs, setHearAboutUs] = useState("");
  const [isHearAboutUsError, setIsHearAboutUsError] = useState(true);
  const [adChallenge, setAdChallenge] = useState(1);
  const { userId } = useUser();
  const [otherValue, setOtherValue] = React.useState("");
  const { axiosUserInstance } = useAxios();
  const { updateAppState } = useAppState();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth?.userData);
  const handleNext = () => {
    if (hearAboutUs?.length > 1000) {
      setIsHearAboutUsError(true);
      toast("Word limit cannot exceed 1000 words");
      return;
    } else if (hearAboutUs?.length < 1) {
      setIsHearAboutUsError(true);
      toast("Sorry but answer cannot be empty");
      return;
    } else {
      axiosUserInstance
        .patch(API_ROUTES.USER.UPDATE_USER?.replace(":userId", userId), {
          questions: {
            userType:
              selectedRole.value === "Other" ? otherValue : selectedRole.value,
            howDidYouHearAboutUs: hearAboutUs,
            howBigOfAChallengeIsAdContentForYou: adChallenge,
          },
        })
        .then((res) => {
          updateAppState();
          // if (user?.accountType) {
          if (user?.userName) {
            navigate(APP_ROUTES.DASHBOARD);
          } else {
            navigate(APP_ROUTES.AUTH.SET_USER_NAME);
          }
          // } else {
          //   navigate(APP_ROUTES.AUTH.ACCOUNT_TYPE);
          // }
        });
    }
  };

  return (
    <PublicContainer>
      <div className={styles.role_select_screen}>
        <PillProgressIndicator currentStep={1} totalSteps={3} />
        <Typography variant="h2">What Describes You Best?</Typography>
        <div className={styles.roles_options}>
          {RolesOptions.map((role) => {
            const isChecked = role.value === selectedRole.value;
            const roleClass = isChecked
              ? `${styles.role_option} ${styles.selected_role}`
              : styles.role_option;
            return (
              <div
                className={roleClass}
                key={role.id}
                onClick={() => {
                  setSelectedRole(role);
                }}
              >
                <div style={{ display: "flex" }}>
                  <Radio
                    checked={isChecked}
                    value={role.value}
                    name="radio-buttons"
                  />
                  <ContainerWithLabel label={role.label} selected={isChecked} />
                </div>

                {role.value === "Other" && (
                  <input
                    type="text"
                    style={{
                      border: 0,
                      borderBottom: "1px solid #A1B0CC",
                      outline: "none",
                      marginTop: "11px",
                      padding: "5px",
                      width: "100%",
                      marginLeft: "16%",
                      minWidth: "350px",
                    }}
                    placeholder={"Specify here"}
                    onChange={(e) => setOtherValue(e.target.value)}
                    value={otherValue}
                  />
                )}
              </div>
            );
          })}
        </div>

        <div style={{ width: "100%", display: "flex", gap: "6rem" }}>
          <div style={{ width: "50%" }}>
            <Typography
              var
              sx={{ width: "100%", marginLeft: "10px", marginBottom: "20px" }}
              variant="h2"
            >
              How did you hear about us?
            </Typography>
            <TextField
              onChange={(e) => {
                setIsHearAboutUsError(false);
                setHearAboutUs(e.target.value);
              }}
              sx={{
                width: "70%",
                marginLeft: ".5rem",
              }}
              id="outlined-multiline-static"
              label="Please provide a detailed answer here"
              multiline
              rows={5}
              defaultValue=""
            />
          </div>
          <div style={{ marginTop: "0px", width: "50%" }}>
            <Typography variant="h2" sx={{ mb: "20px" }}>
              How big of a challenge is Ad content for you?
            </Typography>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <Typography sx={{ fontStyle: "italic" }}>(1)</Typography>
              <Slider
                sx={{
                  width: "42%",
                  marginLeft: "3rem",
                }}
                onChange={(e, newValue) => setAdChallenge(newValue)}
                aria-label="Scale"
                value={adChallenge}
                valueLabelDisplay="auto"
                step={1}
                marks
                min={1}
                max={10}
              />
              <Typography
                sx={{
                  fontStyle: "italic",
                }}
              >
                (10)
              </Typography>
            </Stack>
            <Typography
              sx={{
                fontStyle: "italic",
                marginLeft: "2rem",
                marginTop: "1rem",
                width: "50%",
              }}
            >
              (1 being I have Aladin’s lamp for viral converting content, 10
              being, I’ve spent countless hours trying to make things work, I
              still end up being where I started)
            </Typography>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={
              (selectedRole.value === "Other" && !otherValue) ||
              !hearAboutUs?.length > 0 ||
              isHearAboutUsError
            }
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </div>
    </PublicContainer>
  );
}
