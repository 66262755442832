import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: null,
  adAccount: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setAdAccount: (state, action) => {
      state.adAccount = action.payload;
    },
    setUserToken: (state, action) => {
      state.userData = {
        ...state.userData,
        tokens: action.payload,
      };
    },
  },
});

export const { setUserData, setUserToken, setAdAccount } = authSlice.actions;
export default authSlice.reducer;
