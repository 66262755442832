import React from "react";
import { Stack, Typography } from "@mui/material";
import { Logo } from "assets/icons/react";
import { BRAND_NAME } from "utils/constants";

const TitleBar = ({
  title,
  subtitle,
  children,
  titleBarStyle,
  description,
  branding = false,
}) => {
  const titleBarDefaultStyle = {
    width: "100%",
    marginBottom: "24px",
  };

  title = branding ? BRAND_NAME + " | " + title : title;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ ...titleBarDefaultStyle, ...titleBarStyle }}
    >
      <Stack direction="column">
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "22px",
            lineHeight: "150%",
            color: "#1C1E20",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {branding && (
            <Stack style={{ marginRight: 4 }}>
              <Logo height="20" width="20" />
            </Stack>
          )}
          {title}
        </Typography>
        {subtitle && (
          <Stack direction="row" spacing={1}>
            <Typography
              sx={{
                fontSize: "10px",
                lineHeight: "15px",
                color: "#1C1E20",
                fontWeight: "700",
              }}
            >
              Platform:
            </Typography>
            <Typography
              sx={{
                fontSize: "10px",
                lineHeight: "15px",
                color: "rgba(28, 30, 32, 0.7)",
                fontWeight: "400",
              }}
            >
              {subtitle}
            </Typography>
          </Stack>
        )}
        {description && (
          <Typography
            sx={{
              fontSize: "10px",
              lineHeight: "15px",
              color: "#1C1E20",
              fontWeight: "400",
            }}
          >
            {description}
          </Typography>
        )}
      </Stack>
      <Stack>{children}</Stack>
    </Stack>
  );
};

export default TitleBar;
