import { useTheme } from "@emotion/react";
import {
  Button,
  Dialog,
  Radio,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";
import styles from "./styles.module.scss";
import { ContainerWithLabel } from "components/common/atoms";

const AggregateLogicOptions = [
  { id: "1", value: "AND", label: "AND" },
  {
    id: "2",
    value: "OR",
    label: "OR",
  },
  { id: "3", value: "NOT", label: "NOT" },
];

function KeywordLogicModal({ handleAggregateChange }) {
  const theme = useTheme();
  const { aggregateLogic } = useSelector((state) => state.insights);
  const [selectedAggregateLogic, setSelectedAggregateLogic] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();

  function handleClose() {
    handleAggregateChange(selectedAggregateLogic);
    dispatch(setModal(modalInitState));
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={
        modal.open && modal.type === MODALTYPES.KEYWORD_AGGREGATION_LOGIC_MODAL
      }
      aria-labelledby="responsive-dialog-title"
    >
      <div className={styles.role_select_screen}>
        <Typography
          variant="h2"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          Please select the aggregation logic for the search
        </Typography>
        <div className={styles.roles_options}>
          {AggregateLogicOptions.map((role) => {
            const isChecked = role.value === selectedAggregateLogic;
            const roleClass = isChecked
              ? `${styles.role_option} ${styles.selected_role}`
              : styles.role_option;
            return (
              <div
                className={roleClass}
                key={role.id}
                onClick={() => setSelectedAggregateLogic(role?.value)}
              >
                <div style={{ display: "flex" }}>
                  <Radio
                    checked={isChecked}
                    value={role.value}
                    name="radio-buttons"
                  />
                  <ContainerWithLabel label={role.label} selected={isChecked} />
                </div>
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-25px",
          }}
        >
          <Button
            disabled={selectedAggregateLogic === null}
            onClick={handleClose}
            variant="contained"
            sx={{
              textTransform: "capitalize",
              height: "36px",
              fontSize: "auto",
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default KeywordLogicModal;
