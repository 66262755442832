import React from "react";
import {
  Button,
  Dialog,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Tags } from "components/common/atoms";
import { FileUploadDnD, InputWithLabel } from "components/common/molecules";
import { MainContainer } from "components/layouts";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { FileCopy, Replay } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { GrayHeader } from "screens/Prediction/Components";
import usePredictionStep1 from "./usePredictionStep1";
import { APP_ROUTES } from "routes";

const PredictionStatus = {
  1: {
    label: "AI Tagging in progress",
    color: "#d90c07",
    disabled: true,
    btnName: "In Progress",
  },
  2: {
    label: "AI Tagging complete",
    color: "#009436",
    btnName: "Update Manual Tagging",
  },
  3: {
    label: "Manual Tagging complete",
    color: "#0084c0",
    btnName: "Update Filters",
  },
  4: {
    label: "Prediction calculating",
    color: "#bc8f03",
    disabled: true,
    btnName: "In Progress",
  },
  5: { label: "Prediction available", color: "#280084", btnName: "View" },
};

const PredictionStep1 = () => {
  const {
    predictionReports,
    refreshPredictionList,
    uploadedFiles,
    setUploadedFiles,
    onDeleteFile,
    getUploadUrls,
    savePredictionModalOpen,
    setSavePredictionModalOpen,
    reportName,
    setReportName,
    handleCreatePrediction,
  } = usePredictionStep1();

  const navigate = useNavigate();
  return (
    <Stack
      gap={4}
      sx={{
        width: "100%",
      }}
    >
      <MainContainer header={<GrayHeader step={1} />}>
        <Stack
          direction="column"
          alignItems="center"
          spacing={3}
          sx={{ padding: "2px" }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: "20px",
              lineHeight: "26px",
              textAlign: "center",
              maxWidth: "525px",
              textTransform: "capitalize",
            }}
          >
            Upload your creatives for prediction , you can add more than one
            file here
          </Typography>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              minWidth: "760px",
              maxWidth: "760px",
              maxHeight: "300px",
              height: "100%",
              borderRadius: "12px",
              cursor: "pointer",
            }}
          >
            <FileUploadDnD
              onUpload={(files) =>
                setUploadedFiles((prev) => [...prev, ...files])
              }
              multiple
              extensions={{
                "image/jpeg": [".jpg", ".jpeg"],
                "image/png": [".png"],
                "image/svg+xml": [".svg"],
                "video/mp4": [".mp4"],
                "video/quicktime": [".mov"],
                "video/x-msvideo": [".avi"],
                "video/x-flv": [".flv"],
                "video/x-ms-wmv": [".wmv"],
                "video/webm": [".webm"],
              }}
            >
              <Stack
                direction="column"
                alignItems="center"
                sx={{ gap: "4px", padding: "40px 12px", width: "100%" }}
              >
                <CloudDownloadOutlinedIcon
                  sx={{
                    color: "#5E48E8",
                    fontSize: "32px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "21px",
                    fontWeight: "500",
                    color: "#1C1E20",
                    textTransform: "capitalize",
                    marginBottom: "8px",
                  }}
                >
                  Drag & drop files here
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ display: "flex", gap: "12px", color: "#7167FB" }}
                >
                  <Stack sx={{ transform: "rotate(45deg)" }}>
                    <AttachFileOutlinedIcon />
                  </Stack>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "150%",
                      fontWeight: "400",
                      textTransform: "capitalize",
                      letterSpacing: "0.01em",
                    }}
                  >
                    Choose Files here
                  </Typography>
                </Button>
              </Stack>
            </FileUploadDnD>
            {uploadedFiles?.length > 0 && (
              <Stack
                sx={{
                  margin: "12px",
                  padding: "12px",
                  height: "100px",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  overflowY: "auto",
                }}
              >
                {uploadedFiles?.map((file, index) => {
                  const { name } = file;
                  const tag = {
                    id: index,
                    name,
                  };

                  return (
                    <Tags
                      tag={tag}
                      onDelete={() => onDeleteFile(file)}
                      sx={{
                        fontSize: "16px",
                        lineHeight: "21px",
                        fontWeight: "400",
                        color: "#1C1E20",
                        textTransform: "capitalize",
                        marginBottom: "8px",
                      }}
                    />
                  );
                })}
              </Stack>
            )}
          </Stack>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={() => getUploadUrls()}
            disabled={uploadedFiles?.length === 0}
          >
            Submit
          </Button>
        </Stack>
      </MainContainer>
      <MainContainer
        header={
          <Stack
            direction="row"
            spacing={2}
            sx={{ padding: "8px 16px", background: "#F9F9F9" }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <FileCopy sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              <Typography sx={{ fontWeight: "500" }}>Reports</Typography>
            </Stack>
            <Button
              sx={{
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                padding: "0",
                minHeight: "unset",
                minWidth: "unset",
                color: "#000",
              }}
              onClick={refreshPredictionList}
            >
              <Replay />
            </Button>
          </Stack>
        }
      >
        <TableContainer
          className="content-container"
          sx={{
            maxHeight: `calc(100vh - 155px)`,
          }}
        >
          <Table
            stickyHeader
            aria-label="simple table"
            sx={{ border: "1px solid #A1B0CC" }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ border: "1px solid #A1B0CC" }}>
                  Date of Report
                </TableCell>
                <TableCell align="left" sx={{ border: "1px solid #A1B0CC" }}>
                  Status
                </TableCell>
                <TableCell align="left" sx={{ border: "1px solid #A1B0CC" }}>
                  Report Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {predictionReports?.length > 0 ? (
                predictionReports?.map((report) => {
                  const { btnName } = PredictionStatus?.[report?.status];
                  const btnRedirect =
                    btnName === "View"
                      ? APP_ROUTES.PREDICTION.PREDICTION_REPORT_DETAILS?.replace(
                          ":reportableId",
                          report?.id
                        )
                      : APP_ROUTES.PREDICTION.PREDICTION_REPORT?.replace(
                          ":reportableId",
                          report?.id
                        );
                  return (
                    <TableRow key={report.id} hover>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid #A1B0CC" }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="baseline"
                        >
                          <Typography sx={{ fontWeight: "500" }}>
                            {report?.name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid #A1B0CC" }}
                      >
                        <Typography
                          sx={{
                            textTransform: "capitalize",
                            border: `1px solid ${
                              PredictionStatus?.[report?.status]?.color
                            }`,
                            padding: "4px 12px",
                            borderRadius: "32px",
                            display: "inline-block",
                            color: PredictionStatus?.[report?.status]?.color,
                            fontSize: "16px",
                            fontWeight: "300",
                          }}
                        >
                          {PredictionStatus?.[report?.status]?.label}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid #A1B0CC" }}
                      >
                        <Button
                          variant="text"
                          sx={{ textTransform: "capitalize" }}
                          disabled={PredictionStatus?.[report?.status].disabled}
                          onClick={() => {
                            navigate(btnRedirect);
                          }}
                        >
                          {btnName}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    padding: "16px",
                  }}
                >
                  No Reports Found
                </Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Save Prediction Modal */}

        <Dialog
          open={savePredictionModalOpen}
          onClose={() => {
            setSavePredictionModalOpen(false);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <Stack
            direction="column"
            sx={{ padding: "16px", minWidth: "400px", minHeight: "100px" }}
            spacing={3}
          >
            <InputWithLabel
              label="Report Name"
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
            />
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button
                variant="outlined"
                onClick={() => setSavePredictionModalOpen(false)}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                onClick={() => handleCreatePrediction()}
                disabled={reportName === ""}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      </MainContainer>
    </Stack>
  );
};

export default PredictionStep1;
