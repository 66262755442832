import { Avatar, Button, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.scss";
import SyncIcon from "@mui/icons-material/Sync";
import useUngroupedVideos from "./useUngroupedVideos";
import { BasicActionsModal, CustomModal } from "components/modals";
import { Check } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { merged_squares } from "assets/icons/svg";
import GroupImagesModal from "components/modals/GroupImagesModal";
import ContentContainer from "components/layouts/ContentContainer";
import { GROUP_ENTITY } from "utils/constants";
import { PrivateContainer } from "components/layouts";
import { BackButton } from "components/common/atoms";
import { APP_ROUTES } from "routes";
import { InputWithLabel } from "components/common/molecules";
import { storageUrlToCdn } from "utils/helper";
import MoveToGroupsModal from "components/modals/UngrouppedMediaModal/MoveToGroupsModal";
import UngrupedNewGroupModal from "components/modals/UngrouppedMediaModal/UngrupedNewGroup";
import { Droppable } from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";
import { Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DragDropContext } from "react-beautiful-dnd";
import ConfirmationModal from "components/modals/UngrouppedMediaModal/ConfirmationModal";

const Card = (props) => {
  const {
    _id,
    entityId: id,
    url,
    isSelected,
    isSelecting,
    handleSelect,
    groupName = "",
    groupId,
    name,
    groupThumbnailUrl,
    thumbnailUrl,
    suggestedGroups,
  } = props;

  const dispatch = useDispatch();
  function handleClick() {
    if (!isSelecting) {
      //open modal
      dispatch(
        setModal({
          type: MODALTYPES.BASIC_ACTION.GROUP_IMAGE,
          open: true,
          payload: {
            action_sure_msg: `Are you sure you want to move ${name} video to ${groupName} group`,
            action_warning_msg: "This Action Cannot Be Undone",
            primaryBtnText: "Yes, Move",
            secondaryBtnText: "Cancel",
            dangerMode: false,
            image: merged_squares,
            groupThumbnailUrl,
            groupName: groupName,
            suggestedGroups,
            url: thumbnailUrl,
            primaryActionPayload: {
              groupId,
              id,
            },
          },
        })
      );
    } else {
      handleSelect(_id);
    }
  }

  return (
    <div className={styles.creative_card} onClick={handleClick} key={id}>
      {isSelecting && (
        <Avatar
          sx={{
            backgroundColor: "#fff",
            height: "24px",
            width: "24px",
            position: "absolute",
            top: "16px",
            right: "16px",
            border: "1px solid #3f51b5",
            zIndex: 1,
          }}
        >
          {isSelected && <Check color="primary" />}
        </Avatar>
      )}
      <div
        className={styles.thumbnail}
        style={{ filter: isSelected && "brightness(50%)" }}
      >
        <img
          src={storageUrlToCdn(thumbnailUrl)}
          className="react-player"
          key={_id}
          alt="Entity Media Asset"
        />
        {!!name && (
          <Stack
            direction="row"
            className={styles.thumbnail_text}
            sx={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 93.92%)",
              height: "100%",
              width: "100%",
            }}
          >
            <Stack
              direction="row"
              sx={{
                gap: "5px",
                backgroundColor: "black",
                opacity: "0.8",
                borderRadius: "5px",
                padding: "5px",
                width: "100%",
              }}
            >
              <span>✨</span>
              <p
                title={`Tip: ${name ?? ""} likely goes to ${
                  groupName ?? ""
                } group`}
              >
                {`Tip: ${name?.slice(0, 20) ?? ""} likely goes to ${
                  groupName?.slice(0, 20) ?? ""
                } group`}
              </p>
            </Stack>
          </Stack>
        )}
      </div>
    </div>
  );
};

export default function VideosTab() {
  const {
    isSelecting,
    entitiesList,
    handleSelect,
    addSysGeneratedTagsModalPrimaryAction,
    moveToSuggestedGroup,
    initGroups,
    totalDocs,
    onCreateNewGroup,
    newGroupName,
    setNewGroupName,
    newGroupModalPrimaryAction,
    handleCreateAndAddToNewGroup,
  } = useUngroupedVideos();
  const { open, type } = useSelector((state) => state?.modal);
  const dispatch = useDispatch();
  const handleOnDragEnd = (result) => {
    if (result?.destination?.droppableId?.split("-")[0] === "new") {
      const draggedGroup = entitiesList[result?.draggableId?.split("-")[1]];
      dispatch(
        setModal({
          type: MODALTYPES.UNGROUPED_CREATE_NEW_GROUP,
          open: true,
          payload: {
            id: draggedGroup?.entityId, //entity id
          },
        })
      );
    } else {
      if (!result?.destination?.droppableId?.split("-")[2]) return;
      // alert(result?.destination?.droppableId?.split("-")[2]);
      const draggedGroup = entitiesList[result?.draggableId?.split("-")[1]];
      const choosenGroup =
        draggedGroup?.suggestedGroups[
          result?.destination?.droppableId?.split("-")[2] || 0
        ];
      dispatch(
        setModal({
          type: MODALTYPES.UNGROUPPED_CONFIRMATION_MODAL,
          open: true,
          payload: {
            id: draggedGroup?.entityId, //entity id
            groupId: choosenGroup?.groupId,
            groupName: choosenGroup?.groupName,
          },
        })
      );
    }
  };
  return (
    <PrivateContainer
      bosyStyles={{
        justifyContent: "flex-start",
      }}
      activePage={APP_ROUTES.GROUPS_TAGS.UNGROUPED.VIDEOS}
    >
      <BackButton
        to={APP_ROUTES.GROUPS_TAGS.default}
        btnText="Ungrouped Videos"
        from={APP_ROUTES.GROUPS_TAGS.UNGROUPED.VIDEOS}
      />
      <div className={styles.image_tab__container}>
        <div className={styles.header}>
          <div className={styles.left}>
            <span className="text-sm">Total Groups: {totalDocs}</span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "rgba(28, 30, 32, 0.5)",
                cursor: "pointer",
              }}
              onClick={initGroups}
            >
              Refresh <SyncIcon sx={{ padding: "0.25rem" }} />
            </span>
          </div>
          {/* <div className={styles.right}>
            {isSelecting && (
              <Button
                variant="outlined"
                onClick={toggleSelecting}
                sx={{ textTransform: "none" }}
              >
                Cancel
              </Button>
            )}
            {isSelecting ? (
              <Button
                variant="contained"
                onClick={handleMoveToGroup}
                sx={{ textTransform: "none" }}
                disabled={
                  entitiesList.filter((group) => group.isSelected).length === 0
                }
              >
                Move To
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={toggleSelecting}
                sx={{ textTransform: "none" }}
              >
                Select
              </Button>
            )}
          </div> */}
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <ContentContainer
            containerStyles={{
              maxHeight: "calc(100vh - 50px)",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              rowGap: "24px",
              width: "100%",
              alignItems: "flex-start",
            }}
          >
            <Stack direction={"column"} spacing={2} width={"100%"}>
              {entitiesList.map((entity, index) => (
                <div
                  key={entity._id}
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    flexWrap: "wrap",
                    width: "100%",
                    border: "1px dashed grey",
                    padding: "10px",
                    maxHeight: "230px",
                  }}
                >
                  {/* Current entity */}
                  <Droppable droppableId={`entity-${index}`}>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Draggable
                          draggableId={`entity-${index}`}
                          index={index}
                          key={`ENTITY-${index}`}
                        >
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <Card
                                isSelecting={isSelecting}
                                handleSelect={handleSelect}
                                key={entity._id}
                                {...entity}
                              />
                            </div>
                          )}
                        </Draggable>
                      </div>
                    )}
                  </Droppable>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      flex: "0 0 auto",
                      position: "sticky",
                      width: "20px",
                      maxHeight: "230px",
                    }}
                  >
                    <DragIndicatorIcon />
                  </Stack>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                      overflowY: "hidden",
                    }}
                  >
                    {entity?.suggestedGroups?.map(
                      (suggested, suggestedIndex) => (
                        <Droppable
                          droppableId={`suggested-${index}-${suggestedIndex}`}
                          key={`SUGGESTED-${index}-${suggestedIndex}`}
                        >
                          {(provided) => (
                            <div className={styles.creative_card}>
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={styles.thumbnail}
                              >
                                <img
                                  alt="Suggested"
                                  src={storageUrlToCdn(
                                    suggested?.groupThumbnailUrl
                                  )}
                                />
                                {provided.placeholder}
                              </div>
                            </div>
                          )}
                        </Droppable>
                      )
                    )}
                    <Droppable droppableId={`new-group-${index}`}>
                      {(provided) => (
                        <Stack
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          direction="row"
                          sx={{
                            padding: "10px",
                            border: "1px dashed black",
                            borderRadius: "10px",
                            backgroundColor: "lightgrey",
                            textAlign: "center",
                            height: "210px",
                            width: "180px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body1">
                            Create new group
                          </Typography>
                          <AddIcon sx={{ width: "100", height: "100" }} />
                          {provided.placeholder}
                        </Stack>
                      )}
                    </Droppable>
                  </div>
                </div>
              ))}
            </Stack>
          </ContentContainer>
        </DragDropContext>
        <ConfirmationModal
          modalType={MODALTYPES.UNGROUPPED_CONFIRMATION_MODAL}
          primaryAction={moveToSuggestedGroup}
        />
        <MoveToGroupsModal
          modalType={MODALTYPES.BASIC_ACTION.GROUP_IMAGE}
          primaryAction={moveToSuggestedGroup}
          handleCreateAndAddToNewGroup={handleCreateAndAddToNewGroup}
        />
        <BasicActionsModal
          modalType={MODALTYPES.BASIC_ACTION.ADD_SYS_GENERATED_TAGS}
          primaryAction={addSysGeneratedTagsModalPrimaryAction}
        />
        <GroupImagesModal
          moveToSuggestedGroup={moveToSuggestedGroup}
          entityType={GROUP_ENTITY.VIDEO}
          onCreateNewGroup={onCreateNewGroup}
        />
        <UngrupedNewGroupModal
          modalType={MODALTYPES.UNGROUPED_CREATE_NEW_GROUP}
          handleCreateAndAddToNewGroup={handleCreateAndAddToNewGroup}
        />
        {open && type === MODALTYPES.CREATE_NEW_GROUP_MODAL && (
          <CustomModal open={true}>
            <Stack
              sx={{
                gap: "20px",
                padding: "10%",
                width: "100%",
                minWidth: "600px",
              }}
            >
              <Typography variant="h2">Create New Group</Typography>
              <InputWithLabel
                label=""
                placeholder="Group Name"
                onChange={(e) => setNewGroupName(e.target.value)}
              />
              <Stack
                sx={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    width: "100px",
                  }}
                  disabled={newGroupName.length === 0}
                  onClick={newGroupModalPrimaryAction}
                >
                  Create
                </Button>
              </Stack>
            </Stack>
          </CustomModal>
        )}
      </div>
    </PrivateContainer>
  );
}
