import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MODALTYPES, setModal } from "../../../../redux/slices/modal.slices";
import useInfiniteScroll from "../../../../hooks/useInfiniteScroll";
import { API_ROUTES } from "../../../../routes";
import { GROUP_ENTITY } from "../../../../utils/constants";
import useAxios from "../../../../hooks/useAxios";

export default function useTextTagging(isManuallyTagged) {
  const { axiosOrgInstance } = useAxios();
  const dispatch = useDispatch();
  const [groupsList, setGroupsList] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { isLoadMore } = useInfiniteScroll("content-container", hasNextPage);
  const [page, setPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);

  useEffect(() => {
    if (isLoadMore && hasNextPage) {
      axiosOrgInstance
        .get(API_ROUTES.ONBOARDING.LIST_ORG_GROUPS, {
          params: {
            groupEntity: GROUP_ENTITY.TEXT,
            page: page + 1,
            isManualGroupingDone: 1,
            isGroupManualTaggingDone: isManuallyTagged ? 1 : 0,
          },
        })
        .then((res) => {
          setHasNextPage(res?.hasNextPage);
          setPage(res?.page);
          setGroupsList((prev) => [...prev, ...res?.docs]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadMore]);

  function initGroup() {
    axiosOrgInstance
      .get(API_ROUTES.ONBOARDING.LIST_ORG_GROUPS, {
        params: {
          groupEntity: GROUP_ENTITY.TEXT,
          isManualGroupingDone: 1,
          isGroupManualTaggingDone: isManuallyTagged ? 1 : 0,
        },
      })
      .then((res) => {
        setHasNextPage(res?.hasNextPage);
        setTotalDocs(res?.totalDocs);
        setGroupsList(res?.docs);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function startTagging(group) {
    dispatch(
      setModal({
        open: true,
        type: isManuallyTagged
          ? MODALTYPES.MANUAL_TEXT_TAGGING_MODAL
          : MODALTYPES.TEXT_TAGGING_MODAL,
        payload: { group: group },
      })
    );
  }

  useEffect(() => {
    initGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    groupsList,
    totalDocs,
    initGroup,
    startTagging,
  };
}
