import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useSelector, useDispatch } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";
import { API_ROUTES, APP_ROUTES } from "routes";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Typography } from "@mui/material";
import {
  Cable,
  Plus,
  Receipt,
  Settings,
  SquareArrowOutUpRight,
  UserCog,
  Users,
} from "lucide-react";

import UserSettings from "./components/UserSettings";
import useAuth from "hooks/useAuth";

const UserProfile = () => {
  const navigate = useNavigate();
  const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedtab] = useState("account");
  const location = useLocation();
  const { isUserAuthenticated, userData } = useAuth();

  useEffect(() => {
    const eventPayload = {
      event: "page_view",
      page_path: location.pathname,
    };
    if (isUserAuthenticated) {
      eventPayload["User_Details"] = {
        user_id: userData?.user?._id,
        email: userData?.user?.email,
        first_name: userData?.user?.firstName,
        last_name: userData?.user?.lastName,
        username: userData?.user?.userName,
        user_type: userData?.user?.userType,
        login_status: isUserAuthenticated,
        created_at: userData?.user?.createdAt,
      };
    }

    if (window.dataLayer) window.dataLayer.push(eventPayload);

  }, [location]);

  function handleClose() {
    dispatch(setModal(modalInitState));
  }

  let selectedTabContent;

  switch (selectedTab) {
    case "billing":
      selectedTabContent = (
        <div>
          <CardHeader>
            <CardTitle>Billing</CardTitle>
            <CardDescription>Manage your billing preferences.</CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="space-y-1">
              <div>Next billing date</div>
              {/* <div>{profileDetails?.nextBillingDate}</div> */}
            </div>
          </CardContent>
        </div>
      );
      break;

    case "account":
    default:
      selectedTabContent = <UserSettings />;
      break;
  }

  return (
    <Dialog
      open={modal?.open && modal?.type === MODALTYPES.USER_PROFILE}
      onOpenChange={handleClose}
    >
      <DialogContent className="flex sm:max-w-[60%] sm:min-h-[50%]">
        <div className="flex flex-col mt-4 gap-x-4 justify-between">
          <div className="flex flex-col min-w-[40%] justify-start gap-y-2 ">
            <Typography variant="h3">Accounts</Typography>
            <Button
              variant="ghost"
              className="w-full justify-between"
              onClick={() => setSelectedtab("account")}
            >
              <span>
                <UserCog size={16} className="mr-2 inline-block" />
                My Profile
              </span>
            </Button>
            <Button
              variant="ghost"
              className="w-full justify-between"
              onClick={() => setSelectedtab("billing")}
            >
              <span>
                <Receipt size={16} className="mr-2 inline-block" />
                Billing
              </span>
            </Button>
          </div>
          <div className="flex flex-col min-w-[40%] justify-start gap-y-2">
            <Button
              variant="ghost"
              className="w-full justify-between"
              onClick={() => {
                dispatch(setModal(modalInitState));
                navigate(APP_ROUTES.AUTH.ADD_ORGANIZATION);
              }}
            >
              <span>
                <Plus size={16} className="mr-2 inline-block" />
                Add organization
              </span>
              <SquareArrowOutUpRight size={16} className="ml-4" />
            </Button>
            <Button
              variant="ghost"
              className="w-full justify-between"
              onClick={() => {
                dispatch(
                  setModal({
                    open: true,
                    type: MODALTYPES.INVITE_USER_MODAL,
                    payload: {},
                  })
                );
                navigate(APP_ROUTES.SETTING);
              }}
            >
              <span>
                <Cable size={16} className="mr-2 inline-block" />
                My Connections
              </span>
              <SquareArrowOutUpRight size={16} className="ml-4" />
            </Button>
            <Button
              variant="ghost"
              className="w-full justify-between"
              onClick={() => {
                dispatch(setModal(modalInitState));
                navigate(APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT);
              }}
            >
              <span>
                <Users size={16} className="mr-2 inline-block" />
                Invite users
              </span>
              <SquareArrowOutUpRight size={16} className="ml-4" />
            </Button>
            <Button
              variant="ghost"
              className="w-full justify-between"
              onClick={() => {
                dispatch(setModal(modalInitState));
                navigate(APP_ROUTES.SETTING);
              }}
            >
              <span>
                <Settings size={16} className="mr-2 inline-block" />
                Organization settings
              </span>
              <SquareArrowOutUpRight size={16} className="ml-4" />
            </Button>
          </div>
        </div>

        <div>{selectedTabContent}</div>
      </DialogContent>
    </Dialog>
  );
};

export default UserProfile;
