import { DataThresholding } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

export const ThresholdValueFilterOptions = [
  { label: "Top 10%", value: "10%" },
  {
    label: "Top 25%",
    value: "25%",
  },
  {
    label: "Average",
    value: "Average",
  },
  {
    label: "Custom",
    value: "4",
  },
];
export default function ThresholdValueFilter(props) {
  const { options, value, onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const popUpOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onChange({
      thresholdType: selectedOption,
      thresholdValue: thresholdValues,
    });
  };

  const [thresholdValues, setThresholdValues] = React.useState();

  const [selectedOption, setSelectedOption] = React.useState(
    value?.thresholdType
  );

  function getSelectedOptionLabel(value) {
    const option = options.find((option) => `${option?.value}` === `${value}`);
    return option?.label;
  }

  function handleCheckBox(event) {
    const { value } = event.target;
    if (selectedOption !== value) {
      setSelectedOption(value);
    }
  }

  useEffect(() => {
    if (value?.thresholdValues && !thresholdValues) {
      setThresholdValues(value.thresholdValues);
    }
  }, [thresholdValues, value]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <Button
        variant="outlined"
        startIcon={<DataThresholding />}
        id="basic-button"
        aria-controls={popUpOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={popUpOpen ? "true" : undefined}
        onClick={handleClick}
        sx={{ textTransform: "capitalize", height: "36px" }}
      >
        {`${selectedOption}` === "4" &&
          `${!!thresholdValues ? thresholdValues : "Threshold Values"}`}
        {`${selectedOption}` !== "4" && getSelectedOptionLabel(selectedOption)}
        {!selectedOption && "Select Threshold Values"}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={popUpOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{ padding: "0 140px 0 16px" }}
        >
          <Stack direction="column">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Filtered By</Typography>
            </div>
            <FormGroup>
              {options.map((option) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={`${selectedOption}` === `${option?.value}`}
                        disabled={option.value === "4" ? false : true}
                      />
                    }
                    label={option?.label}
                    value={option?.value}
                    onChange={handleCheckBox}
                    key={option?.value}
                  />
                );
              })}

              {`${selectedOption}` === "4" && (
                <TextField
                  id="custom"
                  label="Custom Threshold Values"
                  variant="standard"
                  onChange={(e) => {
                    setThresholdValues(e.currentTarget.value);
                  }}
                />
              )}
            </FormGroup>
          </Stack>

          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            <Button variant="contained" onClick={handleClose}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </Stack>
  );
}
