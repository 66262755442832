import { Button, Container, Stack, Typography } from "@mui/material";
import { GoogleIcon } from "assets/icons/react";
import { InputWithLabel } from "components/common/molecules";
import { line, sign_up_illustration } from "../../../assets/images";
import styles from "./styles.module.scss";
import useLogin from "./useLogin";

export default function LoginScreen() {
  const {
    form,
    handleChange,
    handleSubmit,
    isValidated,
    handleFirebaseFacebookLogin,
    handleFirebaseGoogleLogin,
    handleHomeRoute,
    handleSignUpRoute,
    handleForgotPasswordRoute,
  } = useLogin();

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "100px",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "4rem",
          width: "100%",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack sx={{ width: "100%", maxWidth: "488px" }}>
            <img
              src="/logoNavbar.svg"
              alt="logo"
              onClick={handleHomeRoute}
              className="cursor-pointer h-16"
            />
          </Stack>

          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "24px",
              marginTop: "40px",
              color: "#A1B0CC",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                gap: "1rem",
                width: "100%",
                maxWidth: "488px",
                color: "#0d0c22",
                border: "1px solid #e7e7e9",
                borderRadius: "2rem",
                padding: "0.75rem",
                "&:hover": {
                  border: "1px solid #dbdbde",
                  background: "white",
                },
              }}
              startIcon={<GoogleIcon />}
              color="inherit"
              onClick={handleFirebaseGoogleLogin}
            >
              Sign in with Google
            </Button>
            {/* <Button
              sx={{ textTransform: "none" }}
              variant="outlined"
              startIcon={<MetaIcon />}
              color="inherit"
              onClick={handleFirebaseFacebookLogin}
            >
              {" "}
              Meta
            </Button> */}
          </Stack>

          <div className={styles.between_lines}>
            <img src={line} alt="line" />
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              or sign in with email
            </Typography>
            <img src={line} alt="line" />
          </div>

          <div className={styles.form_styles}>
            <InputWithLabel
              label="Email Id"
              name="email"
              value={form.email}
              onChange={handleChange}
            />
            <Stack sx={{ position: "relative" }}>
              <Typography
                sx={{ position: "absolute", right: 0 }}
                className="cursor-pointer underline"
                onClick={handleForgotPasswordRoute}
              >
                Forgot?
              </Typography>
              <InputWithLabel
                label="Enter Password"
                name="password"
                type="password"
                value={form.password}
                onChange={handleChange}
              />
            </Stack>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "24px",
                width: "100%",
                maxWidth: "488px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  gap: "1rem",
                  width: "100%",
                  maxWidth: "488px",
                  background: "#0d0c22",
                  borderRadius: "2rem",
                  padding: "0.75rem",
                  "&:hover": {
                    background: "#565564",
                  },
                }}
                onClick={handleSubmit}
              >
                Login
              </Button>
              <Typography
                fontSize={"1rem"}
                fontWeight={400}
                textAlign={"center"}
                marginTop={"25px"}
                sx={{ maxWidth: "488px" }}
                color={"#3d3d4e"}
              >
                Don't have an account?{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={handleSignUpRoute}
                >
                  Sign up
                </span>
              </Typography>
              {/* <ShadButton disabled={!isValidated} onClick={handleSubmit}>
                Login
              </ShadButton> */}
            </div>
          </div>
        </Stack>
        <div
          className="hidden xl:flex"
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={sign_up_illustration}
            className={styles.loginimage}
            alt=""
          />
        </div>
      </Container>
    </Stack>
  );
}
