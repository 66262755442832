import {
  Button,
  Dialog,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { modalInitState, setModal } from "../../../redux/slices/modal.slices";
import { merged_squares } from "../../../assets/icons/svg";
import { Stack } from "@mui/system";

export default function MergeMultipleGroupsModal(props) {
  const { primaryAction, secondaryAction } = props;
  const { payload } = useSelector((state) => state?.modal);
  const {
    primaryBtnText = "Merge",
    secondaryBtnText = "Cancel",
    primaryActionPayload,
  } = payload || {};

  const [addManualTags, setAddManualTags] = React.useState(true);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  function handleClose() {
    dispatch(setModal(modalInitState));
  }
  function handlePrimaryAction() {
    handleClose();
    const payload = {
      addManualTags,
      ...primaryActionPayload,
    };
    primaryAction && primaryAction(payload);
  }

  function handleSecondaryAction() {
    handleClose();
    secondaryAction && secondaryAction();
  }
  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      aria-labelledby="responsive-dialog-title"
    >
      <div
        style={{
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
        }}
      >
        <div style={{ display: "flex", gap: "5%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexBasis: "30%",
              flexGrow: "1",
              flexShrink: "1",
            }}
          >
            <img src={merged_squares} alt="merge group" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexBasis: " 65%",
              flexGrow: "1",
              flexShrink: "1",
              gap: "12px",
              justifyContent: "center",
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: "400" }}>
              Do You Want To Merge These Groups?
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontSize: "16px", fontWeight: "700", color: "#1C1E20" }}
            >
              Both Group Tags Will Automatically Merge
            </Typography>
          </div>
        </div>
        <Stack direction="column" gap="30px">
          <FormControl
            sx={{
              display: "flexbox",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <FormLabel id="demo-row-radio-buttons-group-label">
              Do You Want To Add Manual Tags?
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={addManualTags}
              onChange={(e) =>
                setAddManualTags(e.target.value === "true" ? true : false)
              }
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Stack>
        <DialogActions
          style={{ display: "flex", padding: "16px 24px 0 16px", gap: "40px" }}
        >
          <Button
            sx={{
              textTransform: "capitalize",
              fontWeight: "500",
            }}
            variant="text"
            color={"primary"}
            autoFocus
            onClick={handleSecondaryAction}
          >
            {secondaryBtnText}
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              textTransform: "capitalize",
              fontWeight: "400",
            }}
            color={"primary"}
            onClick={() => handlePrimaryAction()}
          >
            {primaryBtnText}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
