import React from "react";
import { Stack } from "@mui/material";

const GenerationContainer = ({ children, sx }) => {
  return (
    <Stack
      direction="column"
      spacing={4}
      sx={{
        padding: "40px",
        background: "#FFFFFF",
        border: "1px solid #EAECF0",
        borderRadius: "10px",
        ...sx,
      }}
      marginBottom={3}
    >
      {children}
    </Stack>
  );
};

export default GenerationContainer;
