import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

import {
  RecommendationContainer,
  RecommendationListItem,
  RecommendationStepper,
  SameDataComposedChart,
} from "../Components";

import { HorizontalValueSelect } from "components/common/atoms";
import { convertToTitleCase } from "utils/helper";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { VIDEO_SEGMENT_TYPE_REVERSE } from "utils/constants";
import SEO from "utils/SEO";
import {
  CometChatMessages,
  ListItemStyle,
  MessageHeaderConfiguration,
  MessagesStyle,
} from "@cometchat/chat-uikit-react";

export default function VideoIntelligence(props) {
  const {
    segment,
    isLoading,
    recommendationData,
    activeRecommendation,
    setActiveRecommendation,
    loadingRecommendationData,
    isDrawerOpen = false,
    currentGroup = null,
  } = props;

  const [videoSegmentsOptions, setVideoSegmentsOptions] = useState([]);

  const [selectedSegment, setSelectedSegment] = React.useState([]);

  const [categoriesOptions, setCategoryOptions] = useState([]);

  const [selectedCategory, setSelectedCategory] = React.useState([]);
  const [categoriesForLineChart, setCategoriesForLineChart] = useState([]);

  const [selectedCategoryForLineChart, setSelectedCategoryForLineChart] =
    React.useState([]);

  useEffect(() => {
    let videoSegments = segment?.map((item) => ({
      label: VIDEO_SEGMENT_TYPE_REVERSE[item?.key],
      value: item?.key,
      ...item,
    }));
    let categoriesForLineChart = [];
    videoSegments?.forEach((item) => {
      item?.category?.forEach((category, index) => {
        //only unique categories
        if (
          !categoriesForLineChart?.find((item) => item?.label === category?.key)
            ?.label
        )
          categoriesForLineChart.push({
            label: category?.key,
            value: category?.breakup,
          });
      });
    });
    setVideoSegmentsOptions(videoSegments);
    setCategoriesForLineChart(categoriesForLineChart);
  }, [segment]);

  useEffect(() => {
    setSelectedCategoryForLineChart([categoriesForLineChart?.[0]] ?? []);
  }, [categoriesForLineChart]);

  useEffect(() => {
    setSelectedSegment(videoSegmentsOptions?.[0] ?? []);
  }, [videoSegmentsOptions]);

  useEffect(() => {
    const categoriesOptions = selectedSegment?.category?.map((item) => ({
      label: item?.key,
      value: item?.breakup,
    }));
    setCategoryOptions(categoriesOptions);
  }, [selectedSegment]);

  useEffect(() => {
    setSelectedCategory(categoriesOptions?.[0] ?? []);
  }, [categoriesOptions]);
  const lStyle = new ListItemStyle({
    background: "transparent",
  });

  const mhConfig = new MessageHeaderConfiguration({
    listItemStyle: lStyle,
    display: "none",
  });

  const hasWindow = typeof window !== "undefined";

  const mStyle = new MessagesStyle({
    width: "390px",
    height: hasWindow ? window.innerHeight - 20 : "760px",
    overflowY: "scroll",
    display: "flex",
    justifyContent: "right",
  });
  return (
    <>
      <SEO
        title="Storyteller Video Intelligence Recommendations"
        description="AI powered Video Intelligence recommendations on your creatives to boost market reach"
        name="Recommendation by Storyteller"
        type="Report"
      />
      <Stack>
        <Stack
          direction="column"
          spacing={1}
          width={isDrawerOpen ? "100%" : "100%"}
        >
          <Stack width="100%">
            <RecommendationContainer title="Top Recommendation">
              {recommendationData?.topRecommendations?.length > 0 ? (
                <Stack direction="row" spacing={3}>
                  <Stack direction="column" spacing={2} sx={{ flex: 1 }}>
                    {recommendationData?.topRecommendations?.map(
                      (item, index) => (
                        <RecommendationListItem
                          key={index}
                          isActive={index === activeRecommendation}
                          onClick={() => setActiveRecommendation(index)}
                        >
                          {item?.recommendation}
                        </RecommendationListItem>
                      )
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{ maxWidth: "400px", minWidth: "400px" }}
                  >
                    {recommendationData?.topRecommendations && (
                      <RecommendationStepper
                        topRecommendations={
                          recommendationData?.topRecommendations
                        }
                        activeRecommendation={activeRecommendation}
                        setActiveRecommendation={setActiveRecommendation}
                      />
                    )}
                  </Stack>
                </Stack>
              ) : (
                <Typography variant="body1" sx={{ color: "#000000" }}>
                  {loadingRecommendationData ? "Loading..." : "No Data Found"}
                </Typography>
              )}
            </RecommendationContainer>

            <RecommendationContainer title="Category Influence Graph">
              {segment?.length > 0 ? (
                <Stack direction="row" spacing={3}>
                  <SameDataComposedChart
                    segmentData={segment}
                    categories={selectedCategoryForLineChart}
                  />
                  <div style={{ width: "400px" }}>
                    <FormGroup>
                      {categoriesForLineChart?.length > 0 &&
                        categoriesForLineChart.map((v, i) => {
                          const checked = selectedCategoryForLineChart?.find(
                            (item) => item?.value === v?.value
                          )
                            ? true
                            : false;
                          return (
                            <FormControlLabel
                              key={i}
                              control={
                                <Checkbox
                                  checked={checked}
                                  onChange={(e) => {
                                    let temp = selectedCategoryForLineChart;

                                    if (e.target.checked) {
                                      temp.push(v);
                                    } else {
                                      temp =
                                        selectedCategoryForLineChart.filter(
                                          (item) => item.value !== v.value
                                        );
                                    }

                                    setSelectedCategoryForLineChart(temp);
                                  }}
                                  inputProps={{ "aria-label": "controlled" }}
                                  checkedIcon={<RadioButtonChecked />}
                                  icon={<RadioButtonUnchecked />}
                                />
                              }
                              label={convertToTitleCase(v?.label)}
                            />
                          );
                        })}
                    </FormGroup>
                  </div>
                </Stack>
              ) : (
                <Typography variant="body1" sx={{ color: "#000000" }}>
                  {isLoading ? "Loading..." : "No Data Found"}
                </Typography>
              )}
            </RecommendationContainer>

            <RecommendationContainer
              title="Actionable Feature Impact Analysis"
              RightTitle={
                <>
                  {segment?.length > 0 && (
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        padding: "4px",
                        color: "#7167FB",
                      }}
                    >
                      <HorizontalValueSelect
                        options={videoSegmentsOptions}
                        label="Video Snippet"
                        key={selectedSegment?.label}
                        selectedValue={selectedSegment}
                        onSelect={(value) => {
                          setSelectedSegment(value);
                          const selectedCategory = value?.category?.map(
                            (item) => ({
                              label: item?.key,
                              value: item?.breakup,
                            })
                          );
                          setSelectedCategory(selectedCategory?.[0] ?? []);
                        }}
                      />

                      <HorizontalValueSelect
                        key={selectedCategory?.label}
                        options={categoriesOptions}
                        label="Category"
                        selectedValue={selectedCategory}
                        onSelect={(value) => setSelectedCategory(value)}
                      />
                    </Stack>
                  )}
                </>
              }
            >
              {selectedCategory?.value?.length > 0 ? (
                <TableContainer
                  sx={{
                    border: "1px solid #A1B0CC",
                  }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderBottom: "1px solid #A1B0CC" }}>
                          Analysis
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <Box
                      sx={{
                        height: "300px",
                        overflowY: "auto",
                      }}
                    >
                      <TableBody>
                        {selectedCategory?.value?.map((item) => {
                          return (
                            <TableRow key={item?.key}>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  border: "0",
                                  paddingTop: "0",
                                  paddingBottom: "0",
                                }}
                              >
                                <RecommendationListItem
                                  listStyle={{ background: "transparent" }}
                                >
                                  {item?.statement ?? ""}
                                </RecommendationListItem>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Box>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>
                  {isLoading ? "Loading..." : "No Data Found"}
                </Typography>
              )}
            </RecommendationContainer>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
