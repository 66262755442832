import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

export default function ChangeVisualizationTab({
  showModal,
  confirmFn,
  closeFn,
}) {
  function handleClose() {
    closeFn();
  }

  function chnageTab() {
    confirmFn();
  }
  return (
    <AlertDialog open={showModal} onOpenChange={handleClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Change Visualization Tab?</AlertDialogTitle>
          <AlertDialogDescription className="flex flex-col gap-4">
            You have selected more than 6 rows in the table. Switching the tab
            will reset the selected rows. Are you sure you want to continue ?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => chnageTab()}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
