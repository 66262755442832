import { useEffect, useState } from "react";

import { useGetSpecificPrediction } from "apis/predictionApi";

import { useParams } from "react-router-dom";

export default function usePrediction() {
  const { reportableId = "" } = useParams();

  const { data: specificPrediction } = useGetSpecificPrediction(reportableId);
  const [predictionStep, setPredictionStep] = useState(null);

  useEffect(() => {
    const status = specificPrediction?.status;
    if (reportableId && status) {
      setPredictionStep(status);
    } else {
      if (predictionStep !== 1) {
        setPredictionStep(1);
      }
    }
  }, [specificPrediction, reportableId, predictionStep]);

  return {
    predictionStep,
  };
}
