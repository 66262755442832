import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Link, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { Chip, TableHead, Tooltip } from "@mui/material";
import { convertToTitleCase } from "utils/helper";
import TableLoader from "components/common/loaders/TableLoader";
import { REPORTABLE_TYPE } from "utils/constants";
import EmptyData from "components/common/empty/EmptyData";
import { toast } from "react-toastify";
import { BookOpen, ClipboardCopy, Eye, EyeOff } from "lucide-react";
import { twJoin } from "tailwind-merge";

const columns = [
  { id: "name", label: "Name" },
  { id: "orgName", label: "Organization Name" },
  { id: "type", label: "Type", align: "left" },
  {
    id: "actions",
    label: "Actions",
    align: "left",
  },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function ReportTable({
  rows,
  isReportLoading,
  isViewBookmark,
  HandleChangeVisibility,
}) {
  const navigate = useNavigate();

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: `calc(100vh - 155px)`,
      }}
      className="content-container"
    >
      <Table stickyHeader aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => {
              if (!isViewBookmark && column.id === "orgName") return null;

              return (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{
                    fontSize: "1rem",
                    backgroundColor: "#eee",
                  }}
                >
                  {column.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length > 0 &&
            rows.map((row, index) => {
              let viewRedirect = "";
              const {
                name,
                reportableType,
                reportableId,
                isPublic,
                organizationId,
                organizationName,
                url,
                icon,
              } = row;

              if (reportableType === REPORTABLE_TYPE.INSIGHTS) {
                viewRedirect = APP_ROUTES.INSIGHTS.default + `/${reportableId}`;
              } else if (reportableType === REPORTABLE_TYPE.RECOMMENDATIONS) {
                viewRedirect = APP_ROUTES.RECOMMENDATION.REPORT_DETAIL.replace(
                  ":reportableId",
                  reportableId
                );

                if (row?.meta) {
                  const isVideoStage = JSON.parse(row.meta)?.isVideoStage;
                  if (isVideoStage) {
                    viewRedirect = viewRedirect.replace(
                      ":recommendationType",
                      "videoIntelligence"
                    );
                  } else {
                    viewRedirect = viewRedirect.replace(
                      ":recommendationType",
                      "creativeFeature"
                    );
                  }
                }
              } else if (reportableType === REPORTABLE_TYPE.PREDICTIONS) {
                viewRedirect =
                  APP_ROUTES.PREDICTION.PREDICTION_REPORT_DETAILS.replace(
                    ":reportableId",
                    reportableId
                  );
              } else if (reportableType === REPORTABLE_TYPE.COMPARE) {
                viewRedirect = APP_ROUTES.INSIGHTS.COMAPRE_REPORT.replace(
                  ":reportId",
                  reportableId
                );
              } else if (
                reportableType === REPORTABLE_TYPE.COMPARE_MULTIPLE_DATES
              ) {
                viewRedirect = APP_ROUTES.INSIGHTS.COMPARE_DATE_REPORT.replace(
                  ":reportId",
                  reportableId
                );
              } else if (reportableType === REPORTABLE_TYPE.AD_BREAKDOWN) {
                viewRedirect = APP_ROUTES.INSIGHTS.AD_BREAKDOWN_REPORT.replace(
                  ":reportId",
                  reportableId
                );
              } else if (reportableType === REPORTABLE_TYPE.CREATIVE_INSIGHTS) {
                viewRedirect =
                  APP_ROUTES.INSIGHTS.CREATIVE_INSIGHT_REPORT.replace(
                    ":reportId",
                    reportableId
                  );
              } else if (
                reportableType === REPORTABLE_TYPE.CREATIVE_MOMENTUM_FATIGUE
              ) {
                viewRedirect =
                  APP_ROUTES.INSIGHTS.CREATIVE_MOMENTUM_FATIGUE_REPORT.replace(
                    ":reportId",
                    reportableId
                  );
              }
              return (
                <TableRow
                  key={`${row.reportableId}-${index}`}
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(
                      isViewBookmark
                        ? viewRedirect + `?orgId=${organizationId}`
                        : viewRedirect
                    );
                  }}
                  hover
                >
                  <TableCell component="th" scope="row">
                    {!icon ? (
                      <img
                        className="w-4 h-4 mr-2 inline"
                        src="/meta.png"
                        alt=""
                      />
                    ) : (
                      <p className="text-sm mr-2 inline">{icon}</p>
                    )}
                    {name}
                    {!isPublic && (
                      <span class="ml-2 border border-blue-500 bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">
                        Private
                      </span>
                    )}
                  </TableCell>
                  {isViewBookmark && (
                    <TableCell component="th" scope="row">
                      {organizationName || "NA"}
                    </TableCell>
                  )}
                  <TableCell align="left">
                    <Chip
                      label={convertToTitleCase(reportableType)}
                      variant="outlined"
                    />
                  </TableCell>

                  <TableCell align="left">
                    <div class="inline-flex rounded-md shadow-sm" role="group">
                      {isPublic ? (
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(url);
                            toast.success("Report url copied to clipboard");
                          }}
                          class={twJoin(
                            "pr-2 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200  hover:bg-gray-100 hover:text-blue-700",
                            isViewBookmark ? "rounded-lg" : "rounded-s-lg"
                          )}
                        >
                          <Tooltip title="Copy report URl">
                            <ClipboardCopy className="inline ml-2" size={18} />
                          </Tooltip>
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              isViewBookmark
                                ? viewRedirect + `?orgId=${organizationId}`
                                : viewRedirect
                            );
                          }}
                          class={twJoin(
                            "pr-2 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200  hover:bg-gray-100 hover:text-blue-700",
                            isViewBookmark ? "rounded-lg" : "rounded-s-lg"
                          )}
                        >
                          <Tooltip title="Open Report">
                            <BookOpen className="inline ml-2" size={18} />
                          </Tooltip>
                        </button>
                      )}

                      {!isViewBookmark && (
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            HandleChangeVisibility(reportableId, !isPublic);
                          }}
                          class="px-2 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700"
                        >
                          <Tooltip title="Toggle Visibility">
                            {isPublic ? (
                              <Eye size={18} />
                            ) : (
                              <EyeOff size={18} />
                            )}
                          </Tooltip>
                        </button>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}

          {isReportLoading && <TableLoader rowsNum={isViewBookmark ? 4 : 3} />}
        </TableBody>
      </Table>
      {!isReportLoading && rows?.length === 0 && (
        <div className="flex items-center justify-center w-full">
          <EmptyData title="No Reports Found" customClass={`my-24 `} />
        </div>
      )}
    </TableContainer>
  );
}

export default ReportTable;
