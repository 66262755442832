import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const todaysDate = moment().format("YYYY-MM-DD");

const initialCompareState = {
  filterAndOptions: [],
  selectedMetrices: {
    type: "kpis",
    value: [],
    mode: 1,
  },
  date: { selectionType: null, startDate: todaysDate, endDate: todaysDate },
  isReportPublic: false,
  payload: [],
  kpiFilters: [],
  groupBy: {
    label: "Ad Name",
    value: "adName",
  },
  preset: null,
  filter: [
    {
      groupName: null,
      filters: [
        {
          startDate: moment(new Date()).format("YYYY-MM-DD"),
          endDate: moment(new Date()).format("YYYY-MM-DD"),
          selectionType: null,
        },
      ],
    },
    {
      groupName: null,
      filters: [
        {
          startDate: moment(new Date()).format("YYYY-MM-DD"),
          endDate: moment(new Date()).format("YYYY-MM-DD"),
          selectionType: null,
        },
      ],
    },
  ],
  adAccountIds: [],
  additionalFilterSet: [],
};

const compareDatesFiltersSlice = createSlice({
  name: "compareDatefilters",
  initialState: initialCompareState,
  reducers: {
    setAllCompare: (state, action) => {
      return { ...state, ...action.payload };
    },
    setDate: (state, action) => {
      return {
        ...state,
        date: {
          ...action.payload,
          selectionType: parseInt(action?.payload?.selectionType) || 4,
        },
      };
    },
    setIsReportPublic: (state, action) => {
      return {
        ...state,
        isReportPublic: action.payload,
      };
    },
    addExtraFilter: (state, action) => {
      return {
        ...state,
        extraFilters: [
          ...state?.extraFilters,
          {
            type: 1,
            condition: 1,
            tags: [],
            aggregator: state?.extraFiltersAggregator,
          },
        ],
      };
    },
    removeExtraFilterField: (state, action) => {
      return {
        ...state,
        extraFilters: [
          ...state?.extraFilters?.filter(
            (elem, idx) => idx !== action.payload.index
          ),
        ],
      };
    },
    updateExtraFilterFieldSelect: (state, action) => {
      const { index, key, value } = action.payload;
      const updatedExtraFilters = [...state.extraFilters];
      updatedExtraFilters[index] = {
        ...updatedExtraFilters[index],
        [key]: value,
      };
      return {
        ...state,
        extraFilters: updatedExtraFilters,
      };
    },
    updateExtraFilterAggregateLogic: (state, action) => {
      state.extraFiltersAggregator = action.payload.logic;
      state.extraFilters = [
        {
          type: 1,
          condition: 1,
          tags: [],
          aggregator: action.payload.logic,
        },
      ];
    },
    initExtraFilters: (state, action) => {
      state.extraFilters = initialCompareState?.extraFilters;
    },
    setExtraFilters: (state, action) => {
      state.extraFilters = action.payload;
    },
    setPayload: (state, action) => {
      state.payload = action.payload;
    },
    setKpiFilters: (state, action) => {
      state.kpiFilters = action.payload;
    },
    setSelectedMetrices: (state, action) => {
      state.selectedMetrices = action.payload;
    },
    setGroupBy: (state, action) => {
      state.groupBy = action.payload;
    },
    setPreset: (state, action) => {
      state.preset = action.payload;
    },
    setFilterSet: (state, action) => {
      state.filter = action.payload;
    },
    setFilterSet2: (state, action) => {
      state.filter = action.payload;
    },
    setInitGroups: (state, action) => {
      state.filter = initialCompareState.filter;
    },
    setAdditionalFilters: (state, action) => {
      state.additionalFilterSet = action.payload;
    },
    setAdAccountIds: (state, action) => {
      state.adAccountIds = action.payload;
    },
    setDateNew: (state, action) => {
      state.date = action.payload;
    },
  },
});

export const {
  setAllCompare,
  setGroupBy,
  setKpiFilters,
  setPayload,
  setSelectedMetrices,
  setDate,
  setIsReportPublic,
  addExtraFilter,
  updateExtraFilterFieldSelect,
  removeExtraFilterField,
  updateExtraFilterAggregateLogic,
  initExtraFilters,
  setExtraFilters,
  setPreset,
  setFilterSet,
  setAdAccountIds,
  setDateNew,
  setFilterSet2,
  setAdditionalFilters,
  setInitGroups,
} = compareDatesFiltersSlice.actions;

export default compareDatesFiltersSlice.reducer;
