import {
  Button,
  Dialog,
  DialogActions,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { modalInitState, setModal } from "../../../redux/slices/modal.slices";
import { InputWithLabel } from "components/common/molecules";
import ForwardIcon from "@mui/icons-material/Forward";

export default function BasicActionsModal(props) {
  const { modalType, primaryAction, secondaryAction } = props;
  const { open, type, payload } = useSelector((state) => state?.modal);
  const {
    action_sure_msg,
    action_warning_msg,
    primaryBtnText = "Remove",
    secondaryBtnText = "Cancel",
    dangerMode = false,
    image,
    primaryActionPayload,
    groupName,
    url,
    groupThumbnailUrl,
  } = payload || {};
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [grpName, setGrpName] = useState(groupName);

  const dispatch = useDispatch();

  function handleClose() {
    setGrpName(null);
    dispatch(setModal(modalInitState));
  }
  function handlePrimaryAction() {
    setGrpName(null);
    handleClose();
    primaryAction &&
      primaryAction({ ...primaryActionPayload, groupName: grpName });
  }

  function handleSecondaryAction() {
    setGrpName(null);
    handleClose();
    secondaryAction && secondaryAction();
  }
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open && modalType === type}
      aria-labelledby="responsive-dialog-title"
    >
      <div
        style={{
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
        }}
      >
        {url && groupThumbnailUrl && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src={url} height={150} width={150} />
              <ForwardIcon
                verticalAlign="middle"
                style={{ marginTop: 60, width: 50, height: 50 }}
              />
              <img src={groupThumbnailUrl} height={150} width={150} />
            </div>
            <Divider />
          </>
        )}

        <div style={{ display: "flex", gap: "5%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexBasis: "30%",
              flexGrow: "1",
              flexShrink: "1",
            }}
          >
            <img src={image} alt="remove_img" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexBasis: " 65%",
              flexGrow: "1",
              flexShrink: "1",
              gap: "12px",
              justifyContent: "center",
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: "400" }}>
              {action_sure_msg}
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontSize: "16px", fontWeight: "700", color: "#1C1E20" }}
            >
              {action_warning_msg}
            </Typography>
          </div>
        </div>
        {groupName && (
          <Stack
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexBasis: "30%",
              flexGrow: "1",
              flexShrink: "1",
            }}
          >
            <InputWithLabel
              type="text"
              defaultValue={groupName}
              placeholder="Enter Group Name"
              label="Do want to change Group Name?"
              onChange={(e) => {
                setGrpName(e.target.value);
              }}
            />
          </Stack>
        )}

        <DialogActions
          style={{ display: "flex", padding: "16px 24px 0 16px", gap: "40px" }}
        >
          <Button
            sx={{
              textTransform: "capitalize",
              fontWeight: "500",
            }}
            variant="text"
            color={dangerMode ? "error" : "primary"}
            autoFocus
            onClick={handleSecondaryAction}
          >
            {secondaryBtnText}
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              textTransform: "capitalize",
              fontWeight: "400",
            }}
            color={dangerMode ? "error" : "primary"}
            onClick={handlePrimaryAction}
            autoFocus
          >
            {primaryBtnText}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
