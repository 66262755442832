import React from "react";
import { twJoin } from "tailwind-merge";

export default function TWTooltip({
  message,
  children,
  tooltipCondition = true,
  customClass = "",
}) {
  return (
    <div className="group relative flex break-all">
      {children}
      {tooltipCondition && (
        <span
          className={twJoin(
            "absolute top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 z-50",
            customClass
          )}
        >
          {message}
        </span>
      )}
    </div>
  );
}
