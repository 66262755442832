import React, { useState } from "react";
import PrivateContainer from "components/layouts/PrivateContainer";
import {
  Button,
  Typography,
  Stack,
  IconButton,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  Tooltip,
} from "@mui/material";
import { ContentContainer, MainContainer } from "components/layouts";
import { DateFilter, TitleBar } from "components/common/molecules";
import { BlurredBgBtnWIcon } from "components/common/atoms";
import { APP_ROUTES } from "routes";
import {
  // DownloadForOfflineOutlined,
  // ShareOutlined,
  ArrowBackIosOutlined,
  PlayCircleOutline,
  Image,
} from "@mui/icons-material";
import {
  PredictionReportCardModal,
  PredictionReportDetailList,
} from "../Components";
import { MODALTYPES } from "redux/slices/modal.slices";
import { useDispatch, useSelector } from "react-redux";
import usePredictionReportDetails from "./usePredictionReportDetails";
import { useNavigate } from "react-router-dom";
import { DateFilterOptions } from "components/common/molecules/DateFilter";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import SEO from "utils/SEO";
import ReactPlayer from "react-player";
import { storageUrlToCdn } from "utils/helper";
import {
  MessageHeaderConfiguration,
  MessagesStyle,
} from "@cometchat/uikit-shared";
import { ListItemStyle } from "@cometchat/uikit-elements";
import { CometChatMessages } from "@cometchat/chat-uikit-react";
import { ChatIcon } from "assets/icons/react";
import StorytellerLogoLoader from "components/common/loaders/StorytellerLogoLoader";

const GrayHeader = ({ children }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ padding: "16px", background: "#F9F9F9", width: "100%" }}
    >
      {children}
    </Stack>
  );
};

function PredictionReportDetails() {
  const truncateUtil = (text, textCharLimit) => {
    if (text?.length > textCharLimit) {
      const truncatedText = text?.slice(0, textCharLimit);
      return `${truncatedText}...`;
    }
    return text;
  };
  const dispatch = useDispatch();
  const { open, type } = useSelector((state) => state?.modal);
  const {
    report,
    predictionMedias,
    meta,
    reportMeta,
    hideSidebar,
    disableFilters,
    currentReportOrgId,
    isBookmarkSuccess,
    reportableId,
    currentGroup,
    loadingReport,
    handleRemoveBookmark,
    handleBookmarkReport,
  } = usePredictionReportDetails();
  const [modalData, setModalData] = useState();
  function getFormattedDate(dataValue) {
    const date = new Date(dataValue);
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  const filters = [
    `Objectives: ${report?.objectives ? report?.objectives?.join(", ") : ""}`,
    `Optimization Goals: ${
      report?.optimizationGoals ? report?.optimizationGoals?.join(", ") : ""
    }`,
    `Funnel Stages: ${
      report?.funnelStages ? report?.funnelStages?.join(", ") : ""
    }`,
    `Creative Types: ${
      report?.creativeTypes ? report?.creativeTypes?.join(", ") : ""
    }`,
    `Targeting Locations: ${
      report?.targetingCountries ? report?.targetingCountries?.join(", ") : ""
    }`,
    `Ad Account Ids: ${
      report?.adAccountIds ? report?.adAccountIds?.join(", ") : ""
    }`,
    `Threshold Value: ${reportMeta?.thresholdValue ?? ""}`,
  ];
  const navigate = useNavigate();
  const TotalCreatives =
    meta?.length ?? 0 > 1
      ? `(${meta?.length} Creatives)`
      : `(${meta?.length} Creative)`;
  const { bookmarkedReports, userBookmarkReports } = useSelector(
    (state) => state?.app
  );
  const lStyle = new ListItemStyle({
    background: "transparent",
    visibility: "hidden",
  });

  const mhConfig = new MessageHeaderConfiguration({
    listItemStyle: lStyle,
    display: "none",
  });

  const hasWindow = typeof window !== "undefined";

  const mStyle = new MessagesStyle({
    width: "390px",
    height: hasWindow ? window.innerHeight - 20 : "760px",
    overflowY: "scroll",
    display: "flex",
    justifyContent: "right",
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    setIsDrawerOpen((prev) => !prev);
  };
  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
        maxHeight: "100vh",
      }}
      activePage={APP_ROUTES.PREDICTION.default}
      hideSidebar={hideSidebar}
    >
      <SEO
        title="Prediction Report"
        description="Creative's prediction report powered by Storyteller"
        name="Storyteller"
        type="Report"
      />
      {loadingReport && reportableId ? (
        <StorytellerLogoLoader />
      ) : (
        <Stack direction={"row"} width={"100%"} spacing={1}>
          {/* <Tooltip title="Open Chat" placement="left">
          <IconButton
            size="large"
            disabled={disableFilters}
            sx={{
              position: "fixed",
              bottom: 20,
              right: 20,
              zIndex: 1000,
              padding: "7px",
              height: "70px",
              width: "70px",
              backgroundColor: "rgb(113, 103, 251)",
              boxShadow: "0 0 15px 0px #00000080",
              color: "#fff",
              "&:hover": {
                backgroundColor: "rgb(113, 103, 251)",
              },
            }}
            onClick={handleDrawerOpen}
          >
            <ChatIcon />
          </IconButton>
        </Tooltip> */}
          <Stack
            direction="column"
            spacing={1}
            width={isDrawerOpen ? "70%" : "100%"}
          >
            <ContentContainer
              containerStyles={{
                maxHeight: `calc(100vh - 14px)`,
                width: "100%",
                marginTop: "0",
              }}
            >
              <Stack
                direction="column"
                alignItems="center"
                sx={{ height: "100%", width: "100%" }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-around"
                  sx={{
                    width: "100%",
                  }}
                >
                  <TitleBar title="Prediction" subtitle="Storyteller.Ai" />
                  {hideSidebar && (
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        height: "36px",
                        minWidth: "7%",
                      }}
                      onClick={() => navigate(APP_ROUTES.AUTH.LOGIN)}
                    >
                      Sign In
                    </Button>
                  )}
                </Stack>
                <MainContainer
                  header={
                    <GrayHeader>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: "100%" }}
                      >
                        <Stack direction="row" alignItems="center">
                          <IconButton
                            onClick={() =>
                              navigate(APP_ROUTES.PREDICTION.default)
                            }
                          >
                            <ArrowBackIosOutlined
                              sx={{ fontSize: "18px", color: "#1C1E20" }}
                            />
                          </IconButton>
                          <Typography sx={{ fontSize: "24px" }}>
                            {`${report?.name ?? ""} ${TotalCreatives} `}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          sx={{
                            display: hideSidebar ? "none" : "",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "capitalize",
                              height: "36px",
                              fontSize: "auto",
                              display:
                                hideSidebar || !reportableId ? "none" : "",
                            }}
                            onClick={
                              isBookmarkSuccess ||
                              bookmarkedReports.includes(reportableId) ||
                              userBookmarkReports.includes(reportableId)
                                ? handleRemoveBookmark
                                : handleBookmarkReport
                            }
                          >
                            {isBookmarkSuccess ||
                            bookmarkedReports?.includes(reportableId) ||
                            userBookmarkReports.includes(reportableId) ? (
                              <BookmarkAddedIcon />
                            ) : (
                              <BookmarkBorderIcon />
                            )}
                          </Button>
                        </Stack>
                        {/* <Stack direction="row">
                    <IconButton sx={{ color: "#1C1E20" }}>
                      <DownloadForOfflineOutlined />
                    </IconButton>
                    <IconButton sx={{ color: "#1C1E20" }}>
                      <ShareOutlined />
                    </IconButton>
                  </Stack> */}
                      </Stack>
                    </GrayHeader>
                  }
                >
                  <Stack
                    direction="column"
                    sx={{
                      padding: "8px",
                      backgroundColor: "#F9F9F9",
                      borderRadius: "8px",
                    }}
                  >
                    <PredictionReportDetailList
                      title="Prediction done on"
                      value={
                        report?.updatedAt?.value
                          ? getFormattedDate(report?.updatedAt?.value)
                          : ""
                      }
                    />
                    <PredictionReportDetailList
                      title="Filter selected"
                      value={filters ?? []}
                    />
                    <PredictionReportDetailList
                      title="KPI’s selected"
                      value={report?.kpis ?? []}
                    />
                    {meta?.length > 0 && (
                      <>
                        <PredictionReportDetailList
                          title="Based On model trained on date"
                          value={
                            meta[0]?.model_meta?.model_last_trained_timestamp
                              ? getFormattedDate(
                                  meta[0]?.model_meta
                                    ?.model_last_trained_timestamp
                                )
                              : ""
                          }
                        />
                        <PredictionReportDetailList
                          title="Most impactful feature of the model"
                          value={
                            meta[0]?.model_meta?.model_last_trained_timestamp
                              ? getFormattedDate(
                                  meta[0]?.model_meta
                                    ?.model_last_trained_timestamp
                                )
                              : ""
                          }
                        />
                        <PredictionReportDetailList
                          title="Date"
                          child={
                            <span style={{ marginTop: -5 }}>
                              <DateFilter
                                options={DateFilterOptions}
                                value={report?.date}
                                disabled
                              />
                            </span>
                          }
                        />
                      </>
                    )}
                  </Stack>
                  <ContentContainer
                    containerStyles={{
                      padding: "32px 4px",
                      minWidth: "100%",
                      marginTop: "0",
                    }}
                  >
                    <TableContainer className="content-container">
                      <Table
                        stickyHeader
                        aria-label="simple table"
                        sx={{ padding: 0 }}
                      >
                        <TableHead sx={{}}>
                          <TableCell
                            style={{
                              fontSize: "18px",
                              backgroundColor: "#eee",
                              textAlign: "start",
                              width: "35%",
                            }}
                          >
                            Media Asset
                          </TableCell>
                          <TableCell
                            style={{
                              width: "25%",
                              fontSize: "18px",
                              backgroundColor: "#eee",
                              textAlign: "start",
                            }}
                          >
                            Stats
                          </TableCell>
                          <TableCell
                            style={{
                              width: "40%",
                              textAlign: "start",
                              fontSize: "18px",
                              backgroundColor: "#eee",
                            }}
                          >
                            Recommendations
                          </TableCell>
                        </TableHead>
                        {predictionMedias?.map((item, idx) => {
                          let type = item?.type === 2 ? "IMAGE" : "VIDEO";
                          let props = meta[idx]
                            ? {
                                prediction: meta[idx]?.prediction,
                                statement: meta[idx]?.statement,
                                confidence: meta[idx]?.confidence,
                              }
                            : {
                                prediction: "",
                                confidence: "",
                                statement: "",
                              };
                          const statements = props?.statement
                            .split(/\d+\./)
                            .filter(Boolean);
                          return (
                            <TableRow
                              sx={{
                                ":hover": { background: "#F9F9F9" },
                                minHeight: "100px",
                              }}
                            >
                              <TableCell
                                sx={{
                                  width: "35%",
                                  maxHeight: "100px",
                                }}
                              >
                                {type === "IMAGE" ? (
                                  <img
                                    src={storageUrlToCdn(item?.slug)}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "4px",
                                      objectFit: "cover",
                                    }}
                                    alt="thumbnail"
                                  />
                                ) : (
                                  <ReactPlayer
                                    url={storageUrlToCdn(item?.slug)}
                                    className="react-player"
                                    width="100%"
                                    controls={true}
                                    playing={false}
                                    height="100%"
                                  />
                                )}
                                <BlurredBgBtnWIcon
                                  Icon={
                                    type === "IMAGE" ? (
                                      <Image sx={{ fontSize: "20px" }} />
                                    ) : (
                                      <PlayCircleOutline
                                        sx={{ fontSize: "20px" }}
                                      />
                                    )
                                  }
                                  sx={{ top: "8px", left: "8px" }}
                                  title={type}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  width: "25%",
                                }}
                              >
                                <Stack
                                  direction="column"
                                  spacing={1}
                                  sx={{
                                    borderRadius: "10px",
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#1C1E20",
                                      }}
                                    >
                                      Predicted {report?.kpis[0]}:
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#1C1E20",
                                        background:
                                          +props.prediction < 1
                                            ? "lightpink"
                                            : +props.prediction === 1
                                            ? "lightgreen"
                                            : "lightyellow",
                                        padding: "4px 8px",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      {+props.prediction < 1 ? (
                                        <span
                                          style={{
                                            color: "darkred",
                                            fontWeight: 600,
                                          }}
                                        >
                                          Looser
                                        </span>
                                      ) : +props.prediction === 1 ? (
                                        <span
                                          style={{
                                            color: "darkgreen",
                                            fontWeight: 600,
                                          }}
                                        >
                                          Winner
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "darkorange",
                                            fontWeight: 600,
                                          }}
                                        >
                                          Unexplored
                                        </span>
                                      )}
                                    </Typography>
                                  </Stack>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#1C1E20",
                                      }}
                                    >
                                      Confidence:
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#1C1E20",
                                        background: "#F9F9F9",
                                        padding: "4px 8px",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      {props.confidence}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </TableCell>
                              <TableCell
                                sx={{
                                  width: "40%",
                                }}
                              >
                                <Stack
                                  sx={{
                                    borderRadius: "10px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "15px", color: "#1C1E20" }}
                                  >
                                    <span style={{ color: "#7167FB" }}>
                                      Optimization Tips:{" "}
                                    </span>
                                    {statements?.map((curr, index) => {
                                      return (
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            color: "#1C1E20",
                                          }}
                                        >
                                          {index + 1}: {curr}{" "}
                                        </Typography>
                                      );
                                    })}
                                  </Typography>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                    </TableContainer>
                  </ContentContainer>
                </MainContainer>
                {open && type === MODALTYPES.PREDICTION_REPORT_CARD_MODAL && (
                  <PredictionReportCardModal {...modalData} />
                )}
              </Stack>
            </ContentContainer>
          </Stack>
          <Stack
            width={isDrawerOpen ? "30%" : "0%"}
            alignItems={"center"}
            justifyContent={"right"}
            sx={{
              maxHeight: "calc(100vh - 9rem)",
              position: "sticky",
              top: "0",
              right: "0",
            }}
          >
            <Stack
              direction="column"
              sx={{
                width: "390px",
              }}
            >
              {currentGroup && isDrawerOpen ? (
                <Stack width={"90%"}>
                  <CometChatMessages
                    group={currentGroup}
                    messagesStyle={mStyle}
                    messageHeaderConfiguration={mhConfig}
                  />
                </Stack>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      )}
    </PrivateContainer>
  );
}

export default PredictionReportDetails;
