const DashboardIcon = ({ isActive }) => {
  const color = isActive ? "#7167FB" : "#858383";
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="3" width="5" height="5" rx="1" fill={color} />
      <rect x="12" y="3" width="5" height="5" rx="1" fill={color} />
      <rect x="2" y="13" width="5" height="5" rx="1" fill={color} />
      <rect x="12" y="13" width="5" height="5" rx="1" fill={color} />
      <rect x="7" y="8" width="5" height="5" rx="1" fill={color} />
      <rect x="17" y="8" width="5" height="5" rx="1" fill={color} />
    </svg>
  );
};
export default DashboardIcon;
