import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { merged_squares } from "../../../../assets/icons/svg";
import { add_tags } from "../../../../assets/images";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "../../../../redux/slices/modal.slices";
import useAxios from "../../../../hooks/useAxios";
import { APP_ROUTES, API_ROUTES } from "../../../../routes";
import useInfiniteScroll from "../../../../hooks/useInfiniteScroll";
import { GROUP_ENTITY } from "../../../../utils/constants";
import { toast } from "react-toastify";
import useAppState from "../../../../hooks/useAppState";

export default function useImageTab() {
  const [isMerging, setIsMerging] = React.useState(false);
  const [groups, setGroups] = React.useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { axiosOrgInstance } = useAxios();
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const { isLoadMore } = useInfiniteScroll("content-container", hasNextPage);
  const [page, setPage] = React.useState(1);
  const [totalDocs, setTotalDocs] = React.useState(0);
  const { updateAppState } = useAppState();

  function toggleMerging() {
    if (isMerging) {
      initGroups();
    }
    setIsMerging((prev) => !prev);
  }

  function handleSelect(id) {
    let _groups = groups?.map((group) => {
      if (group._id === id) {
        return {
          ...group,
          isSelected: !group.isSelected,
        };
      }
      return group;
    });
    setGroups(_groups);
  }

  function handleMerge() {
    //filter selected groups
    let _groups = groups?.filter((group) => group.isSelected);
    dispatch(
      setModal({
        type: MODALTYPES.BASIC_ACTION.MERGE_GROUPS,
        open: true,
        payload: {
          action_sure_msg: `Do You Want To Merge These ${_groups?.length} Groups?`,
          action_warning_msg: "This Action Cannot Be Undone",
          primaryBtnText: "Merge",
          secondaryBtnText: "Cancel",
          dangerMode: false,
          image: merged_squares,
        },
      })
    );
  }

  function mergeModalPrimaryAction() {
    let _groups = groups?.filter((group) => group.isSelected);
    axiosOrgInstance
      .patch(API_ROUTES.ONBOARDING.MERGE_GROUPS, {
        groupIds: _groups?.map((group) => group._id),
      })
      .then((res) => {
        initGroups();
        setIsMerging(false);
      });
  }

  function handleSkipUpdate() {
    dispatch(
      setModal({
        type: MODALTYPES.BASIC_ACTION.ADD_SYS_GENERATED_TAGS,
        open: true,
        payload: {
          action_sure_msg: `Do You Want To Add System Generated Tags?`,
          action_warning_msg:
            "In This AI Automatically Adds Tags In Your Creative Groups",
          primaryBtnText: "Yes",
          secondaryBtnText: "Cancel",
          dangerMode: false,
          image: add_tags,
        },
      })
    );
  }

  function addSysGeneratedTagsModalPrimaryAction() {
    axiosOrgInstance
      .post(API_ROUTES.ONBOARDING.SKIP_MANUAL_GROUPING, {
        groupEntity: GROUP_ENTITY.IMAGE,
      })
      .then((res) => {
        toast.success("System Generated Tags Added Successfully");
        navigate(APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS);
        initGroups();
      });
  }

  function addSysGeneratedTagsModalSecondaryAction() {
    dispatch(
      setModal({
        open: false,
      })
    );
  }

  function initGroups() {
    const container = document.querySelector(`.content-container`);
    const currentScrollPosition = container ? container.scrollTop : 0;
    axiosOrgInstance
      .get(API_ROUTES.ONBOARDING.LIST_ORG_GROUPS, {
        params: {
          groupEntity: GROUP_ENTITY.IMAGE,
        },
      })
      .then((res) => {
        setTotalDocs(res?.totalDocs);

        setHasNextPage(res?.hasNextPage);
        setPage(res?.page);
        let _groups = res?.docs?.map((group) => {
          return {
            ...group,
            isSelected: false,
          };
        });
        setGroups(_groups);
        container.scrollTop = currentScrollPosition;
        if (res?.totalDocs === 0) {
          updateAppState();
        }
      });
  }

  useEffect(() => {
    initGroups();
    return () => dispatch(setModal(modalInitState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoadMore && hasNextPage) {
      const container = document.querySelector(`.content-container`);
      const currentScrollPosition = container ? container.scrollTop : 0;
      axiosOrgInstance
        .get(API_ROUTES.ONBOARDING.LIST_ORG_GROUPS, {
          params: {
            groupEntity: GROUP_ENTITY.IMAGE,
            page: page + 1,
          },
        })
        .then((res) => {
          setHasNextPage(res?.hasNextPage);
          setPage(res?.page);
          let _groups = res?.docs?.map((group) => {
            return {
              ...group,
              isSelected: false,
            };
          });
          container.scrollTop = currentScrollPosition;
          setGroups((prev) => [...prev, ..._groups]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadMore]);

  return {
    isMerging,
    toggleMerging,
    groups,
    handleSelect,
    handleMerge,
    handleSkipUpdate,
    addSysGeneratedTagsModalPrimaryAction,
    addSysGeneratedTagsModalSecondaryAction,
    initGroups,
    mergeModalPrimaryAction,
    totalDocs,
  };
}
