import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import axios from "axios";
import {
  ChevronDown,
  Download,
  Maximize2,
  PencilRuler,
  Tv,
  X,
  Loader2,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useAxios from "hooks/useAxios";
import { GET_GROUP_TAGS } from "apis/apiEndPoints";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import ReactPlayer from "react-player";

const PreviewMediaAseet = () => {
  const [mediaAsset, setMediaAsset] = useState();
  const [groupInfo, setGroupInfo] = useState();
  const [isPlaying, setPlaying] = useState(false);
  const [isGettingMediaAsset, setIsGettingMediaAsset] = useState(false);

  const { open, type, payload } = useSelector((state) => state?.modal);
  const { groupId, handleCreativeInsight } = payload || {};
  const playerRef = useRef();

  const dispatch = useDispatch();
  const { axiosOrgInstance } = useAxios();

  const getMediaAsset = (id) => {
    setIsGettingMediaAsset(true);
    axiosOrgInstance
      .get(GET_GROUP_TAGS.GET_GROUP_MEDIA_ASSET.replace(":groupId", groupId), {
        params: {
          limit: 1,
        },
      })
      .then((res) => {
        setIsGettingMediaAsset(false);
        setMediaAsset(res?.docs?.[0]);
        setGroupInfo(res?.meta?.group);
        return res?.docs?.[0];
      })
      .catch((err) => toast.error("Something went wrong"));
  };

  const [isDownloadingMediaAsset, setIsDownloadingMediaAsset] = useState(false);
  const handleDownload = async (url) => {
    try {
      setIsDownloadingMediaAsset(true);
      const response = await axios.get(url, {
        responseType: "blob",
      });
      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "creative" || "download");

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsDownloadingMediaAsset(false);
    } catch (error) {
      setIsDownloadingMediaAsset(false);
      console.error("Error downloading the file:", error);
    }
  };

  const handleEditTags = (type) => {
    const payload = {
      groupName: groupInfo.groupName,
      groupId: groupInfo._id,
    };
    if (type === "VIDEO") {
      payload.videoUrl = mediaAsset.videoUrl;
    } else {
      payload.imageUrl = mediaAsset.imageUrl;
    }
    dispatch(
      setModal({
        type:
          type === "IMAGE"
            ? MODALTYPES.IMAGE_TAGGING_MODAL
            : MODALTYPES.VIDEO_TAGGING_MODAL,
        open: true,
        payload,
      })
    );
  };

  useEffect(() => {
    if (!groupId) return;
    if (open && type === MODALTYPES.MEDIA_ASSET_PREVIEW) {
      getMediaAsset(groupId);
    }
  }, [open, payload]);

  return (
    <div
      className={`${
        open && type === MODALTYPES.MEDIA_ASSET_PREVIEW
          ? "flex flex-col"
          : "hidden"
      } w-[350px] p-2 z-[50] overflow-hidden shadow-md max-h-[520px] bg-white absolute bottom-0 right-3 border border-gray-200 rounded-t-xl`}
    >
      {isGettingMediaAsset ? (
        <div class="min-h-[250px] flex items-center flex-col justify-start space-y-4 mt-28">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="flex justify-between gap-x-2 items-center mb-3">
            <div className="w-full flex gap-x-2">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  {isDownloadingMediaAsset ? (
                    <Button className="px-2" variant="outline">
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      <ChevronDown className="w-4 h-4" />
                    </Button>
                  ) : (
                    <Button
                      // className="w-full bg-black flex justify-center"
                      className="px-2"
                      variant="outline"
                      // size="icon"
                    >
                      <Download className="w-4 h-4" />
                      <ChevronDown className="w-4 h-4" />
                    </Button>
                  )}
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56 ml-40">
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      className="flex gap-x-2 items-center"
                      onClick={() =>
                        handleDownload(
                          mediaAsset?.imageUrl || mediaAsset?.videoUrl
                        )
                      }
                    >
                      <Download className="h-4 w-4" />
                      <span>Download creative</span>
                    </DropdownMenuItem>
                    {mediaAsset?.videoUrl && (
                      <DropdownMenuItem
                        className="flex gap-x-2 items-center"
                        onClick={() => handleDownload(mediaAsset?.thumbnailUrl)}
                      >
                        <Tv className="h-4 w-4" />
                        <span>Download gif</span>
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
              <Button
                onClick={() => {
                  handleCreativeInsight(groupInfo?._id);
                }}
                variant="outline"
                className="flex items-center gap-x-2 text-xs"
              >
                <p>Creative insights</p>
                <Maximize2 className="w-4 h-4 text-gray-500" />
              </Button>
              <Button
                onClick={() =>
                  handleEditTags(mediaAsset?.imageUrl ? "IMAGE" : "VIDEO")
                }
                variant="outline"
                className="flex items-center gap-x-2 text-xs"
              >
                <p>Edit tags</p>
                <PencilRuler className="w-4 h-4 text-gray-500" />
              </Button>
            </div>
            <div>
              <X
                className="h-4 w-4 cursor-pointer"
                onClick={() => {
                  dispatch(setModal(modalInitState));
                  setPlaying(false);
                }}
              />
            </div>
          </div>
          <Separator className="w-full" />
          <div className="h-fit w-full overflow-hidden object-contain">
            {mediaAsset?.videoUrl ? (
              <>
                <ReactPlayer
                  ref={playerRef}
                  url={mediaAsset?.imageUrl || mediaAsset?.videoUrl}
                  controls={true}
                  className="rounded-2xl react-player h-fit w-full object-contain"
                  audio={true}
                  playing={isPlaying}
                  width={"100%"}
                  onPlay={() => setPlaying(true)}
                  onPause={() => setPlaying(false)}
                />
              </>
            ) : (
              <img
                className="h-fit w-fit object-contain"
                src={mediaAsset?.imageUrl}
                alt="Media asset"
                srcset=""
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PreviewMediaAseet;
