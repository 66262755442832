import React from "react";

export default function PredictionIcon({ isActive }) {
  const color = isActive ? "#7167FB" : "#858383";
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.438 4.188A.563.563 0 0 1 7 3.625h2.813a.562.562 0 0 1 .562.563V7A.563.563 0 0 1 9.25 7V5.545L6.273 8.523a.563.563 0 0 1-.796-.796L8.455 4.75H7a.563.563 0 0 1-.562-.563ZM2.65 2.154A2.814 2.814 0 0 1 5.313.25h5.625a2.812 2.812 0 0 1 2.812 2.813v5.624a2.816 2.816 0 0 1-1.903 2.663v.41c0 .377-.132.858-.441 1.255-.325.419-.842.735-1.55.735h-6.49A3.095 3.095 0 0 1 .25 10.634V4.146c0-.618.222-1.132.614-1.488.383-.348.883-.503 1.376-.503h.41v-.001ZM2.5 3.279h-.259a.911.911 0 0 0-.62.211c-.133.12-.246.32-.246.655v6.49c0 1.117.873 1.991 1.991 1.991h6.49c.337 0 .534-.136.662-.301a.997.997 0 0 0 .202-.565v-.26H5.313A2.812 2.812 0 0 1 2.5 8.687V3.28Zm2.813-1.904a1.687 1.687 0 0 0-1.688 1.688v5.624a1.687 1.687 0 0 0 1.688 1.688h5.625a1.687 1.687 0 0 0 1.687-1.688V3.064a1.687 1.687 0 0 0-1.687-1.688H5.313Z"
        fill={color}
      />
    </svg>
  );
}
