import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import React from "react";

const ModelKPIList = ({ isModelKPIsLoading, modelKPIs }) => {
  const columns = [
    { id: "category", label: "Category", align: "left", width: "1/6" },
    { id: "kpis", label: "KPI", align: "left", width: "1/6" },
    { id: "Description", label: "Description", width: "[60%]", align: "left" },
    { id: "isDefaultKPI", label: "Default KPI", width: "1/6", align: "left" },
  ];
  return (
    <Table>
      <TableCaption>
        {!modelKPIs?.length
          ? "Oops...No Model Trained For Your Organization,Kindly Subscribe"
          : "A list of your organization model-kpi"}
      </TableCaption>
      <TableHeader>
        <TableRow>
          {columns?.map((curr) => (
            <TableHead className={`w-${curr?.width}`}>{curr?.label}</TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {isModelKPIsLoading ? (
          <Skeleton className="h-4 w-screen" />
        ) : (
          modelKPIs?.map((curr, index) => (
            <TableRow key={index}>
              <TableCell className="font-medium">
                {curr?.kpi?.category}
              </TableCell>
              <TableCell>{curr?.kpi?.kpi}</TableCell>
              <TableCell>{curr?.kpi?.description}</TableCell>
              <TableCell className="text-right">
                {curr?.kpi?.isDefaultKPI === null || curr?.kpi?.isDefaultKPI
                  ? "YES"
                  : "NO"}
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default ModelKPIList;
