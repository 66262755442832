import React from "react";
import { PrivateContainer } from "../../../../components/layouts";
import styles from "./styles.module.css";

export default function LoadingScreen() {
  return (
    <PrivateContainer>
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>Onboarding in Progress</h1>
          <p className={styles.message}>
            Get a cup of coffee, while we club together <br />
            your best performing creatives with each other.
          </p>
          <img
            src={"/Relaxing.jpg"}
            alt="Cup of coffee"
            className={styles.image}
          />
        </div>
      </div>
    </PrivateContainer>
  );
}
