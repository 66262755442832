import useAxios from "hooks/useAxios";
import {
  AUTH,
  COMMON,
  CONTROL_PANEL,
  GET_GROUP_TAGS,
  ORGANISATION,
  PRESETS,
  REPORTS,
} from "./apiEndPoints";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { API_ROUTES } from "routes";
import { CACHE_TIME } from "utils/constants";
import { toast } from "react-toastify";
import {
  setOrganisationAdAccounts,
  setOrganisationFolders,
  setOrganisationTags,
  setSelectedOrganisation,
} from "redux/slices/organisation.slice";
import { setAppState } from "redux/slices/appState.slices";
import { modalInitState, setModal } from "redux/slices/modal.slices";
import { useNavigate } from "react-router-dom";

const twoMins = 2000 * 60;
const fiveMinutes = 5000 * 60;
const tenMinutes = 10000 * 60;
const useGetUploadUrls = () => {
  const { axiosOrgInstance } = useAxios();

  return useMutation((payload) => {
    return axiosOrgInstance.post(COMMON.GET_UPLOAD_URLS, payload);
  });
};

const useGetUserAppStatus = (enabled) => {
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const dispatch = useDispatch();
  const { axiosUserInstance } = useAxios();
  const queryKey = ["userAppStatus", selectedOrganisation?._id];

  return useQuery(
    queryKey,
    async () => {
      const res = await axiosUserInstance.get(COMMON.APPLICATION_STATUS);
      console.log(res, "<<<<<<USER APP STATUS");
      dispatch(setAppState(res));
      return res;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: fiveMinutes,
      enabled: false,
    }
  );
};

const useGetOrgAppStatus = (enabled) => {
  const dispatch = useDispatch();
  const { axiosOrgInstance } = useAxios();
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const queryKey = ["orgAppStatus", selectedOrganisation?._id];

  return useQuery(
    queryKey,
    async () => {
      const res = await axiosOrgInstance.get(COMMON.APPLICATION_STATUS);
      dispatch(setAppState(res));
      return res;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: fiveMinutes,
      enabled,
    }
  );
};

const useGetOrganisationList = (enabled) => {
  const { axiosUserInstance } = useAxios();
  const queryKey = ["organisationList"];

  return useInfiniteQuery(
    queryKey,
    async ({ pageParam = 1 }) => {
      const res = await axiosUserInstance.get(AUTH.LIST_ORG, {
        params: {
          page: pageParam,
          limit: 100,
        },
      });

      return res;
    },
    {
      getNextPageParam: (lastPage) => {
        const { hasNextPage, page } = lastPage;

        if (hasNextPage) {
          return page + 1;
        }
        return null;
      },
      staleTime: tenMinutes,
      enabled,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: tenMinutes,
      notifyOnChangeProps: "all",
    }
  );
};

const useGetAvailableCurrencies = (enabled) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["currency"];

  const data = useQuery(
    queryKey,
    () =>
      axiosOrgInstance.get(COMMON.GET_AVAILABLE_CURRENCIES).then((res) => {
        return Object.keys(JSON.parse(res.value));
      }),
    {
      retry: 0,
      staleTime: CACHE_TIME,
      refetchOnWindowFocus: false,
      enabled,
    }
  );
  return data;
};

const useRefreshOrganization = () => {
  const { axiosOrgInstance } = useAxios();
  return useMutation((payload) => {
    axiosOrgInstance
      .post(ORGANISATION.REFRESH_ORGANIZATION_CONNECTORS, payload)
      .then((res) => {
        toast("Refresh successfully initiated");
      })
      .catch((err) => console.log(err));
  });
};

const useUpdateDefaultCurrency = () => {
  const { axiosOrgInstance } = useAxios();
  return useMutation((payload) => {
    return axiosOrgInstance.patch(
      API_ROUTES.ORGANISATION.UPDATE_DEFAULT_CURRENCY.replace(
        ":id",
        payload?.orgId
      ).replace(":new", payload?.currency)
    );
  });
};

const useGetModelKPI = (enabled) => {
  const { axiosOrgInstance } = useAxios();
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const queryKey = ["modelKPI", selectedOrganisation?._id];
  const data = useQuery(
    queryKey,
    () =>
      axiosOrgInstance.get(API_ROUTES.COMMON.GET_ORG_MODEL_KPIS).then((res) => {
        return res;
      }),
    {
      retry: 0,
      staleTime: CACHE_TIME,
      cacheTime: CACHE_TIME,
      refetchOnWindowFocus: false,
      enabled,
    }
  );
  return data;
};
const useGetOrganizationTags = (enabled) => {
  const { axiosOrgInstance } = useAxios();
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const dispatch = useDispatch();
  const queryKey = ["tags", selectedOrganisation?._id];
  const data = useQuery(
    queryKey,
    function getOrganizationTags() {
      axiosOrgInstance
        .get(ORGANISATION.GET_ORGANIZATION_TAGS)
        .then((res) => {
          console.log(res, "TAGS RES");
          dispatch(setOrganisationTags(res));
        })
        .catch((err) => toast("Error fetching organization tags"));
    },
    {
      retry: 0,
      staleTime: CACHE_TIME,
      refetchOnWindowFocus: false,
      enabled,
    }
  );
  return data;
};

const useGetOrgAdAccounts = () => {
  const { axiosOrgInstance } = useAxios();
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const dispatch = useDispatch();
  return useMutation(() => {
    return axiosOrgInstance
      .post(ORGANISATION.ADD_ACCOUNTS, {
        organizationId: selectedOrganisation?._id,
      })
      .then((res) => {
        dispatch(setOrganisationAdAccounts(res));
      })
      .catch((err) =>
        toast("Error fetching organization connected ad-accounts")
      );
  });
};

const useChangeRole = () => {
  const { axiosOrgInstance } = useAxios();
  return useMutation((payload) => {
    return axiosOrgInstance
      .patch(
        ORGANISATION.CHANGE_USER_ROLE.replace(
          ":userId",
          payload?.userId
        ).replace(":type", payload?.type)
      )
      .then((res) => {
        payload?.refetchOrgUsers();
      })
      .catch((err) => toast("Error changing user role"));
  });
};

const useGetOrgFolders = (enabled) => {
  const dispatch = useDispatch();
  const { axiosOrgInstance } = useAxios();
  const { auth } = useSelector((state) => state);
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const isUserAuthenticated = !!auth?.userData?.tokens?.user?.token;
  const isOrganisationAuthenticated = !!selectedOrganisation?.token;
  const queryKey = ["folders"];

  const data = useQuery(
    queryKey,
    function getFolders() {
      if (!isUserAuthenticated || !isOrganisationAuthenticated) return null;
      return axiosOrgInstance
        .get(API_ROUTES.ORGANISATION.ORGANIZAITON_FOLDERS)
        .then((res) => {
          dispatch(setOrganisationFolders(res));
          return res;
        })
        .catch((err) => console.log(err, "FAILED TO GET FOLDERS"));
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
  return data;
};

const useGetReportMessage = (reportableId, reportOrgId) => {
  const queryKey = [reportableId, "message"];
  const { axiosUserInstance } = useAxios();
  const data = useQuery(
    queryKey,
    function getMessage() {
      return axiosUserInstance
        .get(REPORTS.GET_REPORT_MESSAGE.replace(":reportableId", reportableId))
        .then((res) => res)
        .catch((err) => console.log(err));
    },
    {
      enabled: !!reportableId && !!reportOrgId,
    }
  );
  return data;
};

const useGetGroupMediaAsset = (groupId) => {
  const queryKey = [groupId, "asset"];
  const { axiosOrgInstance } = useAxios();
  const data = useQuery(
    queryKey,
    function getMessage() {
      return axiosOrgInstance
        .get(
          GET_GROUP_TAGS.GET_GROUP_MEDIA_ASSET.replace(":groupId", groupId),
          {
            params: {
              limit: 1,
            },
          }
        )
        .then((res) => res?.docs?.[0])
        .catch((err) => console.log(err));
    },
    {
      enabled: !!groupId,
      staleTime: CACHE_TIME,
    }
  );
  return data;
};

const useEditorganisationDetails = () => {
  const { axiosOrgInstance } = useAxios();
  const dispatch = useDispatch();
  return useMutation((payload) => {
    axiosOrgInstance
      ?.patch(API_ROUTES.ORGANISATION.EDIT_DETAILS, payload)
      .then((res) => {
        toast("Organization edited successfully");
        dispatch(setSelectedOrganisation(res));
      })
      .catch((err) => toast(err));
  });
};

const useInviteUserToOrg = (getOrgUsers) => {
  const { axiosOrgInstance } = useAxios();
  const dispatch = useDispatch();
  return useMutation((payload) => {
    return axiosOrgInstance
      .patch(API_ROUTES.ORGANISATION.ADD_USER, payload)
      .then(() => {
        toast.success("User Added To Organisation Successfully");
        dispatch(setModal(modalInitState));
        getOrgUsers();
      })
      .catch((err) => toast.error(err));
  });
};

const useGetPresets = (enabled, currentReportOrgId) => {
  const { axiosOrgInstance } = useAxios();
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const queryKey = ["presets", currentReportOrgId || selectedOrganisation?._id];

  const data = useQuery(
    queryKey,
    () => {
      if (!selectedOrganisation?._id && !currentReportOrgId) return;
      return axiosOrgInstance
        .get(PRESETS.GET_PRESETS, {
          params: {
            orgId: currentReportOrgId,
          },
        })
        .then((res) => {
          return res;
        });
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled,
    }
  );
  return data;
};

const useCancelSubscription = () => {
  const { axiosOrgInstance } = useAxios();
  return useMutation(() => {
    return axiosOrgInstance
      .patch(ORGANISATION.CANCEL_SUBSCRIPTION)
      .then((res) => {
        toast.success("Cancelled successfully");
        return res;
      })
      .catch((err) => toast.error("Something went wrong"));
  });
};

const useDeactivateOrganisation = () => {
  const { axiosOrgInstance } = useAxios();
  const navigate = useNavigate();
  return useMutation(() => {
    return axiosOrgInstance
      .delete(ORGANISATION.DEACTIVATE_ORGANIZATION)
      .then((res) => {
        toast.success("Organisation deactivated successfully");
        navigate("/logout");
        return res;
      })
      .catch((err) => toast.error("Something went wrong"));
  });
};

const useHeartbeat = (organizationId) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["heartbeat", organizationId];

  const data = useQuery(
    queryKey,
    () => {
      if (!organizationId) return;
      return axiosOrgInstance
        .post(CONTROL_PANEL.HEARTBEAT, {
          organizationId,
        })
        .then((res) => {
          return res;
        });
    },
    {
      retry: 0,
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      enabled: !!organizationId,
      notifyOnChangeProps: ["data"],
    }
  );
  return data;
};

export {
  useGetUploadUrls,
  useGetUserAppStatus,
  useGetOrgAppStatus,
  useGetOrganisationList,
  useGetAvailableCurrencies,
  useUpdateDefaultCurrency,
  useGetModelKPI,
  useGetOrganizationTags,
  useRefreshOrganization,
  useGetOrgAdAccounts,
  useChangeRole,
  useGetOrgFolders,
  useGetReportMessage,
  useEditorganisationDetails,
  useInviteUserToOrg,
  useGetPresets,
  useCancelSubscription,
  useDeactivateOrganisation,
  useGetGroupMediaAsset,
  useHeartbeat,
};
