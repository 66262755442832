import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CometChatMessages } from "@cometchat/chat-uikit-react";
import { ListItemStyle } from "@cometchat/uikit-elements";
import {
  MessageHeaderConfiguration,
  MessagesStyle,
} from "@cometchat/uikit-shared";
import EmptyData from "components/common/empty/EmptyData";
import MetaLoader from "components/common/loaders/MetaLoader";
import Spinner from "components/common/loaders/Spinner";
import StorytellerLogoLoader from "components/common/loaders/StorytellerLogoLoader";
import ChangeVisualizationTab from "components/common/Modals/ChangeVisualizationTab";
import Filters from "components/common/molecules/Filters/Filters";
import HeaderFeatures from "components/common/molecules/HeaderFeature/HeaderFeatures";
import PreviewMediaAseet from "components/common/molecules/PreviewMediaAsset/PreviewMediaAseet";
import ReportDetails from "components/common/molecules/ReportInformation/ReportDetails";
import { PrivateContainer, ResponsiveBox } from "components/layouts";
import { ImageTaggingModal, VideoTaggingModal } from "components/modals";
import KeywordLogicModal from "components/modals/InsightsKeywordsModal/KeywordLogicModal";
import MoveReportToFolderModal from "components/modals/MoveReportToFolderModal/MoveReportToFolder";
import PresetModal from "components/modals/PresetsModal/PresetModal";
import SaveInsightsReportModal from "components/modals/SaveInsightsReportModal/SaveInsightsReportModal";
import { toBlob } from "html-to-image";
import { pick } from "lodash";
import {
  BarChart3,
  ClipboardCopy,
  Info,
  InspectionPanel,
  LineChart,
  Plus,
  X,
} from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MODALTYPES } from "redux/slices/modal.slices";
import SEO from "utils/SEO";
import { APP_ROUTES } from "../../routes";
import {
  BarChartMultiYAxis,
  LineChartMultiYAxis,
  PlotChartModal,
  TableView,
} from "./Components";
import NewInsightsCardView from "./Components/Card/InsightsNewCard";
import ColorTable, { DarkTooltip } from "./Components/ColorTable/ColorTable";
import useInsights from "./useInsights";

const groupbyOptions = [
  {
    label: "Ad-Name",
    value: "adName",
  },
  {
    label: "Headline",
    value: "headline",
  },
  {
    label: "Landing Page",
    value: "landingPageUrl",
  },
  {
    label: "Copy",
    value: "body",
  },
  {
    label: "Creative",
    value: "groupId",
  },
];

const kpiFiltersConditions = [
  {
    label: ">",
    value: "greater",
  },
  {
    label: "<",
    value: "lesser",
  },
  {
    label: "is between",
    value: "between",
  },
];

const kpiFiltersReverse = {
  [kpiFiltersConditions[0]?.value]: kpiFiltersConditions[0]?.label,
  [kpiFiltersConditions[1]?.value]: kpiFiltersConditions[1]?.label,
  [kpiFiltersConditions[2]?.value]: kpiFiltersConditions[2]?.label,
};

const SELECTED_METRIC_COLOR = {
  1: "bg-red-500",
  2: "bg-yellow-500",
  3: "bg-indigo-400",
  4: "bg-pink-400",
  5: "bg-green-400",
  6: "bg-purple-400",
  7: "bg-blue-400",
  8: "bg-lime-400",
  9: "bg-violet-400",
  10: "bg-emerald-400",
  11: "bg-[#7167FB]",
  12: "bg-blue-400",
};

const Insights = () => {
  const {
    filterAndOptions,
    isLoadingSpecificPreset,
    insightState,
    insightsData = [],
    isInsightLoading,
    isSaveReportEnable,
    visualisationTab,
    hideSidebar,
    groupBy,
    isBookmarkSuccess,
    specificInsightsId,
    isCompareView,
    isMakingShortUrl,
    currentFilterObj,
    payload,
    selectedMetrices,
    isCreatingReport,
    kpiFilters,
    sortedData,
    rowSelection,
    isUpdatingReport,
    disableFilters,
    groupIds,
    date,
    isLoadingFilters,
    smartSuggestion,
    isSmartSuggestionLoading,
    currentGroup,
    insightsReport,
    reportableMessage,
    selectedKPIs,
    presetData,
    preset,
    specificPresetData,
    isMakingPreset,
    pagination,
    setPagination,
    tempKpis,
    reportData,
    filterSet,
    adAccountIds,
    colorFormat,
    loadingReport,
    confirmVisualizationModal,
    handleColorFormatChange,
    deleteReport,
    setSortedData,
    setRowSelection,
    handleExtraFilterSelectUpdate,
    handleSearchMotionFilters,
    changeGroupBy,
    onOpenFilters,
    onOpenKpiFilters,
    onFilterWhereChange,
    onFilterValueChange,
    onUpdatePayload,
    onUpdateKpiFilters,
    onUpdateSelectedMetrices,
    handleUpdateSearchExtraFilter,
    onSelectedMetricsUpdate,
    handleAggregateChange,
    handleUpdateAndSave,
    onCompare,
    setSelectedKPIs,
    handlePresetSelect,
    makePreset,
    onDateDaysChange,
    handleAddNewFilter,
    setVisualisationTab,
    _handleApplyFilters,
    saveReport,
    handleRemoveNewFilter,
    handleAggregateLogicChangeExtraFilters,
    handleBookmarkReport,
    handleGroupIdCHange,
    handleRemoveBookmark,
    moveReportToFolder,
    handleUrlShortener,
    setCurrentFilterObj,
    updatePreset,
    onUpdateReportData,
    setTempKpis,
    onFilterFilterChange,
    applyPayload,
    onRemovePayload,
    handleDeleteFromFilterSet,
    onFilterSetOpen,
    handleAdAccountId,
    handleAddMore,
    handleUpdateSelectionType,
    handleUpdateDate,
    doesShowColor,
    handleAdBreakdown,
    handleCreativeInsight,
    changeVisualizationTab,
    closeVisualizationTabSwitch,
    confirmVisualizationTabSwitch,
  } = useInsights();

  const { open, type } = useSelector((state) => state?.modal);
  const [chartKPIs, setChartKPIs] = useState([]);
  const [isCopying, setIsCopying] = useState(false);
  const [initialProgress, setInitialProgress] = useState(0);

  const resolvedKPIs =
    visualisationTab === 0 ? selectedMetrices?.value : chartKPIs;

  const isSelectionValid = (type) => {
    let maxRowSelection, maxKpiSelection;

    if (visualisationTab === 0) {
      maxRowSelection = 10000;
      maxKpiSelection = 7;
    } else if (visualisationTab === 1) {
      maxRowSelection = 6;
      maxKpiSelection = 7;
    } else {
      maxRowSelection = 6;
      maxKpiSelection = 7;
    }

    if (
      type === "row" &&
      Object.keys(rowSelection)?.length >= maxRowSelection
    ) {
      toast(
        `In ${
          visualisationTab === 0 ? "card" : "chart"
        } view maximum selection should be ${maxRowSelection}`
      );
      return false;
    }

    if (type === "kpi" && resolvedKPIs?.length >= maxKpiSelection) {
      toast(
        `In ${
          visualisationTab === 0 ? "card" : "chart"
        } view maximum kpi selection should be ${maxKpiSelection}`
      );
      return false;
    }

    return true;
  };

  const updateKPIs = (value) => {
    if (visualisationTab === 0) {
      onUpdateSelectedMetrices({
        ...selectedMetrices,
        ...value,
      });
    } else {
      setChartKPIs(value?.value);
    }
  };

  const [triggeredTab, setTriggeredTab] = useState(0);

  const metricsOptions = insightState?.metrics?.map((item) => ({
    label: item?.label,
    value: item?.kpi,
  }));

  const lStyle = new ListItemStyle({
    background: "transparent",
  });

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(resolvedKPIs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateKPIs({ value: items });
  };

  const mhConfig = new MessageHeaderConfiguration({
    listItemStyle: lStyle,
    display: "none",
  });
  const hasWindow = typeof window !== "undefined";

  const mStyle = new MessagesStyle({
    display: "flex",
    justifyContent: "center",
    height: hasWindow ? window.innerHeight - 20 : "760px",
    // width: "350px",
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPerformanceMetrics, setIsPerformanceMetrics] = useState(false);

  const beforeUnloadListener = (event) => {
    event.preventDefault();
    return (event.returnValue = "");
  };

  const ref = useRef();
  const handleCopyToClipboard = useCallback(async () => {
    setIsCopying(true);
    if (ref.current) {
      const elementsToHide =
        ref?.current?.querySelectorAll(".rm-for-screenshot");
      const elementsToShow = ref?.current?.querySelectorAll(
        ".show-for-screenshot"
      );
      elementsToShow.forEach((element) => {
        element.style.display = "flex";
      });
      elementsToHide.forEach((element) => {
        element.style.display = "none";
      });
      try {
        const blob = await toBlob(ref.current, { cacheBust: true });
        const item = { [blob.type]: blob };
        const clipboardItem = new ClipboardItem(item);
        await navigator.clipboard.write([clipboardItem]);
        toast("Data Copied To Clipboard.", {
          type: "success",
          toastId: "sadkmadsm1214",
        });
      } catch (error) {
        console.error("Failed to copy data to clipboard:", error);
        toast("Failed to copy data to clipboard.", { type: "error" });
      } finally {
        elementsToHide.forEach((element) => {
          element.style.display = "block";
        });
        elementsToShow.forEach((element) => {
          element.style.display = "none";
        });
        setIsCopying(false);
      }
    }
  }, [ref]);

  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnloadListener);
    return () =>
      window.removeEventListener("beforeunload", beforeUnloadListener);
  }, []);

  const dispatch = useDispatch();

  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
        maxHeight: "calc(100vh - 30px)",
      }}
      activePage={APP_ROUTES.INSIGHTS.default}
      hideSidebar={hideSidebar}
    >
      <SEO
        title="Insights"
        description="Creative insight reports powered by Storyteller"
        name="Insights by Storyteller"
        type="Report"
      />
      {loadingReport && specificInsightsId ? (
        <StorytellerLogoLoader />
      ) : (
        <>
          <div className="w-full flex h-full text-xs">
            <div
              className={`${
                isDrawerOpen ? "w-[70%]" : "w-full"
              } overflow-auto px-5`}
            >
              <div className="sticky top-0 z-[50] flex justify-between w-full mb-4 bg-white pb-2">
                <div className="w-1/3">
                  <p className="outline-none truncate">{reportData?.name}</p>
                </div>
                <HeaderFeatures
                  specificInsightsId={specificInsightsId}
                  disableFilters={disableFilters}
                  isSaveReportEnable={isSaveReportEnable}
                  handleUpdateAndSave={handleUpdateAndSave}
                  isMakingShortUrl={isMakingShortUrl}
                  handleUrlShortener={handleUrlShortener}
                  hideSidebar={hideSidebar}
                  isBookmarkSuccess={isBookmarkSuccess}
                  handleRemoveBookmark={handleRemoveBookmark}
                  insightsData={insightsData}
                  deleteReport={deleteReport}
                  handleBookmarkReport={handleBookmarkReport}
                  isUpdatingReport={isUpdatingReport}
                  report={insightsReport}
                  setIsDrawerOpen={setIsDrawerOpen}
                  isDrawerOpen={isDrawerOpen}
                  onFilterValueChange={onFilterValueChange}
                  handleAdAccountId={handleAdAccountId}
                  payload={payload}
                  adAccountIds={adAccountIds}
                  filterAndOptions={
                    filterAndOptions
                      ? pick(filterAndOptions, ["adAccountIds"])
                      : {}
                  }
                />
              </div>
              <div className="mt-1 h-full">
                {/* for the report title, description and comment section */}

                <ReportDetails
                  reportData={reportData}
                  reportableMessage={reportableMessage}
                  onUpdateReportData={onUpdateReportData}
                />

                {/* the main filters part for date groupby add-filter*/}
                <Filters
                  handleUpdateDate={handleUpdateDate}
                  handleUpdateSelectionType={handleUpdateSelectionType}
                  filterSet={filterSet}
                  insightState={insightState}
                  onDateDaysChange={onDateDaysChange}
                  disableFilters={disableFilters}
                  hideSidebar={hideSidebar}
                  groupBy={groupBy}
                  groupbyOptions={groupbyOptions}
                  specificInsightsId={specificInsightsId}
                  onCompare={onCompare}
                  groupIds={groupIds}
                  _handleApplyFilters={_handleApplyFilters}
                  setInitialProgress={setInitialProgress}
                  handleAggregateLogicChangeExtraFilters={
                    handleAggregateLogicChangeExtraFilters
                  }
                  handleRemoveNewFilter={handleRemoveNewFilter}
                  handleAddNewFilter={handleAddNewFilter}
                  isSmartSuggestionLoading={isSmartSuggestionLoading}
                  smartSuggestion={smartSuggestion}
                  handleUpdateSearchExtraFilter={handleUpdateSearchExtraFilter}
                  handleSearchMotionFilters={handleSearchMotionFilters}
                  handleExtraFilterSelectUpdate={handleExtraFilterSelectUpdate}
                  currentFilterObj={currentFilterObj}
                  isPerformanceMetrics={isPerformanceMetrics}
                  setIsPerformanceMetrics={setIsPerformanceMetrics}
                  setCurrentFilterObj={setCurrentFilterObj}
                  metricsOptions={metricsOptions}
                  kpiFilters={kpiFilters}
                  payload={payload}
                  filterAndOptions={filterAndOptions}
                  kpiFiltersConditions={kpiFiltersConditions}
                  kpiFiltersReverse={kpiFiltersReverse}
                  changeGroupBy={changeGroupBy}
                  onOpenFilters={onOpenFilters}
                  onOpenKpiFilters={onOpenKpiFilters}
                  onFilterWhereChange={onFilterWhereChange}
                  onFilterValueChange={onFilterValueChange}
                  onUpdatePayload={onUpdatePayload}
                  onUpdateKpiFilters={onUpdateKpiFilters}
                  date={date}
                  isLoadingFilters={isLoadingFilters}
                  onFilterFilterChange={onFilterFilterChange}
                  applyPayload={applyPayload}
                  onRemovePayload={onRemovePayload}
                  onFilterSetOpen={onFilterSetOpen}
                  handleDeleteFromFilterSet={handleDeleteFromFilterSet}
                  handleAddMore={handleAddMore}
                />

                <div className="bg-gray-100 border-[2px] border-gray-100 w-full flex rounded-2xl p-3 mt-10 gap-x-2 justify-between items-center">
                  <div className="w-[80%] flex flex-row items-center gap-x-3 h-fit">
                    <div
                      className="flex flex-row gap-x-2 flex-wrap items-center" // Updated to flex-wrap for horizontal layout
                    >
                      {resolvedKPIs?.map((metric, index) => {
                        const KPI = filterAndOptions?.kpi?.find(
                          (kpi) => kpi?.kpi === metric
                        );

                        if (!KPI) return null;

                        return (
                          <div className="flex items-center">
                            <div className="h-fit font-medium p-1 m-1 border-[1px] border-gray-300 rounded-md shadow-sm flex items-center justify-between gap-2">
                              <span
                                className={`px-2 py-1 rounded-md text-white shadow-xl flex items-center ${
                                  SELECTED_METRIC_COLOR[index + 1]
                                }`}
                              >
                                {index + 1}
                              </span>
                              <span className="text-gray-600">
                                {KPI?.label}
                              </span>
                              <X
                                className="h-4 w-4 cursor-pointer"
                                onClick={() => {
                                  updateKPIs({
                                    value: resolvedKPIs?.filter(
                                      (kpi) => kpi !== metric
                                    ),
                                  });
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <Popover>
                        <DarkTooltip
                          placement="top"
                          title="Select the metrics that you want to analyse."
                        >
                          <PopoverTrigger
                            asChild
                            className="w-min shadow-sm cursor-pointer"
                          >
                            <div className="text-[#7167FB] font-medium px-2 py-2 border-[1px] border-gray-300 rounded-md shadow-sm flex items-center gap-x-1 whitespace-nowrap">
                              <Plus className="h-4 w-4 cursor-pointer" />
                              <span className="text-gray-600 font-medium">
                                Add metric
                              </span>
                            </div>
                          </PopoverTrigger>
                        </DarkTooltip>
                        <PopoverContent
                          className="w-140px p-0 flex justify-between"
                          side="right"
                          align="start"
                        >
                          <Command>
                            <CommandInput placeholder="Search metric..." />
                            <CommandEmpty>No metric found.</CommandEmpty>
                            <CommandList>
                              <CommandGroup>
                                {filterAndOptions?.kpi?.map((option) => {
                                  if (
                                    resolvedKPIs.includes(
                                      option?.kpi.toString()
                                    )
                                  ) {
                                    return null;
                                  }
                                  return (
                                    <CommandItem
                                      className="flex justify-between text-xs font-medium"
                                      key={option?.label.toString()}
                                      value={option?.label.toString()}
                                      onSelect={(currentValue) => {
                                        if (!isSelectionValid("kpi")) {
                                          return;
                                        }
                                        updateKPIs({
                                          value: [
                                            ...resolvedKPIs,
                                            option?.kpi.toString(),
                                          ],
                                        });
                                      }}
                                    >
                                      <div className="flex w-full items-center gap-x-2">
                                        <div className="flex items-center">
                                          <img
                                            src={option?.icon}
                                            alt="kpi_default-icon"
                                            className="w-4 h-4"
                                          />
                                          <div className="text-left ml-2">
                                            {option?.label}
                                          </div>
                                        </div>
                                        <div className="ml-auto">
                                          <DarkTooltip
                                            title={option?.description}
                                            arrow
                                            placement="right"
                                          >
                                            <Info className="h-4 w-4 text-black" />
                                          </DarkTooltip>
                                        </div>
                                      </div>
                                    </CommandItem>
                                  );
                                })}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                  <div className="flex gap-x-4 items-center">
                    {!isCopying ? (
                      <DarkTooltip
                        placement="top"
                        title={"Copy performance card/graph to your clipboard."}
                      >
                        <Button
                          className="shadow-sm"
                          variant="outline"
                          size="icon"
                          onClick={() => handleCopyToClipboard()}
                        >
                          <ClipboardCopy className="h-5 w-5 text-gray-500" />
                        </Button>
                      </DarkTooltip>
                    ) : (
                      <Spinner spinnerClass="h-6 w-6 border-[#7167FB]" />
                    )}
                    <Tabs
                      defaultValue="account"
                      className="border-[1px] rounded-md border-gray-300 cursor-not-allowed"
                    >
                      <TabsList
                        className={`flex p-1 bg-gray-200 ${
                          sortedData.length ? "" : "bg-slate-200"
                        }`}
                      >
                        <DarkTooltip title="Card View" placement="top">
                          <TabsTrigger
                            value="tile-view"
                            onClick={() => {
                              changeVisualizationTab(0);
                            }}
                            className={`${
                              visualisationTab === 0
                                ? "bg-white"
                                : "!bg-inherit !text-inherit"
                            }`}
                          >
                            <InspectionPanel
                              className={`w-5 h-5 ${
                                visualisationTab === 0
                                  ? "text-blue-500 font-extrabold"
                                  : ""
                              }`}
                            />
                          </TabsTrigger>
                        </DarkTooltip>
                        <DarkTooltip title="Line Graph View" placement="top">
                          <TabsTrigger
                            value="line-view"
                            onClick={() => {
                              changeVisualizationTab(1);
                            }}
                            className={`${
                              visualisationTab === 1
                                ? "bg-white"
                                : "!bg-inherit !text-inherit"
                            }`}
                          >
                            <LineChart
                              className={`w-5 h-5 ${
                                visualisationTab === 1
                                  ? "text-blue-500 font-extrabold"
                                  : ""
                              }`}
                            />
                          </TabsTrigger>
                        </DarkTooltip>
                        <DarkTooltip title="Column Graph View" placement="top">
                          <TabsTrigger
                            value="bar-chart"
                            onClick={() => {
                              changeVisualizationTab(2);
                              setTriggeredTab(2);
                            }}
                            className={`${
                              visualisationTab === 2
                                ? "bg-white"
                                : "!bg-inherit !text-inherit"
                            }`}
                          >
                            <BarChart3
                              className={`w-5 h-5 ${
                                visualisationTab === 2
                                  ? "text-blue-500 font-extrabold"
                                  : ""
                              }`}
                            />
                          </TabsTrigger>
                        </DarkTooltip>
                      </TabsList>
                    </Tabs>
                  </div>
                </div>

                <div ref={ref} className="p-5 min-h-[15%] my-4">
                  {isInsightLoading ? (
                    <div class="flex items-center flex-col justify-start space-y-4 mt-28">
                      <p className="text-xs font-medium">Waiting For Meta...</p>
                      <MetaLoader
                        initialProgress={initialProgress}
                        setInitialProgress={setInitialProgress}
                      />
                    </div>
                  ) : (
                    <>
                      {sortedData.length > 0 &&
                      Object.keys(rowSelection)?.length ? (
                        <>
                          {visualisationTab === 0 &&
                            (resolvedKPIs.length ? (
                              <NewInsightsCardView
                                rowSelection={rowSelection}
                                sortedData={sortedData}
                                selectedKPIs={resolvedKPIs}
                                handleGroupIdCHange={handleGroupIdCHange}
                                isCompareView={isCompareView}
                                filterAndOptions={filterAndOptions}
                                colorFormat={colorFormat}
                                doesShowColor={doesShowColor}
                                isCopying={isCopying}
                                setIsCopying={setIsCopying}
                                handleCreativeInsight={handleCreativeInsight}
                              />
                            ) : (
                              <>
                                <EmptyData
                                  customClass="mt-10"
                                  imageUrl="/choice.png"
                                  title="Select KPI"
                                />
                              </>
                            ))}
                          <ResponsiveBox>
                            {visualisationTab === 1 &&
                              (resolvedKPIs.length ? (
                                <LineChartMultiYAxis
                                  insightsData={sortedData}
                                  selectedMetrics={resolvedKPIs}
                                  rowSelection={rowSelection}
                                  filterAndOptions={filterAndOptions}
                                  handleCreativeInsight={handleCreativeInsight}
                                />
                              ) : (
                                <>
                                  <EmptyData
                                    customClass="mt-10"
                                    imageUrl="/choice.png"
                                    title="Select KPI"
                                  />
                                </>
                              ))}
                            {visualisationTab === 2 &&
                              (resolvedKPIs.length ? (
                                <BarChartMultiYAxis
                                  insightsData={sortedData}
                                  selectedMetrics={resolvedKPIs}
                                  rowSelection={rowSelection}
                                  filterAndOptions={filterAndOptions}
                                  handleCreativeInsight={handleCreativeInsight}
                                />
                              ) : (
                                <>
                                  <EmptyData
                                    customClass="mt-10"
                                    imageUrl="/choice.png"
                                    title="Select Ad Groups"
                                  />
                                </>
                              ))}
                            {visualisationTab === 3 && (
                              <TableView insightsData={insightsData} />
                            )}
                          </ResponsiveBox>
                        </>
                      ) : (
                        <EmptyData
                          customClass="mt-10"
                          imageUrl="/choice.png"
                          title="Select Ad Groups"
                        />
                      )}
                    </>
                  )}
                  <div className="hidden my-2 h-12 items-center gap-2 show-for-screenshot">
                    <img src="/logo192.png" alt="" className="h-8" />
                    <p className="text-sm">
                      Created using www.thestoryteller.tech
                    </p>
                  </div>
                </div>
                <div className="mb-10">
                  {insightsData?.length > 0 && (
                    <ColorTable
                      setSortedData={setSortedData}
                      filterAndOptions={filterAndOptions}
                      isSelectionValid={isSelectionValid}
                      insightsData={insightsData}
                      rowSelection={rowSelection}
                      visualisationTab={visualisationTab}
                      setRowSelection={setRowSelection}
                      selectedMetrices={resolvedKPIs}
                      updateKPIs={updateKPIs}
                      selectedKPIs={resolvedKPIs}
                      setSelectedKPIs={setSelectedKPIs}
                      handlePresetSelect={handlePresetSelect}
                      preset={preset}
                      presetData={presetData}
                      isMakingPreset={isMakingPreset}
                      makePreset={makePreset}
                      disableFilters={disableFilters}
                      hideSidebar={hideSidebar}
                      specificPresetData={specificPresetData}
                      pagination={pagination}
                      setPagination={setPagination}
                      _handleApplyFilters={_handleApplyFilters}
                      tempKpis={tempKpis}
                      setTempKpis={setTempKpis}
                      colorFormat={colorFormat}
                      handleColorFormatChange={handleColorFormatChange}
                      doesShowColor={doesShowColor}
                      handleAdBreakdown={handleAdBreakdown}
                      handleCreativeInsight={handleCreativeInsight}
                      isLoadingSpecificPreset={isLoadingSpecificPreset}
                    />
                  )}
                </div>
              </div>
            </div>

            <div
              className={`${
                currentGroup && isDrawerOpen ? "w-[30%]" : "w-0"
              } h-fit overflow-hidden sticky max-h-screen flex justify-center items-start top-0 right-0`}
            >
              {currentGroup && isDrawerOpen ? (
                <CometChatMessages
                  group={currentGroup}
                  messagesStyle={mStyle}
                  messageHeaderConfiguration={mhConfig}
                />
              ) : null}
            </div>
          </div>
          {open && type === MODALTYPES.SAVE_INSIGHTS_REPORT && (
            <SaveInsightsReportModal
              reportData={reportData}
              saveReport={saveReport}
              isCreatingReport={isCreatingReport}
            />
          )}

          {open && type === MODALTYPES.PLOT_CHART_MODAL && (
            <PlotChartModal
              selectedMetrics={insightState?.selectedMetrics}
              onSelectedMetricsUpdate={onSelectedMetricsUpdate}
              metricsOptions={insightState?.metrics}
              handleClick={() => changeVisualizationTab(triggeredTab)}
            />
          )}
          {open && type === MODALTYPES.IMAGE_TAGGING_MODAL && (
            <ImageTaggingModal postOnboarding={true} />
          )}
          {open && type === MODALTYPES.VIDEO_TAGGING_MODAL && (
            <VideoTaggingModal postOnboarding={true} />
          )}
          {open && type === MODALTYPES.KEYWORD_AGGREGATION_LOGIC_MODAL && (
            <KeywordLogicModal handleAggregateChange={handleAggregateChange} />
          )}
          <MoveReportToFolderModal primaryAction={moveReportToFolder} />
          <PresetModal
            preset={preset}
            handlePresetSelect={handlePresetSelect}
            presetData={presetData}
            selectedMetrices={selectedMetrices}
            specificPresetData={specificPresetData}
            filterAndOptions={filterAndOptions}
            onUpdateSelectedMetrices={onUpdateSelectedMetrices}
            updatePreset={updatePreset}
            isMakingPreset={isMakingPreset}
            makePreset={makePreset}
            tempKpis={tempKpis}
            setTempKpis={setTempKpis}
            isLoadingSpecificPreset={isLoadingSpecificPreset}
          />
          <PreviewMediaAseet />
          <ChangeVisualizationTab
            showModal={confirmVisualizationModal.showModal}
            closeFn={closeVisualizationTabSwitch}
            confirmFn={confirmVisualizationTabSwitch}
          />
        </>
      )}
    </PrivateContainer>
  );
};

export default Insights;
