import { createSlice } from "@reduxjs/toolkit";

export const intialState = {
  loading: [],
  bookmarkedReports: [],
  userBookmarkReports: [],
  groupIds: [],
};

const appSlice = createSlice({
  name: "app",
  initialState: intialState,
  reducers: {
    setLoading: (state, action) => {
      if (action.payload === true) state.loading.push(true);
      else state.loading.pop();
    },
    setSidebarOpen: (state, action) => {
      state.appState = { ...state.appState, isSidebarOpen: action.payload };
    },
    setBookmarkReports: (state, action) => {
      state.bookmarkedReports.push(action.payload);
    },
    setUserBookmarkReports: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.userBookmarkReports = [];
      } else {
        state.userBookmarkReports.push(action.payload);
      }
    },
    setUserBookmarksAfterUnbookmarking: (state, action) => {
      state.userBookmarkReports = state.userBookmarkReports.filter(
        (id) => id !== action.payload
      );
    },
    setGroupIds: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.groupIds = [];
      } else if (state.groupIds.includes(action.payload)) {
        state.groupIds = state.groupIds.filter(
          (curr) => curr !== action.payload
        );
      } else {
        state.groupIds.push(action.payload);
      }
    },
  },
});

export const {
  setLoading,
  setSidebarOpen,
  setBookmarkReports,
  setUserBookmarkReports,
  setGroupIds,
  setUserBookmarksAfterUnbookmarking,
} = appSlice.actions;
export default appSlice.reducer;
