import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { toast } from "@/components/ui/use-toast";
import { Tooltip } from "@mui/material";
import { REPORT_TYPE_ICONS } from "config/statics";
import { ClipboardCopy, Copy, Eye, EyeOff, Share, Trash2 } from "lucide-react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { APP_ROUTES } from "routes";
import { REPORTABLE_TYPE } from "utils/constants";

const ReportItem = ({
  folder,
  report,
  openFolderIds,
  duplicateReport,
  deleteReport,
  HandleChangeVisibility,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  let viewRedirect = location.pathname;
  const { name, reportableType, reportableId, icon, meta, url, isPublic } =
    report;

  switch (reportableType) {
    case REPORTABLE_TYPE.INSIGHTS:
      viewRedirect = `${APP_ROUTES.INSIGHTS.default}/${reportableId}`;
      break;
    case REPORTABLE_TYPE.COMPARE:
      viewRedirect = APP_ROUTES.INSIGHTS.COMAPRE_REPORT.replace(
        ":reportId",
        reportableId
      );
      break;
    case REPORTABLE_TYPE.COMPARE_MULTIPLE_DATES:
      viewRedirect = APP_ROUTES.INSIGHTS.COMPARE_DATE_REPORT.replace(
        ":reportId",
        reportableId
      );
      break;
    case REPORTABLE_TYPE.RECOMMENDATIONS:
      viewRedirect = APP_ROUTES.RECOMMENDATION.REPORT_DETAIL.replace(
        ":reportableId",
        reportableId
      );
      if (meta) {
        const isVideoStage = JSON.parse(meta)?.isVideoStage;
        viewRedirect = viewRedirect.replace(
          ":recommendationType",
          isVideoStage ? "videoIntelligence" : "creativeFeature"
        );
      }
      break;
    case REPORTABLE_TYPE.PREDICTIONS:
      viewRedirect = APP_ROUTES.PREDICTION.PREDICTION_REPORT_DETAILS.replace(
        ":reportId",
        reportableId
      );
      break;
    case REPORTABLE_TYPE.CREATIVE_INSIGHTS:
      viewRedirect = APP_ROUTES.INSIGHTS.CREATIVE_INSIGHT_REPORT.replace(
        ":reportId",
        reportableId
      );
      break;
    case REPORTABLE_TYPE.AD_BREAKDOWN:
      viewRedirect = APP_ROUTES.INSIGHTS.AD_BREAKDOWN_REPORT.replace(
        ":reportId",
        reportableId
      );
      break;
    case REPORTABLE_TYPE.CREATIVE_MOMENTUM_FATIGUE:
      viewRedirect =
        APP_ROUTES.INSIGHTS.CREATIVE_MOMENTUM_FATIGUE_REPORT.replace(
          ":reportId",
          reportableId
        );
      break;
    default:
      break;
  }

  const handleReportMove = (reportableId) => {
    dispatch(
      setModal({
        open: true,
        type: MODALTYPES.MOVE_REPORT_TO_FOLDER,
        payload: {
          reportId: reportableId,
        },
      })
    );
  };

  return (
    <li
      key={reportableId}
      className={`px-4 group py-2 relative transition-opacity flex items-center hover:bg-gray-200 rounded-md ${
        true || openFolderIds?.size ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className="rounded-md cursor-pointer hover:border hover:border-sky-300 shadow-sm bg-sky-100 flex flex-col min-w-6 min-h-6 mr-2 items-center justify-center relative">
        {!icon ? (
          <img
            className="max-w-5 h-auto group-hover:hidden group-hover:animate-bounceUp transition-transform"
            src={REPORT_TYPE_ICONS[reportableType]}
            alt=""
          />
        ) : (
          <div className="text-base h-auto group-hover:hidden group-hover:animate-bounceUp transition-transform">
            {icon}
          </div>
        )}
        <img
          className="max-w-4 h-auto hidden group-hover:block group-hover:animate-bounceIn absolute transition-transform"
          src="/meta.png"
          alt=""
        />
      </div>
      <di className="w-[70%]">
        <Tooltip title={name?.length > 10 ? name : ""} placement="top" arrow>
          <Link to={viewRedirect}>
            <p className="truncate">{name}</p>
          </Link>
        </Tooltip>
      </di>
      <div
        className="relative inline-block"
        onClick={() => {
          dispatch(
            setModal({
              open: true,
              type: MODALTYPES.MOVE_REPORT_TO_FOLDER,
              payload: {
                reportId: reportableId,
              },
            })
          );
        }}
      ></div>
      <DropdownMenu className="group">
        <DropdownMenuTrigger asChild>
          <img
            width="16"
            alt=""
            src="/ellipsis.png"
            className="invisible group-hover:visible ml-1 hover:cursor-pointer"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w=48">
          <DropdownMenuRadioGroup value="right">
            <DropdownMenuItem className="text-xs">
              <div
                className="flex"
                onClick={() => handleReportMove(reportableId)}
              >
                <ClipboardCopy className="mr-2 h-4 w-4" />
                <span>Move Report to folder</span>
              </div>
            </DropdownMenuItem>
            <DropdownMenuItem
              className="text-xs"
              onClick={() => {
                duplicateReport({
                  reportId: reportableId,
                  folderId: folder?._id,
                });
              }}
            >
              <Copy className="mr-2 h-4 w-4" />
              <span>Duplicate</span>
            </DropdownMenuItem>
            {isPublic && url && (
              <DropdownMenuItem
                className="text-xs"
                onClick={() => {
                  navigator.clipboard.writeText(url);
                  toast({
                    description: "Url Copied To Clipboard.",
                  });
                }}
              >
                <Share className="mr-2 h-4 w-4" />
                <span>Share report</span>
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              onClick={(e) => {
                HandleChangeVisibility(reportableId, !isPublic);
              }}
              className="text-xs"
            >
              {isPublic ? (
                <Eye className="mr-2 h-4 w-4" />
              ) : (
                <EyeOff className="mr-2 h-4 w-4" />
              )}
              <span>Toggle Visibility</span>
            </DropdownMenuItem>
          </DropdownMenuRadioGroup>
          <DropdownMenuSeparator />
          <DropdownMenuRadioGroup>
            <DropdownMenuItem
              className="text-xs focus:text-red-500 text-red-500"
              onClick={() => {
                dispatch(
                  setModal({
                    type: MODALTYPES.COMMON_ALERT,
                    open: true,
                    payload: {
                      action: () => deleteReport({ id: reportableId }),
                      title: "Are you sure?",
                      description:
                        "This action cannot be undone. This will permanently delete the report.",
                    },
                  })
                );
              }}
            >
              <Trash2 className="mr-2 h-4 w-4" />
              <span>Delete Report</span>
            </DropdownMenuItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </li>
  );
};

export default ReportItem;
