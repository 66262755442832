import React from "react";
import styles from "./Unauthorized.module.css";
import { PrivateContainer } from "components/layouts";
import useAuth from "hooks/useAuth";

const Unauthorized = () => {
  const { userToken } = useAuth();

  return (
    <>
      <PrivateContainer hideSidebar={!userToken ? true : false}>
        <div className={styles.container}>
          <div className={styles.content}>
            <h1 className={styles.title}>Unauthorized Access</h1>
            <p className={styles.message}>
              You do not have permission to access this resource.
            </p>
            <img src={"/403.jpg"} alt="403 Error" className={styles.image} />
            <p className={styles.reasonsTitle}>Probable Reasons:</p>
            <ul className={styles.reasons}>
              <li>Resource not included in subscription</li>
              <li>Onboarding is still in progress or you are not onboarded</li>
              <li>Trying to access unauthorized resource</li>
              <li>If nothing works, contact support</li>
            </ul>
          </div>
        </div>
      </PrivateContainer>
    </>
  );
};

export default Unauthorized;
