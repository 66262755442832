import {
  Archive,
  ChevronDown,
  ChevronRight,
  Copy,
  Folder,
  Move,
  MoveDiagonal2,
  Pencil,
  Plus,
  Star,
  StarOff,
  Trash2,
} from "lucide-react";
import { FolderItem } from "./FolderItem";
import { Tooltip } from "@mui/material";
import { twJoin } from "tailwind-merge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import ReportItem from "./ReportItem";
import { useDispatch } from "react-redux";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";

export const FolderRootItem = ({
  folder,
  toggleFolder,
  openFolderIds,
  setFolderForEdit,
  isDropdownOpen,
  folderIdForEdit,
  setSelectedFolderName,
  setOpenRenameInput,
  handleFolderAction,
  children,
  duplicateReport,
  dropdownRef,
  showLoader,
  checkIfActionAllowed,
  deleteReport,
  HandleChangeVisibility,
}) => {
  const hasChildrenOrReports =
    children?.length > 0 || folder?.reports?.length > 0;

  const dispatch = useDispatch();

  return (
    <div key={folder?._id}>
      <div className="relative">
        <div
          className="flex px-2 group py-2 items-center  text-[14px] w-full"
          ref={dropdownRef}
        >
          <div
            onClick={() => toggleFolder(folder?._id, folder?.level, children)}
            className={twJoin(
              "rounded-md p-1 cursor-pointer",
              openFolderIds.has(folder?._id)
                ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
                : "hover:bg-slate-300"
            )}
          >
            {openFolderIds.has(folder?._id) ? (
              <ChevronDown size={16} />
            ) : (
              <ChevronRight size={16} />
            )}
          </div>
          <div className="flex text-xs items-center w-[90%]">
            <div className="flex-grow overflow-hidden w-full ml-2 text-gray-600 font-medium">
              <Tooltip
                placement="bottom"
                arrow
                title={folder?.name?.length > 10 ? folder?.name : ""}
              >
                <p className="truncate">{folder?.name}</p>
              </Tooltip>
            </div>
            <div className="flex gap-1 ml-1 flex-shrink-0">
              {folder?.isFavourite && (
                <Star color="orange" className="w-4 h-4" fill="orange" />
              )}
              <DropdownMenu className="group">
                <DropdownMenuTrigger asChild>
                  <img
                    width="16"
                    alt=""
                    src="/ellipsis.png"
                    className="invisible group-hover:visible cursor-pointer"
                  />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-max">
                  <DropdownMenuGroup>
                    {checkIfActionAllowed(folder?.name) && (
                      <DropdownMenuItem
                        className="text-xs"
                        onClick={() => {
                          setFolderForEdit(folder?._id);
                          setSelectedFolderName(folder?.name);
                          setOpenRenameInput(true);
                        }}
                      >
                        <Pencil className="mr-2 h-4 w-4" />
                        <span>Rename</span>
                      </DropdownMenuItem>
                    )}
                    <DropdownMenuSub>
                      <DropdownMenuSubTrigger className="text-xs">
                        <Plus className="mr-2 h-4 w-4" />
                        <span>Create</span>
                      </DropdownMenuSubTrigger>
                      <DropdownMenuPortal>
                        <DropdownMenuSubContent>
                          <DropdownMenuItem
                            className="text-xs"
                            onClick={() => {
                              handleFolderAction(
                                "addNestedFolder",
                                folder?._id
                              );
                            }}
                          >
                            <Folder className="mr-2 h-4 w-4" />
                            <span>Folder</span>
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="text-xs"
                            onClick={() => {
                              dispatch(
                                setModal({
                                  open: true,
                                  type: MODALTYPES.CREATE_REPORTS,
                                  payload: {},
                                })
                              );
                              localStorage.setItem(
                                "REPORT_FOLDER",
                                JSON.stringify([
                                  {
                                    _id: folder?._id,
                                    name: folder?.name,
                                  },
                                ])
                              );
                            }}
                          >
                            <Folder className="mr-2 h-4 w-4" />
                            <span>Report</span>
                          </DropdownMenuItem>
                        </DropdownMenuSubContent>
                      </DropdownMenuPortal>
                    </DropdownMenuSub>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      className="text-xs"
                      onClick={() =>
                        handleFolderAction(
                          "favourite",
                          folder?._id,
                          folder?.isFavourite,
                          folder?.parent
                        )
                      }
                    >
                      {folder?.isFavourite ? (
                        <>
                          <StarOff className="mr-2 h-4 w-4" />
                          <span>Remove From Favourite</span>
                        </>
                      ) : (
                        <>
                          <Star className="mr-2 h-4 w-4" />
                          <span>Add to Favourite</span>
                        </>
                      )}
                    </DropdownMenuItem>
                    {checkIfActionAllowed(folder?.name) && (
                      <DropdownMenuItem
                        className="text-xs"
                        onClick={() =>
                          handleFolderAction(
                            "duplicate",
                            folder?._id,
                            "",
                            folder?.parent
                          )
                        }
                      >
                        <Copy className="mr-2 h-4 w-4" />
                        <span>Duplicate Folder</span>
                      </DropdownMenuItem>
                    )}
                    {checkIfActionAllowed(folder?.name) && (
                      <DropdownMenuItem
                        className="text-xs"
                        onClick={() =>
                          handleFolderAction(
                            "moveFolder",
                            folder?._id,
                            "",
                            folder?.parent
                          )
                        }
                      >
                        <Move className="mr-2 h-4 w-4" />
                        <span>Move To Another Folder</span>
                      </DropdownMenuItem>
                    )}
                    {checkIfActionAllowed(folder?.name) && (
                      <DropdownMenuSeparator />
                    )}
                    {checkIfActionAllowed(folder?.name) && !folder?.isArchive && (
                      <DropdownMenuItem
                        className="text-xs"
                        onClick={() =>
                          handleFolderAction("archive", folder?._id)
                        }
                      >
                        <Archive className="mr-2 h-4 w-4" />
                        <span>Archive</span>
                      </DropdownMenuItem>
                    )}
                    {checkIfActionAllowed(folder?.name) && (
                      <DropdownMenuItem
                        className="text-xs focus:text-red-500 text-red-500"
                        onClick={() =>
                          handleFolderAction(
                            "delete",
                            folder?._id,
                            "",
                            folder?.parent
                          )
                        }
                      >
                        <Trash2 className="mr-2 h-4 w-4" />
                        <span>Delete</span>
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
      {openFolderIds.has(folder?._id) && (
        <>
          <FolderItem
            children={children}
            toggleFolder={toggleFolder}
            openFolderIds={openFolderIds}
            setFolderForEdit={setFolderForEdit}
            isDropdownOpen={isDropdownOpen}
            folderIdForEdit={folderIdForEdit}
            setSelectedFolderName={setSelectedFolderName}
            setOpenRenameInput={setOpenRenameInput}
            handleFolderAction={handleFolderAction}
            duplicateReport={duplicateReport}
            dropdownRef={dropdownRef}
            checkIfActionAllowed={checkIfActionAllowed}
            showLoader={showLoader}
            deleteReport={deleteReport}
            HandleChangeVisibility={HandleChangeVisibility}
          />
          {folder?.reports?.length > 0 ? (
            <ul className="text-xs ml-[20px] border-l-[1px]">
              {folder?.reports?.map((report) => (
                <li key={report?.reportableId}>
                  <ReportItem
                    deleteReport={deleteReport}
                    report={report}
                    folder={folder}
                    openFolderIds={openFolderIds}
                    handleReportMove={() => {}}
                    duplicateReport={duplicateReport}
                    HandleChangeVisibility={HandleChangeVisibility}
                  />
                </li>
              ))}
            </ul>
          ) : null}
          {!hasChildrenOrReports && showLoader && (
            <div class="rounded-md p-4 max-w-sm w-[70%] mx-auto">
              <div class="animate-pulse flex space-x-4">
                <div class="flex-1 space-y-6 py-1">
                  <div class="space-y-3">
                    <div class="h-2 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!hasChildrenOrReports && !showLoader && (
            <div className="ml-[20px] py-1 text-gray-400 text-xs font-medium">
              This folder is empty
            </div>
          )}
        </>
      )}
    </div>
  );
};
