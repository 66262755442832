import { Button, Typography } from "@mui/material";
import React from "react";
import { InputWithLabel } from "components/common/molecules";
import PublicContainer from "components/layouts/PublicContainer";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";

const formStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "18px",
  width: "100%",
  marginTop: "70px",
};

export default function ResetPasswordScreen() {
  const navigate = useNavigate();
  return (
    <PublicContainer>
      <Typography variant="h1" sx={{ fontWeight: "700", textAlign: "center" }}>
        Set New Password
      </Typography>

      <Typography
        variant="h3"
        sx={{
          marginTop: "20px",
          color: "#7f7f7f",
          fontWeight: "300",
          textAlign: "center",
          lineHeight: 1.2,
        }}
      >
        Your New Password must be different to previous one
      </Typography>

      <div className="login-screen__form" style={formStyles}>
        <InputWithLabel
          label="Enter New Password"
          name="pass"
          placeholder="Password Should Be Strong"
        />
        <InputWithLabel
          label="Re-Enter Your Password"
          name="re-pass"
          placeholder="Password Should Be Strong"
        />
      </div>

      <div
        style={{
          marginTop: "70px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(APP_ROUTES.AUTH.LOGIN)}
          sx={{
            textTransform: "none",
          }}
        >
          Reset Password
        </Button>
      </div>
    </PublicContainer>
  );
}
