import { createContext } from "react";

const SidebarContext = createContext();

export default function ModernSidebar({ children, open }) {
  return (
    <aside className="min-h-screen max-h-screen">
      <nav
        // className={`${
        //   open ? "min-w-[240px]" : "w-full"
        // } max-w-[240px] h-full flex flex-col border-r shadow-sm`}
        className="w-full h-full flex flex-col border-r shadow-sm"
      >
        <div className="p-4 pb-2 flex justify-between items-center" />

        <SidebarContext.Provider value={{ open }}>
          <ul className="flex-1 px-3">{children}</ul>
        </SidebarContext.Provider>

        {/* <button
            onClick={() => {
              dispatch(dispatch(setSidebarOpen(!appState.isSidebarOpen)));
            }}
            className="sticky bottom-0 left-[20px] justify-center w-full p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
          >
            {open ? <ChevronFirst /> : <ChevronLast />}
          </button> */}
      </nav>
    </aside>
  );
}

export function SidebarItem({
  icon,
  text,
  active,
  alert,
  onClick,
  danger,
  open,
}) {
  return (
    <li
      onClick={onClick}
      className={`relative text-xs flex justify-start items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group ${
        active
          ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
          : danger
          ? "bg-red-200"
          : "hover:bg-indigo-50 text-gray-600"
      }`}
    >
      {icon}
      <span
        className={`overflow-hidden transition-all ${
          open ? "w-40 ml-3" : "hidden"
        }`}
      >
        {text}
      </span>
      {alert && (
        <div
          className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${
            open ? "" : "top-2"
          }`}
        ></div>
      )}

      {!open && (
        <div
          className={`absolute z-[500] left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-xs invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}
        >
          {text}
        </div>
      )}
    </li>
  );
}
