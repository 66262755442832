const saveUserTokenObj = (token) => {
  let tokenObj = JSON.stringify(token);
  localStorage.setItem("user_token", tokenObj);
};

const getUserTokenObj = () => {
  let tokenObj = localStorage.getItem("user_token");
  if (tokenObj) {
    return JSON.parse(tokenObj);
  }
  return null;
};

const removeUserTokenObj = () => {
  localStorage.removeItem("user_token");
};

const saveOrgTokenObj = (token) => {
  let tokenObj = JSON.stringify(token);

  localStorage.setItem("org_token", tokenObj);
};

const getOrgTokenObj = () => {
  let tokenObj = localStorage.getItem("org_token");
  if (tokenObj) {
    return JSON.parse(tokenObj);
  }
  return null;
};

const removeOrgTokenObj = () => {
  localStorage.removeItem("org_token");
};

const clearAllTokens = () => {
  localStorage.clear();
  sessionStorage.clear();
  (function () {
    var cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
      var d = window.location.hostname.split(".");
      while (d.length > 0) {
        var cookieBase =
          encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          d.join(".") +
          " ;path=";
        var p = window.location.pathname.split("/");
        document.cookie = cookieBase + "/";
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/");
          p.pop();
        }
        d.shift();
      }
    }
  })();
};

export {
  saveUserTokenObj,
  getUserTokenObj,
  removeUserTokenObj,
  saveOrgTokenObj,
  getOrgTokenObj,
  removeOrgTokenObj,
  clearAllTokens,
};
