import { Progress } from "@/components/ui/progress";
import React from "react";

const MetaLoader = ({ initialProgress, setInitialProgress }) => {
  React.useEffect(() => {
    const timer = setInterval(
      () =>
        setInitialProgress((prev) => {
          if (prev + 10 > 95) {
            return 95;
          } else {
            return prev + 10;
          }
        }),
      2000
    );
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <Progress
        value={initialProgress}
        className="w-[20%] text-[#3733aa] h-2 [&>*]:bg-[#3733aa] [&>*]:animate-pulse"
      />
    </>
  );
};

export default MetaLoader;
