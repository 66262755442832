import { Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const PredictionReportDetailList = (props) => {
  const { title, value, child } = props;
  return (
    <Stack
      direction="column"
      sx={{
        width: "100%",
        padding: "8px",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "max-content 1fr",
          width: "100%",
          gap: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <CircleIcon sx={{ fontSize: "10px" }} />
            <Typography
              sx={{ fontWeight: "700", color: "#1C1E20", fontSize: "16px" }}
            >
              {title}:
            </Typography>
          </div>
        </div>
        {value && (
          <div>
            {typeof value === "object" ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",

                  gap: "8px",
                }}
              >
                {value.map((item) => {
                  const [key, value] = item?.split(":");
                  return (
                    <div
                      style={{
                        display: "flex",
                        fontSize: "14px",
                        gap: "8px",
                        backgroundColor: "#ededed",
                        padding: "8px 16px",
                        borderRadius: "16px",
                        wordBreak: "break-all",
                        maxWidth: "max-content",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "700",
                          minWidth: "max-content",
                        }}
                      >{`${key ?? ""}${!!value ? ":" : ""} `}</span>
                      {value ?? ""}
                    </div>
                  );
                })}
              </div>
            ) : (
              <Typography sx={{ fontWeight: "500", color: "#1C1E2070" }}>
                {value ?? ""}
              </Typography>
            )}
          </div>
        )}

        {child && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",

              gap: "8px",
            }}
          >
            {child}
          </div>
        )}
      </div>
    </Stack>
  );
};

export default PredictionReportDetailList;
