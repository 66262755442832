import PrivateContainer from "components/layouts/PrivateContainer";
import { OrganisationSettings } from "./Tabs";

import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useDispatch } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";
import { APP_ROUTES } from "routes";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";
import {
  Cable,
  HandPlatter,
  Plus,
  Receipt,
  Settings,
  SquareArrowOutUpRight,
  UserCog,
  UserRoundPlus,
  Users,
} from "lucide-react";

import useProfileDetails from "screens/Settings/Tabs/AccountDetail/Components/ProfileDetails/useProfileDetails";
import UserSettings from "./Tabs/AccountDetail/Components/ProfileDetails/ProfileDetails";
import {
  FeatureManagements,
  ManageUser,
} from "./Tabs/OrganisationSettings/Components";
import ManageBilling from "./Tabs/OrganisationSettings/Components/BillingManagements/Billing";
import { Toaster } from "@/components/ui/toaster";
import { A_PERMISSIONS } from "config/roles";
import useUserRole from "hooks/useUserRole";

const SettingsScreen = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [selectedTab, setSelectedtab] = useState("organisation");
  const location = useLocation();
  const { hasUserPermission } = useUserRole();

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("selectedTab")) {
      setSelectedtab(searchParams.get("selectedTab"));
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    params.set("selectedTab", selectedTab);
    setSearchParams(params);
  }, [selectedTab]);

  function handleClose() {
    dispatch(setModal(modalInitState));
  }

  useEffect(() => {
    const { selectedTab } = location?.state || {};
    if (selectedTab) {
      setSelectedtab(selectedTab);
    }
  }, [location]);

  let selectedTabContent;

  switch (selectedTab) {
    case "billing":
      selectedTabContent = <ManageBilling />;
      break;

    case "manageUser":
      selectedTabContent = (
        <ManageUser
          inviteUser={
            <Button
              variant="secondary"
              onClick={() =>
                dispatch(
                  setModal({
                    open: true,
                    type: MODALTYPES.INVITE_USER_MODAL,
                    payload: {},
                  })
                )
              }
            >
              <UserRoundPlus className="mr-2 h-4 w-4" /> Invite Member
            </Button>
          }
        />
      );
      break;

    case "featureManagement":
      selectedTabContent = <FeatureManagements />;
      break;

    case "organisation":
      selectedTabContent = <OrganisationSettings />;
      break;

    case "account":
    default:
      selectedTabContent = <UserSettings />;
      break;
  }

  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
      }}
      activePage={APP_ROUTES.SETTING}
    >
      <Toaster />
      <div className="flex h-full">
        <div className="flex flex-col mt-4 gap-x-4 justify-between w-[20%]">
          <div className="flex flex-col justify-start gap-y-6 max-h-screen overflow-scroll">
            <Typography variant="h2" className="mb-12">
              Settings
            </Typography>
            <div className="flex flex-col gap-y-4">
              <Typography variant="h4" className="mb-8 text-gray-400">
                Organization Settings
              </Typography>
              <div className="space-y-2">
                <Button
                  variant="ghost"
                  className={`w-full justify-between ${
                    selectedTab === "organisation" ? "bg-gray-100" : ""
                  }`}
                  onClick={() => setSelectedtab("organisation")}
                >
                  <span>
                    <Settings size={16} className="mr-2 inline-block" />
                    My Organization
                  </span>
                </Button>

                <Button
                  variant="ghost"
                  className={`w-full justify-between ${
                    selectedTab === "manageUser" ? "bg-gray-100" : ""
                  }`}
                  onClick={() => setSelectedtab("manageUser")}
                >
                  <span>
                    <Users size={16} className="mr-2 inline-block" />
                    Manage Users
                  </span>
                </Button>

                <Button
                  variant="ghost"
                  className={`w-full justify-between ${
                    selectedTab === "billing" ? "bg-gray-100" : ""
                  }`}
                  onClick={() => setSelectedtab("billing")}
                >
                  <span>
                    <Receipt size={16} className="mr-2 inline-block" />
                    Billing Management
                  </span>
                </Button>

                <Button
                  variant="ghost"
                  className={`w-full justify-between ${
                    selectedTab === "featureManagement" ? "bg-gray-100" : ""
                  }`}
                  onClick={() => setSelectedtab("featureManagement")}
                >
                  <span>
                    <HandPlatter size={16} className="mr-2 inline-block" />
                    Feature Management
                  </span>
                </Button>
              </div>
            </div>
            <div className="flex flex-col gap-y-4">
              <Typography variant="h4" className="mb-8 text-gray-400">
                Profile Settings
              </Typography>
              <div className="space-y-2">
                <Button
                  variant="ghost"
                  className={`w-full justify-between ${
                    selectedTab === "account" ? "bg-gray-100" : ""
                  }`}
                  onClick={() => setSelectedtab("account")}
                >
                  <span>
                    <UserCog size={16} className="mr-2 inline-block" />
                    My Profile
                  </span>
                </Button>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start gap-y-2">
            <Button
              variant="ghost"
              className="w-full justify-between"
              onClick={() => {
                dispatch(setModal(modalInitState));
                navigate(APP_ROUTES.AUTH.ADD_ORGANIZATION);
              }}
            >
              <span>
                <Plus size={16} className="mr-2 inline-block" />
                Add organization
              </span>
              <SquareArrowOutUpRight size={16} className="ml-4" />
            </Button>
            {hasUserPermission(A_PERMISSIONS.CONNECT_AD_ACOUNT) && (
              <Button
                variant="ghost"
                className="w-full justify-between"
                onClick={() => {
                  dispatch(setModal(modalInitState));
                  navigate(APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT);
                }}
              >
                <span>
                  <Cable size={16} className="mr-2 inline-block" />
                  My Connections
                </span>
                <SquareArrowOutUpRight size={16} className="ml-4" />
              </Button>
            )}
          </div>
        </div>

        <div className="w-[80%] max-h-screen">{selectedTabContent}</div>
      </div>
    </PrivateContainer>
  );
};

export default SettingsScreen;
