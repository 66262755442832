import React, { useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import useUserRole from "hooks/useUserRole";
import { A_PERMISSIONS } from "../../../../config/roles";

//JS docs
/**
 * @param {Object} props
 * @param {Array} props.tabs
 * @param {String} props.tabs.name
 * @param {JSX.Element} props.tabs.component
 * @param {Number} props.activeTabIndex
 * @param {Function} props.onTabChange
 * @returns {JSX.Element}
 * @constructor
 * @example
 * <TabNav tabs={[{name:"Tab 1", component:<Tab1/>}, {name:"Tab 2", component:<Tab2/>]} activeTabIndex={0} />
 */

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BottomBarTabs(props) {
  const { tabs = [], activeTabIndex = 0, onTabChange, tabProps = {} } = props;
  const { hasUserPermission } = useUserRole();

  const [activeTab, setActiveTab] = React.useState(
    activeTabIndex > tabs?.length ? 0 : activeTabIndex
  );

  function handleTabChange(index) {
    setActiveTab(index);
    onTabChange && onTabChange(index);
  }

  useEffect(() => {
    if (!hasUserPermission(A_PERMISSIONS.VIEW_ACCOUNT)) {
      setActiveTab(1);
    } else {
      setActiveTab(activeTabIndex);
    }
  }, [activeTabIndex]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Tabs
        value={activeTab}
        sx={{
          "& .MuiTab-root": {
            fontFamily: "DM Sans !important",
            fontWeight: "400 !important",
            fontSize: "14px !important",
            color: "#858383 !important",
            textTransform: "none !important",
          },

          "& .Mui-selected": {
            color: "#1C1E20 !important",
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#7167FB",
          },
        }}
      >
        {tabs.map((tab, index) => {
          if (
            tab.name === "My Account" &&
            !hasUserPermission(A_PERMISSIONS.VIEW_ACCOUNT)
          ) {
            return null;
          }
          return (
            <Tab
              key={index}
              label={tab?.name}
              onClick={() => handleTabChange(index)}
              {...a11yProps(0)}
            />
          );
        })}
      </Tabs>

      {/* {tabs[activeTab]?.component} */}
      {React.cloneElement(tabs[activeTab]?.component, tabProps)}
    </div>
  );
}
