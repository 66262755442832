import React from "react";
import OrganisationDetail from "./Components/OrganisationDetail";
import { useSelector } from "react-redux";

export default function ManageOrganisation() {
  const { selectedOrganisation: reduxSelectedOrganisation } = useSelector(
    (state) => state?.organisation
  );

  return (
    <OrganisationDetail selectedOrganisation={reduxSelectedOrganisation} />
  );
}
