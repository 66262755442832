import axios from "axios";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { API_URL } from "../config";
import { setLoading } from "../redux/slices/app.slices";
import { API_ROUTES, APP_ROUTES } from "../routes";
import useAuth from "./useAuth";
import { setUserToken } from "../redux/slices/auth.slice";
import { setOrganisationToken } from "../redux/slices/organisation.slice";
import {
  CONTROL_PANEL,
  FOLDERS,
  INSIGHTS,
  PRESETS,
  REPORTS,
  TYPEAHEAD,
} from "apis/apiEndPoints";

const disableLoaderUrls = [
  API_ROUTES.COMMON.APPLICATION_STATUS,
  API_ROUTES.TAGGING.GET_IMAGE_AI_TAGS,
  API_ROUTES.TAGGING.GET_VIDEO_AI_TAGS,
  API_ROUTES.TAGGING.GET_ORGANIZATION_TAGS,
  API_ROUTES.TAGGING.CREATE_CUSTOM_AUDIENCE_TAGS,
  API_ROUTES.ORGANISATION.SMART_FILTERS,
  API_ROUTES.ONBOARDING.LIST_ORG_GROUPS,
  REPORTS.GET_REPORTS,
  REPORTS.BOOKMARK_REPORT,
  INSIGHTS.GET_SPECIFIC_INSIGHT,
  INSIGHTS.INSIGHTS_FILTERS,
  INSIGHTS.GET_INSIGHT_DATA,
  API_ROUTES.ORGANISATION.GET_AD_ACCOUNTS,
  API_ROUTES.ORGANISATION.GET_USERS,
  API_ROUTES.COMMON.GET_ORG_MODEL_KPIS,
  REPORTS.SHORT_REPORT_URL,
  FOLDERS.CREATE_FOLDER,
  FOLDERS.GET_ORGANIZATION_FOLDERS,
  FOLDERS.GET_REPORTS_IN_FOLDER,
  FOLDERS.DELETE_FOLDER,
  FOLDERS.RENAME_FOLDER,
  INSIGHTS.CREATE_INSIGHTS_REPORT,
  API_ROUTES.ORGANISATION.EDIT_DETAILS,
  API_ROUTES.ORGANISATION.ADD_USER,
  API_ROUTES.ORGANISATION.AUDIENCE_SEGMENT,
  REPORTS.GET_REPORT_MESSAGE,
  PRESETS.GET_SPECIFIC_PRESET,
  PRESETS.GET_PRESETS,
  "/ad-account",
  TYPEAHEAD,
  CONTROL_PANEL.HEARTBEAT,
];

function replaceParams(pattern, url) {
  const patternParts = pattern.split("/");
  const urlParts = url.split("/");
  if (patternParts.length !== urlParts.length) return false;
  return patternParts.every((part, index) => {
    return part[0] === ":" || part === urlParts[index];
  });
}

function handleRequest(config, dispatch, userToken, orgToken) {
  const newConfigUrl = config?.url?.split("?")?.[0];
  if (
    disableLoaderUrls.findIndex((item) => replaceParams(item, newConfigUrl)) ===
      -1 &&
    window?.location?.pathname !== APP_ROUTES.UNAUTHORIZED
  ) {
    dispatch(setLoading(true));
  }

  if (userToken) {
    config.headers.Authorization = `Bearer ${userToken}`;
  } else if (orgToken) {
    config.headers.Authorization = `Bearer ${orgToken}`;
  }

  const deviceClient = window.navigator.userAgent.includes("Mobile")
    ? "mobile"
    : "web";
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const deviceLang = navigator.language || navigator.userLanguage;

  config.headers["x-device-deviceClient"] = deviceClient;
  config.headers["x-device-timezone"] = timezone;
  config.headers["x-device-deviceLang"] = deviceLang;
  config.headers["x-device-appVersion"] = "v1";
  config.headers["x-device-deviceHash"] = "hellooo";

  return config;
}

function handleResponse(response, dispatch) {
  let url = response?.request?.responseURL?.replace(API_URL, "");
  if (disableLoaderUrls.findIndex((item) => replaceParams(item, url)) === -1) {
    dispatch(setLoading(false));
  }
  const { status, data } = response;
  if (status === 200 || status === 201) {
    return data?.meta
      ? { ...data.data, meta: data.meta, data: data.data }
      : data?.data
      ? data.data
      : data;
  }
}

async function handleError(error, dispatch, authType, auth, organisation) {
  dispatch(setLoading(false));
  const { response } = error;
  const { status, data } = response;
  if (
    status === 400 ||
    status === 401 ||
    status === 403 ||
    status === 404 ||
    status === 422
  ) {
    if (status === 401 || status === 403) {
      if (
        data?.message === "Token expired! Please login again and the continue!"
      ) {
        try {
          // When token type is user
          if (authType === "user") {
            const refreshTokenAPIResponse = await axios({
              baseURL: API_URL,
              url: API_ROUTES.AUTH.REFRESH_USER_TOKEN,
              headers: {
                Authorization: `Bearer ${auth?.userData?.tokens?.userRefresh?.token}`,
              },
            });
            const { user } = refreshTokenAPIResponse?.data?.data;
            if (refreshTokenAPIResponse?.status === 200) {
              dispatch(setUserToken(refreshTokenAPIResponse.data.data));
              const { url, data } = error.config;
              const response = await axios({
                baseURL: API_URL,
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
                url,
                data,
              });

              return handleResponse(response, dispatch);
            }
          } else if (authType === "org") {
            // When token type is org

            const refresh_url = API_ROUTES.AUTH.GET_ORG_TOKEN.replace(
              ":organizationId",
              organisation?.selectedOrganisation?._id
            );

            const refreshTokenAPIResponse = (
              await axios({
                baseURL: API_URL,
                url: refresh_url,
                headers: {
                  Authorization: `Bearer ${auth?.userData?.tokens?.user?.token}`,
                },
              })
            ).data.data;

            dispatch(setOrganisationToken(refreshTokenAPIResponse?.token));
            const response = await axios({
              ...error.config,
              baseURL: API_URL,
              headers: {
                Authorization: `Bearer ${refreshTokenAPIResponse?.token}`,
              },
            });
            // This is the response of the original request after token refresh
            return handleResponse(response, dispatch);
          }
        } catch (error) {
          window.location.href = "/logout";
        }
      } else {
        toast.error(data?.message);
      }
    }
    //Token expired! Please login again and the continue!
    return Promise.reject(data?.message);
  }
  return Promise.reject(error);
}

function useCreateAxiosInstance(tokenType) {
  const { auth, organisation } = useSelector((state) => state);
  const { userToken, orgToken } = useAuth();
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const dispatch = useDispatch();
  // Memoize the axios instance
  const instance = useMemo(() => {
    const newInstance = axios.create({
      timeout: 300000,
      baseURL: API_URL,
    });
    newInstance.interceptors.request.use((config) =>
      handleRequest(
        config,
        dispatch,
        tokenType === "user" ? userToken : null,
        tokenType === "org" ? orgToken : null
      )
    );
    newInstance.interceptors.response.use(
      (response) => handleResponse(response, dispatch),
      (error) => handleError(error, dispatch, tokenType, auth, organisation)
    );
    return newInstance;
  }, [
    dispatch,
    tokenType,
    userToken,
    orgToken,
    auth,
    organisation,
    selectedOrganisation,
  ]);
  return instance;
}

export default function useAxios() {
  return {
    axiosInstance: useCreateAxiosInstance(),
    axiosUserInstance: useCreateAxiosInstance("user"),
    axiosOrgInstance: useCreateAxiosInstance("org"),
  };
}
