import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Typography, Stack, Chip } from "@mui/material";

import { PrivateContainer } from "components/layouts";
import { APP_ROUTES } from "routes";
import { TitleBar } from "components/common/molecules";
import { GenerationContainer, GenerationPill } from "../../Components";
import useInsights from "screens/Insights/useInsights";
import MultipleValuesFilter from "components/common/atoms/CustomSelect/MultipleValuesFilter";

export default function RecommendationScreen() {
  const { insightState, onFilterUpdate } = useInsights();
  const navigate = useNavigate();
  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
        height: "auto",
        paddingBottom: "32px",
      }}
      activePage={APP_ROUTES.GENERATION.default}
    >
      <Stack direction="column" spacing={3} marginBottom={3}>
        <Stack direction="column" spacing={3}>
          <TitleBar
            title="Generation ✨"
            description="Create ad copy and designed to boost conversions quickly."
          />
        </Stack>
        <Stack direction="column" spacing={3}>
          <GenerationContainer>
            <Stack direction="column" spacing={1} alignItems="flex-start">
              <GenerationPill label="Step 1 / 3" />
            </Stack>
            <Stack direction="row">
              <Typography variant="h3">
                What this image will be used{" "}
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography>Choose the filter </Typography>
              <Stack direction="row">
                <Stack direction="row" gap={2} flexWrap={"wrap"}>
                  {Object.entries(insightState?.filters).map(([key, value]) => {
                    if (key === "dateRange" || key === "sortOrder") return null;
                    let defaultOption =
                      value?.options?.filter((item) => item.default) || [];
                    return (
                      <MultipleValuesFilter
                        key={key}
                        id={key}
                        options={value?.options}
                        label={value?.label}
                        selectedOption={defaultOption}
                        onChange={(selectedOption) => {
                          onFilterUpdate(key, selectedOption);
                        }}
                      />
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="flex-start">
              <Button
                sx={{ textTransform: "capitalize" }}
                variant="contained"
                onClick={() => {
                  navigate(APP_ROUTES.GENERATION.GENERATION_STEP_2);
                }}
              >
                Next
              </Button>
            </Stack>
          </GenerationContainer>
        </Stack>
      </Stack>
    </PrivateContainer>
  );
}
