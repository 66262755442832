import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    maxWidth: "80vw",
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "16px",
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridGap: "10px",
      width: "100%",
      // display: "flex",
      // flexDirection: "row",
      // flexWrap: "wrap",
    },
    "& .MuiMenuItem-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      flexWrap: "wrap",

      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function MetricsFilter(props) {
  const {
    label = "Label Missing",
    optionsList = [],
    selectedOption = {},
    onChange,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        sx={{
          border: "1px solid #A1B0CC",
          backgroundColor: "#FFFFFF",
          textTransform: "none",
          fontSize: "14px",
          height: "36px",
        }}
        endIcon={<KeyboardArrowDownIcon />}
        variant="outlined"
      >
        <span>{label}</span>
        <span
          style={{
            marginLeft: "6px",
            fontSize: "10px",
            color: "#1C1E20",
            fontWeight: "400",
          }}
        >
          {selectedOption?.label}
        </span>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {optionsList?.map((option) => (
          <MenuItem
            onClick={() => {
              onChange(label, option);
              handleClose();
            }}
            disableRipple
            sx={{
              border: `1px solid ${
                selectedOption?.id === option?.id ? "#7167FB" : "#FFFFFF"
              }`,
              borderRadius: "4px",
            }}
            key={option?.key}
          >
            <div
              style={{
                display: "flex",
                maxWidth: "200px",
              }}
            >
              {option?.label?.slice(0, 40)}
            </div>
            <div
              style={{
                display: "flex",
                maxWidth: "200px",
                flexWrap: "wrap",
                flexDirection: "column",
              }}
            >
              {option?.label?.slice(40, 80)}
            </div>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
