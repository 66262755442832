export const APP_ROUTES = {
  LANDING_PAGE: "/",
  DASHBOARD: "/dashboard",
  AUTH: {
    LOGIN: "/login",
    LOGOUT: "/logout",
    SIGNUP: "/signup",
    FORGOT_PASSWORD: "/forgotpassword",
    RESET_PASSWORD: "/resetpassword",
    ORG_SELECTION_SCREEN: "/OrgSelectionScreen",
    VERIFICATION_LINK_SENT: "/verification-link-sent",
    VERIFICATION_LINK_EXP: "/verification-link-expired",
    VERIFIED: "/verified",
    GATHERING_DATA: "/gathering-data",
    SELECT_ROLE: "/select-role",
    ADD_ORGANIZATION: "/add-organisation",
    CONNECT_ADD_ACCOUNT: "/connect-ad-account",
    ACCOUNT_TYPE: "/account-type",
    SET_USER_NAME: "/user-selection",
    MULTIPLE_ADD_ACCOUNT: "/multiple-ad-account",
    CONFIRMATION: "/confirmation",
    LEADIE: "/leadsie",
  },

  PLATFORMS: {
    PLATFORM_CONNECT_ACCOUNTS: "/platforms/:platformId/accounts",
  },

  ONBOARDING: {
    LOADING: "/loading-groups",
    ONBOARDING_GROUPS: "/onboarding-groups",
    ONBOARDING_GROUPS_CREATIVES:
      "/onboarding-group-creatives/:groupEntity/:groupId",
    AUTO_TAGGING_PROCESSING: "/auto-tagging-processing",
    CONGRATULATIONS: "/congratulations",
  },

  GROUPING: {
    GROUPING_UNGROUPED: "/grouping",
  },

  GROUPS_TAGS: {
    default: "/groups-and-tags",
    UNGROUPED: {
      IMAGES: "/ungrouped/images",
      VIDEOS: "/ungrouped/videos",
    },
    UNTAGGED: {
      TEXT: "/untagged/text",
      AUDIENCE: "/untagged/audience",
    },
    TAGGING: {
      IMAGES: "/tagging/images",
      GROUP_CONTENT: "/tagging/group/:groupEntity/:groupId",
      VIDEOS: "/tagging/videos",
      TEXT: "/tagging/text",
      AUDIENCE: "/tagging/audience",
      AUDIENCE_SEGMENTS: "/audience_segments",
    },
  },
  TAGGED: {
    TAGGED_IMAGES_GROUP: "/images/group/tagged",
    TAGGED_VIDEOS_GROUP: "/videos/group/tagged",
    TAGGED_TEXTS_GROUP: "/texts/group/tagged",
    TAGGED_AUDIENCES_GROUP: "/audiences/group/tagged",
  },

  TAGGING: {
    IMAGE_TAGGING: "/image-tagging",
    VIDEO_TAGGING: "/video-tagging",
    TEXT_TAGGING: "/text-tagging",
    AUDIENCE_TAGGING: "/audience-tagging",
    IMAGE_TAGGING_GROUP_CONTENT: "/image-tagging-group-content/:groupId",
    VIDEO_TAGGING_GROUP_CONTENT: "/video-tagging-group-content/:groupId",
  },

  REPORTS: {
    default: "/reports",
    REPORT_DETAIL: "/reports/:reportId",
  },

  VIDEO_BREAKDOWN: {
    default: "/video-breakdown",
  },

  BRAND_ASSETS: {
    default: "/brand-assets",
  },

  RECOMMENDATION: {
    default: "/recommendation/:recommendationType",
    CREATIVE_RECOMMENDATION: "/recommendation/creativeFeature",
    VIDEO_RECOMMENDATION: "/recommendation/videoIntelligence",
    REPORT_DETAIL: "/recommendation/:recommendationType/:reportableId",
  },
  SETTING: "/setting",
  ADD_AD_ACCOUNT: "/add-ad-account",
  PREDICTION: {
    default: "/prediction",
    PREDICTION_REPORT: "/prediction/:reportableId",
    PREDICTION_REPORT_DETAILS: "/prediction/report/:reportableId",
    PREDICTION_STEP_2: "/prediction/step2",
    PREDICTION_STEP_3: "/prediction/step3",
  },
  SELECT_ORGANIZATION: "/selectOrganization",
  ADSID: "/adsId",
  INSIGHTS: {
    default: "/insights",
    INSIGHTS_REPORTS: "/insights/:reportId",
    COMPARE: "/compare",
    COMAPRE_REPORT: "/compare/:reportId",
    COMPARE_DATE: "/compare-dates",
    COMPARE_DATE_REPORT: "/compare-dates/:reportId",
    AD_BREAKDOWN: "/ad-breakdown",
    AD_BREAKDOWN_REPORT: "/ad-breakdown/:reportId",
    CREATIVE_INSIGHT: "/creative-insight",
    CREATIVE_INSIGHT_REPORT: "/creative-insight/:reportId",
    CREATIVE_MOMENTUM_FATIGUE: "/creative-momentum",
    CREATIVE_MOMENTUM_FATIGUE_REPORT: "/creative-momentum/:reportId",
  },
  GENERATION: {
    default: "/generation/step1",
    GENERATION_STEP_2: "/generation/step2",
    GENERATION_STEP_3: "/generation/step3",
  },
  UPGRADE_PLAN: "/upgrade-plan",
  SUGGESTED: "/suggested",
  ASSESTS: "/assets",
  ALL: "*",
  UNAUTHORIZED: "/block",
};
