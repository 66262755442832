import { createSlice } from "@reduxjs/toolkit";

const initialOrgState = {
  onBoardingStep: 0,
  allOrganisations: [],
  selectedOrganisation: null,
  organisationFilters: null,
  reportOrganisationId: null,
  folders: [],
  tags: null,
  AdAccounts: null,
  showArchiveFolder: false,
};

const organisationSlice = createSlice({
  name: "organisation",
  initialState: initialOrgState,
  reducers: {
    setOnBoardingStep: (state, action) => {
      state.onBoardingStep = action.payload;
    },

    setAllOrganisations: (state, action) => {
      state.allOrganisations = action.payload;
    },

    setSelectedOrganisation: (state, action) => {
      state.selectedOrganisation = action.payload;
    },

    setOrganisationHeartbeat: (state, action) => {
      state.selectedOrganisation = {
        ...state.selectedOrganisation,
        heartbeat: action.payload,
      };
    },

    setOrganisationToken: (state, action) => {
      state.selectedOrganisation = {
        ...state.selectedOrganisation,
        token: action.payload,
      };
    },
    resetOrganisations: (state) => {
      state.selectedOrganisation = initialOrgState;
    },

    setOrganisationFilters: (state, action) => {
      state.organisationFilters = action.payload;
    },

    setCurrentReportOrgId: (state, action) => {
      state.reportOrganisationId = action.payload;
    },
    setOrganisationTags: (state, action) => {
      state.tags = action.payload;
    },
    setOrganisationAdAccounts: (state, action) => {
      state.AdAccounts = action.payload;
    },
    setOrganisationFolders: (state, action) => {
      state.folders = action.payload;
    },
    toggleShowArchiveFolder: (state) => {
      state.showArchiveFolder = !state.showArchiveFolder;
    },
  },
});

export const {
  setOnBoardingStep,
  setAllOrganisations,
  setSelectedOrganisation,
  setOrganisationToken,
  resetOrganisations,
  setOrganisationFilters,
  setCurrentReportOrgId,
  setOrganisationTags,
  setOrganisationAdAccounts,
  setOrganisationFolders,
  toggleShowArchiveFolder,
  setOrganisationHeartbeat,
} = organisationSlice.actions;

export default organisationSlice.reducer;
