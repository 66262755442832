import * as React from "react";

const GroupAnalytics = ({ isActive }) => {
  const color = isActive ? "#7167FB" : "#858383";
  return (
    <svg width={18} height={19} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.25 2.336H4.5v-1.5H.75v3.75h1.5v9.75H.75v3.75H4.5v-1.5h9.75v1.5H18v-3.75h-1.5v-9.75H18V.836h-3.75v1.5Zm-12 0H3v.75h-.75v-.75ZM3 16.586h-.75v-.75H3v.75Zm13.5 0h-.75v-.75h.75v.75Zm-.75-14.25h.75v.75h-.75v-.75Zm0 12h-1.5v1.5H4.5v-1.5H3v-9.75h1.5v-1.5h9.75v1.5h1.5v9.75Z"
        fill={color}
      />
    </svg>
  );
};

export default GroupAnalytics;
