import { Badge, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { API_ROUTES, APP_ROUTES } from "routes";
import useAxios from "hooks/useAxios";
import {
  FIVETRAN_PLATFORM_FACEBOOK_ADS,
  PLATFORM_STATUS,
} from "../../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setOrganisationToken } from "redux/slices/organisation.slice";
import { toast } from "react-toastify";
import { Lock } from "lucide-react";
import { PrivateContainer } from "components/layouts";
import { Button as ButtonShadcn } from "@/components/ui/button";

const customPlatforms = [
  {
    _id: "kasmdlamldmaldmlad",
    status: 0,
    isDisabled: true,
    comingSoon: {
      isComingSoon: true,
      percentageCompleted: "20%",
    },
    iconUrl: "https://cdn-icons-png.flaticon.com/128/15789/15789316.png",
    name: "Tiktok",
  },
  {
    _id: "kasmdlamldmaldasdasd",
    status: 0,
    isDisabled: true,
    comingSoon: {
      isComingSoon: true,
      percentageCompleted: "20%",
    },
    iconUrl: "https://cdn-icons-png.flaticon.com/128/1383/1383260.png",
    name: "Youtube",
  },
  {
    _id: "kasmdlamldmalsadadads",
    status: 0,
    isDisabled: true,
    comingSoon: {
      isComingSoon: true,
      percentageCompleted: "20%",
    },
    iconUrl: "https://cdn-icons-png.flaticon.com/128/3670/3670166.png",
    name: "Snapchat",
  },
  {
    _id: "kasmdlamldmaasdasdad",
    status: 0,
    isDisabled: true,
    comingSoon: {
      isComingSoon: true,
      percentageCompleted: "20%",
    },
    iconUrl: "https://cdn-icons-png.flaticon.com/128/11378/11378728.png",
    name: "Amazon",
  },
  {
    _id: "kasmdlamldmalasdasdd",
    status: 0,
    isDisabled: true,
    comingSoon: {
      isComingSoon: true,
      percentageCompleted: "20%",
    },
    iconUrl: "https://cdn-icons-png.flaticon.com/128/300/300221.png",
    name: "Google DV360",
  },
];

export default function AdAccountScreen() {
  const [connectId, code] = new URLSearchParams(
    window.location.href.split("?")[1]
  ).values();

  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const navigate = useNavigate();
  const bodyStyles = {
    alignItems: "flex-start",
    height: "100%",
  };

  const { axiosOrgInstance, axiosUserInstance, axiosInstance } = useAxios();
  const dispatch = useDispatch();
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const [platformsList, setPlatformsList] = React.useState([]);

  function getOrgToken() {
    axiosUserInstance
      .get(
        API_ROUTES.AUTH.GET_ORG_TOKEN.replace(
          ":organizationId",
          selectedOrganisation?._id
        )
      )
      .then((res) => {
        dispatch(setOrganisationToken(res?.token));
        getPlatformsList(res?.token);
      });
  }

  function getPlatformsList(token) {
    axiosInstance
      .get(API_ROUTES.AUTH.GET_PLATFORM, {
        headers: {
          Authorization: `Bearer ${token || selectedOrganisation?.token}`,
        },
      })
      .then((res) => {
        if (res?.docs) {
          setPlatformsList(res.docs);
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Error fetching available platforms");
      });
  }

  function connectPlatform(platform) {
    axiosOrgInstance
      .post(API_ROUTES.AUTH.ADD_PLATFORMS.replace(":platformId", platform?._id))
      .then((res) => {
        //find the platform and set the redirect uri
        window.location.href = res;
        const updatedPlatformsList = platformsList.map((item) => {
          if (item._id === platform._id) {
            return { ...item, redirectUri: res };
          }
          return item;
        });
        setPlatformsList(updatedPlatformsList);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function connectInternalConnector(platformId) {
    axiosOrgInstance
      .patch(API_ROUTES.AUTH.ADD_INTERNAL_PLATFORM_CONNECT_CALLBACK, {
        platformId,
        oAuthCode: code,
      })
      .then((res) => {
        getPlatformsList();
        navigate(APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getConnectedPlatforms() {
    return (
      platformsList?.filter((item) => {
        return item?.connected === true;
      }) || []
    );
  }

  function getFivtranFacebookAdsPlatform() {
    return platformsList?.find((item) => {
      return item?.name === FIVETRAN_PLATFORM_FACEBOOK_ADS;
    });
  }

  useEffect(() => {
    if (selectedOrganisation?.token) {
      getPlatformsList(selectedOrganisation?.token);
    } else {
      getOrgToken();
    }
  }, []);

  useEffect(() => {
    if (platformsList?.length > 0) {
      const connectedPlatforms = getConnectedPlatforms();
      if (connectedPlatforms.length > 0) {
        setNextBtnDisabled(false);
      }
    }
  }, [platformsList]);

  function performPlatformCardBtnAction(platform) {
    const orgPlatform = platform;
    if (orgPlatform) {
      switch (orgPlatform?.connected) {
        case true:
          navigate(
            APP_ROUTES.PLATFORMS.PLATFORM_CONNECT_ACCOUNTS.replace(
              ":platformId",
              orgPlatform?._id
            ) +
              `?internal=${orgPlatform?.isInternal || false}&pagePosts=${
                platform?.name?.includes("Page Posts") || false
              }`
          );
          break;
        default:
          return connectPlatform(platform);
      }
    }
  }

  // getPlatformStatus
  function getPlatformStatus(platform) {
    const orgPlatform = platform;
    if (orgPlatform) {
      switch (orgPlatform?.connected) {
        case true:
          return "Modify";
        default:
          return "CONNECT";
      }
    }
    return "CONNECT";
  }

  useEffect(() => {
    if (connectId && code) {
      const [organizationId, platformId, isInternal] = connectId?.split("_");
      if (isInternal || Boolean(isInternal)) {
        const platform = platformsList?.find(
          (item) => item?._id === platformId
        );
        if (platform && platform?.status < PLATFORM_STATUS.CONNECTED) {
          connectInternalConnector(platformId);
        }
      }
    }
  }, [connectId, code, platformsList]);

  return (
    <PrivateContainer
      bodyStyles={{
        padding: 0,
        gap: 0,
      }}
    >
      <Stack
        direction="column"
        sx={{ width: "100%", height: "100%", justifyContent: "space-between" }}
      >
        <Stack
          flexDirection={"row"}
          padding={"20px 50px"}
          className="flex flex-row justify-between items-center space-y-1.5 sticky top-0 pb-6 bg-white z-[2] "
        >
          <Stack className="flex flex-col space-y-1.5 sticky top-0 bg-white z-[2]">
            <Typography
              variant="h3"
              sx={{
                fontSize: "1.25rem",
                fontWeight: 600,
              }}
            >
              Connect Ad Accounts
            </Typography>
            <Typography className="!text-sm text-muted-foreground">
              Connect the platform profiles with access to the ad accounts
              linked with Storyteller. This ensures full data access and faster
              report loads.
            </Typography>
          </Stack>
        </Stack>
        <Stack flexDirection={"column"} gap={2} padding={"0px 50px"} flex={1}>
          {platformsList &&
            platformsList.length > 0 &&
            platformsList?.concat(customPlatforms).map((platform) => (
              <Badge
                sx={{ width: "100%" }}
                badgeContent={
                  platform?.comingSoon?.isComingSoon ? (
                    <div class="absolute inline-flex items-center justify-center w-8 h-8 text-xs font-bold text-red-500 bg-red-100 border-2 border-white rounded-full -top-1 -start-2 dark:border-gray-900">
                      <Lock color="red" size={12} />
                    </div>
                  ) : platform?.connected ? (
                    ""
                  ) : (
                    ""
                  )
                }
              >
                <Stack
                  key={platform?._id}
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    gap: "16px",
                    border: "1px solid #e2e2e2",

                    borderRadius: "8px",

                    width: "100%",
                    padding: "24px",
                    ...(platform?.isDisabled && {
                      pointerEvents: "none",
                    }),
                    background: "white",
                  }}
                >
                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    gap={4}
                    justifyContent={"space-between"}
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
                      <img
                        src={platform?.iconUrl}
                        alt=""
                        style={{
                          height: "2rem",
                          width: "2rem",
                          objectFit: "contain",
                        }}
                      />
                      <Typography variant="body2" textAlign="center">
                        {platform?.name}
                      </Typography>
                    </Stack>
                    <Stack
                      flexDirection={"column"}
                      alignItems={"end"}
                      gap={"0.5rem"}
                    >
                      {platform?.comingSoon?.isComingSoon ? (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            fontSize: "0.75rem",
                            textDecoration: "none",
                          }}
                          disabled={true}
                        >
                          Coming Soon
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          variant="contained"
                          disabled={
                            typeof platform?.status === "number" &&
                            platform.status >= PLATFORM_STATUS.CONNECTED
                              ? true
                              : false
                          }
                          sx={{
                            fontSize: "0.75rem",
                            textDecoration: "none",
                            width: "min-content",
                          }}
                          onClick={() => performPlatformCardBtnAction(platform)}
                        >
                          {getPlatformStatus(platform)}
                        </Button>
                      )}
                      {platform?.redirectUri && (
                        <a
                          className="text-xs text-primary underline cursor-pointer hover:text-[#7167FB]"
                          href={platform?.redirectUri}
                        >
                          Click here to connect
                        </a>
                      )}
                    </Stack>
                  </Stack>
                  {/* {platform?.comingSoon?.isComingSoon ? (
                  <Button
                    variant="contained"
                    disabled={true}
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    {`COMING SOON`}
                  </Button>
                ) : (
                  <>
                    <Button
                      disabled={
                        typeof platform?.status === "number" &&
                        platform.status >= PLATFORM_STATUS.CONNECTED
                          ? true
                          : false
                      }
                      variant="contained"
                      onClick={() => performPlatformCardBtnAction(platform)}
                    >
                      {getPlatformStatus(platform)}
                    </Button>
                  </>
                )} */}

                  {/* {platform?.redirectUri && (
              <a href={platform?.redirectUri}>Click here to connect</a>
            )} */}
                </Stack>
              </Badge>
            ))}
        </Stack>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            padding: "20px 50px",
          }}
          className="py-4 justify-end sticky bottom-0 bg-white z-[2] "
        >
          <ButtonShadcn
            variant="secondary"
            color="primary"
            sx={{
              textTransform: "none",
              fontSize: "0.75rem",
            }}
            onClick={() => navigate(APP_ROUTES.DASHBOARD)}
          >
            Back
          </ButtonShadcn>
          <ButtonShadcn
            disabled={nextBtnDisabled}
            color="primary"
            sx={{
              textTransform: "none",
              fontSize: "0.75rem",
              marginTop: "0",
            }}
            onClick={() => navigate(APP_ROUTES.ONBOARDING.LOADING)}
          >
            Next
          </ButtonShadcn>
        </Stack>

        {/* <div className="w-full mt-20 flex justify-end gap-4">
            <Button
              variant="outlined"
              color="primary"
              sx={{
                textTransform: "none",
              }}
              onClick={() => navigate(APP_ROUTES.SETTING)}
            >
              Back
            </Button>
            <Button
              disabled={nextBtnDisabled}
              variant="contained"
              color="primary"
              sx={{
                textTransform: "none",
              }}
              onClick={() => navigate(APP_ROUTES.ONBOARDING.LOADING)}
            >
              Next
            </Button>
          </div> */}
      </Stack>
    </PrivateContainer>
  );
}
