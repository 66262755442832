import React from "react";

const Logo = ({ height = "28", width = "48" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 72 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7003 63.0963C41.5499 60.3128 48.7408 56.8058 55.2713 52.5258C68.6191 43.7785 73.1334 36.9005 70.4258 30.0537C65.3408 24.609 59.073 20.4859 49.4226 20.167C46.4166 46.4571 31.8937 64.5382 12.5986 64.7436C18.3402 67.0089 25.6828 65.943 33.7003 63.0963Z"
        fill="#471FD2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.4286 30.0564C68.34 24.7772 61.9514 19.5145 51.9997 13.4285C46.2678 9.92323 40.0405 7.08867 33.5004 4.5444C24.5983 1.08243 16.3712 -0.538937 10.3313 2.84331L7.78882 4.69258L7.78206 4.69778C4.91996 11.5905 6.26989 18.2034 9.67853 24.6854C35.3872 16.4659 56.1181 17.3775 70.4286 30.0564Z"
        fill="#8A86FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.78203 4.69774C5.54082 6.76972 3.72159 9.801 2.43732 14.029C-0.767577 24.577 -0.840964 41.3851 2.39 52.141C4.45064 58.9991 8.01088 62.9343 12.5985 64.7437C22.7462 64.9005 30.308 61.065 35.6691 53.8482C16.3326 41.0749 4.74225 25.4609 7.78203 4.69774Z"
        fill="#563DEA"
      />
    </svg>
  );
};

export default Logo;
