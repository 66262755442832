import { Download } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import React from "react";

const GenerationImageCard = (props) => {
  const { id, assetUrl } = props;
  return (
    <Stack
      sx={{
        minWidth: "254px",
        maxHeight: "254px",
        minHeight: "264px",
        maxHeight: "264px",
        overflow: "hidden",
        borderRadius: "6px",
        position: "relative",
      }}
    >
      <img
        src={assetUrl}
        alt={`card_${id}_image`}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
      <div
        style={{
          position: "absolute",
          bottom: "0",
          borderRadius: "0",
          width: "100%",
          backgroundColor: "#000",
          display: "flex",
          justifyContent: "space-between",
          padding: "8px",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            borderRadius: "24px",
            color: "#fff",
            padding: "4px 16px",
            fontSize: "10px",
            lineHeight: "15px",
            backgroundColor: "#FFFFFF20",
          }}
        >
          AI Generated
        </Stack>
        <Button
          variant="contained"
          sx={{
            display: "flex",
            textTransform: "capitalize",
            gap: "8px",
            alignItems: "center",
            backgroundColor: "#000",
            fontSize: "10px",
            lineHeight: "15px",
            "&:hover": {
              backgroundColor: "#000",
            },
          }}
        >
          Download <Download sx={{ fontSize: "14px" }} />
        </Button>
      </div>
    </Stack>
  );
};

export default GenerationImageCard;
