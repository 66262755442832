import LoginScreen from "./LoginScreen";
import OrgSelectionScreen from "./OrgSelectionScreen";
import SignupScreen from "./SignupScreen";
import ForgetPasswordScreen from "./ForgetPasswordScreen";
import ResetPasswordScreen from "./ResetPasswordScreen";

import GatheringDataScreen from "./StatusesScreens/GatheringDataScreen";
import VerificationLinkExpiredScreen from "./StatusesScreens/VerificationLinkExpiredScreen";
import VerificationLinkSentScreen from "./StatusesScreens/VerificationLinkSentScreen";
import VerifiedScreen from "./StatusesScreens/VerifiedScreen";

import RoleSelectScreen from "./SignupScreen/Steps/RoleSelectScreen";
import AddOrganisationScreen from "./SignupScreen/Steps/AddOrganisationScreen";
import AdAccountScreen from "./SignupScreen/Steps/AdAccountScreen";
import MultipleAdAccountScreen from "./SignupScreen/Steps/MultipleAdAccountScreen";

const AuthScreens = {
  LoginScreen,
  OrgSelectionScreen,
  SignupScreen,
  ForgetPasswordScreen,
  ResetPasswordScreen,
  RoleSelectScreen,
  AddOrganisationScreen,
  AdAccountScreen,
  MultipleAdAccountScreen,
};

const AuthStatusesScreens = {
  GatheringDataScreen,
  VerificationLinkExpiredScreen,
  VerificationLinkSentScreen,
  VerifiedScreen,
};

export { AuthScreens, AuthStatusesScreens };
