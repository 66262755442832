// import { Button } from "@mui/material";
import React from "react";
import styles from "./styles.module.scss";
import SyncIcon from "@mui/icons-material/Sync";
import { useNavigate } from "react-router-dom";
import useVideoTab from "./useVideoTab";
import { BasicActionsModal } from "../../../../components/modals";
import { MODALTYPES } from "../../../../redux/slices/modal.slices";
import { APP_ROUTES } from "../../../../routes";
import { ContentContainer } from "../../../../components/layouts";
import { storageUrlToCdn } from "utils/helper";
import { Button } from "@/components/ui/button";

const Card = (props) => {
  const {
    _id,
    groupName,
    totalGroupEntities,
    isPlaying,
    groupThumbnailUrl,
    isMerging,
    isSelected,
    handleSelect,
    handlePlayPause,
  } = props;
  const navigate = useNavigate();

  function handleClick() {
    if (!isMerging) {
      navigate(
        APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS_CREATIVES?.replace(
          `:groupEntity`,
          "video"
        )?.replace(`:groupId`, _id),
        {
          option: { state: { groupName: groupName } },
        }
      );
    } else {
      handleSelect(_id);
    }
  }
  return (
    <div
      className={styles.creative_card}
      onClick={handleClick}
      style={{ ...(isSelected && { border: "2px solid #3f51b5" }) }}
    >
      <div className={styles.thumbnail}>
        <img
          src={storageUrlToCdn(groupThumbnailUrl)}
          alt="thumbnail"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "4px",
            objectFit: "cover",
          }}
        />
      </div>
      <div style={{ marginTop: "25px" }}>
        <div className={styles.creative_detail}>
          Total {totalGroupEntities} videos in this Group
        </div>
      </div>
    </div>
  );
};

export default function VideoTab() {
  const {
    initGroups,
    isMerging,
    toggleMerging,
    groups,
    handleSelect,
    handleMerge,
    handlePlayPause,
    handleSkipUpdate,
    addSysGeneratedTagsModalPrimaryAction,
    mergeModalPrimaryAction,
    totalDocs,
  } = useVideoTab();

  return (
    <div className={styles.image_tab__container}>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>Total Groups: {totalDocs ?? 0}</span>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "rgba(28, 30, 32, 0.5)",
              gap: "15px",
            }}
            onClick={initGroups}
          >
            Refresh <SyncIcon />
          </span>
        </div>
        <div className={styles.right}>
          <Button
            variant="secondary"
            onClick={toggleMerging}
            sx={{ textTransform: "none", fontSize: "1rem" }}
          >
            {!isMerging ? "Merge Groups" : "Cancel Merge"}
          </Button>
          {isMerging ? (
            <Button
              onClick={handleMerge}
              sx={{
                textTransform: "none",
                marginLeft: "1rem",
                fontSize: "1rem",
              }}
            >
              Update Merging
            </Button>
          ) : (
            <Button
              variant="secondary"
              onClick={handleSkipUpdate}
              sx={{ textTransform: "none", fontSize: "1rem" }}
            >
              Skip Updating Groups
            </Button>
          )}
        </div>
      </div>
      <ContentContainer
        className={styles.content}
        containerStyles={{
          maxHeight: "calc(100vh - 180px)",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(260px, 1fr))",
        }}
      >
        {groups?.map((group) => (
          <Card
            isMerging={isMerging}
            handleSelect={handleSelect}
            key={group?._id}
            handlePlayPause={handlePlayPause}
            {...group}
          />
        ))}
      </ContentContainer>
      <BasicActionsModal
        modalType={MODALTYPES.BASIC_ACTION.MERGE_GROUPS}
        primaryAction={mergeModalPrimaryAction}
      />
      <BasicActionsModal
        modalType={MODALTYPES.BASIC_ACTION.ADD_SYS_GENERATED_TAGS}
        primaryAction={addSysGeneratedTagsModalPrimaryAction}
      />
    </div>
  );
}
