import React from "react";
import { Stack, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const GenerationTextCard = (props) => {
  const { title, subTitle } = props;
  return (
    <Stack
      direction="column"
      sx={{
        borderRadius: "10px",
        border: "1px solid #EAECF0",
        minWidth: "136px",
        minHeight: "106px",
        overflow: "hidden",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ backgroundColor: "#F9F9F9", padding: "6px 24px" }}
      >
        <Typography sx={{ fontSize: "16px", color: "#1C1E20" }}>
          {title}
        </Typography>
        <InfoOutlined sx={{ fontSize: "12px", color: "#1C1E2070" }} />
      </Stack>
      <Stack
        sx={{
          padding: "24px 16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            lineHeight: "20px",
            color: "#1C1E2040",
            textTransform: "capitalize",
          }}
        >
          {subTitle}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default GenerationTextCard;
