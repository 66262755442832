import React from "react";
import { PrivateContainer } from "components/layouts";
import { APP_ROUTES } from "../../routes";
import { TitleBar } from "components/common/molecules";
import useReports from "./useReports";
import { ReportTable } from "./Components";
import { Stack } from "@mui/material";
import { Button } from "@/components/ui/button";
import TableLoader from "components/common/loaders/TableLoader";
import SEO from "utils/SEO";

const Reports = () => {
  const {
    reportsData,
    isReportLoading,
    bookmarkedReports,
    isViewBookmark,
    changeReportVisibility,
    handleUrlShortener,
    handleChangeViewToBookmark,
  } = useReports();

  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
      }}
      activePage={APP_ROUTES.REPORTS.default}
    >
      <SEO
        title="Reports"
        description="Creative reports powered by Storyteller"
        name="Reports by Storyteller"
        type="Report"
      />
      <Stack direction="column" spacing={3}>
        <Stack direction="column" spacing={3}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <h1 className="text-xl font-semibold">
              {isViewBookmark ? "Bookmarked Reports" : "Reports"}
            </h1>
            <Button variant="secondary" onClick={handleChangeViewToBookmark}>
              {isViewBookmark ? "View Reports" : "View Bookmarked Reports"}
            </Button>
          </Stack>
        </Stack>
        <ReportTable
          rows={isViewBookmark ? bookmarkedReports : reportsData}
          isReportLoading={isReportLoading}
          isViewBookmark={isViewBookmark}
          HandleChangeVisibility={changeReportVisibility}
          HandleShortUrl={handleUrlShortener}
        />
      </Stack>
    </PrivateContainer>
  );
};

export default Reports;
