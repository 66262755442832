import { useState } from "react";
import { auth } from "config/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_ROUTES, APP_ROUTES } from "routes";
import useAxios from "hooks/useAxios";

export default function useForgotPassword() {
  const navigate = useNavigate();
  const { axiosInstance } = useAxios();
  const [email, setEmail] = useState("");

  async function handlePasswordRest() {
    axiosInstance
      .get(API_ROUTES.USER.CHECK_USER_EXISTS.replace(":email", email))
      .then((res) => {
        if (res?.exists) {
          sendPasswordResetEmail(auth, email)
            .then(() => {
              navigate(APP_ROUTES.AUTH.VERIFICATION_LINK_SENT);
            })
            .catch((error) => {
              const errorMessage = error.message;
              toast.error(errorMessage);
            });
        } else {
          toast.info("User does not exists");
        }
      })
      .catch((err) => console.log(err));
  }
  return {
    email,
    handlePasswordRest,
    setEmail,
  };
}
