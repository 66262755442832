import { CometChat } from "@cometchat-pro/chat";
import { CometChatUIKit } from "@cometchat/chat-uikit-react";
import {
  useCreateRecommendationReport,
  useGetRecommendationFilters,
  useGetRecommendations,
  useGetSpecificRecommendation,
} from "apis/recommendationApi";
import { useBookmarkReport, useRemoveBookmark } from "apis/reportsApi";

import useAppState from "hooks/useAppState";
import useAxios from "hooks/useAxios";
import useReports from "hooks/useReports";
import { isEqual } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setBookmarkReports,
  setUserBookmarksAfterUnbookmarking,
} from "redux/slices/app.slices";
import {
  addExtraFilter,
  removeExtraFilterField,
  setAll,
  setDate,
  setFilters,
  setIsReportPublic,
  setMetrics,
  setSelectedMetrics,
  updateExtraFilterAggregateLogic,
} from "redux/slices/recomendation.slice";
import { API_ROUTES } from "routes";
import { checkIfGroupExists, createGroup } from "utils/CometChat";
import { updateExtraFilterFieldSelect } from "../../redux/slices/recomendation.slice";

const todaysDate = moment().format("YYYY-MM-DD");

function getRecommendationStateFromReport(report, insightState) {
  let date = report?.date;
  if (date?.selectionType !== 4) {
    date = {
      selectionType: date?.selectionType,
      startDate: todaysDate,
      endDate: todaysDate,
    };
  } else {
    date = {
      selectionType: date?.selectionType,
      startDate: date?.startDate?.value || date?.startDate,
      endDate: date?.endDate?.value || date?.endDate,
    };
  }
  const selectedMetrics = insightState?.metrics
    ?.filter((item) => {
      return report?.kpis?.includes(item?.kpi);
    })
    ?.map((item) => {
      return {
        label: item?.label,
        value: item?.kpi,
      };
    });
  const extraFilters = JSON.parse(report?.extraFilters);
  const filters = {};
  Object.entries(insightState?.filters).forEach(([key, value]) => {
    filters[key] = {
      ...value,
      options: value?.options?.map((item) => {
        if (report?.[key]?.includes(item?.key)) {
          return { ...item, default: true };
        }
        return { ...item, default: false };
      }),
    };
  });

  return {
    filters,
    selectedMetrics,
    date,
    metrics: insightState?.metrics,
    extraFilters,
  };
}

export default function useRecommendation() {
  const params = useParams();
  const { recommendationType } = params;
  const isVideoStage = recommendationType === "videoIntelligence";
  const { hideSidebar, disableFilters, currentReportOrgId } = useReports();
  const dispatch = useDispatch();
  const specificRecommendationId = params?.reportableId;
  const platforms = useSelector(
    (state) => state?.organisation?.selectedOrganisation?.platforms
  );
  const userData = useSelector((state) => state.auth.userData);

  const { organisationOnBoarded } = useAppState();

  const { data: insightFilters, refetch: refetchFilters } =
    useGetRecommendationFilters(organisationOnBoarded, currentReportOrgId);
  const {
    mutateAsync: getRecommendationData,
    data: recommendationData,
    isLoading: loadingRecommendationData,
  } = useGetRecommendations(currentReportOrgId);

  const { data: recommendationSpecificData, isLoading: loadingReport } =
    useGetSpecificRecommendation(
      insightFilters,
      specificRecommendationId,
      currentReportOrgId
    );

  const recommendationState = useSelector((state) => state.recommendation);

  const { mutateAsync: createRecommendationReport } =
    useCreateRecommendationReport();

  const prevRecommendationState = useRef();
  const [isSaveReportEnable, setIsSaveReportEnable] = useState(
    !specificRecommendationId
  ); // to check if the report is changed or not

  function getRecommendationPayloadFromRecommState(
    filters,
    kpis,
    date,
    platforms,
    isVideoStage = false,
    hideSidebar,
    report
  ) {
    const payload = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (key !== "metrics") {
        payload[key] = value?.options
          ?.filter((item) => item.default)
          ?.map((item) => item.key);
      }
    });
    payload.kpis = kpis;
    payload.date = date;
    payload.platformIds =
      platforms?.length > 0
        ? platforms?.map((item) => item?.platformId)
        : report?.platformIds;
    payload.isVideoStage = isVideoStage;
    if (isVideoStage) {
      payload.creativeTypes = ["VIDEO"];
    }
    if (hideSidebar || !organisationOnBoarded) {
      payload.organizationId = report?.organizationId;
    }
    if (report) {
      payload.adAccountIds = report?.adAccountIds;
    }
    if (report) {
      payload.targetingCountries = report?.targetingCountries;
    }
    payload.extraFilters = recommendationState?.extraFilters;
    return payload;
  }

  function willFilterEmpty(options) {
    const _options = options?.filter((option) => option?.default);
    if (_options?.length === 1) {
      return true;
    }
    return false;
  }

  function onFilterUpdate(key, selectedOption) {
    let _filter = { ...recommendationState?.filters };
    Object.entries(recommendationState?.filters).forEach(
      ([filterKey, value]) => {
        if (key === filterKey) {
          _filter[key] = {
            ...value,
            options: value?.options?.map((item) => {
              if (item.key === selectedOption.key) {
                if (item?.default && willFilterEmpty(value?.options)) {
                  toast("Minimum one filter is required");
                  return { ...item, default: true };
                } else {
                  return { ...item, default: !item.default };
                }
              }
              return item;
            }),
          };
        }
      }
    );
    dispatch(setFilters(_filter));
  }

  function onRemoveSelectedMetrics(index) {
    const _selectedMetrics = recommendationState?.selectedMetrics?.filter(
      (metric, idx) => idx !== index
    );
    if (_selectedMetrics?.length > 0) {
      dispatch(setSelectedMetrics(_selectedMetrics));
    } else {
      toast("Minimum one KPI selection is mandatory");
    }
  }

  function onSelectedMetricsUpdate(selectedMetrics) {
    dispatch(setSelectedMetrics(selectedMetrics));
  }

  function onDateDaysChange(config) {
    dispatch(setDate(config));
  }

  function onApplyFilter() {
    getRecommendationData(
      getRecommendationPayloadFromRecommState(
        recommendationState?.filters,
        recommendationState?.selectedMetrics?.map((item) => item.value),
        recommendationState?.date,
        platforms,
        isVideoStage
      )
    );
  }

  function handleExtraFilterSelectUpdate(index, value, key) {
    setSmartSuggestions([]);
    dispatch(updateExtraFilterFieldSelect({ index, value, key }));
  }

  function handleUpdateSearchExtraFilter(index, value, key) {
    dispatch(updateExtraFilterFieldSelect({ index, value, key }));
  }

  function handleAggregateLogicChangeExtraFilters(logic) {
    dispatch(updateExtraFilterAggregateLogic({ logic }));
  }

  function handleAddNewFilter() {
    dispatch(addExtraFilter());
  }

  function handleRemoveNewFilter(index) {
    dispatch(removeExtraFilterField({ index }));
  }

  const { axiosOrgInstance } = useAxios();
  const [isSmartSuggestionLoading, setIsSmartSuggestionLoading] =
    useState(false);
  const [smartSuggestion, setSmartSuggestions] = useState([]);
  const handleFetchSmartSuggestions = (q, key) => {
    setIsSmartSuggestionLoading(true);
    axiosOrgInstance
      .get(`${API_ROUTES.ORGANISATION.SMART_FILTERS}?q=${q}&type=${key}`)
      .then((res) => {
        let _temp = [];
        setIsSmartSuggestionLoading(false);
        res?.map((curr) => {
          _temp.push(curr[key]);
        });
        setSmartSuggestions(_temp || ["Nothing Found...."]);
      });
  };
  function handleSearchMotionFilters(type, q) {
    const key_enum = {
      1: "adName",
      2: "adSetName",
      3: "campaignName",
      4: "groupName",
    };
    switch (type) {
      case 1:
        setSmartSuggestions([]);
        handleFetchSmartSuggestions(q, key_enum[type]);
        break;
      case 2:
        setSmartSuggestions([]);
        handleFetchSmartSuggestions(q, key_enum[type]);
        break;
      case 3:
        setSmartSuggestions([]);
        handleFetchSmartSuggestions(q, key_enum[type]);
        break;
      case 4:
        setSmartSuggestions([]);
        handleFetchSmartSuggestions(q, key_enum[type]);
        break;
      default:
        break;
    }
  }

  function handleSetReportPublicChange() {
    dispatch(setIsReportPublic(!recommendationState?.isReportPublic));
  }

  const { mutateAsync: bookmarkReport, isSuccess: isBookmarkSuccess } =
    useBookmarkReport();

  function handleBookmarkReport(inputs) {
    const payload = {
      reportId: specificRecommendationId,
    };
    const res = bookmarkReport(payload);
    if (res) {
      dispatch(setBookmarkReports(specificRecommendationId));
      toast("Report bookmarked Successfully");
    }
  }

  function saveRecommendationReport(reportName = "Recommendation Report") {
    const kpis = recommendationState?.selectedMetrics?.map(
      (item) => item.value
    );

    const payload = {
      ...getRecommendationPayloadFromRecommState(
        recommendationState?.filters,
        kpis,
        recommendationState?.date,
        platforms,
        isVideoStage
      ),
      name: reportName,
      isPublic: recommendationState?.isReportPublic,
    };

    createRecommendationReport(payload);
  }

  const {
    mutateAsync: removeBookmarkMutate,
    isSuccess: isRemoveBookmarkSuccess,
    isError: isRemoveBookmarkError,
  } = useRemoveBookmark();
  function handleRemoveBookmark() {
    removeBookmarkMutate(specificRecommendationId);
  }

  useEffect(() => {
    if (isRemoveBookmarkSuccess) {
      toast("Removed from bookmarks");
      dispatch(setUserBookmarksAfterUnbookmarking(specificRecommendationId));
    } else if (isRemoveBookmarkError) {
      toast("Try again");
    }
  }, [isRemoveBookmarkError, isRemoveBookmarkSuccess]);

  const [currentGroup, setCurrentGroup] = useState(null);
  async function joinGroup(groupId, triedOnce = false) {
    try {
      await CometChat.joinGroup(groupId, CometChat.GROUP_TYPE.PUBLIC, "");
      CometChat.getGroup(groupId).then((group) => setCurrentGroup(group));
    } catch (error) {
      if (error?.code === "ERR_ALREADY_JOINED") {
        CometChat.getGroup(groupId).then((group) => setCurrentGroup(group));
      }

      if (error?.code === "USER_NOT_LOGED_IN") {
        if (triedOnce) {
          return;
        }

        if (!userData?.user) return;
        const authKey = process.env.REACT_APP_COMETCHAT_AUTH_KEY;
        const uid = userData?.user?._id;

        CometChatUIKit.login(uid, authKey)
          .then((loggedInUser) => {
            joinGroup(groupId, true);
          })
          .catch((loginError) => {
            console.log("Login Error:", loginError);
          });
      }
    }
  }

  useEffect(() => {
    if (insightFilters) {
      //set The first option as default if the option exist
      const filters = {};
      const kpis = [];

      Object.entries(insightFilters).forEach(([key, value]) => {
        if (value?.options?.length > 0) {
          filters[key] = {
            ...value,
            options: value?.options?.map((item, index) => {
              if (index === 0) {
                return { ...item, default: true };
              }
              return item;
            }),
          };
        } else if (key === "modelKpi") {
          kpis.push(value?.[0]?.kpi);
          const selectedMetrics = [
            {
              label: value?.[0]?.label,
              value: value?.[0]?.kpi,
            },
          ];

          dispatch(setMetrics(value));
          dispatch(setSelectedMetrics(selectedMetrics));
        }
      });

      dispatch(setFilters(filters));
      if (specificRecommendationId) return;
    }
  }, [insightFilters, recommendationType]);

  useEffect(() => {
    if (recommendationSpecificData) {
      const report = recommendationSpecificData?.[0]?.report;

      if (!report) return;
      let recommendationStateFromReport = getRecommendationStateFromReport(
        report,
        recommendationState
      );

      // Check if the group already exists
      const groupId = report?.reportableId;
      const groupName = report?.name;
      checkIfGroupExists(groupId).then((existingGroup) => {
        if (existingGroup) {
          // If group exists, join the user to the group
          joinGroup(groupId);
        } else {
          // If group does not exist, create the group and then join the user
          createGroup(groupId, groupName).then((newGroup) => {
            if (newGroup) {
              // Group created successfully, now join the user
              joinGroup(groupId);
            }
          });
        }
      });

      prevRecommendationState.current = recommendationStateFromReport;

      dispatch(setAll(recommendationStateFromReport));
      let payload = getRecommendationPayloadFromRecommState(
        recommendationStateFromReport?.filters,
        recommendationStateFromReport?.selectedMetrics?.map(
          (item) => item.value
        ),
        recommendationStateFromReport?.date,
        platforms,
        isVideoStage,
        hideSidebar,
        report
      );
      getRecommendationData(payload);
    }
  }, [recommendationSpecificData]);

  useEffect(() => {
    if (prevRecommendationState?.current) {
      if (!isEqual(prevRecommendationState?.current, recommendationState)) {
        setIsSaveReportEnable(true);
      } else {
        setIsSaveReportEnable(false);
      }
    }
  }, [recommendationState]);

  return {
    recommendationState,
    loadingReport,
    onFilterUpdate,
    onSelectedMetricsUpdate,
    onApplyFilter,
    handleBookmarkReport,
    onDateDaysChange,
    currentReportOrgId,
    recommendationData: recommendationData ?? {},
    saveRecommendationReport,
    isSaveReportEnable,
    loadingRecommendationData,
    hideSidebar,
    disableFilters,
    handleSetReportPublicChange,
    specificRecommendationId,
    isBookmarkSuccess,
    handleRemoveBookmark,
    handleSearchMotionFilters,
    handleUpdateSearchExtraFilter,
    handleExtraFilterSelectUpdate,
    handleAddNewFilter,
    handleRemoveNewFilter,
    handleAggregateLogicChangeExtraFilters,
    smartSuggestion,
    isSmartSuggestionLoading,
    isReportPublic: recommendationState?.isReportPublic,
    refetchFilters,
    onRemoveSelectedMetrics,
    currentGroup,
  };
}
