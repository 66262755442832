import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import useAxios from "hooks/useAxios";
import { COMMON, INSIGHTS } from "./apiEndPoints";
import { CACHE_TIME, REPORTABLE_TYPE } from "utils/constants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { modalInitState, setModal } from "redux/slices/modal.slices";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { useGetOrgFolders } from "./common";
import { refreshOpenFolderIds } from "redux/slices/folder.slice";
import useAuth from "hooks/useAuth";
const useGetInsightFilters = (isPostOnboarding, currentReportOrgId) => {
  const { axiosOrgInstance } = useAxios();
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const navigate = useNavigate();
  const { isUserAuthenticated } = useAuth();
  const queryKey = ["insightFilters", selectedOrganisation?._id];

  const data = useQuery(
    queryKey,
    () =>
      axiosOrgInstance
        .get(
          `/commons/filters?include="objectives,optimizationGoals,funnelStages,creativeTypes,targetingLocations,adAccountIds,kpi,platformIds"`
        )
        .then((res) => {
          const payload = {
            objectives: res.objectives,
            optimizationGoals: res.optimizationGoals,
            funnelStages: res.funnelStages,
            creativeTypes: res.creativeTypes,
            targetingCountries: res.targetingLocations,
            adAccountIds: res.adAccountIds,
            ...res,
          };
          delete payload.targetingLocations;
          return payload;
        }),
    {
      enabled: isPostOnboarding || !!currentReportOrgId,
      staleTime: CACHE_TIME,
      cacheTime: CACHE_TIME,
      refetchOnWindowFocus: false,
    }
  );
  if (!isUserAuthenticated) {
    if (!currentReportOrgId) {
      navigate("/block");
    }
  }

  return data;
};

const useGetInsightsData = (id, currentReportOrgId) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["insightsData"];
  // let isEnabled = !id;
  let isEnabled = false;
  return useMutation(
    queryKey,
    (payload) => {
      // if (!payload?.kpis?.length && !payload.sortBy) return;
      return axiosOrgInstance
        .post(
          INSIGHTS.GET_INSIGHT_DATA,
          {
            ...payload,
          },
          {
            params: {
              orgId: currentReportOrgId,
            },
          }
        )
        .then((res) => res)
        .catch((err) => toast.error(err?.message));
    },
    {
      enabled: isEnabled,
      queryKey: queryKey,
    }
  );
};
const useGetVideoBreakdown = (id, currentReportOrgId) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["insightsData"];
  // let isEnabled = !id;
  let isEnabled = false;
  return useMutation(
    queryKey,
    (payload) => {
      // if (!payload?.kpis?.length && !payload.sortBy) return;
      return axiosOrgInstance.post(
        INSIGHTS.GET_INSIGHT_DATA,
        {
          ...payload,
        },
        {
          params: {
            orgId: currentReportOrgId,
          },
        }
      );
    },
    {
      enabled: isEnabled,
      onSuccess: () => {},
      onError: (err) => toast.error(err?.message?.length ? err?.message : err),
      queryKey: queryKey,
    }
  );
};

const useUpdateInsightsReport = (reportableId) => {
  const dispatch = useDispatch();
  const { axiosOrgInstance } = useAxios();
  const { refetch: refetchRootFolders } = useGetOrgFolders(false);
  const { isUserAuthenticated, userData } = useAuth();
  const { selectedOrganisation } = useSelector((state) => state.organisation);

  return useMutation((payload) => {
    // if (!payload?.kpis?.length && !payload.sortBy) return;
    return axiosOrgInstance
      .patch(
        INSIGHTS.UPDATE_INSIGHTS_REPORT.replace(":reportableId", reportableId),
        {
          ...payload,
        }
      )
      .then((res) => {
        const report = res[0]?.report;

        toast.success("Saved succesfully");
        refetchRootFolders(true);
        dispatch(refreshOpenFolderIds());
        const eventPayload = {
          event: "save_report",
          User_Details: {
            user_id: userData?.user?._id,
            email: userData?.user?.email,
            first_name: userData?.user?.firstName,
            last_name: userData?.user?.lastName,
            username: userData?.user?.userName,
            user_type: userData?.user?.userType,
            login_status: isUserAuthenticated,
            created_at: userData?.user?.createdAt,
          },
          report: {
            id: report?.reportableId,
            name: report?.name,
            description: report?.description,
            reportable_type: report?.reportableType,
            is_public: report?.isPublic,
          },
          organization: {
            id: selectedOrganisation?._id,
            name: selectedOrganisation?.name,
            status: selectedOrganisation?.status,
            created_at: selectedOrganisation?.createdAt,
            isSubscriptionActive: selectedOrganisation?.isSubscriptionActive,
          },
        };

        if (report?.adAccountIds?.length > 0) {
          report?.adAccountIds.map((item, index) => {
            eventPayload.report[`ad_account[${index}]`] = item;
          });
        }

        if (window.dataLayer) window.dataLayer.push(eventPayload);
      })
      .catch((err) => toast.error("failed to save report"));
  });
};

const useMoveReportToFolder = () => {
  const { axiosOrgInstance } = useAxios();
  const query = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((payload) => {
    axiosOrgInstance
      .patch(
        COMMON.MOVE_REPORT_TO_FOLDER.replace(":id", payload?.folderId).replace(
          ":reportableId",
          payload?.reportableId
        )
      )
      .then((res) => {
        toast("Report Moved Successfully");
        if (payload?.folderId) {
          dispatch(refreshOpenFolderIds());
        }
        query.refetchQueries(["folders"]);
        dispatch(setModal(modalInitState));
        return res;
      })
      .catch((error) => {
        toast.error(error);
        // console.log(error);
      });
  });
};

const useInfiniteInsightsData = (isEnabled, pagePayload) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["insightsData"];

  return useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => {
      return axiosOrgInstance.post(INSIGHTS.GET_INSIGHT_DATA, {
        ...pagePayload,
        perPage: 20,
        sortBy: "ctr",
        page: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.nextPage;
      },
      enabled: isEnabled,
      staleTime: 5000,
      refetchOnWindowFocus: false,
    }
  );
};

const useCreateInsightsReport = () => {
  const navigate = useNavigate();
  const { refetch: refetchOrgFodlers } = useGetOrgFolders(false);
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["createInsightsReport"];
  const dispatch = useDispatch();
  const { isUserAuthenticated, userData } = useAuth();
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  return useMutation(
    queryKey,
    (payload) => {
      const folder = localStorage.getItem("REPORT_FOLDER");
      const _payload = payload;
      if (folder) {
        const folderId = JSON.parse(folder)?.[0]?._id;
        _payload["folderId"] = folderId;
      }
      return axiosOrgInstance
        .post(INSIGHTS.CREATE_INSIGHTS_REPORT, _payload)
        .then((res) => {
          localStorage.removeItem("REPORT_FOLDER");
          dispatch(refreshOpenFolderIds());
          dispatch(setModal(modalInitState));
          toast.success(`Report Created Successfully`);
          refetchOrgFodlers();
          const eventPayload = {
            event: "create_report",
            User_Details: {
              user_id: userData?.user?._id,
              email: userData?.user?.email,
              first_name: userData?.user?.firstName,
              last_name: userData?.user?.lastName,
              username: userData?.user?.userName,
              user_type: userData?.user?.userType,
              login_status: isUserAuthenticated,
              created_at: userData?.user?.createdAt,
            },
            report: {
              id: res?.reportableId,
              name: _payload.name,
              description: _payload.description,
              reportable_type: res?.reportableType,
              is_public: _payload.isPublic,
            },
            organization: {
              id: selectedOrganisation?._id,
              name: selectedOrganisation?.name,
              status: selectedOrganisation?.status,
              created_at: selectedOrganisation?.createdAt,
              isSubscriptionActive: selectedOrganisation?.isSubscriptionActive,
            },
          };

          if (res?.adAccountIds?.length > 0) {
            res?.adAccountIds.map((item, index) => {
              eventPayload.report[`ad_account[${index}]`] = item;
            });
          }
          if (window.dataLayer) window.dataLayer.push(eventPayload);
          if (res?.reportableType === REPORTABLE_TYPE.INSIGHTS) {
            navigate(
              APP_ROUTES.INSIGHTS.INSIGHTS_REPORTS.replace(
                ":reportId",
                res?.reportableId
              )
            );
          } else if (res?.reportableType === REPORTABLE_TYPE.COMPARE) {
            navigate(
              APP_ROUTES.INSIGHTS.COMAPRE_REPORT.replace(
                ":reportId",
                res?.reportableId
              )
            );
          } else if (
            res?.reportableType === REPORTABLE_TYPE.COMPARE_MULTIPLE_DATES
          ) {
            navigate(
              APP_ROUTES.INSIGHTS.COMPARE_DATE_REPORT.replace(
                ":reportId",
                res?.reportableId
              )
            );
          } else if (res?.reportableType === REPORTABLE_TYPE.AD_BREAKDOWN) {
            navigate(
              APP_ROUTES.INSIGHTS.AD_BREAKDOWN_REPORT.replace(
                ":reportId",
                res?.reportableId
              )
            );
          } else if (
            res?.reportableType === REPORTABLE_TYPE.CREATIVE_INSIGHTS
          ) {
            navigate(
              APP_ROUTES.INSIGHTS.CREATIVE_INSIGHT_REPORT.replace(
                ":reportId",
                res?.reportableId
              )
            );
          } else if (
            res?.reportableType === REPORTABLE_TYPE.CREATIVE_MOMENTUM_FATIGUE
          ) {
            navigate(
              APP_ROUTES.INSIGHTS.CREATIVE_MOMENTUM_FATIGUE_REPORT.replace(
                ":reportId",
                res?.reportableId
              )
            );
          }
          return res;
        });
    },
    {
      // onSuccess: () => {
      // const group = new CometChat.Group(
      //   groupId,
      //   groupName,
      //   CometChat.GROUP_TYPE.PUBLIC
      // );
      // await CometChat.createGroup(group);

      // },
      onError: () => {
        toast.error(`Insight Report Creation Failed`);
      },
      queryKey: queryKey,
    }
  );
};

const useGetSpecificInsight = (insightFilters, id, currentReportOrgId) => {
  const { axiosOrgInstance } = useAxios();
  const { isUserAuthenticated, userData } = useAuth();
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const queryKey = [`specificInsight-${id}`];
  const isEnabled = !!insightFilters && !!id;
  return useQuery(
    queryKey,
    () => {
      return axiosOrgInstance
        .get(`${INSIGHTS.GET_SPECIFIC_INSIGHT?.replace(":insightId", id)}`, {
          params: {
            orgId: currentReportOrgId,
          },
        })
        .then((res) => {
          const report = res[0]?.report;
          const eventPayload = {
            event: "view_report",
            User_Details: {
              user_id: userData?.user?._id,
              email: userData?.user?.email,
              first_name: userData?.user?.firstName,
              last_name: userData?.user?.lastName,
              username: userData?.user?.userName,
              user_type: userData?.user?.userType,
              login_status: isUserAuthenticated,
              created_at: userData?.user?.createdAt,
            },
            report: {
              id: report?.reportableId,
              name: report?.name,
              description: report?.description,
              reportable_type: report?.reportableType,
              is_public: report?.isPublic,
            },
            organization: {
              id: selectedOrganisation?._id,
              name: selectedOrganisation?.name,
              status: selectedOrganisation?.status,
              created_at: selectedOrganisation?.createdAt,
              isSubscriptionActive: selectedOrganisation?.isSubscriptionActive,
            },
          };

          if (report?.adAccountIds?.length > 0) {
            report?.adAccountIds.map((item, index) => {
              eventPayload.report[`ad_account[${index}]`] = item;
            });
          }
          if (window.dataLayer) window.dataLayer.push(eventPayload);
          return res;
        })
        .catch((err) => toast.error(err));
    },
    {
      enabled: isEnabled,
      onSuccess: () => {},
      staleTime: 5000,
      queryKey: queryKey,
      refetchOnWindowFocus: false,
    }
  );
};

const useGetAudienceSegments = (currentReportOrgId, q) => {
  const { axiosOrgInstance } = useAxios();
  const { selectedOrganisation } = useSelector((state) => state?.organisation);
  const queryKey = [`audienceSegments-${selectedOrganisation?._id}`];
  return useQuery(
    queryKey,
    () => {
      return axiosOrgInstance
        .get("organization/audience-segment", {
          params: {
            orgId: currentReportOrgId,
            q: q,
          },
        })
        .then((res) => {
          return res?.map((curr) => curr?.name);
        });
    },
    {
      onSuccess: () => {},
      staleTime: 300000,
      queryKey: queryKey,
      refetchOnWindowFocus: false,
    }
  );
};

export {
  useGetInsightFilters,
  useGetInsightsData,
  useInfiniteInsightsData,
  useCreateInsightsReport,
  useGetSpecificInsight,
  useGetAudienceSegments,
  useMoveReportToFolder,
  useUpdateInsightsReport,
  useGetVideoBreakdown,
};
