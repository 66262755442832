import React, { useMemo, useState } from "react";
import { Tooltip as MTooltip } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { GROUP_BY_COLUMNS, REPORTABLE_TYPE } from "utils/constants";
import { storageUrlToCdn } from "utils/helper";
import {
  AD_BREAKDOWN_FIELDS,
  AD_BREAKDOWN_FIELDS_REVERSE,
} from "config/statics";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { useDispatch } from "react-redux";

const CustomTooltip = ({ active, payload, originalData }) => {
  if (active && payload && payload.length) {
    const data = originalData[payload[0].payload.index];
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "white",
          padding: "5px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      >
        {Object.entries(data).map(([key, value]) => {
          if (key === "thumbnailUrl" || key === "index" || key === "groupId")
            return null;
          if (key === AD_BREAKDOWN_FIELDS[1])
            return (
              <p key={key}>{`${
                AD_BREAKDOWN_FIELDS_REVERSE[AD_BREAKDOWN_FIELDS[1]]
              }: ${value}`}</p>
            );
          return <p key={key}>{`${key}: ${value}`}</p>;
        })}
      </div>
    );
  }
  return null;
};

export default function LineLineChartMultiYAxis({
  insightsData,
  selectedMetrics,
  rowSelection,
  filterAndOptions,
  reportableType = null,
  handleCreativeInsight,
}) {
  const [legendClicks, setLegendClicks] = useState([]);
  const dispatch = useDispatch();
  const keysObj = {};

  const columnKey = useMemo(() => {
    const objectType = insightsData[0]?.original?.objectType;
    if (
      objectType === "url" &&
      reportableType !== REPORTABLE_TYPE.AD_BREAKDOWN
    ) {
      return "thumbnailUrl";
    }
    return reportableType === REPORTABLE_TYPE.AD_BREAKDOWN
      ? AD_BREAKDOWN_FIELDS[1]
      : GROUP_BY_COLUMNS.find((key) =>
          insightsData[0]?.original?.hasOwnProperty(key)
        ) || "thumbnailUrl";
  }, [insightsData, reportableType]);

  const kpiObjArr = selectedMetrics.map((metric) => {
    return filterAndOptions?.kpi?.find((kpi) => kpi?.kpi === metric);
  });

  const chartData = insightsData
    .filter((item) => rowSelection[item.index])
    .map((item, index) => {
      const { original: insight = {} } = item;
      kpiObjArr.forEach((metric) => {
        keysObj[metric.label] = insight[metric.kpi]?.display?.value ?? 0;
      });
      return {
        ...keysObj,
        index,
        groupId: item?.original?._id,
        [columnKey]: insight[columnKey],
        thumbnailUrl:
          columnKey === "thumbnailUrl"
            ? storageUrlToCdn(insight.thumbnailUrl)
            : insight[columnKey],
      };
    });

  // calculating the max value for each key to normalize
  const maxValues = {};
  Object.keys(keysObj).forEach((key) => {
    maxValues[key] = Math.max(...chartData.map((data) => data[key]));
  });

  const normalizedData = chartData.map((data) => {
    const normalizedData = { ...data };
    Object.keys(keysObj).forEach((key) => {
      normalizedData[key] = (data[key] / maxValues[key]) * 100;
    });
    return normalizedData;
  });

  const CustomXAxisLabel = ({ x, y, payload }) => {
    const imageSrc = payload.value;
    const yOffset = 30;
    const clipPathId = `avatar-clip-${Math.random().toString(36).slice(2, 9)}`;
    const charLimit = 20;

    return (
      <g transform={`translate(0, ${yOffset})`}>
        <defs>
          <clipPath id={clipPathId}>
            <circle cx={x} cy={y} r={25} />
          </clipPath>
        </defs>
        {columnKey !== "thumbnailUrl" ? (
          <MTooltip arrow title={payload?.value}>
            <text x={x} y={y}>
              {payload?.value?.length < charLimit
                ? payload.value
                : payload?.value?.substring(0, charLimit) + "..."}
            </text>
          </MTooltip>
        ) : (
          <image
            style={{
              cursor: "pointer",
              clipPath: `url(#${clipPathId})`, // Apply the clipPath to the image
            }}
            onClick={() => {
              if (
                chartData?.[payload?.index]?.groupId &&
                handleCreativeInsight
              ) {
                dispatch(
                  setModal({
                    type: MODALTYPES.MEDIA_ASSET_PREVIEW,
                    open: true,
                    payload: {
                      groupId: chartData?.[payload?.index]?.groupId,
                      handleCreativeInsight: handleCreativeInsight,
                    },
                  })
                );
              }
            }}
            x={x - 25} // Adjust the positioning of the image as needed
            y={y - 25} // Adjust the positioning of the image as needed
            width={50} // Adjust the size of the image as needed
            height={50} // Adjust the size of the image as needed
            href={imageSrc} // The image source
          />
        )}
      </g>
    );
  };

  const SELECTED_METRIC_COLOR = {
    1: "#EF4444",
    2: "#F59E0B",
    3: "#7F9CF5",
    4: "#F472B6",
    5: "#34D399",
    6: "#8B5CF6",
    7: "#60A5FA",
  };

  const handleLegendClick = (name) => {
    let _new = null;
    if (legendClicks?.includes(name)) {
      _new = legendClicks?.filter((item) => item !== name);
    } else {
      _new = [...legendClicks, name];
    }

    setLegendClicks(_new);
  };

  return (
    <div className="h-96">
      <ResponsiveContainer
        width={chartData?.length > 10 ? chartData?.length * 10 + "%" : "100%"}
      >
        <LineChart
          data={normalizedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="thumbnailUrl"
            interval={0}
            tick={<CustomXAxisLabel />}
            height={60}
          />
          <Tooltip content={<CustomTooltip originalData={chartData} />} />
          {/* <YAxis yAxisId="left" orientation="left" stroke="#F472B6" /> */}
          {/* <YAxis yAxisId="right" orientation="right" stroke="#EEB40D" /> */}

          <Legend
            verticalAlign="top"
            wrapperStyle={{
              paddingBottom: "20px",
            }}
            formatter={(value, entry) => {
              return (
                <span
                  style={{
                    color: entry.color,
                    cursor: "pointer",
                    marginBottom: "20px",
                  }}
                  onClick={() => handleLegendClick(value)}
                >
                  {value}
                </span>
              );
            }}
          />

          {Object.entries(keysObj).map(([key, value], index) => {
            let hide = null;
            if (legendClicks?.includes(key)) hide = true;
            return (
              <Line
                key={key}
                type="monotone"
                dataKey={key}
                stroke={SELECTED_METRIC_COLOR[index + 1]}
                yAxisId="right"
                hide={hide}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
