import { createSlice } from "@reduxjs/toolkit";

const initialOrgState = {
  openFolderIds: [],
};

const folderSlice = createSlice({
  name: "folderSlice",
  initialState: initialOrgState,
  reducers: {
    setOpenFolderIds: (state, action) => {
      state.openFolderIds = action.payload;
    },
    setFolderHeirachy: (state, action) => {
      state.folderHeirachy = action.payload;
    },
    refreshOpenFolderIds: (state, action) => {
      state.openFolderIds = [];
    },
  },
});

export const { setOpenFolderIds, setFolderHeirachy, refreshOpenFolderIds } =
  folderSlice.actions;

export default folderSlice.reducer;
