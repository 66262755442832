import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useSelector, useDispatch } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "../../../redux/slices/modal.slices";
import useAxios from "../../../hooks/useAxios";
import { API_ROUTES } from "../../../routes";
import { toast } from "react-toastify";

export default function RemoveUserModal(props) {
  const { axiosOrgInstance } = useAxios();
  const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();
  function handleClose() {
    dispatch(setModal(modalInitState));
  }

  function removeUserFromOrg() {
    const userId = modal?.payload?.userId;
    axiosOrgInstance
      .patch(API_ROUTES.ORGANISATION.REMOVE_USER, { userId })
      .then(() => {
        toast.success("User Removed From Organisation Successfully");
        handleClose();
        props?.getOrgUsers();
      });
  }
  return (
    <AlertDialog
      open={modal?.open && modal?.type === MODALTYPES.REMOVE_USER_MODAL}
      onOpenChange={handleClose}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will remove the current user from
            your organization.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => removeUserFromOrg()}>
            Remove
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
