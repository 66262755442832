import React from "react";
import { Card, Stack, Typography } from "@mui/material";
import { storageUrlToCdn } from "utils/helper";

function getCoumnsData(props) {
  const dataPoints = props?.columns
    ?.map((item) => {
      return {
        label: item.label,
        value: props[item.key]?.value ?? props[item.key] ?? 0,
        key: item.key,
      };
    })
    .filter((item) => item.key !== "thumbnailUrl");
  return dataPoints;
}
export default function InsightCard(props) {
  const {
    thumbnailUrl,
    TypePill,
    EditTagsBtn,
    SelectToCompare,
    isCompareView,
  } = props;
  const cardsDataPoints = getCoumnsData(props);
  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: 300,
        minWidth: 250,
        position: "relative",
      }}
    >
      <div
        style={{
          display: isCompareView ? "" : "none",
          position: "absolute",
          top: "-6px",
          right: "6px",
          zIndex: 100,
          opacity: "100%",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
        }}
      >
        {SelectToCompare}
      </div>

      <div
        style={{
          position: "relative",
          opacity: isCompareView ? "30%" : "",
          background: isCompareView ? "rgba(0,0,0,0.5)" : "",
          filter: isCompareView ? "brightness(50%)" : "",
        }}
      >
        <Stack sx={{ height: "200px", position: "relative" }}>
          <img
            src={storageUrlToCdn(thumbnailUrl)}
            alt="insight card thumbnail"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
          {TypePill}
          {EditTagsBtn}
        </Stack>
        <Stack direction="column" spacing={1} sx={{ padding: "16px" }}>
          {cardsDataPoints?.map((item) => {
            if (item.key === "groupName") return null;
            return (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", flexBasis: "50%" }}
                >
                  {item.label}
                </Typography>

                <Typography
                  variant="subtitle2"
                  sx={{
                    padding: "4px 8px",
                    borderRadius: "4px",
                    background: "#D4E4FA",
                    fontWeight: "300",
                    fontSize: "12px",
                  }}
                >
                  {item.value}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </div>
    </Card>
  );
}
