import React from "react";
import { Skeleton, TableCell, TableRow } from "@mui/material";
function TableLoader({ rowsNum, colsNum = 5 }) {
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      {[...Array(rowsNum)]?.map(() => (
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      ))}
    </TableRow>
  ));
}
export default TableLoader;
