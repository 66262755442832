import {
  Dialog,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";
import { Button } from "@/components/ui/button";

const CreateSourceModal = (props) => {
  const { primaryAction } = props;

  const [srcName, setSrcName] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();

  function handleClose() {
    dispatch(setModal(modalInitState));
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={modal?.open && modal?.type === MODALTYPES.CREATE_SOURCE_MODAL}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Stack
        direction="column"
        sx={{
          padding: "40px",
          gap: "30px",
          position: "relative",
          width: "500px",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 5,
            right: 5,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={{ fontSize: "1.2rem", fontWeight: 500 }}>
          Enter Source Name
        </Typography>
        <TextField
          label="Source Name"
          onChange={(e) => setSrcName(e?.target?.value)}
        />
        {/* <Button
          variant="contained"
          sx={{
            width: "fit-content",
            display: "flex",
            alignSelf: "end",
            justifyContent: "center",
            fontSize: "0.85rem",
          }}
          onClick={() => primaryAction(srcName)}
        >
          Create
        </Button> */}
        <Button
          sx={{
            width: "fit-content",
            display: "flex",
            alignSelf: "end",
            justifyContent: "center",
            fontSize: "0.85rem",
          }}
          onClick={() => primaryAction(srcName)}
        >
          Create
        </Button>
      </Stack>
    </Dialog>
  );
};

export default CreateSourceModal;
