import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Divider } from "@mui/material";
import { useTypeahead } from "apis/typeahead";
import Spinner from "components/common/loaders/Spinner";
import {
  Album,
  FileText,
  FolderKanban,
  Link as ILink,
  Search,
} from "lucide-react";
import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";
import { useNavigate } from "react-router-dom";
import { REPORT_TYPE_ICONS } from "config/statics";

const IconObj = {
  Links: <ILink className="w-5 h-5" />,
  Features: <Album className="w-5 h-5" />,
  Reports: <FileText className="w-5 h-5" />,
  Folders: <FolderKanban className="w-5 h-5" />,
};

export function TypeaheadModal() {
  const { open, type } = useSelector((state) => state.modal);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, isLoading, isRefetching } = useTypeahead(searchTerm);

  const debouncedSearch = useMemo(
    () => debounce((term) => setSearchTerm(term), 300),
    []
  );

  const handleChange = (e) => {
    debouncedSearch(e.target.value);
  };

  function handleClose() {
    dispatch(setModal(modalInitState));
  }

  useEffect(() => {
    if (open && type === MODALTYPES.TYPEAHEAD_MODAL) {
      setSearchTerm("default");
    }
  }, [open, type]);

  return (
    <Dialog
      open={open && type === MODALTYPES.TYPEAHEAD_MODAL}
      onOpenChange={handleClose}
    >
      <DialogContent className="max-w-[600px] p-4 outline-none text-xs">
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-grow">
            <Search className="w-5 h-5 mr-2 text-gray-500" />
            <input
              type="text"
              autoFocus
              onChange={handleChange}
              placeholder="Type a command or search..."
              className="border-none px-3 py-1 w-[90%] outline-none"
            />
          </div>
        </div>

        <Divider className="my-4" />

        {searchTerm && (isLoading || isRefetching) ? (
          <Spinner spinnerClass="h-8 w-8 border-black" />
        ) : (
          <div className="max-h-[40vh] overflow-y-auto">
            {!searchTerm ? (
              <p className="text-gray-500 text-left">
                Please type something to search...
              </p>
            ) : data?.isEmpty ? (
              <div className="flex flex-col">
                <p className="text-gray-500 text-left">No results found</p>
                <div
                  key={"Links"}
                  className="mb-4 overflow-y-auto text-xs mt-10"
                >
                  <p className="text-sm text-gray-500 mb-1">{"Links"}</p>
                  <div
                    onClick={() => {
                      handleClose();
                      navigate("/setting");
                    }}
                    className="group flex items-center p-2 bg-gray-100 rounded-md cursor-pointer gap-x-2"
                  >
                    {IconObj["Links"]}
                    <p className="text-xs w-full line-clamp-1">
                      {"Create Report"}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              Object.entries(data).map(([section, items]) => {
                if (Array.isArray(items) && items.length > 0) {
                  return (
                    <div key={section} className="mb-4 overflow-y-auto text-xs">
                      <p className="text-sm text-gray-500 mb-1">{section}</p>
                      {items.map((item, index) => (
                        <div
                          onClick={() => {
                            handleClose();
                            navigate(item?.redirect);
                          }}
                          key={index}
                          className="group flex items-center px-2 py-3 hover:bg-gray-100 rounded-md cursor-pointer gap-x-2"
                        >
                          {section !== "Reports" ? (
                            <>
                              {IconObj[section]}
                              <p className="text-xs w-full line-clamp-1">
                                {item.label}
                              </p>
                            </>
                          ) : (
                            <>
                              <div className="rounded-md cursor-pointer hover:border hover:border-sky-300 shadow-sm bg-sky-100 flex flex-col min-w-6 min-h-6 mr-2 items-center justify-center relative">
                                {!item?.icon ? (
                                  <img
                                    className="max-w-5 h-auto group-hover:hidden group-hover:animate-bounceUp transition-transform"
                                    src={REPORT_TYPE_ICONS[item?.type]}
                                    alt=""
                                  />
                                ) : (
                                  <div className="text-base h-auto group-hover:hidden group-hover:animate-bounceUp transition-transform">
                                    {item?.icon}
                                  </div>
                                )}
                                <img
                                  className="max-w-4 h-auto hidden group-hover:block group-hover:animate-bounceIn absolute transition-transform"
                                  src="/meta.png"
                                  alt=""
                                />
                              </div>
                              <p className="text-xs w-full line-clamp-1">
                                {item.label}
                              </p>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  );
                }
                return null;
              })
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
