import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useAxios from "../../../hooks/useAxios";
import {
  setOrganisationToken,
  setSelectedOrganisation,
} from "../../../redux/slices/organisation.slice";
import { API_ROUTES, APP_ROUTES } from "../../../routes";

export default function useOrgSelection() {
  const [selectedOrg, setSelectedOrg] = useState(null);
  const { axiosUserInstance } = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { allOrganisations, selectedOrganisation } = useSelector(
    (state) => state.organisation
  );

  function handleOrgSelection(org) {
    setSelectedOrg(org);
    dispatch(setSelectedOrganisation(org));
  }

  function handleNext(org) {
    getOrgToken(org);
  }

  function getOrgToken(org) {
    axiosUserInstance
      .get(
        API_ROUTES.AUTH.GET_ORG_TOKEN.replace(
          ":organizationId",
          org?._id || selectedOrg?._id
        )
      )
      .then((res) => {
        dispatch(setOrganisationToken(res?.token));
        // navigate(APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT);
        navigate(APP_ROUTES.DASHBOARD);
      });
  }

  return {
    allOrganisations,
    handleOrgSelection,
    selectedOrganisation,
    handleNext,
  };
}
