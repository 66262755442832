import React from "react";

const DownArrow = ({
  isActive,
  height = "10",
  width = "18",
  style = {},
  onClickHandler = () => {},
}) => {
  // const color = isActive ? "#7167FB" : "#858383";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClickHandler}
    >
      <path d="M1 1L9 9L17 1" stroke="#1C1E20" strokeWidth="2" />
    </svg>
  );
};

export default DownArrow;
