import { useSelector } from "react-redux";

export default function useUserRole() {
  const { appState } = useSelector((state) => state?.appState);
  const userRole = appState?.userRole?.type;
  const userPermissions = appState?.userRole?.permissions;
  const activeFeatures = appState?.activeFeatures;
  const isSubscriptionActive = appState?.isSubscriptionActive;
  const isTrialMode = appState?.trialEndingOn;

  const hasUserPermission = (permission) => {
    if (userRole === "") {
      return false;
    }
    if (
      userPermissions?.includes(permission) ||
      userPermissions?.includes("ALL")
    ) {
      return true;
    }
    return false;
  };

  const hasUserActiveFeature = (feature) => {
    if ((userRole === "" || !isSubscriptionActive) && !isTrialMode) {
      return false;
    }
    if (activeFeatures?.[feature] === true) {
      return true;
    }
    return false;
  };

  const isUserSubscribed = () => {
    const isSubActive = appState?.isSubscriptionActive;
    const subId = appState?.subscriptionId;
    const isTrialMode = appState?.trialEndingOn;
    if ((isSubActive && subId) || isTrialMode) {
      return true;
    }
    return false;
  };

  const isOrgInTrialMode = () => {
    const isTrialMode = appState?.trialEndingOn;
    return isTrialMode ? true : false;
  };

  return {
    userRole,
    userPermissions,
    isOrgInTrialMode,
    hasUserPermission,
    hasUserActiveFeature,
    isUserSubscribed,
  };
}
