import React from "react";
import { Typography } from "@mui/material";
import { processing } from "../../../../assets/images";
import { PrivateContainer } from "../../../../components/layouts";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../../routes";

export default function ProcessingScreen() {
  const navigate = useNavigate();
  React.useEffect(() => {
    setTimeout(() => {
      navigate(APP_ROUTES.ONBOARDING.CONGRATULATIONS);
    }, 5000);
  }, [navigate]);
  return (
    <PrivateContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          marginBottom: "100px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={processing} alt="processing" />
        </div>

        <Typography
          variant="h1"
          sx={{ marginBottom: "10px", fontWeight: "700", textAlign: "center" }}
        >
          Wait For A While, Auto Tagging In Process
        </Typography>
        <Typography
          variant="h3"
          sx={{
            color: "#7f7f7f",
            fontWeight: "500",
            textAlign: "center",
            lineHeight: 1.8,
          }}
        >
          "Convincing AI not to turn evil.."
        </Typography>
      </div>
    </PrivateContainer>
  );
}
