import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createPortal } from "react-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Tooltip } from "@mui/material";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ChevronsUpDown, Check, X, GripVertical, Loader2 } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { Separator } from "@/components/ui/separator";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import CreatePresetModal from "../CreatePresetModal/CreatePresetModal";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";
import { DarkTooltip } from "screens/Insights/Components/ColorTable/ColorTable";

const PresetModal = (props) => {
  const {
    preset,
    handlePresetSelect,
    presetData,
    filterAndOptions,
    updatePreset,
    specificPresetData,
    isMakingPreset,
    makePreset,
    setTempKpis,
    isLoadingSpecificPreset,
  } = props;

  const { modal } = useSelector((state) => state);
  const [currOpen, setOpen] = useState(true);
  const [presetKpis, setPresetKpis] = useState([]);
  const [kpis, setKpis] = useState([]);
  const [isDefaultPreset, setIsDefaultPreset] = useState(false);
  const dispatch = useDispatch();

  const Portal = ({ children }) => {
    if (typeof document === "undefined") return null;
    const mount = document.getElementById("portal-root");
    return createPortal(children, mount);
  };

  const handleClose = () => {
    dispatch(setModal(modalInitState));
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedKpis = Array.from(presetKpis);
    const [movedKpi] = reorderedKpis.splice(result.source.index, 1);
    reorderedKpis.splice(result.destination.index, 0, movedKpi);

    setPresetKpis(reorderedKpis);
  };

  const handleUpdate = () => {
    const _kpis = presetKpis.map((_kpi) => _kpi.id);
    const payload = { kpis: _kpis, isSetAsDefault: !!isDefaultPreset };
    updatePreset(payload);
  };

  const handleTempKpis = () => {
    dispatch(setModal(modalInitState));
    const kpiCopy = presetKpis;
    setTempKpis(kpiCopy);
  };

  const makePayloadFromKPIsArray = (arr) => {
    let obj = {};
    arr?.forEach((kpi) => {
      let val = obj[kpi?.category];
      if (Array.isArray(val)) {
        val?.push(kpi);
      } else {
        val = [kpi];
      }
      obj[kpi?.category] = val;
    });
    setKpis(Object.entries(obj));
  };

  const handleSearch = (value) => {
    const lowerCaseValue = value.toLowerCase();
    const filteredKpis = filterAndOptions?.kpi
      ?.filter((curr) => curr?.label?.toLowerCase()?.includes(lowerCaseValue))
      .sort((a, b) => {
        const relevanceA = a.label.toLowerCase().indexOf(lowerCaseValue);
        const relevanceB = b.label.toLowerCase().indexOf(lowerCaseValue);
        return relevanceA - relevanceB;
      });
    makePayloadFromKPIsArray(filteredKpis);
  };

  useEffect(() => {
    if (modal?.open && modal?.type === MODALTYPES.PRESETS_MODAL) {
      if (filterAndOptions?.kpi) {
        makePayloadFromKPIsArray(filterAndOptions?.kpi);
      }
    }
  }, [modal]);

  useEffect(() => {
    if (specificPresetData) {
      const kpis = specificPresetData.flatMap(
        (presetData) => presetData?.kpis || []
      );
      setIsDefaultPreset(specificPresetData[0]?.isDefault || false);
      setPresetKpis(kpis);
    }
  }, [specificPresetData, preset]);

  return (
    <>
      <Dialog
        open={
          modal?.open && modal?.type === MODALTYPES.PRESETS_MODAL && currOpen
        }
        onOpenChange={handleClose}
      >
        <DialogContent className="max-h-[680px] min-h-[600px] overflow-hidden min-w-[850px] max-w-[900px]">
          <DialogHeader className="flex">
            <DialogTitle className="text-sm font-normal">
              Selected preset
            </DialogTitle>
            <div className="flex flex-row gap-2">
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    className="w-[250px] px-2 overflow-hidden truncate flex justify-between"
                  >
                    <span className="text-black text-sm">
                      {preset
                        ? presetData?.filter(
                            (curr, index) => curr.id === preset
                          )?.[0]?.name
                        : "Select preset"}
                    </span>
                    <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[250px] p-0">
                  <Command>
                    <CommandInput placeholder="Search filter..." />
                    <CommandEmpty>No filter found.</CommandEmpty>
                    <CommandList>
                      <CommandGroup>
                        {presetData?.map((curr, index) => (
                          <CommandItem
                            key={curr?.name}
                            value={curr?.id}
                            onSelect={(val) => {
                              handlePresetSelect(val);
                            }}
                          >
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                curr?.id?.toString() === preset?.toString()
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            {curr?.name}
                          </CommandItem>
                        ))}
                        <Separator />
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              {isLoadingSpecificPreset && (
                <div className="flex items-center">
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                </div>
              )}
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="isDefaultPreset"
                  checked={isDefaultPreset}
                  label="Mark as default preset"
                  onClick={() => setIsDefaultPreset(!isDefaultPreset)}
                />
                <label
                  htmlFor="isDefaultPreset"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Mark as default preset
                </label>
              </div>
            </div>
            <div>
              <p className="mt-1 text-xs italic text-gray-500">{`*(Click on 'update preset' to permanently update the preset. Apply will make the changes in table temporarily.)`}</p>
            </div>
          </DialogHeader>
          <Separator />
          <div className="mt-3 flex gap-x-5 h-[350px]">
            <div className="w-[25%] flex flex-col gap-y-3 overflow-y-scroll">
              <p className="mt-1">KPI's</p>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="kpiList">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="flex flex-col gap-y-3 overflow-y-auto h-full"
                    >
                      {presetKpis?.map((kpi, index) => (
                        <Draggable
                          key={kpi.id}
                          draggableId={kpi.id}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            const child = (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`px-5 py-3 rounded-sm bg-[#FAFAFA] flex items-center gap-x-2 ${
                                  snapshot.isDragging ? "dragging" : ""
                                }`}
                              >
                                <div>
                                  <GripVertical className="w-5 h-5" />
                                </div>
                                <div className="flex gap-x-1 justify-between w-full items-center">
                                  <div className="flex gap-x-2 items-center">
                                    <img
                                      src={
                                        filterAndOptions?.kpi?.find(
                                          (k) => k?.kpi === kpi?.value
                                        )?.icon
                                      }
                                      className="w-4 h-4 object-cover"
                                      alt="default-kpi-icon"
                                    />
                                    <p className="text-sm font-normal">
                                      {kpi?.name}
                                    </p>
                                  </div>
                                  <X
                                    className="h-4 w-4 cursor-pointer"
                                    onClick={() => {
                                      const newKpisArr = presetKpis.filter(
                                        (_kpi) => kpi.id !== _kpi.id
                                      );
                                      setPresetKpis(newKpisArr);
                                    }}
                                  />
                                </div>
                              </div>
                            );

                            if (snapshot.isDragging) {
                              return <Portal>{child}</Portal>;
                            }

                            return child;
                          }}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <Separator orientation="vertical" />
            <div className="w-[70%] overflow-y-scroll flex flex-col gap-y-1">
              <Input
                className="sticky top-0 shadow-sm mt-2 focus-visible:ring-transparent"
                onChange={(e) => handleSearch(e?.target?.value)}
                placeholder="Search metric"
              />
              <div className="flex flex-col gap-y-3">
                {kpis.length ? (
                  kpis?.map(([category, kpis], index) => {
                    return (
                      <>
                        <h3 className="mt-2 text-lg font-bold">{category}</h3>
                        {kpis?.map((option, idx) => {
                          return (
                            <>
                              <DarkTooltip
                                title={option?.description}
                                placement="right"
                                arrow
                              >
                                <div className="ml-5 flex w-fit items-center space-x-2 bg-[#FAFAFA] px-5 rounded-sm py-3">
                                  <Checkbox
                                    id="terms"
                                    value={option?.kpi?.toString()}
                                    onCheckedChange={(val) => {
                                      if (!val) {
                                        const newKpisArr = presetKpis.filter(
                                          (_kpi) => option.id !== _kpi.id
                                        );
                                        setPresetKpis(newKpisArr);
                                      } else {
                                        const kpiArr = [...presetKpis];
                                        kpiArr.push({
                                          id: option.id,
                                          name: option.label,
                                          value: option.kpi,
                                        });
                                        setPresetKpis(kpiArr);
                                      }
                                    }}
                                    checked={presetKpis?.some(
                                      (_kpi) => _kpi.id === option.id
                                    )}
                                  />
                                  <img
                                    src={option.icon}
                                    className="w-4 h-4 object-cover"
                                    alt="default-kpi-icon"
                                  />
                                  <p
                                    htmlFor="terms"
                                    disabled
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                  >
                                    {option?.label}
                                  </p>
                                </div>
                              </DarkTooltip>
                            </>
                          );
                        })}
                      </>
                    );
                  })
                ) : (
                  <div className="flex items-center justify-center mt-4 flex-row">
                    <p className="text-black font-bold">No result found</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Separator />
          <DialogFooter>
            <div className="flex justify-between w-full">
              <div className="flex gap-x-3">
                <Button
                  variant="outline"
                  type="submit"
                  disabled={!preset}
                  onClick={handleUpdate}
                >
                  Update Preset
                </Button>

                <Button
                  variant="secondary"
                  onClick={() => {
                    // setOpen(false);
                    dispatch(setModal(modalInitState));
                    dispatch(
                      setModal({
                        type: MODALTYPES.CREATE_PRESET_MODAL,
                        open: true,
                        payload: {
                          presetKpis,
                        },
                      })
                    );
                  }}
                >
                  Save as new preset
                </Button>
              </div>
              <div>
                <Button
                  type="submit"
                  onClick={() => {
                    handleTempKpis();
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <CreatePresetModal
        isMakingPreset={isMakingPreset}
        makePreset={makePreset}
        presetKpis={presetKpis}
      />
    </>
  );
};

export default PresetModal;
