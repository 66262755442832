import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAxios from "../../../hooks/useAxios";
import { MODALTYPES } from "../../../redux/slices/modal.slices";
import { API_ROUTES, APP_ROUTES } from "../../../routes";
import {
  useGetPredictionMediaTags,
  useUpdatePredictionMediaTags,
} from "apis/predictionApi";
import useUserRole from "hooks/useUserRole";
import { ACTIVE_FEATURES } from "config/roles";
import { useGetOrganizationTags } from "apis/common";

const comboSwitches = [
  "textInVideo",
  "discountShownInVideo",
  "discountCodeShownInVideo",
  "isProductImage",
  "products",
  "voiceover",
];
let segmentMapping = {
  0: "0-3 seconds",
  1: "First 25%",
  2: "Second 25%",
  3: "Third 25%",
  4: "Last 25%",
  5: "Full",
};

const TOTAL_SEGMENTS = 6;
export default function useVideoTagging(
  payload,
  postOnboarding,
  predictionConfigs
) {
  const { axiosOrgInstance } = useAxios();
  const { hasUserActiveFeature } = useUserRole();
  const { open, type } = useSelector((state) => state?.modal);
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const [aiTagApplied, setAiTagApplied] = React.useState(false);
  const [aiTagsLists, setAiTagsLists] = React.useState([]);
  const { refetch: refetchOrganizationTags } = useGetOrganizationTags(false);

  const { data: predictionTags } = useGetPredictionMediaTags(predictionConfigs);
  const { mutateAsync: updatePredictionMediaTags } =
    useUpdatePredictionMediaTags(predictionConfigs);
  const { reportableId, mediaId } = predictionConfigs || {};
  const isOnlylastsegmentActive = reportableId && mediaId;
  let intervalId = useRef(null);
  const navigate = useNavigate();
  const isAdvanceTaggingActive = hasUserActiveFeature(
    ACTIVE_FEATURES.ADVANCE_TAGGING
  );
  const allFields = {
    combineTags: [],
    tags: [],
    resolution: [],
    colorPalette: [],
    visualThemes: [],
    objects: [],
    peopleDemographics: [],
    textAmount: [],
    textOverlayStyle: [],
    logoPresence: false,
    logoPlacement: [],
    productPresence: false,
    productName: [],
    brandIntegration: [],
    backgroundElements: [],
    vfx: [],
    layoutAndComposition: [],
    shotType: [],
    editingStyle: [],
    cameraMovement: [],
    lightingStyle: [],
    interactivity: false,
    platformSpecificFeatures: [],
    useOfSymbolsAndIcons: [],
    depthOfField: [],
    narrativeStyle: [],
    emotionalTone: [],
    isCtaAvailable: false,
    cta: [],
    ctaStyle: [],
    OfferPromotionPresence: false,
    offerPromotion: [],
    hookPresence: false,
    hook: [],
    targetAudienceTraits: [],
    culturalRelevance: [],
    contextualTagsByBusiness: [],

    // --------------------------------

    // creators: [],
    contentThemes: [],
    discountShownInVideo: {
      exist: false,
      value: "",
    },
    discountCodeShownInVideo: {
      exist: false,
      value: "",
    },

    isProductImage: {
      exist: false,
      value: [],
    },

    persons: [],
    segments: [],
    sentiments: [],
    shotLabels: [],
    shotCounts: "",
    shortDetail: "",
    speechTranscriptions: [],
    textInVideo: {
      exist: false,
      value: [],
    },
    voiceover: {
      exist: false,
      value: [],
    },
    products: {
      exist: false,
      value: [],
    },
  };
  const [segment, setSegment] = React.useState(reportableId && mediaId ? 5 : 0);
  const [state, setState] = React.useState([
    { ...allFields },
    { ...allFields },
    { ...allFields },
    { ...allFields },
    { ...allFields },
    { ...allFields },
  ]);

  function handleTagsChange(event) {
    const { name, value } = event;
    const _values = value.flatMap((curr) =>
      curr.split(",").map((tag) => tag.trim())
    );
    setState((prevState) => {
      const newState = [...prevState];
      newState[segment] = {
        ...newState[segment],
        [name]: _values,
      };
      return newState;
    });
  }

  function handleSkipSegment() {
    if (segment === TOTAL_SEGMENTS - 1) {
      setSegment(0);
    } else {
      setSegment((prevState) => prevState + 1);
    }
  }
  function handleSwitch(event) {
    const { name, checked } = event?.target;

    if (comboSwitches.includes(name)) {
      setState((prevState) => {
        const newState = [...prevState];
        newState[segment] = {
          ...newState[segment],
          [name]: {
            exist: checked,
            value:
              name === "textInVideo" ||
              "discountShownInVideo" ||
              "isProductImage" ||
              "products" ||
              "voiceover"
                ? []
                : "",
          },
        };
        return newState;
      });
    } else {
      setState((prevState) => {
        const newState = [...prevState];
        newState[segment] = {
          ...newState[segment],
          [name]: checked,
        };
        return newState;
      });
    }
  }

  function handleInputChange(event) {
    const { name, value } = event?.target;
    if (comboSwitches.includes(name)) {
      setState((prevState) => {
        const newState = [...prevState];
        newState[segment] = {
          ...newState[segment],
          [name]: {
            ...newState[segment][name],
            value,
          },
        };
        return newState;
      });
    } else {
      setState((prevState) => {
        const newState = [...prevState];
        newState[segment] = {
          ...newState[segment],
          [name]: value,
        };
        return newState;
      });
    }
  }

  function getPayloadForSegment(segment) {
    const _payload = {
      resolution: state?.[segment]?.resolution,
      colorPalette: state?.[segment]?.colorPalette,
      visualThemes: state?.[segment]?.visualThemes,
      objects: state?.[segment]?.objects,
      peopleDemographics: state?.[segment]?.peopleDemographics,
      textAmount: state?.[segment]?.textAmount,
      textOverlayStyle: state?.[segment]?.textOverlayStyle,
      logoPresence: state?.[segment]?.logoPresence,
      logoPlacement: state?.[segment]?.logoPlacement,
      productPresence: state?.[segment]?.productPresence,
      productName: state?.[segment]?.productName,
      brandIntegration: state?.[segment]?.brandIntegration,
      backgroundElements: state?.[segment]?.backgroundElements,
      vfx: state?.[segment]?.vfx,
      layoutAndComposition: state?.[segment]?.layoutAndComposition,
      shotType: state?.[segment]?.shotType,
      editingStyle: state?.[segment]?.editingStyle,
      cameraMovement: state?.[segment]?.cameraMovement,
      lightingStyle: state?.[segment]?.lightingStyle,
      interactivity: state?.[segment]?.interactivity,
      platformSpecificFeatures: state?.[segment]?.platformSpecificFeatures,
      useOfSymbolsAndIcons: state?.[segment]?.useOfSymbolsAndIcons,
      depthOfField: state?.[segment]?.depthOfField,
      narrativeStyle: state?.[segment]?.narrativeStyle,
      emotionalTone: state?.[segment]?.emotionalTone,
      isCtaAvailable: state?.[segment]?.isCtaAvailable,
      cta: state?.[segment]?.cta,
      ctaStyle: state?.[segment]?.ctaStyle,
      OfferPromotionPresence: state?.[segment]?.OfferPromotionPresence,
      offerPromotion: state?.[segment]?.offerPromotion,
      hookPresence: state?.[segment]?.hookPresence,
      hook: state?.[segment]?.hook,
      targetAudienceTraits: state?.[segment]?.targetAudienceTraits,
      culturalRelevance: state?.[segment]?.culturalRelevance,
      contextualTagsByBusiness: state?.[segment]?.contextualTagsByBusiness,

      // ---------------------------

      contentThemes: state?.[segment]?.contentThemes,
      discount: state?.[segment]?.discountShownInVideo?.exist
        ? state?.[segment]?.discountShownInVideo?.value
        : "",
      discountCode: state?.[segment]?.discountCodeShownInVideo?.exist
        ? state?.[segment]?.discountCodeShownInVideo?.value
        : "",

      shotLabels: state?.[segment]?.shotLabels,

      products: state?.[segment]?.products?.exist
        ? state?.[segment]?.products?.value
        : [],
      speechTranscriptions: state?.[segment]?.speechTranscriptions || [],

      persons: state?.[segment]?.persons,
      segments: state?.[segment]?.segments,
      segmentType: segment + 1,
      sentiments: state?.[segment]?.sentiments,
      texts: state?.[segment]?.textInVideo?.exist
        ? state?.[segment]?.textInVideo?.value
        : [],
      voiceover: state?.[segment]?.voiceover?.exist
        ? state?.[segment]?.voiceover?.value
        : [],
      shortDetail: state?.[segment]?.shortDetail,
      creators: state?.[segment]?.creators,
      shotCounts: state?.[segment]?.shotCounts,
      combineTags: state?.[segment]?.combineTags,
      tags: state?.[segment]?.tags,
    };

    return _payload;
  }
  async function handlePrimaryAction(grpName) {
    let successSegments = 0;
    for (let segment = 0; segment < TOTAL_SEGMENTS; segment++) {
      const _payload = getPayloadForSegment(segment);
      _payload["groupName"] = grpName ? grpName : payload?.groupName ?? "";

      try {
        if (reportableId && mediaId) {
          if (segment === 5) await updatePredictionMediaTags(_payload);
        } else {
          await axiosOrgInstance.post(
            API_ROUTES.TAGGING.CREATE_VIDEO_GROUP_TAGS?.replace(
              ":groupId",
              payload?.groupId
            ),
            _payload
          );
        }
        successSegments = successSegments + 1;
      } catch (err) {
        console.log("err", err);
      }
    }

    if (successSegments === TOTAL_SEGMENTS) {
      refetchOrganizationTags(true);
      toast.success(
        `Successful placements of tags in '${
          grpName ? grpName : payload?.groupName ?? ""
        }'`
      );
      if (postOnboarding) {
        return;
      }
      navigate(APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS, {
        state: { from: "video" },
      });
    } else {
      toast.error(`Failed to place tags in '${payload?.groupName ?? ""}'`);
      if (postOnboarding) {
        return;
      }
      navigate(APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS, {
        state: { from: "video" },
      });
    }
  }

  function saveSingleSegment() {
    const _payload = getPayloadForSegment(segment);
    axiosOrgInstance
      .post(
        API_ROUTES.TAGGING.CREATE_VIDEO_GROUP_TAGS?.replace(
          ":groupId",
          payload?.groupId
        ),
        _payload
      )
      .then((res) => {
        toast.success(
          `Successful placements of tags in ${payload?.groupName} for ${segmentMapping[segment]}`
        );
        if (segment === TOTAL_SEGMENTS - 1) {
          navigate(APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS);
        } else {
          setSegment((prevState) => prevState + 1);
        }
      });
  }

  function updateStateFromAITags(tags) {
    //add tags to existing state
    //let's say tags are according to segments
    //so we need to add tags to each segment

    setState((prevState) => {
      const newState = [...prevState];
      for (let segment = 0; segment <= TOTAL_SEGMENTS; segment++) {
        const tag = tags?.[segment + 1] || {};
        newState[segment] = {
          ...newState[segment],

          groupVideoUrl: tag?.groupVideoUrl,

          combineTags: [
            ...new Set([
              ...(newState?.[segment]?.combineTags || []),
              ...(tag?.combineTags || []),
            ]),
          ],
          tags: [
            ...new Set([
              ...(newState?.[segment]?.tags || []),
              ...(tag?.tags || []),
            ]),
          ],

          resolution: [
            ...new Set([
              ...(newState?.[segment]?.resolution || []),
              ...(tag?.resolution || []),
            ]),
          ],

          colorPalette: [
            ...new Set([
              ...(newState?.[segment]?.colorPalette || []),
              ...(tag?.colorPalette || []),
            ]),
          ],

          visualThemes: [
            ...new Set([
              ...(newState?.[segment]?.visualThemes || []),
              ...(tag?.visualThemes || []),
            ]),
          ],
          objects: [
            ...new Set([
              ...(newState?.[segment]?.objects || []),
              ...(tag?.objects || []),
            ]),
          ],
          peopleDemographics: [
            ...new Set([
              ...(newState?.[segment]?.peopleDemographics || []),
              ...(tag?.peopleDemographics || []),
            ]),
          ],
          textAmount: [
            ...new Set([
              ...(newState?.[segment]?.textAmount || []),
              ...(tag?.textAmount || []),
            ]),
          ],
          textOverlayStyle: [
            ...new Set([
              ...(newState?.[segment]?.textOverlayStyle || []),
              ...(tag?.textOverlayStyle || []),
            ]),
          ],
          logoPresence: tag?.logoPresence || false,

          logoPlacement: [
            ...new Set([
              ...(newState?.[segment]?.logoPlacement || []),
              ...(tag?.logoPlacement || []),
            ]),
          ],
          productPresence: tag?.productPresence || false,
          productName: [
            ...new Set([
              ...(newState?.[segment]?.productName || []),
              ...(tag?.productName || []),
            ]),
          ],
          brandIntegration: [
            ...new Set([
              ...(newState?.[segment]?.brandIntegration || []),
              ...(tag?.brandIntegration || []),
            ]),
          ],

          backgroundElements: [
            ...new Set([
              ...(newState?.[segment]?.backgroundElements || []),
              ...(tag?.backgroundElements || []),
            ]),
          ],
          vfx: [
            ...new Set([
              ...(newState?.[segment]?.vfx || []),
              ...(tag?.vfx || []),
            ]),
          ],
          layoutAndComposition: [
            ...new Set([
              ...(newState?.[segment]?.layoutAndComposition || []),
              ...(tag?.layoutAndComposition || []),
            ]),
          ],
          shotType: [
            ...new Set([
              ...(newState?.[segment]?.shotType || []),
              ...(tag?.shotType || []),
            ]),
          ],
          editingStyle: [
            ...new Set([
              ...(newState?.[segment]?.editingStyle || []),
              ...(tag?.editingStyle || []),
            ]),
          ],
          cameraMovement: [
            ...new Set([
              ...(newState?.[segment]?.cameraMovement || []),
              ...(tag?.cameraMovement || []),
            ]),
          ],
          lightingStyle: [
            ...new Set([
              ...(newState?.[segment]?.lightingStyle || []),
              ...(tag?.lightingStyle || []),
            ]),
          ],

          interactivity: tag?.interactivity || false,
          platformSpecificFeatures: [
            ...new Set([
              ...(newState?.[segment]?.platformSpecificFeatures || []),
              ...(tag?.platformSpecificFeatures || []),
            ]),
          ],
          useOfSymbolsAndIcons: [
            ...new Set([
              ...(newState?.[segment]?.useOfSymbolsAndIcons || []),
              ...(tag?.useOfSymbolsAndIcons || []),
            ]),
          ],
          depthOfField: [
            ...new Set([
              ...(newState?.[segment]?.depthOfField || []),
              ...(tag?.depthOfField || []),
            ]),
          ],

          narrativeStyle: [
            ...new Set([
              ...(newState?.[segment]?.narrativeStyle || []),
              ...(tag?.narrativeStyle || []),
            ]),
          ],
          emotionalTone: [
            ...new Set([
              ...(newState?.[segment]?.emotionalTone || []),
              ...(tag?.emotionalTone || []),
            ]),
          ],
          isCtaAvailable: tag?.isCtaAvailable,
          cta: [
            ...new Set([
              ...(newState?.[segment]?.cta || []),
              ...(tag?.cta || []),
            ]),
          ],
          ctaStyle: [
            ...new Set([
              ...(newState?.[segment]?.ctaStyle || []),
              ...(tag?.ctaStyle || []),
            ]),
          ],
          OfferPromotionPresence: tag?.OfferPromotionPresence || false,
          offerPromotion: [
            ...new Set([
              ...(newState?.[segment]?.offerPromotion || []),
              ...(tag?.offerPromotion || []),
            ]),
          ],
          hookPresence: tag?.hookPresence || false,
          hook: [
            ...new Set([
              ...(newState?.[segment]?.hook || []),
              ...(tag?.hook || []),
            ]),
          ],
          targetAudienceTraits: [
            ...new Set([
              ...(newState?.[segment]?.targetAudienceTraits || []),
              ...(tag?.targetAudienceTraits || []),
            ]),
          ],
          culturalRelevance: [
            ...new Set([
              ...(newState?.[segment]?.culturalRelevance || []),
              ...(tag?.culturalRelevance || []),
            ]),
          ],
          contextualTagsByBusiness: [
            ...new Set([
              ...(newState?.[segment]?.contextualTagsByBusiness || []),
              ...(tag?.contextualTagsByBusiness || []),
            ]),
          ],

          // ---------------------------------------

          discountShownInVideo: {
            exist: tag?.discount?.length > 0,
            value: tag?.discount,
          },

          discountCodeShownInVideo: {
            exist: tag?.discountCode?.length > 0,
            value: tag?.discountCode,
          },

          creators: [
            ...new Set([
              ...(newState?.[segment]?.creators || []),
              ...(tag?.creators || []),
            ]),
          ],

          sentiments: [
            ...new Set([
              ...(newState?.[segment]?.sentiments || []),
              ...(tag?.sentiments || []),
            ]),
          ],
          contentThemes: [
            ...new Set([
              ...(newState?.[segment]?.contentThemes || []),
              ...(tag?.contentThemes || []),
            ]),
          ],

          persons: [
            ...new Set([
              ...(newState?.[segment]?.persons || []),
              ...(tag?.persons || []),
            ]),
          ],
          textInVideo: {
            ...newState[segment]?.textInVideo,
            exist: !!tag?.texts?.length,
            value: [
              ...new Set([
                ...(newState[segment]?.textInVideo?.value || []),
                ...(tag?.texts || []),
              ]),
            ],
          },

          voiceover: {
            ...newState[segment]?.voiceover,
            exist: !!tag?.voiceover?.length,
            value: [
              ...new Set([
                ...(newState[segment]?.voiceover?.value || []),
                ...(tag?.voiceover || []),
              ]),
            ],
          },

          products: {
            ...newState[segment]?.products,
            exist: !!tag?.products?.length,
            value: [
              ...new Set([
                ...(newState[segment]?.isProductImage?.value || []),
                ...(tag?.products || []),
              ]),
            ],
          },
          shotLabels: [
            ...new Set([
              ...(newState?.[segment]?.shotLabels || []),
              ...(tag?.shotLabels || []),
            ]),
          ],
          shotCounts: tag?.shotCounts || "",
          speechTranscriptions: [
            ...new Set([
              ...(newState?.[segment]?.speechTranscriptions || []),
              ...(tag?.speechTranscriptions || []),
            ]),
          ],
        };
      }
      return newState;
    });
    setAiTagApplied(true);
    setIsWarningModalOpen(false);
  }

  function getAiTagsList(tags) {
    let aiTags = [];
    for (let segment = 1; segment <= TOTAL_SEGMENTS; segment++) {
      delete tags?.[segment]?._id;
      delete tags?.[segment]?.segmentType;
      const segmentTags = Object.values(tags?.[segment] || {});
      aiTags.push(segmentTags?.flat());
    }
    setAiTagsLists(aiTags);
  }
  function fetchAiTags() {
    axiosOrgInstance
      .get(
        API_ROUTES.TAGGING.GET_VIDEO_AI_TAGS.replace(
          ":groupId",
          payload?.groupId
        )
      )
      .then((res) => {
        updateStateFromAITags(res);
        getAiTagsList(res);
      });
  }

  function getAITaggingStatus() {
    axiosOrgInstance
      .get(API_ROUTES.COMMON.APPLICATION_STATUS, {
        params: {
          groupId: payload?.groupId,
        },
      })
      .then((res) => {
        if (res?.isGroupAiTaggingDone) {
          clearInterval(intervalId?.current);
          fetchAiTags();
        }
      });
  }

  function handleWait() {
    setIsWarningModalOpen(false);
  }

  function handleContinue() {
    setIsWarningModalOpen(false);
    handlePrimaryAction();
  }

  function openWarningModal() {
    if (isOnlylastsegmentActive) {
      handlePrimaryAction();
      return;
    }
    setIsWarningModalOpen(true);
  }

  function getGroupTags(groupId) {
    axiosOrgInstance
      .get(
        API_ROUTES.GET_GROUP_TAGS.GET_VIDEO_GROUP_TAGS.replace(
          ":groupId",
          groupId
        )
      )
      .then((res) => {
        updateStateFromAITags(res);
      });
  }
  useEffect(() => {
    if (open && type === MODALTYPES.VIDEO_TAGGING_MODAL) {
      if (postOnboarding) {
        if (reportableId && mediaId) {
          return;
        } else {
          getGroupTags(payload?.groupId);
        }
      }
      intervalId.current = setInterval(() => {
        getAITaggingStatus();
      }, 5000);
    }
  }, [open, type]);

  useEffect(() => {
    if (reportableId && mediaId) {
      if (predictionTags) {
        const formattedIntoSegment = {
          1: {},
          2: {},
          3: {},
          4: {},
          5: {},
          6: predictionTags?.tags || {},
        };

        updateStateFromAITags(formattedIntoSegment);
      }
    }
  }, [predictionTags]);

  useEffect(() => {
    (() => {
      clearInterval(intervalId?.current);
    })();
  }, []);

  return {
    state,
    segment,
    setSegment,
    handleTagsChange,
    handleSwitch,
    handleInputChange,
    handlePrimaryAction,
    handleSkipSegment,
    segmentMapping,
    saveSingleSegment,
    TOTAL_SEGMENTS,
    aiTagApplied,
    handleWait,
    handleContinue,
    openWarningModal,
    isWarningModalOpen,
    aiTagsLists,
    isAdvanceTaggingActive,
  };
}
