import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../hooks/useAxios";
import useInfiniteScroll from "../../../../hooks/useInfiniteScroll";

import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "../../../../redux/slices/modal.slices";
import { API_ROUTES, APP_ROUTES } from "../../../../routes";
import { GROUP_ENTITY } from "../../../../utils/constants";
import { toast } from "react-toastify";

export default function useUngroupedVideos() {
  const [isSelecting, setIsSelecting] = React.useState(false);
  const [entitiesList, setEntitiesList] = React.useState([]);
  const { axiosOrgInstance } = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const { isLoadMore } = useInfiniteScroll("content-container", hasNextPage);
  const [page, setPage] = React.useState(1);
  const [totalDocs, setTotalDocs] = React.useState(0);
  const [newGroupName, setNewGroupName] = React.useState("");

  function toggleSelecting() {
    setIsSelecting((prev) => !prev);
  }

  function handleSelect(id) {
    let _groups = entitiesList?.map((group) => {
      if (group._id === id) {
        return {
          ...group,
          isSelected: !group.isSelected,
        };
      }
      return group;
    });

    setEntitiesList(_groups);
  }

  function handleMoveToGroup() {
    let entities = entitiesList?.filter((group) => group.isSelected);
    dispatch(
      setModal({
        type: MODALTYPES.GROUP_IMAGES_MODAL,
        open: true,
        payload: {
          title: `Move selected ${
            entities?.length > 1 ? "videos" : "video"
          } to a group`,
          btnText: "Create new group",
          dangerMode: false,
          entityType: GROUP_ENTITY.VIDEO,
          primaryActioPayload: {
            entities,
          },
        },
      })
    );
  }

  function addSysGeneratedTagsModalPrimaryAction() {
    navigate(APP_ROUTES.ONBOARDING.AUTO_TAGGING_PROCESSING);
  }

  function moveToSuggestedGroup(actionPayload) {
    const { id: entityId, groupId, groupName } = actionPayload;
    let entities = entitiesList?.filter((group) => group.isSelected);
    let entitiesIds = entities?.map((entity) => entity._id);
    if (!groupId) return;

    let payload = {
      groupEntityIdsToBeAdded: !!entityId ? [entityId] : entitiesIds,
    };

    if (groupName) {
      payload.groupName = groupName;
    }

    axiosOrgInstance
      .patch(
        API_ROUTES.ONBOARDING.UPDATE_GROUP_ENTITY.replace(":groupId", groupId),
        payload
      )
      .then(() => {
        initGroups();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleCreateAndAddToNewGroup(actionPayload) {
    const { id: entityId, newGroupName } = actionPayload;
    axiosOrgInstance
      .post(API_ROUTES.UNGROUPED.CREATE_GROUP, {
        groupName: newGroupName,
        groupEntity: GROUP_ENTITY.VIDEO,
        groupEntityIds: [entityId],
      })
      .then((res) => {
        const payload = {
          groupEntityIdsToBeAdded: [entityId],
          groupName: newGroupName,
        };
        axiosOrgInstance
          .patch(
            API_ROUTES.ONBOARDING.UPDATE_GROUP_ENTITY.replace(
              ":groupId",
              res?._id
            ),
            payload
          )
          .then(() => {
            toast("Grouping completed successfully");
            initGroups();
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }

  function onCreateNewGroup() {
    dispatch(setModal({ type: MODALTYPES.CREATE_NEW_GROUP_MODAL, open: true }));
  }
  function newGroupModalPrimaryAction() {
    let entities = entitiesList?.filter((group) => group.isSelected);
    let entitiesIds = entities?.map((entity) => entity.entityId);
    axiosOrgInstance
      .post(API_ROUTES.UNGROUPED.CREATE_GROUP, {
        groupName: newGroupName,
        groupEntity: GROUP_ENTITY.VIDEO,
        groupEntityIds: entitiesIds,
      })
      .then((res) => {
        toast.success(`Videos successfully moved to ${newGroupName} group`);
        dispatch(setModal(modalInitState));
        initGroups();
      });
  }
  function initGroups() {
    axiosOrgInstance
      .get(API_ROUTES.UNGROUPED.LIST_UNGROUPED_ENTITIES, {
        params: {
          groupEntity: GROUP_ENTITY.VIDEO,
        },
      })
      .then((res) => {
        const ungroupedEntities = res?.docs;
        let _groups = ungroupedEntities?.map((group) => {
          return {
            ...group,
            isSelected: false,
          };
        });

        setEntitiesList(_groups);
        setHasNextPage(res?.hasNextPage);
        setPage(res?.page);
        setTotalDocs(res?.totalDocs);
        dispatch(setModal(modalInitState));
      });
  }

  useEffect(() => {
    if (isLoadMore && hasNextPage) {
      axiosOrgInstance
        .get(API_ROUTES.UNGROUPED.LIST_UNGROUPED_ENTITIES, {
          params: {
            groupEntity: GROUP_ENTITY.VIDEO,
            page: page + 1,
          },
        })
        .then((res) => {
          const ungroupedEntities = res?.docs;
          let _groups = ungroupedEntities?.map((group) => {
            return {
              ...group,
              isSelected: false,
            };
          });
          setHasNextPage(res?.hasNextPage);
          setPage(res?.page);
          setEntitiesList((prev) => [...prev, ..._groups]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadMore]);

  useEffect(() => {
    initGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSelecting,
    toggleSelecting,
    entitiesList,
    handleSelect,
    handleMoveToGroup,
    addSysGeneratedTagsModalPrimaryAction,
    moveToSuggestedGroup,
    initGroups,
    totalDocs,
    onCreateNewGroup,
    newGroupName,
    setNewGroupName,
    newGroupModalPrimaryAction,
    handleCreateAndAddToNewGroup,
  };
}
