import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { add_tags, throwing_trash } from "../../../assets/images";
import {
  modalInitState,
  MODALTYPES,
  setModal,
} from "../../../redux/slices/modal.slices";
import useAxios from "../../../hooks/useAxios";
import { API_ROUTES, APP_ROUTES } from "../../../routes";
import { toast } from "react-toastify";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";

export default function useGroupContent() {
  const [contentList, setContentList] = React.useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { isLoadMore } = useInfiniteScroll("content-container", hasNextPage);
  const [page, setPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { axiosOrgInstance } = useAxios();

  //useParams
  const { groupId, groupEntity } = useParams();
  //useLocation
  const { state: derivedGroupName } = location;

  const groupNameRef = useRef(derivedGroupName);
  const initGroups = useCallback(() => {
    axiosOrgInstance
      .get(API_ROUTES.ONBOARDING.LIST_GROUP_ENTITY.replace(":groupId", groupId))
      .then((res) => {
        let _groups = res?.docs?.map((group, index) => {
          return {
            ...group,
            isSelected: true,
          };
        });
        setTotalDocs(res?.totalDocs);
        setHasNextPage(res?.hasNextPage);
        setPage(res?.page);
        setContentList([..._groups]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  function handlePlayPause(id) {
    let lists = [...contentList];
    let updatedPlaying = lists?.map((group) => {
      if (group._id === id) {
        return {
          ...group,
          isPlaying: !group.isPlaying,
        };
      }

      return { ...group, isPlaying: false };
    });
    setContentList(updatedPlaying);
  }

  function resetEntitiesSelection() {
    let _groups = contentList.map((group) => {
      return {
        ...group,
        isSelected: true,
      };
    });
    setContentList(_groups);
  }

  function handleSelect(id) {
    let _groups = contentList?.map((group) => {
      if (group._id === id) {
        return {
          ...group,
          isSelected: !group.isSelected,
        };
      }
      return group;
    });

    //unselected counts
    let selectedCount = _groups?.filter((group) => group.isSelected).length;
    if (selectedCount !== 0) setContentList(_groups);
  }

  function postSuccessAction() {
    dispatch(
      setModal({
        type: MODALTYPES.SUCCESS,
        open: true,
        props: { success_msg: "Your Group Updated Successfully" },
      })
    );

    setTimeout(() => {
      dispatch(setModal(modalInitState));
      dispatch(
        setModal({
          type: MODALTYPES.BASIC_ACTION.ADD_MANUAL_TAGS,
          open: true,
          payload: {
            action_sure_msg: `Do You Want To Add Manual Tags?`,
            action_warning_msg:
              "In This Step You Add Manual Tags For Your Creative.",
            primaryBtnText: "Yes",
            secondaryBtnText: "Use AI Tags",
            dangerMode: false,
            image: add_tags,
          },
        })
      );
    }, 2000);
  }

  function updateGroupCall(groupEntityIdsToBeRemoved) {
    let payload = {
      groupEntityIdsToBeRemoved: groupEntityIdsToBeRemoved,
    };

    axiosOrgInstance
      .patch(
        API_ROUTES.ONBOARDING.UPDATE_GROUP_ENTITY.replace(":groupId", groupId),
        payload
      )
      .then((res) => {
        //go back
        // navigate(APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS);
      })
      .catch((err) => {
        groupNameRef.current.focus();
      });
  }

  function updateGroupNameCall() {
    if (!groupNameRef?.current?.value) {
      groupNameRef?.current?.focus();
      return;
    }

    let payload = {
      groupName: groupNameRef?.current?.value,
    };

    axiosOrgInstance
      .patch(
        API_ROUTES.ONBOARDING.UPDATE_MANUAL_GROUPING.replace(
          ":groupId",
          groupId
        ),
        payload
      )
      .then((res) => {
        let _unSelectedImages = contentList?.filter(
          (group) => !group.isSelected
        );
        if (_unSelectedImages?.length > 0) {
          updateGroup(false);
          return;
        }
        postSuccessAction();
      });
  }

  function updateGroup(willNavigate) {
    let _SelectedImages = contentList?.filter((group) => group.isSelected);
    const areAllSelected = _SelectedImages?.length === 0;
    let _unSelectedImages = contentList?.filter((group) => !group.isSelected);
    if (areAllSelected) {
      return;
    } else {
      let action_sure_msg = "";
      if (!willNavigate) {
        action_sure_msg = `Do You Want To Remove ${_unSelectedImages?.length} ${groupEntity}s in this Group?`;
      } else {
        action_sure_msg = `Do You Want To Remove ${_SelectedImages?.length} ${groupEntity}s in this Group?`;
      }
      dispatch(
        setModal({
          type: MODALTYPES.BASIC_ACTION.REMOVE_IMAGES,
          open: true,
          payload: {
            action_sure_msg,
            action_warning_msg: "This Action Cannot Be Undone",
            primaryBtnText: "Remove",
            secondaryBtnText: "Cancel",
            dangerMode: true,
            image: throwing_trash,
            primaryActionPayload: {
              willNavigate: willNavigate,
            },
          },
        })
      );
    }
  }

  function addManualTagsPrimaryAction() {
    const _selectedMedia = contentList?.filter((group) => group.isSelected);

    const payload = {
      groupName: groupNameRef?.current?.value,
      groupId,
    };

    groupEntity === "image"
      ? (payload.imageUrl = _selectedMedia?.[0]?.imageUrl)
      : (payload.videoUrl = _selectedMedia?.[0]?.videoUrl);

    let type =
      groupEntity === "image"
        ? MODALTYPES.IMAGE_TAGGING_MODAL
        : MODALTYPES.VIDEO_TAGGING_MODAL;
    dispatch(setModal({ type, open: true, payload }));
  }

  function addManualTagsSecondaryAction() {
    const API_ROUTE = groupEntity === "image" ?
      API_ROUTES.TAGGING.CREATE_IMAGE_GROUP_TAGS :
      API_ROUTES.TAGGING.CREATE_VIDEO_GROUP_TAGS
    axiosOrgInstance
      .post(
        API_ROUTE?.replace(
          ":groupId",
          groupId
        ),
        {
          useAiTags: true,
        }
      )
      .then((res) => {
        toast.success("Your Tags are Added Successfully");
        navigate(APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS, {
          state: { from: groupEntity === "image" ? "image" : "video" },
        });
      });
  }

  function removeImagesPrimaryAction(primaryActionPayload) {
    let _SelectedEntities = contentList?.filter((group) => group.isSelected);
    let _UnselectedEntities = contentList?.filter((group) => !group.isSelected);
    let _selectedEntityIds = _SelectedEntities?.map((group) => group._id);
    let _unSelectedEntityIds = _UnselectedEntities?.map((group) => group._id);

    let payload = {
      groupEntityIdsToBeRemoved: primaryActionPayload?.willNavigate
        ? _selectedEntityIds
        : _unSelectedEntityIds,
    };

    axiosOrgInstance
      .patch(
        API_ROUTES.ONBOARDING.UPDATE_GROUP_IMAGES.replace(":groupId", groupId),
        payload
      )
      .then((res) => {
        //go back
        initGroups();
        if (primaryActionPayload?.willNavigate) return;
        else {
          postSuccessAction();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function removeImagesSecondaryAction() {}

  useEffect(() => {
    initGroups();
  }, [initGroups]);

  useEffect(() => {
    if (isLoadMore && hasNextPage) {
      axiosOrgInstance
        .get(
          API_ROUTES.ONBOARDING.LIST_GROUP_ENTITY.replace(":groupId", groupId),
          {
            params: {
              page: page + 1,
            },
          }
        )
        .then((res) => {
          setHasNextPage(res?.hasNextPage);
          setPage(res?.page);
          let _groups = res?.docs?.map((group, index) => {
            return {
              ...group,
              isSelected: true,
            };
          });

          setContentList((prev) => [...prev, ..._groups]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadMore]);

  useEffect(() => {
    if (groupNameRef.current) {
      if (!derivedGroupName) groupNameRef.current.focus();
    }
  }, [derivedGroupName, groupNameRef]);

  return {
    // These are for Group Content
    contentList,
    handleSelect,
    updateGroup,
    groupEntity,
    totalDocs,
    resetEntitiesSelection,

    // These are for Basic Action Modal
    addManualTagsPrimaryAction,
    addManualTagsSecondaryAction,
    removeImagesPrimaryAction,
    removeImagesSecondaryAction,

    // These are for Group Names
    derivedGroupName,
    groupNameRef,
    updateGroupCall,
    updateGroupNameCall,

    //For video
    handlePlayPause,
  };
}
