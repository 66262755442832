import {
  useGetPaginatedMediaForPrediction,
  useUpdateSpecificPrediction,
} from "apis/predictionApi";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APP_ROUTES } from "routes";

export default function usePredictionStep2() {
  const { reportableId = "" } = useParams();
  const { data: predictionMedias, fetchNextPage } =
    useGetPaginatedMediaForPrediction(reportableId);

  const flatData = predictionMedias?.pages?.map((page) => page.data).flat();
  const [currentPage, setCurrentPage] = useState(1);
  const { totalPages = 1 } =
    predictionMedias?.pages?.[0]?.meta?.pagination ?? {};
  const hasNext = currentPage < totalPages;

  const { isLoadMore } = useInfiniteScroll("content-container", hasNext);

  const { mutateAsync: performManualTaggingComplete } =
    useUpdateSpecificPrediction(
      APP_ROUTES.PREDICTION.PREDICTION_REPORT.replace(
        ":reportableId",
        reportableId
      )
    );

  function setManualTaggingCompleted() {
    performManualTaggingComplete({
      reportableId,
      payload: { action: "manualTaggingCompleted" },
    });
  }

  useEffect(() => {
    const hasNext = currentPage < totalPages;

    if (isLoadMore && hasNext) {
      setCurrentPage((prev) => prev + 1);
      fetchNextPage();
    }
  }, [isLoadMore, hasNext, fetchNextPage, currentPage, totalPages]);
  return {
    predictionMedias: flatData || [],
    setManualTaggingCompleted,
  };
}
