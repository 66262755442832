import React from "react";
import PublicContainer from "../../../../components/layouts/PublicContainer";
import { Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { emailVerified } from "../../../../assets/icons/svg";
import { APP_ROUTES } from "../../../../routes";

export default function VerifiedScreen() {
  const navigate = useNavigate();
  return (
    <PublicContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "30px",
            height: "100%",
          }}
        >
          <img src={emailVerified} alt="" />
        </div>

        <Typography
          variant="h1"
          sx={{ marginBottom: "25px", fontWeight: "700", textAlign: "center" }}
        >
          Verified
        </Typography>
        <Typography
          variant="h3"
          sx={{
            color: "#7f7f7f",
            fontWeight: "500",
            textAlign: "center",
            lineHeight: 1.8,
          }}
        >
          You Have Successfully Verified Account.
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              marginTop: "25px",
              padding: "10px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
            onClick={() => {
              navigate(APP_ROUTES.AUTH.SELECT_ROLE);
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </PublicContainer>
  );
}
