import { createSlice } from "@reduxjs/toolkit";

export const intialState = {
  appState: {
    isDataPullCompleted: false,
    isUserTypeDefined: false,
    isOrganizationAdded: false,
    isPlatformAddedToOrganization: false,
    isOrganizationImagesAiGroupingDone: false,
    isOrganizationVideosAiGroupingDone: false,
    isOrganizationImagesManualGroupingDone: false,
    isOrganizationVideosManualGroupingDone: false,
    isOrganizationTextsGroupingDone: false,
    isOrganizationCustomAudiencesGroupingDone: false,
    isOrganizationCustomAudiencesTaggingDone: false,
    isSidebarOpen: true,
    userRole: "",
    subscriptionId: null,
    isSubscriptionActive: false,
    activeFeatures: {
      INSIGHTS: false,
      RECOMMENDATION: false,
      PREDICTION: false,
      GENERATION: false,
    },
    paymentLink: null,
  },
};

const appStateSlice = createSlice({
  name: "appState",
  initialState: intialState,
  reducers: {
    setAppState: (state, action) => {
      state.appState = { ...state.appState, ...action.payload };
    },
    setSidebarOpen: (state, action) => {
      state.appState = { ...state.appState, isSidebarOpen: action.payload };
    },
    resetAppState: (state) => {
      state.appState = { ...intialState };
    },
  },
});

export const { setAppState, setSidebarOpen, resetAppState } =
  appStateSlice.actions;
export default appStateSlice.reducer;
