const { Button } = require("@mui/material");

const isNotClkStyle = {
  background: "rgba(255, 255, 255, 0.7)",
  boxShadow: "none",
};

const BlurredBgBtnWIcon = ({ Icon, sx, title, isClickable, onClick }) => {
  return (
    <Button
      variant="contained"
      sx={{
        background: "rgba(255, 255, 255, 0.7)",
        position: "absolute",
        textTransform: "capitalize",
        display: "flex",
        gap: "8px",
        fontSize: "12px",
        fontWeight: "300",
        borderRadius: "24px",
        padding: "4px 8px",
        color: "#000",
        ...(!isClickable && isNotClkStyle),
        "&:hover": {
          background: "rgba(255, 255, 255, 0.8)",
          ...(!isClickable && isNotClkStyle),
        },
        ...sx,
      }}
      onClick={onClick}
    >
      {Icon}
      <span style={{ mixBlendMode: "exclusion", color: "#fff" }}>{title}</span>
    </Button>
  );
};

export default BlurredBgBtnWIcon;
