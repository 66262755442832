import { useMutation, useQuery } from "@tanstack/react-query";
import useAxios from "hooks/useAxios";
import { RECOMMENDATION } from "./apiEndPoints";
import { CACHE_TIME } from "utils/constants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const fiveMinutes = 1000 * 60 * 5;

const useGetRecommendationFilters = (isPostOnboarding, currentReportOrgId) => {
  const { axiosOrgInstance } = useAxios();
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const queryKey = ["recommendationFilters", selectedOrganisation?._id];
  let queryParams;
  if (!!currentReportOrgId) {
    queryParams = `orgId=${currentReportOrgId}&include="adAccountIds,targetingLocations,creativeTypes,optimizationGoals,objectives,funnelStages,modelKpi,platformIds"`;
  } else {
    queryParams = `include="adAccountIds,targetingLocations,creativeTypes,optimizationGoals,objectives,funnelStages,modelKpi,platformIds"`;
  }
  const data = useQuery(
    queryKey,
    () =>
      axiosOrgInstance.get(`/commons/filters?${queryParams}`).then((res) => {
        const payload = {
          objectives: res.objectives,
          optimizationGoals: res.optimizationGoals,
          funnelStages: res.funnelStages,
          creativeTypes: res.creativeTypes,
          targetingCountries: res.targetingLocations,
          adAccountIds: res.adAccountIds,
          ...res,
        };
        delete payload.targetingLocations;
        return payload;
      }),
    {
      enabled: isPostOnboarding || !!currentReportOrgId,
      staleTime: CACHE_TIME,
      cacheTime: CACHE_TIME,
      refetchOnWindowFocus: false,
    }
  );
  return data;
};

const useGetRecommendations = (currentReportOrgId) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["recommendations"];
  return useMutation(
    (payload) => {
      if (!payload?.kpis?.length && !payload.sortBy) return;
      return axiosOrgInstance
        .post(
          RECOMMENDATION.GET_RECOMMENDATIONS,
          {
            ...payload,
          },
          {
            params: {
              orgId: currentReportOrgId,
            },
          }
        )
        .then((res) => {
          const response = {
            ...res,
          };

          if (response.segment) {
            response.segment = response.segment.sort((a, b) => +a.key - +b.key);
          }

          return response;
        });
    },
    {
      onSuccess: () => {},
      queryKey: queryKey,
    }
  );
};

const useCreateRecommendationReport = () => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["createInsightsReport"];

  return useMutation(
    queryKey,
    (payload) => {
      return axiosOrgInstance.post(
        RECOMMENDATION.CREATE_RECOMMENDATIONS_REPORT,
        payload
      );
    },
    {
      onSuccess: () => {
        toast.success(`Recommendation Report Created Successfully`);
      },
      queryKey: queryKey,
    }
  );
};

const useGetSpecificRecommendation = (
  insightFilters,
  id,
  currentReportOrgId
) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = [`recommendation-${id}`];
  const isEnabled = !!insightFilters && !!id;
  return useQuery(
    queryKey,
    () => {
      return axiosOrgInstance.get(
        `${RECOMMENDATION.GET_SPECIFIC_RECOMMENDATION?.replace(
          ":recommendationId",
          id
        )}`,
        {
          params: {
            orgId: currentReportOrgId,
          },
        }
      );
    },
    {
      enabled: isEnabled,
      onSuccess: () => {},
      queryKey: queryKey,
      refetchOnWindowFocus: false,
    }
  );
};

export {
  useGetRecommendationFilters,
  useGetRecommendations,
  useCreateRecommendationReport,
  useGetSpecificRecommendation,
};
