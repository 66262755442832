import React, { useEffect } from "react";
import {
  Dialog,
  useMediaQuery,
  Typography,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "../../../redux/slices/modal.slices";
import useAxios from "../../../hooks/useAxios";
import { API_ROUTES } from "../../../routes";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";

const audienceType = {
  Cold: 1,
  Mixed: 2,
  Warm: 3,
};

function WarmAudienceForm(props) {
  const { warmAudienceForm, setWarmAudienceForm } = props;
  return (
    <FormControl
      sx={{
        display: "flexbox",
        flexDirection: "column",
        gap: "8px",
        paddingLeft: "16px",
        borderLeft: "1px solid #A1B0CC",
      }}
    >
      <FormLabel id="demo-row-radio-buttons-group-label">
        Any post purchase audience?
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={warmAudienceForm?.isPostPurchaseAudience}
        onChange={(e) => {
          const value = e.target.value === "true" ? true : false;
          setWarmAudienceForm((prev) => {
            const payload = {
              ...prev,
              isPostPurchaseAudience: value,
            };

            if (value === true) {
              delete payload["isBofAudience"];
            }

            return payload;
          });
        }}
      >
        <FormControlLabel value={true} control={<Radio />} label="Yes" />
        <FormControlLabel value={false} control={<Radio />} label="No" />
      </RadioGroup>

      {warmAudienceForm?.isPostPurchaseAudience === false && (
        <>
          <FormLabel>Any BOF audience?</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={warmAudienceForm?.isBofAudience}
            onChange={(e) =>
              setWarmAudienceForm((prev) => ({
                ...prev,
                isBofAudience: e.target.value === "true" ? true : false,
              }))
            }
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </>
      )}
    </FormControl>
  );
}

export default function AudienceTaggingModal(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { axiosOrgInstance } = useAxios();
  const { open, type, payload } = useSelector((state) => state?.modal);
  const [warmAudienceForm, setWarmAudienceForm] = React.useState({
    isBofAudience: false,
    isPostPurchaseAudience: false,
  });
  const [activeAudience, setActiveAudience] = React.useState();
  const dispatch = useDispatch();

  function handleClose() {
    dispatch(setModal(modalInitState));
    setActiveAudience();
    setWarmAudienceForm({
      isBofAudience: false,
      isPostPurchaseAudience: false,
    });
  }

  function audienceActionHandler(actionType) {
    setActiveAudience(actionType);
  }
  function SaveAudience() {
    handleClose();

    const apiPayload = {
      customAudienceType: audienceType[activeAudience],
    };
    if (activeAudience === "Warm") {
      apiPayload.isBofAudience = Boolean(warmAudienceForm.isBofAudience);
      apiPayload.isPostPurchaseAudience = Boolean(
        warmAudienceForm.isPostPurchaseAudience
      );
    }

    axiosOrgInstance
      .post(
        API_ROUTES.TAGGING.CREATE_CUSTOM_AUDIENCE_TAGS?.replace(
          ":groupId",
          payload?.row?._id
        ),
        apiPayload
      )
      .then((res) => {
        toast.success(`${payload?.row?.groupName} Tagged Successfully`);
        props?.initGroups && props?.initGroups();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getGroupTags() {
    axiosOrgInstance
      .get(
        API_ROUTES.GET_GROUP_TAGS.GET_CUSTOM_AUDIENCE_TAGS?.replace(
          ":groupId",
          payload?.row?._id
        )
      )
      .then((res) => {
        const warmAudienceForm = {
          isBofAudience: res?.isBofAudience,
          isPostPurchaseAudience: res?.isPostPurchaseAudience,
        };
        // res?.customAudienceType = 1 /2 /3
        const activeAudience = Object.keys(audienceType).find(
          (key) => audienceType[key] === res?.customAudienceType
        );
        setActiveAudience(activeAudience);
        setWarmAudienceForm(warmAudienceForm);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if (open && type === MODALTYPES.AUDIENCE_TAGGING_MODAL) {
      if (props?.postOnboarding) {
        getGroupTags();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open && type === MODALTYPES.AUDIENCE_TAGGING_MODAL}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
    >
      <div className={styles.audience_tagging_wrapper}>
        <div className={styles.audience_tagging_header}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3" className={styles.audience_tagging_title}>
              Audience Tagging
            </Typography>
            <Close onClick={handleClose} />
          </Stack>

          <Typography
            variant="subtitle1"
            className={styles.audience_tagging_subtitle}
          >
            Select Your Targeted Audience
          </Typography>
        </div>
        <div className={styles.audience_tagging_actions}>
          <Button
            name="Cold"
            variant="outlined"
            className={styles.audience_tagging_btn}
            onClick={(event) => audienceActionHandler(event.target.name)}
            style={{ borderColor: activeAudience === "Cold" && "#5E48E8" }}
          >
            Cold Audience
          </Button>
          <Button
            name="Warm"
            variant="outlined"
            className={styles.audience_tagging_btn}
            onClick={(event) => audienceActionHandler(event.target.name)}
            style={{ borderColor: activeAudience === "Warm" && "#5E48E8" }}
          >
            Warm Audience
          </Button>
          <Button
            name="Mixed"
            variant="outlined"
            className={styles.audience_tagging_btn}
            onClick={(event) => audienceActionHandler(event.target.name)}
            style={{ borderColor: activeAudience === "Mixed" && "#5E48E8" }}
          >
            Mixed Audience
          </Button>
        </div>
        {activeAudience === "Warm" && (
          <WarmAudienceForm
            warmAudienceForm={warmAudienceForm}
            setWarmAudienceForm={setWarmAudienceForm}
          />
        )}
        <div className={styles.audience_tagging_info}>
          <Typography className={styles.audience_tagging_info_title}>
            Audience Name
          </Typography>
          <Typography
            variant="h3"
            className={styles.audience_tagging_info_name}
          >
            {payload?.row?.groupEntityIds?.[0]?.name || payload?.row?.groupName}
          </Typography>
        </div>
        <div className={styles.audience_tagging_save}>
          <Button
            variant="contained"
            disabled={!activeAudience}
            onClick={SaveAudience}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
