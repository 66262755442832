import React, { useEffect, useState } from "react";
import styles from "./StorytellerLogoLoader.module.css";
import { Stack } from "@mui/material";
import logo from "@/../../public/logo.svg";

function StorytellerLogoLoader() {
  const [currentIdx, setCurrentIdx] = useState(1);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  const [arr, setArr] = useState(
    shuffleArray([
      "For every minute spent organizing, an hour is earned.",
      "Organizing is a journey, not a destination.",
      "Clutter is nothing more than postponed decisions.",
      "The secret of getting ahead is getting started.",
      "The key to success is to start before you are ready.",
      "Out of clutter, find simplicity.",
      "The best way to get something done is to begin.",
      "A place for everything, everything in its place.",
      "Small deeds done are better than great deeds planned.",
      "The greatest wealth is to live content with little.",
      "Do something today that your future self will thank you for.",
      "Less stuff means more room for living.",
      "A well-organized space clears the mind.",
      "Success is not the key to happiness. Happiness is the key to success.",
      "Every accomplishment starts with the decision to try.",
      "Perfection is not attainable, but if we chase perfection we can catch excellence.",
      "The journey of a thousand miles begins with one step.",
      "Productivity is never an accident. It is always the result of a commitment to excellence.",
      "The way to get started is to quit talking and begin doing.",
      "The future depends on what you do today.",
    ])
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (currentIdx === arr?.length) {
        setCurrentIdx(1);
      } else {
        setCurrentIdx(currentIdx + 1);
      }
    }, 4000);
    return () => {
      clearTimeout(timeout);
    };
  }, [currentIdx]);
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1.25rem",
      }}
    >
      <img src={logo} alt="" className={`${styles.loaderImage}`} />
      <div className="relative">
        {arr.map((elem, idx) => {
          return (
            <p
              className={
                currentIdx === idx + 1
                  ? `${styles.isVisible}`
                  : `${styles.isHidden}`
              }
            >
              {elem}
            </p>
          );
        })}
      </div>
    </Stack>
  );
}

export default StorytellerLogoLoader;
