import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useAxios from "hooks/useAxios";
import { COMMON, REPORTS } from "./apiEndPoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { CACHE_TIME } from "utils/constants";
import { setUserBookmarkReports } from "redux/slices/app.slices";
import { useDispatch } from "react-redux";
import { useGetOrgFolders } from "./common";
import { refreshOpenFolderIds } from "redux/slices/folder.slice";

const useGetReports = () => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["reportsList"];
  return useMutation(
    queryKey,
    (page = 1) =>
      axiosOrgInstance.get(REPORTS.GET_REPORTS, {
        params: {
          page,
          perPage: 20,
          sort: "createdAt:desc",
        },
      }),
    {
      staleTime: 5000,
      refetchOnWindowFocus: false,
    }
  );
};

const useGetBookmarkReports = (enabled) => {
  const dispatch = useDispatch();
  const { axiosUserInstance } = useAxios();
  const queryKey = ["bookmarked-reports"];

  const data = useQuery(
    queryKey,
    () =>
      axiosUserInstance
        .get(REPORTS.BOOKMARK_REPORT, {
          params: {
            sort: "createdAt:desc",
          },
        })
        .then((res) => {
          dispatch(setUserBookmarkReports([]));
          res.map((curr) => {
            dispatch(setUserBookmarkReports(curr.reportableId));
          });
          return res;
        }),
    {
      staleTime: CACHE_TIME,
      enabled,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );
  return data;
};

const useRemoveBookmark = () => {
  const { refetch: fetchBookmarks } = useGetBookmarkReports(false);
  const { axiosUserInstance } = useAxios();
  return useMutation((reportId) => {
    axiosUserInstance
      .delete(REPORTS.REMOVE_BOOKMARK.replace(":id", reportId))
      .then((res) => {
        toast.success("Bookmark removed successfully");
        fetchBookmarks();
      })
      .catch((err) => toast.error("Failed to remove bookmark"));
  });
};

const useBookmarkReport = (init) => {
  const { axiosUserInstance } = useAxios();
  return useMutation((inputs) => {
    axiosUserInstance
      .post(REPORTS.BOOKMARK_REPORT, inputs)
      .then((res) => {
        init();
        toast.success("Bookmark added successfully");
        return res;
      })
      .catch((err) => toast.error("Failed to mark bookmark"));
  });
};

const useDeleteReport = () => {
  const navigate = useNavigate();
  const { axiosOrgInstance } = useAxios();
  const { refetch: refetchOrgFodlers } = useGetOrgFolders(false);
  const dispatch = useDispatch();
  return useMutation((payload) => {
    return axiosOrgInstance
      .delete(REPORTS.DELETE_REPORT.replace(":id", payload.id))
      .then((res) => {
        dispatch(refreshOpenFolderIds());
        toast.info("Report Deleted Successfully.");
        navigate(APP_ROUTES.DASHBOARD);
        refetchOrgFodlers();
        return res;
      })
      .catch((err) => toast.error("Please try again"));
  });
};

const useMakeShortUrl = () => {
  const { axiosUserInstance } = useAxios();
  return useMutation((inputs) => {
    return axiosUserInstance
      .post(REPORTS.SHORT_REPORT_URL, inputs)
      .then((res) => {
        navigator.clipboard.writeText(res?.shortLink);
        toast.success("Report url copied to clipboard");
        return res;
      })
      .catch((err) => toast.error("Failed to generate report url"));
  });
};

const useMakeReportPublic = () => {
  const { axiosOrgInstance } = useAxios();
  return useMutation((data) => {
    axiosOrgInstance
      .patch(
        `${REPORTS.MAKE_REPORT_PUBLIC.replace(":id", data?.id)}?visibility=${
          data?.visibility
        }`
      )
      .then((res) => {
        toast.success("Report visibility successfully changed");
        // getReports();
      })
      .catch((err) => {
        toast.error("Failed to update report");
      });
  });
};

const useDuplicateReport = () => {
  const { axiosOrgInstance } = useAxios();
  const query = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((payload) => {
    axiosOrgInstance
      .post(COMMON.DUPLICATE_REPORT_TO_FOLDER, payload)
      .then((res) => {
        toast("Report Copied Successfully");
        query.refetchQueries(["folders"]);
        dispatch(refreshOpenFolderIds());
        return res;
      })
      .catch((error) => {
        toast.error(error);
        console.log(error);
      });
  });
};

const useToggleReportVisibility = () => {
  const { axiosOrgInstance } = useAxios();
  return useMutation((data) => {
    axiosOrgInstance
      .patch(
        `${REPORTS.MAKE_REPORT_PUBLIC.replace(":id", data?.id)}?visibility=${
          data?.visibility
        }`
      )
      .then((res) => {
        toast("Report visibility successfully changed");
        return res;
      })
      .catch((err) => {
        toast.error("Failed to update report");
      });
  });
};

export {
  useGetReports,
  useBookmarkReport,
  useGetBookmarkReports,
  useRemoveBookmark,
  useMakeReportPublic,
  useMakeShortUrl,
  useDeleteReport,
  useDuplicateReport,
  useToggleReportVisibility,
};
