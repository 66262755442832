const { Stack, Typography } = require("@mui/material");

const RecommendationContainer = ({
  title,
  children,
  LeftTitle,
  RightTitle,
  sx,
}) => {
  return (
    <Stack
      direction="column"
      sx={
        sx || {
          border: "1px solid #E4E4E7",
          borderRadius: "4px",
          padding: "24px",
          gap: "16px",
          width: "100%",
        }
      }
    >
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Stack direction="row" spacing={1}>
          <Typography variant="h3" sx={{ color: "#1C1E20", fontWeight: "500" }}>
            {title}
          </Typography>
          {LeftTitle && <Stack direction="row">{LeftTitle}</Stack>}
        </Stack>
        {RightTitle && RightTitle}
      </Stack>
      {children}
    </Stack>
  );
};

export default RecommendationContainer;
