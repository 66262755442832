import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from "@/components/ui/dropdown-menu";
import { Tooltip } from "@mui/material";
import {
  Archive,
  ChevronDown,
  ChevronRight,
  Copy,
  Folder,
  Move,
  MoveDiagonal2,
  Pencil,
  Plus,
  Star,
  StarOff,
  Trash2,
} from "lucide-react";
import { twJoin } from "tailwind-merge";
import ReportItem from "./ReportItem";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { useDispatch } from "react-redux";

export const FolderItem = ({
  children,
  toggleFolder,
  openFolderIds,
  setFolderForEdit,
  folderIdForEdit,
  setSelectedFolderName,
  setOpenRenameInput,
  handleFolderAction,
  duplicateReport,
  dropdownRef,
  showLoader,
  checkIfActionAllowed,
  deleteReport,
  HandleChangeVisibility,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      {children &&
        children.length > 0 &&
        children.map((childFolder) => {
          const margin = 10 * childFolder?.level;
          return (
            <div key={childFolder?._id}>
              <div className="relative">
                <div
                  className="flex px-2 group py-2 items-center cursor-pointer text-[14px] w-full"
                  style={{
                    marginLeft: `${margin}px`,
                    borderLeft: "1px solid #e3e3e3",
                  }}
                >
                  <div
                    onClick={() =>
                      toggleFolder(
                        childFolder?._id,
                        childFolder?.level,
                        childFolder?.children
                      )
                    }
                    className={twJoin(
                      "rounded-md p-1",
                      openFolderIds.has(childFolder?._id)
                        ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
                        : "hover:bg-slate-300"
                    )}
                  >
                    {openFolderIds.has(childFolder?._id) ? (
                      <ChevronDown size={16} />
                    ) : (
                      <ChevronRight size={16} />
                    )}
                  </div>
                  <div className="flex text-xs items-center w-[80%]">
                    <div className="flex-grow overflow-hidden w-[60%] ml-2 text-gray-600 font-medium">
                      <Tooltip
                        placement="bottom"
                        arrow
                        title={
                          childFolder?.name?.length > 10
                            ? childFolder?.name
                            : ""
                        }
                      >
                        <p className="truncate w-full">{childFolder?.name}</p>
                      </Tooltip>
                    </div>
                    <div className="flex gap-1 ml-1 flex-shrink-0">
                      {childFolder?.isFavourite && (
                        <Star
                          color="orange"
                          className="w-4 h-4"
                          fill="orange"
                        />
                      )}
                      <DropdownMenu className="group">
                        <DropdownMenuTrigger asChild>
                          <img
                            width="16"
                            alt=""
                            src="/ellipsis.png"
                            className="invisible group-hover:visible"
                          />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-max">
                          <DropdownMenuGroup>
                            {checkIfActionAllowed(childFolder?.name) && (
                              <DropdownMenuItem
                                className="text-xs"
                                onClick={() => {
                                  setFolderForEdit(childFolder?._id);
                                  setSelectedFolderName(childFolder?.name);
                                  setOpenRenameInput(true);
                                }}
                              >
                                <Pencil className="mr-2 h-4 w-4" />
                                <span>Rename</span>
                              </DropdownMenuItem>
                            )}
                            <DropdownMenuSub>
                              <DropdownMenuSubTrigger className="text-xs">
                                <Plus className="mr-2 h-4 w-4" />
                                <span>Create</span>
                              </DropdownMenuSubTrigger>
                              <DropdownMenuPortal>
                                <DropdownMenuSubContent>
                                  <DropdownMenuItem
                                    className="text-xs"
                                    onClick={() => {
                                      handleFolderAction(
                                        "addNestedFolder",
                                        childFolder?._id
                                      );
                                    }}
                                  >
                                    <Folder className="mr-2 h-4 w-4" />
                                    <span>Folder</span>
                                  </DropdownMenuItem>
                                  <DropdownMenuItem
                                    className="text-xs"
                                    onClick={() => {
                                      dispatch(
                                        setModal({
                                          open: true,
                                          type: MODALTYPES.CREATE_REPORTS,
                                          payload: {},
                                        })
                                      );
                                      localStorage.setItem(
                                        "REPORT_FOLDER",
                                        JSON.stringify([
                                          {
                                            _id: childFolder?._id,
                                            name: childFolder?.name,
                                          },
                                        ])
                                      );
                                    }}
                                  >
                                    <Folder className="mr-2 h-4 w-4" />
                                    <span>Report</span>
                                  </DropdownMenuItem>
                                </DropdownMenuSubContent>
                              </DropdownMenuPortal>
                            </DropdownMenuSub>
                          </DropdownMenuGroup>
                          <DropdownMenuSeparator />
                          <DropdownMenuGroup>
                            <DropdownMenuItem
                              className="text-xs"
                              onClick={() =>
                                handleFolderAction(
                                  "favourite",
                                  childFolder?._id,
                                  childFolder?.isFavourite,
                                  childFolder?.parent
                                )
                              }
                            >
                              {childFolder?.isFavourite ? (
                                <>
                                  <StarOff className="mr-2 h-4 w-4" />
                                  <span>Remove From Favourite</span>
                                </>
                              ) : (
                                <>
                                  <Star className="mr-2 h-4 w-4" />
                                  <span>Add to Favourite</span>
                                </>
                              )}
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="text-xs"
                              onClick={() =>
                                handleFolderAction(
                                  "duplicate",
                                  childFolder?._id,
                                  "",
                                  childFolder?.parent
                                )
                              }
                            >
                              <Copy className="mr-2 h-4 w-4" />
                              <span>Duplicate Folder</span>
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="text-xs"
                              onClick={() =>
                                handleFolderAction(
                                  "moveFolder",
                                  childFolder?._id,
                                  "",
                                  childFolder?.parent
                                )
                              }
                            >
                              <Move className="mr-2 h-4 w-4" />
                              <span>Move To Another Folder</span>
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="text-xs"
                              onClick={() =>
                                handleFolderAction(
                                  "moveToRoot",
                                  childFolder?._id,
                                  "",
                                  childFolder?.parent
                                )
                              }
                            >
                              <MoveDiagonal2 className="mr-2 h-4 w-4" />
                              <span>Move To Root Folder</span>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            {!childFolder?.isArchived && (
                              <DropdownMenuItem
                                className="text-xs"
                                onClick={() =>
                                  handleFolderAction(
                                    "archive",
                                    childFolder?._id
                                  )
                                }
                              >
                                <Archive className="mr-2 h-4 w-4" />
                                <span>Archive</span>
                              </DropdownMenuItem>
                            )}
                            <DropdownMenuItem
                              className="text-xs focus:text-red-500 text-red-500"
                              onClick={() =>
                                handleFolderAction(
                                  "delete",
                                  childFolder?._id,
                                  "",
                                  childFolder?.parent
                                )
                              }
                            >
                              <Trash2 className="mr-2 h-4 w-4" />
                              <span>Delete</span>
                            </DropdownMenuItem>
                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                </div>
              </div>
              {openFolderIds.has(childFolder?._id) && (
                <>
                  <FolderItem
                    children={childFolder.children || []}
                    toggleFolder={toggleFolder}
                    openFolderIds={openFolderIds}
                    setFolderForEdit={setFolderForEdit}
                    folderIdForEdit={folderIdForEdit}
                    setSelectedFolderName={setSelectedFolderName}
                    setOpenRenameInput={setOpenRenameInput}
                    handleFolderAction={handleFolderAction}
                    duplicateReport={duplicateReport}
                    dropdownRef={dropdownRef}
                    showLoader={showLoader}
                    checkIfActionAllowed={checkIfActionAllowed}
                    deleteReport={deleteReport}
                  />
                  {childFolder?.reports?.length > 0 ? (
                    <ul className="text-xs px-4 ml-4">
                      {childFolder?.reports?.map((report) => (
                        <li key={report?.reportableId}>
                          <ReportItem
                            deleteReport={deleteReport}
                            folder={childFolder}
                            report={report}
                            toggleFolder={toggleFolder}
                            openFolderIds={openFolderIds}
                            handleReportMove={() => {}}
                            duplicateReport={duplicateReport}
                            HandleChangeVisibility={HandleChangeVisibility}
                          />
                        </li>
                      ))}
                    </ul>
                  ) : null}
                  {!childFolder.children?.length < 1 &&
                    childFolder?.reports?.length < 1 &&
                    showLoader && (
                      <div class="rounded-md p-4 max-w-sm w-[70%] mx-auto">
                        <div class="animate-pulse flex space-x-4">
                          <div class="flex-1 space-y-6 py-1">
                            <div class="space-y-3">
                              <div class="h-2 bg-slate-200 rounded"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {childFolder.children?.length < 1 &&
                    childFolder?.reports?.length < 1 &&
                    !showLoader && (
                      <div
                        className="py-1 text-gray-400 text-xs font-medium"
                        style={{
                          marginLeft: 12 * childFolder?.level,
                        }}
                      >
                        This folder is empty
                      </div>
                    )}
                </>
              )}
            </div>
          );
        })}
    </>
  );
};
