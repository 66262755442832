import React from "react";
import { Avatar, AvatarGroup, Grid, Stack, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import { ContentContainer } from "../../../components/layouts";

export default function ImageTab() {
  const groupAnalyticsList = [
    {
      title: "Group Analytics 1",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 2",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 3",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 4",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 5",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 6",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 7",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 8",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 9",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 10",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 11",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 12",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 13",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 14",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 15",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 16",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 17",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 18",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 19",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 20",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 21",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 22",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 23",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
    {
      title: "Group Analytics 24",
      totalCreative: "Total 18 Creative In This Group",
      lastEdit: "Last Edit 05/09/2022",
      impression: "34.5K",
      revenue: "34.5K",
      ctr: "34.5K",
      purchase: "34.5K",
      cpm: "34.5K",
      roas: "34.5K",
    },
  ];
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h3">Creative Group</Typography>
      <ContentContainer className={styles.analytics_card_wrap}>
        {groupAnalyticsList.map((item, index) => {
          return (
            <Grid item className={styles.analytics_card} key={index}>
              <Typography className={styles.analytics_card_title}>
                {item.title}
              </Typography>
              <AvatarGroup
                max={4}
                total={6}
                sx={{
                  justifyContent: "flex-end",

                  "& .MuiAvatar-root": {
                    marginLeft: "-15px",
                  },
                }}
                className={styles.analytics_card_avatar}
              >
                <Avatar
                  alt="Remy Sharp"
                  src="https://source.unsplash.com/random"
                />
                <Avatar
                  alt="Travis Howard"
                  src="https://source.unsplash.com/random"
                />
                <Avatar
                  alt="Cindy Baker"
                  src="https://source.unsplash.com/random"
                />
                <Avatar
                  alt="Agnes Walker"
                  src="https://source.unsplash.com/random"
                />
              </AvatarGroup>
              <Typography className={styles.analytics_card_subtitle}>
                {item.totalCreative}
              </Typography>
              <Typography className={styles.analytics_card_subtitle}>
                {item.lastEdit}
              </Typography>
              <div className={styles.analytics_card_info}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  className={styles.analytics_card_pair}
                >
                  <Typography className={styles.analytics_card_key}>
                    Impression
                  </Typography>
                  <Typography className={styles.analytics_card_value}>
                    {item.impression}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  className={styles.analytics_card_pair}
                >
                  <Typography className={styles.analytics_card_key}>
                    Revenue
                  </Typography>
                  <Typography className={styles.analytics_card_value}>
                    {item.revenue}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  className={styles.analytics_card_pair}
                >
                  <Typography className={styles.analytics_card_key}>
                    CTR
                  </Typography>
                  <Typography className={styles.analytics_card_value}>
                    {item.ctr}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  className={styles.analytics_card_pair}
                >
                  <Typography className={styles.analytics_card_key}>
                    No. of Purchase
                  </Typography>
                  <Typography className={styles.analytics_card_value}>
                    {item.purchase}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  className={styles.analytics_card_pair}
                >
                  <Typography className={styles.analytics_card_key}>
                    CPM
                  </Typography>
                  <Typography className={styles.analytics_card_value}>
                    {item.cpm}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  className={styles.analytics_card_pair}
                >
                  <Typography className={styles.analytics_card_key}>
                    ROAS
                  </Typography>
                  <Typography className={styles.analytics_card_value}>
                    {item.roas}
                  </Typography>
                </Stack>
              </div>
            </Grid>
          );
        })}
      </ContentContainer>
    </Stack>
  );
}
