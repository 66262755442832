import React from "react";

export default function BrandAssetIcon({ isActive }) {
  const color = isActive ? "#7167FB" : "#858383";
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.449 4.377a.85.85 0 0 0 0-1.206l-1.23-1.229.003.002L8.546.27a.96.96 0 0 0-1.344.008L.279 7.196a.946.946 0 0 0 0 1.343L7.2 15.454a.952.952 0 0 0 1.345 0l2.902-2.9a.851.851 0 0 0-.604-1.455.856.856 0 0 0-.604.25l-2.197 2.2a.227.227 0 0 1-.325 0L2.188 8.026a.227.227 0 0 1 0-.325l5.527-5.523.023-.02a.225.225 0 0 1 .302.02l2.201 2.199a.853.853 0 0 0 1.208 0Zm-5.41 3.526A1.866 1.866 0 0 0 7.91 9.77a1.87 1.87 0 0 0 1.868-1.867 1.866 1.866 0 0 0-1.869-1.867A1.87 1.87 0 0 0 6.04 7.903Zm9.426-.679-2.16-2.15a.855.855 0 0 0-1.208 1.208l1.46 1.457a.227.227 0 0 1 0 .326L12.117 9.5a.85.85 0 0 0 .604 1.455c.226 0 .444-.09.604-.25l2.141-2.139a.949.949 0 0 0-.002-1.343Z"
        fill={color}
      />
    </svg>
  );
}
