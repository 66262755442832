import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import { MODALTYPES, setModal } from "../../../redux/slices/modal.slices";
import { API_ROUTES } from "../../../routes";

export default function useTaggingGroupContent() {
  const dispatch = useDispatch();
  const [contentList, setContentList] = useState([]);
  const { groupId, groupEntity } = useParams();
  const [hasNextPage, setHasNextPage] = useState(false);
  const { isLoadMore } = useInfiniteScroll("content-container", hasNextPage);
  const [page, setPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const { axiosOrgInstance } = useAxios();
  const [isRemoving, setIsRemoving] = useState(false);
  const [metaData, setMetaData] = useState({});

  const location = useLocation();
  const { state: derivedGroupName } = location;

  function initContentList() {
    axiosOrgInstance
      .get(
        API_ROUTES.ONBOARDING.LIST_GROUP_ENTITY?.replace(":groupId", groupId)
      )
      .then((res) => {
        let data = res?.docs?.map((item) => {
          return {
            ...item,
            isSelected: false,
          };
        });
        setContentList(data);
        setHasNextPage(res?.hasNextPage);
        setPage(res?.page);
        setTotalDocs(res?.totalDocs);
        setMetaData(res?.meta);
        console.log(res);
      });
  }

  function onRemoving() {
    let payload = {
      groupEntityIdsToBeRemoved: contentList
        ?.filter((item) => item.isSelected)
        ?.map((item) => item._id),
    };

    axiosOrgInstance
      .patch(
        API_ROUTES.ONBOARDING.UPDATE_GROUP_ENTITY.replace(":groupId", groupId),
        payload
      )
      .then((res) => {
        //go back
        dispatch(
          setModal({
            type: MODALTYPES.SUCCESS,
            title: "Remove images successfully",
            content: `You have removed ${res?.removedCount} ${groupEntity} from ${derivedGroupName}`,
          })
        );
        initContentList();
        setIsRemoving(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function onSelectionChange(id) {
    if (contentList?.length === 1) {
      return;
    }
    setContentList((prev) => {
      return prev.map((item) => {
        if (item._id === id) {
          return {
            ...item,
            isSelected: !item.isSelected,
          };
        }
        return item;
      });
    });
  }

  function onCancelRemoving() {
    setIsRemoving(false);
    setContentList((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          isSelected: false,
        };
      });
    });
  }

  useEffect(() => {
    initContentList();
  }, []);

  useEffect(() => {
    if (isLoadMore && hasNextPage) {
      axiosOrgInstance
        .get(
          API_ROUTES.ONBOARDING.LIST_GROUP_ENTITY?.replace(":groupId", groupId),
          {
            params: {
              page: page + 1,
            },
          }
        )
        .then((res) => {
          setHasNextPage(res?.hasNextPage);
          setPage(res?.page);
          setContentList((prev) => [...prev, ...res?.docs]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadMore]);
  return {
    contentList,
    totalDocs,
    isRemoving,
    setIsRemoving,
    onRemoving,
    onCancelRemoving,
    onSelectionChange,
    metaData,
    groupEntity,
  };
}
