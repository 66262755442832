import React, { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { debounce } from "@mui/material/utils";
import { Chip, Paper, Tooltip } from "@mui/material";

export default function AutoComplete(props) {
  const {
    type = "text",
    isInsights = false,
    handleSearchTags,
    insightFilterType = "",
    extraFiltersSuggestions = [],
    placeholder = "",
    values = [],
    onTagUpdate,
    disabled,
    isSmartSuggestionLoading,
  } = props;
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState(extraFiltersSuggestions);

  const searchInsightsSuggestions = React.useMemo(
    () => debounce((query) => handleSearchTags(insightFilterType, query), 500),
    [handleSearchTags, insightFilterType]
  );

  useEffect(() => {
    if (isInsights) {
      searchInsightsSuggestions(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (isInsights) setOptions(extraFiltersSuggestions);
  }, [extraFiltersSuggestions, isInsights]);

  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={1}
      id="multiple-limit-tags"
      options={options}
      freeSolo
      disabled={disabled}
      loading={isSmartSuggestionLoading}
      getOptionLabel={(option) => option}
      value={values}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            type={type}
            label="Enter"
            placeholder={!values?.length ? placeholder : ""}
            inputProps={{
              ...params.inputProps,
              value: inputValue,
              style: { color: "black", fontSize: "0.7rem", height: "20.6px" },
            }}
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.8rem",
                color: "black",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              },
              "& label#multiple-limit-tags-label": {
                // top: "1px",
              },
              // height: "50px",
            }}
          />
        );
      }}
      PaperComponent={({ children }) => (
        <Paper style={{ color: "black", fontSize: "0.8rem", width: "full" }}>
          {children}
        </Paper>
      )}
      sx={{
        minWidth: "250px",
        maxWidth: "250px",
        "& .MuiAutocomplete-tag": {
          fontSize: "0.7rem",
          maxWidth: "140px",
          display: "flex",
          justifyContent: "flex-start",
          // overflow: "hidden",
          // textOverflow: "ellipsis",
        },
        "& .MuiAutocomplete-inputRoot": {
          display: "flex",
          flexWrap: "wrap",
          // justifyContent: "space-around",
          // maxHeight: "40px", // Set your desired fixed height here
          // overflow: "scroll",
        },
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip key={index} title={option} arrow placement="top">
            <Chip
              label={option}
              {...getTagProps({ index })}
              sx={{
                fontSize: "0.8rem",
                height: "24px",
                maxWidth: "200px",
                "& .MuiChip-label": {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
            />
          </Tooltip>
        ))
      }
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        onTagUpdate(newValue);
      }}
      includeInputInList
      filterSelectedOptions
    />
  );
}
