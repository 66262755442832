import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Button,
  Typography,
} from "@mui/material";

const columns = [
  {
    field: "creative",
    headerName: "Creative",
    width: 100,
  },

  {
    field: "roas",
    headerName: "ROAS",
    width: 100,
  },

  {
    field: "impression",
    headerName: "Impression",
    width: 100,
  },
];

function createData(creative, roas, impression) {
  return { creative, roas, impression };
}

const rows = [
  createData(
    "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    "2.4X",
    "122223.4k",
    "34"
  ),
  createData(
    "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    "2.4X",
    "122223.4k",
    "34"
  ),
  createData(
    "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    "2.4X",
    "122223.4k",
    "34"
  ),
  createData(
    "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    "2.4X",
    "122223.4k",
    "34"
  ),
  createData(
    "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    "2.4X",
    "122223.4k",
    "34"
  ),
  createData(
    "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    "2.4X",
    "122223.4k",
    "34"
  ),
];

function PerformanceTable() {
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", borderRadius: "0", height: 600 }}
    >
      <Table
        sx={{ minWidth: 650, height: "max-content" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "#FAFAFC",
              borderBottom: "1px solid #EAECF0",
            }}
          >
            {columns.map((column) => {
              return (
                <TableCell key={column.field} sx={{ width: column.width }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      color: "#1C1E20",
                    }}
                  >
                    {column.headerName}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell
                sx={{
                  borderRight: "1px solid #EAECF0",
                  borderLeft: "1px solid #EAECF0",
                }}
              >
                <img
                  src={row.creative}
                  style={{ width: "70px", height: "70px", objectFit: "cover" }}
                  alt="creative"
                />
              </TableCell>
              <TableCell sx={{ borderRight: "1px solid #EAECF0" }}>
                <Stack direction="row" alignItems="baseline">
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "400",
                      lineHeight: "26px",
                      color: "#1C1E20",
                    }}
                  >
                    {row.roas}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "400",
                      lineHeight: "15px",
                      color: "#34B53A",
                    }}
                  >
                    (24% Better)
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell sx={{ borderRight: "1px solid #EAECF0" }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "400",
                    lineHeight: "26px",
                    color: "#1C1E20",
                  }}
                >
                  {row.impression}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PerformanceTable;
