import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  registrationPending: true,
  registrationStep: 0,
  adAccounts: [],
};

const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setRegistrationStep: (state, action) => {
      state.registrationStep = action.payload;
    },
    setFacebookAdAccounts: (state, action) => {
      state.adAccounts = action.payload;
    },
    setRegistrationPending: (state, action) => {
      state.registrationPending = action.payload;
    },
  },
});

export const {
  setRegistrationStep,
  setFacebookAdAccounts,
  setRegistrationPending,
} = signupSlice.actions;

export default signupSlice.reducer;
