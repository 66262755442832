import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { ContentContainer } from "components/layouts";
import { ManualTextTaggingModal } from "components/modals";
import useTextTagging from "../useTextTagging";
import SyncIcon from "@mui/icons-material/Sync";
import { useSelector } from "react-redux";
import { MODALTYPES } from "redux/slices/modal.slices";
import { BackButton } from "components/common/atoms";
import styles from "./styles.module.scss";
import { APP_ROUTES } from "routes";

export default function ManualTagged() {
  const { totalDocs, initGroup, startTagging, groupsList } =
    useTextTagging(true);
  const { open, type } = useSelector((state) => state?.modal);
  return (
    <Stack direction="column" justifyContent="flexStart">
      <Stack>
        <BackButton
          to={APP_ROUTES.GROUPS_TAGS.default}
          btnText="Text Tagging"
          from={APP_ROUTES.GROUPS_TAGS.TAGGING}
        />
      </Stack>
      <div className={styles.image_tab__container}>
        <div className={styles.header}>
          <div className={styles.left}>
            <span className="text-sm">Total Groups: {totalDocs}</span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "rgba(28, 30, 32, 0.5)",
                cursor: "pointer",
              }}
              onClick={initGroup}
            >
              Refresh <SyncIcon sx={{ padding: "0.25rem" }} />
            </span>
          </div>
        </div>
        <ContentContainer
          className={styles.content}
          containerStyles={{
            maxHeight: "calc(100vh - 180px)",
            gap: "1.5rem",
            // display: "grid",
            // gridTemplateColumns: "repeat(auto-fill, minmax(230px, 1fr))",
          }}
        >
          {groupsList.map((text, index) => {
            return (
              <Stack
                direction="column"
                alignItems="center"
                sx={{
                  padding: "1.5em",
                  background: "#F2F3F5",
                  borderRadius: "8px",
                  cursor: "pointer",
                  height: "227px",
                  width: "227px",
                  gap: "8px",
                  position: "relative",
                }}
              >
                <Stack
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    height: "160px",
                    width: "200px",
                    minHeight: "160px",
                    minWidth: "200px",
                  }}
                >
                  <Typography
                    className="line-clamp-6"
                    sx={{
                      fontSize: "0.8rem",
                      // textAlign: "center",
                    }}
                    title={text?.groupName}
                  >
                    {text?.groupName}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="text"
                    sx={{
                      textTransform: "none",
                      fontSize: "0.8rem",
                      "&:hover": {
                        fontWeight: "700",
                        background: "none",
                      },
                    }}
                    onClick={() => startTagging(text)}
                  >
                    Update Tags
                  </Button>
                </Stack>
              </Stack>
            );
          })}
        </ContentContainer>

        {open && type === MODALTYPES.MANUAL_TEXT_TAGGING_MODAL && (
          <ManualTextTaggingModal postOnboarding={true} />
        )}
      </div>
    </Stack>
  );
}
