import { createSlice } from "@reduxjs/toolkit";

export const modalInitState = {
  open: false,
  type: null,
  payload: null,
};

export const MODALTYPES = {
  MEDIA_ASSET_PREVIEW: "MEDIA_ASSET_PREVIEW",
  PRESETS_MODAL: "PRESETS_MODAL",
  CREATE_PRESET_MODAL: "CREATE_PRESET_MODAL",
  CREATE_SOURCE_MODAL: "CREATE_SOURCE_MODAL",
  ADD_AD_ACCOUNTS: "ADD_AD_ACCOUNTS",
  SAVE_INSIGHTS_REPORT: "SAVE_INSIGHTS_REPORT",
  MOVE_REPORT_TO_FOLDER: "MOVE_REPORT_TO_FOLDER",
  AD_ACCOUNTS: "AD_ACCOUNTS",
  CREATE_REPORTS: "CREATE_REPORTS",
  CONTACT_SUPPORT: "CONTACT_SUPPORT",
  EDIT_ORGANISATION_DETAILS: "EDIT_ORGANISATION_DETAILS",
  USER_PROFILE: "USER_PROFILE",
  ADD_ITEMS: "ADD_ITEMS",
  DELETE_ITEMS: "DELETE_ITEMS",
  SUCCESS: "SUCCESS",
  BASIC_ACTION: {
    REMOVE_IMAGES: "REMOVE_IMAGES",
    ADD_MANUAL_TAGS: "ADD_MANUAL_TAGS",
    MERGE_GROUPS: "MERGE_GROUPS",
    ADD_SYS_GENERATED_TAGS: "ADD_SYS_GENERATED_TAGS",
    GROUP_IMAGE: "GROUP_IMAGE",
  },
  CUSTOM_MODAL: {
    MERGE_GROUP: "MERGE_GROUP",
    REMOVE_IMAGES: "REMOVE_IMAGES",
  },
  GROUP_IMAGES_MODAL: "MOVE_IMAGES_MODAL",
  IMAGE_TAGGING_MODAL: "IMAGE_TAGGING_MODAL",
  VIDEO_TAGGING_MODAL: "VIDEO_TAGGING_MODAL",
  TEXT_TAGGING_MODAL: "TEXT_TAGGING_MODAL",
  TYPEAHEAD_MODAL: "TYPEAHEAD_MODAL",
  MANUAL_TEXT_TAGGING_MODAL: "MANUAL_TEXT_TAGGING_MODAL",
  AUDIENCE_TAGGING_MODAL: "AUDIENCE_TAGGING_MODAL",
  REMOVE_AD_ACCOUNT_MODAL: "REMOVE_AD_ACCOUNT_MODAL",
  INVITE_USER_MODAL: "INVITE_USER_MODAL",
  REMOVE_USER_MODAL: "REMOVE_USER_MODAL",
  CREATE_NEW_GROUP_MODAL: "CREATE_NEW_GROUP_MODAL",
  UNGROUPED_CREATE_NEW_GROUP: "UNGROUPED_CREATE_NEW_GROUP",
  UNGROUPPED_CONFIRMATION_MODAL: "UNGROUPPED_CONFIRMATION_MODAL",
  MERGE_MULTIPLE_GROUPS_MODAL: "MERGE_MULTIPLE_GROUPS_MODAL",
  SAVE_REPORT_MODAL: "SAVE_REPORT_MODAL",
  PLOT_CHART_MODAL: "PLOT_CHART_MODAL",
  PREDICTION_REPORT_CARD_MODAL: "PREDICTION_REPORT_CARD_MODAL",
  GENERATION_CREATIVE_TYPE_MODAL: "GENERATION_CREATIVE_TYPE_MODAL",
  KEYWORD_AGGREGATION_LOGIC_MODAL: "KEYWORD_AGGREGATION_LOGIC_MODAL",
  CREATE_AUDIENCE_SEGMENT: "CREATE_AUDIENCE_SEGMENT",
  COMMON_ALERT: "COMMON_ALERT",
};

export const MODAL_ACTION_STATES = {
  PENDING: "PENDING",
  STARTED: "STARTED",
  FINISHED: "FINISHED",
};

export const modalSlice = createSlice({
  name: "modal",
  initialState: modalInitState,
  reducers: {
    setModal: (state, action) => {
      console.log(action, "<<<ACTION");
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setModal } = modalSlice.actions;
export default modalSlice.reducer;
