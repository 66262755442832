import React from "react";
import { PrivateContainer } from "../../../../components/layouts";
import { congratulation } from "../../../../assets/images/index";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setOnBoardingStep } from "../../../../redux/slices/organisation.slice";
import { useDispatch } from "react-redux";
import { APP_ROUTES } from "../../../../routes";

export default function CongratulationScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleNext() {
    dispatch(setOnBoardingStep(1));
    navigate(APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS);
  }

  return (
    <PrivateContainer>
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img src={congratulation} alt="" />
        <Typography
          variant="h1"
          sx={{ fontWeight: "700", textAlign: "center", marginTop: "70px" }}
        >
          Congratulation You Tagged All The Groups
        </Typography>
        <div style={{ marginTop: "40px" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
            }}
            onClick={handleNext}
          >
            Move To The Next
          </Button>
        </div>
      </div>
    </PrivateContainer>
  );
}
