import React from "react";
import "./module.css"; // Make sure to create this CSS file

const SystemUpdateMessage = () => {
  return (
    <div className="update-container">
      <div className="update-box">
        <img
          src="/logowhite.avif"
          alt="Storyteller Logo"
          className="update-logo"
        />
        <h1 className="update-title">🚧 We're Updating Our System 🚧</h1>
        <p className="update-message">
          To give you the best experience, we're currently updating our system.
          Please check back in 30 minutes.
        </p>
        <p className="update-blog">
          In the meantime, feel free to{" "}
          <a href="https://thestoryteller.tech/blog" className="update-link">
            read our blog
          </a>{" "}
          to learn more about our latest updates.
        </p>
      </div>
    </div>
  );
};

export default SystemUpdateMessage;
