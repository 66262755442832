import {
  Button,
  Dialog,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { modalInitState, setModal } from "redux/slices/modal.slices";

const ConfirmationModal = (props) => {
  const { modalType, primaryAction } = props;
  const { open, type, payload } = useSelector((state) => state?.modal);
  const { id = "", groupId = "", groupName = "" } = payload || {};
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  function handleClose() {
    dispatch(setModal(modalInitState));
  }
  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open && modalType === type}
    >
      <Stack
        sx={{
          padding: "30px",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{ padding: "15px" }}
        >
          <Typography variant="h2">{`Are You sure you want to move the asset into group ${groupName} having id ${groupId}`}</Typography>
        </Stack>
        <Stack
          direction={"row"}
          gap={2}
          sx={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "100px",
            }}
            onClick={() =>
              primaryAction({
                id,
                groupId,
                groupName,
              })
            }
          >
            Yes
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "100px",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ConfirmationModal;
