import { CalendarMonthOutlined } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import { CalendarDays } from "lucide-react";
import moment from "moment";
import { Button as ShadButton } from "@/components/ui/button";
import React, { useEffect } from "react";
import { DateRangePicker } from "components/common/molecules";
import { Separator } from "@/components/ui/separator";

export const DateFilterOptions = [
  { label: "Today", value: 1 },
  {
    label: "Last 7 days",
    value: 2,
  },
  {
    label: "This Month",
    value: 3,
  },
  {
    label: "Custom",
    value: 4,
  },
];
//value :   { selectionType: 4, startDate: todaysDate, endDate: todaysDate },
export default function DateFilter(props) {
  const { options, value, onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const popUpOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onChange({
      selectionType: selectedOption,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    });
  };

  const [startDate, setStartDate] = React.useState(
    new Date(value?.startDate?.value || value?.startDate) || null
  );
  const [endDate, setEndDate] = React.useState(
    new Date(value?.endDate?.value || value?.endDate) || null
  );
  const [selectedOption, setSelectedOption] = React.useState(
    value?.selectionType
  );

  function getSelectedOptionLabel(value) {
    const option = options.find((option) => `${option?.value}` === `${value}`);
    return option?.label;
  }

  function handleCheckBox(event) {
    const { value } = event.target;
    setSelectedOption(value);
  }

  useEffect(() => {
    if (value?.startDate && !startDate) {
      const date = new Date(value?.startDate);
      setStartDate(date);
    }
    if (value?.endDate && !endDate) {
      const date = new Date(value?.endDate);
      setEndDate(date);
    }
  }, [endDate, startDate, value]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <ShadButton
        variant="outline"
        onClick={handleClick}
        disabled={props.disabled}
      >
        <CalendarDays className="p-1" />
        {selectedOption === 4 &&
          `${
            !!startDate ? moment(startDate).format("YYYY-MM-DD") : "Start Date"
          } - ${!!endDate ? moment(endDate).format("YYYY-MM-DD") : "End Date"}`}
        {selectedOption !== 4 && getSelectedOptionLabel(selectedOption)}
        {!selectedOption && "Select Filter"}
      </ShadButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={popUpOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{ padding: "0 140px 0 16px" }}
        >
          <div>
            <div>
              <div>
                <div>
                  <p>Filtered By :- </p>
                </div>
                {options.map((option) => {
                  return (
                    // <FormControlLabel
                    //   control={
                    //     <Checkbox
                    //       checked={`${selectedOption}` === `${option?.value}`}
                    //     />
                    //   }
                    //   label={option?.label}
                    //   value={option?.value}
                    //   onChange={handleCheckBox}
                    //   key={option?.value}
                    // />
                    <div>
                      <Checkbox
                        checked={`${selectedOption}` === `${option?.value}`}
                        value={option?.value}
                        onChange={handleCheckBox}
                        key={option?.value}
                        id="terms"
                      />
                      <label
                        htmlFor="terms"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        {option?.label}
                      </label>
                    </div>
                  );
                })}
              </div>

              <Separator className="mt-2" />
              <div>
                {(selectedOption === 4 || selectedOption === "4") && (
                  <DateRangePicker
                    onStartChange={(value) => {
                      setStartDate(value);
                      setEndDate(moment().format("YYYY-MM-DD"));
                    }}
                    onEndChange={(value) => {
                      setEndDate(value);
                    }}
                    value={{
                      dateStart: startDate,
                      dateStop: endDate,
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-center -mt-5">
            <ShadButton variant="secondary" onClick={handleClose}>
              Save
            </ShadButton>
          </div>
        </Stack>
      </Menu>
    </Stack>
  );
}
