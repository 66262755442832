import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Loader2 } from "lucide-react";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";
import { toast } from "react-toastify";

const CreatePresetModal = (props) => {
  const { isMakingPreset, makePreset, presetKpis } = props;
  const [name, setName] = useState();

  const { modal } = useSelector((state) => state);

  const dispatch = useDispatch();

  function handleClose() {
    dispatch(setModal(modalInitState));
  }

  function handleCreate() {
    if (!presetKpis?.length) {
      toast("Something went wrong.", { toastId: "dsadadadads" });
    }
    const _kpis = presetKpis.map((_kpi) => _kpi.id);
    const payload = {
      name,
      kpis: _kpis,
    };
    makePreset(payload);
  }

  return (
    <Dialog
      open={modal?.open && modal?.type === MODALTYPES.CREATE_PRESET_MODAL}
      onOpenChange={handleClose}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create preset</DialogTitle>
          <DialogDescription>
            Create new preset and remove worrying about remembering preferred
            metrices
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>
            <Input
              id="name"
              className="col-span-3"
              onChange={(e) => setName(e?.target?.value)}
            />
          </div>
        </div>
        <DialogFooter>
          {isMakingPreset ? (
            <Button disabled>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Please wait
            </Button>
          ) : (
            <Button disabled={!name} type="submit" onClick={handleCreate}>
              Save changes
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreatePresetModal;
