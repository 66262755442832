import { useMutation, useQuery } from "@tanstack/react-query";
import { FOLDERS, REPORTS } from "./apiEndPoints";
import { toast } from "react-toastify";
import { useGetOrgFolders } from "./common";
import { useDispatch } from "react-redux";
import { modalInitState, setModal } from "redux/slices/modal.slices";
const { default: useAxios } = require("hooks/useAxios");

const useCreateFolders = (hideInput) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["createFolder"];
  const { refetch: refetchRootFolders } = useGetOrgFolders(false);

  return useMutation(
    (payload) => axiosOrgInstance.post(FOLDERS.CREATE_FOLDER, payload),
    {
      onSuccess: async (data, variables) => {
        hideInput();
        if (!variables?.parent) {
          refetchRootFolders();
        }
      },
      queryKey: queryKey,
    }
  );
};

const useUpdateFolders = (hideInput) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["updateFolder"];
  const dispatch = useDispatch();
  return useMutation(
    queryKey,
    (payload) => {
      return axiosOrgInstance.post(FOLDERS.RENAME_FOLDER, payload);
    },
    {
      onSuccess: async () => {
        hideInput();
        dispatch(setModal(modalInitState));
      },
      onError: () => {
        toast("Something went wrong");
      },
      queryKey: queryKey,
    }
  );
};

const useDeleteFolder = () => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["deleteFolder"];

  return useMutation(
    queryKey,
    (payload) => {
      return axiosOrgInstance.delete(
        FOLDERS.DELETE_FOLDER.replace(":folderId", payload.folderId)
      );
    },
    {
      onSuccess: async () => {},
      queryKey: queryKey,
    }
  );
};

const useGetFolderReports = () => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["getFolderReports"];

  return useQuery(
    queryKey,
    () =>
      axiosOrgInstance.get(FOLDERS.GET_ORGANIZATION_FOLDERS).then((res) => res),
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );
};

const useAddReportToFolder = (folderId, reportId) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["addReportToFolder"];

  return useMutation(
    queryKey,
    () => {
      return axiosOrgInstance
        .patch(
          FOLDERS.ADD_REPORTS_TO_FOLDER.replace(":folderId", folderId).replace(
            "reportId",
            reportId
          )
        )
        .then((res) => {
          toast("Report Added succesfully");
          return res;
        })
        .catch((err) => toast(err));
    },
    {
      queryKey: queryKey,
    }
  );
};

const useGetSpecificFolder = () => {
  const { axiosOrgInstance } = useAxios();
  return useMutation((folderId) => {
    return axiosOrgInstance
      .get(REPORTS.GET_FOLDER_HEIRACHY.replace(":folderId", folderId))
      .then((res) => res)
      .catch((err) => toast.error("Failed to fetch folder resources"));
  });
};

export {
  useCreateFolders,
  useGetFolderReports,
  useAddReportToFolder,
  useDeleteFolder,
  useUpdateFolders,
  useGetSpecificFolder,
};
