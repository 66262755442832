import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Table,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CableIcon from "@mui/icons-material/Cable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PrivateContainer } from "../../components/layouts";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES, APP_ROUTES } from "../../routes";
import { useParams, useSearchParams } from "react-router-dom";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { useDispatch } from "react-redux";
import AddAdAccountModal from "components/modals/AddAdAccountModal/AddAdAccountModal";
import { BackButton } from "components/common/atoms";
import CreateSourceModal from "components/modals/CreateSourceModal/CreateSourceModal";
import useUserRole from "hooks/useUserRole";
import { ROLES } from "config/roles";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { Button as ButtonAddSource } from "@/components/ui/button";
import RemoveAdAccountDialog from "./RemoveAdAccountDialog";

function PlatformConnectAccounts(props) {
  const { platformId } = useParams();
  const [searchParams] = useSearchParams();
  const isInternalPlatform = searchParams.get("internal") === "true";
  const isPagePosts = searchParams.get("pagePosts") === "true";
  const { userRole } = useUserRole();
  const { axiosOrgInstance } = useAxios();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState([]);
  const [oAuthSources, setOAuthSources] = useState([]);
  const [selectedOAuthSource, setSelectedOAuthSource] = useState("");
  const [selectedOAuthSourceName, setSelectedOAuthSourceName] = useState("");
  const [redirectUri, setRedirectUri] = useState("");
  const [selectedOAuthSourceAccounts, setSelectedOAuthSourceAccounts] =
    useState([]);
  const [listedOAuthSourceAccounts, setListedOAuthSourceAccounts] = useState(
    []
  );
  const [selectedAccountObj, setSelectedAccountObj] = useState(null);
  const [showRemoveAdAccountModal, setShowRemoveAdAccountModal] =
    useState(false);
  const [newAccountIdFields, setNewAccountIdFields] = useState([]);
  const init = () => {
    axiosOrgInstance
      .get(
        API_ROUTES.AUTH.GET_PLATFORM_OAUTH_SOURCES.replace(
          ":platformId",
          platformId
        )
      )
      .then((res) => {
        setOAuthSources(res);
        const defaultExpandedState = new Array(res.length).fill(false);
        setExpanded(defaultExpandedState);
        if (Array.isArray(res) && res.length > 0) {
          setSelectedOAuthSource(res[0]?._id);
          setSelectedOAuthSourceName(res[0]?.name || "Source 1");
          setSelectedOAuthSourceAccounts(res[0].accounts);
          setListedOAuthSourceAccounts(res[0]?.listedAccounts);
          setNewAccountIdFields(new Array(res.length).fill(""));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    init();
    const isCallback = searchParams.get("isCallback") === "true";

    if (isCallback) {
      const checkSuccess = searchParams.get("success") === "true";
      let message = searchParams.get("message");
      if (message !== "undefined") {
        try {
          message = message.split("_").join(" ");
        } catch (e) {
          message = message;
        }
      } else {
        message = null;
      }

      if (checkSuccess) {
        toast(message || "Account linking started successfully", {
          type: "success",
        });
      } else {
        toast(message || "Something went wrong", {
          type: "error",
        });
      }
    }
  }, []);

  const handleExpandClick = (index) => {
    const newExpandedState = [...expanded];
    newExpandedState[index] = !newExpandedState[index];
    setExpanded(newExpandedState);
  };

  const handleCreateNewSource = (sourceName) => {
    const payload = { name: sourceName };
    axiosOrgInstance
      .post(
        API_ROUTES.AUTH.CREATE_NEW_SOURCE.replace(":platformId", platformId),
        payload
      )
      .then((res) => {
        toast("New Source created successfully");
      })
      .catch((err) => toast("some error occurred"))
      .finally(() => {
        init();
        dispatch(
          setModal({
            type: MODALTYPES.CREATE_SOURCE_MODAL,
            open: false,
          })
        );
      });
  };

  const handleConnectBtn = async (source) => {
    if (!source?.authenticated) {
      const url = await axiosOrgInstance.post(
        API_ROUTES.AUTH.AUTHENTICATE_PLATFORM_OAUTH_SOURCES.replace(
          ":platformId",
          platformId
        ).replace(":oAuthSourceId", source?._id)
      );
      window.location.href = url;
    }
  };

  const handleListAssetsBtn = async (source) => {
    if (source?.listAssetsRedirectUrl) {
      window.location.href = source?.listAssetsRedirectUrl;
    }
  };

  const handleAddAccountBtn = async (index) => {
    if (newAccountIdFields[index]?.length > 2) {
      if (selectedOAuthSourceAccounts?.length > 0) {
        axiosOrgInstance
          .post(
            API_ROUTES.AUTH.ADD_NEW_AD_ACCOUNT.replace(
              ":platformId",
              platformId
            )
              .replace(":oAuthSourceId", selectedOAuthSource)
              .replace(":accountId", newAccountIdFields[index])
          )
          .then((res) => {
            toast("Ad-Account linking process started successfully");
          })
          .catch((err) => toast("Something went wrong"))
          .finally(() => {
            init();
            dispatch(
              setModal({
                type: MODALTYPES.ADD_AD_ACCOUNTS,
                open: false,
              })
            );
          });

        const newFields = [...newAccountIdFields];
        newFields[index] = "";
        setNewAccountIdFields(newFields);
      } else {
        const oldAccountSet = new Set(selectedOAuthSourceAccounts);
        oldAccountSet.add(newAccountIdFields[index]);
        handleSaveAccounts();
      }
    }
  };

  const handleDeleteAccount = (id) => {
    axiosOrgInstance
      .delete(
        API_ROUTES.AUTH.DELETE_ACCOUNT.replace(":platformId", platformId)
          .replace(":oAuthSourceId", selectedOAuthSource)
          .replace(":accountId", id)
      )
      .then((res) => {
        toast("Account removed successfully");
        init();
      })
      .catch((err) => toast("Something went wrong"));
  };

  const handleSaveAccounts = async () => {
    if (selectedOAuthSourceAccounts?.length !== 0) {
      try {
        const response = await axiosOrgInstance
          .post(
            API_ROUTES.AUTH.SAVE_PLATFORM_OAUTH_SOURCES_ACCOUNTS.replace(
              ":platformId",
              platformId
            ).replace(":oAuthSourceId", selectedOAuthSource?._id),
            {
              accountIds: selectedOAuthSourceAccounts,
            }
          )
          .finally(() => {
            init();
            dispatch(
              setModal({
                type: MODALTYPES.ADD_AD_ACCOUNTS,
                open: false,
              })
            );
          });
      } catch (error) {
        alert(error.message);
        console.log(error);
      }
    } else {
      alert("Please Add Accounts to connect");
    }
  };

  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "3px 8px",
    border: "1px solid",
    height: "40px",
    lineHeight: 1.5,
    backgroundColor: "#FEBE10",
    borderColor: "#FEBE10",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#FEBE10",
      borderColor: "#FEBE10",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#FEBE10",
      borderColor: "#FEBE10",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  });

  const _isInternalPlatformAndSuperAdmin = isInternalPlatform ? (isInternalPlatform && userRole === ROLES.SA) : true;

  return (
    <PrivateContainer
      containerStyles={{
        maxHeight: `calc(100vh - 70px)`,
        padding: "0 20px",
        gap: 0,
      }}
    >
      <Stack
        container
        direction={"column"}
        justifyContent="flex-start"
        minHeight="90vh"
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={2}
          sx={{ marginBottom: "30px" }}
        >
          <BackButton
            to={APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT}
            btnText="Manage Connections"
            from={APP_ROUTES.PLATFORMS.PLATFORM_CONNECT_ACCOUNTS}
          />
          <ButtonAddSource
            variant="secondary"
            sx={{
              textTransform: "none",
              marginLeft: "1rem",
              fontSize: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
            onClick={() => {
              dispatch(
                setModal({
                  type: MODALTYPES.CREATE_SOURCE_MODAL,
                  open: true,
                })
              );
            }}
          >
            <AddIcon className="mr-2 h-4 w-4" />
            <span>Add Source</span>
          </ButtonAddSource>
        </Stack>
        <Badge
          sx={{
            width: "100%",
          }}
          badgeContent={
            <div class="absolute inline-flex items-center justify-center w-8 h-8 text-xs font-bold bg-white border-2 border-white rounded-full -top-1 -start-2 dark:border-gray-900">
              <TipsAndUpdatesIcon />
            </div>
          }
        >
          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            justifyContent="start"
            gap={2}
            sx={{ marginBottom: "30px" }}
            borderRadius={"1rem"}
            padding={"1rem"}
            fontSize={"0.5rem"}
            backgroundColor="#75757520"
            boxShadow={"0px 4px 8px rgba(0, 0, 0, 0.1)"}
            width={"100%"}
          >
            <Typography
              display={"flex"}
              alignItems={"center"}
              fontSize={"0.75rem"}
            >
              <CheckCircleIcon color="success" /> : Authenticated
            </Typography>
            <Typography
              display={"flex"}
              alignItems={"center"}
              fontSize={"0.75rem"}
            >
              <CancelIcon color="error" /> : Not Authenticated
            </Typography>
            <Typography
              display={"flex"}
              alignItems={"center"}
              fontSize={"0.75rem"}
            >
              <AddIcon color="primary" /> : Add new{" "}
              {!isPagePosts ? "Ad-Account" : "Page Id"}
            </Typography>
            <Typography
              display={"flex"}
              alignItems={"center"}
              fontSize={"0.75rem"}
            >
              <CableIcon color="primary" /> : Link new{" "}
              {!isPagePosts ? "Ad-Account" : "Page Id"}
            </Typography>
          </Stack>
        </Badge>
        <Stack flexDirection={"column"} gap={2}>
          {oAuthSources.map((source, index) => (
            <Badge
              sx={{ width: "100%" }}
              badgeContent={
                <div class="absolute inline-flex items-center justify-center w-8 h-8 text-xs font-bold text-white bg-white border-2 border-white rounded-full -top-1 -start-2 dark:border-gray-900">
                  {source?.authenticated ? (
                    <IconButton
                      title="Authenticated"
                      aria-label="completed"
                      color="success"
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      title="Not Authenticated"
                      aria-label="completed"
                      color="error"
                    >
                      <CancelIcon />
                    </IconButton>
                  )}
                </div>
              }
            >
              <Stack
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  gap: "16px",
                  border: "1px solid #e2e2e2",

                  borderRadius: "8px",

                  width: "100%",
                  padding: "24px",
                  background: "white",
                }}
                onClick={() => {
                  setSelectedOAuthSource(source?._id);
                  setSelectedOAuthSourceName(
                    source?.name || `Source ${index + 1}`
                  );
                }}
              >
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  gap={4}
                  justifyContent={"space-between"}
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
                    <img
                      src="https://www.svgrepo.com/download/431792/meta.svg"
                      alt=""
                      style={{
                        height: "2rem",
                        width: "2rem",
                        objectFit: "contain",
                      }}
                    />
                    <Stack flexDirection={"column"} alignItems={"start"}>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        textAlign="center"
                      >
                        {source?.name || `Source ${index + 1}`}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {source?.name || `Source ${index + 1}`} have total{" "}
                        {source?.accounts?.length}{" "}
                        {!isPagePosts ? "Ad-Account(s)" : "Page Id(s)"}{" "}
                        connected
                      </Typography>
                    </Stack>
                  </Stack>
                  <Tooltip title="Add new Ad-Account" arrow>
                    <div
                      onClick={() => {
                        if (isInternalPlatform) {
                          window.location.replace(
                            source?.listAssetsRedirectUrl
                          );
                        } else {
                          setRedirectUri(source?.listAssetsRedirectUrl);
                          dispatch(
                            setModal({
                              type: MODALTYPES.ADD_AD_ACCOUNTS,
                              open: true,
                            })
                          );
                        }
                      }}
                    >
                      {(source?.authenticated || isInternalPlatform) &&
                        (isInternalPlatform ? (
                          <IconButton aria-label="completed" color="primary">
                            <CableIcon />
                          </IconButton>
                        ) : (
                          <IconButton aria-label="completed" color="primary">
                            <AddIcon />
                          </IconButton>
                        ))}
                    </div>
                  </Tooltip>
                </Stack>
                {_isInternalPlatformAndSuperAdmin && !source?.authenticated && (
                  <Stack
                    width={"100%"}
                    flexDirection={"column"}
                    alignItems={"start"}
                    paddingLeft={"2.65rem"}
                  >
                    <Button
                      variant="text"
                      sx={{
                        textTransform: "none",
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        "&:hover": {
                          background: "none",
                        },
                      }}
                      onClick={() => handleConnectBtn(source)}
                    >
                      Click here to Authenticate
                    </Button>
                  </Stack>
                )}
                {(isInternalPlatform ? true : source?.authenticated) && (
                  <Stack
                    width={"100%"}
                    flexDirection={"column"}
                    alignItems={"start"}
                    paddingLeft={"2.65rem"}
                  >
                    <Accordion
                      sx={{
                        width: "100%",
                        boxShadow: "none",
                        padding: "0",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                        sx={{
                          width: "100%",
                          boxShadow: "none",
                          padding: "0.5rem",
                          fontWeight: 500,
                          fontSize: "0.85rem",
                          borderRadius: "0px",
                          borderTop: "1px solid #e2e2e2",
                        }}
                      >
                        {isInternalPlatform
                          ? "Connect Pages"
                          : `List of ${
                              !isPagePosts ? "Ad-Account(s)" : "Page Id(s)"
                            }`}
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ width: "100%" }}
                        className="!px-0"
                      >
                        {source?.accountsStatus?.length ? (
                          <TableContainer>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Status</TableCell>
                                  <TableCell align="left">ID</TableCell>
                                  <TableCell align="left">Name</TableCell>
                                  <TableCell align="right">Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {source?.accountsStatus?.map(
                                  (accountObj, i) => {
                                    let statusChip;
                                    switch (accountObj.status) {
                                      case 1:
                                      case 2:
                                        statusChip = (
                                          <IconButton
                                            aria-label="completed"
                                            color="success"
                                          >
                                            <CheckCircleIcon />
                                          </IconButton>
                                        );
                                        break;
                                      case 0:
                                        statusChip = (
                                          <IconButton
                                            aria-label="completed"
                                            color="warning"
                                          >
                                            <ErrorOutlineIcon />
                                          </IconButton>
                                        );
                                        break;
                                      case -1:
                                        statusChip = (
                                          <IconButton
                                            aria-label="completed"
                                            color="error"
                                          >
                                            <CancelIcon />
                                          </IconButton>
                                        );
                                        break;
                                      default:
                                        break;
                                    }

                                    return (
                                      <TableRow
                                        key={accountObj.id}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {statusChip}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {accountObj.account}
                                        </TableCell>
                                        <TableCell align="left">
                                          {accountObj?.accountName || "-"}
                                        </TableCell>
                                        <TableCell align="right">
                                          <IconButton
                                            onClick={() => {
                                              setSelectedAccountObj(accountObj);
                                              setShowRemoveAdAccountModal(true);
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ) : (
                          <Typography
                            fontSize={"0.85rem"}
                            width={"100%"}
                            textAlign={"center"}
                          >
                            No accounts Connected.
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                )}
              </Stack>
            </Badge>
          ))}
        </Stack>
        <AddAdAccountModal
          source={selectedOAuthSource}
          sourceName={selectedOAuthSourceName}
          redirecturi={redirectUri}
          init={init}
          handleListAssetsBtn={handleListAssetsBtn}
          handleDeleteAccount={handleDeleteAccount}
        />
        <CreateSourceModal primaryAction={handleCreateNewSource} />
        <RemoveAdAccountDialog
          showModal={showRemoveAdAccountModal}
          selectedAccountObj={selectedAccountObj}
          confirmFn={() => handleDeleteAccount(selectedAccountObj.account)}
          closeFn={() => {
            setShowRemoveAdAccountModal(false);
            setSelectedAccountObj(null);
          }}
        />
      </Stack>
    </PrivateContainer>
  );
}

export default PlatformConnectAccounts;
