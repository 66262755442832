import React, { useEffect, useState } from "react";
import useAxios from "../../../../hooks/useAxios";
import useInfiniteScroll from "../../../../hooks/useInfiniteScroll";
import { API_ROUTES } from "../../../../routes";
import { GROUP_ENTITY } from "../../../../utils/constants";

export default function useAudienceTagging(isManuallyTagged) {
  const { axiosOrgInstance } = useAxios();
  const [groups, setGroups] = React.useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const { isLoadMore } = useInfiniteScroll("content-container", hasNextPage);

  function initGroups() {
    axiosOrgInstance
      .get(API_ROUTES.ONBOARDING.LIST_ORG_GROUPS, {
        params: {
          groupEntity: GROUP_ENTITY.CUSTOM_AUDIENCE,
          isManualGroupingDone: 1,
          isGroupManualTaggingDone: isManuallyTagged ? 1 : 0,
        },
      })
      .then((res) => {
        setGroups(res?.docs);
        setHasNextPage(res?.hasNextPage);
        setPage(res?.page);
        setTotalDocs(res?.totalDocs);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    initGroups();
  }, []);

  useEffect(() => {
    if (isLoadMore && hasNextPage) {
      axiosOrgInstance
        .get(API_ROUTES.ONBOARDING.LIST_ORG_GROUPS, {
          params: {
            groupEntity: GROUP_ENTITY.CUSTOM_AUDIENCE,
            isManualGroupingDone: 1,
            isGroupManualTaggingDone: isManuallyTagged ? 1 : 0,
            page: page + 1,
          },
        })
        .then((res) => {
          setHasNextPage(res?.hasNextPage);
          setPage(res?.page);
          setGroups((prev) => [...prev, ...res?.docs]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadMore]);
  return {
    groups,
    totalDocs,
    initGroups,
  };
}
