import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAxios from "../../../hooks/useAxios";
import {
  modalInitState,
  MODALTYPES,
  setModal,
} from "../../../redux/slices/modal.slices";
import { API_ROUTES, APP_ROUTES } from "../../../routes";
import {
  useGetPredictionMediaTags,
  useUpdatePredictionMediaTags,
} from "apis/predictionApi";
import useUserRole from "hooks/useUserRole";
import { ACTIVE_FEATURES } from "config/roles";
import { useGetOrganizationTags } from "apis/common";

const comboSwitches = [
  "textInImage",
  "discountShownInImage",
  "discountCodeShownInImage",
  "isProductImage",
];
export default function useImageTagging(
  payload,
  postOnboarding,
  predictionConfigs
) {
  const { axiosOrgInstance } = useAxios();
  const { hasUserActiveFeature } = useUserRole();
  const navigate = useNavigate();
  const { open, type } = useSelector((state) => state?.modal);
  const dispatch = useDispatch();
  const { refetch: refetchOrganizationTags } = useGetOrganizationTags(false);

  const [aiTagsList, setAiTagsList] = React.useState([]);
  const { data: predictionTags } = useGetPredictionMediaTags(predictionConfigs);
  const isAdvanceTaggingActive = hasUserActiveFeature(
    ACTIVE_FEATURES.ADVANCE_TAGGING
  );

  const { mutateAsync: updatePredictionMediaTags } =
    useUpdatePredictionMediaTags(predictionConfigs);
  const { reportableId, mediaId } = predictionConfigs || {};
  const [state, setState] = React.useState({
    combineTags: [],
    tags: [],
    resolution: [],
    colorPalette: [],
    visualThemes: [],
    objects: [],
    peopleDemographics: [],
    textAmount: [],
    textOverlayStyle: [],
    logoPresence: false,
    logoPlacement: [],
    productPresence: false,
    productName: [],
    brandIntegration: [],
    backgroundElements: [],
    vfx: [],
    layoutAndComposition: [],
    shotType: [],
    editingStyle: [],
    cameraMovement: [],
    lightingStyle: [],
    interactivity: false,
    platformSpecificFeatures: [],
    useOfSymbolsAndIcons: [],
    depthOfField: [],
    narrativeStyle: [],
    emotionalTone: [],
    isCtaAvailable: false,
    cta: [],
    ctaStyle: [],
    OfferPromotionPresence: false,
    offerPromotion: [],
    hookPresence: false,
    hook: [],
    targetAudienceTraits: [],
    culturalRelevance: [],
    contextualTagsByBusiness: [],

    // ------------------

    dimensions: [],
    sentiments: [],
    contentThemes: [],

    persons: [],
    textInImage: {
      exist: false,
      value: [],
    },
    discountShownInImage: {
      exist: false,
      value: "",
    },
    discountCodeShownInImage: {
      exist: false,
      value: "",
    },
    isProductImage: {
      exist: false,
      value: [],
    },
    aiFeaturesTags: [],
  });

  const [aiTagApplied, setAiTagApplied] = React.useState(false);

  let intervalId = useRef(null);
  function handleTagsChange(event) {
    const { name, value } = event;
    const _values = value.flatMap((curr) =>
      curr.split(",").map((tag) => tag.trim())
    );
    setState((prevState) => ({
      ...prevState,
      [name]: _values,
    }));
  }

  function handleSwitch(event) {
    const { name, checked } = event?.target;

    if (comboSwitches.includes(name)) {
      setState((prevState) => ({
        ...prevState,
        [name]: {
          exist: checked,
          value: prevState?.[name]?.value,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }
  }

  function handleInputChange(event) {
    let { name, value } = event?.target;
    if (comboSwitches.includes(name)) {
      setState((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }

  async function handlePrimaryAction(grpName) {
    const _payload = {
      tags: state.tags,
      combineTags: state?.combineTags,
      visualThemes: state?.visualThemes,
      resolution: state?.resolution,
      colorPalette: state?.colorPalette,
      objects: state?.objects,
      peopleDemographics: state?.peopleDemographics,
      textAmount: state?.textAmount,
      textOverlayStyle: state?.textOverlayStyle,
      logoPresence: state?.logoPresence,
      logoPlacement: state?.logoPlacement,
      productPresence: state?.productPresence,
      productName: state?.productName,
      brandIntegration: state?.brandIntegration,
      backgroundElements: state?.backgroundElements,
      vfx: state?.vfx,
      layoutAndComposition: state?.layoutAndComposition,
      shotType: state?.shotType,
      editingStyle: state?.editingStyle,
      cameraMovement: state?.cameraMovement,
      lightingStyle: state?.lightingStyle,
      interactivity: state?.interactivity,
      platformSpecificFeatures: state?.platformSpecificFeatures,
      useOfSymbolsAndIcons: state?.useOfSymbolsAndIcons,
      depthOfField: state?.depthOfField,
      narrativeStyle: state?.narrativeStyle,
      emotionalTone: state?.emotionalTone,
      isCtaAvailable: state?.isCtaAvailable,
      cta: state?.cta,
      ctaStyle: state?.ctaStyle,
      OfferPromotionPresence: state?.OfferPromotionPresence,
      offerPromotion: state?.offerPromotion,
      hookPresence: state?.hookPresence,
      hook: state?.hook,
      targetAudienceTraits: state?.targetAudienceTraits,
      culturalRelevance: state?.culturalRelevance,
      contextualTagsByBusiness: state?.contextualTagsByBusiness,

      // -----------------------------------

      creators: state?.creators,
      contentThemes: state?.contentThemes,
      discount: state?.discountShownInImage?.exist
        ? state?.discountShownInImage?.value
        : "",
      discountCode:
        state?.discountShownInImage?.exist &&
        state?.discountCodeShownInImage?.value !== ""
          ? state?.discountCodeShownInImage?.value
          : "",

      dimensions: state?.dimensions,
      products: state?.isProductImage?.exist
        ? state?.isProductImage?.value
        : [],

      persons: state?.persons,
      sentiments: state?.sentiments,
      texts: state?.textInImage?.exist ? state?.textInImage?.value : [],
      groupName: grpName ? grpName : payload?.groupName ?? "",
    };

    if (reportableId && mediaId) {
      updatePredictionMediaTags(_payload).then((res) => {
        dispatch(setModal(modalInitState));
      });
      return;
    }
    await axiosOrgInstance
      .post(
        API_ROUTES.TAGGING.CREATE_IMAGE_GROUP_TAGS?.replace(
          ":groupId",
          payload?.groupId
        ),
        _payload
      )
      .then((res) => {
        refetchOrganizationTags(true);
        toast.success(
          `Successful placements of tags in '${
            grpName ? grpName : payload?.groupName ?? ""
          }'`
        );
        dispatch(setModal(modalInitState));
        if (postOnboarding) {
          return;
        }
        navigate(APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS);
      });
  }

  function updateStateFromAITags(tags) {
    //add tags to existing state
    setState((prevState) => ({
      ...prevState,

      combineTags: [
        ...new Set([...prevState?.combineTags, ...(tags?.combineTags || [])]),
      ],
      tags: [...new Set([...prevState?.tags, ...(tags?.tags || [])])],
      resolution: [
        ...new Set([...prevState?.resolution, ...(tags?.resolution || [])]),
      ],
      colorPalette: [
        ...new Set([...prevState?.colorPalette, ...(tags?.colorPalette || [])]),
      ],
      visualThemes: [
        ...new Set([...prevState?.visualThemes, ...(tags?.visualThemes || [])]),
      ],
      objects: [...new Set([...prevState?.objects, ...(tags?.objects || [])])],
      peopleDemographics: [
        ...new Set([
          ...prevState?.peopleDemographics,
          ...(tags?.peopleDemographics || []),
        ]),
      ],
      textAmount: [
        ...new Set([...prevState?.textAmount, ...(tags?.textAmount || [])]),
      ],
      textOverlayStyle: [
        ...new Set([
          ...prevState?.textOverlayStyle,
          ...(tags?.textOverlayStyle || []),
        ]),
      ],
      logoPresence: tags?.logoPresence || false,

      logoPlacement: [
        ...new Set([
          ...prevState?.logoPlacement,
          ...(tags?.logoPlacement || []),
        ]),
      ],
      productPresence: tags?.productPresence || false,
      productName: [
        ...new Set([...prevState?.productName, ...(tags?.productName || [])]),
      ],
      brandIntegration: [
        ...new Set([
          ...prevState?.brandIntegration,
          ...(tags?.brandIntegration || []),
        ]),
      ],
      backgroundElements: [
        ...new Set([
          ...prevState?.backgroundElements,
          ...(tags?.backgroundElements || []),
        ]),
      ],
      vfx: [...new Set([...prevState?.vfx, ...(tags?.vfx || [])])],
      layoutAndComposition: [
        ...new Set([
          ...prevState?.layoutAndComposition,
          ...(tags?.layoutAndComposition || []),
        ]),
      ],
      shotType: [
        ...new Set([...prevState?.shotType, ...(tags?.shotType || [])]),
      ],

      editingStyle: [
        ...new Set([...prevState?.editingStyle, ...(tags?.editingStyle || [])]),
      ],
      cameraMovement: [
        ...new Set([
          ...prevState?.cameraMovement,
          ...(tags?.cameraMovement || []),
        ]),
      ],

      lightingStyle: [
        ...new Set([
          ...prevState?.lightingStyle,
          ...(tags?.lightingStyle || []),
        ]),
      ],

      interactivity: tags?.interactivity || false,
      platformSpecificFeatures: [
        ...new Set([
          ...prevState?.platformSpecificFeatures,
          ...(tags?.platformSpecificFeatures || []),
        ]),
      ],
      useOfSymbolsAndIcons: [
        ...new Set([
          ...prevState?.useOfSymbolsAndIcons,
          ...(tags?.useOfSymbolsAndIcons || []),
        ]),
      ],
      depthOfField: [
        ...new Set([...prevState?.depthOfField, ...(tags?.depthOfField || [])]),
      ],

      narrativeStyle: [
        ...new Set([
          ...prevState?.narrativeStyle,
          ...(tags?.narrativeStyle || []),
        ]),
      ],
      emotionalTone: [
        ...new Set([
          ...prevState?.emotionalTone,
          ...(tags?.emotionalTone || []),
        ]),
      ],
      isCtaAvailable: tags?.isCtaAvailable || false,
      cta: [...new Set([...prevState?.cta, ...(tags?.cta || [])])],
      ctaStyle: [
        ...new Set([...prevState?.ctaStyle, ...(tags?.ctaStyle || [])]),
      ],
      OfferPromotionPresence: tags?.OfferPromotionPresence || false,
      offerPromotion: [
        ...new Set([
          ...prevState?.offerPromotion,
          ...(tags?.offerPromotion || []),
        ]),
      ],
      hookPresence: tags?.hookPresence || false,
      hook: [...new Set([...prevState?.hook, ...(tags?.hook || [])])],
      targetAudienceTraits: [
        ...new Set([
          ...prevState?.targetAudienceTraits,
          ...(tags?.targetAudienceTraits || []),
        ]),
      ],
      culturalRelevance: [
        ...new Set([
          ...prevState?.culturalRelevance,
          ...(tags?.culturalRelevance || []),
        ]),
      ],
      contextualTagsByBusiness: [
        ...new Set([
          ...prevState?.contextualTagsByBusiness,
          ...(tags?.contextualTagsByBusiness || []),
        ]),
      ],
      // --------------------------------------------

      sentiments: [
        ...new Set([...prevState?.sentiments, ...(tags?.sentiments || [])]),
      ],
      contentThemes: [
        ...new Set([
          ...prevState?.contentThemes,
          ...(tags?.contentThemes || []),
        ]),
      ],

      persons: [...new Set([...prevState?.persons, ...(tags?.persons || [])])],
      textInImage: {
        ...prevState?.textInImage,
        exist: tags?.texts?.length > 0,
        value: [
          ...new Set([
            ...prevState?.textInImage?.value,
            ...(tags?.texts || []),
          ]),
        ],
      },
      discountShownInImage: {
        ...prevState?.discountShownInImage,
        exist: tags?.discount?.length > 0,
        value: tags?.discount || "",
      },

      discountCodeShownInImage: {
        ...prevState?.discountCodeShownInImage,
        exist: tags?.discountCode?.length > 0,
        value: tags?.discountCode || "",
      },

      isProductImage: {
        ...prevState?.products,
        exist: tags?.products?.length > 0,
        value: [
          ...new Set([
            ...prevState?.isProductImage?.value,
            ...(tags?.products || []),
          ]),
        ],
      },
    }));
    setAiTagApplied(true);
  }

  function getAiTagsList(tags) {
    delete tags["_id"];
    let allTags = Object.values(tags)?.flat();
    const res = [];
    for (const tag of allTags) {
      if (typeof tag === "string") {
        res.push(tag);
      }
    }
    setAiTagsList(res);
  }

  function fetchAiTags() {
    axiosOrgInstance
      .get(
        API_ROUTES.TAGGING.GET_IMAGE_AI_TAGS.replace(
          ":groupId",
          payload?.groupId
        )
      )
      .then((res) => {
        updateStateFromAITags(res);
        getAiTagsList(res);
      });
  }

  function getAITaggingStatus() {
    axiosOrgInstance
      .get(API_ROUTES.COMMON.APPLICATION_STATUS, {
        params: {
          groupId: payload?.groupId,
        },
      })
      .then((res) => {
        if (res?.isGroupAiTaggingDone) {
          clearInterval(intervalId?.current);
          fetchAiTags();
        }
      });
  }
  function getGroupTags(groupId) {
    axiosOrgInstance
      .get(
        API_ROUTES.GET_GROUP_TAGS.GET_IMAGE_GROUP_TAGS.replace(
          ":groupId",
          groupId
        )
      )
      .then((res) => {
        updateStateFromAITags(res);
      });
  }

  useEffect(() => {
    if (open && type === MODALTYPES.IMAGE_TAGGING_MODAL) {
      if (postOnboarding) {
        if (reportableId && mediaId) {
          if (predictionTags) {
            updateStateFromAITags(predictionTags?.tags);
          }
        } else {
          getGroupTags(payload?.groupId);
        }
        return;
      } else {
        getAITaggingStatus();
        intervalId.current = setInterval(() => {
          getAITaggingStatus();
        }, 5000);
      }
    }
  }, [open, type, predictionTags]);

  useEffect(() => {
    (() => {
      clearInterval(intervalId?.current);
    })();
  }, []);

  return {
    state,
    handleTagsChange,
    handleSwitch,
    handleInputChange,
    handlePrimaryAction,
    aiTagsList,
    aiTagApplied,
    isAdvanceTaggingActive,
  };
}
