import React from "react";
import { PillIcon } from "../../../../assets/icons/react";

export default function index(props) {
  const { currentStep, totalSteps } = props;

  return (
    <div style={{ display: "flex", gap: "8px", mt: "10px" }}>
      {Array.from({ length: totalSteps }, (_, index) => {
        const isCompletedOrActiveStep = index + 1 <= currentStep;
        return <PillIcon key={index} isActive={isCompletedOrActiveStep} />;
      })}
    </div>
  );
}
