import React from "react";
import styles from "./NotFound.module.css";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>404</h1>
          <img src={"/404.jpg"} alt="404 Error" className={styles.image} />
          <p className={styles.message}>
            Oops! The page you're looking for doesn't exist.
          </p>
          <Link className={styles.button} to={"/login"}>
            Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
