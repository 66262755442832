import React from "react";
import { BottomBarTabs } from "components/common/molecules";
import { PrivateContainer } from "components/layouts";
import { APP_ROUTES } from "routes";
import { BrandKit, MediaAssets } from "./Components";
export default function BrandAssetsScreen() {
  const tabs = [
    {
      name: "Brand Kit",
      component: <BrandKit />,
    },
    {
      name: "Media Assets",
      component: <MediaAssets />,
    },
  ];
  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
      }}
      activePage={APP_ROUTES.BRAND_ASSETS.default}
    >
      <BottomBarTabs tabs={tabs} />
    </PrivateContainer>
  );
}
