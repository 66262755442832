import { Button, Radio, Typography } from "@mui/material";
import { ContainerWithLabel } from "components/common/atoms";
import styles from "./styles.module.scss";
import { PillProgressIndicator } from "components/common/molecules";
import { PublicContainer } from "components/layouts";
import React, { useEffect } from "react";
import useAccountType from "./useAccountType";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { APP_ROUTES } from "routes";

const AccountType = () => {
  const { selectedRole, setSelectedRole, RolesOptions, handleSubmit } =
    useAccountType();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth?.userData);
  useEffect(() => {
    if (user?.accountType) {
      //redirect to username
      navigate(APP_ROUTES.AUTH.SET_USER_NAME);
    }
  }, []);
  return (
    <PublicContainer>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div className={styles.role_select_screen}>
          <PillProgressIndicator currentStep={2} totalSteps={3} />
          <Typography variant="h2">What Describes You Best?</Typography>
          <div className={styles.roles_options}>
            {RolesOptions.map((role) => {
              const isChecked = role.value === selectedRole;
              const roleClass = isChecked
                ? `${styles.role_option} ${styles.selected_role}`
                : styles.role_option;
              return (
                <div
                  className={roleClass}
                  key={role.id}
                  onClick={() => {
                    setSelectedRole(role?.value);
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Radio
                      checked={isChecked}
                      value={role.value}
                      name="radio-buttons"
                    />
                    <ContainerWithLabel
                      label={role.label}
                      selected={isChecked}
                    />
                  </div>
                </div>
              );
            })}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
        <div style={{ width: "50%" }}>
          {/* <Typography variant="h2">Here goes the education</Typography> */}
        </div>
      </div>
    </PublicContainer>
  );
};

export default AccountType;
