import useAppState from "hooks/useAppState";
import useAxios from "hooks/useAxios";
import useUser from "hooks/useUser";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setUserData } from "redux/slices/auth.slice";
import { API_ROUTES, APP_ROUTES } from "routes";

export default function useUserName() {
  const [uname, setUName] = useState();
  const { axiosUserInstance } = useAxios();
  const { updateAppState } = useAppState();
  const navigate = useNavigate();
  const { userId } = useUser();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  function handleSubmit() {
    axiosUserInstance
      .patch(API_ROUTES.USER.UPDATE_USER?.replace(":userId", userId), {
        userName: uname,
      })
      .then((res) => {
        dispatch(setUserData({ tokens: auth?.userData?.tokens, user: res }));
        updateAppState();
        navigate(APP_ROUTES.DASHBOARD);
      })
      .catch((err) => {
        toast(err);
      });
  }
  return { uname, setUName, handleSubmit };
}
