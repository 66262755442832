import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  AudienceTaggingModal,
  BasicActionsModal,
} from "../../../../components/modals";
import { MODALTYPES, setModal } from "../../../../redux/slices/modal.slices";
import useAudienceTagging from "../useAudienceTagging";
import { Edit } from "lucide-react";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";

export default function AITagged() {
  const dispatch = useDispatch();
  const [isManuallyTagged, setIsManuallyTagged] = useState(false);

  const {
    groups,
    totalDocs,
    initGroups,
    handleSkipUpdate,
    addSysGeneratedTagsModalPrimaryAction,
  } = useAudienceTagging(isManuallyTagged);

  const styleTable = { borderCollapse: "separate" };

  const styleTableRow = {
    fontSize: "1rem",
    cursor: "pointer",
    width: "100%",
  };

  const styleCellContent = {
    color: "rgba(28, 30, 32, 0.7)",
  };
  return (
    <Stack sx={{ gap: "20px" }}>
      <Stack>
        <div className="flex justify-between">
          <div>
            <Typography>Total: {totalDocs}</Typography>
          </div>

          <div className="flex space-x-2">
            <div className="flex items-center space-x-2">
              <Switch
                id="manually-tagged"
                checked={isManuallyTagged}
                onCheckedChange={() => {
                  setIsManuallyTagged(!isManuallyTagged);
                }}
              />
              <Label htmlFor="manually-tagged">Manually Tagged</Label>
            </div>
            <Button
              variant="outlined"
              sx={{
                width: "150px",
                textTransform: "none",
              }}
              onClick={handleSkipUpdate}
            >
              Skip Tagging
            </Button>
          </div>
        </div>
      </Stack>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: `calc(100vh - 0px)`,
        }}
        className="content-container"
      >
        <Table
          stickyHeader
          aria-label="custom pagination table"
          sx={styleTable}
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontSize: "1rem",
                  backgroundColor: "#eee",
                }}
                align="left"
              >
                S. No
              </TableCell>
              <TableCell
                style={{
                  fontSize: "1rem",
                  backgroundColor: "#eee",
                }}
                align="left"
              >
                Audience Name
              </TableCell>
              <TableCell
                style={{
                  fontSize: "1rem",
                  backgroundColor: "#eee",
                }}
                align="center"
              >
                Type
              </TableCell>
              <TableCell
                style={{
                  fontSize: "1rem",
                  backgroundColor: "#eee",
                }}
                align="center"
              >
                Size
              </TableCell>
              <TableCell
                style={{
                  fontSize: "1rem",
                  backgroundColor: "#eee",
                }}
                align="left"
              >
                Date Created
              </TableCell>
              <TableCell
                style={{
                  fontSize: "1rem",
                  backgroundColor: "#eee",
                }}
                align="left"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {groups.map((row, index) => (
              <TableRow
                sx={styleTableRow}
                onClick={() =>
                  dispatch(
                    setModal({
                      open: true,
                      type: MODALTYPES.AUDIENCE_TAGGING_MODAL,
                      payload: { row },
                    })
                  )
                }
              >
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle2">
                    {row?.groupEntityIds?.[0]?.name}
                  </Typography>
                  <Typography variant="caption">{row?.groupName}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      ...styleCellContent,
                      color: "#7167FB",
                    }}
                  >
                    {row?.groupEntityIds?.[0]?.subtype ?? "-"}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={styleCellContent}>
                    {row?.groupEntityIds?.[0]?.size ?? "-"}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography sx={styleCellContent}>
                    {moment(row?.groupEntityIds?.[0]?.timeCreated).format(
                      "DD/MM/YYYY"
                    )}
                  </Typography>
                  <Typography sx={styleCellContent}>
                    {moment(row?.groupEntityIds?.[0]?.timeCreated).format(
                      "HH:mm A"
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    ...styleCellContent,
                    color: "#7167FB",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    dispatch(
                      setModal({
                        open: true,
                        type: MODALTYPES.AUDIENCE_TAGGING_MODAL,
                        payload: { row },
                      })
                    )
                  }
                >
                  <Tooltip arrow title="Edit">
                    <Edit size={20} />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AudienceTaggingModal
        initGroups={initGroups}
        postOnboarding={isManuallyTagged}
      />
      <BasicActionsModal
        modalType={MODALTYPES.BASIC_ACTION.ADD_SYS_GENERATED_TAGS}
        primaryAction={addSysGeneratedTagsModalPrimaryAction}
      />
    </Stack>
  );
}
