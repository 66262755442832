import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from "recharts";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";

const TOOLTIP_MAPPING = {
  x: "Creative Momentum",
  y: "Spend",
};

const CustomTooltip = ({ active, originalData, currHover }) => {
  if (currHover !== null && originalData?.[currHover]) {
    return (
      <div className="ml-10 w-56 max-w-56 max-h-[300px] bg-white shadow-xl rounded-lg border border-gray-100">
        <img
          src={originalData?.[currHover]?.thumbnail}
          className="object-contain w-full rounded-t-lg h-[200px] max-h-[200px]"
        />
        <div className="flex flex-col my-1 gap-y-2 justify-between px-2">
          {Object.entries(originalData?.[currHover])?.map(([key, val], idx) => {
            if (key === "index" || key === "thumbnail" || key === "groupId")
              return null;
            return (
              <div
                key={idx}
                className="flex justify-between text-sm items-center"
              >
                <p>{TOOLTIP_MAPPING?.[key]}</p>
                <p
                  className={`${
                    val < 0 ? "bg-red-300" : "bg-gray-200"
                  } px-2 py-1 rounded-lg`}
                >
                  {val?.toFixed(2)}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};

const CustomScatterChart = (props) => {
  const { data, handleCreativeInsight } = props;
  const dispatch = useDispatch();
  const [currHover, setHover] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const { selectedOrganisation } = useSelector((state) => state.organisation);

  const normalizeValue = (value) => {
    return value <= 0 ? 0 : Math.log(value);
  };

  const processData = (data) => {
    const processedData = data.map((item, index) => ({
      x: item.creativeMomentumFatigue.creativeMomentum,
      y: item.creativeMomentumFatigue.spendInOrgCurrency,
      thumbnail: item.groupThumbnailUrl,
      groupId: item?.groupId,
      index,
    }));
    setOriginalData(processedData);
    const normalizedChartData = processedData.map((item) => ({
      ...item,
      y: normalizeValue(item.y),
    }));
    setChartData(normalizedChartData);
  };

  useEffect(() => {
    if (data && data.length) {
      processData(data);
    }
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
        <CartesianGrid />
        <XAxis
          type="number"
          dataKey="x"
          name="Creative Momentum"
          stroke="black"
          strokeWidth={2}
          domain={[-1.2, 1.2]}
        >
          <Label value="Fatigue Score" position="insideBottom" dy={20} />
        </XAxis>
        <YAxis
          type="number"
          dataKey="y"
          name="Daily Ad Spend"
          tickFormatter={(value) =>
            Math.exp(value).toFixed(2)?.toString() +
            selectedOrganisation?.defaultCurrency
          }
        >
          <Label
            value={`Daily Ad Spend (${selectedOrganisation?.defaultCurrency})`}
            angle={-90}
            position="insideLeft"
            dx={-20}
          />
        </YAxis>
        <Tooltip
          content={
            <CustomTooltip originalData={originalData} currHover={currHover} />
          }
        />
        <ReferenceLine x={0} stroke="black" strokeWidth={2} />
        <Scatter
          name="Data Points"
          data={chartData}
          shape={(props) => {
            const { cx, cy, payload } = props;
            const radius = 30;
            const borderColor = payload.x < 0 ? "red" : "green";
            return (
              <foreignObject
                onMouseEnter={() => setHover(payload?.index)}
                x={cx - radius / 2}
                y={cy - radius / 2}
                width={radius}
                height={radius}
                style={{
                  cursor: "pointer",
                  zIndex: payload.index === currHover ? 500 : "auto",
                }}
              >
                <div
                  style={{
                    width: radius,
                    height: radius,
                    borderRadius: "50%",
                    overflow: "hidden",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                    border: `2px solid ${borderColor}`,
                    backgroundColor: "black",
                  }}
                  onClick={() => {
                    dispatch(
                      setModal({
                        type: MODALTYPES.MEDIA_ASSET_PREVIEW,
                        open: true,
                        payload: {
                          groupId: chartData?.[currHover]?.groupId,
                          handleCreativeInsight: handleCreativeInsight,
                        },
                      })
                    );
                  }}
                >
                  <img
                    className="object-fit w-full"
                    src={payload.thumbnail}
                    alt=""
                  />
                </div>
              </foreignObject>
            );
          }}
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default CustomScatterChart;
