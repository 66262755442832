import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export default function FileUploadDnD(props) {
  const {
    id = "file-uploader",
    children,
    onUpload,
    uploaderStyles = {},
    multiple = false,
    extensions,
  } = props;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => Object.assign(file));
    // Do something with the files

    acceptedFiles.forEach((file, index) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);
    });

    onUpload(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    //images and videos
    accept: extensions ? extensions : undefined,
    multiple,
  });
  return (
    <div
      {...getRootProps()}
      style={{
        filter: isDragActive ? "brightness(0.8)" : "brightness(1)",
        background: isDragActive ? "#E5E5E5" : "#FFFFFF",
        border: "1px dashed #7167FB",
        borderRadius: "12px",
        width: "100%",
        ...uploaderStyles,
      }}
    >
      {children}

      <input {...getInputProps()} id={id} />
    </div>
  );
}
