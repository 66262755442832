import React from "react";
import { Typography, Stack } from "@mui/material";

const PredictionReportCard = (props) => {
  const { TypePill, ReadPill, isTruncate } = props;
  const truncateUtil = (text, textCharLimit) => {
    if (text?.length > textCharLimit) {
      const truncatedText = text?.slice(0, textCharLimit);
      return `${truncatedText}...`;
    }
    return text;
  };
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        border: "1px solid #A1B0CC",
        borderRadius: "12px",
        padding: "16px",
        minWidth: "260px",
        maxWidth: "260px",
      }}
    >
      <Stack
        sx={{
          height: "140px",
          borderRadius: "12px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={props.thumbnail}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "4px",
            objectFit: "cover",
          }}
          alt="thumbnail"
        />
        {TypePill}
      </Stack>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ fontSize: "12px", color: "#1C1E20" }}>
            Predicted CTR:
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              color: "#1C1E20",
              background:
                +props.prediction < 1
                  ? "lightpink"
                  : +props.prediction === 1
                  ? "lightgreen"
                  : "lightyellow",
              padding: "4px 8px",
              borderRadius: "4px",
            }}
          >
            {+props.prediction < 1 ? (
              <span style={{ color: "darkred", fontWeight: 600 }}>Looser</span>
            ) : +props.prediction === 1 ? (
              <span style={{ color: "darkgreen", fontWeight: 600 }}>
                Winner
              </span>
            ) : (
              <span style={{ color: "darkorange", fontWeight: 600 }}>
                Unexplored
              </span>
            )}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ fontSize: "12px", color: "#1C1E20" }}>
            Confidence:
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              color: "#1C1E20",
              background: "#F9F9F9",
              padding: "4px 8px",
              borderRadius: "4px",
            }}
          >
            {props.confidence}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{ background: "#F9F9F9", padding: "10px", borderRadius: "10px" }}
      >
        <Typography sx={{ fontSize: "12px", color: "#1C1E20" }}>
          <span style={{ color: "#7167FB" }}>Optimization Tips: </span>
          {isTruncate && truncateUtil(props?.statement, 80)}{" "}
          {isTruncate && props?.statement.length > 80 && ReadPill}
          {!isTruncate && props?.statement}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PredictionReportCard;
