import React, { useEffect } from "react";
import PrivateContainer from "components/layouts/PrivateContainer";

import { TitleBar } from "components/common/molecules";
import { APP_ROUTES } from "routes";
import usePrediction from "./usePrediction";
import {
  PredictionStep1,
  PredictionStep2,
  PredictionStep3,
} from "./PredictionSteps";
import { useNavigate } from "react-router-dom";
import SEO from "utils/SEO";

function Prediction() {
  const { predictionStep } = usePrediction();
  const navigate = useNavigate();
  useEffect(() => {
    if (predictionStep === 4) {
      navigate(APP_ROUTES.PREDICTION.default);
    }
  }, [navigate, predictionStep]);
  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
      }}
      activePage={APP_ROUTES.PREDICTION.default}
    >
      <SEO
        title="Prediction"
        description="AI powered creative predictions on your creatives to boost market reach"
        name="Prediction by Storyteller"
        type="Report"
      />
      <TitleBar title="Prediction" subtitle="Storyteller.Ai" />

      {predictionStep === 1 && <PredictionStep1 />}
      {predictionStep === 2 && <PredictionStep2 />}
      {predictionStep === 3 && <PredictionStep3 />}
    </PrivateContainer>
  );
}

export default Prediction;
