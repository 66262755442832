import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { FileUploadDnD } from "components/common/molecules";
import { Skeleton } from "@/components/ui/skeleton";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { useState } from "react";

const UserSettings = ({
  profileDetails,
  isLoading,
  setProfileDetails,
  updateBasicDetails,
  DeactivateAccount,
}) => {
  const [isDeactivate, setIsDeactivate] = useState("");

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <CardTitle className="text-xl">Account</CardTitle>
          <CardDescription>
            Make changes to your account here. Click save when you're done.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-1">
            <div className="flex gap-x-3 mb-2 justify-center">
              <FileUploadDnD
                uploaderStyles={{
                  border: "none",
                  width: "fit-content",
                }}
              >
                <Avatar className="w-20 h-20">
                  <AvatarImage
                    src={profileDetails?.profilePicture}
                    alt="user profile pic"
                  />
                </Avatar>
              </FileUploadDnD>
            </div>
            <div className="flex gap-x-3 mb-2 justify-center">
              <div id="email">{profileDetails?.email ?? ""}</div>
            </div>
          </div>

          <div className="space-y-1">
            <Label htmlFor="name">User Name</Label>
            {isLoading ? (
              <Skeleton />
            ) : (
              <Input
                id="name"
                defaultValue={profileDetails?.userName}
                onChange={(e) => {
                  setProfileDetails((prev) => ({
                    ...prev,
                    userName: e.target.value,
                  }));
                }}
              />
            )}
          </div>

          <div className="space-y-2 mt-4">
            <Label htmlFor="account-type">Account Type</Label>
            {isLoading ? (
              <Skeleton />
            ) : (
              <div className="flex items-center space-x-2">
                <Label htmlFor="airplane-mode">Brand Owner</Label>
                <Switch
                  id="airplane-mode"
                  checked={profileDetails?.accountType === "Agency"}
                  onChange={(e) => {
                    setProfileDetails((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                  onCheckedChange={(val) => {
                    setProfileDetails((prev) => ({
                      ...prev,
                      accountType: val ? "Agency" : "Brand Owner",
                    }));
                  }}
                />
                <Label htmlFor="airplane-mode">Agency</Label>
              </div>
            )}
          </div>
        </CardContent>
        <CardFooter>
          <Button variant="outline" onClick={updateBasicDetails}>
            Save
          </Button>
        </CardFooter>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="text-lg">Deactivate Account</CardTitle>
          <CardDescription className="text-xs">
            This Will Make Your Account Permanently Unusable, To Continue,
            Please Type "DEACTIVATE".
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-2">
          <div className="space-y-1">
            <Label htmlFor="current">Type Here</Label>
            <Input
              id="current"
              onChange={(e, v) => setIsDeactivate(e?.target?.value)}
            />
          </div>
        </CardContent>
        <CardFooter>
          <Button
            disabled={isDeactivate !== "DEACTIVATE"}
            onClick={() => DeactivateAccount()}
            variant="destructive"
          >
            Deactivate
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};
export default UserSettings;
