import { PillProgressIndicator } from "components/common/molecules";
import { PublicContainer } from "components/layouts";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useUserName from "./useUserName";
import { APP_ROUTES } from "routes";
import { Button } from "@mui/material";
// import { Button } from "@/components/ui/button";

const UserName = () => {
  const { handleSubmit, setUName, uname } = useUserName();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth?.userData);
  useEffect(() => {
    if (user?.userName) {
      //redirect to username
      navigate(APP_ROUTES.DASHBOARD);
    }
  }, []);
  return (
    <PublicContainer>
      {/* <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div className={styles.role_select_screen}>
          <PillProgressIndicator currentStep={3} totalSteps={3} />
          <Label>Username</Label>
          <Input
            onChange={(e) => setUName(e.target.value)}
            placeholder="name..."
          />
          <p>This is your public display name.</p>
          <Button onClick={handleSubmit}>Submit</Button>
        </div>
        <div style={{ width: "50%" }}>
          <Typography variant="h2">Here goes the education</Typography>
        </div>
      </div> */}
      <div className="flex items-start justify-start ml-36 absolute left-0">
        <PillProgressIndicator currentStep={1} totalSteps={2} />
      </div>
      <section class="relative w-full gap-x-1 flex lg:h-screen lg:items-center">
        <div class="w-[40%] px-4 py-12 sm:px-6 sm:py-16 lg:w-1/2 lg:px-8 lg:py-24">
          <div class="mx-auto max-w-lg text-center">
            <h1 class="text-2xl font-bold sm:text-3xl">
              This is what you are heading to! 👉🏿
            </h1>

            <p class="mt-4 text-gray-500">
              This will be your publically differentiable username on the
              platform. Choose what you like and suits your personality!
            </p>
            <form
              action="#"
              class="mb-0 mt-8 space-y-4 flex justify-center w-full"
            >
              <div className="w-full">
                <label for="email" class="sr-only">
                  Username
                </label>

                <div class="relative w-full">
                  <input
                    type="email"
                    class="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                    placeholder="Enter username"
                    onChange={(e) => setUName(e.target.value)}
                  />

                  <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="size-4 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </form>
            {/* <Button
              className={`${
                uname ? "bg-[#7167FB] text-white" : ""
              } mt-10 flex justify-center w-full max-w-md`}
              variant="secondary"
              onClick={handleSubmit}
            >
              Submit
            </Button> */}
            <Button
              variant="contained"
              disabled={!uname}
              onClick={handleSubmit}
              className="w-full"
              sx={{
                textTransform: "none",
                fontSize: "1rem",
                marginTop: "2.5rem",
              }}
            >
              Submit
            </Button>
          </div>
        </div>

        <div class="relative h-64 w-full sm:h-96 lg:h-full lg:w-[60%]">
          <img
            alt=""
            src="dashboard.png"
            className="h-full w-full object-contain"
          />
        </div>
      </section>
    </PublicContainer>
  );
};

export default UserName;
