import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";

import {
  appSlice,
  signupSlice,
  modalSlice,
  organisationSlice,
  authSlice,
  recomendationSlice,
  insightsSlice,
  appStateSlices,
  predictionSlice,
  compareSlice,
  insightFiltersSlice,
  compareFiltersSlice,
  compareDatesFiltersSlice,
  adBreakdownSlice,
  creativeInsightsSlice,
  folderSlice,
  creativemomentumslice,
} from "./slices";
import creativeMomentumSlice from "./slices/creativeMomentum.slice";

const persistConfig = {
  key: "root",
  storage,
  // insightFilters add in whitelist again
  whitelist: [
    "organisation",
    "auth",
    "appState",
    "compare",
    "adBreakdownFilters",
    "creativeInsightFilters",
  ],
  blacklist: ["signup", "modal", "app"],
};

const rootReducer = combineReducers({
  app: appSlice,
  signup: signupSlice,
  modal: modalSlice,
  organisation: organisationSlice,
  auth: authSlice,
  recommendation: recomendationSlice,
  insights: insightsSlice,
  appState: appStateSlices,
  prediction: predictionSlice,
  compare: compareSlice,
  insightFilters: insightFiltersSlice,
  compareFilters: compareFiltersSlice,
  compareDatesFilters: compareDatesFiltersSlice,
  adBreakdownFilters: adBreakdownSlice,
  creativeInsightFilters: creativeInsightsSlice,
  folderSlice: folderSlice,
  creativeMomentumFilters: creativeMomentumSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);
