import React, { useEffect } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

//JS docs
/**
 * @param {Object} props
 * @param {Array} props.tabs
 * @param {String} props.tabs.name
 * @param {JSX.Element} props.tabs.component
 * @param {Number} props.activeTabIndex
 * @param {Function} props.onTabChange
 * @returns {JSX.Element}
 * @constructor
 * @example
 * <TabNav tabs={[{name:"Tab 1", component:<Tab1/>}, {name:"Tab 2", component:<Tab2/>]} activeTabIndex={0} />
 */

export default function TabNav(props) {
  const {
    tabs = [],
    activeTabIndex = 0,
    disableInactiveTabs = false,
    onTabChange,
    disabledTabsIndex = [],
    buttonStyles = {},
  } = props;

  const [activeTab, setActiveTab] = React.useState(
    activeTabIndex > tabs?.length ? 0 : activeTabIndex
  );

  function handleTabChange(index) {
    setActiveTab(index);
    onTabChange && onTabChange(index);
  }

  useEffect(() => {
    setActiveTab(activeTabIndex);
  }, [activeTabIndex]);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <ButtonGroup disableElevation aria-label="Disabled elevation buttons">
        {tabs.map((tab, index) => (
          <Button
            key={index}
            onClick={() => handleTabChange(index)}
            variant={activeTab === index ? "contained" : "outlined"}
            sx={{ width: "250px", textTransform: "none", ...buttonStyles }}
            disabled={disableInactiveTabs && disabledTabsIndex?.includes(index)}
          >
            {tab.name}
          </Button>
        ))}
      </ButtonGroup>
      <div className="tab-nav__content">{tabs[activeTab]?.component}</div>
    </div>
  );
}
