import { debounce } from "@mui/material";
import useAxios from "hooks/useAxios";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { API_ROUTES } from "routes";

export default function useOrganizationTagSelector() {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const { tags } = useSelector((state) => state.organisation);
  const { axiosOrgInstance } = useAxios();
  const fetchOrganizaionTags = useCallback((query) => {
    axiosOrgInstance
      .get(API_ROUTES.TAGGING.GET_ORGANIZATION_TAGS, {
        params: {
          search: query,
        },
      })
      .then((res) => {
        let tags = res?.tags ?? [];
        setOptions(tags);
        return res?.tags;
      });
  }, []);

  const searchGlobalTags = React.useMemo(
    () => debounce((query) => fetchOrganizaionTags(query), 500),
    [fetchOrganizaionTags]
  );

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return {
    options,
    tags,
    open,
    setOpen,
    value,
    setValue,
    searchGlobalTags,
  };
}
