import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  modalInitState,
  MODALTYPES,
  setModal,
} from "../../../redux/slices/modal.slices";
import { AutoCompleteWithTags } from "../../common/molecules";

import ReactPlayer from "react-player";
import useVideoTagging from "./useVideoTagging";
import useReactPlayer from "./useReactPlayer";
import CustomModal from "../CustomModal";
import { Tags } from "../../common/atoms";
import { Close } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { get } from "lodash";
import { storageUrlToCdn } from "utils/helper";
import AdvanceTagging from "components/common/tagging";
import EditIcon from "@mui/icons-material/Edit";

const formFieldStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "100%",
};

const labelStyles = { fontSize: "16px", fontWeight: "400px", color: "#1C1E20" };
const radioGrayStyles = {
  backgroundColor: "#F3F3F4",
  borderRadius: "6px",
  paddingRight: "9px",
  pointerEvents: "none",
};

export default function VideoTaggingModal(props) {
  const { postOnboarding } = props;
  const modalPlayer = useRef();
  const { open, type, payload } = useSelector((state) => state?.modal);
  const { reportableId = "" } = useParams();
  const predictionConfigs = { reportableId, mediaId: payload?.mediaId };
  const isOnlylastsegmentActive = reportableId && payload?.mediaId;
  const [grpName, setGrpName] = useState();
  const [advanceTagging, setAdvanceTagging] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  const handleEditClick = () => {
    setIsEditing(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const handleOutsideClick = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const {
    state,
    handleTagsChange,
    handleSwitch,
    handleInputChange,
    segment,
    handleSkipSegment,
    handlePrimaryAction,
    segmentMapping,
    setSegment,
    TOTAL_SEGMENTS,
    aiTagApplied,
    handleWait,
    handleContinue,
    openWarningModal,
    isWarningModalOpen,
    aiTagsLists,
    isAdvanceTaggingActive,
  } = useVideoTagging(payload, postOnboarding, predictionConfigs);

  useEffect(() => {
    if (isAdvanceTaggingActive) {
      setAdvanceTagging(true);
    }
  }, [isAdvanceTaggingActive]);

  const { playing, handleProgress, onChangePlayerState, handleSeekMouseDown } =
    useReactPlayer(modalPlayer, segment);
  const {
    primaryBtnText = "Save",
    secondaryBtnText = null,
    dangerMode = false,
    videoUrl = "",
  } = payload || {};
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  function handleClose() {
    setGrpName(null);
    if (postOnboarding) {
      dispatch(setModal(modalInitState));
    }
  }

  function handleSave() {
    if (aiTagApplied) {
      handlePrimaryAction &&
        handlePrimaryAction(grpName).then(() => {
          handleClose();
        });
    } else {
      openWarningModal();
    }
  }

  const videoSegment = [
    "0-3 seconds",
    "First 25%",
    "Second 25%",
    "Third 25%",
    "Last 25%",
    "Full",
  ];

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={false}
      open={open && type === MODALTYPES.VIDEO_TAGGING_MODAL}
      aria-labelledby="responsive-dialog-title"
      onClose={handleClose}
    >
      <div
        style={{
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
          width: "100%",
          minWidth: "90vw",
          overflow: "hidden",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "50%",
            }}
          >
            {isEditing ? (
              <TextField
                inputRef={inputRef}
                defaultValue={payload?.groupName}
                onChange={(e) => setGrpName(e.target.value)}
                variant="standard"
                fullWidth
              />
            ) : (
              <Typography id="modal-title" variant="h2" className="truncate">
                {grpName ? grpName : payload?.groupName ?? ""}
              </Typography>
            )}
            {!isEditing && (
              <IconButton onClick={handleEditClick}>
                <EditIcon sx={{ height: "1.25rem", width: "1.25rem" }} />
              </IconButton>
            )}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="w-1/2 px-4"
          >
            <Stack direction="row" spacing={4} gap={12} alignItems="center">
              <div style={formFieldStyles}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Switch
                    name="advanceTaggingSwitch"
                    disabled={!isAdvanceTaggingActive}
                    value={advanceTagging}
                    checked={advanceTagging}
                    onChange={() => setAdvanceTagging(!advanceTagging)}
                  />
                  <Typography sx={{ fontSize: "1rem" }}>
                    Advance Tagging {advanceTagging ? "Enabled" : "Disabled"}
                  </Typography>
                </Stack>
              </div>
            </Stack>
            {postOnboarding && (
              <Close sx={{ cursor: "pointer" }} onClick={handleClose} />
            )}
          </Stack>
        </Stack>
        <div style={{ display: "flex", gap: "1rem", overflow: "hidden" }}>
          <div className="w-1/2 flex flex-col items-center justify-center gap-4 p-4">
            <div
              className="tagging-card"
              style={{
                width: "100%",
                padding: "16px",
                boxShadow: "0px 10px 10px 10px rgba(0, 0, 0, 0.12)",
                borderRadius: "12px",
                gap: "1rem",
              }}
            >
              <ReactPlayer
                url={storageUrlToCdn(get(state, `[0].groupVideoUrl`, videoUrl))}
                controls={true}
                ref={modalPlayer}
                className="react-player"
                playing={playing}
                onStart={onChangePlayerState}
                onPlay={onChangePlayerState}
                width="100%"
                onProgress={handleProgress}
                onSeek={handleSeekMouseDown}
              />
            </div>

            {!postOnboarding && (
              <>
                <Typography sx={{ fontSize: "1rem" }} marginTop={"16px"}>
                  AI Tags
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  flexWrap="wrap"
                  gap="4px"
                >
                  {aiTagsLists?.[segment]?.map((tag, index) => {
                    const modTag = {
                      id: index,
                      name: tag,
                    };
                    return (
                      <Tags
                        key={index}
                        tag={modTag}
                        sx={{
                          marginBottom: "4px!important",
                          maxWidth: "200px",
                        }}
                      />
                    );
                  })}
                  {!aiTagApplied && (
                    <Stack>
                      <Typography variant="h3" margin={"16px 0"}>
                        Preparing AI tags
                      </Typography>
                      <CircularProgress />
                    </Stack>
                  )}

                  {aiTagApplied && aiTagsLists?.[segment]?.length === 0 && (
                    <Typography variant="h3" margin={"16px 0"}>
                      No AI Tags Available for this segment
                    </Typography>
                  )}
                </Stack>
              </>
            )}
          </div>

          <div className="w-1/2 flex flex-col items-center gap-4 overflow-y-scroll p-4 pt-0">
            <div className="py-4 bg-white sticky top-0 z-[2] w-full">
              <div style={formFieldStyles} className="mb-2">
                {state?.[segment]?.segmentLength && (
                  <Typography variant="h3" marginTop={"16px"}>
                    {`Segment Length: ${state?.[segment]?.segmentLength} seconds`}
                  </Typography>
                )}
                <Stack direction="row" spacing={4} gap={12} alignItems="center">
                  <div style={formFieldStyles}>
                    <Stack
                      direction="row"
                      spacing={0.5}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography sx={{ fontSize: "1rem" }}>
                        Video Segment
                      </Typography>
                      <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                        {videoSegment[segment]}
                      </Typography>
                    </Stack>
                  </div>
                </Stack>
              </div>
            </div>

            {advanceTagging ? (
              <>
                <AdvanceTagging
                  state={state}
                  segment={segment}
                  handleTagsChange={handleTagsChange}
                  isImageModal={false}
                  formFieldStyles={formFieldStyles}
                  labelStyles={labelStyles}
                  handleSwitch={handleSwitch}
                />
                {/* <AutoCompleteWithTags
                  label="Sentiments"
                  placeholder="Ex: Happy, Sad, Angry"
                  values={
                    !!state?.[segment]?.sentiments
                      ? state?.[segment]?.sentiments
                      : undefined
                  }
                  onTagUpdate={(tags) =>
                    handleTagsChange({
                      name: "sentiments",
                      value: tags,
                    })
                  }
                />
                <AutoCompleteWithTags
                  label="Content Theme"
                  placeholder="Ex: Mountain Photography"
                  values={
                    !!state?.[segment]?.contentThemes
                      ? state?.[segment]?.contentThemes
                      : undefined
                  }
                  onTagUpdate={(tags) =>
                    handleTagsChange({ name: "contentThemes", value: tags })
                  }
                />
                <AutoCompleteWithTags
                  label="Persons"
                  placeholder="Name/Instagram Handle of the person in the Video (add multiple if more than one)"
                  values={
                    !!state?.[segment]?.persons
                      ? state?.[segment]?.persons
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({ name: "persons", value: tags })
                  }
                />
                <AutoCompleteWithTags
                  label="Objects"
                  placeholder="Ex: Car, Bike, etc"
                  values={
                    !!state?.[segment]?.objects
                      ? state?.[segment]?.objects
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({ name: "objects", value: tags })
                  }
                />
                <AutoCompleteWithTags
                  label="People Demographics"
                  values={
                    !!state?.[segment]?.peopleDemographics
                      ? state?.[segment]?.peopleDemographics
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({
                      name: "peopleDemographics",
                      value: tags,
                    })
                  }
                />
                <AutoCompleteWithTags
                  label="Text Amount"
                  values={
                    !!state?.[segment]?.textAmount
                      ? state?.[segment]?.textAmount
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({ name: "textAmount", value: tags })
                  }
                />
                <AutoCompleteWithTags
                  label="Text Overlay Style"
                  values={
                    !!state?.[segment]?.textOverlayStyle
                      ? state?.[segment]?.textOverlayStyle
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({ name: "textOverlayStyle", value: tags })
                  }
                />
                <AutoCompleteWithTags
                  label="Logo Placement"
                  values={
                    !!state?.[segment]?.logoPlacement
                      ? state?.[segment]?.logoPlacement
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({ name: "logoPlacement", value: tags })
                  }
                />
                <AutoCompleteWithTags
                  label="Contextual Tags by business"
                  values={
                    !!state?.[segment]?.contextualTagsByBusiness
                      ? state?.[segment]?.contextualTagsByBusiness
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({
                      name: "contextualTagsByBusiness",
                      value: tags,
                    })
                  }
                />
                <AutoCompleteWithTags
                  label="Target Audience Traits"
                  values={
                    !!state?.[segment]?.targetAudienceTraits
                      ? state?.[segment]?.targetAudienceTraits
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({
                      name: "targetAudienceTraits",
                      value: tags,
                    })
                  }
                />
                <AutoCompleteWithTags
                  label="VFX"
                  values={
                    !!state?.[segment]?.vfx ? state?.[segment]?.vfx : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({ name: "vfx", value: tags })
                  }
                  placeholder="Ex: Animation"
                />
                <AutoCompleteWithTags
                  label="Shot Type"
                  values={
                    !!state?.[segment]?.shotType
                      ? state?.[segment]?.shotType
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({ name: "shotType", value: tags })
                  }
                />
                <AutoCompleteWithTags
                  label="Shot Labels"
                  placeholder="Ex: Beach"
                  values={
                    !!state?.[segment]?.shotLabels
                      ? state?.[segment]?.shotLabels
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({ name: "shotLabels", value: tags })
                  }
                />
                <AutoCompleteWithTags
                  label="No. of Shots"
                  values={
                    !!state?.[segment]?.shotCounts
                      ? state?.[segment]?.shotCounts
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({ name: "shotCounts", value: tags })
                  }
                  disabled
                />
          
                <AutoCompleteWithTags
                  label="Color Grading"
                  placeholder="Ex: Monochrome"
                  values={
                    !!state?.[segment]?.shotLabels
                      ? state?.[segment]?.shotLabels
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({ name: "shotLabels", value: tags })
                  }
                />
                <AutoCompleteWithTags
                  label="Layout and Composition"
                  values={
                    !!state?.[segment]?.shotLabels
                      ? state?.[segment]?.shotLabels
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) => tags}
                  placeholder="Ex: Single Subject Centered"
                />
                <AutoCompleteWithTags
                  label="Background Elements"
                  placeholder="Ex: Urban Scene"
                  values={
                    !!state?.[segment]?.shotLabels
                      ? state?.[segment]?.shotLabels
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) => tags}
                />
                <AutoCompleteWithTags
                  label="Brand Integration"
                  values={
                    !!state?.[segment]?.shotLabels
                      ? state?.[segment]?.shotLabels
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) => tags}
                  placeholder="Ex: Foreground"
                />
                <AutoCompleteWithTags
                  label="Narrative Style"
                  placeholder="Ex: Problem-Solution"
                  values={
                    !!state?.[segment]?.shotLabels
                      ? state?.[segment]?.shotLabels
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) => tags}
                />
                <AutoCompleteWithTags
                  label="Emotional Tone"
                  placeholder="Ex: Inspirational"
                  values={
                    !!state?.[segment]?.shotLabels
                      ? state?.[segment]?.shotLabels
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) => tags}
                />
                <AutoCompleteWithTags
                  label="Call to Action (CTA)"
                  values={
                    !!state?.[segment]?.shotLabels
                      ? state?.[segment]?.shotLabels
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) => tags}
                />
                <AutoCompleteWithTags
                  label="Call to Action (CTA) Style"
                  placeholder="Ex: Direct CTA Button"
                  values={
                    !!state?.[segment]?.shotLabels
                      ? state?.[segment]?.shotLabels
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) => tags}
                />
                <AutoCompleteWithTags
                  label=" Offer/Promotion"
                  values={
                    !!state?.[segment]?.shotLabels
                      ? state?.[segment]?.shotLabels
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) => tags}
                />
                <AutoCompleteWithTags
                  label="Hook"
                  placeholder="Ex: Question"
                  values={
                    !!state?.[segment]?.shotLabels
                      ? state?.[segment]?.shotLabels
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) => tags}
                />
                <AutoCompleteWithTags
                  label="Target Audience Traits"
                  placeholder="Ex: Parents"
                  values={
                    !!state?.[segment]?.shotLabels
                      ? state?.[segment]?.shotLabels
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) => tags}
                />
                Objects
                <TextField
                  label="Short Detail"
                  placeholder="Short video description"
                  value={
                    !!state?.[segment]?.shortDetail
                      ? state?.[segment]?.shortDetail
                      : undefined
                  }
                  name="shortDetail"
                  onChange={handleInputChange}
                />
                <AutoCompleteWithTags
                  label="Speech Transcriptions"
                  placeholder="Video Content in Text"
                  values={
                    !!state?.[segment]?.speechTranscriptions
                      ? state?.[segment]?.speechTranscriptions
                      : undefined
                  }
                  handleTagsChange={handleTagsChange}
                  onTagUpdate={(tags) =>
                    handleTagsChange({
                      name: "speechTranscriptions",
                      value: tags,
                    })
                  }
                />
                <div style={formFieldStyles}>
                  <label style={labelStyles}>
                    Any Text On Video Please Mention Here
                  </label>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    flexWrap={"wrap"}
                  >
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>No</Typography>
                      <Switch
                        name="textInVideo"
                        value={state?.[segment]?.textInVideo?.exist}
                        checked={state?.[segment]?.textInVideo?.exist}
                        onChange={handleSwitch}
                      />
                      <Typography>Yes</Typography>
                    </Stack>

                    {!!state?.[segment]?.textInVideo?.exist && (
                      <AutoCompleteWithTags
                        placeholder="Ex: Basketball"
                        label="Texts In Video"
                        values={
                          !!state?.[segment]?.textInVideo?.value
                            ? state?.[segment]?.textInVideo?.value
                            : undefined
                        }
                        onTagUpdate={(tags) =>
                          // To have the reusability we are passing it inside the target key
                          handleInputChange({
                            target: { name: "textInVideo", value: tags },
                          })
                        }
                      />
                    )}
                  </Stack>
                </div>
                <div style={formFieldStyles}>
                  <label style={labelStyles}>Music/Audio Features</label>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    flexWrap={"wrap"}
                  >
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>No</Typography>
                      <Switch
                        name="voiceover"
                        value={state?.[segment]?.voiceover?.exist}
                        checked={state?.[segment]?.voiceover?.exist}
                        onChange={handleSwitch}
                      />
                      <Typography>Yes</Typography>
                    </Stack>

                    {!!state?.[segment]?.voiceover?.exist && (
                      <AutoCompleteWithTags
                        placeholder="Music (Name/Details)"
                        label="Music"
                        values={
                          !!state?.[segment]?.voiceover?.value
                            ? state?.[segment]?.voiceover?.value
                            : undefined
                        }
                        onTagUpdate={(tags) =>
                          // To have the reusability we are passing it inside the target key
                          handleInputChange({
                            target: { name: "voiceover", value: tags },
                          })
                        }
                      />
                    )}
                  </Stack>
                </div>
                <div style={formFieldStyles}>
                  <label style={labelStyles}>
                    Discount Shown In The Video Segment
                  </label>

                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography>No</Typography>
                    <Switch
                      name="discountShownInVideo"
                      value={state?.[segment]?.discountShownInVideo?.exist}
                      checked={state?.[segment]?.discountShownInVideo?.exist}
                      onChange={handleSwitch}
                    />
                    <Typography>Yes</Typography>
                  </Stack>
                  {!!state?.[segment]?.discountShownInVideo?.exist && (
                    <TextField
                      placeholder="Ex: 12.5"
                      label="Discounts"
                      value={state?.[segment]?.discountShownInVideo?.value}
                      onChange={(e) =>
                        // To have the reusability we are passing it inside the target key
                        handleInputChange({
                          target: {
                            name: "discountShownInVideo",
                            value: e.target.value,
                          },
                        })
                      }
                    />
                  )}
                </div>
                {!!state?.[segment]?.discountShownInVideo?.exist &&
                  state?.[segment]?.discountShownInVideo?.value !== "" && (
                    <div style={formFieldStyles}>
                      <label style={labelStyles}>
                        Discount Code Shown In Video Segment
                      </label>
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        flexWrap={"wrap"}
                      >
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography>No</Typography>
                          <Switch
                            name="discountCodeShownInVideo"
                            value={
                              state?.[segment]?.discountCodeShownInVideo?.exist
                            }
                            checked={
                              state?.[segment]?.discountCodeShownInVideo?.exist
                            }
                            onChange={handleSwitch}
                          />
                          <Typography>Yes</Typography>
                        </Stack>
                      </Stack>

                      {!!state?.[segment]?.discountCodeShownInVideo?.exist && (
                        <TextField
                          placeholder="Ex: WELCOME20"
                          label="Discount Code"
                          value={
                            state?.[segment]?.discountCodeShownInVideo?.value
                          }
                          onChange={(e) =>
                            // To have the reusability we are passing it inside the target key
                            handleInputChange({
                              target: {
                                name: "discountCodeShownInVideo",
                                value: e.target.value,
                              },
                            })
                          }
                        />
                      )}
                    </div>
                  )}
                <div style={formFieldStyles}>
                  <label style={labelStyles}>Product In Video</label>

                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography>No</Typography>
                    <Switch
                      name="products"
                      value={state?.[segment]?.products?.exist}
                      checked={state?.[segment]?.products?.exist}
                      onChange={handleSwitch}
                    />
                    <Typography>Yes</Typography>
                  </Stack>

                  {!!state?.[segment]?.products?.exist && (
                    <AutoCompleteWithTags
                      placeholder="Ex: Storyteller"
                      label="Product Name"
                      values={
                        !!state?.[segment]?.products?.value
                          ? state?.[segment]?.products?.value
                          : undefined
                      }
                      onTagUpdate={(tags) =>
                        // To have the reusability we are passing it inside the target key
                        handleInputChange({
                          target: { name: "products", value: tags },
                        })
                      }
                    />
                  )}
                </div>
                <Stack direction="row" spacing={4} gap={12} alignItems="center">
                  <div style={formFieldStyles}>
                    <label style={labelStyles}>Product Presence</label>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>No</Typography>
                      <Switch
                        name="isCtaAvailable"
                        value={state?.[segment]?.isCtaAvailable}
                        checked={state?.[segment]?.isCtaAvailable}
                        onChange={handleSwitch}
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  </div>
                </Stack>
                <Stack direction="row" spacing={4} gap={12} alignItems="center">
                  <div style={formFieldStyles}>
                    <label style={labelStyles}>CTA Available On Image</label>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>No</Typography>
                      <Switch
                        name="isCtaAvailable"
                        value={state?.[segment]?.isCtaAvailable}
                        checked={state?.[segment]?.isCtaAvailable}
                        onChange={handleSwitch}
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  </div>
                </Stack>
                <Stack direction="row" spacing={4} gap={12} alignItems="center">
                  <div style={formFieldStyles}>
                    <label style={labelStyles}>
                      Call to Action (CTA) presence
                    </label>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>No</Typography>
                      <Switch
                        name="isCtaAvailable"
                        value={state?.[segment]?.isCtaAvailable}
                        checked={state?.[segment]?.isCtaAvailable}
                        onChange={handleSwitch}
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  </div>
                </Stack>
                <Stack direction="row" spacing={4} gap={12} alignItems="center">
                  <div style={formFieldStyles}>
                    <label style={labelStyles}>Offer/Promotion presence</label>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>No</Typography>
                      <Switch
                        name="isCtaAvailable"
                        value={state?.[segment]?.isCtaAvailable}
                        checked={state?.[segment]?.isCtaAvailable}
                        onChange={handleSwitch}
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  </div>
                </Stack>
                <Stack direction="row" spacing={4} gap={12} alignItems="center">
                  <div style={formFieldStyles}>
                    <label style={labelStyles}>Logo Presence</label>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>No</Typography>
                      <Switch
                        name="isCtaAvailable"
                        value={state?.[segment]?.isCtaAvailable}
                        checked={state?.[segment]?.isCtaAvailable}
                        onChange={handleSwitch}
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  </div>
                </Stack>
                <Stack direction="row" spacing={4} gap={12} alignItems="center">
                  <div style={formFieldStyles}>
                    <label style={labelStyles}>Hook presence</label>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>No</Typography>
                      <Switch
                        name="isCtaAvailable"
                        value={state?.[segment]?.isCtaAvailable}
                        checked={state?.[segment]?.isCtaAvailable}
                        onChange={handleSwitch}
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  </div>
                </Stack> */}
              </>
            ) : (
              <>
                <AutoCompleteWithTags
                  label="Tags"
                  placeholder="Ex: Eyewear, Cool, Sky"
                  values={
                    !!state?.[segment]?.labels
                      ? state?.[segment]?.labels
                      : undefined
                  }
                  onTagUpdate={(tags) =>
                    handleTagsChange({ name: "combineTags", value: tags })
                  }
                />
              </>
            )}
          </div>
        </div>

        <DialogActions
          style={{
            display: "flex",
            padding: "16px 24px 0 16px",
            gap: "1rem",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="py-4 w-1/2">
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "1rem",
                color: "grey",
              }}
            >
              (Kindly press ENTER/RETURN after entering multiple comma separated
              or individual tag to make them selected)
            </Typography>
          </div>
          <Stack flexDirection={"row"} gap={"1rem"}>
            {!!secondaryBtnText && (
              <Button
                sx={{
                  textTransform: "capitalize",
                  fontWeight: "500",
                }}
                variant="text"
                color={dangerMode ? "error" : "primary"}
                autoFocus
                onClick={handleClose}
              >
                {secondaryBtnText}
              </Button>
            )}
            {segment > 0 && !isOnlylastsegmentActive && (
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: "400",
                }}
                color={"error"}
                onClick={() => setSegment(segment - 1)}
              >
                {`Back to ${segmentMapping[segment - 1]}`}
              </Button>
            )}
            {segment + 1 === TOTAL_SEGMENTS ? (
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  textTransform: "capitalize",
                  fontWeight: "400",
                }}
                color={dangerMode ? "error" : "primary"}
                onClick={handleSave}
              >
                {primaryBtnText}
              </Button>
            ) : (
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: "400",
                }}
                onClick={handleSkipSegment}
              >
                {`Save ${segmentMapping[segment]}`}
              </Button>
            )}
          </Stack>
        </DialogActions>
      </div>

      <CustomModal open={isWarningModalOpen}>
        <div
          style={{
            padding: "32px",
            display: "flex",
            flexDirection: "column",
            gap: "40px",
          }}
        >
          <div style={{ display: "flex", gap: "5%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexBasis: " 65%",
                flexGrow: "1",
                flexShrink: "1",
                gap: "12px",
                justifyContent: "center",
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: "400" }}>
                AI Tags are not yet fetched for this group
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontSize: "16px", fontWeight: "700", color: "#1C1E20" }}
              >
                Are you sure you want to proceed without checking the AI
                generated Tags?
              </Typography>
            </div>
          </div>

          <DialogActions
            style={{
              display: "flex",
              padding: "16px 24px 0 16px",
              gap: "40px",
            }}
          >
            <Button
              sx={{
                textTransform: "capitalize",
                fontWeight: "500",
              }}
              variant="outlined"
              color={"primary"}
              autoFocus
              onClick={handleWait}
            >
              Wait for the AI Tags
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                textTransform: "capitalize",
                fontWeight: "400",
              }}
              color={"error"}
              onClick={() => {
                handleContinue();
                handleClose();
                dispatch(setModal(modalInitState));
              }}
              autoFocus
            >
              Continue
            </Button>
          </DialogActions>
        </div>
      </CustomModal>
    </Dialog>
  );
}
