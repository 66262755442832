import { useSelector } from "react-redux";
import useAuth from "./useAuth";
import { useSearchParams } from "react-router-dom";

export default function useReports() {
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const [searchParams] = useSearchParams();

  var currentReportOrgId = searchParams.get("orgId");

  const { isUserAuthenticated, isOrganisationAuthenticated } = useAuth();

  const isUserAndOrgAuthenticated =
    isUserAuthenticated && isOrganisationAuthenticated;

  let isUserPartOfReportOrg = true;
  if (selectedOrganisation?._id !== currentReportOrgId && currentReportOrgId) {
    isUserPartOfReportOrg = false;
  }

  let hideSidebar = false;
  let disableFilters = false;
  if (isUserAuthenticated && !isUserPartOfReportOrg) {
    disableFilters = true;
  }
  if (!isUserAuthenticated) {
    disableFilters = true;
    hideSidebar = true;
  }
  return { hideSidebar, disableFilters, currentReportOrgId };
}
