import {
  Dialog,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { AutoFixHigh } from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";
import { Add } from "@mui/icons-material";
import useAxios from "hooks/useAxios";
import { API_ROUTES } from "routes";
import { toast } from "react-toastify";

const AddAdAccountModal = (props) => {
  const {
    source,
    sourceName,
    init,
    handleListAssetsBtn,
    handleDeleteAccount,
    redirecturi,
  } = props;
  const { platformId } = useParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  function handleClose() {
    dispatch(setModal(modalInitState));
  }
  const { axiosOrgInstance } = useAxios();
  const [accountId, setAccountId] = useState("");

  const handleAddAccountBtn = async (index) => {
    if (accountId?.length > 2) {
      axiosOrgInstance
        .post(
          API_ROUTES.AUTH.ADD_NEW_AD_ACCOUNT.replace(":platformId", platformId)
            .replace(":oAuthSourceId", source)
            .replace(":accountId", accountId)
        )
        .then((res) => {
          toast("Ad-Account linking process started successfully");
        })
        .catch((err) => toast("Something went wrong"))
        .finally(() => {
          init();
          dispatch(
            setModal({
              type: MODALTYPES.ADD_AD_ACCOUNTS,
              open: false,
            })
          );
        });

      setAccountId("");
    }
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={modal?.open && modal?.type === MODALTYPES.ADD_AD_ACCOUNTS}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Stack
        direction="column"
        sx={{ padding: "40px", gap: "2rem", position: "relative" }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 5,
            right: 5,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={{ fontSize: "1.2rem", fontWeight: 500 }}>
          Add New Ad-Account for {sourceName}
        </Typography>
        <Stack direction="column" sx={{ gap: "1rem" }}>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            gap={2}
            backgroundColor="#75757520"
            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
            padding="1rem"
            borderRadius={"1rem"}
          >
            <AutoFixHigh />
            <Stack flexDirection={"column"}>
              <Typography className="text-wrap" fontSize={"0.8rem"}>
                To get list of all linked agit pud-accounts with your source
                account
              </Typography>
              <Typography
                className="text-wrap"
                fontSize={"0.8rem"}
                sx={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => {
                  window.location.replace(redirecturi);
                }}
              >
                Click here.
              </Typography>
            </Stack>
          </Stack>

          <Stack flexDirection={"row"}>
            <TextField
              sx={{ width: "100%", marginTop: "10px", fontSize: "0.75rem" }}
              value={accountId}
              onChange={(e) => {
                setAccountId(e.target.value);
              }}
              label="Enter Ad-Account ID"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleAddAccountBtn} color="success">
                      <Add color="success" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ width: "20%" }}
          >
            <Tooltip
              title={
                "View all linked agit pud-accounts with your source account"
              }
              arrow
            >
              <IconButton
                onClick={() => {
                  window.location.replace(redirecturi);
                }}
                aria-label="Add leadsie"
              >
                <AutoFixHigh color="success" />
              </IconButton>
            </Tooltip>
          </Stack> */}
          </Stack>
          {source?.listedAccounts?.length > 0 && (
            <TableContainer
              sx={{
                maxHeight: `calc(100% - 155px)`,
              }}
            >
              <Table>
                <TableHead sx={{ backgroundColor: "rgba(249, 249, 249, 1)" }}>
                  <TableRow>
                    <TableCell>Ad Account ID</TableCell>
                    <TableCell>Ad Account Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source?.listedAccounts?.map((account, index) => (
                    <TableRow key={index}>
                      <TableCell>{account.id}</TableCell>
                      <TableCell>{account.name}</TableCell>
                      <TableCell>
                        {account?.connected ? "CONNECTED" : "NOT-CONNECTED"}
                      </TableCell>
                      {account?.connected && (
                        <TableCell>
                          <IconButton
                            onClick={() => handleDeleteAccount(account?.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default AddAdAccountModal;
