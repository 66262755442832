export const AUTH = {
  GOOGLE_LOGIN: "authentication/google/login",
  FACEBOOK_LOGIN: "authentication/facebook/login",
  CREATE_ORG: "/organization",
  GET_AD_ACCOUNTS: "/facebook/:facebookUserId/ad-accounts",
  ADD_PLATFORMS: "/organization/add-platform",
  GET_PLATFORM: "/platform",
  LIST_ORG: "organization",
  GET_ORG_TOKEN: "auth-token/organization/:organizationId",
  CONNECT_AD_ACCOUNTS: "/ad-account/many",
  GET_FB_ADD_ID: "facebook/app-id",
};

export const USER = {
  GET_USER: "/user/profile",
  UPDATE_USER: "user/:userId",
  DEACTIVATE_USER: "/user/deactivate",
  REGISTER_DEVICE: "/user/devices",
};
export const ONBOARDING = {
  LIST_ORG_GROUPS: "group/organization", //groupEntity=1(image)/2(text)/3(video)&isManualGroupingDone=0(false)/1(true)
  LIST_GROUP_ENTITY: "group/:groupId/entity",
  LIST_CUSTOM_AUDIENCE: "/custom-audience",
  UPDATE_GROUP_IMAGES: "/group/:groupId/entity",
  UPDATE_GROUP_ENTITY: "/group/:groupId/entity",
  MERGE_GROUPS: "/group/merge",
  SKIP_MANUAL_GROUPING: "/group/skip-manual-grouping",
  UPDATE_MANUAL_GROUPING: "/group/:groupId/update-manual-grouping",
  SKIP_MANUAL_TAGS: "/group/skip-manual-tagging",
};

export const COMMON = {
  MARK_LEADSIE_CONNECTED: "/organization/:id",
  APPLICATION_STATUS: "application/status",
  LIST_ORG_GROUPS: "/group/organization", //groupEntity=1(image)/2(text)/3(video)
  LIST_ORG_GROUPS_DROPDOWN: "/group/organization?isDropDownView=1", //groupEntity=1(image)/2(text)/3(video)
  FILTERS: "/common/filters", //params:{include:"platform,creativeType,adAccount,objective,optimizationGoal,kpis"}
  INSIGHTS_FILTERS: "/insight/filters",
  GET_UPLOAD_URLS: "/commons/upload-url",
  GET_AVAILABLE_CURRENCIES: "/commons/currencies",
  MOVE_REPORT_TO_FOLDER: "/organization/folder/:id/:reportableId",
  DUPLICATE_REPORT_TO_FOLDER: "reports/duplicate",
};
export const TAGGING = {
  CREATE_IMAGE_GROUP_TAGS: "/group/:groupId/tag/image",
  CREATE_VIDEO_GROUP_TAGS: "/group/:groupId/tag/video",
  CREATE_TEXT_GROUP_TAGS: "/group/:groupId/tag/text",
  CREATE_CUSTOM_AUDIENCE_TAGS: "/group/:groupId/tag/custom-audience",
  GET_ORGANIZATION_TAGS: "/organization/tag/search", // ?search=tag
  GET_IMAGE_AI_TAGS: "/group/:groupId/tag/ai/image",
  GET_VIDEO_AI_TAGS: "/group/:groupId/tag/ai/video", //?segmentType=1
  GET_TEXT_AI_TAGS: "/group/:groupId/tag/ai/text",
};
export const UNGROUPED = {
  LIST_UNGROUPED_ENTITIES: "/ungrouped/entities", //groupEntity=1(image)/2(text)/3(video)
  CREATE_GROUP: "/group",
};
export const INSIGHTS = {
  INSIGHTS_FILTERS: "/insight/filters",
  GET_INSIGHT_DATA: "/insight/fetch",
  GET_ORG_LEVEL_INSIGHTS: "/insight/organization",
  GROUP_LEVEL_INSIGHTS: "/insight/group",
  CREATE_INSIGHTS_REPORT: "/insight",
  GET_SPECIFIC_INSIGHT: "insight/:insightId",
  UPDATE_INSIGHTS_REPORT: "/insight/:reportableId",
};

export const GET_GROUP_TAGS = {
  GET_GROUP_MEDIA_ASSET: "/group/:groupId/mediaAsset",
  GET_IMAGE_GROUP_TAGS: "/group/:groupId/tag/image",
  GET_VIDEO_GROUP_TAGS: "/group/:groupId/tag/video",
  GET_TEXT_GROUP_TAGS: "/group/:groupId/tag/text",
  GET_CUSTOM_AUDIENCE_TAGS: "/group/:groupId/tag/custom-audience",
};

export const ORGANISATION = {
  ADD_USER: "/organization/add-user",
  REMOVE_USER: "/organization/remove-user",
  GET_AD_ACCOUNTS: "/organization/ad-accounts",
  GET_USERS: "/organization/users",
  GET_ORGANIZATION_TAGS: "/organization/tag",
  REFRESH_FIVETRAN_CONNECTOR: "/fivetran/webhook",
  REFRESH_ORGANIZATION_CONNECTORS: "/organization/refresh",
  ADD_ACCOUNTS: "/ad-account",
  REFRESH_FUNNEL_STAGES: "/organization/refresh/funnel-stages",
  CHANGE_USER_ROLE: "/user/role/:userId/:type",
  CANCEL_SUBSCRIPTION: "/organization/subscription/cancel",
  DEACTIVATE_ORGANIZATION: "/organization/deactivate",
};

export const BRAND_KIT = {
  BRAND_LOGO: {
    GET: "/brand-logo",
    CREATE: "/brand-logo",
    DELETE: "/brand-logo/:brandLogoId",
  },
  BRAND_COLOR: {
    GET: "/brand-color",
    CREATE: "/brand-color",
    UPDATE: "/brand-color/:brandColorId",
    DELETE: "/brand-color/:brandColorId",
  },

  BRAND_FONT: {
    GET: "/brand-font",
    CREATE: "/brand-font",
    DELETE: "/brand-font/:brandFontId",
  },
};

export const PREDICTION = {
  default: "/prediction",
  CREATE_PREDICTION: "/predictions",
  GET_PREDICTION_REPORTS: "/predictions",
  GET_SPECIFIC_PREDICTION: "/predictions/:reportableId",
  UPDATE_SPECIFIC_PREDICTION: "/predictions/:reportableId/perform-action",
  GET_MEDIA_FOR_PREDICTION: "predictions/:reportableId/media-assets",
  GET_PREDICTION_MEDIA_TAGS: "/predictions/:reportableId/media-assets/:mediaId",
  UPDATE_PREDICTION_MEDIA_TAGS:
    "/predictions/:reportableId/media-assets/:mediaId/tag",
  GET_PREDICTION_REPORT_DETAILS: "/reports",
};

export const RECOMMENDATION = {
  GET_RECOMMENDATIONS: "/recommendations/fetch",
  CREATE_RECOMMENDATIONS_REPORT: "/recommendations",
  GET_SPECIFIC_RECOMMENDATION: "/recommendations/:recommendationId",
};

export const GENERATION = {
  GET_GENERATION: "/generations",
  CREATE_GENERATION: "/generations",
};

export const REPORTS = {
  GET_REPORTS: "/reports",
  BOOKMARK_REPORT: "/reports/bookmark",
  REMOVE_BOOKMARK: "/reports/:id",
  MAKE_REPORT_PUBLIC: "/reports/public/:id",
  SHORT_REPORT_URL: "/reports/shortUrl",
  DELETE_REPORT: "reports/:id/hard",
  GET_REPORT_MESSAGE: "reports/message/:reportableId",
  GET_FOLDER_HEIRACHY: "organization/folder/:folderId",
};

export const FOLDERS = {
  CREATE_FOLDER: "/organization/folder",
  ADD_REPORTS_TO_FOLDER: "/organization/folder/:folderId/:reportableId",
  GET_REPORTS_IN_FOLDER: "organization/folder/:folderId",
  GET_ORGANIZATION_FOLDERS: "/organization/folder",
  DELETE_FOLDER: "/organization/folder/:folderId",
  RENAME_FOLDER: "/organization/folder/details",
};

export const PRESETS = {
  GET_PRESETS: "/organization/presets",
  GET_SPECIFIC_PRESET: "organization/presets/:id",
  CREATE_PRESET: "/organization/presets",
  UPDATE_PRESET: "/organization/presets/:id",
};

export const TYPEAHEAD = "/organization/typeahead";

export const CONTROL_PANEL = {
  HEARTBEAT: "/control-panel/heartbeat",
};
