import React from "react";
import {
  Button,
  Dialog,
  useMediaQuery,
  Typography,
  Stack,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "../../../../redux/slices/modal.slices";

import { AutoCompleteWithChips } from "components/common/molecules";

export default function PlotChartModal(props) {
  const {
    metricsOptions,
    selectedMetrics,
    onSelectedMetricsUpdate,
    handleClick,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();
  const _metricsOptions = metricsOptions?.map((item) => ({
    label: item.label,
    value: item.kpi,
  }));
  function handleClose() {
    dispatch(setModal(modalInitState));
  }
  return (
    <Dialog
      fullScreen={fullScreen}
      open={modal?.open && modal?.type === MODALTYPES.PLOT_CHART_MODAL}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Stack direction="column" sx={{ padding: "16px" }} spacing={3}>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            backgroundColor: "#F9F9F9",
            border: "1px solid #EAECF0",
            borderRadius: "10px",
            padding: "4px 8px",
          }}
        >
          <Typography sx={{ fontWeight: "500" }}>Important:</Typography>
          <Typography sx={{ color: "1C1E2070" }}>
            You can plot a chart between only{" "}
            <span style={{ color: "#1C1E20", fontWeight: "500" }}>
              Two Metrics
            </span>
            , please. Select or remove your metrics for plotting the charts.
          </Typography>
        </Stack>
        <Stack direction="column" spacing={1}>
          <AutoCompleteWithChips
            optionsList={_metricsOptions}
            inputLabel="Search Metrics"
            onTagUpdate={(value) => {
              onSelectedMetricsUpdate(value);
            }}
            values={selectedMetrics}
            limit={2}
          />
        </Stack>
        <Stack direction="row" justifyContent="center">
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            disabled={selectedMetrics.length !== 2}
            onClick={(e) => {
              handleClick(e);
              handleClose();
            }}
          >
            Plot Chart
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
