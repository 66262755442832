import React from "react";
import { Button, Tooltip } from "@mui/material";

export default function IconButtonWithTooltip(props) {
  const { iconTitle, children, isActive, style, handleClick } = props;
  return (
    <Tooltip title={iconTitle} arrow sx={{ background: "#fff" }}>
      <Button
        variant={isActive ? "outlined" : "text"}
        sx={{
          padding: "4px 6px",
          minWidth: "auto",
          color: isActive ? "#7167FB" : "rgba(28, 30, 32, 0.7)",
          ...style,
        }}
        onClick={handleClick}
      >
        {children}
      </Button>
    </Tooltip>
  );
}
