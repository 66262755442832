import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { ContentContainer } from "components/layouts";
import { Copy, Loader2, RouteOff } from "lucide-react";
import { useSelector } from "react-redux";
import moment from "moment";
import useUserRole from "hooks/useUserRole";
import useOrganisationDetail from "../ManageOrganisation/Components/OrganisationDetail/useOrganisationDetail";
import { A_PERMISSIONS } from "config/roles";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";

export default function ManageBilling(props) {
  const [isDeactivate, setIsDeactivate] = useState("");

  const { selectedOrganisation } = useSelector((state) => state?.organisation);
  const { hasUserPermission, isUserSubscribed } = useUserRole();

  const { isCancellingSub, cancelSubscription } =
    useOrganisationDetail(selectedOrganisation);

  let nextBillingDate;

  if (selectedOrganisation?.paymentMeta) {
    try {
      const paymentMeta = JSON.parse(selectedOrganisation?.paymentMeta);
      if (paymentMeta?.status == "active") {
        nextBillingDate = moment
          .unix(paymentMeta.current_period_end)
          .format("DD MMM YYYY");
      }
    } catch (err) {}
  }

  const { toast } = useToast();

  return (
    <ContentContainer
      containerStyles={{
        maxHeight: `calc(100vh - 70px)`,
        padding: "0 20px",
      }}
    >
      <Stack direction="column" spacing={4} sx={{ width: "100%" }}>
        <div
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 500,
            }}
          >
            Manage Billings
          </Typography>
        </div>

        <div class="grid grid-cols-2 p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div>
            <svg
              class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3 inline-block"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
            </svg>
            <a href="#">
              <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                Request Upgradation
              </h5>
            </a>
            <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">
              Please contact from Admin to upgrade your plan.
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <div className="grid flex-1 gap-2">
              <Input
                id="link"
                defaultValue="varun@thestoryteller.tech"
                readOnly
              />
            </div>
            <Button
              type="submit"
              variant="outline"
              size="sm"
              className="px-3"
              onClick={() => {
                navigator.clipboard.writeText("varun@thestoryteller.tech");
                toast({
                  description: "Email Address Copied To Clipboard.",
                });
              }}
            >
              <span className="sr-only">Copy</span>
              <Copy className="h-4 w-4" />
            </Button>
          </div>
        </div>

        <div className="flex flex-row justify-between space-y-1">
          {nextBillingDate && <div>Next billing date: {nextBillingDate}</div>}
        </div>

        {hasUserPermission(A_PERMISSIONS.UPDATE_CURRENCY) &&
          isUserSubscribed() && (
            <Card>
              <CardHeader>
                <CardTitle className="text-lg">Cancel subscription</CardTitle>
                <CardDescription className="text-xs">
                  This Will Cancel you current subscription, To Continue, Please
                  Type "CANCEL".
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-2">
                <div className="space-y-1">
                  <Label htmlFor="current">Type Here</Label>
                  <Input
                    id="current"
                    onChange={(e, v) => setIsDeactivate(e?.target?.value)}
                    className="w-fit"
                  />
                </div>
              </CardContent>
              <CardFooter>
                {!isCancellingSub ? (
                  <Button
                    disabled={isDeactivate !== "CANCEL"}
                    variant="destructive"
                    onClick={cancelSubscription}
                  >
                    <RouteOff className="mr-2 h-4 w-4" /> Cancel subscription
                  </Button>
                ) : (
                  <Button disabled>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Cancelling
                  </Button>
                )}
              </CardFooter>
            </Card>
          )}
      </Stack>
    </ContentContainer>
  );
}
