import React from "react";

const VideoBreakdownIcon = ({ isActive }) => {
  const color = isActive ? "#7167FB" : "#858383";
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.925 7.001 7.173 4.93v8.14L9.925 11 12.586 9 9.925 7.001Zm0 0L7.173 4.93v8.14L9.925 11 12.586 9 9.925 7.001Zm0 0L7.173 4.93v8.14L9.925 11 12.586 9 9.925 7.001Zm-1.85-5.174V0a8.965 8.965 0 0 0-4.8 1.999l1.281 1.293a7.155 7.155 0 0 1 3.52-1.465Zm-4.79 2.74L1.993 3.284A9.011 9.011 0 0 0 0 8.095h1.823a7.192 7.192 0 0 1 1.461-3.527ZM1.822 9.906H0a9.01 9.01 0 0 0 1.994 4.812l1.29-1.294a7.126 7.126 0 0 1-1.461-3.518ZM3.275 16A8.994 8.994 0 0 0 8.075 18v-1.827a7.155 7.155 0 0 1-3.519-1.465L3.276 16ZM18 9c0 4.667-3.537 8.52-8.075 9v-1.827A7.244 7.244 0 0 0 16.195 9a7.244 7.244 0 0 0-6.27-7.173V0C14.463.48 18 4.333 18 9Z"
        fill={color}
      />
    </svg>
  );
};

export default VideoBreakdownIcon;
