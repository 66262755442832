import React from "react";

const Grouping = ({ isActive }) => {
  const color = isActive ? "#7167FB" : "#858383";
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 0C1.417 0 .75.667.75 1.5v8.25c0 .832.667 1.5 1.5 1.5h3v3c0 .832.668 1.5 1.5 1.5H15c.832 0 1.5-.668 1.5-1.5V6c0-.832-.668-1.5-1.5-1.5h-3v-3c0-.833-.668-1.5-1.5-1.5H2.25Zm0 1.5h8.25v3H6.75c-.832 0-1.5.668-1.5 1.5v3.75h-3V1.5ZM6.75 6h3.75v3.75H6.75V6ZM12 6h3v8.25H6.75v-3h3.75c.832 0 1.5-.668 1.5-1.5V6Z"
        fill={color}
      />
    </svg>
  );
};

export default Grouping;
