import { Box } from "@mui/material";
import React from "react";

const openDrawerWidth = "210px";

export default function ResponsiveBox({ children }) {
  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "auto",
        overflowY: "hidden",
        "@media (min-width: 600px)": {
          maxWidth: "600px",
        },
        "@media (min-width: 960px)": {
          maxWidth: `${960 - parseInt(openDrawerWidth)}px`,
        },
        "@media (min-width: 1280px)": {
          maxWidth: `${1280 - parseInt(openDrawerWidth)}px`,
        },
        "@media (min-width: 1440px)": {
          maxWidth: `${1440 - parseInt(openDrawerWidth)}px`,
        },
        "@media (min-width: 1536px)": {
          maxWidth: `${1536 - parseInt(openDrawerWidth)}px`,
        },
      }}
    >
      {children}
    </Box>
  );
}
