import React from "react";
import PublicContainer from "../../../components/layouts/PublicContainer";
import { Button, Typography } from "@mui/material";
import { addCircle } from "../../../assets/icons/svg";

import useOrgSelection from "./useOrgSelection";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../routes";

export default function OrgSelectionScreen() {
  const {
    allOrganisations,
    handleOrgSelection,
    selectedOrganisation,
    handleNext,
  } = useOrgSelection();
  const navigate = useNavigate();
  return (
    <PublicContainer>
      <Typography
        variant="h1"
        sx={{
          fontWeight: "700",
          textAlign: "center",
          textTransform: "capitalize",
          paddingBottom: "8px",
        }}
      >
        Welcome to storyteller
      </Typography>
      <Typography
        variant="h3"
        sx={{
          fontWeight: "500",
          textAlign: "center",
          textTransform: "capitalize",
          color: "rgba(28, 30, 32, 0.7)",
          paddingBottom: "64px",
        }}
      >
        The creative engine for your growth
      </Typography>
      <Typography
        variant="h2"
        sx={{
          fontWeight: "500",
          textAlign: "center",
          textTransform: "capitalize",
          maxWidth: "400px",
          paddingBottom: "58px",
        }}
      >
        Select the organization for which you want to analyze content
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          paddingBottom: "32px",
        }}
      >
        {allOrganisations?.map((org) => (
          <div
            key={org._id}
            style={{
              display: "flex",
              gap: "60px",
              justifyContent: "space-between",
              padding: "14px 16px",
              border:
                selectedOrganisation?._id === org?._id
                  ? "1px solid #7167FB"
                  : "1px solid #E5E5E5",
              borderRadius: "6px",
              cursor: "pointer",
            }}
            onClick={() => handleOrgSelection(org)}
            onDoubleClick={() => {
              handleOrgSelection(org);
              handleNext(org);
            }}
          >
            <Typography variant="body2">{org?.name}</Typography>
            <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
              {`${org?.adAccountIds?.length} ad account${
                org?.adAccountIds?.length > 1 ? "s" : ""
              }`}
            </Typography>
          </div>
        ))}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
        >
          <div
            style={{ display: "flex", gap: "4px" }}
            onClick={() => navigate(APP_ROUTES.AUTH.ADD_ORGANIZATION)}
          >
            <img src={addCircle} alt="add" />
            <Typography>Add more organization</Typography>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={() => handleNext(selectedOrganisation)}
            disabled={!selectedOrganisation}
          >
            Next
          </Button>
        </div>
      </div>
    </PublicContainer>
  );
}
