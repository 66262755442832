import { Button, Button as ShadButton } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  Select as ShadSelect,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { PopoverClose } from "@radix-ui/react-popover";
import {
  FILTER_CATEGORY_REVERSE_MAPPING,
  FILTER_MAPPING,
} from "config/filters";
import { Check, ChevronDown, ChevronsUpDown, Info, Trash2 } from "lucide-react";
import AutoComplete from "screens/Insights/Components/AutoCompleteWithTags/AutoComplete";
import { DarkTooltip } from "screens/Insights/Components/ColorTable/ColorTable";

const AGGREGATOR_REVERSE_MAPPING = {
  is: {
    label: "is",
    value: "is",
  },
  isNot: {
    label: "is not",
    value: "isNot",
  },
  contains: {
    label: "Contains",
    value: "contains",
  },
  notContains: {
    label: "Does not contains",
    value: "notContains",
  },
};

const FilterContent = (props) => {
  const {
    payload,
    filterAndOptions,
    onFilterWhereChange,
    fIndex,
    onFilterFilterChange,
    audienceSegments,
    onFilterValueChange,
    handleSearchMotionFilters,
    handleUpdateSearchExtraFilter,
    isSmartSuggestionLoading,
    smartSuggestions,
    handleDeleteFromFilterSet,
    onUpdatePayload,
    handleAddMore,
    onRemovePayload,
    setCurrentFilterObj,
    applyPayload,
    setOpenFilters,
    groupIndex,
    isIndividualFilter,
  } = props;

  const truncateText = (text) => {
    return text && text.length > 25 ? text.substring(0, 25) + "..." : text;
  };

  function getLabelsForValues(key, index) {
    if (key === "audienceSegment") {
      const filter = payload?.[index]?.filter;
      const selectedValues = payload[index]?.[filter] || [];
      return selectedValues?.join(" or ");
    }
    if (
      Object.keys(FILTER_CATEGORY_REVERSE_MAPPING).includes(key) &&
      FILTER_CATEGORY_REVERSE_MAPPING[key]?.isStatic
    ) {
      const filter = payload?.[index]?.filter;
      const selectedValues = payload[index]?.[filter] || [];
      const staticOptions = FILTER_CATEGORY_REVERSE_MAPPING[key]?.options;
      const _vals = [];
      selectedValues?.forEach((value) => {
        return staticOptions?.forEach((option, idx) => {
          if (option?.value === value) {
            _vals?.push(option?.label);
          }
        });
      });
      return _vals?.join(" or ");
    }
    const filter = payload?.[index]?.filter;
    const options = filterAndOptions[key]?.options || [];
    const selectedValues = payload[index]?.[filter] || [];

    const filteredOptions = options.filter((option) =>
      selectedValues.includes(option.key)
    );
    const labels = filteredOptions
      .map((option) => option.label)
      .filter((label) => label);

    return labels.join(" or ");
  }

  return (
    <div id="parent-filter-content" className="overflow-visible text-xs">
      <div
        id="filter-content"
        className="flex gap-x-3 items-start max-h-[270px] overflow-y-scroll"
      >
        <p className="mt-2">Where</p>
        <div className="flex flex-col gap-y-2 mt-2">
          {payload?.map((filter, index) => {
            if (filter.type === "adAccountIds") {
              return null;
            }

            return (
              <div className="flex gap-x-2 items-center flex-wrap">
                <DropdownMenu>
                  <DropdownMenuTrigger className="w-[160px] text-xs" asChild>
                    <Button
                      className="truncate flex justify-between text-xs"
                      variant="outline"
                    >
                      <span className="w-[90%] flex justify-start truncate text-xs">
                        {payload[index]?.type
                          ? Object.keys(
                              FILTER_CATEGORY_REVERSE_MAPPING
                            ).includes(payload[index]?.type)
                            ? FILTER_CATEGORY_REVERSE_MAPPING[
                                payload[index]?.type
                              ]?.label
                            : filterAndOptions[payload[index]?.type]?.label
                          : "Where"}
                      </span>
                      <ChevronDown className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-52">
                    <DropdownMenuGroup>
                      {Object?.entries(filterAndOptions)?.map(
                        ([key, value], idx) => {
                          if (
                            key === "dateRange" ||
                            key === "sortOrder" ||
                            key === "kpi" ||
                            key === "adAccountIds" ||
                            key === "objectives" ||
                            key === "optimizationGoals" ||
                            key === "funnelStages" ||
                            key === "targetingCountries" ||
                            key === "creativeTypes"
                          )
                            return null;
                          return (
                            <DropdownMenuItem
                              key={idx}
                              value={key?.toString()}
                              className="cursor-pointer w-full text-xs"
                              onClick={() => {
                                onFilterWhereChange(
                                  index,
                                  key,
                                  fIndex,
                                  groupIndex
                                );
                              }}
                            >
                              <span>{value?.label}</span>
                            </DropdownMenuItem>
                          );
                        }
                      )}
                      {FILTER_MAPPING.map((curr, ix) => {
                        return (
                          <DropdownMenuSub>
                            <DropdownMenuSubTrigger className="text-xs">
                              <span>{curr?.label}</span>
                            </DropdownMenuSubTrigger>
                            <DropdownMenuPortal>
                              <DropdownMenuSubContent>
                                {curr?.subCategories?.map((subCat, ix) => {
                                  return (
                                    <DropdownMenuItem
                                      className="text-xs"
                                      onClick={() => {
                                        onFilterWhereChange(
                                          index,
                                          subCat?.dataKey,
                                          fIndex,
                                          groupIndex
                                        );
                                      }}
                                    >
                                      <div className="flex w-full gap-x-2 justify-between">
                                        <span>{subCat?.label}</span>
                                        <DarkTooltip
                                          title={subCat?.tooltip}
                                          placement="right"
                                        >
                                          <Info className="h-4 w-5" />
                                        </DarkTooltip>
                                      </div>
                                    </DropdownMenuItem>
                                  );
                                })}
                              </DropdownMenuSubContent>
                            </DropdownMenuPortal>
                          </DropdownMenuSub>
                        );
                      })}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>

                {/* is is-not contains not-contains dropdown */}
                {payload?.[index]?.mode === 1 ? (
                  <ShadSelect
                    disabled={false}
                    onValueChange={(val) => {
                      onFilterFilterChange(index, val, fIndex, groupIndex);
                    }}
                    value={payload[index]?.filter || "is"}
                  >
                    <SelectTrigger className="w-[120px] text-xs font-medium">
                      <span>
                        {AGGREGATOR_REVERSE_MAPPING?.[payload?.[index]?.filter]
                          ?.label || "is"}
                      </span>
                    </SelectTrigger>
                    <SelectContent className="text-xs">
                      <SelectItem value="is">is</SelectItem>
                      <SelectItem value="isNot">is not</SelectItem>
                    </SelectContent>
                  </ShadSelect>
                ) : (
                  <ShadSelect
                    className="text-xs"
                    disabled={false}
                    onValueChange={(val) => {
                      onFilterFilterChange(index, val, fIndex, groupIndex);
                    }}
                    value={payload?.[index]?.filter || "contains"}
                  >
                    <SelectTrigger className="w-[120px] text-xs font-medium">
                      {AGGREGATOR_REVERSE_MAPPING?.[payload?.[index]?.filter]
                        ?.label || "contains"}
                    </SelectTrigger>
                    <SelectContent className="text-xs">
                      <SelectItem value="contains">contains</SelectItem>
                      <SelectItem value="notContains">
                        does not contains
                      </SelectItem>
                    </SelectContent>
                  </ShadSelect>
                )}

                {/* Select value dropdwon */}
                {!Object.keys(FILTER_CATEGORY_REVERSE_MAPPING).includes(
                  payload?.[index]?.type
                ) ||
                FILTER_CATEGORY_REVERSE_MAPPING?.[payload?.[index]?.type]
                  ?.type === "dropdown" ? (
                  FILTER_CATEGORY_REVERSE_MAPPING?.[payload?.[index]?.type]
                    ?.isSearchBased ? (
                    <>
                      <AutoComplete
                        label="Tags(type something and wait for realtime suggestions)"
                        placeholder="hit return to add filter"
                        size="small"
                        values={
                          payload[index]?.filter === "is"
                            ? payload?.[index]?.is
                            : payload[index]?.isNot
                        }
                        handleSearchTags={handleSearchMotionFilters}
                        insightFilterType={payload?.[index]?.type}
                        isInsights={true}
                        onTagUpdate={(val) => {
                          onFilterValueChange(
                            index,
                            val,
                            fIndex,
                            groupIndex,
                            true
                          );
                        }}
                        extraFiltersSuggestions={
                          isSmartSuggestionLoading
                            ? ["Loading..."]
                            : smartSuggestions
                        }
                        isSmartSuggestionLoading={isSmartSuggestionLoading}
                        fullWidth
                      />
                    </>
                  ) : (
                    <Popover>
                      <PopoverTrigger asChild>
                        <ShadButton
                          variant="outline"
                          role="combobox"
                          className="w-[250px] px-2 overflow-hidden truncate flex justify-between"
                        >
                          <span className=" text-xs">
                            {payload[index]?.[payload?.[index]?.filter]
                              ?.length > 0
                              ? truncateText(
                                  getLabelsForValues(
                                    payload[index]?.type,
                                    index
                                  )
                                )
                              : "Select filter..."}
                          </span>
                          <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
                        </ShadButton>
                      </PopoverTrigger>
                      <PopoverContent className="w-[250px] p-0">
                        <Command>
                          <CommandInput placeholder="Search filter..." />
                          <CommandEmpty>No filter found.</CommandEmpty>
                          <CommandList>
                            <CommandGroup>
                              {(payload[index]?.type === "audienceSegment"
                                ? audienceSegments
                                : FILTER_CATEGORY_REVERSE_MAPPING?.[
                                    payload?.[index]?.type
                                  ]?.isStatic
                                ? FILTER_CATEGORY_REVERSE_MAPPING?.[
                                    payload?.[index]?.type
                                  ]?.options
                                : filterAndOptions[
                                    payload[index]?.type?.toString()
                                  ]?.options
                              )?.map((name) => {
                                return (
                                  <CommandItem
                                    key={name}
                                    value={name?.key ? name?.key : name?.value}
                                    onSelect={(val) => {
                                      onFilterValueChange(
                                        index,
                                        val,
                                        fIndex,
                                        groupIndex
                                      );
                                    }}
                                    className="text-xs"
                                  >
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        payload[index]?.[
                                          payload?.[index]?.filter
                                        ]?.includes(
                                          payload[index]?.type ===
                                            "audienceSegment"
                                            ? name
                                            : name?.key
                                            ? name?.key
                                            : name?.value
                                        ) ||
                                          payload[index]?.[
                                            payload[index]?.filter
                                          ]?.includes(
                                            payload[index]?.type ===
                                              "audienceSegment"
                                              ? name
                                              : name?.value
                                              ? name?.value
                                              : name
                                          )
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {name?.label ? name?.label : name}
                                  </CommandItem>
                                );
                              })}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  )
                ) : (
                  <>
                    <AutoComplete
                      label="Tags(type something and wait for realtime suggestions)"
                      placeholder="hit return to add filter"
                      size="small"
                      values={
                        payload[index]?.filter === "contains"
                          ? payload?.[index]?.contains
                          : payload[index]?.notContains
                      }
                      handleSearchTags={handleSearchMotionFilters}
                      insightFilterType={payload?.[index]?.type}
                      isInsights={true}
                      onTagUpdate={(tags) => {
                        handleUpdateSearchExtraFilter(
                          index,
                          tags,
                          payload[index]?.filter,
                          fIndex,
                          groupIndex
                        );
                      }}
                      extraFiltersSuggestions={
                        isSmartSuggestionLoading
                          ? ["Loading..."]
                          : smartSuggestions
                      }
                      isSmartSuggestionLoading={isSmartSuggestionLoading}
                      fullWidth
                    />
                  </>
                )}

                <Trash2
                  onClick={() => {
                    if (index === 0) return null;
                    if (fIndex !== null) {
                      handleDeleteFromFilterSet(fIndex, index, groupIndex);
                    } else {
                      const _currentPayload = payload?.filter(
                        (curr, idx) => idx !== index
                      );
                      onUpdatePayload(_currentPayload);
                    }
                  }}
                  className={
                    index === 0
                      ? "p-1 text-red-500 cursor-not-allowed opacity-25 mr-2"
                      : "p-1 text-red-500 cursor-pointer mr-2"
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      {/* buttons apply, remove, etc*/}
      <div className="text-xs">
        <div className="flex justify-between">
          <ShadButton
            className={`mt-4 justify-end text-xs`}
            variant="secondary"
            onClick={() => {
              let updatedPayload;
              if (payload?.length > 0) {
                const oldPayload = payload[payload?.length - 1];
                if (["is", "isNot"]?.includes(oldPayload?.filter)) {
                  updatedPayload = {
                    is: [],
                    isNot: [],
                    type: payload?.[payload?.length - 1]?.type,
                    mode: payload?.[payload?.length - 1]?.mode,
                    filter: payload?.[payload?.length - 1]?.filter,
                  };
                } else {
                  updatedPayload = {
                    contains: [],
                    notContains: [],
                    type: payload?.[payload?.length - 1]?.type,
                    mode: payload?.[payload?.length - 1]?.mode,
                    filter: payload?.[payload?.length - 1]?.filter,
                  };
                }
              } else {
                updatedPayload = {
                  type: "adName",
                  mode: 2,
                  filter: "contains",
                  contains: [],
                  notContains: [],
                };
              }
              handleAddMore(updatedPayload, fIndex, groupIndex);
            }}
          >
            Add OR condition
          </ShadButton>
          <div className="flex gap-x-2">
            <PopoverClose asChild>
              <ShadButton
                variant="outline"
                className="mt-4 justify-end text-xs"
                onClick={() => {
                  onRemovePayload(fIndex, groupIndex);
                }}
              >
                Remove
              </ShadButton>
            </PopoverClose>
            <ShadButton
              className="mt-4 justify-end text-white bg-[#7167FB] hover:bg-[#7167FB]/90 text-xs"
              onClick={() => {
                if (isIndividualFilter) {
                  applyPayload(groupIndex, isIndividualFilter);
                  setOpenFilters(false);
                } else {
                  if (groupIndex === 0 || groupIndex) {
                    if (fIndex === null) {
                      setOpenFilters(false);
                      applyPayload(groupIndex);
                    } else {
                      setOpenFilters(false);
                    }
                  } else if (fIndex === null) {
                    setOpenFilters(false);
                    applyPayload();
                    setCurrentFilterObj([]);
                  } else {
                    setOpenFilters(false);
                  }
                }
              }}
            >
              {fIndex !== null ? "Close" : "Save"}
            </ShadButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterContent;
