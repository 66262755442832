const COLORS = {
  background: { main: "#FFFFFF" },
  primary: { main: "#5E48E8", light: "#DAD7FE" },
  error: { main: "#FF3A29", light: "#FFE5D3" },
  warning: { main: "#FFB200", light: "#FFF5CC" },
  info: { main: "#02A0FC", light: "#CCF8FE" },
  success: { main: "#34a853", light: "#E2FBD7" },
  stoke: { main: "#A1B0CC" },
  text: {
    main: "#1C1E20",
    70: "#adb3b8",
    50: "#778088",
  },
};

const TYPOGRAPHY = {
  fontFamily: "DM Sans",
  h1: {
    fontSize: "40px",
    fontWeight: "medium",
    lineHeight: "auto",
    letterSpacing: "0",
    paragraphSpacing: "0",
    fontFamily: "inherit",
  },
};

const BORDER_RADIUS = {
  small: "4px",
  medium: "6px",
  large: "12px",
};

const GROUP_ENTITY = {
  CUSTOM_AUDIENCE: 1,
  IMAGE: 2,
  TEXT: 3,
  VIDEO: 4,
};

const PERFORMANCETYPE = {
  BEST: 1,
  WORST: 2,
};

const VIDEO_SEGMENT_TYPE = {
  ZERO_TO_THREE_SECONDS: 1,
  ZERO_TO_TWENTY_FIVE_PERCENT: 2,
  TWENTY_FIVE_TO_FIFTY_PERCENT: 3,
  FIFTY_TO_SEVENTY_FIVE_PERCENT: 4,
  SEVENTY_FIVE_TO_HUNDRED_PERCENT: 5,
  ZERO_TO_HUNDRED_PERCENT: 6,
};

const VIDEO_SEGMENT_TYPE_REVERSE = {
  [VIDEO_SEGMENT_TYPE.ZERO_TO_THREE_SECONDS]: "0-3 Seconds",
  [VIDEO_SEGMENT_TYPE.ZERO_TO_TWENTY_FIVE_PERCENT]: "First 25%",
  [VIDEO_SEGMENT_TYPE.TWENTY_FIVE_TO_FIFTY_PERCENT]: "Second 25%",
  [VIDEO_SEGMENT_TYPE.FIFTY_TO_SEVENTY_FIVE_PERCENT]: "Third 25%",
  [VIDEO_SEGMENT_TYPE.SEVENTY_FIVE_TO_HUNDRED_PERCENT]: "Last 25%",
  [VIDEO_SEGMENT_TYPE.ZERO_TO_HUNDRED_PERCENT]: "Complete 100%",
};
const CACHE_TIME = 10 * 24 * 60 * 60 * 1000;

const PLATFORM_STATUS = {
  DELETED: -2,
  NOT_CONNECTED: -1,
  CONNECTED: 0,
  SYNC_PENDING: 1,
  SYNCING: 2,
  SYNC_DONE: 3,
};

const REPORTABLE_TYPE = {
  INSIGHTS: "insights",
  PREDICTIONS: "predictions",
  COMPARE: "compare",
  RECOMMENDATIONS: "recommendations",
  COMPARE_MULTIPLE_DATES: "compare-dates",
  AD_BREAKDOWN: "ad-breakdown",
  CREATIVE_INSIGHTS: "creative-insight",
  CREATIVE_MOMENTUM_FATIGUE: "creative-momentum",
};

const BRAND_NAME = "Storyteller";

export {
  COLORS,
  TYPOGRAPHY,
  BORDER_RADIUS,
  GROUP_ENTITY,
  PERFORMANCETYPE,
  VIDEO_SEGMENT_TYPE,
  VIDEO_SEGMENT_TYPE_REVERSE,
  CACHE_TIME,
  PLATFORM_STATUS,
  REPORTABLE_TYPE,
  BRAND_NAME,
};

export const FIVETRAN_PLATFORM_FACEBOOK_ADS = "Facebook Ads";
export const STORYTELLER_PLATFORM_FACEBOOK_ADS = "Facebook Ads (Page Posts)";

export const GROUP_BY_COLUMNS = [
  "adName",
  "headline",
  "landingPageUrl",
  "groupId",
  "body",
  "name",
];
