import Picker from "@emoji-mart/react";
import { Wallpaper } from "lucide-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const ReportDetails = (props) => {
  const { reportData, reportableMessage, onUpdateReportData } = props;

  const [pickerVisible, setPickerVisible] = useState(false);
  const { appState } = useSelector((state) => state?.appState);
  const open = appState.isSidebarOpen;

  const togglePicker = (event) => {
    event.stopPropagation();
    setPickerVisible(!pickerVisible);
  };

  const updateEmoji = (emoji) => {
    onUpdateReportData(emoji.native, "icon");
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <div onClick={togglePicker} className="cursor-pointer">
          {reportData?.icon ? (
            <p className={"inline-block mr-2 text-2xl"}>{reportData?.icon}</p>
          ) : (
            <Wallpaper className="inline-block mr-2" />
          )}
        </div>
        {pickerVisible && (
          <div
            className={`z-[1000] absolute ${
              open ? "left-[400px]" : "left-[220px]"
            }`}
          >
            <Picker
              onEmojiSelect={updateEmoji}
              previewPosition="none"
              onClickOutside={() => setPickerVisible(false)}
              maxFrequentRows={0}
            />
          </div>
        )}
        <input
          type="text"
          className=" inline-block font-semibold text-black text-xl outline-none w-full"
          value={reportData?.name}
          placeholder="Untitled"
          onChange={(e) => onUpdateReportData(e.target.value, "name", 30)}
          style={{
            minWidth: "200px",
            display: "flex",
            flexWrap: "wrap",
          }}
        />
      </div>

      {reportableMessage?.[0]?.message ? (
        <p className="bg-orange-200 rounded-md text-gray-500 p-3 mt-2">
          {reportableMessage?.[0].message}
        </p>
      ) : (
        <textarea
          onChange={(e) =>
            onUpdateReportData(e.target.value, "description", 150)
          }
          value={reportData?.description}
          placeholder="Type a description for this report"
          // rows={3}
          className="mt-2 text-gray-500 p-3 text-sm outline-none min-h-20 resize-none break-all overflow-auto"
          width={"100%"}
        />
      )}
    </div>
  );
};

export default ReportDetails;
