import React from "react";
import PrivateContainer from "components/layouts/PrivateContainer";
import { Stack, Typography, Avatar } from "@mui/material";
import styles from "./styles.module.scss";
import ContentContainer from "components/layouts/ContentContainer";
import ImageTaggingModal from "components/modals/ImageTaggingModal";
import { BackButton } from "components/common/atoms";

import useTaggingGroupContent from "./useTaggingGroupContent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import ReactPlayer from "react-player";
import { storageUrlToCdn } from "utils/helper";
import { Button } from "@/components/ui/button";

export default function TaggingGroupContent() {
  const {
    contentList,
    totalDocs,
    isRemoving,
    setIsRemoving,
    onRemoving,
    onCancelRemoving,
    onSelectionChange,
    metaData,
    groupEntity,
  } = useTaggingGroupContent();
  return (
    <PrivateContainer bodyStyles={{ justifyContent: "flex-start" }}>
      <Stack
        direction="column"
        alignItems="flex-start"
        className={styles.group_wrap}
      >
        {/* <Stack direction='row' justifyContent='space-between'></Stack> */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={styles.group_info}
        >
          <BackButton />

          <Stack direction="row" spacing={2}>
            {!isRemoving && (
              <Button
                variant="secondary"
                sx={{ textTransform: "none", fontSize: "1rem" }}
                onClick={() => setIsRemoving(true)}
              >
                {`Remove ${groupEntity}`}
              </Button>
            )}

            {isRemoving && (
              <>
                <Button
                  variant="secondary"
                  sx={{ textTransform: "none", fontSize: "1rem" }}
                  onClick={onCancelRemoving}
                >
                  Cancel
                </Button>
                <Button
                  className={styles.group_action}
                  onClick={onRemoving}
                  sx={{
                    textTransform: "none",
                    marginLeft: "1rem",
                    fontSize: "1rem",
                  }}
                >
                  Update
                </Button>
              </>
            )}
          </Stack>
        </Stack>
        <Stack className={styles.group_info} marginTop="1rem">
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            className={styles.group_info_items}
          >
            <Stack
              direction="row"
              spacing={1}
              className={styles.group_info_date}
            >
              <Typography
                className={styles.group_info_date_key}
                sx={{ fontSize: "0.75rem" }}
              >
                last update date:
              </Typography>
              <Typography
                className={styles.group_info_date_value}
                sx={{ fontSize: "0.75rem" }}
              >
                {!!metaData?.group?.updatedAt
                  ? moment(metaData?.group?.updatedAt).format("DD/MM/YYYY")
                  : ""}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              className={styles.group_info_total}
            >
              <Typography
                className={styles.group_info_total_key}
                sx={{ fontSize: "0.75rem" }}
              >
                {`Total ${groupEntity}${totalDocs > 1 ? "s" : ""}:`}
              </Typography>
              <Typography
                className={styles.group_info_total_value}
                sx={{ fontSize: "0.75rem" }}
              >
                {totalDocs}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <ContentContainer
          className={styles.content}
          containerStyles={{
            maxHeight: "calc(100vh - 180px)",
            gap: "1.5rem",
          }}
        >
          {contentList.map((entity, i) => {
            return (
              <Stack
                direction="column"
                alignItems="center"
                sx={{
                  padding: "1.5em",
                  background: entity?.isSelected ? "#ff000030" : "#F2F3F5",
                  borderRadius: "8px",
                  cursor: "pointer",
                  height: "227px",
                  width: "227px",
                  gap: "8px",
                  position: "relative",
                }}
                onClick={() =>
                  isRemoving ? onSelectionChange(entity?._id) : () => {}
                }
              >
                {isRemoving && (
                  <Avatar
                    sx={{
                      backgroundColor: "#fff",
                      height: "24px",
                      width: "24px",
                      position: "absolute",
                      top: "16px",
                      right: "16px",
                      border: "1px solid #3f51b5",
                      zIndex: 1,
                    }}
                  >
                    {entity?.isSelected && <CheckCircleIcon color="error" />}
                  </Avatar>
                )}
                <Stack sx={{ height: "160px", width: "200px" }}>
                  {groupEntity === "image" ? (
                    <img
                      src={storageUrlToCdn(entity?.imageUrl)}
                      alt="thumbnail"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <ReactPlayer
                      url={storageUrlToCdn(entity?.videoUrl)}
                      controls
                      className="react-player"
                      key={entity?._id}
                      // playing={isPlaying}
                      // onStart={() => handlePlayPause(_id)}
                      // onPlay={() => handlePlayPause(_id)}
                      // onClick={() => handlePlayPause(_id)}
                      width="100%"
                      height="100%"
                    />
                  )}
                </Stack>
                {entity?.name && entity?.name !== "untitled" && (
                  <Stack>
                    <Typography
                      sx={{ textTransform: "capitalize" }}
                      title={entity?.name}
                    >
                      {entity?.name?.slice(0, 25) +
                        (entity?.name?.length > 25 ? "..." : "")}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            );
          })}
        </ContentContainer>
      </Stack>
      <ImageTaggingModal />
    </PrivateContainer>
  );
}
