import React from "react";
import Box from "@mui/material/Box";

import SuccessModal from "../../modals/SuccessModal/SuccessModal";
import { Backdrop } from "@mui/material";
import { useSelector } from "react-redux";
import MainLoader from "components/common/loaders/MainLoader";
import MainSidebar from "components/common/molecules/ModernSidebar/MainSidebar";
import { Outlet } from "react-router-dom";
import { APP_ROUTES } from "routes";
import useAuth from "hooks/useAuth";

const PrivateWrapper = () => {
  const { loading } = useSelector((state) => state?.app);
  const { isUserAuthenticated } = useAuth();

  let hideSidebar = false;
  if (!isUserAuthenticated) {
    hideSidebar = true;
  }

  return (
    <Box
      style={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div style={{ display: hideSidebar ? "none" : "" }}>
        <MainSidebar activePage={localStorage.getItem("activePage")} />
      </div>
      <Outlet />
      <SuccessModal />

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 999 }}
        open={loading?.length > 0}
      >
        <MainLoader />
      </Backdrop>
    </Box>
  );
};

export default PrivateWrapper;
