import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { add_tags } from "../../../assets/images";
import useAppState from "../../../hooks/useAppState";
import useAxios from "../../../hooks/useAxios";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import { MODALTYPES, setModal } from "../../../redux/slices/modal.slices";
import { API_ROUTES } from "../../../routes";
import { GROUP_ENTITY } from "../../../utils/constants";

export default function useTextTagging(isManuallyTagged) {
  const { axiosOrgInstance } = useAxios();
  const dispatch = useDispatch();
  const [groupsList, setGroupsList] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { isLoadMore } = useInfiniteScroll("content-container", hasNextPage);
  const [page, setPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const { updateAppState } = useAppState();

  useEffect(() => {
    if (isLoadMore && hasNextPage) {
      axiosOrgInstance
        .get(API_ROUTES.ONBOARDING.LIST_ORG_GROUPS, {
          params: {
            groupEntity: GROUP_ENTITY.TEXT,
            page: page + 1,
            isManualGroupingDone: 1,
            isGroupManualTaggingDone: isManuallyTagged ? 1 : 0,
          },
        })
        .then((res) => {
          setHasNextPage(res?.hasNextPage);
          setPage(res?.page);
          setGroupsList((prev) => [...prev, ...res?.docs]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadMore]);

  function initGroup() {
    axiosOrgInstance
      .get(API_ROUTES.ONBOARDING.LIST_ORG_GROUPS, {
        params: {
          groupEntity: GROUP_ENTITY.TEXT,
          isManualGroupingDone: 1,
          isGroupManualTaggingDone: isManuallyTagged ? 1 : 0,
        },
      })
      .then((res) => {
        setHasNextPage(res?.hasNextPage);
        setTotalDocs(res?.totalDocs);
        setGroupsList(res?.docs);
        if (res?.totalDocs === 0) {
          updateAppState();
        }
      });
  }

  function startTagging(group) {
    dispatch(
      setModal({
        open: true,
        type: isManuallyTagged
          ? MODALTYPES.MANUAL_TEXT_TAGGING_MODAL
          : MODALTYPES.TEXT_TAGGING_MODAL,
        payload: { group: group },
      })
    );
  }

  function handleSkipUpdate() {
    dispatch(
      setModal({
        type: MODALTYPES.BASIC_ACTION.ADD_SYS_GENERATED_TAGS,
        open: true,
        payload: {
          action_sure_msg: `Do You Want To Add System Generated Tags?`,
          action_warning_msg:
            "In This AI Automatically Adds Tags In Your Creative Groups",
          primaryBtnText: "Yes",
          secondaryBtnText: "Cancel",
          dangerMode: false,
          image: add_tags,
        },
      })
    );
  }

  function addSysGeneratedTagsModalPrimaryAction() {
    axiosOrgInstance
      .post(API_ROUTES.ONBOARDING.SKIP_MANUAL_TAGS, {
        groupEntity: GROUP_ENTITY.TEXT,
      })
      .then((res) => {
        toast.success("System Generated Tags Added Successfully");
        // navigate(APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS);
        initGroup();
      });
  }
  useEffect(() => {
    initGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    groupsList,
    totalDocs,
    initGroup,
    startTagging,
    handleSkipUpdate,
    addSysGeneratedTagsModalPrimaryAction,
  };
}
