import React from "react";

export default function GenerationIcon({ isActive }) {
  const color = isActive ? "#7167FB" : "#858383";
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 13.3125C4.6625 12.975 3.578 12.2655 2.7465 11.184C1.915 10.1025 1.4995 8.8745 1.5 7.5C1.5 5.825 2.08125 4.40625 3.24375 3.24375C4.40625 2.08125 5.825 1.5 7.5 1.5C8.875 1.5 10.1033 1.9155 11.1848 2.7465C12.2663 3.5775 12.9755 4.662 13.3125 6H11.7375C11.4125 5.1 10.8655 4.375 10.0965 3.825C9.3275 3.275 8.462 3 7.5 3C6.25 3 5.1875 3.4375 4.3125 4.3125C3.4375 5.1875 3 6.25 3 7.5C3 8.4625 3.275 9.32825 3.825 10.0972C4.375 10.8663 5.1 11.413 6 11.7375V13.3125ZM9 16.5C8.5875 16.5 8.23425 16.353 7.94025 16.059C7.64625 15.765 7.4995 15.412 7.5 15V9C7.5 8.5875 7.647 8.23425 7.941 7.94025C8.235 7.64625 8.588 7.4995 9 7.5H15C15.4125 7.5 15.7657 7.647 16.0597 7.941C16.3538 8.235 16.5005 8.588 16.5 9V15C16.5 15.4125 16.353 15.7657 16.059 16.0597C15.765 16.3538 15.412 16.5005 15 16.5H9ZM9 15H15V9H9V15ZM9.375 14.25H14.625L12.9 12L11.625 13.6875L10.725 12.45L9.375 14.25Z"
        fill={color}
        // fill-opacity="0.7"
      />
    </svg>
  );
}
