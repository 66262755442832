import React from "react";
import { useParams } from "react-router-dom";
import { ContentContainer, PrivateContainer } from "components/layouts";
import { APP_ROUTES } from "../../../routes";
import { Stack, Typography } from "@mui/material";

import MultipleValuesFilter from "components/common/atoms/CustomSelect/MultipleValuesFilter";

import { IconButtonWithTooltip } from "components/common/atoms";
import { DateFilter, InsightCard } from "components/common/molecules";
import {
  AppsRounded,
  TimelineOutlined,
  BarChartOutlined,
  TableChartOutlined,
} from "@mui/icons-material";
import { reportData } from "../reportData";

const Reports = () => {
  const { reportId } = useParams();
  const DateFilterOptions = [
    { label: "Today", value: "Today" },
    {
      label: "Last 7 days",
      value: "Last 7 days",
    },
    {
      label: "This Month",
      value: "This Month",
    },
  ];
  const isManualDateSelect = true;

  const currentReport = reportData.find((report) => report.id === reportId);

  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
      }}
      activePage={APP_ROUTES.INSIGHTS.default}
    >
      <Stack direction="column" spacing={3}>
        <Stack direction="column" spacing={3}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <Typography
                sx={{
                  fontSize: "22px",
                  lineHeight: "150%",
                  color: "#1C1E20",
                  fontWeight: "500",
                }}
              >
                Reports
              </Typography>
              <Stack direction="row">
                <Stack direction="row" spacing={1}>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      lineHeight: "15px",
                      color: "#1C1E20",
                      fontWeight: "700",
                    }}
                  >
                    Platform
                  </Typography>
                  <div>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        lineHeight: "15px",
                        color: "rgba(28, 30, 32, 0.7)",
                        fontWeight: "400",
                      }}
                    >
                      Meta Account
                    </Typography>
                    {/* <div>
                      <MetaIcon />
                    </div> */}
                  </div>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2}>
              <DateFilter
                options={DateFilterOptions}
                isManualDateSelect={isManualDateSelect}
              />
            </Stack>
          </Stack>
          <Stack direction="row">
            <Stack direction="row" gap={2} flexWrap={"wrap"}>
              <MultipleValuesFilter />
              <MultipleValuesFilter />
              <MultipleValuesFilter />
              <MultipleValuesFilter />
              <MultipleValuesFilter />
              <MultipleValuesFilter />
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction="column"
          sx={{
            borderRadius: "12px",
            border: "1px solid #EAECF0",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              padding: "16px",
              backgroundColor: "rgba(249, 249, 249, 1)",
            }}
          >
            <Stack direction="row" spacing={2}>
              <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                Select Metrics:
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <IconButtonWithTooltip iconTitle="Tile View" isActive={true}>
                <AppsRounded />
              </IconButtonWithTooltip>
              <IconButtonWithTooltip iconTitle="Line Chart">
                <TimelineOutlined />
              </IconButtonWithTooltip>
              <IconButtonWithTooltip iconTitle="Bar Chart">
                <BarChartOutlined />
              </IconButtonWithTooltip>
              <IconButtonWithTooltip iconTitle="Tabular Format">
                <TableChartOutlined />
              </IconButtonWithTooltip>
            </Stack>
          </Stack>
          <ContentContainer
            containerStyles={{
              maxHeight: "calc(100vh - 260px)",
              padding: "16px",
              marginTop: "0",
            }}
          >
            {currentReport.reportList.map((report) => (
              <InsightCard key={report.id} {...report} />
            ))}
          </ContentContainer>
        </Stack>
      </Stack>
    </PrivateContainer>
  );
};

export default Reports;
