import React from "react";
import { Button, Typography, Stack } from "@mui/material";

import { ContentContainer, PrivateContainer } from "components/layouts";
import { APP_ROUTES } from "routes";
import { TitleBar } from "components/common/molecules";
import {
  GenerationImageCard,
  GenerationContainer,
  GenerationPill,
} from "../../Components";
import useInsights from "screens/Insights/useInsights";

const generationCardData = [
  {
    id: "1",
    assetUrl: "https://picsum.photos/id/237/200/300",
  },
  {
    id: "2",
    assetUrl: "https://picsum.photos/id/237/200/300",
  },
  {
    id: "3",
    assetUrl: "https://picsum.photos/id/237/200/300",
  },
  {
    id: "4",
    assetUrl: "https://picsum.photos/id/237/200/300",
  },
  {
    id: "5",
    assetUrl: "https://picsum.photos/id/237/200/300",
  },
  {
    id: "6",
    assetUrl: "https://picsum.photos/id/237/200/300",
  },
  {
    id: "7",
    assetUrl: "https://picsum.photos/id/237/200/300",
  },
  {
    id: "8",
    assetUrl: "https://picsum.photos/id/237/200/300",
  },
  {
    id: "9",
    assetUrl: "https://picsum.photos/id/237/200/300",
  },
  {
    id: "10",
    assetUrl: "https://picsum.photos/id/237/200/300",
  },
  {
    id: "11",
    assetUrl: "https://picsum.photos/id/237/200/300",
  },
  {
    id: "12",
    assetUrl: "https://picsum.photos/id/237/200/300",
  },
];

export default function RecommendationScreen() {
  const { filterState, onFilterUpdate } = useInsights();

  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
        height: "auto",
        paddingBottom: "32px",
      }}
      activePage={APP_ROUTES.GENERATION.default}
    >
      <Stack direction="column" spacing={3} marginBottom={3}>
        <Stack direction="column" spacing={3}>
          <TitleBar
            title="Generation ✨"
            description="Create ad copy and headlines designed to boost conversions quickly."
          />
        </Stack>
      </Stack>

      <GenerationContainer>
        <Stack direction="column">
          <Stack direction="row" alignItems="flex-start">
            <GenerationPill label="Step 3 / 3" />
          </Stack>
          <ContentContainer
            containerStyles={{
              maxHeight: "calc(100vh - 300px)",
              padding: "0",
            }}
          >
            {generationCardData.map((item) => (
              <GenerationImageCard key={item.id} {...item} />
            ))}
          </ContentContainer>
        </Stack>
      </GenerationContainer>
    </PrivateContainer>
  );
}
