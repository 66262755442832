import { useReducer } from "react";
import { useDispatch } from "react-redux";
import {
  MODALTYPES,
  setModal,
} from "../../../../../../redux/slices/modal.slices";
import { useChangeRole } from "apis/common";
import useOrganisationDetail from "../ManageOrganisation/Components/OrganisationDetail/useOrganisationDetail";
import { useSelector } from "react-redux";

const columns = [
  {
    field: "email",
    headerName: "Email Address",
    width: 150,
  },

  {
    field: "invite_date",
    headerName: "Invite Date",
    width: 150,
  },

  {
    field: "user_role",
    headerName: "Role",
    width: 200,
  },
];

function createData(email, username, invite_date) {
  return { email, username, invite_date };
}

const rows = [
  createData("user1@email.com", "user1", "12/12/2021"),
  createData("user2@email.com", "user2", "12/12/2021"),
  createData("user3@email.com", "user3", "Pending"),
  createData("user4@email.com", "user4", "12/12/2021"),
];

const initialState = {
  rows: rows,
  columns: columns,
  order: "asc",
  orderBy: "invoice",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PAYMENT_HISTORY":
      return {
        ...state,
        paymentHistory: action.payload,
      };
    case "SET_PAYMENT_HISTORY_LOADING":
      return {
        ...state,
        paymentHistoryLoading: action.payload,
      };
    case "SET_PAYMENT_HISTORY_ERROR":
      return {
        ...state,
        paymentHistoryError: action.payload,
      };

    case "SET_ORDER":
      return {
        ...state,
        order: action.payload,
      };
    case "SET_ORDER_BY":
      return {
        ...state,
        orderBy: action.payload,
      };

    default:
      return state;
  }
};
export default function useManageUser() {
  const [state, setState] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    setState({ type: "SET_ORDER", payload: isAsc ? "desc" : "asc" });
    setState({ type: "SET_ORDER_BY", payload: property });
  };

  const { selectedOrganisation: reduxSelectedOrganisation } = useSelector(
    (state) => state?.organisation
  );

  const { users, isOrgUsersLoading, getOrgUsers } = useOrganisationDetail(
    reduxSelectedOrganisation
  );

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const onRemoveUser = (userId) => {
    dispatch(
      setModal({
        type: MODALTYPES.REMOVE_USER_MODAL,
        open: true,
        payload: { userId },
      })
    );
  };

  const { mutate: changeRole } = useChangeRole();
  const handleRoleChange = (payload) => {
    changeRole({
      userId: payload?.userId,
      type: payload?.type,
      refetchOrgUsers: payload?.refetchOrgUser,
    });
  };
  return {
    users,
    getOrgUsers,
    isOrgUsersLoading,
    state,
    createSortHandler,
    onRemoveUser,
    handleRoleChange,
  };
}
