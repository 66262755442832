import React, { useEffect, useState } from "react";
import { Resizable } from "react-resizable";
import "./resize.css";
import "react-resizable/css/styles.css";

const ResizableSidebar = ({ children, showNavbar }) => {
  const [width, setWidth] = useState(240);

  useEffect(() => {
    if (showNavbar) {
      setWidth(240);
    } else {
      setWidth(0);
    }
  }, [showNavbar]);

  return (
    <Resizable
      className="resizable-sidebar"
      width={width}
      height={Infinity}
      onResize={(e, { size }) => setWidth(size.width)}
      minConstraints={[240, Infinity]}
      maxConstraints={[500, Infinity]}
      resizeHandles={["e"]}
    >
      <div
        style={{
          width: `${width}px`,
          height: "100vh",
          overflow: "hidden",
          transition: !width || width === 240 ? "width 0.2s" : "width 0s",
        }}
      >
        {children}
      </div>
    </Resizable>
  );
};

export default ResizableSidebar;
