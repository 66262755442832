import { AddOutlined } from "@mui/icons-material";
import { Avatar, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Plus } from "assets/icons/react";
import { deleteIcon, information } from "assets/icons/svg";
import { ColorPallete } from "components/common/molecules";
import ContentContainer from "components/layouts/ContentContainer";
import useBrandColors from "./useBrandColors";

const NoDataTemplate = ({ addBrandPallete }) => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        flex: 1,
        paddingTop: "7%",
      }}
    >
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          gap: "6px",
        }}
      >
        <img
          src={information}
          alt=""
          style={{
            height: "20px",
            width: "20px",
          }}
        />
        <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
          You Have No Brand Color
        </Typography>
      </Stack>
      <Typography
        sx={{
          fontSize: "10px",
          fontWeight: "400",
          color: "#1C1E2080",
          textAlign: "center",
          textTransform: "capitalize",
          marginTop: "15px",
        }}
      >
        your brand color will appear here when you set it here
      </Typography>

      <ColorPallete isNewPallette addBrandPallete={addBrandPallete}>
        <Button
          variant="outlined"
          sx={{ marginTop: "25px", textTransform: "none" }}
        >
          Choose
        </Button>
      </ColorPallete>
    </Stack>
  );
};
const ColorCard = ({ pallete, color, deleteBrandColor }) => {
  return (
    <Stack
      sx={{
        minWidth: "162px",
        minHeight: "80px",
        maxHeight: "80px",
        maxWidth: "162px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "0.5px solid #A1B0CC",
        position: "relative",
        backgroundColor: color,
      }}
    >
      <Stack sx={{ width: "140px", height: "46px" }}>
        <Avatar
          sx={{
            margin: "5px",
            position: "absolute",
            right: "0px",
            top: "0px",
            cursor: "pointer",
            backgroundColor: "#FFFFFF",
          }}
        >
          <img
            src={deleteIcon}
            alt=""
            onClick={() => deleteBrandColor(pallete?._id, color)}
            style={{
              height: "15px",
              width: "15px",
            }}
          />
        </Avatar>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          height: "20px",
          backgroundColor: "#fff",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "400",

            textAlign: "center",
            textTransform: "capitalize",
          }}
        >
          {color}
        </Typography>
      </Stack>
    </Stack>
  );
};
const AddMoreColors = ({ addBrandPallete, pallete }) => {
  return (
    <ColorPallete addBrandPallete={addBrandPallete} currentPallete={pallete}>
      <Stack
        sx={{
          minWidth: "162px",
          minHeight: "80px",
          maxHeight: "80px",
          maxWidth: "162px",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          border: "0.5px solid #A1B0CC",
          position: "relative",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Stack
          sx={{
            width: "140px",
            height: "46px",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Plus color="#1C1E2080" />
        </Stack>
      </Stack>
    </ColorPallete>
  );
};

const BrandPalletsContainer = ({ children, deleteBrandPallete, pallete }) => {
  return (
    <Stack
      sx={{
        gap: "28px",
        width: "100%",
        flexWrap: "wrap",
        border: "1px solid #A1B0CC",
        padding: "18px 16px",
        borderRadius: "6px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            color: "#1C1E20",
          }}
        >
          {pallete?.colorPaletteName}
        </Typography>
        <img
          src={deleteIcon}
          alt=""
          onClick={() => deleteBrandPallete(pallete)}
          style={{
            height: "15px",
            width: "15px",
          }}
        />
      </Stack>
      <Stack sx={{ flexDirection: "row", gap: "28px", flexWrap: "wrap" }}>
        {children}
      </Stack>
    </Stack>
  );
};

const AddMoreButton = ({ addBrandPallete }) => {
  return (
    <ColorPallete isNewPallette addBrandPallete={addBrandPallete}>
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "107px",
          height: "91px",
          border: "0.5px solid #A1B0CC",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        <AddOutlined htmlColor="#1C1E2080" />
        <Typography
          sx={{
            fontSize: "10px",
            fontWeight: "400",
            color: "#1C1E2080",
          }}
        >
          Add More
        </Typography>
      </Stack>
    </ColorPallete>
  );
};

export default function BrandColors() {
  const {
    brandPallets,
    addBrandPallete,
    deleteBrandColor,
    deleteBrandPallete,
  } = useBrandColors();
  return (
    <ContentContainer
      containerStyles={{
        maxHeight: "65vh",
      }}
    >
      {!!brandPallets.length ? (
        <Stack
          sx={{
            gap: "32px",
            width: "100%",
          }}
        >
          <AddMoreButton addBrandPallete={addBrandPallete} />

          <Stack
            direction={"row"}
            sx={{
              gap: "28px",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {brandPallets.map((pallete) => (
              <BrandPalletsContainer
                pallete={pallete}
                deleteBrandPallete={deleteBrandPallete}
              >
                <AddMoreColors
                  addBrandPallete={addBrandPallete}
                  pallete={pallete}
                />
                {pallete?.colors.map((color) => (
                  <ColorCard
                    pallete={pallete}
                    color={color}
                    deleteBrandColor={deleteBrandColor}
                  />
                ))}
              </BrandPalletsContainer>
            ))}
          </Stack>
        </Stack>
      ) : (
        <NoDataTemplate addBrandPallete={addBrandPallete} />
      )}
    </ContentContainer>
  );
}
