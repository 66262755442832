import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { InputWithLabel } from "components/common/molecules";
import PublicContainer from "components/layouts/PublicContainer";

import { PillProgressIndicator } from "components/common/molecules";
import useAddOrg from "./useAddOrg";
import { useSelector } from "react-redux";
import business from "@/../assets/images/businessplan.svg";

const formStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "18px",
  width: "100%",
  height: "100%",
};

const bodyStyles = {
  overflow: "hidden",
};

export default function AddOrganisationScreen() {
  const {
    state,
    agencyState,
    handleAgencyChange,
    handleChange,
    handleSubmit,
    isValid,
    handleCancel,
  } = useAddOrg();
  const { user } = useSelector((state) => state?.auth?.userData);

  return (
    <PublicContainer bodyStyles={bodyStyles}>
      <div className="flex items-center justify-start w-full">
        <PillProgressIndicator currentStep={2} totalSteps={2} />
      </div>
      <section class="flex w-full h-full">
        <div class="xl:w-1/2">
          <div>
            <Typography
              variant="h2"
              sx={{ fontWeight: "700", marginTop: "48px" }}
            >
              Add Organization
            </Typography>

            {/* <p class="mt-4 text-gray-500">
              This will be your publically differentiable username on the
              platform. Choose what you like and suits your personality!
            </p> */}
            <form
              action="#"
              class="space-y-4 flex justify-center w-full max-h-[50vh] overflow-auto mt-4 "
            >
              {user?.accountType === "Agency" ? (
                <div className="login-screen__form" style={formStyles}>
                  <InputWithLabel
                    label="Name of Brand/Organization?"
                    name="name"
                    placeholder="Type Here"
                    onChange={handleAgencyChange}
                    value={agencyState.name}
                  />
                  <InputWithLabel
                    label="Name of Agency?"
                    name="agencyName"
                    placeholder="Type Here"
                    onChange={handleAgencyChange}
                    value={agencyState.agencyName}
                  />
                  <InputWithLabel
                    label="What is the website of your agency?"
                    type="string"
                    name="website"
                    placeholder="Type Here"
                    onChange={handleAgencyChange}
                    value={agencyState.website}
                  />
                  <InputWithLabel
                    label="What is the Team size?"
                    type="string"
                    name="teamSize"
                    placeholder="Type Here"
                    onChange={handleAgencyChange}
                    value={agencyState.teamSize}
                  />
                  <InputWithLabel
                    label="Do you do creatives in-house?"
                    type="string"
                    name="inHouseCreatives"
                    placeholder="Type Here"
                    onChange={handleAgencyChange}
                    value={agencyState.inHouseCreatives}
                  />
                  <FormControl
                    sx={{ mt: "20px", minWidth: "40%" }}
                    size="medium"
                  >
                    <InputLabel id="demo-select-small-label">
                      Number of Active Clients?
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={agencyState?.activeClients}
                      name="activeClients"
                      label="Number of active clients"
                      onChange={handleAgencyChange}
                    >
                      <MenuItem value={"<5"}>{"<5"}</MenuItem>
                      <MenuItem value={"5-15"}>{"5 - 15"}</MenuItem>
                      <MenuItem value={"15-50"}>{"15-50"}</MenuItem>
                      <MenuItem value={">50"}>{">50"}</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ mt: "20px", minWidth: "40%" }}
                    size="medium"
                  >
                    <InputLabel id="demo-select-small-label">
                      Monthly Adspend managed across all clients on Meta?
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={agencyState?.adSpend}
                      name="adSpend"
                      label="How much do you spend on Ads in a month on Meta"
                      onChange={handleAgencyChange}
                    >
                      <MenuItem value={"<$50,000"}>{"<$50,000"}</MenuItem>
                      <MenuItem value={"$50,000 - $250,000"}>
                        $50,000 - $250,000
                      </MenuItem>
                      <MenuItem value={"$250,000-$1,000,0000"}>
                        $250,000-$1,000,0000
                      </MenuItem>
                      <MenuItem value={">$1,000,000"}>{">$1,000,000"}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              ) : (
                <div className="login-screen__form" style={formStyles}>
                  <InputWithLabel
                    label="Name Of The Brand"
                    name="name"
                    placeholder="Type Here"
                    onChange={handleChange}
                    value={state.name}
                  />
                </div>
              )}
            </form>
            {/* <Button
              className={`${
                uname ? "bg-[#7167FB] text-white" : ""
              } mt-10 flex justify-center w-full max-w-md`}
              variant="secondary"
              onClick={handleSubmit}
            >
              Submit
            </Button> */}
            <div
              style={{
                marginTop: "50px",
                display: "flex",
                justifyContent: "flex-end",
                width: "33%",
                minWidth: "488px",
                gap: "20px",
              }}
            >
              <Button
                onClick={handleCancel}
                sx={{
                  textTransform: "none !important",
                }}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                sx={{
                  textTransform: "none !important",
                }}
                disabled={!isValid}
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </div>
          </div>
        </div>

        <div class="relative hidden h-64 w-full sm:h-96 lg:h-full lg:block ">
          <img alt="" src={business} className="h-full w-full object-contain" />
        </div>
      </section>
    </PublicContainer>
  );
}
