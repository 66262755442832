import React from "react";

const Suggested = ({ isActive }) => {
  const color = isActive ? "#7167FB" : "#858383";
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5627 0.339942C18.4189 0.242054 18.2521 0.183293 18.0787 0.169442C17.9053 0.15559 17.7313 0.187124 17.5737 0.260942L0.573737 8.26094C0.399965 8.34283 0.253455 8.47303 0.151727 8.63598C0.0499993 8.79893 -0.00264053 8.98774 0.00010195 9.17982C0.00284443 9.3719 0.0608533 9.55912 0.167192 9.7191C0.27353 9.87908 0.423698 10.0051 0.599737 10.0819L5.99974 12.4449V19.1669L11.8357 14.9989L16.5997 17.0829C16.7468 17.1474 16.9072 17.1754 17.0675 17.1647C17.2277 17.154 17.3829 17.1049 17.5202 17.0216C17.6574 16.9382 17.7725 16.823 17.8558 16.6857C17.9391 16.5485 17.9881 16.3932 17.9987 16.2329L18.9987 1.23294C19.0097 1.05907 18.9753 0.885335 18.8988 0.728782C18.8224 0.572228 18.7066 0.438233 18.5627 0.339942ZM16.0967 14.6799L10.8277 12.3739L13.9997 6.16694L6.35074 10.4169L3.41874 9.13394L16.8897 2.79394L16.0967 14.6799Z"
        fill={color}
      />
    </svg>
  );
};

export default Suggested;
