import React from "react";
import PrivateContainer from "../../../components/layouts/PrivateContainer";
import AITagged from "./AITagged/AITagged";
import { useLocation } from "react-router-dom";

export default function AudienceTaggingScreen() {
  const { pathname } = useLocation();
  return (
    <PrivateContainer
      activePage={pathname}
      bodyStyles={{ justifyContent: "flex-start", padding: "24px" }}
    >
      <AITagged />
    </PrivateContainer>
  );
}
