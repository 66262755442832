export const FILTER_MAPPING = [
  {
    label: "Name",
    subCategories: [
      {
        label: "Ad name",
        dataKey: "adName",
        type: "input",
        tooltip:
          "To filter ads with a specific name, click here. (This might come in handy when you're using the same ad across different campaigns/audiences)",
      },
      {
        label: "Ad set name",
        dataKey: "adSetName",
        type: "input",
        tooltip:
          "To filter out creative analytics for specific ad sets. (This might come in handy when you're conducting an a/b test manually)",
      },
      {
        label: "Campaign name",
        dataKey: "campaignName",
        type: "input",
        tooltip:
          "To filter out creative analytics for specific campaigns. (This might come in handy when you have different campaigns with different objectives/KPIs)",
      },
    ],
  },
  {
    label: "Campaign Setup",
    subCategories: [
      // {
      //   label: "Campaign Type",
      //   dataKey: "campaignType",
      //   type: "dropdown",
      //   isStatic: true,
      // },
      {
        label: "Campaign Objective",
        dataKey: "objectives",
        type: "dropdown",
        isStatic: false,
        tooltip:
          "Filter by specific objectives. (This is useful when you have several different objectives in the ad account like sales, awareness, leads and more.)",
      },
      {
        label: "Campaign Goal",
        dataKey: "optimizationGoals",
        type: "dropdown",
        isStatic: false,
        tooltip:
          "Filter by specific goals. (This will come in handy when you have different campaign goals under the same objective like instant forms, calls and more.)",
      },
    ],
  },
  {
    label: "Ad Setup",
    tooltip: "Filter campaigns by specific ads.",
    subCategories: [
      {
        label: "Ad Type",
        dataKey: "adType",
        type: "dropdown",
        isStatic: true,
        tooltip:
          "Filter campaigns by specific types of ads. (This could be carousels, DCOs and more)",
      },
      // {
      //   label: "Ad Distribution Format",
      //   dataKey: "adDistributionFormat",
      //   type: "dropdown",
      //   isStatic: true,
      // },
      {
        label: "Ad Status",
        dataKey: "adStatus",
        type: "dropdown",
        isStatic: true,
        tooltip:
          "Filter out campaigns by their status. (This could be active, inactive and error.)",
      },
    ],
  },
  {
    label: "Demographics / Audiences",
    tooltip: "Filter out campaigns by the audience they're targeting.",
    subCategories: [
      {
        label: "Location",
        dataKey: "targetingCountries",
        type: "dropdown",
        isStatic: true,
        tooltip: "Filter out campaigns by the regions you're targeting.",
      },
      {
        label: "Funnel Stage",
        dataKey: "funnelStages",
        type: "dropdown",
        isStatic: false,
        tooltip:
          "Filter campaigns by the funnel stage. (This could be top -of-funnel, middle-of-funnel and more.)",
      },
      {
        label: "Audience Segment",
        dataKey: "audienceSegment",
        type: "dropdown",
        isStatic: true,
        tooltip:
          "Filter campaigns by the audiences segments you've created on Storyteller. (This will allow you to see how certain communications strategies are working for certain audiences.)",
      },
      // {
      //   label: "Device Type",
      //   dataKey: "deviceType",
      //   type: "dropdown",
      //   isStatic: true,
      // },
    ],
  },
  {
    label: "Creative Setup",
    tootlip:
      "Filter out specific creatives you want to look at in Storyteller.",
    subCategories: [
      {
        label: "Creative Format",
        dataKey: "creativeTypes",
        type: "dropdown",
        isStatic: false,
        tooltip: "Filter out creatives by image or videos.",
      },
      {
        //key nahi thi
        label: "Creative Tags",
        dataKey: "keywords",
        type: "input",
        isStatic: false,
        tooltip:
          "Filter out creatives by the tags assigned to them by Storyteller or yourself manually.",
      },
    ],
  },
];

export const FILTER_CATEGORY_REVERSE_MAPPING = {
  adName: {
    type: "input",
    isStatic: true,
    label: "Ad name",
  },
  adSetName: {
    type: "input",
    isStatic: true,
    label: "Ad set name",
  },
  campaignName: {
    type: "input",
    isStatic: true,
    label: "Campaign name",
  },
  campaignType: {
    type: "dropdown",
    isStatic: true,
    label: "Campaign Type",
    options: [
      {
        label: "Pratik",
        value: "pratik",
      },
      {
        label: "Pratik Tiwari",
        value: "pratikTiwari",
      },
    ],
  },
  objectives: {
    type: "dropdown",
    isStatic: true,
    label: "Campaign Objective",
    options: [
      {
        label: "Awareness",
        value: "awareness",
      },
      {
        label: "Consideration",
        value: "consideration",
      },
      {
        label: "Conversion",
        value: "conversion",
      },
    ],
  },
  optimizationGoals: {
    type: "dropdown",
    isStatic: true,
    label: "Campaign Goal",
    options: [
      {
        label: "Traffic",
        value: "traffic",
      },
      {
        label: "Engagement",
        value: "engagement",
      },
      {
        label: "Conversions",
        value: "conversions",
      },
      {
        label: "Reach",
        value: "reach",
      },
      {
        label: "Brand Awareness",
        value: "brandAwareness",
      },
    ],
  },
  adType: {
    type: "dropdown",
    isStatic: true,
    label: "Ad Type",
    options: [
      {
        label: "Carousal",
        value: "CAROUSAL",
      },
      {
        label: "DCO",
        value: "DCO",
      },
      {
        label: "Catalog",
        value: "CATALOG",
      },
      {
        label: "Page post",
        value: "PAGE_POST",
      },
      {
        label: "Single media asset",
        value: "SINGLE_MEDIA",
      },
    ],
  },
  adDistributionFormat: {
    type: "dropdown",
    isStatic: true,
    label: "Ad Distribution Format",
    options: [
      {
        label: "Dynamic creative",
        value: "dynamicCreative",
      },
      {
        label: "Page post",
        value: "pagePost",
      },
    ],
  },
  adStatus: {
    type: "dropdown",
    isStatic: true,
    label: "Ad Status",
    options: [
      {
        label: "Active",
        value: "active",
      },
      {
        label: "Inactive",
        value: "inactive",
      },
      {
        label: "Error",
        value: "error",
      },
    ],
  },
  targetingCountries: {
    type: "dropdown",
    isStatic: false,
    label: "Location",
  },
  funnelStages: {
    type: "dropdown",
    isStatic: false,
    label: "Funnel Stage",
  },
  audienceSegment: {
    type: "dropdown",
    isStatic: false,
    label: "Audience Segment",
    isSearchBased: true,
  },
  deviceType: {
    type: "dropdown",
    isStatic: true,
    label: "Device Type",
    options: [
      {
        label: "Pratik",
        value: "pratik",
      },
      {
        label: "Pratik Tiwari",
        value: "pratikTiwari",
      },
    ],
  },
  creativeTypes: {
    type: "dropdown",
    isStatic: false,
    label: "Creative Format",
  },
  keywords: {
    type: "input",
    isStatic: false,
    label: "Creative Tags",
  },
};
