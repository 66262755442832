import React from "react";
import { Stack, Button } from "@mui/material";
import { ContentContainer, MainContainer } from "components/layouts";
import { GrayHeader, PredictionTaggingCard } from "../../Components";
import { useDispatch, useSelector } from "react-redux";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { ImageTaggingModal, VideoTaggingModal } from "components/modals";
import { BlurredBgBtnWIcon } from "components/common/atoms";
import {
  Edit,
  PlayCircleOutline,
  Image as ImageIcon,
} from "@mui/icons-material";
import usePredictionStep2 from "./usePredictionStep2";
import { storageUrlToCdn } from "utils/helper";

function PredictionStep2() {
  const { open, type } = useSelector((state) => state?.modal);
  const dispatch = useDispatch();

  const { predictionMedias, setManualTaggingCompleted } = usePredictionStep2();

  const handleEditTags = (asset) => {
    let meta = JSON.parse(asset.meta);
    const payload = {
      groupName: meta?.fileName,
      mediaId: asset?.id,
    };

    if (asset.type === "IMAGE") {
      payload.imageUrl = asset?.thumbnailUrl;
    } else {
      payload.videoUrl = asset?.thumbnailUrl;
    }

    dispatch(
      setModal({
        type:
          asset?.type === "IMAGE"
            ? MODALTYPES.IMAGE_TAGGING_MODAL
            : MODALTYPES.VIDEO_TAGGING_MODAL,
        open: true,
        payload,
      })
    );
  };

  return (
    <>
      <MainContainer header={<GrayHeader step={2} />}>
        <ContentContainer
          containerStyles={{
            maxHeight: "calc(100vh - 300px)",
            padding: "0 16px 32px 16px",
            marginTop: "0",
          }}
        >
          {predictionMedias?.map((item) => {
            let type = item?.type === 2 ? "IMAGE" : "VIDEO";
            let thumbnail = item?.slug;
            return (
              <PredictionTaggingCard
                thumbnail={storageUrlToCdn(thumbnail)}
                type={type}
                key={item.id}
                TypePill={
                  <BlurredBgBtnWIcon
                    Icon={
                      type === "IMAGE" ? (
                        <ImageIcon sx={{ fontSize: "20px" }} />
                      ) : (
                        <PlayCircleOutline sx={{ fontSize: "20px" }} />
                      )
                    }
                    sx={{ top: "8px", left: "8px" }}
                    title={type}
                  />
                }
                EditTagsBtn={
                  <BlurredBgBtnWIcon
                    Icon={<Edit sx={{ fontSize: "20px" }} />}
                    sx={{
                      top: "8px",
                      right: "8px",
                    }}
                    title="Edit Tags"
                    isClickable
                    onClick={() =>
                      handleEditTags({
                        ...item,
                        type,
                        thumbnailUrl: thumbnail,
                      })
                    }
                  />
                }
              />
            );
          })}
        </ContentContainer>

        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ paddingTop: "8px" }}
        >
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={() => setManualTaggingCompleted()}
          >
            Manual Tagging Complete
          </Button>
        </Stack>
      </MainContainer>

      {open && type === MODALTYPES.IMAGE_TAGGING_MODAL && (
        <ImageTaggingModal postOnboarding={true} />
      )}
      {open && type === MODALTYPES.VIDEO_TAGGING_MODAL && (
        <VideoTaggingModal postOnboarding={true} />
      )}
    </>
  );
}

export default PredictionStep2;
