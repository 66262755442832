import React from "react";

export default function RecommendationIcon({ isActive }) {
  const color = isActive ? "#7167FB" : "#858383";
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75.25c-.108-.074-.62.01-.75 0-.13-.011.118-.056 0 0l-12.75 6c-.13.06-.674-.123-.75 0-.076.121-.002.605 0 .75.002.143-.08.63 0 .75s.618-.058.75 0L5 9.25v5.25l4.5-3 3.75 1.5c.11.047-.12.007 0 0 .12-.009.647.062.75 0 .103-.063-.062.102 0 0 .063-.104-.008-.63 0-.75L14.75 1c.008-.131.057.117 0 0-.057-.118.108-.677 0-.75ZM12.5 11.5 8.75 9.25 11 4.75l-6 3L2.75 7l10.5-4.5-.75 9Z"
        fill={color}
      />
    </svg>
  );
}
