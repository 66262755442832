import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@/components/ui/button";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  Dialog,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "../../../redux/slices/modal.slices";
import { useInviteUserToOrg } from "apis/common";
import useUserRole from "hooks/useUserRole";

const ROLES_MAPPING = [
  { label: "Super admin", value: "SA" },
  { label: "Admin", value: "A" },
  { label: "Employee", value: "U" },
];

const SA_ROLE_OPTIONS = [
  { label: "Super admin", value: "SA" },
  { label: "Admin", value: "A" },
  { label: "Employee", value: "U" },
];

const A_ROLE_OPTIONS = [
  { label: "Admin", value: "A" },
  { label: "Employee", value: "U" },
];

const U_ROLE_OPTIONS = [
  { label: "Admin", value: "A" },
  { label: "Employee", value: "U" },
];

const REVERSE_ROLES_MAPPING = {
  [ROLES_MAPPING[0]?.value]: ROLES_MAPPING[0]?.label,
  [ROLES_MAPPING[1]?.value]: ROLES_MAPPING[1]?.label,
  [ROLES_MAPPING[2]?.value]: ROLES_MAPPING[2]?.label,
};

export default function InviteUserModal(props) {
  const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [userRole, setUserRole] = useState("U");

  const { userRole: reduxUserRole } = useUserRole();

  const [errors, setErrors] = useState({
    name: false,
    email: false,
  });

  function handleClose() {
    dispatch(setModal(modalInitState));
    setName("");
    setEmail("");
    setUserRole("U");
  }

  const { mutate: addUser, isLoading: isAddinguser } = useInviteUserToOrg(
    props?.getOrgUsers
  );

  const regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validateEmail = (emailVal) => regEmail.test(emailVal);
  const validateName = (nameVal) => nameVal.length >= 4;

  function setError(fieldName, isError) {
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: isError }));
  }

  function isEmail(val) {
    if (!validateEmail(val)) {
      setError("email", true);
      return false;
    }
    setError("email", false);
    return true;
  }

  function isName(val) {
    if (!validateName(val)) {
      setError("name", true);
      return false;
    }
    setError("name", false);
    return true;
  }

  function addUserToOrganisation() {
    const validName = isName(name);
    const validEmail = isEmail(email);

    if (!validName || !validEmail) {
      return;
    } else {
      const payload = {
        userName: name,
        userEmail: email,
        role: userRole,
      };
      addUser(payload);
    }
  }

  function getRolesOptions() {
    switch (reduxUserRole) {
      case "SA":
        return SA_ROLE_OPTIONS;
      case "A":
        return A_ROLE_OPTIONS;
      default:
        return U_ROLE_OPTIONS;
    }
  }

  return (
    <Dialog
      sx={{ padding: "40px" }}
      className="z-[9990]"
      open={modal?.open && modal?.type === MODALTYPES.INVITE_USER_MODAL}
      onOpenChange={handleClose}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Member</DialogTitle>
          <DialogDescription>
            Add New User To Your Organization And Explore The World Of
            Storytelling.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="">
              Name
            </Label>
            <Input
              id="name"
              onChange={(e) => setName(e?.target?.value)}
              className="col-span-3"
            />
            <div className="col-span-3 flex justify-end -mt-2 ml-4">
              {errors?.name && (
                <span className="col-span-3 text-xs text-red-500 ml-2">
                  *Enter valid 4 charcter long name
                </span>
              )}
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="">
              Email
            </Label>
            <Input
              id="name"
              onChange={(e) => setEmail(e?.target?.value)}
              className="col-span-3"
            />
            <div className="col-span-3 flex justify-end w-[85%] -mt-2">
              {errors?.email && (
                <span className="col-span-3 text-xs text-red-500">
                  *Enter valid email address
                </span>
              )}
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="">
              Role
            </Label>
            <Select onValueChange={(val) => setUserRole(val)}>
              <SelectTrigger className="w-max">
                <SelectValue
                  placeholder={
                    userRole ? REVERSE_ROLES_MAPPING[userRole] : "Select a role"
                  }
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {getRolesOptions()?.map((role) => (
                    <SelectItem value={role?.value}>{role?.label}</SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
        <DialogFooter>
          {isAddinguser ? (
            <Button disabled={true}>Inviting...</Button>
          ) : (
            <Button disabled={!name || !email} onClick={addUserToOrganisation}>
              Invite
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
