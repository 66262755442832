import { useGetUploadUrls } from "apis/common";
import { useCreatePrediction, useGetPredictions } from "apis/predictionApi";
import axios from "axios";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "redux/slices/app.slices";

export default function usePredictionStep1() {
  const [predictionPaginatedData, setPredictionPaginatedData] = useState([]);
  const { mutateAsync: handleFilesUpload } = useGetUploadUrls();
  const { mutateAsync: createPrediction } = useCreatePrediction();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const createPredictionPayloadRef = useRef([]);
  const [reportName, setReportName] = useState("");
  const [savePredictionModalOpen, setSavePredictionModalOpen] = useState(false);
  const currentPageRef = useRef(1);
  const totalPagesRef = useRef(1);
  const hasNextPage = currentPageRef?.current < totalPagesRef?.current;
  const { mutateAsync: getPredictionList } = useGetPredictions(
    currentPageRef?.current
  );
  const { isLoadMore } = useInfiniteScroll("content-container", hasNextPage);
  const dispatch = useDispatch();
  const platforms = useSelector(
    (state) => state?.organisation?.selectedOrganisation?.platforms
  );

  function onDeleteFile(file) {
    const newFiles = uploadedFiles.filter((f) => f.path !== file.path);
    setUploadedFiles(newFiles);
  }

  function getUploadUrls() {
    const fileNames = [];
    const extensions = [];
    const path = "predictions";
    const n = uploadedFiles.length;
    uploadedFiles.forEach((file) => {
      fileNames.push(file.name);
      extensions.push(file.name.split(".").pop());
    });
    const payload = {
      fileNames,
      extensions,
      path,
      n: n.toString(),
    };

    let mediaAssets = [];
    handleFilesUpload(payload).then((res) => {
      const urls = res?.map((item) => item?.putSignedUrl);
      const slugs = res?.map((item) => item?.key);
      //perform upload of the files to these urls
      const promises = [];
      uploadedFiles.forEach((file, index) => {
        dispatch(setLoading(true));
        promises.push(
          axios
            .put(urls[index], file, {
              headers: {
                "Content-Type": file.type,
              },
            })
            .then(() => {
              let data = {
                slug: slugs[index],
                type: file.type === "video/mp4" ? 4 : 2,
                meta: {
                  fileName: file.name,
                  mimeType: file.type,
                  size: `${file.size}`,
                },
              };
              mediaAssets.push(data);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              dispatch(setLoading(false));
            })
        );
      });
      Promise.all(promises).then(() => {
        createPredictionPayloadRef.current = {
          name: reportName,
          mediaAssets,
        };
        setSavePredictionModalOpen(true);
        // currentPageRef.current = 1;
        // getPredictionList(currentPageRef?.current).then((res) => {
        //   let data = res?.data;
        //   if (data) {
        //     setPredictionPaginatedData(data);
        //   }
        //   const _totalPages = res?.meta?.pagination?.totalPages;
        //   if (_totalPages) {
        //     totalPagesRef.current = _totalPages;
        //   }
        // });
      });
    });
  }

  function ResetFields() {
    setUploadedFiles([]);
    setReportName("");
    setSavePredictionModalOpen(false);
  }
  function handleCreatePrediction() {
    const platformIds = platforms.map((i) => i._id);
    const payload = {
      ...createPredictionPayloadRef.current,
      name: reportName,
      platformIds,
    };

    createPrediction(payload).finally(() => {
      ResetFields();
      refreshPredictionList();
    });
  }

  function refreshPredictionList() {
    const page = 1;
    getPredictionList(page).then((res) => {
      let data = res?.data;
      if (data) {
        setPredictionPaginatedData(data);
        currentPageRef.current = page;
      }
    });
  }

  useEffect(() => {
    currentPageRef.current = 1;
    getPredictionList(currentPageRef?.current).then((res) => {
      let data = res?.data;
      if (data) {
        setPredictionPaginatedData(data);
      }
      const _totalPages = res?.meta?.pagination?.totalPages;
      if (_totalPages) {
        totalPagesRef.current = _totalPages;
      }
    });
  }, [getPredictionList]);

  useEffect(() => {
    if (isLoadMore) {
      if (currentPageRef.current >= totalPagesRef.current) return;
      getPredictionList(currentPageRef?.current + 1).then((res) => {
        let data = res?.data;
        currentPageRef.current = currentPageRef.current + 1;
        if (data) {
          setPredictionPaginatedData((prev) => [...prev, ...data]);
        }
      });
    }
  }, [getPredictionList, isLoadMore]);

  return {
    predictionReports: predictionPaginatedData,
    refreshPredictionList,
    handleFilesUpload,
    uploadedFiles,
    setUploadedFiles,
    onDeleteFile,
    getUploadUrls,
    reportName,
    setReportName,
    savePredictionModalOpen,
    setSavePredictionModalOpen,
    handleCreatePrediction,
  };
}
