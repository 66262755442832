import { Clear } from "@mui/icons-material";
import { Chip } from "@mui/material";
import React from "react";

const chipStyles = {
  backgroundColor: "#DAD7FE",
  padding: "2px",
  borderRadius: "4px",
  marginRight: "2px",
  color: "#5E48E8",
  margin: "4px 2px",
};

export const Tags = (props) => {
  const { onDelete, tag, sx, onClick } = props;

  function handleDelete() {
    onDelete && onDelete(tag);
  }

  return (
    <Chip
      sx={{ ...chipStyles, ...sx }}
      label={tag.name}
      {...(onDelete && { onDelete: () => handleDelete(tag) })}
      deleteIcon={
        <Clear
          sx={{
            height: "16px",
            width: "16px",
            fill: "#5E48E8",
          }}
        />
      }
      onClick={onClick}
    />
  );
};
