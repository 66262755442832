import { Card } from "@mui/material";
import React, { useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

const CustomXAxisLabel = ({ x, y, payload }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseOver = () => {
    setShowTooltip(true);
  };

  const handleMouseOut = () => {
    setShowTooltip(false);
  };
  return (
    <text
      x={x}
      y={y}
      dy={20}
      dx={-10}
      fill="#666"
      fontSize={12}
      textAnchor="start"
      transform={`rotate(60, ${x}, ${y})`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {payload.value}
      {showTooltip && <title>{payload.value}</title>}
    </text>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: "white",
          padding: 10,
          border: 1,
          borderRadius: 5,
        }}
      >
        <p>{`Category: ${data.label}`}</p>
        <p>{`Impact: ${data.impact}`}</p>
        <p>{`Impression: ${data.impression}`}</p>
      </div>
    );
  }
  return null;
};

const processCategories = (categories) => {
  return categories.map((category) => ({
    key: category.key,
    impact: category.impact,
    color: category.impact < 0 ? "red" : "green",
    impression: category.impression,
    label: category.label,
  }));
};

const PositiveAndNegativeBarChart = ({ categories }) => {
  const chartData = processCategories(categories);
  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart
        data={chartData}
        margin={{
          top: 5,
          left: -20,
          right: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="label"
          interval={0}
          tick={<CustomXAxisLabel />}
          height={80}
        />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <ReferenceLine y={0} stroke="#000" />

        <Bar dataKey="impact">
          {chartData.map((entry, index) => (
            <Cell key={index} fill={entry.color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PositiveAndNegativeBarChart;
