import React from "react";

const Tick = () => {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.336 0.998399L5.4272 9.6368L0 5.1136L1.024 3.8848L5.1968 7.3616L11.088 0L12.336 0.998399Z"
        fill="black"
      />
    </svg>
  );
};

export default Tick;
