import React from "react";
import { Typography } from "@mui/material";
import useCurrentUrl from "../../../hooks/useCurrentUrl";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../routes";

const styles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "11%",
  width: "100%",
  border: "1px solid #A1B0CC",
  padding: "0 6.25%",
};

const LoginOrSignupMessage = () => {
  const [currentUrl] = useCurrentUrl();
  const navigate = useNavigate();

  if (currentUrl === "/signup") {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6">Already Have An Account? </Typography>
        &nbsp;
        <Typography
          variant="h4"
          color="secondary"
          style={{ cursor: "pointer", lineHeight: 0 }}
          onClick={() => navigate(APP_ROUTES.AUTH.LOGIN)}
        >
          Login
        </Typography>
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6"> Don't Have An Account? </Typography>
        &nbsp;
        <Typography
          variant="h4"
          color="secondary"
          style={{ cursor: "pointer", lineHeight: 0 }}
          onClick={() => navigate(APP_ROUTES.AUTH.SIGNUP)}
        >
          Signup
        </Typography>
      </div>
    );
  }
};

export default function PublicNavbar({ showMessage = true }) {
  const [currentUrl] = useCurrentUrl();
  const navigate = useNavigate();
  const isLoginSignup =
    currentUrl === "/login" || currentUrl === "/signup" || currentUrl === "/";
  return (
    <div style={styles}>
      <div style={{ padding: "24px 0" }}>
        <img
          src="/logoNavbar.svg"
          alt="logo"
          onClick={() => navigate(APP_ROUTES.AUTH.LOGIN)}
          style={{ cursor: "pointer" }}
        />
      </div>
      {showMessage && isLoginSignup && <LoginOrSignupMessage />}
    </div>
  );
}
