import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import useAppState from "../hooks/useAppState";
import { APP_ROUTES } from "../routes";

export default function PostOnboardingRoute({ children }) {
  const { organisationOnBoarded } = useAppState();
  const [isOrgBoarded, setIsOrgBoarded] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsOrgBoarded(organisationOnBoarded);
    }, 1000); // wait 1 second before assuming onboarding is complete

    return () => clearTimeout(timeoutId);
  }, [organisationOnBoarded]);

  if (!isOrgBoarded) {
    return <Navigate to={APP_ROUTES.AUTH.LOGIN} />;
  }

  return <>{children}</>;
}
