import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { convertToTitleCase } from "utils/helper";

export default function HorizontalValueSelect(props) {
  const {
    selectStyles = {},
    optionStyles = {},
    options = [],
    label = "label",
    onSelect,
    key,
    selectedValue,
  } = props;
  const [value, setValue] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(selectedValue || options?.[0]);
  }, []);

  return (
    <FormControl sx={{ minWidth: "10%" }}>
      <InputLabel
        sx={{
          color: "#A1B0CC",
          flexDirection: "row",
          gap: "4px",
          backgroundColor: "#FFF",
        }}
        id={key}
      >
        {label}
      </InputLabel>
      <Select
        sx={{
          height: "36px",
          minWidth: "max-content",
          ...selectStyles,
        }}
        defaultValue={options?.[0]}
        autoWidth
        value={selectedValue || value}
        onChange={(event) => {
          const value = event.target.value;
          if (value?.length === 0) {
            return toast("Minimum one filter is required");
          }
          setValue(value);

          onSelect && onSelect(value);
        }}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        renderValue={(value) => (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "700",
            }}
            color="primary"
          >
            <span
              style={{
                width: "1px",
                fontSize: "10px",
                fontWeight: "400",
                color: "#1C1E20",
                textTransform: "uppercase",
                // marginLeft: "8px",
              }}
            >
              {convertToTitleCase(value?.label)}
            </span>
          </Typography>
        )}
        displayEmpty
      >
        {options.map((option, index) => {
          if (option === "custom")
            return (
              //disable click away listener

              <MenuItem
                key={index}
                value={option}
                sx={{ ...optionStyles }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {option}
              </MenuItem>
            );

          return (
            <MenuItem
              key={index}
              value={option}
              sx={{
                ...optionStyles,
                ...(JSON.stringify(value) === JSON.stringify(option) && {
                  backgroundColor: "#F3F4F6",
                }),
              }}
              selected={JSON.stringify(value) === JSON.stringify(option)}
            >
              {convertToTitleCase(option?.label)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
