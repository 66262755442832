import { useEffect } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AuthScreens, AuthStatusesScreens } from "./screens/Auth";
import GroupAnalytics from "./screens/GroupAnalytics/GroupAnalytics";
import {
  OnboardingScreens,
  OnboardingStatusesScreens,
} from "./screens/Onboarding";
import appTheme from "./utils/theme";

import useAppState from "./hooks/useAppState";
import { API_ROUTES, APP_ROUTES } from "./routes";
import { CustomRoute } from "./routing/AuthRoute/AuthRoute";
import PostOnboardingRoute from "./routing/PostOnboardingRoute";
import Logout from "./screens/Auth/Logout";
import LoadingScreen from "./screens/Onboarding/StatusesScreens/LoadingScreen";
import Setting from "./screens/Settings";
import TaggingScreens from "./screens/TaggingProcess";

import BrandAssetsScreen from "./screens/BrandAssets/BrandAssetsScreen";
import GroupingTagging from "./screens/GroupingTagging/GroupingTagging";
import UngroupedImages from "./screens/GroupingTagging/Ungrouped/UngroupedImages";
import UngroupedVideos from "./screens/GroupingTagging/Ungrouped/UngroupedVideos";
import Prediction from "./screens/Prediction";
import UpgradePlan from "./screens/UpgradePlan";

import UntaggedAudience from "./screens/GroupingTagging/Ungrouped/UntaggedAudience/UntaggedAudience";
import UnTaggedText from "./screens/GroupingTagging/Ungrouped/UntaggedText/UnTaggedText";

import PredictionReportDetails from "screens/Prediction/PredictionReportDetails";
import Reports from "screens/Reports";
import ReportDetails from "screens/Reports/ReportDetails";
import Insights from "../src/screens/Insights";
import {
  GenerationStepOne,
  GenerationStepThree,
  GenerationStepTwo,
} from "./screens/Generation/GenerationSteps";
import { AddAdAccount } from "./screens/Settings/Tabs/OrganisationSettings/Components/ManageOrganisation/Components";

import { COMMON } from "apis/apiEndPoints";
import { useGetOrgFolders, useGetOrganizationTags } from "apis/common";
import { useGetReports } from "apis/reportsApi";
import Notification from "components/common/notifications/Notification";
import useAuth from "hooks/useAuth";
import useAxios from "hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { setGroupIds } from "redux/slices/app.slices";
import { setOrganisationToken } from "redux/slices/organisation.slice";
import Dashboard from "screens/Auth/LoginScreen/dashboard/Dashboard";
import Leadsie from "screens/Auth/SignupScreen/Steps/leadsie/Leadsie";
import Recommendation from "screens/Recommendations/Recommendation";
// Importing from insights.slice.js
import {
  setAggregateLogic,
  setFilters as setInsightsFilters,
  setMetrics as setInsightsMetrics,
  setSelectedKeywords as setInsightsSelectedKeywords,
  setSelectedMetrics as setInsightsSelectedMetrics,
  setSortBy as setInsightsSortBy,
} from "redux/slices/insights.slice";

// Importing from recommendation.slice.js
import PrivateWrapper from "components/layouts/Wrapper/PrivateWrapper";
import CreateReportsModal from "components/modals/CreateReportsModal/CreateReportsModal";
import { TypeaheadModal } from "components/modals/Typeahead/typeahead";
import UserProfile from "components/modals/UserProfile/UserProfile";
import { setAppState } from "redux/slices/appState.slices";
import {
  setFilters as setRecommendationFilters,
  setMetrics as setRecommendationMetrics,
  setSelectedMetrics as setRecommendationSelectedMetrics,
} from "redux/slices/recomendation.slice";
import AdBreakdown from "screens/AdBreakdown/AdBreakdown";
import AccountType from "screens/Auth/LoginScreen/AccountType/AccountType";
import UserName from "screens/Auth/LoginScreen/UserName/UserName";
import Compare from "screens/Compare/Compare";
import CompareWithDates from "screens/Compare2/CompareDates";
import CreativeInsight from "screens/CreativeInsights/CreativeInsight";
import CreativeMomentum from "screens/CreativeMomentumFatigue/CreativeMomentum";
import Unauthorized from "screens/Errors/403/Unauthorized";
import NotFound from "screens/Errors/404/NotFound";
import AudienceSegment from "screens/TaggingProcess/Audience/AudienceSegments/audienceSegment";
import { useLoginUser } from "utils/CometChat";
import PlatformConnectAccounts from "./screens/Platforms";
import { LicenseInfo } from "@mui/x-date-pickers-pro";

function App() {
  const { updateAppState, appState } = useAppState();
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const { mutateAsync: getReportsData } = useGetReports();
  const { initUser } = useLoginUser();

  const { axiosUserInstance, axiosInstance } = useAxios();
  const { refetch: refetchOrganizationTags } = useGetOrganizationTags(false);
  const { userToken, orgToken } = useAuth();
  const { refetch: getFoldersAgain } = useGetOrgFolders();

  useEffect(() => {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

    updateAppState();
    initUser();
  }, []);

  useEffect(() => {
    if (selectedOrganisation && !orgToken) {
      getOrgToken(selectedOrganisation?._id);
    } else {
      return;
    }
  }, [selectedOrganisation]);

  useEffect(() => {
    <Navigate to={APP_ROUTES.AUTH.LOGIN} />;
  }, [appState]);

  function handleInsightsFiltersRefetch(insightFilters) {
    dispatch(setGroupIds([]));
    dispatch(setInsightsSelectedMetrics([]));
    dispatch(setAggregateLogic(null));
    const filters = {};
    const kpis = [];

    Object.entries(insightFilters).forEach(([key, value]) => {
      if (value?.options?.length > 0) {
        filters[key] = {
          ...value,
          options: value?.options?.map((item, index) => {
            if (index === 0) {
              return { ...item, default: true };
            }
            return item;
          }),
        };
      } else if (key === "kpi") {
        kpis.push(value?.[0]?.kpi);
        const selectedMetrics = [
          {
            label: value?.[0]?.label,
            value: value?.[0]?.kpi,
          },
        ];

        dispatch(setInsightsMetrics(value));
        dispatch(setInsightsSelectedMetrics(selectedMetrics));
      }
    });
    dispatch(setInsightsFilters(filters));
    dispatch(setInsightsSortBy(kpis[0]));
  }
  function handleRecommendationFiltersRefetch(insightFilters) {
    if (insightFilters) {
      //set The first option as default if the option exist
      const filters = {};
      const kpis = [];

      Object.entries(insightFilters).forEach(([key, value]) => {
        if (value?.options?.length > 0) {
          filters[key] = {
            ...value,
            options: value?.options?.map((item, index) => {
              if (index === 0) {
                return { ...item, default: true };
              }
              return item;
            }),
          };
        } else if (key === "modelKpi") {
          kpis.push(value?.[0]?.kpi);
          const selectedMetrics = [
            {
              label: value?.[0]?.label,
              value: value?.[0]?.kpi,
            },
          ];

          dispatch(setRecommendationMetrics(value));
          dispatch(setRecommendationSelectedMetrics(selectedMetrics));
        }
      });

      dispatch(setRecommendationFilters(filters));
    }
  }

  const getAppStatus = (orgToken) => {
    if (!orgToken) return;
    axiosInstance
      .get(COMMON.APPLICATION_STATUS, {
        headers: {
          Authorization: `Bearer ${orgToken}`,
        },
      })
      .then(async (res) => {
        await initUser();
        dispatch(setAppState(res));
        refetchOrganizationTags(true);
        dispatch(setGroupIds([]));
        dispatch(setInsightsSelectedKeywords([]));
        getReportsData();
        axiosInstance
          .get(
            `/commons/filters?include="objectives,optimizationGoals,funnelStages,creativeTypes,targetingLocations,adAccountIds,kpi,platformIds"`,
            {
              headers: {
                Authorization: `Bearer ${orgToken}`,
              },
            }
          )
          .then((res) => {
            const payload = {
              objectives: res.objectives,
              optimizationGoals: res.optimizationGoals,
              funnelStages: res.funnelStages,
              creativeTypes: res.creativeTypes,
              targetingCountries: res.targetingLocations,
              adAccountIds: res.adAccountIds,
              ...res,
            };
            handleInsightsFiltersRefetch(payload);
          });
        const queryParams = `include="adAccountIds,targetingLocations,creativeTypes,optimizationGoals,objectives,funnelStages,modelKpi,platformIds"`;
        axiosInstance
          .get(`/commons/filters?${queryParams}`, {
            headers: {
              Authorization: `Bearer ${orgToken}`,
            },
          })
          .then((res) => {
            const payload = {
              objectives: res.objectives,
              optimizationGoals: res.optimizationGoals,
              funnelStages: res.funnelStages,
              creativeTypes: res.creativeTypes,
              targetingCountries: res.targetingLocations,
              adAccountIds: res.adAccountIds,
              ...res,
            };
            handleRecommendationFiltersRefetch(payload);
            if (
              // window.location.pathname !== APP_ROUTES.DASHBOARD &&
              // window.location.pathname !== APP_ROUTES.SETTING &&
              // window.location.pathname !== APP_ROUTES.AUTH.LEADIE &&
              window.location.pathname !== APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT
            ) {
              window.location.replace(APP_ROUTES.DASHBOARD);
            }
          });
        getFoldersAgain(true);
      });
  };

  const getOrgToken = (id) => {
    if (!userToken || !id) return;
    axiosUserInstance
      .get(API_ROUTES.AUTH.GET_ORG_TOKEN.replace(":organizationId", id))
      .then((res) => {
        dispatch(setOrganisationToken(res?.token));
        getAppStatus(res?.token);
      });
  };

  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={appTheme}>
      <Notification />
      <ToastContainer limit={0} />
      <Router>
        <UserProfile />
        <CreateReportsModal />
        <TypeaheadModal />
        <Routes>
          <Route
            path={APP_ROUTES.LANDING_PAGE}
            element={
              <CustomRoute>
                <AuthScreens.LoginScreen />
              </CustomRoute>
            }
          />

          <Route
            path={APP_ROUTES.AUTH.LOGIN}
            exact={true}
            element={
              <CustomRoute>
                <AuthScreens.LoginScreen />
              </CustomRoute>
            }
          />

          <Route
            path={APP_ROUTES.AUTH.SIGNUP}
            exact={true}
            element={<AuthScreens.SignupScreen />}
          />
          <Route
            path={APP_ROUTES.AUTH.FORGOT_PASSWORD}
            exact={true}
            element={<AuthScreens.ForgetPasswordScreen />}
          />
          <Route
            path={APP_ROUTES.AUTH.RESET_PASSWORD}
            exact={true}
            element={<AuthScreens.ResetPasswordScreen />}
          />

          <Route
            path={APP_ROUTES.AUTH.ORG_SELECTION_SCREEN}
            exact={true}
            element={
              <CustomRoute>
                <AuthScreens.OrgSelectionScreen />
              </CustomRoute>
            }
          />
          <Route
            path={APP_ROUTES.AUTH.VERIFICATION_LINK_SENT}
            exact={true}
            element={<AuthStatusesScreens.VerificationLinkSentScreen />}
          />

          <Route
            path={APP_ROUTES.AUTH.VERIFICATION_LINK_EXP}
            exact={true}
            element={<AuthStatusesScreens.VerificationLinkExpiredScreen />}
          />

          <Route
            path={APP_ROUTES.AUTH.VERIFIED}
            exact={true}
            element={<AuthStatusesScreens.VerifiedScreen />}
          />

          <Route
            path={APP_ROUTES.AUTH.GATHERING_DATA}
            exact={true}
            element={<AuthStatusesScreens.GatheringDataScreen />}
          />

          <Route
            path={APP_ROUTES.AUTH.SELECT_ROLE}
            exact={true}
            element={
              <CustomRoute>
                <AuthScreens.RoleSelectScreen />
              </CustomRoute>
            }
          />

          <Route
            path={APP_ROUTES.AUTH.ADD_ORGANIZATION}
            exact={true}
            element={
              <CustomRoute>
                <AuthScreens.AddOrganisationScreen />
              </CustomRoute>
            }
          />

          <Route
            path={APP_ROUTES.AUTH.LEADIE}
            exact={true}
            element={<Leadsie org={selectedOrganisation} />}
          />

          <Route
            path={APP_ROUTES.AUTH.MULTIPLE_ADD_ACCOUNT}
            exact={true}
            element={
              <CustomRoute>
                <AuthScreens.MultipleAdAccountScreen />
              </CustomRoute>
            }
          />

          {/* ---------------------------------------------- */}

          {/* ----------------------------------------------- */}

          {/* ----------------------------------------------- */}

          {/* ---------------------------------------------------- */}

          {/* ----------------------------------------------- */}

          <Route
            path={APP_ROUTES.ADD_AD_ACCOUNT}
            exact={true}
            element={
              <CustomRoute>
                <AddAdAccount />
              </CustomRoute>
            }
          />

          <Route
            path={APP_ROUTES.AUTH.LOGOUT}
            exact={true}
            element={<Logout />}
          />

          <Route
            path={APP_ROUTES.AUTH.ACCOUNT_TYPE}
            exact={true}
            element={<AccountType />}
          />

          <Route
            path={APP_ROUTES.AUTH.SET_USER_NAME}
            exact={true}
            element={<UserName />}
          />

          <Route path={APP_ROUTES.ALL} element={<NotFound />} />

          {/* --------------- Private Routes ------------------------ */}

          <Route path="/" element={<PrivateWrapper />}>
            <Route
              path={APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT}
              exact={true}
              element={<AuthScreens.AdAccountScreen />}
            />

            <Route
              path={APP_ROUTES.DASHBOARD}
              exact={true}
              element={
                <CustomRoute>
                  <Dashboard />
                </CustomRoute>
              }
            />

            <Route path={APP_ROUTES.UNAUTHORIZED} element={<Unauthorized />} />

            <Route
              path={APP_ROUTES.SETTING}
              exact={true}
              element={
                <CustomRoute>
                  <Setting />
                </CustomRoute>
              }
            />

            <Route
              path={APP_ROUTES.TAGGING.AUDIENCE_TAGGING}
              exact={true}
              element={
                <CustomRoute>
                  <TaggingScreens.AudienceTaggingScreen />
                </CustomRoute>
              }
            />
            <Route
              path={APP_ROUTES.TAGGING.TEXT_TAGGING}
              exact={true}
              element={
                <CustomRoute>
                  <TaggingScreens.TextTaggingScreen />
                </CustomRoute>
              }
            />

            <Route
              path={APP_ROUTES.TAGGING.AUDIENCE_TAGGING}
              exact={true}
              element={
                <CustomRoute>
                  <TaggingScreens.AudienceTaggingScreen />
                </CustomRoute>
              }
            />
            <Route
              path={APP_ROUTES.TAGGING.TEXT_TAGGING}
              exact={true}
              element={
                <CustomRoute>
                  <TaggingScreens.TextTaggingScreen />
                </CustomRoute>
              }
            />

            <Route
              path={APP_ROUTES.UPGRADE_PLAN}
              exact={true}
              element={
                <CustomRoute>
                  <UpgradePlan />
                </CustomRoute>
              }
            />
            {/* --------------- BRAND ASSETS------------------------ */}

            <Route
              path={APP_ROUTES.BRAND_ASSETS.default}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <BrandAssetsScreen />
                </PostOnboardingRoute>
              }
            />

            {/* ------------------------------------------- */}

            <Route
              path={APP_ROUTES.GROUPS_TAGS.TAGGING.IMAGES}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <TaggingScreens.ImageTaggingScreen />
                </PostOnboardingRoute>
              }
            />
            <Route
              path={APP_ROUTES.GROUPS_TAGS.TAGGING.AUDIENCE_SEGMENTS}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <AudienceSegment />
                </PostOnboardingRoute>
              }
            />
            <Route
              path={APP_ROUTES.GROUPS_TAGS.TAGGING.GROUP_CONTENT}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <TaggingScreens.TaggingGroupContent />
                </PostOnboardingRoute>
              }
            />

            <Route
              path={APP_ROUTES.GROUPS_TAGS.TAGGING.VIDEOS}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <TaggingScreens.VideoTaggingScreen />
                </PostOnboardingRoute>
              }
            />

            <Route
              path={APP_ROUTES.GROUPS_TAGS.TAGGING.TEXT}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <TaggingScreens.ManuallyTaggedTextScreen />
                </PostOnboardingRoute>
              }
            />

            <Route
              path={APP_ROUTES.GROUPS_TAGS.TAGGING.AUDIENCE}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <TaggingScreens.ManualTaggedAudienceScreen />
                </PostOnboardingRoute>
              }
            />

            {/* ---------------INSIGHTS------------------------ */}

            <Route
              path={APP_ROUTES.INSIGHTS.default}
              exact={true}
              element={
                <CustomRoute>
                  <Insights />
                </CustomRoute>
              }
            />
            <Route
              path={APP_ROUTES.INSIGHTS.COMPARE}
              exact={true}
              element={
                <CustomRoute>
                  <Compare />
                </CustomRoute>
              }
            />
            <Route
              path={APP_ROUTES.INSIGHTS.COMPARE_DATE}
              exact={true}
              element={
                <CustomRoute>
                  <CompareWithDates />
                </CustomRoute>
              }
            />
            <Route
              path={APP_ROUTES.INSIGHTS.AD_BREAKDOWN}
              exact={true}
              element={
                <CustomRoute>
                  <AdBreakdown />
                </CustomRoute>
              }
            />

            <Route
              path={APP_ROUTES.INSIGHTS.CREATIVE_INSIGHT}
              exact={true}
              element={
                <CustomRoute>
                  <CreativeInsight />
                </CustomRoute>
              }
            />
            <Route
              path={APP_ROUTES.INSIGHTS.CREATIVE_MOMENTUM_FATIGUE}
              exact={true}
              element={
                <CustomRoute>
                  <CreativeMomentum />
                </CustomRoute>
              }
            />
            <Route
              path={APP_ROUTES.INSIGHTS.INSIGHTS_REPORTS}
              exact={true}
              element={<Insights />}
            />
            <Route
              path={APP_ROUTES.INSIGHTS.COMPARE_DATE_REPORT}
              exact={true}
              element={<CompareWithDates />}
            />
            <Route
              path={APP_ROUTES.INSIGHTS.COMAPRE_REPORT}
              exact={true}
              element={<Compare />}
            />

            <Route
              path={APP_ROUTES.INSIGHTS.CREATIVE_MOMENTUM_FATIGUE_REPORT}
              exact={true}
              element={<CreativeMomentum />}
            />

            <Route
              path={APP_ROUTES.INSIGHTS.CREATIVE_INSIGHT_REPORT}
              exact={true}
              element={<CreativeInsight />}
            />

            <Route
              path={APP_ROUTES.INSIGHTS.AD_BREAKDOWN_REPORT}
              exact={true}
              element={<AdBreakdown />}
            />
            {/* --------------- GENERATION------------------------ */}

            <Route
              path={APP_ROUTES.GENERATION.default}
              exact={true}
              element={
                <CustomRoute>
                  <GenerationStepOne />
                </CustomRoute>
              }
            />
            <Route
              path={APP_ROUTES.GENERATION.GENERATION_STEP_2}
              exact={true}
              element={
                <CustomRoute>
                  <GenerationStepTwo />
                </CustomRoute>
              }
            />
            <Route
              path={APP_ROUTES.GENERATION.GENERATION_STEP_3}
              exact={true}
              element={
                <CustomRoute>
                  <GenerationStepThree />
                </CustomRoute>
              }
            />

            {/* --------------- GROUPING------------------------ */}

            <Route
              path={APP_ROUTES.INSIGHTS.GROUP_ANALYTICS}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <GroupAnalytics />
                </PostOnboardingRoute>
              }
            />

            <Route
              path={APP_ROUTES.GROUPS_TAGS.default}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <GroupingTagging />
                </PostOnboardingRoute>
              }
            />
            <Route
              path={APP_ROUTES.GROUPS_TAGS.UNGROUPED.IMAGES}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <UngroupedImages />
                </PostOnboardingRoute>
              }
            />
            <Route
              path={APP_ROUTES.GROUPS_TAGS.UNGROUPED.VIDEOS}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <UngroupedVideos />
                </PostOnboardingRoute>
              }
            />

            {/* ---------------------Untagged-------------- */}

            <Route
              path={APP_ROUTES.GROUPS_TAGS.UNTAGGED.TEXT}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <UnTaggedText />
                </PostOnboardingRoute>
              }
            />
            <Route
              path={APP_ROUTES.GROUPS_TAGS.UNTAGGED.AUDIENCE}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <UntaggedAudience />
                </PostOnboardingRoute>
              }
            />
            {/* ---------------OnBoardingScreens-------------- */}

            <Route
              path={APP_ROUTES.ONBOARDING.LOADING}
              exact={true}
              element={
                <CustomRoute>
                  <LoadingScreen />
                </CustomRoute>
              }
            />
            <Route
              path={APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS}
              exact={true}
              element={
                <CustomRoute>
                  <OnboardingScreens.CreativeGroupsScreen />
                </CustomRoute>
              }
            />

            <Route
              path={APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS_CREATIVES}
              exact={true}
              element={<OnboardingScreens.GroupContentScreen />}
            />

            <Route
              path={APP_ROUTES.ONBOARDING.AUTO_TAGGING_PROCESSING}
              exact={true}
              element={
                <CustomRoute>
                  <OnboardingStatusesScreens.ProcessingScreen />
                </CustomRoute>
              }
            />

            <Route
              path={APP_ROUTES.ONBOARDING.CONGRATULATIONS}
              exact={true}
              element={
                <CustomRoute>
                  <OnboardingStatusesScreens.CongratulationScreen />
                </CustomRoute>
              }
            />

            {/* ---------------Platforms---------------------- */}

            <Route
              path={APP_ROUTES.PLATFORMS.PLATFORM_CONNECT_ACCOUNTS}
              exact={true}
              element={
                <CustomRoute>
                  <PlatformConnectAccounts />
                </CustomRoute>
              }
            />

            {/* --------------- PREDICTION------------------------ */}

            <Route
              path={APP_ROUTES.PREDICTION.default}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <CustomRoute>
                    <Prediction />
                  </CustomRoute>
                </PostOnboardingRoute>
              }
            />

            <Route
              path={APP_ROUTES.PREDICTION.PREDICTION_REPORT}
              exact={true}
              element={<Prediction />}
            />

            <Route
              path={APP_ROUTES.PREDICTION.PREDICTION_REPORT_DETAILS}
              exact={true}
              element={<PredictionReportDetails />}
            />

            {/* --------------- RECOMMENDATION------------------ */}

            <Route
              path={APP_ROUTES.RECOMMENDATION.default}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <CustomRoute>
                    <Recommendation />
                  </CustomRoute>
                </PostOnboardingRoute>
              }
            />
            <Route
              path={APP_ROUTES.RECOMMENDATION.REPORT_DETAIL}
              exact={true}
              element={<Recommendation />}
            />

            {/* -------------- REPORTS-------------------- */}

            <Route
              path={APP_ROUTES.REPORTS.default}
              exact={true}
              element={
                // <PostOnboardingRoute>
                <CustomRoute>
                  <Reports />
                </CustomRoute>
                // </PostOnboardingRoute>
              }
            />

            <Route
              path={APP_ROUTES.REPORTS.REPORT_DETAIL}
              exact={true}
              element={
                <PostOnboardingRoute>
                  <ReportDetails />
                </PostOnboardingRoute>
              }
            />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
