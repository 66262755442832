import { AddOutlined } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import ContentContainer from "../../../../../../components/layouts/ContentContainer";
import useProductShoots from "./useProductShoots";

const OutlineButton = ({ text }) => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        minWidth: "107px",
        height: "91px",
        border: "0.5px solid #A1B0CC",
        borderRadius: "4px",
        cursor: "pointer",
        padding: "10px",
      }}
    >
      <AddOutlined htmlColor="#1C1E2080" />
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "400",
          color: "#1C1E2080",
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

const BorderedContainer = () => {
  return (
    <Stack
      sx={{
        border: "1px solid #A1B0CC",
        borderRadius: "6px",
      }}
    ></Stack>
  );
};
export default function ProductShoots() {
  const { productShoots, downloadProductsTemplate } = useProductShoots();
  return (
    <ContentContainer
      containerStyles={{
        maxHeight: "65vh",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          gap: "16px",
          alignItems: "center",
        }}
      >
        <OutlineButton text="Add New Color Shoots" />
        <OutlineButton text="Add Products" />
        <Button
          sx={{
            textTransform: "none",
            height: "48px",
          }}
          variant="contained"
          onClick={downloadProductsTemplate}
        >
          Download Products Template
        </Button>
      </Stack>

      <Stack>
        {productShoots.map((product) => (
          <BorderedContainer></BorderedContainer>
        ))}
      </Stack>
    </ContentContainer>
  );
}
