import React from "react";
import { Stack } from "@mui/material";

const MainContainer = (props) => {
  const { header, children } = props;
  return (
    <Stack
      direction="column"
      sx={{
        border: "1px solid #dddddd",
        width: "100%",
        borderRadius: "10px",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{ background: "#F9F9F9", borderRadius: "10px" }}
      >
        {header}
      </Stack>
      <Stack sx={{ padding: "16px" }}>{children}</Stack>
    </Stack>
  );
};

export default MainContainer;
