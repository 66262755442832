import { createTheme } from "@mui/material/styles";

const appTheme = createTheme({
  palette: {
    primary: {
      main: "#7167FB",
      light: "#dad7fe",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#02A0FC",
    },
    error: {
      main: "#FF3A29",
      light: "#FFE5D3",
    },
    warning: {
      main: "#FFB200",
      light: "#FFF5CC",
    },
    info: {
      main: "#02A0FC",
      light: "#CCF8FE",
    },
    success: {
      main: "#34a853",
      light: "#E2FBD7",
    },
    normal: {
      main: "#1C1E20",
      light: "#F2F2F2",
    },
    text: {
      primary: "#1C1E20",
      secondary: "rgba(0,0,0,0.7)",
      disabled: "rgba(0,0,0,0.5)",
    },
  },
  typography: {
    h1: {
      fontFamily: "Inter, sans-serif",
      fontWeight: 500,
      fontSize: 40,
      letterSpacing: "0em",
    },
    h2: {
      fontFamily: "Inter, sans-serif",
      fontSize: 22,
      fontWeight: 500,
      letterSpacing: "0em",
    },
    h3: {
      fontFamily: "Inter, sans-serif",
      fontSize: 20,
    },
    h4: {
      fontFamily: "Inter, sans-serif",
      fontSize: 14,
      letterSpacing: "0em",
    },
    h5: {
      fontFamily: "Inter, sans-serif",
      fontSize: 10,
    },
    h6: {
      fontFamily: "Inter, sans-serif",
      fontSize: 16,
      letterSpacing: "0em",
    },
    subtitle1: {
      fontFamily: "Inter, sans-serif",
    },
    subtitle2: {
      fontFamily: "Inter, sans-serif",
    },
    body1: {
      fontFamily: "Inter, sans-serif",
    },
    body2: {
      fontFamily: "Inter, sans-serif",
    },
    button: {
      fontFamily: "Inter, sans-serif",
      fontSize: 16,
    },
    caption: {
      fontFamily: "Inter, sans-serif",
    },
    overline: {
      fontFamily: "Inter, sans-serif",
    },
  },
  shape: {
    borderRadius: 6,
  },
});

export default appTheme;
