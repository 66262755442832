import { CometChat } from "@cometchat-pro/chat";
import { CometChatUIKit } from "@cometchat/chat-uikit-react";
import { useSelector } from "react-redux";
import "config/cometchat";
import { requestForToken } from "config/firebase";

export async function checkIfGroupExists(groupId) {
  try {
    const group = await CometChat.getGroup(groupId);
    return group;
  } catch (error) {
    // If group does not exist, CometChat will throw an error
    return null;
  }
}

export async function createGroup(groupId, groupName) {
  try {
    const group = await new CometChat.Group(
      groupId,
      groupName,
      CometChat.GROUP_TYPE.PUBLIC
    );
    await CometChat.createGroup(group);
    return group;
  } catch (error) {
    // console.log("Error creating group:", error);
    throw error;
  }
}

export function useLoginUser() {
  const userData = useSelector((state) => state.auth.userData);
  function initUser() {
    const authKey = process.env.REACT_APP_COMETCHAT_APP_ID;
    const uid = userData?.user?.userName;
    const name = userData?.user?.firstName;
    const new_user = {
      uid,
      name,
    };
    CometChatUIKit.createUser(new_user)
      .then((createdUser) => {
        // console.log("User created successfully:", createdUser);

        CometChatUIKit.login(uid, authKey)
          .then(async (loggedInUser) => {
            // console.log("Login Successful:", loggedInUser);
            const FCM_TOKEN = await requestForToken();
            // console.log("2. Received FCM Token", FCM_TOKEN);
            // i am registering the fcm token for cometchat
            await CometChat.registerTokenForPushNotification(FCM_TOKEN);
          })
          .catch((loginError) => {
            // console.log("Login Error:", loginError);
          });
      })
      .catch((createError) => {
        CometChatUIKit.login(uid, authKey)
          .then(async (loggedInUser) => {
            // console.log("Login Successful:", loggedInUser);
            const FCM_TOKEN = await requestForToken();
            // console.log("2. Received FCM Token", FCM_TOKEN);
            await CometChat.registerTokenForPushNotification(FCM_TOKEN);
          })
          .catch((loginError) => {
            // console.log("Login Error:", loginError);
          });
      });
  }
  return { initUser };
}
