import React from "react";

import { PrivateContainer } from "../../components/layouts";
import { APP_ROUTES } from "../../routes";
import useUserRole from "hooks/useUserRole";
import SEO from "utils/SEO";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Groups3Icon from "@mui/icons-material/Groups3";
import { useNavigate } from "react-router-dom";

const actionsList = [
  {
    title: "Images",
    actions: [
      {
        title: "Ungrouped Images",
        iconName: <PermMediaIcon style={{ color: "#FF9800" }} />,
        path: APP_ROUTES.GROUPS_TAGS.UNGROUPED.IMAGES,
      },
      {
        title: "Image Tagging",
        iconName: <ImageSearchIcon style={{ color: "#FF9800" }} />,
        path: APP_ROUTES.GROUPS_TAGS.TAGGING.IMAGES,
      },
    ],
  },
  {
    title: "Videos",
    actions: [
      {
        title: "Ungrouped Videos",
        iconName: <VideoLibraryIcon style={{ color: "#9C27B0" }} />,
        path: APP_ROUTES.GROUPS_TAGS.UNGROUPED.VIDEOS,
      },
      {
        title: "Video Tagging",
        iconName: <VideoSettingsIcon style={{ color: "#9C27B0" }} />,
        path: APP_ROUTES.GROUPS_TAGS.TAGGING.VIDEOS,
      },
    ],
  },
  {
    title: "Text",
    actions: [
      {
        title: "Untagged Text",
        iconName: <FormatColorTextIcon style={{ color: "#4CAF50" }} />,
        path: APP_ROUTES.GROUPS_TAGS.UNTAGGED.TEXT,
      },
      {
        title: "Text Tagging",
        iconName: <TextIncreaseIcon style={{ color: "#4CAF50" }} />,
        path: APP_ROUTES.GROUPS_TAGS.TAGGING.TEXT,
      },
    ],
  },
  {
    title: "Audience",
    actions: [
      {
        title: "Audience Segments",
        iconName: <Groups3Icon style={{ color: "#2196F3" }} />,
        path: APP_ROUTES.GROUPS_TAGS.TAGGING.AUDIENCE_SEGMENTS,
      },
      {
        title: "Audience Tagging",
        iconName: <PersonAddAltIcon style={{ color: "#2196F3" }} />,
        path: APP_ROUTES.GROUPS_TAGS.TAGGING.AUDIENCE,
      },
    ],
  },
];

export default function GroupingTagging() {
  const { hasUserPermission } = useUserRole();
  const navigate = useNavigate();
  return (
    <PrivateContainer
      activePage={APP_ROUTES.GROUPS_TAGS.default}
      bodyStyles={{
        justifyContent: "flex-start",
      }}
    >
      <SEO
        title="Groups"
        description="Storyteller is the only AI Powered insights and recommendation platform"
        name="Groupping by Storyteller"
        type="Report"
      />
      <h1 className="text-xl font-semibold">Groups & Tags</h1>
      <div className="border-solid border-neutral-800">
        {actionsList.map((category, idx) => (
          <>
            <div className="mt-8">
              <p className="text-md font-semibold">{category.title}</p>
              <div className="grid grid-cols-3 gap-2 mt-5" key={idx}>
                {category.actions.map((action, index) =>
                  (action.path === APP_ROUTES.GROUPS_TAGS.UNGROUPED.IMAGES ||
                    action.path === APP_ROUTES.GROUPS_TAGS.UNGROUPED.VIDEOS) &&
                  !hasUserPermission("ALL") ? null : (
                    <div
                      key={index}
                      onClick={() => navigate(action.path)}
                      className="text-xs"
                    >
                      <button
                        type="button"
                        className="text-gray-900 text-sm font-medium gap-x-2 w-[100%] h-[90%] bg-custom-gray-500 hover:bg-custom-gray-800 border border-custom-gray-800 hover:hover:border-custom-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-100 rounded-lg px-5 py-3.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2"
                      >
                        <div className="m-2 p-2 rounded-full border border-custom-gray-800 bg-white">
                          {action.iconName}
                        </div>
                        <div className="flex flex-col">
                          <p className="text-left">{action.title}</p>
                        </div>
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>
          </>
        ))}
      </div>
    </PrivateContainer>
  );
}
