import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { merged_squares } from "../../../assets/icons/svg";
import { MODALTYPES, setModal } from "../../../redux/slices/modal.slices";
import useAxios from "../../../hooks/useAxios";
import { APP_ROUTES, API_ROUTES } from "../../../routes";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import { GROUP_ENTITY } from "../../../utils/constants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function useImageTagging() {
  const [isMerging, setIsMerging] = React.useState(false);
  const [groups, setGroups] = React.useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { axiosOrgInstance } = useAxios();
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const { isLoadMore } = useInfiniteScroll("content-container", hasNextPage);
  const [page, setPage] = React.useState(1);
  const [sort, setSort] = React.useState();
  const [totalDocs, setTotalDocs] = React.useState(0);
  const [filterTags, setFilterTags] = React.useState([]);
  const { open } = useSelector((state) => state?.modal);

  function toggleMerging() {
    if (isMerging) {
      initGroups();
    }
    setIsMerging((prev) => !prev);
  }

  function handleSelect(id) {
    let _groups = groups?.map((group) => {
      if (group._id === id) {
        return {
          ...group,
          isSelected: !group.isSelected,
        };
      }
      return group;
    });
    setGroups(_groups);
  }

  function handleMerge() {
    //filter selected groups
    let _groups = groups?.filter((group) => group.isSelected);
    dispatch(
      setModal({
        type: MODALTYPES.MERGE_MULTIPLE_GROUPS_MODAL,
        open: true,
        payload: {
          action_sure_msg: `Do You Want To Merge These ${_groups?.length} Groups?`,
          action_warning_msg: "This Action Cannot Be Undone",
          primaryBtnText: "Merge",
          secondaryBtnText: "Cancel",
          dangerMode: false,
          image: merged_squares,
        },
      })
    );
  }

  function mergeModalPrimaryAction(payload) {
    const { addManualTags } = payload;
    let _groups = groups?.filter((group) => group.isSelected);

    axiosOrgInstance
      .patch(API_ROUTES.ONBOARDING.MERGE_GROUPS, {
        groupIds: _groups?.map((group) => group._id),
      })
      .then((res) => {
        initGroups();
        setIsMerging(false);
        if (addManualTags) {
          dispatch(
            setModal({
              open: true,
              type: MODALTYPES.IMAGE_TAGGING_MODAL,
              payload: {
                groupId: res?._id,
                groupName: res?.groupName,
                imageUrl: res?.groupThumbnailUrl,
              },
            })
          );
        }
      });
  }

  function handleUpdateTags(group) {
    const payload = {
      ...group,
    };
    dispatch(
      setModal({
        type: MODALTYPES.IMAGE_TAGGING_MODAL,
        open: true,
        payload,
      })
    );
  }

  function addSysGeneratedTagsModalPrimaryAction() {
    axiosOrgInstance
      .post(API_ROUTES.ONBOARDING.SKIP_MANUAL_GROUPING, {
        groupEntity: GROUP_ENTITY.IMAGE,
      })
      .then((res) => {
        toast.success("System Generated Tags Added Successfully");
        navigate(APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS);
        initGroups();
      });
  }

  function initGroups(sortInline, filterTagsInline) {
    axiosOrgInstance
      .get(API_ROUTES.ONBOARDING.LIST_ORG_GROUPS, {
        params: {
          groupEntity: GROUP_ENTITY.IMAGE,
          isManualGroupingDone: 1,
          filterTags: filterTagsInline || filterTags,
          sort:
            sortInline || sort
              ? sort
              : JSON.stringify({ theta: 1, distance: 1 }),
        },
      })
      .then((res) => {
        setTotalDocs(res?.totalDocs);
        setHasNextPage(res?.hasNextPage);
        setPage(res?.page);
        let _groups = res?.docs?.map((group) => {
          return {
            ...group,
            isSelected: false,
          };
        });
        setGroups(_groups);
      });
  }

  useEffect(() => {
    initGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTags]);

  useEffect(() => {
    if (isLoadMore && hasNextPage) {
      axiosOrgInstance
        .get(API_ROUTES.ONBOARDING.LIST_ORG_GROUPS, {
          params: {
            groupEntity: GROUP_ENTITY.IMAGE,
            page: page + 1,
            isManualGroupingDone: 1,
            filterTags: filterTags,
            sort: sort || JSON.stringify({ theta: 1, distance: 1 }),
          },
        })
        .then((res) => {
          setHasNextPage(res?.hasNextPage);
          setPage(res?.page);
          let _groups = res?.docs?.map((group) => {
            return {
              ...group,
              isSelected: false,
            };
          });
          setGroups((prev) => [...prev, ..._groups]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadMore]);

  return {
    setFilterTags,
    sort,
    setSort,
    isMerging,
    toggleMerging,
    groups,
    handleSelect,
    handleMerge,
    handleUpdateTags,
    addSysGeneratedTagsModalPrimaryAction,
    initGroups,
    mergeModalPrimaryAction,
    totalDocs,
  };
}
