/* eslint-disable react-hooks/exhaustive-deps */
import { useGetOrgFolders } from "apis/common";
import {
  useCreateFolders,
  useDeleteFolder,
  useGetSpecificFolder,
  useUpdateFolders,
} from "apis/folderApi";
import { useDeleteReport, useToggleReportVisibility } from "apis/reportsApi";
import { useDuplicateReport } from "apis/reportsApi";
import useUserRole from "hooks/useUserRole";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import _ from "lodash";
import {
  refreshOpenFolderIds,
  setOpenFolderIds,
} from "redux/slices/folder.slice";
import { toggleShowArchiveFolder } from "redux/slices/organisation.slice";

export default function useFolder() {
  const dispatch = useDispatch();

  // local states
  const [showInput, setShowInput] = useState(false);
  const [openRenameInput, setOpenRenameInput] = useState(false);
  const [selectedFolderName, setSelectedFolderName] = useState("");
  const [folderIdForEdit, setFolderForEdit] = useState("");
  const [folderTree, setFolderTree] = useState([]);
  const [folderUnderOp, setFolderUnderOp] = useState({});

  // global states
  const { isOrgInTrialMode } = useUserRole();
  const { appState } = useSelector((state) => state?.appState);
  const { openFolderIds } = useSelector((state) => state?.folderSlice);
  const { showArchiveFolder } = useSelector((state) => state?.organisation);

  // react query
  const {
    data: createdFolder,
    mutateAsync: createFolder,
    isLoading: createFolderLoading,
  } = useCreateFolders(() => setShowInput(false));
  const {
    data: deletedFolder,
    mutateAsync: deleteFolder,
    isLoading: deleteFolderLoading,
  } = useDeleteFolder();
  const {
    data: updatedFolder,
    mutateAsync: updateFolder,
    isLoading: updateFolderLoading,
  } = useUpdateFolders(() => setOpenRenameInput(false));
  const { mutateAsync: duplicateReport, isLoading: duplicateReportLoading } =
    useDuplicateReport();
  const {
    data: specificFolder,
    mutate: fetchSpecificFolder,
    isLoading: specificFolderLoading,
  } = useGetSpecificFolder();
  const {
    data: rootFolders = [],
    isLoading: rootFoldersLoading,
    isRefetching: rootFoldersRefetching,
    refetch: refetchRootFolders,
  } = useGetOrgFolders(true);
  const { mutateAsync: deleteReport } = useDeleteReport();

  const { mutate: handleChangeVisibility } = useToggleReportVisibility();

  // ref
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  // constants
  const showLoader =
    rootFoldersLoading ||
    rootFoldersRefetching ||
    createFolderLoading ||
    deleteFolderLoading ||
    updateFolderLoading ||
    duplicateReportLoading ||
    specificFolderLoading;

  // methods
  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowInput(false);
      setOpenRenameInput(false);
    }
  };

  const findFolderByIdAndUpdate = (
    folderAndReports = false,
    traversal,
    folderId,
    level = 1
  ) => {
    const { folders = [], reports = [] } = folderAndReports;
    const folderWithLevel = folders.map((folder) => ({
      ...folder,
      level: 1 + level,
    }));
    for (let folder of traversal) {
      if (folder._id === folderId) {
        folder["level"] = level;
        folder["children"] = folderWithLevel;
        folder["reports"] = reports || [];
        delete folder["reportIds"];
        const updatedFilesJSON = [...folderTree];
        const index = updatedFilesJSON.findIndex(
          (item) => item._id === folderId
        );
        updatedFilesJSON[index] = folder;
        setFolderTree(updatedFilesJSON);

        return folder;
      } else if (folder?.children && folder?.children?.length > 0) {
        let found = findFolderByIdAndUpdate(
          folderAndReports,
          folder.children,
          folderId,
          level
        );
        if (found) {
          return found;
        }
      }
    }
    return;
  };

  const findFolderByIdAndUpdateDetails = (
    details,
    traversal,
    folderId,
    action
  ) => {
    for (let folder of traversal) {
      if (folder._id === folderId) {
        if (action === "update") {
          Object.assign(folder, details);
          const updatedFilesJSON = [...folderTree];
          const index = updatedFilesJSON.findIndex(
            (item) => item._id === folderId
          );
          updatedFilesJSON[index] = folder;
          setFolderTree(updatedFilesJSON);
          return folder;
        } else if (action === "add" && details) {
          if (!folder.children) {
            folder.children = [];
          }
          details["level"] = folder["level"] + 1;
          details["children"] = folder.children;
          details.children.push(details);
          setFolderTree([...folderTree]);
          return details;
        } else if (action === "delete") {
          const updatedFilesJSON = deleteFolderById([...folderTree], folderId);
          setFolderTree(updatedFilesJSON);
          return folderId;
        }
      } else if (folder?.children && folder?.children.length > 0) {
        let found = findFolderByIdAndUpdateDetails(
          details,
          folder.children,
          folderId,
          action
        );
        if (found) {
          return found;
        }
      }
    }
    return;
  };

  const HandleChangeVisibility = (id, visibility) => {
    handleChangeVisibility({ id, visibility });
    setTimeout(() => {
      handleRefresh(true);
    }, 2000);
  };

  const deleteFolderById = (traversal, folderId) => {
    for (let i = 0; i < traversal.length; i++) {
      if (traversal[i]._id === folderId) {
        traversal.splice(i, 1);
        return traversal;
      } else if (traversal[i]?.children && traversal[i].children.length > 0) {
        traversal[i].children = deleteFolderById(
          traversal[i].children,
          folderId
        );
      }
    }
    return traversal;
  };

  const gatherAllChildIds = (childrenArr = []) => {
    if (!childrenArr.length) {
      return [];
    }
    let ids = [];
    childrenArr.forEach((child) => {
      ids.push(child._id);
      const childIds = gatherAllChildIds(child.children);
      ids = ids.concat(childIds);
    });
    return ids;
  };

  const toggleFolder = (folderId, level, childFolders = []) => {
    setFolderUnderOp({ folderId, level });
    if (openFolderIds.includes(folderId)) {
      const childIdsToClose = gatherAllChildIds(childFolders || []);
      const ids = _.cloneDeep(openFolderIds);

      dispatch(
        setOpenFolderIds(
          ids.filter((id) => id !== folderId && !childIdsToClose.includes(id))
        )
      );
    } else {
      fetchSpecificFolder(folderId);
      const ids = _.cloneDeep(openFolderIds);
      ids.push(folderId);
      dispatch(setOpenFolderIds(ids));
    }
  };

  const handlePlusClick = (event) => {
    event.stopPropagation();
    setShowInput(!showInput);
    setFolderForEdit(null);
  };

  const addFolder = (event) => {
    event.stopPropagation();
    const folderName = event.target.value;
    if (!folderName) {
      toast(
        {
          title: "Please enter folder name.",
          variant: "destructive",
          duration: 5000,
        },
        { toastId: "ksadmkadsk12342323" }
      );
    } else {
      createFolder({ name: folderName, parent: folderIdForEdit });
    }
  };

  const handleFolderAction = (action, folderId, name = "", parentId = null) => {
    setFolderUnderOp({ folderId: folderId, name, parentId, action });
    if (action === "delete") {
      dispatch(
        setModal({
          type: MODALTYPES.COMMON_ALERT,
          open: true,
          payload: {
            action: () => deleteFolder({ folderId }),
            title: "Are you sure?",
            description:
              "This action cannot be undone. This will permanently delete the folder.",
            btnText: "Delete",
          },
        })
      );
    } else if (action === "archive") {
      dispatch(
        setModal({
          type: MODALTYPES.COMMON_ALERT,
          open: true,
          payload: {
            action: () => updateFolder({ id: folderId, action: "markArchive" }),
            title: "Are you sure?",
            description:
              "This action cannot be undone. This will archive the folder.",
            btnText: "Archive",
          },
        })
      );
    } else if (action === "favourite") {
      updateFolder({
        id: folderId,
        action: name ? "removeFavourite" : "markFavourite",
      });
    } else if (action === "duplicate") {
      updateFolder({ id: folderId, action: "duplicateFolder" });
      toast("Duplicating folder started. \n please refresh in sometime");
    } else if (action === "rename") {
      updateFolder({ id: folderId, name });
    } else if (action === "addNestedFolder") {
      setShowInput(!showInput);
      setFolderForEdit(folderId);
    } else if (action === "moveFolder") {
      dispatch(
        setModal({
          type: MODALTYPES.MOVE_REPORT_TO_FOLDER,
          open: true,
          payload: {
            action: updateFolder,
            actionType: action,
            title: "Move Folder",
            description: "Move folder to another folder",
            folderToMove: folderId,
          },
        })
      );
    } else if (action === "moveToRoot") {
      updateFolder({ id: folderId, action: "moveToRoot" });
      handleRefresh(true);
    }
  };

  const handleReportFolderOpen = (rootFolders) => {
    rootFolders?.map((folder) => {
      if (folder.name === "Reports" && folder.isDefault) {
        if (!openFolderIds.includes(folder._id)) {
          toggleFolder(folder._id, 1);
        }
      }
    });
  };

  const fillParentFolders = (folders) => {
    const parentFolders = folders;
    const parentFoldersWithLevel = parentFolders?.map((folder) => {
      return {
        ...folder,
        level: 1,
      };
    });
    setFolderTree(parentFoldersWithLevel);
  };

  const handleRefresh = (refetch = false) => {
    dispatch(refreshOpenFolderIds());
    refetch && refetchRootFolders();
  };

  const handleFolderTreeStateAfterUpdate = (updatedFolder) => {
    const normalActions = ["favourite", "rename"];
    const actionWhichReqRefresh = ["moveToRoot", "moveFolder", "archive"];
    if (normalActions.includes(folderUnderOp?.action)) {
      findFolderByIdAndUpdateDetails(
        updatedFolder[0],
        folderTree,
        updatedFolder[0]._id,
        "update"
      );
    } else if (actionWhichReqRefresh.includes(folderUnderOp?.action)) {
      handleRefresh(true);
    }
  };

  // side effects
  useEffect(() => {
    if (folderTree && folderUnderOp?.folderId) {
      findFolderByIdAndUpdate(
        specificFolder,
        folderTree,
        folderUnderOp?.folderId,
        folderUnderOp?.level
      );
    }
  }, [specificFolder]);

  useEffect(() => {
    if (rootFolders?.length) {
      fillParentFolders(rootFolders);
      handleReportFolderOpen(rootFolders);
    }
  }, [rootFolders, rootFoldersRefetching, rootFoldersLoading]);

  useEffect(() => {
    if (updatedFolder?.length) {
      handleFolderTreeStateAfterUpdate(updatedFolder);
    }
  }, [updatedFolder]);

  useEffect(() => {
    if (createdFolder && folderIdForEdit) {
      const ids = _.cloneDeep(openFolderIds);
      dispatch(setOpenFolderIds(ids.filter((id) => id !== folderIdForEdit)));
    }
    handleReportFolderOpen(rootFolders);
  }, [createdFolder]);

  useEffect(() => {
    if (deletedFolder?.length) {
      findFolderByIdAndUpdateDetails("", folderTree, deletedFolder, "delete");
    }
    handleReportFolderOpen(rootFolders);
  }, [deletedFolder]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    handleReportFolderOpen(rootFolders);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const checkIfActionAllowed = (folderName) => {
    const strictFolders = ["Reports", "Archive"];
    return !strictFolders.includes(folderName);
  };

  return {
    inputRef,
    showLoader,
    showInput,
    openRenameInput,
    selectedFolderName,
    folderIdForEdit,
    folderTree,
    openFolderIds,
    dropdownRef,
    appState,
    rootFolders,
    showArchiveFolder,
    setSelectedFolderName,
    handleFolderAction,
    handlePlusClick,
    addFolder,
    isOrgInTrialMode,
    toggleFolder,
    setFolderForEdit,
    setOpenRenameInput,
    duplicateReport,
    handleRefresh,
    checkIfActionAllowed,
    dispatch,
    toggleShowArchiveFolder,
    deleteReport,
    HandleChangeVisibility,
  };
}
