import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Textarea } from "@/components/ui/textarea";
import { Toaster } from "@/components/ui/toaster";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import { Tooltip } from "@mui/material";
import { useDuplicateReport } from "apis/reportsApi";
import {
  BadgePlus,
  Bookmark,
  Check,
  ChevronDown,
  CircleCheck,
  CirclePause,
  CircleX,
  Copy,
  Ellipsis,
  Layers2,
  Loader2,
  MessageCircle,
  MessageCircleOff,
  RefreshCcw,
  RouteOff,
  ShareIcon,
  Trash,
} from "lucide-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { twMerge } from "tailwind-merge";

const HeaderFeatures = (props) => {
  const {
    specificInsightsId,
    disableFilters,
    handleUpdateAndSave,
    isMakingShortUrl,
    handleUrlShortener,
    hideSidebar,
    handleRemoveBookmark,
    insightsData,
    deleteReport,
    handleBookmarkReport,
    isUpdatingReport = false,
    report = {},
    setIsDrawerOpen,
    isDrawerOpen,
    payload,
    filterAndOptions,
    onFilterValueChange,
    adAccountIds,
    handleAdAccountId,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const keyName = "adAccountIds";
    const { [keyName]: { options = [] } = {} } = filterAndOptions;

    if (options?.length > 0) {
      payload.forEach((obj, index) => {
        const { type, value } = obj;
        if (type === "adAccountIds" && !value?.length) {
          onFilterValueChange(index, options[0]?.key);
        }
      });
    }
  }, [filterAndOptions]);

  const [reportMessage, setReportMessage] = useState("");
  const { userBookmarkReports } = useSelector((state) => state?.app);

  const { mutateAsync: duplicateReport, isLoading: duplicateReportLoading } =
    useDuplicateReport();

  const getAdAccountLabel = () => {
    let res;
    filterAndOptions?.adAccountIds?.options?.map((curr, index) => {
      if (adAccountIds?.includes(curr?.key)) {
        res = curr?.label;
        return res;
      }
    });
    return res ? res : "Select Ad Account";
  };

  let lastUpdatedAt;
  if (
    insightsData?.length > 0 &&
    insightsData[0]?.lastUpdatedAt &&
    Array.isArray(insightsData[0]?.lastUpdatedAt)
  ) {
    let temp = insightsData[0]?.lastUpdatedAt;
    lastUpdatedAt = temp.sort(
      (a, b) => new Date(a.lastUpdated) - new Date(b.lastUpdated)
    );
  }

  return (
    <>
      <div
        className={twMerge(
          disableFilters || hideSidebar
            ? `opacity-50 pointer-events-none cursor-not-allowed flex gap-x-3 ${
                isDrawerOpen ? "z-0" : "z-[10]"
              } items-center`
            : `flex gap-x-3 ${isDrawerOpen ? "z-0" : "z-[1000]"} items-center`
        )}
      >
        <Toaster />
        {adAccountIds &&
          Object.entries(filterAndOptions)?.map(([key, filter], index) => {
            if (key === "adAccountIds") {
              return (
                <DropdownMenu key={index}>
                  <DropdownMenuTrigger>
                    <Button
                      className="text-xs"
                      disabled={hideSidebar || disableFilters}
                      variant="outline"
                    >
                      <img className="w-4 h-4 mr-2" src="/meta.png" alt="" />
                      <span className="mr-2">{getAdAccountLabel()}</span>
                      {adAccountIds?.length > 1 && (
                        <span className="rounded-sm px-2 py-1 mr-1 font-medium bg-blue-200/60 text-gray-500">
                          {adAccountIds?.length > 1 &&
                            "+" + (adAccountIds?.length - 1)}
                        </span>
                      )}
                      <ChevronDown className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-max text-xs">
                    {filter?.options?.map((filterOption) => {
                      const accountStatus = filterOption?.accountStatus;

                      let filterStatus;
                      let isDisabled = false;

                      if (accountStatus !== undefined) {
                        switch (true) {
                          case accountStatus === -2: {
                            filterStatus = (
                              <Tooltip placement="top"  arrow title="Account is deleted">
                                <CircleX className="w-4 h-4" color="red" />
                              </Tooltip>
                            );
                            isDisabled = true;
                            break;
                          }

                          case accountStatus === -1: {
                            filterStatus = (
                              <Tooltip
                                arrow
                                placement="top"
                                title="Account need re-authentication"
                              >
                                <RouteOff className="w-4 h-4" color="yellow" />
                              </Tooltip>
                            );
                            isDisabled = true;
                            break;
                          }

                          case accountStatus === 0: {
                            filterStatus = (
                              <Tooltip placement="top" arrow title="Account is paused">
                                <CirclePause
                                  className="w-4 h-4"
                                  color="orange"
                                />
                              </Tooltip>
                            );
                            break;
                          }

                          case accountStatus === 1: {
                            filterStatus = (
                              <Tooltip placement="top" arrow title="Account is active">
                                <CircleCheck
                                  className="w-4 h-4"
                                  color="green"
                                />
                              </Tooltip>
                            );
                            break;
                          }

                          default: {
                            filterStatus = null;
                            break;
                          }
                        }
                      }
                      return (
                        <DropdownMenuItem
                          key={filterOption.key}
                          disabled={isDisabled}
                          className="cursor-pointer flex justify-between"
                          onClick={() => handleAdAccountId(filterOption.key)}
                        >
                          <div className="inline-flex items-center gap-x-2">
                            <img className="w-4 h-4 " src="/meta.png" alt="" />
                            {filterOption?.label}
                            {filterStatus}
                          </div>
                          <Check
                            className={cn(
                              "ml-2 h-4 w-4",
                              adAccountIds.includes(filterOption?.key)
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                        </DropdownMenuItem>
                      );
                    })}
                  </DropdownMenuContent>
                </DropdownMenu>
              );
            }
          })}

        {insightsData?.length > 0 && lastUpdatedAt && (
          <Button
            className={`${
              !insightsData || !insightsData?.length > 0
                ? "hidden"
                : "text-xs font-medium"
            }`}
            variant="secondary"
          >
            Updated {moment(lastUpdatedAt[0]).fromNow()}
            <RefreshCcw className="p-1" />
          </Button>
        )}

        {isUpdatingReport ? (
          <Button variant="outline" disabled>
            <Loader2 className="mr-2 h-4 w-4 animate-spin text-xs" />
            Saving...
          </Button>
        ) : (
          <Button
            // variant="outline"
            className="text-xs font-medium"
            disabled={disableFilters || hideSidebar}
            onClick={handleUpdateAndSave}
          >
            Save
          </Button>
        )}

        {specificInsightsId && report?.isPublic && (
          <Popover>
            <PopoverTrigger>
              <Button
                disabled={disableFilters || hideSidebar}
                variant="outline"
                size="icon"
                // className="bg-[#7167FB] text-white flex justify-end hover:bg-[#6b62ed] text-xs"
              >
                <ShareIcon size={14} />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[350px] mt-5 mr-16 text-xs">
              <Textarea
                placeholder="Type your message here."
                onChange={(e) => setReportMessage(e.target.value)}
              />
              {report?.url && (
                <div className="flex items-center space-x-2 mt-2 text-xs">
                  <div className="grid flex-1 gap-2">
                    <Label htmlFor="link" className="sr-only">
                      Link
                    </Label>
                    <Input id="link" defaultValue={report?.url} readOnly />
                  </div>
                  <Button
                    type="submit"
                    variant="secondary"
                    onClick={() => {
                      navigator.clipboard.writeText(report?.url);
                      toast({
                        description: "Url Copied To Clipboard.",
                      });
                    }}
                  >
                    <Copy className="h-4 w-4" />
                  </Button>
                </div>
              )}
              <div className="mt-4">
                {specificInsightsId &&
                  (isMakingShortUrl ? (
                    <Button
                      disabled
                      variant="secondary"
                      // className="bg-[#7167FB] text-white flex justify-end hover:bg-[#6b62ed] text-xs"
                    >
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Copying...
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleUrlShortener(reportMessage)}
                      variant="secondary"
                      // className="bg-[#7167FB] text-white flex justify-end hover:bg-[#6b62ed] text-xs"
                    >
                      Generate
                    </Button>
                  ))}
              </div>
            </PopoverContent>
          </Popover>
        )}

        {specificInsightsId && (
          <Button
            variant="outline"
            size="icon"
            className={`${hideSidebar || !specificInsightsId ? "hidden" : ""}`}
            onClick={
              userBookmarkReports.includes(specificInsightsId)
                ? handleRemoveBookmark
                : handleBookmarkReport
            }
          >
            {userBookmarkReports.includes(specificInsightsId) ? (
              <BookmarkAddedIcon className="h-4 w-4" />
            ) : (
              <Bookmark className="h-4 w-4" />
            )}
          </Button>
        )}

        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button
              disabled={hideSidebar || disableFilters}
              variant="outline"
              size="icon"
              className="text-xs"
            >
              <Ellipsis className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-max mr-12">
            <DropdownMenuGroup>
              <DropdownMenuItem
                disabled={!insightsData?.length}
                className={`cursor-pointer text-xs font-medium`}
                onClick={() => {
                  dispatch(
                    setModal({
                      open: true,
                      type: MODALTYPES.SAVE_INSIGHTS_REPORT,
                      payload: {},
                    })
                  );
                }}
              >
                <BadgePlus className="mr-2 h-4 w-4" />
                <span>Save as new report</span>
              </DropdownMenuItem>
              <DropdownMenuItem
                disabled={!specificInsightsId}
                className="cursor-pointer text-xs font-medium"
                onClick={() => {
                  dispatch(
                    setModal({
                      open: true,
                      type: MODALTYPES.MOVE_REPORT_TO_FOLDER,
                      payload: {},
                    })
                  );
                }}
              >
                <Copy className="mr-2 h-4 w-4" />
                <span>Move to new folder</span>
              </DropdownMenuItem>
              <DropdownMenuItem
                disabled={!specificInsightsId}
                className="cursor-pointer text-xs font-medium"
                onClick={() => {
                  duplicateReport({
                    reportId: report?.reportableId,
                  });
                }}
              >
                <Layers2 className="mr-2 h-4 w-4" />
                <span>Duplicate report</span>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                disabled={!specificInsightsId}
                className="cursor-pointer text-xs font-medium"
                onClick={() => {
                  dispatch(
                    setModal({
                      type: MODALTYPES.COMMON_ALERT,
                      open: true,
                      payload: {
                        action: () => deleteReport({ id: specificInsightsId }),
                        title: "Are you sure?",
                        description:
                          "This action cannot be undone. This will permanently delete the report.",
                      },
                    })
                  );
                }}
              >
                <Trash className="mr-2 h-4 w-4" />
                <span>Delete Report</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>

        {specificInsightsId &&
          !disableFilters &&
          !hideSidebar &&
          report?.isChatEnabled &&
          (isDrawerOpen ? (
            <Button
              disabled={
                hideSidebar ||
                disableFilters ||
                !report?.isChatEnabled ||
                !specificInsightsId
              }
              variant="outline"
              size="icon"
              className="text-xs"
              onClick={() => setIsDrawerOpen((prev) => !prev)}
            >
              <MessageCircleOff className="h-4 w-4" />
            </Button>
          ) : (
            <Button
              className="text-xs"
              variant="outline"
              size="icon"
              onClick={() => setIsDrawerOpen((prev) => !prev)}
            >
              <MessageCircle className="h-4 w-4" />
            </Button>
          ))}
      </div>
    </>
  );
};

export default HeaderFeatures;
