import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAxios from "../../../../../../hooks/useAxios";
import { API_ROUTES } from "../../../../../../routes";

export default function useBrandColors() {
  const [brandPallets, setBrandPallets] = useState([]);
  const { axiosOrgInstance } = useAxios();

  function getBrandColors() {
    let logos = [];
    setBrandPallets(logos);
    axiosOrgInstance.get(API_ROUTES.BRAND_KIT.BRAND_COLOR.GET).then((res) => {
      setBrandPallets(res?.docs);
    });
  }

  function deleteBrandColor(id, color) {
    axiosOrgInstance
      .delete(
        API_ROUTES.BRAND_KIT.BRAND_COLOR.DELETE?.replace(":brandColorId", id),
        {
          data: {
            color: color,
          },
        }
      )
      .then(() => {
        toast.success(`Color deleted successfully`);
        getBrandColors();
      });
  }

  function deleteBrandPallete(pallete) {
    axiosOrgInstance
      .delete(
        API_ROUTES.BRAND_KIT.BRAND_COLOR.DELETE?.replace(
          ":brandColorId",
          pallete?._id
        )
      )
      .then(() => {
        toast.success(`${pallete?.colorPaletteName} deleted successfully`);
        getBrandColors();
      });
  }

  function addBrandPallete(pallete) {
    if (pallete?._id) {
      let id = pallete?._id;
      delete pallete?._id;
      let payload = {
        color: pallete?.color,
      };
      axiosOrgInstance
        ?.patch(
          API_ROUTES.BRAND_KIT.BRAND_COLOR.UPDATE?.replace(":brandColorId", id),
          payload
        )
        .then(() => {
          toast.success(
            `${pallete?.color} added to ${pallete?.colorPaletteName}`
          );
          getBrandColors();
        });

      return;
    }

    axiosOrgInstance
      .post(API_ROUTES.BRAND_KIT.BRAND_COLOR.CREATE, pallete)
      .then(() => {
        getBrandColors();
      });
  }

  useEffect(() => {
    getBrandColors();
  }, []);

  return {
    brandPallets,
    deleteBrandColor,
    deleteBrandPallete,
    addBrandPallete,
  };
}
