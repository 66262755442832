import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_ROUTES, APP_ROUTES } from "../../../../../routes";

import useAxios from "../../../../../hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllOrganisations,
  setOrganisationToken,
  setSelectedOrganisation,
} from "../../../../../redux/slices/organisation.slice";
import { toast } from "react-toastify";
import { COMMON } from "apis/apiEndPoints";
import { setAppState } from "redux/slices/appState.slices";

export default function useAddOrg() {
  const { user } = useSelector((state) => state?.auth?.userData);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { axiosUserInstance } = useAxios();

  const [state, setState] = React.useState({
    name: "",
    accounts: "",
    industry: "",
    website: "",
    adSpend: "",
  });
  const [errors, setErrors] = React.useState({
    name: "",
    accounts: "",
    industry: "",
    website: "",
    adSpend: "<$50,000",
  });

  const [agencyState, setAgencyState] = React.useState({
    name: "",
    agencyName: "",
    website: "",
    activeClients: "<5",
    adSpend: "<$50,000",
    teamSize: "",
    inHouseCreatives: "",
  });
  const [agencyErrors, setAgencyErrors] = React.useState({
    name: "",
    agencyName: "",
    website: "",
    activeClients: "",
    adSpend: "",
    teamSize: "",
    inHouseCreatives: "",
  });

  const [isValid, setIsValid] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAgencyChange = (e) => {
    const { name, value } = e.target;
    setAgencyState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validate = useCallback(() => {
    let nameError = "";
    // let accountsError = "";
    // let websiteError = "";
    // let industryError = "";
    // let adSpendError = "";
    if (!state.name) {
      nameError = "Name is required";
    }
    // if (!state.accounts) {
    //   accountsError = "Accounts is required";
    // }
    // if (!state.adSpend) {
    //   adSpendError = "Ad Spend is required";
    // }
    // if (!state.website) {
    //   websiteError = "Website is required";
    // }
    // if (!state.industry) {
    //   industryError = "Industry is required";
    // }
    if (
      nameError
      // accountsError ||
      // industryError ||
      // websiteError ||
      // adSpendError
    ) {
      setErrors({
        name: nameError,
        // accounts: accountsError,
        // industry: industryError,
        // website: websiteError,
        // adSpend: adSpendError,
      });
      return false;
    }
    return true;
  }, [state]);

  const validateAgency = useCallback(() => {
    let nameError = "";
    let agencyNameError = "";
    let activeClientsError = "";
    let websiteError = "";
    let adSpendError = "";
    let teamSizeError = "";
    let inHouseCreativesError = "";
    if (!agencyState.name) {
      nameError = "Name is required";
    }
    if (!agencyState.agencyName) {
      nameError = "Name is required";
    }
    if (!agencyState.activeClients) {
      activeClientsError = "Accounts is required";
    }
    if (!agencyState.adSpend) {
      adSpendError = "Ad Spend is required";
    }
    if (!agencyState.website) {
      websiteError = "Website is required";
    }
    if (!agencyState.teamSize) {
      teamSizeError = "Industry is required";
    }
    if (!agencyState.inHouseCreatives) {
      inHouseCreativesError = "Industry is required";
    }
    if (
      nameError ||
      agencyNameError ||
      activeClientsError ||
      websiteError ||
      adSpendError ||
      teamSizeError ||
      inHouseCreativesError
    ) {
      setAgencyErrors({
        name: nameError,
        agencyName: agencyNameError,
        activeClients: activeClientsError,
        teamSize: teamSizeError,
        website: websiteError,
        adSpend: adSpendError,
        inHouseCreatives: inHouseCreativesError,
      });
      return false;
    }
    return true;
  }, [agencyState]);

  const { axiosInstance } = useAxios();
  function getOrgToken(id) {
    axiosUserInstance
      .get(API_ROUTES.AUTH.GET_ORG_TOKEN.replace(":organizationId", id))
      .then((res) => {
        dispatch(setOrganisationToken(res?.token));
        axiosInstance
          .get(COMMON.APPLICATION_STATUS, {
            headers: {
              Authorization: `Bearer ${res?.token}`,
            },
          })
          .then((res) => {
            dispatch(setAppState(res));
            navigate(APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT);
          });
        // navigate(APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS);
        // navigate(APP_ROUTES.DASHBOARD);
      });
  }

  function getOrgList(id) {
    axiosUserInstance
      .get(`${API_ROUTES.AUTH.LIST_ORG}`)
      .then((res) => {
        let orgList = res?.docs;
        dispatch(setAllOrganisations(res?.docs));
        let selectedOrg = orgList.find((org) => org._id === id);
        dispatch(setSelectedOrganisation(selectedOrg));
        getOrgToken(id);
      })
      .catch((err) => toast("Error fetching organisations"));
  }

  function handleCancel() {
    navigate(APP_ROUTES.DASHBOARD);
  }

  function CreateOrganization() {
    let payload;
    if (user?.accountType === "Agency") {
      payload = {
        name: agencyState.name,
        questions: {
          agencyName: agencyState.agencyName,
          activeClients: agencyState.activeClients,
          website: agencyState.website,
          adSpend: agencyState.adSpend,
          teamSize: agencyState.teamSize,
          inHouseCreatives: agencyState.inHouseCreatives,
        },
      };
    } else {
      payload = {
        name: state.name,
        questions: {
          adAccountsConnected: state.accounts,
          industry: state.industry,
          website: state.website,
          adSpend: state.adSpend,
        },
      };
    }
    axiosUserInstance
      .post(API_ROUTES.AUTH.CREATE_ORG, payload)
      .then((res) => {
        getOrgList(res.organizationId);

        const eventPayload = {
          event: "new_organization_create",
          User_Details: {
            user_id: user?._id,
            email: user?.email,
            first_name: user?.firstName,
            last_name: user?.lastName,
            username: user?.userName,
            user_type: user?.userType,
            created_at: user?.createdAt,
          },
          Organization_Details: {
            organization_id: res?.organizationId,
            organization_name: state.name,
          },
        };

        if (window.dataLayer) window.dataLayer.push(eventPayload);
      })
      .catch((err) => {
        console.log(err);
        toast(err);
      });
  }

  useEffect(() => {
    if (user?.accountType === "Agency") {
      let valid = validateAgency();
      setIsValid(valid);
    } else {
      let valid = validate();
      setIsValid(valid);
    }
  }, [state, validate, agencyState]);

  const handleSubmit = (e) => {
    e.preventDefault();
    CreateOrganization();
  };
  return {
    state,
    isValid,
    agencyState,
    errors,
    agencyErrors,
    handleChange,
    handleAgencyChange,
    handleSubmit,
    handleCancel,
  };
}
