import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";

import { stringAvatar } from "../../../../helpers/mui.helpers";
import useAxios from "../../../../hooks/useAxios";
import { API_ROUTES, APP_ROUTES } from "../../../../routes";

import { useNavigate } from "react-router-dom";
import {
  setOrganisationToken,
  setSelectedOrganisation,
} from "../../../../redux/slices/organisation.slice";

const profileHeaderStyles = makeStyles(() => ({
  profileMainDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "30px",
  },
  profileLeftDiv: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  profileRightDiv: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  profileNameText: {
    fontFamily: "DM Sans !important",
    fontWeight: "500 !important",
    fontSize: "22px !important",
    color: "#1C1E20 !important",
  },
  profileShortText: {
    height: "24px",
    width: "24px",
    background: "#1C1E20",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "DM Sans !important",
    fontWeight: "600 !important",
    fontSize: "12px !important",
    color: "#FFF !important",
    textTransform: "capitalize",
  },
  profileCompanyText: {
    fontFamily: "DM Sans !important",
    fontWeight: "400 !important",
    fontSize: "20px !important",
    color: "#1C1E20 !important",
  },
  dropdownText1: {
    fontFamily: "DM Sans !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    color: "#1C1E20 !important",
  },
  dropdownText2: {
    fontFamily: "DM Sans !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    color: "rgba(28, 30, 32, 0.7) !important",
    lineHeight: "20px !important",
  },
  dropdownText3: {
    fontFamily: "DM Sans !important",
    fontWeight: "400 !important",
    fontSize: "10px !important",
    color: "rgba(28, 30, 32, 0.7) !important",
  },
  dropdownMenu: {
    "& .MuiPaper-root": {
      width: "337px",
      background: "#FFFFFF",
      border: "0.5px solid rgba(28, 30, 32, 0.5)",
      boxShadow: "0px 65px 177px rgba(0, 0, 0, 0.12)",
      borderRadius: "6px",
      padding: "16px 10px 4px 10px",
      marginTop: "20px",
    },
  },
  organizationDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "48px",
    borderRadius: "4px",
    padding: "0px 6px",
    marginTop: "8px",
    cursor: "pointer",
  },
  organizationDivActive: {
    background: "rgba(113, 103, 251, 0.05)",
  },
  organizationInnerDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const ProfileHeader = () => {
  const classes = profileHeaderStyles();
  const { axiosUserInstance } = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { allOrganisations = [], selectedOrganisation } = useSelector(
    (state) => state.organisation
  );

  const { userData } = useSelector((state) => state.auth);
  const userName = `${userData?.user?.firstName ?? ""} ${
    userData?.user?.lastName ?? ""
  }`;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getOrgToken(org) {
    axiosUserInstance
      .get(API_ROUTES.AUTH.GET_ORG_TOKEN.replace(":organizationId", org?._id))
      .then((res) => {
        dispatch(setOrganisationToken(res?.token));
        if (org?.adAccountIds?.length === 0) {
          navigate(APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT);
        } else {
          setTimeout(() => {
            navigate(APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS);
          }, 2000);
        }
      });
  }
  const organisationChangeHandler = (org) => {
    handleClose();
    dispatch(setSelectedOrganisation(org));
    getOrgToken(org);
  };

  return (
    <div className={classes.profileMainDiv}>
      <div className={classes.profileLeftDiv}>
        <div
          style={{
            height: "40px",
            width: "40px",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          <Avatar {...stringAvatar(userName)}></Avatar>
        </div>
        <Typography className={classes.profileNameText}>
          Hi, {userName}
        </Typography>
      </div>
      {/* <div className={classes.profileRightDiv}>
        <div className={classes.profileShortText}>
          {selectedOrganisation?.name?.[0] ?? ""}
        </div>
        <Typography className={classes.profileCompanyText}>
          {selectedOrganisation?.name ?? ""}
        </Typography>
        <div
          onClick={handleClick}
          style={{ cursor: "pointer", marginLeft: "20px" }}
        >
          <DownArrow />
        </div>
      </div> */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.dropdownMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography className={classes.dropdownText1}>
          Select Organization
        </Typography>
        {allOrganisations.map((org) => {
          return (
            <div
              key={org._id}
              className={`${classes.organizationDiv} ${
                org._id === selectedOrganisation._id &&
                classes.organizationDivActive
              }`}
              onClick={() => organisationChangeHandler(org)}
            >
              <Typography className={classes.dropdownText2}>
                {org?.name}
              </Typography>
              <div className={classes.organizationInnerDiv}>
                <Typography className={classes.dropdownText3}>
                  {org?.adAccountIds?.length ?? 0} Account Linked
                </Typography>
              </div>
            </div>
          );
        })}
      </Menu>
    </div>
  );
};

export default ProfileHeader;
