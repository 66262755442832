import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { FileUploadDnD } from "components/common/molecules";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { API_ROUTES } from "routes";
import { toast } from "react-toastify";
import useAxios from "hooks/useAxios";
import { useNavigate } from "react-router-dom";
import useProfileDetails from "./useProfileDetails";
import { useGetUploadUrls } from "apis/common";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoading } from "redux/slices/app.slices";

const UserSettings = () => {
  const navigate = useNavigate();
  const [isDeactivate, setIsDeactivate] = useState("");
  const { axiosUserInstance } = useAxios();

  const {
    profileDetails,
    setProfileDetails,
    updateBasicDetails,
    updateUserDetails,
    isLoading,
    user,
  } = useProfileDetails();

  function DeactivateAccount() {
    axiosUserInstance.patch(API_ROUTES.USER.DEACTIVATE_USER).then((res) => {
      toast.success("Account Deactivated Successfully");
      navigate("/logout");
    });
  }

  const dispatch = useDispatch();
  const { mutateAsync: handleFilesUpload } = useGetUploadUrls();

  function getUploadUrls(file) {
    const fileNames = [file.name];
    const extensions = [file.name.split(".").pop()];
    const path = "predictions";
    const n = 1;
    const payload = {
      fileNames,
      extensions,
      path,
      n: n.toString(),
    };

    handleFilesUpload(payload).then((res) => {
      dispatch(setLoading(true));
      const urls = res?.map((item) => item?.putSignedUrl);
      const slugs = res?.map((item) => item?.key);
      //perform upload of the files to these urls
      axios
        .put(urls[0], file, {
          headers: {
            "Content-Type": file.type,
          },
        })
        .then(() => {
          const payload = {
            profilePicture: slugs[0],
          };
          updateUserDetails(payload);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    });
  }

  return (
    <div className="space-y-4">
      <div>
        <CardHeader>
          <CardTitle className="text-xl">Account</CardTitle>
          <CardDescription>
            Make changes to your account here. Click save when you're done.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-1">
            <div className="flex gap-x-3 mb-2 justify-center">
              <FileUploadDnD
                uploaderStyles={{
                  border: "none",
                  cursor: "pointer",
                  width: "fit-content",
                }}
                extensions={{
                  "image/jpeg": [".jpg", ".jpeg"],
                  "image/png": [".png"],
                }}
                onUpload={(files) => {
                  getUploadUrls(files[0]);
                }}
              >
                <Avatar className="w-20 h-20">
                  <AvatarImage
                    // src={profileDetails?.profilePicture}
                    src={user?.profilePicture}
                    alt="user profile pic"
                  />
                  <AvatarFallback className="capitalize text-2xl text-center">
                    {user?.userName?.[0]}
                  </AvatarFallback>
                </Avatar>
              </FileUploadDnD>
            </div>
            <div className="flex gap-x-3 mb-2 justify-center">
              <div id="email">{profileDetails?.email ?? ""}</div>
            </div>
          </div>

          <div className="space-x-2">
            <Label htmlFor="name">Name:</Label>
            {isLoading ? (
              <Skeleton />
            ) : (
              <Input
                id="name"
                defaultValue={profileDetails?.userName}
                className="inline-block w-fit"
                onChange={(e) => {
                  setProfileDetails((prev) => ({
                    ...prev,
                    userName: e.target.value,
                  }));
                }}
              />
            )}
          </div>

          <div className="space-x-2">
            <Label htmlFor="name">Email:</Label>
            {isLoading ? (
              <Skeleton />
            ) : (
              <span id="email" className="w-fit">
                {profileDetails?.email}{" "}
              </span>
            )}
          </div>

          <div className="space-x-2">
            <Label htmlFor="name">Username:</Label>
            {isLoading ? (
              <Skeleton />
            ) : (
              <span id="email" className="w-fit">
                @{profileDetails?.userName}{" "}
              </span>
            )}
          </div>

          {/* <div className="space-x-2 mt-4">
            <Label htmlFor="account-type">Account Type:</Label>
            {isLoading ? (
              <Skeleton />
            ) : (
              <div className="inline-block items-center space-x-2">
                <Label htmlFor="airplane-mode">Brand Owner</Label>
                <Switch
                  id="airplane-mode"
                  checked={profileDetails?.accountType === "Agency"}
                  onChange={(e) => {
                    setProfileDetails((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                  onCheckedChange={(val) => {
                    setProfileDetails((prev) => ({
                      ...prev,
                      accountType: val ? "Agency" : "Brand Owner",
                    }));
                  }}
                />
                <Label htmlFor="airplane-mode">Agency</Label>
              </div>
            )}
          </div> */}
        </CardContent>
        <CardFooter>
          <Button variant="outline" onClick={updateBasicDetails}>
            Save
          </Button>
        </CardFooter>
      </div>

      <div>
        <CardHeader>
          <CardTitle className="text-lg">Deactivate Account</CardTitle>
          <CardDescription className="text-xs">
            This Will Make Your Account Permanently Unusable, To Continue,
            Please Type "DEACTIVATE".
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-2">
          <div className="space-y-1">
            <Label htmlFor="current">Type Here</Label>
            <Input
              id="current"
              onChange={(e, v) => setIsDeactivate(e?.target?.value)}
              className="w-fit"
            />
          </div>
        </CardContent>
        <CardFooter>
          <Button
            disabled={isDeactivate !== "DEACTIVATE"}
            onClick={() => DeactivateAccount()}
            variant="destructive"
          >
            Deactivate
          </Button>
        </CardFooter>
      </div>
    </div>
  );
};
export default UserSettings;
