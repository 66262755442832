import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import useAxios from "../../../../../../hooks/useAxios";
import { API_ROUTES } from "../../../../../../routes";

export default function useBrandfont() {
  const { axiosOrgInstance } = useAxios();
  const [googleFonts, setGoogleFonts] = useState([]);
  const [customFonts, setCustomFonts] = useState([]);

  function addGoogleFont(data) {
    const payload = {
      brandFontType: 2,
      brandFontName: data.family ?? "Open Sans",
    };
    axiosOrgInstance
      .post(API_ROUTES.BRAND_KIT.BRAND_FONT.CREATE, payload)
      .then((res) => {
        toast.success("Font added successfully");
        getGoogleFonts();
      });
  }

  function addCustomFont(files) {
    const file = files[0];
    const formData = new FormData();
    formData.append("brandFontFile", file);
    formData.append("brandFontType", 1);
    formData.append("brandFontName", file?.name);

    axiosOrgInstance
      .post(API_ROUTES.BRAND_KIT.BRAND_FONT.CREATE, formData)
      .then((res) => {
        toast.success("Font added successfully");
        getCustomFonts();
      });
  }

  const getGoogleFonts = useCallback(() => {
    axiosOrgInstance
      .get(API_ROUTES.BRAND_KIT.BRAND_FONT.GET, {
        params: {
          brandFontType: 2,
        },
      })
      .then((res) => {
        setGoogleFonts(res?.docs);
      });
  }, [axiosOrgInstance]);

  const getCustomFonts = useCallback(() => {
    axiosOrgInstance
      .get(API_ROUTES.BRAND_KIT.BRAND_FONT.GET, {
        params: {
          brandFontType: 1,
        },
      })
      .then((res) => {
        setCustomFonts(res?.docs);
      });
  }, [axiosOrgInstance]);

  function deleteFont(id, type) {
    axiosOrgInstance
      .delete(
        API_ROUTES.BRAND_KIT.BRAND_FONT.DELETE?.replace(":brandFontId", id)
      )
      .then((res) => {
        toast.success("Font deleted successfully");
        type === 1 ? getCustomFonts() : getGoogleFonts();
      });
  }

  useEffect(() => {
    getGoogleFonts();
    getCustomFonts();
  }, [getGoogleFonts, getCustomFonts]);

  return { addGoogleFont, addCustomFont, googleFonts, customFonts, deleteFont };
}
