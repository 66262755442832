import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { BackButton } from "components/common/atoms";
import { PrivateContainer } from "components/layouts";
import { AudienceTaggingModal } from "components/modals";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { APP_ROUTES } from "routes";
import useAudienceTagging from "./useAudienceTagging";

export default function UntaggedAudience() {
  const dispatch = useDispatch();
  const { groups, initGroups } = useAudienceTagging(false);

  const styleTable = { borderCollapse: "separate", borderSpacing: "0px 16px" };

  const styleTableRow = {
    backgroundColor: "#F9FAFB",
    borderColor: "rgba(161, 176, 204, 0.5);",
    cursor: "pointer",
    width: "100%",
  };

  const styleCellContent = {
    color: "rgba(28, 30, 32, 0.7)",
  };
  return (
    <PrivateContainer
      activePage={APP_ROUTES.GROUPS_TAGS.UNTAGGED.AUDIENCE}
      bodyStyles={{ justifyContent: "flex-start" }}
    >
      <BackButton />
      <TableContainer
        className="content-container"
        style={{ maxHeight: "80vh" }}
      >
        <Table stickyHeader aria-label="sticky table" sx={styleTable}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Audience Name</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Size</TableCell>
              <TableCell align="left">Date Created</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {groups.map((row) => (
              <TableRow
                sx={styleTableRow}
                onClick={() =>
                  dispatch(
                    setModal({
                      open: true,
                      type: MODALTYPES.AUDIENCE_TAGGING_MODAL,
                      payload: { row },
                    })
                  )
                }
              >
                <TableCell align="left">
                  <Typography variant="subtitle2">
                    {row?.groupEntityIds?.[0]?.name}
                  </Typography>
                  <Typography variant="caption">
                    {row?.groupEntityIds?.[0]?._id}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={styleCellContent}>
                    {row?.groupEntityIds?.[0]?.type ?? "-"}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={styleCellContent}>
                    {row?.groupEntityIds?.[0]?.size ?? "-"}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography sx={styleCellContent}>
                    {moment(row?.groupEntityIds?.[0]?.timeCreated).format(
                      "DD/MM/YYYY"
                    )}
                  </Typography>
                  <Typography sx={styleCellContent}>
                    {moment(row?.groupEntityIds?.[0]?.timeCreated).format(
                      "HH:mm A"
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AudienceTaggingModal initGroups={initGroups} />
    </PrivateContainer>
  );
}
