import { useMutation } from "@tanstack/react-query";
import { COMMON, ORGANISATION } from "apis/apiEndPoints";
import {
  useCancelSubscription,
  useDeactivateOrganisation,
  useGetAvailableCurrencies,
  useGetModelKPI,
  useGetOrganisationList,
  useRefreshOrganization,
} from "apis/common";
import useAuth from "hooks/useAuth";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setAppState } from "redux/slices/appState.slices";
import {
  setAllOrganisations,
  setSelectedOrganisation,
} from "redux/slices/organisation.slice";
import useAxios from "../../../../../../../../hooks/useAxios";
import { API_ROUTES } from "../../../../../../../../routes";

export default function useOrganisationDetail(selectedOrganisation) {
  const [adAccounts, setAdAccounts] = useState([]);
  const [users, setUsers] = useState([]);
  const [isSyncButtonDisable, setIsSyncButtonDisable] = useState(false);
  const { axiosOrgInstance } = useAxios();
  const [isOrgUsersLoading, setIsOrgUsersLoading] = useState(false);

  const { selectedOrganisation: reduxSelectedOrg } = useSelector(
    (state) => state?.organisation
  );

  const dispatch = useDispatch();
  const { isUserAuthenticated } = useAuth();
  const { data: currencies, isLoading: isCurrencyLoading } =
    useGetAvailableCurrencies(isUserAuthenticated && !!reduxSelectedOrg?.token);

  const { mutate: cancelSubscription, isLoading: isCancellingSub } =
    useCancelSubscription();

  const { mutate: deactivateOrganisation, isLoading: isDeactivatingOrg } =
    useDeactivateOrganisation();

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data: organisationList,
    refetch: refetchOrgList,
  } = useGetOrganisationList(isUserAuthenticated);

  const { mutate: refreshOrganisation } = useRefreshOrganization();

  const handleOrganisationRefresh = () => {
    if (reduxSelectedOrg?.platforms?.length === 0) {
      return toast("Error! No platform added to be synced");
    }
    let connector = [];
    if (Array.isArray(reduxSelectedOrg?.platforms)) {
      connector = reduxSelectedOrg?.platforms?.filter(
        (curr) => !curr?.isInternal
      );
      console.log("IN ARRAY, connector", connector);
    } else {
      if (!reduxSelectedOrg?.platforms?.isInternal) {
        connector = [...reduxSelectedOrg?.platforms];
      }
      console.log("IN OBJECT, connector", connector);
    }
    if (connector?.length === 0) {
      return toast("Error! Fivetran connector not connected");
    }
    const requestPayload = {
      created: "2023-10-15T14:23:53.422Z",
      connector_type: "",
      connector_id: connector?.[0]?.connectorId,
      data: {
        status: "SUCCESSFUL",
        reason: "",
        rescheduledAt: "",
      },
      destination_group_id: "",
      event: "sync_end",
      sync_id: "",
    };
    console.log(requestPayload);
    refreshOrganisation(requestPayload);
  };

  const [changedCurrency, setChangedCurrency] = useState(null);
  const changeVisibilityMutationFn = (data) => {
    axiosOrgInstance
      .patch(
        API_ROUTES.ORGANISATION.UPDATE_DEFAULT_CURRENCY.replace(
          ":id",
          data?.orgId
        ).replace(":new", data?.currency)
      )
      .then((res) => {
        toast("Currency changed successfully");
        setChangedCurrency(data?.currency);
        refetchOrgList(true);
        getAppStatus();
        return res;
      })
      .catch((e) => toast("Error changing currency"));
  };

  const funny = useMutation({
    mutationFn: changeVisibilityMutationFn,
  });

  const {
    data: modelKPIs,
    isLoading: isModelKPIsLoading,
    refetch: refetchModelKPIs,
  } = useGetModelKPI(false);

  function refreshFunnelStages() {
    axiosOrgInstance
      .get(ORGANISATION.REFRESH_FUNNEL_STAGES)
      .then(() => toast("Refresh intitiated successfully"))
      .catch((err) => toast("Unable to intitiate refresh"));
  }

  function getAppStatus() {
    axiosOrgInstance.get(COMMON.APPLICATION_STATUS).then((res) => {
      dispatch(setAppState(res));
    });
  }

  async function handleCurrencyChange(currency, orgId) {
    funny.mutate({ currency, orgId });
  }

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    } else if (!hasNextPage && !isFetchingNextPage) {
      const res = organisationList?.pages?.reduce(
        (acc, curr) => [...acc, ...curr?.docs],
        []
      );
      dispatch(setAllOrganisations(res));
    }
  }, [
    dispatch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    organisationList?.pages,
  ]);

  useEffect(() => {
    if (isUserAuthenticated) {
      refetchOrgList();
    }
  }, []);

  useEffect(() => {
    const res = organisationList?.pages?.reduce(
      (acc, curr) => [...acc, ...curr?.docs],
      []
    );
    dispatch(setAllOrganisations(res));
  }, [organisationList]);

  function getOrgUsers() {
    setIsOrgUsersLoading(true);
    axiosOrgInstance.get(API_ROUTES.ORGANISATION.GET_USERS).then((res) => {
      setIsOrgUsersLoading(false);
      let _users = res?.users?.map((user) => {
        return {
          userId: user?._id,
          email: user?.email,
          userName: user?.userName,
          name: `${user?.firstName ?? ""} ${user?.lastName ?? ""}`,
          inviteDate: moment(user?.createdAt).format("DD MMM YYYY"),
          user_role: user?.role,
          profilePicture: user?.profilePicture,
        };
      });
      setUsers(_users);
      // refetchModelKPIs(true);
    });
  }

  useEffect(() => {
    if (reduxSelectedOrg?._id !== selectedOrganisation?._id) {
      dispatch(setSelectedOrganisation(selectedOrganisation));
    }
  }, []);

  useEffect(() => {
    if (selectedOrganisation?._id !== reduxSelectedOrg?._id) return;
    else {
      getOrgUsers();
      refetchModelKPIs(true);
    }
  }, []);

  return {
    modelKPIs,
    isOrgUsersLoading,
    adAccounts,
    users,
    currencies,
    isCurrencyLoading,
    isModelKPIsLoading,
    changedCurrency,
    getOrgUsers,
    handleCurrencyChange,
    handleOrganisationRefresh,
    isSyncButtonDisable,
    refreshFunnelStages,
    cancelSubscription,
    isCancellingSub,
    isDeactivatingOrg,
    deactivateOrganisation,
  };
}
