import { CometChat } from "@cometchat-pro/chat";
import { CometChatUIKit } from "@cometchat/chat-uikit-react";
import {
  useGetSpecificPrediction,
  useGetPaginatedMediaForPrediction,
} from "apis/predictionApi";
import { useBookmarkReport, useRemoveBookmark } from "apis/reportsApi";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useReports from "hooks/useReports";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setBookmarkReports,
  setUserBookmarksAfterUnbookmarking,
} from "redux/slices/app.slices";
import { checkIfGroupExists, createGroup } from "utils/CometChat";

export default function usePredictionReportDetails() {
  const { reportableId = "" } = useParams();
  const { hideSidebar, currentReportOrgId } = useReports();
  const userData = useSelector((state) => state.auth.userData);

  const {
    data: predictionReportDetails,
    isLoading: loadingReport,
    refetch: refetchSpecificPrediction,
    isSuccess: isReportFetched,
  } = useGetSpecificPrediction(reportableId, currentReportOrgId);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { data: predictionMedias, fetchNextPage } =
    useGetPaginatedMediaForPrediction(reportableId, currentReportOrgId);

  useEffect(() => {
    refetchSpecificPrediction();
  }, []);

  const [currentGroup, setCurrentGroup] = useState(null);
  async function joinGroup(groupId, triedOnce = false) {
    try {
      await CometChat.joinGroup(groupId, CometChat.GROUP_TYPE.PUBLIC, "");
      CometChat.getGroup(groupId).then((group) => setCurrentGroup(group));
    } catch (error) {
      if (error?.code === "ERR_ALREADY_JOINED") {
        CometChat.getGroup(groupId).then((group) => setCurrentGroup(group));
      }

      if (error?.code === "USER_NOT_LOGED_IN") {
        if (triedOnce) {
          return;
        }

        if (!userData?.user) return;
        const authKey = process.env.REACT_APP_COMETCHAT_AUTH_KEY;
        const uid = userData?.user?._id;

        CometChatUIKit.login(uid, authKey)
          .then((loggedInUser) => {
            joinGroup(groupId, true);
          })
          .catch((loginError) => {
          });
      }
    }
  }

  const flatData = predictionMedias?.pages?.map((page) => page.data).flat();
  const [currentPage, setCurrentPage] = useState(1);
  const { totalPages = 1 } =
    predictionMedias?.pages?.[0]?.meta?.pagination ?? {};
  const hasNext = currentPage < totalPages;

  const { mutateAsync: bookmarkReport, isSuccess: isBookmarkSuccess } =
    useBookmarkReport();

  const dispatch = useDispatch();
  function handleBookmarkReport(inputs) {
    const payload = {
      reportId: reportableId,
    };
    const res = bookmarkReport(payload);
    if (res) {
      dispatch(setBookmarkReports(reportableId));
      toast("Report bookmarked Successfully");
    }
  }

  const { isLoadMore } = useInfiniteScroll("content-container", hasNext);
  const meta = predictionReportDetails?.meta ?? "";
  const report = predictionReportDetails?.report ?? null;
  useEffect(() => {
    if (!isReportFetched || !report) return;
    const groupId = report?.reportableId;
    const groupName = report?.name;
    checkIfGroupExists(groupId).then((existingGroup) => {
      if (existingGroup) {
        // If group exists, join the user to the group
        joinGroup(groupId);
      } else {
        // If group does not exist, create the group and then join the user
        createGroup(groupId, groupName).then((newGroup) => {
          if (newGroup) {
            // Group created successfully, now join the user
            joinGroup(groupId);
          }
        });
      }
    });
  }, [isReportFetched, report]);
  let metaParsed = [];
  let reportMeta = null;
  try {
    reportMeta = report?.meta ? JSON.parse(report?.meta) : null;
    if (typeof meta === "string") {
      const jsonMeta = meta.replace(/'/g, '"').replace(/None/g, "null");
      metaParsed = JSON.parse(jsonMeta);
    } else {
      metaParsed = meta;
    }
  } catch (e) {
    console.error(e);
  }
  useEffect(() => {
    const hasNext = currentPage < totalPages;

    if (isLoadMore && hasNext) {
      setCurrentPage((prev) => prev + 1);
      fetchNextPage();
    }
  }, [isLoadMore, hasNext, fetchNextPage, currentPage, totalPages]);

  const {
    mutateAsync: removeBookmarkMutate,
    isSuccess: isRemoveBookmarkSuccess,
    isError: isRemoveBookmarkError,
  } = useRemoveBookmark();
  function handleRemoveBookmark() {
    removeBookmarkMutate(reportableId);
  }

  useEffect(() => {
    if (isRemoveBookmarkSuccess) {
      toast("Removed from bookmarks");
      dispatch(setUserBookmarksAfterUnbookmarking(reportableId));
    } else if (isRemoveBookmarkError) {
      toast("Try again");
    }
  }, [isRemoveBookmarkError, isRemoveBookmarkSuccess]);

  return {
    report: report,
    predictionMedias: flatData ?? [],
    meta: metaParsed,
    reportMeta: reportMeta,
    hideSidebar,
    currentReportOrgId,
    isBookmarkSuccess,
    reportableId,
    currentGroup,
    loadingReport,
    handleBookmarkReport,
    handleRemoveBookmark,
  };
}
