import React from "react";
import { ContentContainer, PrivateContainer } from "components/layouts";
import { BackButton } from "components/common/atoms";
import { Avatar, Button, TextField } from "@mui/material";

import useGroupContent from "./useGroupContent";

import styles from "./styles.module.scss";
import { Check } from "@mui/icons-material";
import { BasicActionsModal } from "components/modals";
import { MODALTYPES } from "redux/slices/modal.slices";
import ImageTaggingModal from "components/modals/ImageTaggingModal";
import VideoTaggingModal from "components/modals/VideoTaggingModal.jsx";
import ReactPlayer from "react-player";
import { APP_ROUTES } from "routes";
import { storageUrlToCdn } from "utils/helper";

const Card = (props) => {
  const {
    _id,
    imageUrl,
    groupEntity,
    videoUrl,
    isSelected,
    handleSelect,
    handlePlayPause,
    isPlaying,
  } = props;

  function handleClick() {
    handleSelect(_id);
  }
  return (
    <div className={styles.creative_card} key={_id}>
      <Avatar
        sx={{
          backgroundColor: "#fff",
          height: "24px",
          width: "24px",
          position: "absolute",
          top: "16px",
          right: "16px",
          border: "1px solid #3f51b5",
          zIndex: 1,
        }}
        onClick={handleClick}
      >
        {isSelected && <Check color="primary" />}
      </Avatar>

      <div className={styles.thumbnail}>
        {groupEntity === "image" ? (
          <img src={storageUrlToCdn(imageUrl)} alt="thumbnail" />
        ) : (
          <ReactPlayer
            url={storageUrlToCdn(videoUrl)}
            controls
            className="react-player"
            key={_id}
            playing={isPlaying}
            onStart={() => handlePlayPause(_id)}
            onPlay={() => handlePlayPause(_id)}
            onClick={() => handlePlayPause(_id)}
            width="100%"
            height="100%"
          />
        )}
      </div>
    </div>
  );
};
export default function GroupsContentScreen() {
  const {
    // These are for Group Content
    contentList,
    handleSelect,
    updateGroup,
    groupEntity,

    // These are for Basic Action Modal
    addManualTagsPrimaryAction,
    addManualTagsSecondaryAction,
    removeImagesPrimaryAction,
    removeImagesSecondaryAction,

    // These are for Group Names
    groupNameRef,
    updateGroupNameCall,

    //For video
    handlePlayPause,
  } = useGroupContent();

  return (
    <PrivateContainer>
      <div className={styles.group_content_screen__container}>
        <BackButton
          to={APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS}
          from={groupEntity}
        />
        <div className={styles.header}>
          <div className={styles.left}>
            Group Name:
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "26px",
                },
              }}
              inputRef={groupNameRef}
              placeholder="Give a name to group"
            />
          </div>
          <div className={styles.right}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                const willNavigate = true;
                updateGroup(willNavigate);
              }}
            >
              Remove Entities
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => updateGroupNameCall()}
            >
              Update & Tag
            </Button>
          </div>
        </div>

        <ContentContainer className={styles.content}>
          {contentList?.map((content) => (
            <Card
              {...content}
              groupEntity={groupEntity}
              key={content._id}
              handleSelect={handleSelect}
              handlePlayPause={handlePlayPause}
            />
          ))}
        </ContentContainer>
      </div>
      <BasicActionsModal
        modalType={MODALTYPES.BASIC_ACTION.REMOVE_IMAGES}
        primaryAction={removeImagesPrimaryAction}
        secondaryAction={removeImagesSecondaryAction}
      />
      <BasicActionsModal
        modalType={MODALTYPES.BASIC_ACTION.ADD_MANUAL_TAGS}
        primaryAction={addManualTagsPrimaryAction}
        secondaryAction={addManualTagsSecondaryAction}
      />
      <ImageTaggingModal />
      <VideoTaggingModal />
    </PrivateContainer>
  );
}
