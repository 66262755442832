import React from "react";

const Refresh = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 15.5V10.25H8.25L5.83725 12.665C6.24936 13.0864 6.74128 13.4215 7.28431 13.6507C7.82733 13.88 8.41058 13.9987 9 14C9.92944 13.9987 10.8357 13.7095 11.5941 13.1723C12.3526 12.6352 12.9262 11.8763 13.236 11H13.2495C13.335 10.7563 13.4003 10.505 13.4438 10.25H14.9528C14.7701 11.6999 14.0645 13.0333 12.9685 14C11.8725 14.9666 10.4614 15.5 9 15.5H8.9925C8.20486 15.5024 7.42458 15.3485 6.69682 15.0472C5.96907 14.746 5.30829 14.3034 4.75275 13.745L3 15.5ZM4.5555 8.75003H3.0465C3.22911 7.30063 3.93423 5.96764 5.02962 5.00108C6.125 4.03453 7.53539 3.50081 8.99625 3.50003H9C9.78777 3.49754 10.5682 3.65137 11.2961 3.95262C12.024 4.25387 12.6849 4.69656 13.2405 5.25503L15 3.50003V8.75003H9.75L12.1665 6.33503C11.754 5.91314 11.2615 5.57776 10.7177 5.34853C10.174 5.1193 9.59006 5.00082 9 5.00003C8.07056 5.0014 7.16434 5.29054 6.40585 5.82771C5.64736 6.36488 5.07381 7.12374 4.764 8.00003H4.7505C4.66425 8.24378 4.599 8.49503 4.55625 8.75003H4.5555Z"
        fill="black"
      />
    </svg>
  );
};

export default Refresh;
