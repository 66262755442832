import React from "react";

const Plus = ({ color }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0.5C5.19891 0.5 5.38968 0.579018 5.53033 0.71967C5.67098 0.860322 5.75 1.05109 5.75 1.25V4.25H8.75C8.94891 4.25 9.13968 4.32902 9.28033 4.46967C9.42098 4.61032 9.5 4.80109 9.5 5C9.5 5.19891 9.42098 5.38968 9.28033 5.53033C9.13968 5.67098 8.94891 5.75 8.75 5.75H5.75V8.75C5.75 8.94891 5.67098 9.13968 5.53033 9.28033C5.38968 9.42098 5.19891 9.5 5 9.5C4.80109 9.5 4.61032 9.42098 4.46967 9.28033C4.32902 9.13968 4.25 8.94891 4.25 8.75V5.75H1.25C1.05109 5.75 0.860322 5.67098 0.71967 5.53033C0.579018 5.38968 0.5 5.19891 0.5 5C0.5 4.80109 0.579018 4.61032 0.71967 4.46967C0.860322 4.32902 1.05109 4.25 1.25 4.25H4.25V1.25C4.25 1.05109 4.32902 0.860322 4.46967 0.71967C4.61032 0.579018 4.80109 0.5 5 0.5Z"
        fill={color}
      />
    </svg>
  );
};

export default Plus;
