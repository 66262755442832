import React from "react";

const Logout = ({ isActive }) => {
  const color = isActive ? "#7167FB" : "#858383";
  return (
    <svg width={17} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 15a7.5 7.5 0 1 1 6-12h-2.033a6 6 0 1 0 .001 9h2.033A7.488 7.488 0 0 1 7.5 15Zm5.25-4.5V8.25h-6v-1.5h6V4.5l3.75 3-3.75 3Z"
        fill={color}
      />
    </svg>
  );
};

export default Logout;
