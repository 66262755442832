import { Button, Typography, Box, Grid, Paper, Stack } from "@mui/material";
import { PrivateContainer } from "components/layouts";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APP_ROUTES } from "routes";
import CreateAudienceSegmentModal from "./CreateAudienceSegmentModal";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import useAxios from "hooks/useAxios";
import { toast } from "react-toastify";
import { BackButton } from "components/common/atoms";

const AudienceSegment = () => {
  const dispatch = useDispatch();
  const { open, type } = useSelector((state) => state.modal);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const { axiosOrgInstance } = useAxios();
  const handleCreateSegment = (inputs) => {
    axiosOrgInstance
      .post("organization/audience-segment", inputs)
      .then(
        (res) =>
          toast("Successfully created audience segment") &&
          handleGetAudienceSegments()
      )
      .catch((err) => toast("Something went wrong"));
  };
  const [audienceSegments, setAudienceSegments] = useState([]);
  const handleGetAudienceSegments = () => {
    axiosOrgInstance
      .get("organization/audience-segment")
      .then((res) => setAudienceSegments(res))
      .catch((err) => toast("Failed to get audience segments"));
  };
  useEffect(() => {
    handleGetAudienceSegments();
  }, []);

  const handleExitEdit = () => {
    setIsEditOpen(false);
  };

  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
        maxHeight: "calc(100vh - 30px)",
      }}
      activePage={APP_ROUTES.GROUPS_TAGS.default}
    >
      <Stack direction="row" justifyContent="space-between">
        <BackButton
          to={APP_ROUTES.GROUPS_TAGS.default}
          btnText="Audience Segment"
          from={APP_ROUTES.GROUPS_TAGS.TAGGING}
        />
      </Stack>
      <Box p={3}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Typography variant="h6">Audience Segments</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                setSelectedSegment(null); // Clear selectedSegment state
                dispatch(
                  setModal({
                    type: MODALTYPES.CREATE_AUDIENCE_SEGMENT,
                    open: true,
                  })
                );
              }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box p={3}>
        <Grid container spacing={3}>
          {audienceSegments.map((segment) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={segment._id}>
              <Paper
                variant="outlined"
                sx={{ p: 2, cursor: "pointer" }}
                onClick={() => {
                  setSelectedSegment(segment);
                  setIsEditOpen(true);
                  dispatch(
                    setModal({
                      type: MODALTYPES.CREATE_AUDIENCE_SEGMENT,
                      open: true,
                    })
                  );
                }}
              >
                <Typography variant="subtitle1">{segment.name}</Typography>
                <Typography variant="body2">
                  {segment.items.length} Meta Audiences
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
      {open && isEditOpen && type === MODALTYPES.CREATE_AUDIENCE_SEGMENT && (
        <CreateAudienceSegmentModal
          handleCreateSegment={handleCreateSegment}
          primaryAction={handleGetAudienceSegments}
          currentAudienceSegment={selectedSegment}
          isEdit={isEditOpen}
          handleExitEdit={handleExitEdit}
        />
      )}
      {open && !isEditOpen && type === MODALTYPES.CREATE_AUDIENCE_SEGMENT && (
        <CreateAudienceSegmentModal
          handleCreateSegment={handleCreateSegment}
          primaryAction={handleGetAudienceSegments}
        />
      )}
    </PrivateContainer>
  );
};

export default AudienceSegment;
