import { useCallback } from "react";
import { Dialog, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  modalInitState,
  MODALTYPES,
  setModal,
} from "../../../redux/slices/modal.slices";
import { success_tick } from "../../../assets/images";

export default function SuccessModal() {
  const { open, type, props } = useSelector((state) => state?.modal);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(setModal(modalInitState));
  }, [dispatch]);

  return (
    <Dialog
      open={open && type === MODALTYPES.SUCCESS}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "65px 165px",
          gap: "30px",
        }}
      >
        <div
          style={{
            height: "117px",
            width: "117px",
          }}
        >
          <img
            src={success_tick}
            alt="success_tick"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
        <Typography variant="h6">{props?.success_msg}</Typography>
      </div>
    </Dialog>
  );
}
