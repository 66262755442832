const toBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};

const generateRandomColor = () => {
  const color = Math.floor(Math.random() * 16777215).toString(16);
  return `#${color}`;
};

const colors = [
  "#D4E4FA",
  "#EADCFC",
  "#C7EBD1",
  "#F9EBDF",
  "#DAD7FE",
  "#B3E0F2",
  "#E7D8FF",
  "#C0F9D7",
  "#FCE0C7",
  "#F3F0FE",
];
const getRandomColorAtIndex = (indx) => {
  return colors[Math.floor(indx % colors.length)];
};

function convertToTitleCase(inputString) {
  if (!inputString) return "";
  return inputString
    .replace(/([A-Z])/g, " $1")
    .replace(/^\w/, (c) => c.toUpperCase());
}

function storageUrlToCdn(string) {
  if (!string) return string;
  return string.replace(
    process.env.REACT_APP_STORAGE_URL,
    process.env.REACT_APP_CDN_URL
  );
}

function isOrganizationOnboarded(org) {
  return (
    org?.isOrganizationImagesAiGroupingDone &&
    org?.isOrganizationVideosAiGroupingDone &&
    org?.isOrganizationImagesManualGroupingDone &&
    org?.isOrganizationVideosManualGroupingDone &&
    org?.isOrganizationTextsGroupingDone &&
    org?.isOrganizationCustomAudiencesGroupingDone &&
    org?.isOrganizationCustomAudiencesTaggingDone &&
    org?.isDataPullCompleted
  );
}

function arrayFirstElement(array) {
  if (!array || !Array.isArray(array) || array.length === 0) return null;
  return array[0];
}

function getColorForProgress(value) {
  if (value >= 70 && value <= 100) {
    return "#009933";
  } else if (value >= 50 && value <= 70) {
    return "#ffff00";
  } else {
    return "#ff0000";
  }
}

export {
  storageUrlToCdn,
  toBase64,
  generateRandomColor,
  convertToTitleCase,
  getRandomColorAtIndex,
  isOrganizationOnboarded,
  arrayFirstElement,
  getColorForProgress,
};
