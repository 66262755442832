import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useSelector } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";
import { useDispatch } from "react-redux";
import { Toaster } from "@/components/ui/toaster";
import { useEffect, useState } from "react";
import StorytellerLogoLoader from "components/common/loaders/StorytellerLogoLoader";

export function ContactSupportModal() {
  const [loading, setLoading] = useState(true);
  const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setModal(modalInitState));
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_CONTACT_SUPPORT_SCRIPT_SRC;
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!modal?.open) {
      setLoading(true);
    }
  }, [modal?.open]);

  return (
    <Dialog
      open={modal?.open && modal?.type === MODALTYPES.CONTACT_SUPPORT}
      onOpenChange={handleClose}
    >
      <Toaster />
      <DialogContent className="sm:max-w-4xl h-[90vh] w-[90vw] overflow-scroll">
        <div className="h-full w-full">
          {loading ? <StorytellerLogoLoader /> : <></>}
          <iframe
            src={process.env.REACT_APP_CONTACT_SUPPORT_SRC}
            onLoad={() => setLoading(false)}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              overflow: "scroll",
            }}
            id="XNtYbhNdQXk27SxQpmRA_1717081857151"
            title="Booking Widget"
          ></iframe>
          <br />
        </div>
      </DialogContent>
    </Dialog>
  );
}
