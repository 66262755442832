import {
  useCreateInsightsReport,
  useGetInsightFilters,
  useGetInsightsData,
  useGetSpecificInsight,
  useMoveReportToFolder,
  useUpdateInsightsReport,
} from "apis/insightsApi";
import {
  useBookmarkReport,
  useDeleteReport,
  useGetBookmarkReports,
  useMakeShortUrl,
  useRemoveBookmark,
} from "apis/reportsApi";
import useAppState from "hooks/useAppState";
import useAxios from "hooks/useAxios";
import useReports from "hooks/useReports";
import {
  setAdAccountIds as SetAdBrkAdAccountIds,
  setDateNew as SetAdBrkDateNew,
  setFilterSet as SetAdBrkFilterSet,
  setGroupBy as SetAdBrkGroupBy,
  setGroupIds as SetAdBrkGroupIds,
  setPayload as SetAdBrkPayload,
  setPreset as SetAdBrkPreset,
  setSelectedMetrices as SetAdBrkSelectedMetrices,
} from "redux/slices/adBreakdown.slice";

import { useGetPresets, useGetReportMessage } from "apis/common";
import {
  useCreatePreset,
  useGetSpecificPresets,
  useUpdatePreset,
} from "apis/presets";
import { FILTER_CATEGORY_REVERSE_MAPPING } from "config/filters";
import { SELECTION_TYPE_MAPPING } from "config/statics";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setAdAccountIds,
  setAdditionalFilters,
  setDateNew,
  setFilterSet2,
  setGroupBy,
  setInitGroups,
  setKpiFilters,
  setPayload,
  setPreset,
  setSelectedMetrices,
} from "redux/slices/comapre2Filters.slice";
import { setGroupIds } from "redux/slices/compare.slice";
import {
  addExtraFilter,
  initExtraFilters,
  removeExtraFilterField,
  setAggregateLogic,
  setExtraFilters,
  setFilters,
  setIsReportPublic,
  setMetrics,
  setSelectedKeywords,
  setSelectedMetrics,
  setSortBy,
  updateExtraFilterAggregateLogic,
  updateExtraFilterFieldSelect,
} from "redux/slices/insights.slice";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { API_ROUTES, APP_ROUTES } from "routes";

const groupbyOptions = [
  {
    label: "Ad Name",
    value: "adName",
  },
  {
    label: "Headline",
    value: "headline",
  },
  {
    label: "Landing Page",
    value: "landingPageUrl",
  },
  {
    label: "Copy",
    value: "body",
  },
  {
    label: "Creative",
    value: "groupId",
  },
];

export default function useCompareDates() {
  const navigate = useNavigate();
  const { axiosOrgInstance } = useAxios();
  const specificInsightsId = useParams()?.reportId;
  const platforms = useSelector(
    (state) => state?.organisation?.selectedOrganisation?.platforms
  );

  const insightsFilterState = useSelector((state) => state.compareDatesFilters);
  const date = insightsFilterState?.date;
  const kpiFilters = insightsFilterState?.kpiFilters;
  const payload = insightsFilterState?.payload || [];
  const groupBy = insightsFilterState?.groupBy;
  const selectedMetrices = insightsFilterState?.selectedMetrices;
  const filterSet = insightsFilterState?.filter;
  const additionalFilterSet = insightsFilterState?.additionalFilterSet;
  const adAccountIds = insightsFilterState?.adAccountIds;
  const preset = insightsFilterState?.preset;
  const [isSmartSuggestionLoading, setIsSmartSuggestionLoading] =
    useState(false);
  const [smartSuggestion, setSmartSuggestions] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedKPIs, setSelectedKPIs] = useState(new Set());
  const [isShowGroups, setIsShowGroups] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [chartKPIs, setChartKPIs] = useState([]);
  const [visualisationTab, setVisualisationTab] = useState(0);

  const [filterAndOptions, setFiltersAndOptions] = useState([]);

  const [currentFilterObj, setCurrentFilterObj] = useState({});

  const insightState = useSelector((state) => state.insights);
  const { groupIds } = useSelector((state) => state?.compare);
  const { tags } = useSelector((state) => state.organisation);
  const [colorFormat, setColorFormat] = useState(2); //1 no-color 2 green only 3 red only 4 both
  const motion = insightState?.extraFilters;

  const dispatch = useDispatch();

  const { organisationOnBoarded } = useAppState();

  const { hideSidebar, disableFilters, currentReportOrgId } = useReports();

  const { data: insightFilters, isLoading: isLoadingFilters } =
    useGetInsightFilters(organisationOnBoarded, currentReportOrgId);

  const { data: presetData } = useGetPresets(true, currentReportOrgId);
  const { mutate: makePreset, isLoading: isMakingPreset } = useCreatePreset();
  const { mutate: updatePreset } = useUpdatePreset();
  const [tempKpis, setTempKpis] = useState([]);

  const [confirmVisualizationModal, setConfirmVisualizationModal] = useState({
    showModal: false,
    idx: null,
  });

  const [reportInfo, setReportInfo] = useState({
    title: "",
    description: "",
  });

  const {
    mutateAsync: getInsightsData,
    data: insightsData,
    isLoading: isInsightLoading,
  } = useGetInsightsData(specificInsightsId, currentReportOrgId);

  const { mutateAsync: updateReport, isLoading: isUpdatingReport } =
    useUpdateInsightsReport(specificInsightsId);

  const { data: insightsSpecificData, isLoading: loadingReport } =
    useGetSpecificInsight(
      insightFilters,
      specificInsightsId,
      currentReportOrgId
    );

  const { data: reportableMessage } = useGetReportMessage(
    specificInsightsId,
    currentReportOrgId
  );

  const { mutateAsync: createInsightsReport, isLoading: isCreatingReport } =
    useCreateInsightsReport();
  const { refetch: fetchBookmarks, isLoading: isFetchingBookmarks } =
    useGetBookmarkReports(false);
  const {
    mutateAsync: bookmarkReport,
    isSuccess: isBookmarkSuccess,
    isLoading: isBookmarking,
  } = useBookmarkReport(fetchBookmarks);

  const { mutateAsync: moveReport } = useMoveReportToFolder();

  function handleBookmarkReport(inputs) {
    const payload = {
      reportId: specificInsightsId,
    };
    bookmarkReport(payload);
  }

  const doesShowColor = (colorRange) => {
    if (colorFormat === 1) {
      return false;
    }
    switch (colorRange) {
      case 1:
        //show green color
        if (colorFormat === 2 || colorFormat === 4) {
          return true;
        }
        return false;
      case -1:
        //show red color only
        if (colorFormat === 3 || colorFormat === 4) {
          return true;
        }
        return false;
      default:
        return false;
    }
  };

  const resolvedKPIs =
    visualisationTab === 0 ? selectedMetrices?.value : chartKPIs;

  const updateKPIs = (value) => {
    if (visualisationTab === 0) {
      onUpdateSelectedMetrices({
        ...selectedMetrices,
        ...value,
      });
    } else {
      setChartKPIs(value?.value);
    }
  };

  const isSelectionValid = (type) => {
    let maxRowSelection, maxKpiSelection;

    if (visualisationTab === 0) {
      maxRowSelection = 10000;
      maxKpiSelection = 7;
    } else if (visualisationTab === 1) {
      maxRowSelection = 6;
      maxKpiSelection = 7;
    } else {
      maxRowSelection = 6;
      maxKpiSelection = 7;
    }

    if (
      type === "row" &&
      Object.keys(rowSelection)?.length >= maxRowSelection
    ) {
      toast(
        `In ${
          visualisationTab === 0 ? "card" : "chart"
        } view maximum selection should be ${maxRowSelection}`
      );
      return false;
    }

    if (type === "kpi" && resolvedKPIs?.length >= maxKpiSelection) {
      toast(
        `In ${
          visualisationTab === 0 ? "card" : "chart"
        } view maximum kpi selection should be ${maxKpiSelection}`
      );
      return false;
    }

    return true;
  };

  function getFilterStateFromFiltersObject() {
    Object.entries(insightFilters)?.forEach(([key, value]) => {
      if (key !== "sortOrder" || key !== "metrics" || key !== "kpi") {
        filterAndOptions[key] = value;
      }
      if (key === "kpi") {
        filterAndOptions[key] = value;
      }
    });
  }

  function isGroupsValid() {
    if (filterSet?.length === 0) {
      toast.info("Please select at leaset one group");
      return false;
    } else {
      let res = true;
      filterSet?.forEach((fSet, index) => {
        if (!fSet?.groupName) {
          res = false;
        }
      });
      return res;
    }
  }

  function makeBody(page = 1) {
    const body = {
      groups: filterSet,
    };

    //add kpis/selectedMetrices in _filters
    body["adAccountIds"] =
      disableFilters || hideSidebar
        ? insightsSpecificData?.[0]?.report?.adAccountIds
        : adAccountIds;
    body["kpis"] = selectedMetrices;
    body["name"] = reportData?.name;
    body["description"] = reportData?.description;
    body["icon"] = reportData?.icon;

    body["groupBy"] = {
      type: "groupBy",
      mode: 1,
      value: [groupBy?.value],
    };

    body["platformIds"] =
      platforms?.length > 0
        ? platforms?.map((item) => item?.platformId)
        : insightsSpecificData
        ? insightsSpecificData?.[0]?.report?.platformIds
        : [];
    body["isCompareReport2"] = true;
    // body["date"] = date;
    body["date"] = {
      selectionType: date?.selectionType,
      startDate: date?.startDate?.value
        ? date?.startDate?.value
        : date?.startDate,
      endDate: date?.endDate?.value ? date?.endDate?.value : date?.endDate,
    };
    if (additionalFilterSet) {
      body["filters"] = additionalFilterSet;
    }
    body["page"] = 1;
    body["perPage"] = 10000000;
    body["sortOrder"] = insightsSpecificData?.[0]?.report?.sortOrder;
    body["sortBy"] = insightsSpecificData?.[0]?.report?.sortBy;

    return body;
  }

  function handleAdBreakdown(groupId) {
    dispatch(SetAdBrkAdAccountIds(adAccountIds));
    dispatch(SetAdBrkDateNew(date));
    dispatch(SetAdBrkFilterSet(additionalFilterSet));
    dispatch(SetAdBrkPayload(payload));
    dispatch(SetAdBrkPreset(preset));
    dispatch(SetAdBrkSelectedMetrices(selectedMetrices));
    dispatch(SetAdBrkGroupBy(groupBy));
    dispatch(SetAdBrkGroupIds([groupId]));
    navigate(APP_ROUTES.INSIGHTS.AD_BREAKDOWN);
  }

  function handleShowGroups() {
    setIsShowGroups((prev) => !prev);
  }

  function handleDeleteGroup(groupIndex) {
    const _updatedFilterSet = filterSet?.filter(
      (curr, index) => index !== groupIndex
    );
    dispatch(setFilterSet2(_updatedFilterSet));
  }

  function handleDuplicateGroup(groupIndex) {
    const _group = _.cloneDeep(filterSet?.[groupIndex]);
    const _updatedFilterSet = _.cloneDeep(filterSet);
    if (_group["groupName"]) {
      _group["groupName"] = _group["groupName"] + " copy";
    }
    _updatedFilterSet.splice(groupIndex + 1, 0, _group);
    dispatch(setFilterSet2(_updatedFilterSet));
  }

  function onOpenFilters(isIndividualFilter) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    dispatch(
      setPayload([
        {
          type: "adName",
          mode: 2,
          contains: [],
          notContains: [],
          filter: "contains",
        },
      ])
    );
  }

  function handleAddMoreGroup() {
    const _updatedFilterSet = [...filterSet];
    _updatedFilterSet.push({
      groupName: null,
      filters: [
        {
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
          selectionType: null,
        },
      ],
    });
    dispatch(setFilterSet2(_updatedFilterSet));
  }

  function handleWriteGroupName(groupIndex, groupName) {
    const _updatedFilterSet = _.cloneDeep(filterSet);
    _updatedFilterSet[groupIndex].groupName = groupName;
    dispatch(setFilterSet2(_updatedFilterSet));
  }

  function handleAddDateInGroup(groupIndex) {
    const _updatedFilterSet = _.cloneDeep(filterSet);
    _updatedFilterSet[groupIndex].filters.push({
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      selectionType: null,
    });
    dispatch(setFilterSet2(_updatedFilterSet));
  }

  function onDateDaysChange(config, groupIndex, fIndex) {
    const _newDate = {
      ...config,
      selectionType: null,
    };
    if (groupIndex !== null) {
      const _updatedFilterSet = _.cloneDeep(filterSet);
      if (fIndex !== null) {
        _updatedFilterSet[groupIndex].filters[fIndex] = _newDate;
      }
      dispatch(setFilterSet2(_updatedFilterSet));
    } else {
      dispatch(setDateNew(_newDate));
    }
  }

  function getDateRangeForSelectionType(selectionType) {
    const _startDate = moment(new Date())
      .subtract(parseInt(SELECTION_TYPE_MAPPING[selectionType]), "days")
      .format("YYYY-MM-DD");
    const _endDate = moment(new Date()).format("YYYY-MM-DD");
    return {
      startDate: _startDate,
      endDate: _endDate,
      selectionType: parseInt(SELECTION_TYPE_MAPPING[selectionType]),
    };
  }

  function handleUpdateSelectionType(selectionType, groupIndex, fIndex) {
    const _newDate = getDateRangeForSelectionType(selectionType);
    if (groupIndex !== null) {
      const _updatedFilterSet = _.cloneDeep(filterSet);
      _updatedFilterSet[groupIndex].filters[fIndex] = _newDate;
      dispatch(setFilterSet2(_updatedFilterSet));
    } else {
      dispatch(setDateNew(_newDate));
    }
  }

  const applyPayload = (groupIndex, isIndividualFilter) => {
    if (isIndividualFilter) {
      const _updatedAdditionalFilters = _.cloneDeep(additionalFilterSet);
      _updatedAdditionalFilters?.push(payload);
      dispatch(setAdditionalFilters(_updatedAdditionalFilters));
      dispatch(setPayload([]));
    } else {
      const _updatedFilterSet = filterSet.map((item, index) => {
        if (index === groupIndex) {
          return {
            ...item,
            filters: [...item.filters, payload],
          };
        }
        return item;
      });
      dispatch(setFilterSet2(_updatedFilterSet));
      dispatch(setPayload([]));
    }
  };

  function onRemovePayload(fIndex, groupIndex) {
    if (groupIndex !== null) {
      const updatedFilterSet = _.cloneDeep(filterSet);
      updatedFilterSet[groupIndex].filters = updatedFilterSet?.[
        groupIndex
      ]?.filters?.filter((fset, index) => index !== fIndex);
      dispatch(setFilterSet2(updatedFilterSet));
    } else {
      dispatch(
        setPayload([
          {
            type: "optimizationGoals",
            mode: 1,
            is: [],
            isNot: [],
            filter: "is",
          },
        ])
      );
    }
  }
  function onAdditionalFilterRemovePayload(fIndex, groupIndex) {
    const updatedAdditionalFilters = _.cloneDeep(additionalFilterSet);
    const _updated = updatedAdditionalFilters?.filter(
      (fSet, index) => index !== fIndex
    );
    dispatch(setAdditionalFilters(_updated));
  }

  function checkIfDuplicateExists(arr) {
    const groups = filterSet.map((item) => item.groupName);
    return new Set(groups).size !== groups.length;
  }

  function _handleApplyFilters(page = 1) {
    if (!isGroupsValid()) {
      toast.info("Please provide valid group names");
    } else if (checkIfDuplicateExists(filterSet)) {
      toast.info("Group names must be unique");
    } else if (adAccountIds?.length > 0) {
      const body = makeBody(page);
      getInsightsData(body);
    } else {
      toast.info("Please select an Ad-Account");
    }
  }

  function saveReport(saveReportInfo) {
    const body = makeBody();
    body["name"] =
      saveReportInfo?.name || insightsSpecificData?.[0]?.report?.name;
    body["description"] =
      saveReportInfo?.description ||
      insightsSpecificData?.[0]?.report?.description;
    body["isPublic"] = saveReportInfo?.isPublic;
    createInsightsReport(body);
  }

  function handleUpdateAndSave() {
    if (!specificInsightsId) {
      dispatch(
        setModal({
          open: true,
          type: MODALTYPES.SAVE_INSIGHTS_REPORT,
          payload: {},
        })
      );
    } else {
      const _body = makeBody();
      updateReport(_body);
    }
  }

  function handleExtraFilterSelectUpdate(index, value, key) {
    setSmartSuggestions([]);
    dispatch(updateExtraFilterFieldSelect({ index, value, key }));
  }

  function handleUpdateSearchExtraFilter(index, value, filter, fIndex) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    // filter for is/isNot contains/notContains
    if (fIndex !== null) {
      const updatedFilterSet = _.cloneDeep(filterSet);
      updatedFilterSet[fIndex][index] = {
        ...updatedFilterSet[fIndex][index],
        is: [],
        isNot: [],
        [filter]: value,
      };
      dispatch(setFilterSet2(updatedFilterSet));
    } else {
      const updatedPayload = [...payload];
      updatedPayload[index] = {
        ...updatedPayload[index],
        [filter]: value,
      };
      dispatch(setPayload(updatedPayload));
    }
  }

  const [currPresetId, setPresetId] = useState(null);
  const { data: specificPresetData, isLoading: isLoadingSpecificPreset } =
    useGetSpecificPresets(currPresetId);
  function handlePresetSelect(presetId) {
    setPresetId(presetId);
    dispatch(setPreset(presetId));
  }

  useEffect(() => {
    if (!currPresetId) {
      setPresetId(presetData?.[0]?.id);
      dispatch(setPreset(presetData?.[0]?.id));
    }
  }, [presetData, specificInsightsId]);

  const handleFetchSmartSuggestions = (q, key) => {
    setIsSmartSuggestionLoading(true);
    axiosOrgInstance
      .get(`${API_ROUTES.ORGANISATION.SMART_FILTERS}?q=${q}&type=${key}`)
      .then((res) => {
        let _temp = [];
        setIsSmartSuggestionLoading(false);
        res?.map((curr) => {
          _temp.push(curr[key]);
        });
        setSmartSuggestions(_temp || ["Nothing Found...."]);
      });
  };

  function handleSearchMotionFilters(type, q) {
    setSmartSuggestions([]);
    if (q?.trim()?.length === 0) {
      setSmartSuggestions([]);
      setIsSmartSuggestionLoading(false);
    } else if (type === "keywords") {
      setSmartSuggestions(tags);
      setIsSmartSuggestionLoading(false);
    } else {
      handleFetchSmartSuggestions(q, type);
    }
  }

  function handleAggregateLogicChangeExtraFilters(logic) {
    dispatch(updateExtraFilterAggregateLogic({ logic }));
  }

  function handleAddNewFilter() {
    dispatch(addExtraFilter());
  }

  function handleRemoveNewFilter(index) {
    dispatch(removeExtraFilterField({ index }));
  }

  const [paginatedInsightsData, setPaginatedInsightsData] = useState([]);

  function handleSetReportPublicChange() {
    dispatch(setIsReportPublic(!insightState?.isReportPublic));
  }

  function handleKeywordAddition(keyword) {
    if (keyword?.length <= 0) {
      dispatch(setAggregateLogic(null));
    }
    if (keyword.length === 2) {
      dispatch(
        setModal({
          type: MODALTYPES.KEYWORD_AGGREGATION_LOGIC_MODAL,
          open: true,
        })
      );
    }
    dispatch(setSelectedKeywords(keyword));
  }

  function handleAggregateChange(aggregate) {
    dispatch(setAggregateLogic(aggregate));
  }

  const { mutateAsync: removeBookmarkMutate, isLoading: isRemovingBookmark } =
    useRemoveBookmark();
  function handleRemoveBookmark() {
    removeBookmarkMutate(specificInsightsId);
  }

  // Function to join a group
  const [currentGroup, setCurrentGroup] = useState(null);

  function moveReportToFolder(folderId, reportId) {
    moveReport({ reportableId: reportId || specificInsightsId, folderId });
  }

  const { mutateAsync: generateShortUrl, isLoading: isMakingShortUrl } =
    useMakeShortUrl();

  const { mutateAsync: deleteReport, isLoading: deleteReportLoading } =
    useDeleteReport();

  async function handleUrlShortener(reportMessage) {
    try {
      const report = insightsSpecificData?.[0]?.report;
      const viewRedirect =
        APP_ROUTES.INSIGHTS.COMPARE_DATE + `/${specificInsightsId}`;
      const longUrl = `${process.env.REACT_APP_URL}${viewRedirect}?orgId=${report?.organizationId}`;
      generateShortUrl({
        longUrl,
        title: report?.name,
        reportableId: specificInsightsId,
        message: reportMessage,
      });
    } catch (error) {
      toast("Failed to generate report URL");
    }
  }

  const [reportData, setReportData] = useState({
    name: "",
    description: "",
    icon: null,
  });

  const onUpdateReportData = (value, type, limit) => {
    // if (value.length > limit) {
    //   toast(`Report ${type} should be less than ${limit} characters`, {
    //     toastId: type + "@33ldsdsk",
    //   });
    //   return;
    // }

    setReportData({
      ...reportData,
      [type]: value,
    });
  };

  const updateReportInfo = useCallback((key, value) => {
    setReportInfo({
      ...reportInfo,
      [key]: value,
    });
  }, []);

  function makeBodyFromReport(
    _payload,
    _extraFilters,
    _date,
    _kpiFilters,
    _platformIds,
    _adAccountIds,
    _groupBy,
    _kpis,
    _groups
  ) {
    const body = {
      groups: _groups,
    };

    body["adAccountIds"] = _adAccountIds;

    body["groupBy"] = {
      type: "groupBy",
      mode: 1,
      value: [_groupBy?.value],
    };

    body["isCompareReport2"] = true;
    if (_payload) {
      body["filters"] = _payload;
    }

    if (_kpis?.length > 0) {
      body["kpis"] = {
        type: "kpis",
        value: _kpis,
        mode: 1,
      };
    }

    body["platformIds"] = _platformIds || [];
    body["date"] = {
      endDate: _date?.endDate?.value ? _date?.endDate?.value : _date?.endDate,
      selectionType: _date?.selectionType,
      startDate: _date?.startDate?.value
        ? _date?.startDate?.value
        : _date?.startDate,
    };
    body["page"] = 1;
    body["perPage"] = 10000000;
    body["kpiFilters"] = _kpiFilters;
    body["sortOrder"] = insightsSpecificData?.[0]?.report?.sortOrder;
    body["sortBy"] = insightsSpecificData?.[0]?.report?.sortBy;
    getInsightsData(body);
  }

  function handleColorFormatChange(format) {
    setColorFormat(format);
  }

  function changeGroupBy(val) {
    dispatch(setGroupBy(val));
  }

  function onFilterSetOpen(fIndex) {
    dispatch(setPayload(filterSet[fIndex]));
  }

  function onFilterWhereChange(index, val, fIndex, groupIndex) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    if (fIndex !== null && groupIndex !== undefined) {
      const updatedFilterSet = _.cloneDeep(filterSet);

      if (Object.keys(FILTER_CATEGORY_REVERSE_MAPPING).includes(val)) {
        const filterType = FILTER_CATEGORY_REVERSE_MAPPING[val].type;
        const filterDetails = {
          type: val,
          is: [],
          isNot: [],
          filter: filterType === "dropdown" ? "is" : "contains",
          mode: filterType === "dropdown" ? 1 : 2,
        };

        updatedFilterSet[groupIndex].filters[fIndex][index] = {
          ...updatedFilterSet[groupIndex].filters[fIndex][index],
          ...filterDetails,
        };
      } else {
        updatedFilterSet[groupIndex].filters[fIndex][index] = {
          type: val,
          is: [],
          isNot: [],
          filter: "is",
          mode: 1,
        };
      }

      dispatch(setFilterSet2(updatedFilterSet));
    } else {
      if (Object.keys(FILTER_CATEGORY_REVERSE_MAPPING).includes(val)) {
        const updatedPayload = [...payload];
        if (FILTER_CATEGORY_REVERSE_MAPPING?.[val]?.type === "dropdown") {
          updatedPayload[index] = {
            type: val,
            mode: 1,
            is: [],
            isNot: [],
            filter: "is",
          };
        } else {
          updatedPayload[index] = {
            type: val,
            mode: 2,
            contains: [],
            notContains: [],
            filter: "contains",
          };
        }
        dispatch(setPayload(updatedPayload));
      } else {
        const updatedPayload = [...payload];
        updatedPayload[index] = {
          type: val,
          mode: 1,
          is: [],
          isNot: [],
          filter: "is",
        };
        dispatch(setPayload(updatedPayload));
      }
    }
  }
  function onAdditionalFilterWhereChange(index, val, fIndex, groupIndex) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    if (Object.keys(FILTER_CATEGORY_REVERSE_MAPPING).includes(val)) {
      const _updatedAdditionalFilters = _.cloneDeep(additionalFilterSet);
      if (FILTER_CATEGORY_REVERSE_MAPPING?.[val]?.type === "dropdown") {
        _updatedAdditionalFilters[fIndex][index] = {
          type: val,
          mode: 1,
          is: [],
          isNot: [],
          filter: "is",
        };
      } else {
        _updatedAdditionalFilters[fIndex][index] = {
          type: val,
          mode: 2,
          contains: [],
          notContains: [],
          filter: "contains",
        };
      }
      dispatch(setAdditionalFilters(_updatedAdditionalFilters));
    } else {
      const _updatedAdditionalFilters = [...additionalFilterSet];
      _updatedAdditionalFilters[fIndex][index] = {
        type: val,
        mode: 1,
        is: [],
        isNot: [],
        filter: "is",
      };
      dispatch(setPayload(_updatedAdditionalFilters));
    }
  }

  function onFilterFilterChange(index, val, fIndex, groupIndex) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    if (fIndex !== null && groupIndex !== null) {
      const updatedFilterSet = _.cloneDeep(filterSet);
      const filter =
        updatedFilterSet[groupIndex]?.filters[fIndex][index].filter;
      if (filter === "is" || filter === "isNot") {
        updatedFilterSet[groupIndex].filters[fIndex][index] = {
          ...updatedFilterSet[groupIndex].filters[fIndex][index],
          is: [],
          isNot: [],
          filter: val,
        };
      } else {
        updatedFilterSet[groupIndex].filters[fIndex][index] = {
          ...updatedFilterSet[groupIndex].filters[fIndex][index],
          contains: [],
          notContains: [],
          filter: val,
        };
      }
      dispatch(setFilterSet2(updatedFilterSet));
    } else {
      const _currentFilterAtIndex = payload?.[index]?.filter;
      if (_currentFilterAtIndex === "is" || _currentFilterAtIndex === "isNot") {
        const updatedPayload = [...payload];
        updatedPayload[index] = {
          ...updatedPayload[index],
          filter: val,
          is: [],
          isNot: [],
        };
        dispatch(setPayload(updatedPayload));
      } else {
        const updatedPayload = [...payload];
        updatedPayload[index] = {
          ...updatedPayload[index],
          filter: val,
          contains: [],
          notContains: [],
        };
        dispatch(setPayload(updatedPayload));
      }
    }
  }
  function onAdditionalFilterFilterChange(index, val, fIndex, groupIndex) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    if (fIndex !== null && groupIndex !== null) {
      const updatedFilterSet = _.cloneDeep(additionalFilterSet);
      const filter = updatedFilterSet?.[fIndex][index]?.filter;
      if (filter === "is" || filter === "isNot") {
        updatedFilterSet[fIndex][index] = {
          ...updatedFilterSet[fIndex][index],
          is: [],
          isNot: [],
          filter: val,
        };
      } else {
        updatedFilterSet[fIndex][index] = {
          ...updatedFilterSet[fIndex][index],
          contains: [],
          notContains: [],
          filter: val,
        };
      }
      dispatch(setAdditionalFilters(updatedFilterSet));
    }
  }

  function handleAdAccountId(id) {
    if (adAccountIds?.includes(id)) {
      const _updatedAdAccountIds = adAccountIds?.filter((curr) => curr !== id);
      dispatch(setAdAccountIds(_updatedAdAccountIds));
    } else {
      dispatch(setAdAccountIds([...adAccountIds, id]));
    }
  }

  function onFilterValueChange(index, val, fIndex, groupIndex, isUpdateAsItIS) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    if (fIndex !== null) {
      const updatedFilterSet = _.cloneDeep(filterSet);
      // const fSet = updatedFilterSet?.[fIndex];
      const fSet = updatedFilterSet[groupIndex].filters[fIndex];
      const filter = fSet?.[index]?.filter;
      if (isUpdateAsItIS) {
        fSet[index] = {
          ...fSet[index],
          [filter]: val,
        };
      } else if (fSet?.[index]?.[filter]?.includes(val)) {
        fSet[index] = {
          ...fSet[index],
          [filter]: fSet?.[index]?.[filter]?.filter((curr) => curr !== val),
        };
      } else {
        fSet[index] = {
          ...fSet[index],
          [filter]: [...fSet?.[index]?.[filter], val],
        };
      }
      dispatch(setFilterSet2(updatedFilterSet));
    } else {
      const filter = payload?.[index]?.filter;
      const updatedPayload = [...payload];
      if (isUpdateAsItIS) {
        updatedPayload[index] = {
          ...updatedPayload[index],
          [filter]: val,
        };
      } else if (payload[index]?.[filter]?.includes(val)) {
        updatedPayload[index] = {
          ...updatedPayload[index],
          [filter]: payload?.[index]?.[filter]?.filter((curr) => curr !== val),
        };
      } else {
        updatedPayload[index] = {
          ...updatedPayload[index],
          [filter]: [...payload[index]?.[filter], val],
        };
      }
      dispatch(setPayload(updatedPayload));
    }
  }
  function onAdditionalFilterValueChange(index, val, fIndex, groupIndex) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    if (fIndex !== null) {
      const updatedFilterSet = _.cloneDeep(additionalFilterSet);
      const fSet = updatedFilterSet[fIndex];
      const filter = fSet?.[index]?.filter;
      if (fSet?.[index]?.[filter]?.includes(val)) {
        fSet[index] = {
          ...fSet[index],
          [filter]: fSet?.[index]?.[filter]?.filter((curr) => curr !== val),
        };
      } else {
        fSet[index] = {
          ...fSet[index],
          [filter]: [...fSet?.[index]?.[filter], val],
        };
      }
      dispatch(setAdditionalFilters(updatedFilterSet));
    }
  }

  function onUpdatePayload(_currentPayload) {
    dispatch(setPayload(_currentPayload));
  }

  function handleDeleteFromFilterSet(fIndex, index, groupIndex) {
    const updatedFilterSet = _.cloneDeep(additionalFilterSet);
    updatedFilterSet[fIndex] = updatedFilterSet[fIndex]?.filter(
      (curr, idx) => idx !== index
    );
    dispatch(setAdditionalFilters(updatedFilterSet));
  }

  function handleAddMore(load, fIndex, groupIndex) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    //you might have felt why i am using findex !== null and not just if fIndex it's because fIndex can be 0 also
    const parent_element = document.getElementById(`parent-filter-content`);
    const element = document.getElementById(`filter-content`);
    if (fIndex !== null) {
      const updatedFilterSet = _.cloneDeep(filterSet);
      updatedFilterSet?.[groupIndex]?.filters?.[fIndex]?.push(load);
      dispatch(setFilterSet2(updatedFilterSet));
    } else {
      const _updatedPayload = [...payload, load];
      dispatch(setPayload(_updatedPayload));
    }
    //waiting for the dom updation
    setTimeout(() => {
      element.scrollTop = parent_element.scrollHeight;
    }, 300);
  }
  function handleAdditionalFiltersAddMore(load, fIndex, groupIndex) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    const parent_element = document.getElementById(`parent-filter-content`);
    const element = document.getElementById(`filter-content`);
    if (fIndex !== null) {
      const updatedFilterSet = _.cloneDeep(additionalFilterSet);
      updatedFilterSet?.[fIndex]?.push(load);
      dispatch(setAdditionalFilters(updatedFilterSet));
    }
    setTimeout(() => {
      element.scrollTop = parent_element.scrollHeight;
    }, 300);
  }

  function onUpdateKpiFilters(updatedKpiFilters) {
    dispatch(setKpiFilters(updatedKpiFilters));
  }

  function onUpdateSelectedMetrices(payload) {
    dispatch(setSelectedMetrices(payload));
  }

  function handleUpdateDate(_date) {
    const _newDate = {
      ..._date,
      ...date,
    };
    dispatch(setDateNew(_newDate));
  }

  function handleSetFirstAdAccountSelected(insightFilters) {
    if (insightFilters) {
      Object.entries(insightFilters).map(([key, val], index) => {
        if (key === "adAccountIds") {
          if (val?.options && val?.options?.length > 0) {
            dispatch(setAdAccountIds([val?.options?.[0]?.key]));
          }
        }
      });
    }
  }

  useEffect(() => {
    const todaysDate = moment().format("YYYY-MM-DD");

    //empty the filter on first load
    dispatch(setPayload([]));
    dispatch(setAdditionalFilters([]));
    dispatch(setInitGroups());
    dispatch(setSelectedMetrics([]));

    dispatch(
      setDateNew({
        startDate: todaysDate,
        endDate: todaysDate,
        selectionType: null,
      })
    );
    dispatch(
      setSelectedMetrices({
        type: "kpis",
        value: [],
        mode: 1,
      })
    );
    dispatch(setAdAccountIds([]));
    if (insightFilters) {
      getFilterStateFromFiltersObject();
      handleSetFirstAdAccountSelected(insightFilters);
      dispatch(setGroupIds([]));
      dispatch(setSelectedKeywords([]));
      dispatch(setAggregateLogic(null));
      const filters = {};
      const kpis = [];

      Object.entries(insightFilters).forEach(([key, value]) => {
        if (value?.options?.length > 0) {
          filters[key] = {
            ...value,
            options: value?.options?.map((item, index) => {
              if (index === 0) {
                return { ...item, default: true };
              }
              return item;
            }),
          };
        } else if (key === "kpi") {
          kpis.push(value?.[0]?.kpi);
          const selectedMetrics = [
            {
              label: value?.[0]?.label,
              value: value?.[0]?.kpi,
            },
          ];

          dispatch(setMetrics(value));
          dispatch(setSelectedMetrics(selectedMetrics));
        }
      });
      dispatch(setFilters(filters));
      dispatch(setSortBy(kpis[0]));

      if (specificInsightsId) return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightFilters]);

  function getGroupByLabel(value) {
    const val = groupbyOptions?.filter((option) => option?.value === value);
    return val[0]?.label;
  }

  useEffect(() => {
    if (insightsSpecificData) {
      const report = insightsSpecificData?.[0]?.report;

      if (!report) return;

      // Check if the group already exists
      const groupId = report?.reportableId;
      const groupName = report?.name;
      // checkIfGroupExists(groupId).then((existingGroup) => {
      //   if (existingGroup) {
      //     // If group exists, join the user to the group
      //     joinGroup(groupId);
      //   } else {
      //     // If group does not exist, create the group and then join the user
      //     createGroup(groupId, groupName)
      //       .then((newGroup) => {
      //         if (newGroup) {
      //           // Group created successfully, now join the user
      //           joinGroup(groupId);
      //         }
      //       })
      //       .catch((err) => {
      //         console.log("Error creating group me", err);
      //       });
      //   }
      // });

      const payload = JSON.parse(report?.filters);
      const extraFilters = report?.extraFilters
        ? JSON.parse(report?.extraFilters)
        : [];
      const date = report?.date;
      const kpiFilters = report?.kpiFilters
        ? JSON.parse(report?.kpiFilters)
        : [];
      const platformIds = report?.platformIds;
      let adAccountIds = report?.adAccountIds;
      let _groups = [];
      if (report?.groups) {
        _groups = JSON.parse(report?.groups);
      }
      if (!adAccountIds || adAccountIds?.length === 0) {
        handleSetFirstAdAccountSelected(insightFilters);
        adAccountIds = insightsFilterState?.adAccountIds;
      }
      let kpis = [];

      if (report?.kpis && report?.kpis?.length > 0) {
        kpis = report?.kpis;
        dispatch(
          setSelectedMetrices({
            type: "kpis",
            value: report?.kpis,
            mode: 1,
          })
        );
      }
      if (report?.groups) {
        dispatch(setFilterSet2(_groups));
      }
      dispatch(setAdditionalFilters(payload));
      const _groupBy = {
        label: getGroupByLabel(JSON.parse(report?.groupBy)?.value[0]),
        value: JSON.parse(report?.groupBy)?.value[0],
      };
      dispatch(setGroupBy(_groupBy));
      dispatch(setKpiFilters(kpiFilters));
      extraFilters?.length === 0
        ? dispatch(initExtraFilters())
        : dispatch(setExtraFilters(extraFilters));
      const _date = {
        endDate: date?.endDate?.value ? date?.endDate?.value : date?.endDate,
        selectionType: date?.selectionType ? date?.selectionType : 4,
        startDate: date?.startDate?.value
          ? date?.startDate?.value
          : date?.startDate,
      };
      dispatch(setDateNew(_date));
      dispatch(setSelectedKeywords([]));
      dispatch(setAggregateLogic(null));
      dispatch(setAdAccountIds(adAccountIds));
      setReportInfo({
        title: report?.name,
        description: report?.description,
        icon: report?.icon,
      });

      setReportData({
        name: insightsSpecificData?.[0]?.report?.name || "",
        description: insightsSpecificData?.[0]?.report?.description || "",
        icon: insightsSpecificData?.[0]?.report?.icon || "",
      });

      makeBodyFromReport(
        payload,
        extraFilters,
        date,
        kpiFilters,
        platformIds,
        adAccountIds,
        _groupBy,
        kpis,
        _groups
      );
    }
  }, [insightsSpecificData]);

  const handleSelectedFourKpis = () => {
    const kpis = [];
    specificPresetData?.[0]?.kpis?.slice(0, 4)?.map((curr) => {
      kpis?.push(curr?.value);
    });
    const payload = {
      type: "kpis",
      value: kpis,
      mode: 1,
    };
    dispatch(setSelectedMetrices(payload));
  };

  useMemo(() => {
    setPaginatedInsightsData(insightsData?.docs);
    setRowSelection({});
    setRowSelection({
      0: true,
      1: true,
      2: true,
      3: true,
    });
    handleSelectedFourKpis();
  }, [insightsData]);

  const changeVisualizationTab = (number) => {
    if (Object.keys(rowSelection)?.length > 6) {
      setConfirmVisualizationModal({ showModal: true, idx: number });
    } else {
      setVisualisationTab(number);
    }
  };

  const closeVisualizationTabSwitch = () => {
    setConfirmVisualizationModal({ showModal: false, idx: null });
  };
  const confirmVisualizationTabSwitch = () => {
    setVisualisationTab(confirmVisualizationModal.idx);
    setRowSelection({});
    closeVisualizationTabSwitch();
  };

  return {
    insightState,
    isLoadingSpecificPreset,
    isMakingShortUrl,
    motion,
    filterAndOptions,
    insightsData: paginatedInsightsData,
    isInsightLoading,
    visualisationTab,
    disableFilters,
    currentReportOrgId,
    isBookmarkSuccess,
    specificInsightsId,
    smartSuggestion,
    isSmartSuggestionLoading,
    hideSidebar,
    isReportPublic: insightState?.isReportPublic,
    currentGroup,
    currentFilterObj,
    payload,
    selectedMetrices,
    groupBy,
    kpiFilters,
    insightFilters,
    insightsReport: insightsSpecificData?.[0]?.report,
    reportableMessage,
    deleteReportLoading,
    sortedData,
    rowSelection,
    selectedKPIs,
    isUpdatingReport,
    groupIds,
    isCreatingReport,
    date,
    isLoadingFilters,
    preset,
    presetData,
    specificPresetData,
    isMakingPreset,
    isRemovingBookmark,
    isBookmarking,
    isFetchingBookmarks,
    reportInfo,
    tempKpis,
    reportData,
    filterSet,
    adAccountIds,
    colorFormat,
    isShowGroups,
    resolvedKPIs,
    chartKPIs,
    isDrawerOpen,
    additionalFilterSet,
    loadingReport,
    confirmVisualizationModal,
    handleAddDateInGroup,
    setIsDrawerOpen,
    updateKPIs,
    isSelectionValid,
    handleWriteGroupName,
    handleShowGroups,
    doesShowColor,
    handleColorFormatChange,
    handleUpdateSelectionType,
    handleUpdateDate,
    setReportInfo,
    updateReportInfo,
    makePreset,
    updatePreset,
    handleUpdateAndSave,
    saveReport,
    _handleApplyFilters,
    setCurrentFilterObj,
    handleUrlShortener,
    onDateDaysChange,
    setVisualisationTab,
    handleSetReportPublicChange,
    handleBookmarkReport,
    moveReportToFolder,
    handleRemoveBookmark,
    handleKeywordAddition,
    handleAggregateChange,
    handleSearchMotionFilters,
    handleUpdateSearchExtraFilter,
    handleExtraFilterSelectUpdate,
    handleAddNewFilter,
    handleRemoveNewFilter,
    handleAggregateLogicChangeExtraFilters,
    deleteReport,
    setSortedData,
    setRowSelection,
    setSelectedKPIs,
    changeGroupBy,
    onOpenFilters,
    onFilterWhereChange,
    onFilterValueChange,
    onUpdatePayload,
    onUpdateKpiFilters,
    onUpdateSelectedMetrices,
    handlePresetSelect,
    onUpdateReportData,
    setTempKpis,
    onFilterFilterChange,
    applyPayload,
    onRemovePayload,
    onFilterSetOpen,
    handleAdAccountId,
    handleDeleteFromFilterSet,
    handleAddMore,
    handleAddMoreGroup,
    handleDeleteGroup,
    handleDuplicateGroup,
    onAdditionalFilterWhereChange,
    onAdditionalFilterValueChange,
    onAdditionalFilterFilterChange,
    handleAdditionalFiltersAddMore,
    onAdditionalFilterRemovePayload,
    handleAdBreakdown,
    changeVisualizationTab,
    closeVisualizationTabSwitch,
    confirmVisualizationTabSwitch,
  };
}
