import React from "react";
import PublicContainer from "../../../../components/layouts/PublicContainer";
import { Typography } from "@mui/material";
import { emailLinkExpired } from "../../../../assets/icons/svg";

export default function VerificationLinkExpiredScreen() {
  return (
    <PublicContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "50px",
          }}
        >
          <img src={emailLinkExpired} alt="" />
        </div>

        <Typography
          variant="h3"
          sx={{ marginBottom: "25px", fontWeight: "700", textAlign: "center" }}
        >
          The link is expired and we have already sent you a new link.
        </Typography>
        <Typography
          variant="h3"
          sx={{
            color: "#7f7f7f",
            fontWeight: "500",
            textAlign: "center",
            lineHeight: 1.8,
          }}
        >
          Check your verfied mail box.
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></div>
      </div>
    </PublicContainer>
  );
}
