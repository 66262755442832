import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const todaysDate = moment().format("YYYY-MM-DD");

const initialInsightState = {
  filters: {},
  metrics: [],
  selectedMetrics: [],
  sortOrder: "asc",
  date: { selectionType: 4, startDate: todaysDate, endDate: todaysDate },
  sortBy: "",
  isReportPublic: false,
  keywords: [],
  aggregateLogic: null,
  extraFiltersAggregator: "AND",
  extraFilters: [
    {
      type: 1,
      condition: 1,
      tags: [],
      aggregator: "AND",
    },
  ],
};

const insightSlice = createSlice({
  name: "insights",
  initialState: initialInsightState,
  reducers: {
    setAll: (state, action) => {
      return { ...state, ...action.payload };
    },
    setSortOrder: (state, action) => {
      return { ...state, sortOrder: action.payload };
    },
    setSortBy: (state, action) => {
      return { ...state, sortBy: action.payload };
    },
    setFilters: (state, action) => {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    },
    setMetrics: (state, action) => {
      return { ...state, metrics: action.payload };
    },
    setSelectedMetrics: (state, action) => {
      return { ...state, selectedMetrics: action.payload };
    },
    setDate: (state, action) => {
      return {
        ...state,
        date: {
          ...action.payload,
          selectionType: parseInt(action?.payload?.selectionType),
        },
      };
    },
    setIsReportPublic: (state, action) => {
      return {
        ...state,
        isReportPublic: action.payload,
      };
    },
    setSelectedKeywords: (state, action) => {
      return {
        ...state,
        keywords: action.payload,
      };
    },
    setAggregateLogic: (state, action) => {
      return {
        ...state,
        aggregateLogic: action.payload,
      };
    },
    addExtraFilter: (state, action) => {
      return {
        ...state,
        extraFilters: [
          ...state?.extraFilters,
          {
            type: 1,
            condition: 1,
            tags: [],
            aggregator: state?.extraFiltersAggregator,
          },
        ],
      };
    },
    removeExtraFilterField: (state, action) => {
      return {
        ...state,
        extraFilters: [
          ...state?.extraFilters?.filter(
            (elem, idx) => idx !== action.payload.index
          ),
        ],
      };
    },
    updateExtraFilterFieldSelect: (state, action) => {
      const { index, key, value } = action.payload;
      const updatedExtraFilters = [...state.extraFilters];
      updatedExtraFilters[index] = {
        ...updatedExtraFilters[index],
        [key]: value,
      };
      return {
        ...state,
        extraFilters: updatedExtraFilters,
      };
    },
    updateExtraFilterAggregateLogic: (state, action) => {
      state.extraFiltersAggregator = action.payload.logic;
      state.extraFilters = [
        {
          type: 1,
          condition: 1,
          tags: [],
          aggregator: action.payload.logic,
        },
      ];
    },
    initExtraFilters: (state, action) => {
      state.extraFilters = initialInsightState?.extraFilters;
    },
    setExtraFilters: (state, action) => {
      state.extraFilters = action.payload;
    },
  },
});

export const {
  setAll,
  setFilters,
  setMetrics,
  setSelectedMetrics,
  setDate,
  setSortBy,
  setSortOrder,
  setIsReportPublic,
  setSelectedKeywords,
  setAggregateLogic,
  addExtraFilter,
  updateExtraFilterFieldSelect,
  removeExtraFilterField,
  updateExtraFilterAggregateLogic,
  initExtraFilters,
  setExtraFilters,
} = insightSlice.actions;

export default insightSlice.reducer;
