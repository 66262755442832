import React from "react";

const Filter = ({ style = {}, onClickHandler = () => {} }) => {
  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClickHandler}
    >
      <path
        d="M19 2H16M12 1V4M12 2H1M5 7H1M9 6V8M20 7H9M19 12H16M12 10V13M12 12H1"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Filter;
