import {
  Button,
  Dialog,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { InputWithLabel } from "components/common/molecules";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { modalInitState, setModal } from "redux/slices/modal.slices";

const UngrupedNewGroupModal = (props) => {
  const { modalType, handleCreateAndAddToNewGroup } = props;
  const { open, type, payload } = useSelector((state) => state?.modal);
  const [newgroupName, setNewGroupName] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  function handleClose() {
    setNewGroupName("");
    dispatch(setModal(modalInitState));
  }
  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open && modalType === type}
    >
      <Stack
        sx={{
          padding: "30px",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{ padding: "15px" }}
        >
          <Typography variant="h2">Create New Group</Typography>
          <CloseIcon sx={{ cursor: "pointer" }} onClick={() => handleClose()} />
        </Stack>
        <InputWithLabel
          label=""
          value={newgroupName}
          placeholder="Group Name"
          onChange={(e) => setNewGroupName(e.target.value)}
        />
        <Stack
          sx={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "100px",
              marginTop: "20px",
            }}
            disabled={newgroupName.length === 0}
            onClick={() =>
              handleCreateAndAddToNewGroup({
                newGroupName: newgroupName,
                id: payload?.id,
              })
            }
          >
            Create
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default UngrupedNewGroupModal;
