import { useSelector } from "react-redux";

export default function useAuth() {
  const { auth } = useSelector((state) => state);
  const { selectedOrganisation } = useSelector((state) => state.organisation);

  return {
    isUserAuthenticated: !!auth?.userData?.tokens?.user?.token,
    isOrganisationAuthenticated: !!selectedOrganisation?.token,
    userToken: auth?.userData?.tokens?.user?.token,
    orgToken: selectedOrganisation?.token,
    userData: auth?.userData,
  };
}
