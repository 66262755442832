import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  LogOut,
  User,
  Settings,
  ChevronDown,
  Users,
  SquareArrowOutUpRight,
  Clipboard,
  Building2,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  ACTIVE_FEATURES,
  A_PERMISSIONS,
  SA_PERMISSIONS,
} from "../../../../config/roles";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import {
  Collapse,
  Grid,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  Logo,
  Logout,
  // Settings,
  Tag,
  Insights,
  RecommendationIcon,
  PredictionIcon,
  BrandAssetIcon,
  Organisation,
  UpgradePlan,
  GenerationIcon,
  DashboardIcon,
} from "../../../../assets/icons/react";
import { APP_ROUTES } from "../../../../routes";
import { useDispatch, useSelector } from "react-redux";
import { SignalCellularAlt } from "@mui/icons-material";

import useAxios from "../../../../hooks/useAxios";
import { setSelectedOrganisation } from "../../../../redux/slices/organisation.slice";
import SideBarArrow from "assets/icons/react/SideBarArrow";
import useAppState from "hooks/useAppState";
import useUserRole from "hooks/useUserRole";
import { setSidebarOpen } from "redux/slices/appState.slices";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";

const closedDrawerWidth = "86px";
const openDrawerWidth = "240px";

const openedMixin = (theme) => ({
  width: openDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: closedDrawerWidth,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  transition: "ease-in-out",
  transitionDuration: "1s",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: open ? openDrawerWidth : closedDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const sidebarStyles = makeStyles(() => ({
  outerDiv: {
    display: "flex",
  },
  mainContent: {
    paddingTop: "31px",
    paddingLeft: "33px",
    paddingRight: "33px",
    flex: 1,
  },
  logoText: {
    fontFamily: "DM Sans !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    color: "#000 !important",
    marginLeft: "9px !important",
  },
  listText: {
    fontFamily: "DM Sans !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    color: "#858383 !important",
    minWidth: "max-content",
  },
  listTextActive: {
    color: "#7167FB !important",
  },
  bottomList: {
    display: "flex",
    flexDirection: "column",

    justifyContent: "flex-end",
    height: "100%",
    width: "100%",
  },
  dividerStyles: {
    border: "1px solid #7167FB",
    background: "#7167FB",
    width: "50px",
    marginLeft: "auto !important",
    marginRight: "auto !important",
    height: "0.5px",
    marginBottom: "10px !important",
  },
  openDividerStyles: {
    width: "176px",
  },
  listHeader: {
    marginTop: "14px",
    marginBottom: "20px",
    transition: "2s ease-in",
  },
  dropdownMenu: {
    "& .MuiPaper-root": {
      width: "337px",
      background: "#FFFFFF",
      border: "0.5px solid rgba(28, 30, 32, 0.5)",
      boxShadow: "0px 65px 177px rgba(0, 0, 0, 0.12)",
      borderRadius: "6px",
      padding: "16px 10px 4px 10px",
      marginTop: "20px",
    },
  },
  recommendationDropdownMenu: {
    "& .MuiPaper-root": {
      width: "270px",
      background: "#FFFFFF",
      border: "0.5px solid rgba(28, 30, 32, 0.5)",
      boxShadow: "0px 65px 177px rgba(0, 0, 0, 0.12)",
      borderRadius: "6px",
      padding: "16px 10px 4px 10px",
      marginTop: "20px",
    },
  },
  dropdownText1: {
    fontFamily: "DM Sans !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    color: "#1C1E20 !important",
  },
  dropdownText2: {
    fontFamily: "DM Sans !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    color: "rgba(28, 30, 32, 0.7) !important",
    lineHeight: "20px !important",
  },
  dropdownText3: {
    fontFamily: "DM Sans !important",
    fontWeight: "400 !important",
    fontSize: "10px !important",
    color: "rgba(28, 30, 32, 0.7) !important",
  },
  organizationDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "48px",
    borderRadius: "4px",
    padding: "0px 6px",
    marginTop: "8px",
    cursor: "pointer",
  },
  recommendationDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "48px",
    borderRadius: "4px",
    padding: "0px 6px",
    marginTop: "8px",
    cursor: "pointer",
  },
  organizationDivActive: {
    background: "rgba(113, 103, 251, 0.05)",
  },
  recommendationDivActive: {
    background: "rgba(113, 103, 251, 0.05)",
  },
  organizationInnerDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const GroupsTags = [
  APP_ROUTES.GROUPS_TAGS.default,
  ...Object.values(APP_ROUTES.GROUPS_TAGS.TAGGING),
  ...Object.values(APP_ROUTES.GROUPS_TAGS.UNGROUPED),
  ...Object.values(APP_ROUTES.GROUPS_TAGS.UNTAGGED),
];

const InsightsSubMenus = Object.values(APP_ROUTES.INSIGHTS);
const RecommendationSubMenus = Object.values(APP_ROUTES.RECOMMENDATION);

const isActiveLink = (activePage, menu, enableSidebar) => {
  switch (menu?.value) {
    case APP_ROUTES.GROUPS_TAGS.default:
      return (
        GroupsTags.includes(activePage) &&
        activePage === menu?.value &&
        enableSidebar
      );

    case APP_ROUTES.INSIGHTS.default:
      return InsightsSubMenus.includes(activePage) && enableSidebar;

    case APP_ROUTES.RECOMMENDATION.default:
      return RecommendationSubMenus.includes(activePage) && enableSidebar;
    default:
      return activePage === menu?.value && enableSidebar;
  }
};
const Sidebar = (props) => {
  const { activePage } = props;
  const classes = sidebarStyles();
  const navigate = useNavigate();
  const [subMenus, setSubMenus] = React.useState("");
  const { appState } = useSelector((state) => state?.appState);
  const open = appState.isSidebarOpen;
  const dispatch = useDispatch();
  const { axiosUserInstance } = useAxios();

  const disabledStyles = {
    opacity: 0.5,
    color: "#858383!important",
    pointerEvents: "none",
  };
  // let enableSidebar =
  //   appState?.isOrganizationImagesManualGroupingDone &&
  //   appState?.isOrganizationVideosManualGroupingDone &&
  //   appState?.isOrganizationTextsTaggingDone &&
  //   appState?.isOrganizationCustomAudiencesTaggingDone;
  let enableSidebar = true;

  const menuChangeHandler = (page) => {
    switch (page) {
      case "logout":
        navigate("/logout");
        return;

      case APP_ROUTES.RECOMMENDATION.default:
        navigate(APP_ROUTES.RECOMMENDATION.CREATIVE_RECOMMENDATION);
        setSubMenus(page);
        break;

      default:
        navigate(page);
        setSubMenus(page);
        setRecomAnchorEl(null);
        break;
    }
  };

  const SubMenusUI = (props) => {
    const { collapseMenuName, subMenuOptions } = props;
    return (
      <Collapse in={collapseMenuName} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subMenuOptions?.map(({ route, label }, idx) => {
            return (
              <div>
                <Tooltip title={open ? null : label} arrow placement="right">
                  <ListItemButton
                    sx={{
                      paddingLeft: "56px",
                      ...(activePage === route &&
                        enableSidebar && {
                          color: "#7167FB !important",
                        }),
                    }}
                    onClick={() => menuChangeHandler(route)}
                    key={route}
                  >
                    <ListItemText primary={open ? label : ""} />
                  </ListItemButton>
                </Tooltip>
              </div>
            );
          })}
        </List>
      </Collapse>
    );
  };

  const { allOrganisations = [], selectedOrganisation } = useSelector(
    (state) => state.organisation
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [recomAnchorEl, setRecomAnchorEl] = React.useState(null);
  const orgMenuOpen = Boolean(anchorEl);
  const recomMenuOpen = Boolean(recomAnchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRecomClick = (event) => {
    setRecomAnchorEl(event.currentTarget);
  };
  const handleRecomClose = (event) => {
    setRecomAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function organisationChangeHandler(org) {
    dispatch(setSelectedOrganisation(org));
  }

  const handleDrawerCollapse = () => {
    dispatch(setSidebarOpen(!appState.isSidebarOpen));
    handleClose();
  };

  const subMenuOptions = [
    {
      route: APP_ROUTES.RECOMMENDATION.CREATIVE_RECOMMENDATION,
      label: "Creative Feature",
    },
    {
      route: APP_ROUTES.RECOMMENDATION.VIDEO_RECOMMENDATION,
      label: "Video Intelligence",
    },
  ];

  const orgOnBoardedSidebarList = [
    {
      label: "Dashboard",
      value: APP_ROUTES.DASHBOARD,
    },
    {
      label: "Insights",
      value: APP_ROUTES.INSIGHTS.default,
    },
    {
      label: "Recommendations",
      value: APP_ROUTES.RECOMMENDATION.default,
    },
    {
      label: "Prediction",
      value: APP_ROUTES.PREDICTION.default,
    },
    {
      label: "Generation",
      value: APP_ROUTES.GENERATION.default,
    },
    { label: "Reports", value: APP_ROUTES.REPORTS.default },

    { label: "Groups & Tags", value: APP_ROUTES.GROUPS_TAGS.default },

    // {
    //   label: "Brand Assets",
    //   value: APP_ROUTES.BRAND_ASSETS.default,
    // },
  ];

  const employeeOptions = [
    {
      label: "Dashboard",
      value: APP_ROUTES.DASHBOARD,
    },
    {
      label: "Insights",
      value: APP_ROUTES.INSIGHTS.default,
    },
    {
      label: "Recommendations",
      value: APP_ROUTES.RECOMMENDATION.default,
    },
    {
      label: "Prediction",
      value: APP_ROUTES.PREDICTION.default,
    },
    {
      label: "Generation",
      value: APP_ROUTES.GENERATION.default,
    },
    { label: "Reports", value: APP_ROUTES.REPORTS.default },
  ];

  const OrgNotOnBoardedList = [
    {
      label: "Dashboard",
      value: APP_ROUTES.DASHBOARD,
    },
    { label: "Reports", value: APP_ROUTES.REPORTS.default },
  ];

  const { organisationOnBoarded } = useAppState();
  const { hasUserPermission, hasUserActiveFeature, isUserSubscribed } =
    useUserRole();
  const activeFeatures = appState?.activeFeatures;
  const shouldHideTab = (menu, activeFeatures, enableSidebar) => {
    if (menu.label === "Recommendations") {
      return (
        !hasUserActiveFeature(ACTIVE_FEATURES.RECOMMENDATION) || !enableSidebar
      );
    }
    if (menu.label === "Insights") {
      return !hasUserActiveFeature(ACTIVE_FEATURES.INSIGHTS) || !enableSidebar;
    }
    if (menu.label === "Prediction") {
      return (
        !hasUserActiveFeature(ACTIVE_FEATURES.PREDICTION) || !enableSidebar
      );
    }
    if (menu.label === "Generation") {
      return (
        !hasUserActiveFeature(ACTIVE_FEATURES.GENERATION) || !enableSidebar
      );
    }

    return false;
  };

  return (
    <Grid container>
      {/* <Grid item>
        <Stack width={"60px"} sx={{ backgroundColor: "black" }}>
          <Typography>HII</Typography>
        </Stack>
      </Grid> */}

      <Grid item sx={{ zIndex: "1" }}>
        <Stack>
          <Drawer
            variant="permanent"
            open={open}
            sx={{
              //hide scrollbar
              "& .MuiDrawer-paper": {
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              },
            }}
          >
            <DrawerHeader className={classes.listHeader}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                  rowGap: "20px",
                  cursor: "pointer",
                  // zIndex: "1000",
                }}
              >
                {/* <Logo height="43" width="43" />
          {open && (
            <Typography className={classes.logoText}>Storyteller</Typography>
          )} */}

                {/* organisation change handler now at top */}
                {open && (
                  <div>
                    <Button
                      onClick={(e) => handleClick(e)}
                      variant="outline"
                      className="flex justify-between gap-x-1 p-1 shadow-md"
                    >
                      <p className="bg-[#7167FB] text-white font-semibold p-1 rounded-sm">
                        {selectedOrganisation?.name
                          ? selectedOrganisation?.name
                              ?.slice(0, 2)
                              ?.toUpperCase()
                          : "ORG"}
                      </p>
                    </Button>
                  </div>
                )}

                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <Button
                      variant="outline"
                      className="flex justify-between gap-x-1 p-1"
                    >
                      <p className="bg-[#7167FB] text-white font-semibold p-1 rounded-sm">
                        {selectedOrganisation?.name
                          ? selectedOrganisation?.name
                              ?.slice(0, 2)
                              ?.toUpperCase()
                          : "N/A"}
                      </p>
                      <span className={`${!open ? "hidden" : ""}`}>
                        {selectedOrganisation?.name
                          ? `${selectedOrganisation?.name
                              ?.slice(0, 11)
                              ?.toUpperCase()} ${
                              selectedOrganisation?.name?.length > 20
                                ? ".."
                                : ""
                            }`
                          : "SETTINGS"}
                      </span>
                      <ChevronDown className="p-1" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-56 z-[99990] cursor-pointer">
                    <DropdownMenuLabel>
                      {selectedOrganisation?.name?.slice(0, 20)?.toUpperCase()}
                      {selectedOrganisation?.name?.length > 20 ? "..." : ""}
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                      <DropdownMenuItem
                        onClick={() => {
                          dispatch(
                            setModal({
                              open: true,
                              type: MODALTYPES.USER_PROFILE,
                              payload: {},
                            })
                          );
                        }}
                      >
                        <User className="mr-2 h-4 w-4" />
                        <span>Profile</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => navigate(APP_ROUTES.REPORTS?.default)}
                      >
                        <Clipboard className="mr-2 h-4 w-4" />
                        <span>Reports</span>
                        <DropdownMenuShortcut>
                          <SquareArrowOutUpRight className="p-2" />
                        </DropdownMenuShortcut>
                      </DropdownMenuItem>
                      {hasUserPermission(A_PERMISSIONS.VIEW_ACCOUNT) ? (
                        <DropdownMenuItem
                          onClick={() => navigate(APP_ROUTES.SETTING)}
                        >
                          <Settings className="mr-2 h-4 w-4" />
                          <span>Settings</span>
                          <DropdownMenuShortcut>
                            <SquareArrowOutUpRight className="p-2" />
                          </DropdownMenuShortcut>
                        </DropdownMenuItem>
                      ) : null}
                      {hasUserPermission(SA_PERMISSIONS.ADD_USER) ? (
                        <DropdownMenuItem
                          onClick={() => {
                            dispatch(
                              setModal({
                                open: true,
                                type: MODALTYPES.INVITE_USER_MODAL,
                                payload: {},
                              })
                            );
                            navigate(APP_ROUTES.SETTING);
                          }}
                        >
                          <Users className="mr-2 h-4 w-4" />
                          <span>Add Member</span>
                          <DropdownMenuShortcut>
                            <SquareArrowOutUpRight className="p-2" />
                          </DropdownMenuShortcut>
                        </DropdownMenuItem>
                      ) : null}
                      {!open ? (
                        <DropdownMenuItem
                          onClick={(e) => {
                            handleClick(e);
                          }}
                        >
                          <Building2 className="mr-2 h-4 w-4" />
                          <span>Organizations</span>
                        </DropdownMenuItem>
                      ) : null}
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      onClick={() => navigate(APP_ROUTES.AUTH.LOGOUT)}
                    >
                      <LogOut className="mr-2 h-4 w-4" />
                      <span>Log out</span>
                      <DropdownMenuShortcut>
                        <SquareArrowOutUpRight className="p-2" />
                      </DropdownMenuShortcut>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </DrawerHeader>
            <Divider
              className={`${classes.dividerStyles} ${
                open && classes.openDividerStyles
              }`}
              sx={{ height: "1px" }}
            />

            <List>
              {(organisationOnBoarded
                ? hasUserPermission("ALL")
                  ? orgOnBoardedSidebarList
                  : employeeOptions
                : OrgNotOnBoardedList
              ).map((menu) => {
                if (shouldHideTab(menu, activeFeatures, enableSidebar)) {
                  return null; // Render nothing for hidden tabs
                }
                return (
                  <Tooltip
                    title={open ? null : menu.label}
                    arrow
                    placement="right"
                  >
                    <div
                      style={!enableSidebar ? disabledStyles : {}}
                      key={menu?.value}
                    >
                      <Link
                        style={{
                          textDecoration: "none",
                          backgroundColor: "transparent",
                        }}
                        to={
                          open
                            ? menu?.value ===
                              "/recommendation/:recommendationType"
                              ? APP_ROUTES.RECOMMENDATION
                                  .CREATIVE_RECOMMENDATION
                              : menu?.value
                            : menu?.value ===
                              "/recommendation/:recommendationType"
                            ? ""
                            : menu?.value
                        }
                      >
                        <ListItemButton
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            if (
                              menu?.value ===
                                "/recommendation/:recommendationType" &&
                              !open
                            ) {
                              handleRecomClick(e);
                            }
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: open ? 0 : "full",
                              mr: open ? "8px" : "0px",
                              justifyContent: "center",
                            }}
                          >
                            {GroupsTags.includes(menu.value) && (
                              <Tag
                                isActive={
                                  GroupsTags.includes(activePage) &&
                                  enableSidebar
                                }
                              />
                            )}

                            {menu?.value === APP_ROUTES.DASHBOARD && (
                              <DashboardIcon
                                isActive={activePage === APP_ROUTES.DASHBOARD}
                              />
                            )}

                            {menu.value === APP_ROUTES.INSIGHTS.default && (
                              <Insights
                                isActive={
                                  InsightsSubMenus.includes(activePage) &&
                                  enableSidebar
                                }
                              />
                            )}

                            {menu?.value === APP_ROUTES.REPORTS.default && (
                              <SignalCellularAlt
                                style={{
                                  color:
                                    activePage === APP_ROUTES.REPORTS.default
                                      ? "#7167FB"
                                      : "#858383",
                                }}
                              />
                            )}

                            {menu?.value ===
                              APP_ROUTES.RECOMMENDATION.default && (
                              <RecommendationIcon
                                isActive={
                                  RecommendationSubMenus.includes(activePage) &&
                                  enableSidebar
                                }
                              />
                            )}

                            {menu?.value === APP_ROUTES.GENERATION.default && (
                              <GenerationIcon
                                isActive={
                                  activePage === APP_ROUTES.GENERATION.default
                                }
                              />
                            )}

                            {menu?.value === APP_ROUTES.PREDICTION.default && (
                              <PredictionIcon
                                isActive={
                                  activePage === APP_ROUTES.PREDICTION.default
                                }
                              />
                            )}

                            {menu?.value ===
                              APP_ROUTES.BRAND_ASSETS.default && (
                              <BrandAssetIcon
                                isActive={
                                  activePage === APP_ROUTES.BRAND_ASSETS.default
                                }
                              />
                            )}
                          </ListItemIcon>

                          {menu?.value === APP_ROUTES.GROUPS_TAGS.default ? (
                            <ListItemText
                              primary={menu?.label}
                              sx={{ opacity: open ? 1 : 0 }}
                              className={`${classes.listText} ${
                                GroupsTags.includes(activePage) &&
                                enableSidebar &&
                                classes.listTextActive
                              }`}
                            />
                          ) : (
                            <ListItemText
                              primary={menu?.label}
                              sx={{ opacity: open ? 1 : 0 }}
                              className={`${classes.listText} ${
                                isActiveLink(activePage, menu, enableSidebar) &&
                                classes.listTextActive
                              }`}
                            />
                          )}

                          {menu?.label === "Recommendations" ? (
                            subMenus?.tagging ? (
                              <ExpandLess
                                sx={{
                                  marginLeft: "4px",
                                }}
                              />
                            ) : (
                              <ExpandMore
                                sx={{
                                  marginLeft: "4px",
                                }}
                              />
                            )
                          ) : null}
                        </ListItemButton>
                      </Link>

                      {menu?.label === "Insights" && (
                        <SubMenusUI
                          collapseMenuName={InsightsSubMenus.includes(
                            activePage
                          )}
                        />
                      )}
                      {menu?.label === "Recommendations" && open ? (
                        <SubMenusUI
                          collapseMenuName={RecommendationSubMenus.includes(
                            activePage
                          )}
                          subMenuOptions={[
                            {
                              route:
                                APP_ROUTES.RECOMMENDATION
                                  .CREATIVE_RECOMMENDATION,
                              label: "Creative Feature",
                            },
                            {
                              route:
                                APP_ROUTES.RECOMMENDATION.VIDEO_RECOMMENDATION,
                              label: "Video Intelligence",
                            },
                          ]}
                        />
                      ) : null}
                    </div>
                  </Tooltip>
                );
              })}
            </List>
            <Box className={classes.bottomList}>
              <Divider
                className={`${classes.dividerStyles} ${
                  open && classes.openDividerStyles
                }`}
              />
              <List>
                {(hasUserPermission(A_PERMISSIONS.VIEW_ACCOUNT) &&
                hasUserPermission(A_PERMISSIONS.HANDLE_PAYMENTS)
                  ? !isUserSubscribed()
                    ? [
                        {
                          label: "Upgrade Plan",
                          value: APP_ROUTES.UPGRADE_PLAN,
                        },
                        // { label: "Organisation", value: "ORG_MENU" },
                        // { label: "Settings", value: APP_ROUTES.SETTING },
                        // { label: "Logout", value: APP_ROUTES.AUTH.LOGOUT },
                      ]
                    : [
                        // { label: "Organisation", value: "ORG_MENU" },
                        // { label: "Settings", value: APP_ROUTES.SETTING },
                        // { label: "Logout", value: APP_ROUTES.AUTH.LOGOUT },
                      ]
                  : [
                      // { label: "Organisation", value: "ORG_MENU" },
                      // { label: "Logout", value: APP_ROUTES.AUTH.LOGOUT },
                    ]
                ).map((menu) => (
                  <Tooltip
                    title={open ? null : menu.label}
                    arrow
                    placement="right"
                  >
                    <div>
                      <Link
                        style={{
                          textDecoration: "none",
                        }}
                        to={menu?.value !== "ORG_MENU" ? menu?.value : ""}
                      >
                        <ListItem
                          disablePadding
                          key={menu?.value}
                          aria-haspopup="listbox"
                          aria-controls="listbox-menu"
                          aria-expanded={orgMenuOpen ? "true" : undefined}
                          onClick={(e) => {
                            if (menu?.value !== "ORG_MENU") {
                              menuChangeHandler(menu?.value);
                            } else {
                              handleClick(e);
                            }
                          }}
                        >
                          <ListItemButton>
                            <ListItemIcon
                              sx={{
                                minWidth: open ? 0 : "full",
                                mr: open ? "19px" : "0px",
                                justifyContent: "center",
                              }}
                            >
                              {menu?.label === "Settings" && (
                                <Settings
                                  isActive={activePage === APP_ROUTES.SETTING}
                                />
                              )}
                              {menu.label === "Logout" && (
                                <Logout
                                  isActive={
                                    activePage === APP_ROUTES.AUTH.LOGOUT
                                  }
                                />
                              )}
                              {menu.label === "Organisation" && (
                                <Organisation
                                  isActive={
                                    activePage === APP_ROUTES.ORGANISATION
                                  }
                                ></Organisation>
                              )}
                              {menu.label === "Upgrade Plan" && (
                                <UpgradePlan
                                  isActive={
                                    activePage === APP_ROUTES.UPGRADE_PLAN
                                  }
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                menu?.label === "Organisation"
                                  ? selectedOrganisation?.name !== undefined
                                    ? `${selectedOrganisation?.name?.slice(
                                        0,
                                        13
                                      )} ${
                                        selectedOrganisation?.name?.length > 13
                                          ? ".."
                                          : ""
                                      }`
                                    : "Organisation"
                                  : menu.label
                              }
                              style={{ opacity: open ? 1 : 0, color: "red" }}
                              className={`${classes.listText} ${
                                activePage === menu?.value &&
                                enableSidebar &&
                                classes.listTextActive
                              }`}
                            />
                            {menu?.label === "Organisation" && <ExpandMore />}
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    </div>
                  </Tooltip>
                ))}
              </List>
              <List
                sx={{
                  mt: 0,
                  position: "sticky",
                  bottom: 0,
                  alignItems: "start",
                  backgroundColor: "white",
                }}
              >
                <ListItemButton
                  sx={{
                    minWidth: "full",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={handleDrawerCollapse}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: open ? 0 : "full",
                      justifyContent: "center",
                    }}
                  >
                    <SideBarArrow isSidebarOpen={open} isActive={!open} />
                  </ListItemIcon>
                </ListItemButton>
              </List>
              <Menu
                anchorEl={anchorEl}
                open={orgMenuOpen}
                onClose={handleClose}
                className={classes.dropdownMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {/* <Typography className={classes.dropdownText1}>
            {enableSidebar ? "Select Organization" : "Add Organization"}
          </Typography> */}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(APP_ROUTES.AUTH.ADD_ORGANIZATION)}
                >
                  {allOrganisations?.length > 0
                    ? "Select Organisation"
                    : "Add New Organisation"}
                </div>

                {allOrganisations?.map((org) => {
                  return (
                    <div
                      key={org?._id}
                      className={`${classes.organizationDiv} ${
                        org?._id === selectedOrganisation?._id &&
                        classes.organizationDivActive
                      }`}
                      onClick={() => organisationChangeHandler(org)}
                    >
                      <Typography className={classes.dropdownText2}>
                        {org?.name}
                      </Typography>
                      <div className={classes.organizationInnerDiv}>
                        <Typography className={classes.dropdownText3}>
                          {org?.adAccountIds?.length ?? 0} Account Linked
                        </Typography>
                      </div>
                    </div>
                  );
                })}
              </Menu>
              <Menu
                anchorEl={recomAnchorEl}
                open={recomMenuOpen}
                onClose={handleRecomClose}
                className={classes.recommendationDropdownMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Typography className={classes.dropdownText1}>
                  Select Recommendation
                </Typography>
                {subMenuOptions?.map(({ route, label }, idx) => {
                  return (
                    <div
                      onClick={() => menuChangeHandler(route)}
                      className={`${classes.recommendationDiv} ${
                        RecommendationSubMenus.includes(activePage)
                          ? classes.recommendationDivActive
                          : ""
                      }`}
                    >
                      <Typography className={classes.dropdownText2}>
                        {label}
                      </Typography>
                    </div>
                  );
                })}
              </Menu>
            </Box>
          </Drawer>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Sidebar;
