import {
  Button,
  Checkbox,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { InputWithLabel } from "components/common/molecules";
import PublicContainer from "components/layouts/PublicContainer";
import useSignup from "./useSignup";
import { GoogleIcon, MetaIcon } from "assets/icons/react";
import styles from "./styles.module.scss";
import { line } from "assets/images";
import { sign_up_illustration } from "assets/images";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
export default function SignupScreen() {
  const {
    form,
    isValidated,
    isLoading,
    continueWithEmail,
    setContinueWithEmail,
    handleChange,
    handleSubmit,
    handleFirebaseFacebookLogin,
    handleFirebaseGoogleLogin,
    handleHomeRoute,
  } = useSignup();

  return (
    <Stack
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "100px",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "4rem",
          width: "100%",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{ position: "relative", width: "100%", maxWidth: "488px" }}
          >
            {continueWithEmail && (
              <IconButton
                onClick={() => {
                  setContinueWithEmail(false);
                }}
                sx={{
                  width: "3rem",
                  height: "3rem",
                  position: "absolute",
                  top: -10,
                  left: 0,
                  border: "1px solid grey",
                  color: "black",
                  borderRadius: "999px",
                }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
            )}
            <img
              src="/logoNavbar.svg"
              alt="logo"
              onClick={handleHomeRoute}
              className="cursor-pointer h-16"
            />
          </Stack>

          {continueWithEmail ? (
            <Stack flexDirection={"column"}>
              <div className={styles.form_styles}>
                <InputWithLabel
                  label="Email Id"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />
                <InputWithLabel
                  label="Create Password"
                  name="password"
                  type="password"
                  value={form.password}
                  onChange={handleChange}
                />
                <InputWithLabel
                  label="Re-Enter Password"
                  type="password"
                  name="re_password"
                  value={form.re_password}
                  onChange={handleChange}
                />

                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "24px",
                    width: "100%",
                    maxWidth: "488px",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "30px",
                      width: "100%",
                    }}
                  >
                    <Checkbox
                      defaultChecked
                      checked={form.terms_conditions}
                      onChange={handleChange}
                      name="terms_conditions"
                      sx={{
                        color: "#0d0c22",
                        "&.Mui-checked": {
                          color: "#0d0c22",
                        },
                      }}
                    />
                    <Typography variant="h4" fontSize={"1rem"}>
                      I Confirm That I Have Read and Agree to The
                      <Typography
                        variant="h4"
                        color="secondary"
                        style={{ display: "inline", cursor: "pointer" }}
                        fontSize={"1rem"}
                      >
                        {" "}
                        Terms And Conditions{" "}
                      </Typography>
                      of Storyteller
                    </Typography>
                  </Stack>
                  <Button
                    disabled={!isValidated || isLoading}
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      textTransform: "none",
                      gap: "1rem",
                      width: "100%",
                      maxWidth: "488px",
                      background: "#0d0c22",
                      borderRadius: "2rem",
                      padding: "0.75rem",
                      "&:hover": {
                        background: "#565564",
                      },
                    }}
                  >
                    {!isLoading ? "Next" : "Please Wait..."}
                  </Button>
                  <Typography
                    fontSize={"1rem"}
                    fontWeight={400}
                    textAlign={"center"}
                    marginTop={"40px"}
                    sx={{ maxWidth: "488px" }}
                    color={"#3d3d4e"}
                  >
                    Already have an account?{" "}
                    <span
                      className="underline cursor-pointer"
                      onClick={handleHomeRoute}
                    >
                      Sign In
                    </span>
                  </Typography>
                </Stack>
              </div>
            </Stack>
          ) : (
            <>
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "24px",
                  marginTop: "40px",
                  color: "#A1B0CC",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    gap: "1rem",
                    width: "100%",
                    maxWidth: "488px",
                    background: "#0d0c22",
                    borderRadius: "2rem",
                    padding: "0.75rem",
                    "&:hover": {
                      background: "#565564",
                    },
                  }}
                  startIcon={<GoogleIcon />}
                  onClick={handleFirebaseGoogleLogin}
                >
                  Sign up with Google
                </Button>
                {/* <Button
              sx={{ textTransform: "none" }}
              variant="outlined"
              startIcon={<MetaIcon />}
              color="inherit"
              onClick={handleFirebaseFacebookLogin}
              >
              {" "}
              Meta
              </Button> */}
              </Stack>

              <div className={styles.between_lines}>
                <img src={line} alt="line" />
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                  or
                </Typography>
                <img src={line} alt="line" />
              </div>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  gap: "1rem",
                  width: "100%",
                  maxWidth: "488px",
                  color: "#0d0c22",
                  border: "1px solid #e7e7e9",
                  borderRadius: "2rem",
                  padding: "0.75rem",
                  marginTop: "40px",
                  "&:hover": {
                    border: "1px solid #dbdbde",
                    background: "white",
                  },
                }}
                onClick={() => {
                  setContinueWithEmail(true);
                }}
              >
                Continue with email
              </Button>
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                textAlign={"center"}
                marginTop={"40px"}
                sx={{ maxWidth: "488px" }}
                color={"#3d3d4e"}
              >
                By creating an account you agree with our{" "}
                <span className="underline cursor-pointer">
                  Terms of Service
                </span>
                ,{" "}
                <span className="underline cursor-pointer">
                  {" "}
                  Privacy Policy
                </span>
                , and our default{" "}
                <span className="underline cursor-pointer">
                  Notification Settings.
                </span>
              </Typography>
              <Typography
                fontSize={"1rem"}
                fontWeight={400}
                textAlign={"center"}
                marginTop={"25px"}
                sx={{ maxWidth: "488px" }}
                color={"#3d3d4e"}
              >
                Already have an account?{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={handleHomeRoute}
                >
                  Sign In
                </span>
              </Typography>
            </>
          )}
        </Stack>
        <div
          className="hidden xl:flex"
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={sign_up_illustration}
            alt=""
            className={styles.loginimage}
          />
        </div>
      </Container>
    </Stack>
  );
}
