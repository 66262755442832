import { Stack, Typography } from "@mui/material";
import React from "react";

const stepsMapping = ["Upload Creatives", "Start Tagging", "Select Metrics"];
export default function GrayHeader({ step = 1 }) {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ padding: "16px", background: "#F9F9F9" }}
    >
      {stepsMapping.map((item, index) => {
        const active = index + 1 === step;
        return (
          <Stack direction="row" spacing={1 / 2} key={item}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: active ? "#7167FB" : "#1C1E20",
              }}
            >
              {`Step ${index + 1}:`}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: active ? "#7167FB" : "#1C1E20",
              }}
            >
              {item}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
}
