import React from "react";

const ContainerWithLabelStyles = {
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  fontWeight: "400",
  color: "rgba(28, 30, 32, 0.7)",
  padding: "19px 14px",
  border: "1px solid #A1B0CC",
  borderRadius: "6px",
  minWidth: "350px",
  cursor: "pointer",
};
const SelectedStyles = {
  border: "1px solid #7167FB",
  color: "#7167FB",
};

export default function ContainerWithLabel(props) {
  const { label, styles, selected = false } = props;
  return (
    <div
      style={{
        ...ContainerWithLabelStyles,
        ...(selected && SelectedStyles),
        ...styles,
      }}
    >
      {label}
    </div>
  );
}
