import React, { useState } from "react";
import {
  Button,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import {
  AutoCompleteWithChips,
  DateFilter,
  TitleBar,
} from "components/common/molecules";
import { PrivateContainer } from "components/layouts";
import { useDispatch, useSelector } from "react-redux";
import { APP_ROUTES } from "routes";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { DateFilterOptions } from "components/common/molecules/DateFilter";
import useRecommendation from "./useRecommendations";
import MultipleValuesFilter from "components/common/atoms/CustomSelect/MultipleValuesFilter/MultipleValuesFilter";
import ForumIcon from "@mui/icons-material/Forum";

import { useNavigate, useParams } from "react-router-dom";
import CreativeFeature from "./CreativeFeature/CreativeFeature";
import VideoIntelligence from "./VideoIntelligence/VideoIntelligence";
import { SaveReportModal } from "components/modals";
import SEO from "utils/SEO";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import ExtraFilters from "screens/Insights/Components/ExtraFilters/ExtraFilters";
import index from "components/common/molecules/PillProgressIndicator/PillsProgressIndicator";
import {
  CometChatMessages,
  ListItemStyle,
  MessageHeaderConfiguration,
  MessagesStyle,
} from "@cometchat/chat-uikit-react";
import { ChatIcon } from "assets/icons/react";
import StorytellerLogoLoader from "components/common/loaders/StorytellerLogoLoader";

export default function Recommendation() {
  const { recommendationType } = useParams();
  const dispatch = useDispatch();
  const {
    recommendationState,
    loadingReport,
    onDateDaysChange,
    onFilterUpdate,
    onSelectedMetricsUpdate,
    onApplyFilter,
    recommendationData,
    saveRecommendationReport,
    loadingRecommendationData,
    isSaveReportEnable,
    disableFilters,
    hideSidebar,
    handleSetReportPublicChange,
    isReportPublic,
    specificRecommendationId,
    handleBookmarkReport,
    handleRemoveBookmark,
    isBookmarkSuccess,
    smartSuggestion,
    isSmartSuggestionLoading,
    handleSearchMotionFilters,
    handleExtraFilterSelectUpdate,
    handleUpdateSearchExtraFilter,
    handleRemoveNewFilter,
    handleAggregateLogicChangeExtraFilters,
    handleAddNewFilter,
    onRemoveSelectedMetrics,
    currentReportOrgId,
    currentGroup,
  } = useRecommendation();

  const metricsOptions = recommendationState?.metrics?.map((item) => ({
    label: item?.label,
    value: item?.kpi,
  }));
  const [activeRecommendation, setActiveRecommendation] = React.useState(0);
  const { open, type } = useSelector((state) => state?.modal);
  const { bookmarkedReports, userBookmarkReports } = useSelector(
    (state) => state?.app
  );
  const navigate = useNavigate();
  const [isSmartFiltersEnable, setIsSmartFiltersEnable] = useState(false);
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  const lStyle = new ListItemStyle({
    background: "transparent",
  });

  const mhConfig = new MessageHeaderConfiguration({
    listItemStyle: lStyle,
    display: "none",
  });

  const hasWindow = typeof window !== "undefined";

  const mStyle = new MessagesStyle({
    width: "390px",
    height: hasWindow ? window.innerHeight - 20 : "760px",
    overflowY: "scroll",
    display: "flex",
    justifyContent: "right",
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    setIsDrawerOpen((prev) => !prev);
  };
  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
        height: "auto",
        paddingBottom: "32px",
      }}
      activePage={
        recommendationType === "creativeFeature"
          ? APP_ROUTES.RECOMMENDATION.CREATIVE_RECOMMENDATION
          : APP_ROUTES.RECOMMENDATION.VIDEO_RECOMMENDATION
      }
      hideSidebar={hideSidebar}
    >
      {/* <Tooltip title="Open Chat" placement="left">
        <IconButton
          disabled={disableFilters || !specificRecommendationId}
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            zIndex: 1000,
            padding: "7px",
            height: "70px",
            width: "70px",
            display: disableFilters || !specificRecommendationId ? "none" : "",
            backgroundColor: "rgb(113, 103, 251)",
            boxShadow: "0 0 15px 0px #00000080",
            color: "#fff",
            "&:hover": {
              backgroundColor: "rgb(113, 103, 251)",
            },
          }}
          onClick={handleDrawerOpen}
        >
          <ChatIcon />
        </IconButton>
      </Tooltip> */}
      {loadingReport && specificRecommendationId ? (
        <StorytellerLogoLoader />
      ) : (
        <Stack direction="row" spacing={1} width={"100%"}>
          <Stack direction="column" width={isDrawerOpen ? "70%" : "100%"}>
            <TitleBar title="Recommendation" subtitle="Meta">
              <Stack direction="row" spacing={2} alignItems="center">
                {isSaveReportEnable && !hideSidebar ? (
                  <Button
                    variant="contained"
                    sx={{
                      pointerEvents:
                        disableFilters || hideSidebar ? "none" : "",
                      opacity: disableFilters || hideSidebar ? 0.5 : 1,
                      display: disableFilters ? "none" : "",
                      textTransform: "capitalize",
                    }}
                    onClick={() =>
                      dispatch(
                        setModal({
                          type: MODALTYPES.SAVE_REPORT_MODAL,
                          open: true,
                        })
                      )
                    }
                  >
                    Save as Report
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      display: hideSidebar ? "" : "none",
                    }}
                    onClick={() => navigate(APP_ROUTES.AUTH.LOGIN)}
                  >
                    Sign In
                  </Button>
                )}
                <Stack
                  direction="row"
                  sx={{
                    display:
                      hideSidebar || !specificRecommendationId ? "none" : "",
                    mt: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      height: "36px",
                      fontSize: "auto",
                      display:
                        hideSidebar || !specificRecommendationId ? "none" : "",
                    }}
                    onClick={
                      isBookmarkSuccess ||
                      bookmarkedReports.includes(specificRecommendationId) ||
                      userBookmarkReports.includes(specificRecommendationId)
                        ? handleRemoveBookmark
                        : handleBookmarkReport
                    }
                  >
                    {bookmarkedReports.includes(specificRecommendationId) ||
                    isBookmarkSuccess ||
                    userBookmarkReports.includes(specificRecommendationId) ? (
                      <BookmarkAddedIcon />
                    ) : (
                      <BookmarkBorderIcon />
                    )}
                  </Button>
                </Stack>
                <div
                  style={{
                    pointerEvents: disableFilters || hideSidebar ? "none" : "",
                    opacity: disableFilters || hideSidebar ? 0.5 : 1,
                  }}
                >
                  <DateFilter
                    options={DateFilterOptions}
                    key={
                      recommendationState?.date?.startDate +
                      recommendationState?.date?.endDate
                    }
                    value={recommendationState?.date}
                    onChange={(value) => {
                      onDateDaysChange(value);
                    }}
                  />
                </div>
              </Stack>
            </TitleBar>
            <Stack
              direction={"column"}
              spacing={2}
              sx={{ marginBottom: "20px" }}
            >
              <Stack
                direction="row"
                gap={0}
                sx={{
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                  alignItems: "center",
                  maxWidth: "370px",
                  pointerEvents: disableFilters || hideSidebar ? "none" : "",
                  opacity: disableFilters || hideSidebar ? 0.5 : 1,
                }}
              >
                <Typography sx={{ marginRight: "10px" }}>
                  Selected Metrices
                </Typography>
                {recommendationState?.selectedMetrics?.map((metric, index) => {
                  const selectedMetric = recommendationState?.metrics?.find(
                    (curr) => curr.label === metric.label
                  );
                  return (
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography color="inherit">
                            {metric?.label}
                          </Typography>
                          <b>{selectedMetric?.description}</b>
                        </React.Fragment>
                      }
                    >
                      <Chip
                        label={metric?.label}
                        variant="outlined"
                        onDelete={() => {
                          onRemoveSelectedMetrics(index);
                        }}
                      />
                    </HtmlTooltip>
                  );
                })}
              </Stack>
              <Stack
                direction="row"
                gap={2}
                flexWrap={"wrap"}
                alignItems={"center"}
                sx={{
                  pointerEvents: disableFilters || hideSidebar ? "none" : "",
                  opacity: disableFilters || hideSidebar ? 0.5 : 1,
                }}
              >
                {Object.entries(recommendationState?.filters).map(
                  ([key, value]) => {
                    if (key === "dateRange") return null;
                    if (
                      recommendationType !== "creativeFeature" &&
                      key === "creativeTypes"
                    )
                      return null;
                    let defaultOption =
                      value?.options?.filter((item) => item.default) || [];
                    return (
                      <MultipleValuesFilter
                        key={key}
                        id={key}
                        options={value?.options}
                        label={value?.label}
                        selectedOption={defaultOption}
                        onChange={(selectedOption) => {
                          onFilterUpdate(key, selectedOption);
                        }}
                      />
                    );
                  }
                )}
                <AutoCompleteWithChips
                  optionsList={metricsOptions}
                  inputLabel="Search Metrics"
                  values={recommendationState?.selectedMetrics}
                  onTagUpdate={(value) => {
                    onSelectedMetricsUpdate(value);
                  }}
                  limit={5}
                  maxVisibleTags={2}
                  showTags={false}
                  multiple={false}
                />
                {/* {isSmartFiltersEnable ? (
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    height: "36px",
                    fontSize: "auto",
                  }}
                  onClick={() => setIsSmartFiltersEnable((prev) => !prev)}
                >
                  Exit Smart Filters
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    height: "36px",
                    fontSize: "auto",
                  }}
                  onClick={() => setIsSmartFiltersEnable((prev) => !prev)}
                >
                  Enable Smart Filters
                </Button>
              )} */}
                <Stack direction="column" width={"100"}>
                  {isSmartFiltersEnable && (
                    <ExtraFilters
                      state={recommendationState}
                      handleExtraFilterSelectUpdate={
                        handleExtraFilterSelectUpdate
                      }
                      handleSearchMotionFilters={handleSearchMotionFilters}
                      handleUpdateSearchExtraFilter={
                        handleUpdateSearchExtraFilter
                      }
                      smartSuggestions={smartSuggestion}
                      handleAddNewFilter={handleAddNewFilter}
                      handleRemoveNewFilter={(index) =>
                        handleRemoveNewFilter(index)
                      }
                      handleAggregateLogicChangeExtraFilters={(value) =>
                        handleAggregateLogicChangeExtraFilters(value)
                      }
                      isSmartSuggestionLoading={isSmartSuggestionLoading}
                    />
                  )}
                </Stack>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    height: "36px",
                    fontSize: "auto",
                  }}
                  onClick={() => onApplyFilter()}
                >
                  Apply Filters
                </Button>
              </Stack>
            </Stack>
            {recommendationType === "creativeFeature" && (
              <CreativeFeature
                recommendationData={
                  Object.keys(recommendationData).includes("categories")
                    ? recommendationData
                    : {}
                }
                activeRecommendation={activeRecommendation}
                setActiveRecommendation={setActiveRecommendation}
                loadingRecommendationData={loadingRecommendationData}
                categories={recommendationData?.categories}
                isLoading={loadingRecommendationData}
              />
            )}
            {recommendationType === "videoIntelligence" && (
              <VideoIntelligence
                recommendationData={
                  Object.keys(recommendationData).includes("segment")
                    ? recommendationData
                    : {}
                }
                activeRecommendation={activeRecommendation}
                setActiveRecommendation={setActiveRecommendation}
                loadingRecommendationData={loadingRecommendationData}
                segment={recommendationData?.segment}
                isLoading={loadingRecommendationData}
              />
            )}
          </Stack>
          <Stack
            width={isDrawerOpen ? "30%" : "0%"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              maxHeight: "calc(100vh - 9rem)",
              position: "sticky",
              top: "0",
              right: "0",
            }}
          >
            <Stack
              direction="column"
              sx={{
                width: "100%",
              }}
            >
              {currentGroup ? (
                <>
                  <CometChatMessages
                    group={currentGroup}
                    messagesStyle={mStyle}
                    messageHeaderConfiguration={mhConfig}
                  />
                </>
              ) : null}
            </Stack>
          </Stack>
          {open && type === MODALTYPES.SAVE_REPORT_MODAL && (
            <SaveReportModal
              selectedFilters={recommendationState?.filters}
              selectedMetrics={recommendationState?.selectedMetrics}
              selectedDate={recommendationState?.date}
              dateFilterOptions={DateFilterOptions}
              onClick={(reportName) => {
                saveRecommendationReport(reportName);
              }}
              onChange={handleSetReportPublicChange}
              isReportPublic={isReportPublic}
            />
          )}
        </Stack>
      )}
    </PrivateContainer>
  );
}
