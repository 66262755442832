import React, { useEffect } from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { VIDEO_SEGMENT_TYPE_REVERSE } from "utils/constants";
import { generateRandomColor } from "utils/helper";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "white",
          padding: "5px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      >
        {Object.entries(data).map(([key, value]) => {
          if (key === "thumbnailUrl") return null;
          return <p>{`${key}: ${value}`}</p>;
        })}
      </div>
    );
  }

  return null;
};

export default function SameDataComposedChart({
  segmentData = [],
  categories,
}) {
  const chartData = segmentData?.map((item) => {
    let data = {};
    data["name"] = VIDEO_SEGMENT_TYPE_REVERSE[item?.key];
    data["impact"] = item?.impact;
    categories?.forEach((category) => {
      item?.category?.forEach((cat) => {
        if (cat?.key === category?.label) {
          data[cat?.key] = cat?.impact;
        }
      });
      if (!data[category?.label]) {
        data[category?.label] = 0;
      }
    });

    return data;
  });
  const lineChartKeys = Object.keys(chartData?.[0]).filter((item) => {
    return item !== "name" && item !== "impact";
  });

  const [randomColors, setRandomColors] = React.useState([]);

  useEffect(() => {
    if (randomColors.length === lineChartKeys.length) return;
    let colorsList = randomColors;

    lineChartKeys?.forEach((v, i) => {
      if (!colorsList[i]) {
        let color = generateRandomColor();
        while (randomColors.includes(color)) {
          color = generateRandomColor();
        }
        colorsList.push(generateRandomColor());
      }
    });
    setRandomColors(colorsList);
  }, [lineChartKeys]);

  return (
    <ResponsiveContainer width="100%" height={500}>
      <ComposedChart
        width={500}
        height={500}
        data={chartData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" interval={0} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar dataKey="impact" barSize={20}>
          {chartData?.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry?.impact < 0 ? "red" : "green"}
            />
          ))}
        </Bar>
        {lineChartKeys?.map((key, index) => {
          return (
            <Line type="monotone" dataKey={key} stroke={randomColors[index]} />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
