import { Stack, Switch, Typography } from "@mui/material";
import { AutoCompleteWithTags } from "../molecules";

const AdvanceTagging = ({
  state,
  segment,
  handleTagsChange,
  isImageModal,
  formFieldStyles,
  labelStyles,
  handleSwitch,
}) => {
  const value = isImageModal ? state : state?.[segment];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        width: "100%",
      }}
    >
      <AutoCompleteWithTags
        label="Tags"
        placeholder="Ex: Eyewear, Cool, Sky"
        values={value?.tags}
        onTagUpdate={(tags) => handleTagsChange({ name: "tags", value: tags })}
      />

      <AutoCompleteWithTags
        label="Resolution"
        placeholder="Ex: High Resolution, Professional, User-Generated Content, Low Resolution"
        values={value?.resolution}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "resolution", value: tags })
        }
      />

      <AutoCompleteWithTags
        label="Color Palette"
        values={value?.colorPalette}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "colorPalette", value: tags })
        }
      />

      <AutoCompleteWithTags
        label="Visual Themes"
        values={value?.visualThemes}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "visualThemes", value: tags })
        }
      />

      <AutoCompleteWithTags
        label="Objects"
        placeholder="Ex: Car, Bike, etc"
        values={value?.objects}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "objects", value: tags })
        }
      />

      <AutoCompleteWithTags
        label="People Demographics"
        values={value?.peopleDemographics}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({
            name: "peopleDemographics",
            value: tags,
          })
        }
      />

      <AutoCompleteWithTags
        label="Text Amount"
        values={value?.textAmount}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "textAmount", value: tags })
        }
        // multiple={false}
      />

      <AutoCompleteWithTags
        label="Text Overlay Style"
        values={value?.textOverlayStyle}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "textOverlayStyle", value: tags })
        }
        // multiple={false}
      />

      <Stack direction="row" spacing={4} gap={12} alignItems="center">
        <div style={formFieldStyles}>
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontSize: "1rem" }}>Logo Presence</Typography>
            <Switch
              name="logoPresence"
              value={value?.logoPresence}
              checked={value?.logoPresence}
              onChange={handleSwitch}
            />
            {/* <Typography>Yes</Typography> */}
          </Stack>
        </div>
      </Stack>

      <AutoCompleteWithTags
        label="Logo Placement"
        values={value?.logoPlacement}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "logoPlacement", value: tags })
        }
        // multiple={false}
      />

      <Stack direction="row" spacing={4} gap={12} alignItems="center">
        <div style={formFieldStyles}>
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontSize: "1rem" }}>Product Presence</Typography>
            <Switch
              name="productPresence"
              value={value?.productPresence}
              checked={value?.productPresence}
              onChange={handleSwitch}
            />
          </Stack>
        </div>
      </Stack>

      <AutoCompleteWithTags
        label="Product Name"
        values={value?.productName}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "productName", value: tags })
        }
      />

      <AutoCompleteWithTags
        label="Brand Integration"
        values={value?.brandIntegration}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "brandIntegration", value: tags })
        }
        placeholder="Ex: Foreground, Background, Subtle, Overt"
        // multiple={false}
      />

      <AutoCompleteWithTags
        label="Background Elements"
        placeholder="Ex: Urban Scene, Nature, Plain Background, Animated"
        values={value?.backgroundElements}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({
            name: "backgroundElements",
            value: tags,
          })
        }
      />

      <AutoCompleteWithTags
        label="VFX"
        values={value?.vfx}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) => handleTagsChange({ name: "vfx", value: tags })}
        placeholder="Ex: Animation, CGI, Slow Motion, None"
      />

      <AutoCompleteWithTags
        label="Layout and Composition"
        values={value?.layoutAndComposition}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "layoutAndComposition", value: tags })
        }
        placeholder="Ex: Single Subject Centered, Asymmetrical, Grid, Layered"
        // multiple={false}
      />

      <AutoCompleteWithTags
        label="Shot Type"
        values={value?.shotType}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "shotType", value: tags })
        }
      />

      <AutoCompleteWithTags
        label="Editing Style"
        values={value?.editingStyle}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "editingStyle", value: tags })
        }
      />

      <AutoCompleteWithTags
        label="Camera Movement"
        values={value?.cameraMovement}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "cameraMovement", value: tags })
        }
      />

      <AutoCompleteWithTags
        label="Lighting Style"
        values={value?.lightingStyle}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "lightingStyle", value: tags })
        }
        // multiple={false}
      />
      <Stack direction="row" spacing={4} gap={12} alignItems="center">
        <div style={formFieldStyles}>
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontSize: "1rem" }}>Interactivity</Typography>
            <Switch
              name="interactivity"
              value={value?.interactivity}
              checked={value?.interactivity}
              onChange={handleSwitch}
            />
          </Stack>
        </div>
      </Stack>

      <AutoCompleteWithTags
        label="Platform-Specific Features"
        values={value?.platformSpecificFeatures}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({
            name: "platformSpecificFeatures",
            value: tags,
          })
        }
        // multiple={false}
      />
      <AutoCompleteWithTags
        label="Use of Symbols and Icons"
        values={value?.useOfSymbolsAndIcons}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({
            name: "useOfSymbolsAndIcons",
            value: tags,
          })
        }
        // multiple={false}
      />
      <AutoCompleteWithTags
        label="Depth of Field"
        values={value?.depthOfField}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({
            name: "depthOfField",
            value: tags,
          })
        }
        // multiple={false}
      />

      <AutoCompleteWithTags
        label="Narrative Style"
        placeholder="Ex: Problem-Solution"
        values={value?.narrativeStyle}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "narrativeStyle", value: tags })
        }
        // multiple={false}
      />

      <AutoCompleteWithTags
        label="Emotional Tone"
        placeholder="Ex: Inspirational"
        values={value?.emotionalTone}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "emotionalTone", value: tags })
        }
        // multiple={false}
      />

      <Stack direction="row" spacing={4} gap={12} alignItems="center">
        <div style={formFieldStyles}>
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontSize: "1rem" }}>
              Call to Action (CTA) presence
            </Typography>
            <Switch
              name="isCtaAvailable"
              value={value?.isCtaAvailable}
              checked={value?.isCtaAvailable}
              onChange={handleSwitch}
            />
          </Stack>
        </div>
      </Stack>

      <AutoCompleteWithTags
        label="Call to Action (CTA)"
        values={value?.cta}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) => handleTagsChange({ name: "cta", value: tags })}
        // multiple={false}
      />

      <AutoCompleteWithTags
        label="Call to Action (CTA) Style"
        placeholder="Ex: Direct CTA Button"
        values={value?.ctaStyle}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "ctaStyle", value: tags })
        }
        // multiple={false}
      />

      <Stack direction="row" spacing={4} gap={12} alignItems="center">
        <div style={formFieldStyles}>
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontSize: "1rem" }}>
              Offer/Promotion presence
            </Typography>
            <Switch
              name="OfferPromotionPresence"
              value={value?.OfferPromotionPresence}
              checked={value?.OfferPromotionPresence}
              onChange={handleSwitch}
            />
          </Stack>
        </div>
      </Stack>
      <AutoCompleteWithTags
        label=" Offer/Promotion"
        values={value?.offerPromotion}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "offerPromotion", value: tags })
        }
        // multiple={false}
      />

      <Stack direction="row" spacing={4} gap={12} alignItems="center">
        <div style={formFieldStyles}>
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontSize: "1rem" }}>Hook presence</Typography>
            <Switch
              name="hookPresence"
              value={value?.hookPresence}
              checked={value?.hookPresence}
              onChange={handleSwitch}
            />
          </Stack>
        </div>
      </Stack>

      <AutoCompleteWithTags
        label="Hook"
        placeholder="Ex: Question"
        values={value?.hook}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) => handleTagsChange({ name: "hook", value: tags })}
        // multiple={false}
      />

      <AutoCompleteWithTags
        label="Target Audience Traits"
        values={value?.targetAudienceTraits}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({
            name: "targetAudienceTraits",
            value: tags,
          })
        }
      />

      <AutoCompleteWithTags
        label="Cultural Relevance"
        placeholder="Ex: Question"
        values={value?.culturalRelevance}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({ name: "culturalRelevance", value: tags })
        }
        // multiple={false}
      />

      <AutoCompleteWithTags
        label="Contextual Tags by business"
        placeholder="Ex: Question"
        values={value?.contextualTagsByBusiness}
        handleTagsChange={handleTagsChange}
        onTagUpdate={(tags) =>
          handleTagsChange({
            name: "contextualTagsByBusiness",
            value: tags,
          })
        }
      />
    </div>
  );
};

export default AdvanceTagging;
