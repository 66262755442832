import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetOrgAppStatus,
  useGetOrganisationList,
  useGetUserAppStatus,
  useHeartbeat,
} from "apis/common";
import {
  setAllOrganisations,
  setOrganisationHeartbeat,
} from "redux/slices/organisation.slice";

export default function useAppState() {
  // const { isUserAuthenticated, isOrganisationAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state?.appState);
  const { auth } = useSelector((state) => state);
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const isUserAuthenticated = !!auth?.userData?.tokens?.user?.token;
  const isOrganisationAuthenticated = !!selectedOrganisation?.token;
  const userToken = auth?.userData?.tokens?.user?.token;
  const orgToken = selectedOrganisation?.token;

  const organisationOnBoarded =
    appState?.isPlatformAddedToOrganization &&
    appState?.isOrganizationImagesAiGroupingDone &&
    appState?.isOrganizationVideosAiGroupingDone &&
    appState?.isOrganizationImagesManualGroupingDone &&
    appState?.isOrganizationVideosManualGroupingDone &&
    appState?.isOrganizationTextsGroupingDone &&
    appState?.isOrganizationCustomAudiencesGroupingDone &&
    appState?.isOrganizationCustomAudiencesTaggingDone &&
    appState?.isDataPullCompleted;

  const { refetch: refetchUserAppStatus } = useGetUserAppStatus(false);
  const { refetch: refetchOrgAppStatus } = useGetOrgAppStatus(false);
  const { data: heartbeat } = useHeartbeat(selectedOrganisation?._id);
  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data: organisationList,
    refetch: refetchOrgList,
  } = useGetOrganisationList(isUserAuthenticated);

  const updateAppState = useCallback(() => {
    if (
      isUserAuthenticated &&
      !!userToken &&
      isOrganisationAuthenticated &&
      !!orgToken
    ) {
      refetchOrgAppStatus(true);
    } else if (isUserAuthenticated && !!userToken) {
      refetchUserAppStatus(true);
    } else if (isOrganisationAuthenticated && !!orgToken) {
      refetchOrgAppStatus(true);
    }
  }, [
    isOrganisationAuthenticated,
    isUserAuthenticated,
    orgToken,
    refetchOrgAppStatus,
    refetchUserAppStatus,
    userToken,
  ]);

  useEffect(() => {
    try {
      if (isUserAuthenticated && auth?.userData?.user?._id) {
        window.userGuiding.identify(auth?.userData?.user?._id, {
          visitor: {
            id: auth?.userData?.user?._id,
            email: auth?.userData?.user?.email,
            firstName: auth?.userData?.user?.firstName,
            lastName: auth?.userData?.user?.lastName,
          },

          account: {
            id: selectedOrganisation?._id,
            accountName: selectedOrganisation?.name,
            payingStatus: selectedOrganisation?.isSubscriptionActive,
            accountCreationDate: selectedOrganisation?.createdAt,
          },
        });
      }
    } catch (err) {
      console.log("userGuiding error", err);
    }
  }, []);

  useEffect(() => {
    if (heartbeat) {
      const heartbeatRawData = localStorage.getItem(heartbeat);
      if (!heartbeatRawData) {
        localStorage.setItem(heartbeat, JSON.stringify(heartbeat));
      } else {
        const heartbeatData = JSON.parse(heartbeatRawData);

        if (heartbeatData.appFrontendVersion !== heartbeat.appFrontendVersion) {
          let temp = { ...heartbeat, isUpdateAvailable: heartbeat };
          // window.location.reload(true);
          dispatch(setOrganisationHeartbeat(temp));
          return;
        } else {
          localStorage.setItem(heartbeat, JSON.stringify(heartbeat));
        }
      }

      dispatch(setOrganisationHeartbeat(heartbeat));
    }
  }, [dispatch, heartbeat]);

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    } else if (!hasNextPage && !isFetchingNextPage) {
      const res = organisationList?.pages?.reduce(
        (acc, curr) => [...acc, ...curr?.docs],
        []
      );

      dispatch(setAllOrganisations(res));
    }
  }, [
    dispatch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    organisationList?.pages,
  ]);

  useEffect(() => {
    if (isUserAuthenticated) {
      refetchOrgList();
    }
  }, []);

  return {
    updateAppState,
    appState,
    organisationOnBoarded,
  };
}
