import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
const { default: useAxios } = require("hooks/useAxios");
const { TYPEAHEAD } = require("./apiEndPoints");

const useTypeahead = (q) => {
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["typeahead", q];

  return useQuery(
    queryKey,
    async () => {
      const response = await axiosOrgInstance.get(TYPEAHEAD, {
        params: {
          q,
          organizationId: selectedOrganisation?._id,
        },
      });
      return response;
    },
    {
      enabled: !!selectedOrganisation?._id && !!q,
      refetchOnWindowFocus: false,
    }
  );
};

export { useTypeahead };
