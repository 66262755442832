import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";

export default function AutoCompleteWithChips(props) {
  const {
    type = "text",
    inputLabel = "Input Label",
    placeholder = "",
    values = [],
    onTagUpdate,
    disabled,
    optionsList = [],
    limit = Infinity,
    sx,
    maxVisibleTags = -1,
    multiple = true,
    showTags = true,
  } = props;

  const customInputTagHandler = (_, value) => {
    if (!multiple) {
      onTagUpdate && onTagUpdate([value[value?.length - 1]]);
    } else {
      if (value?.length === 0) {
        return toast("Minimum one filter is required");
      }
      onTagUpdate && onTagUpdate(value);
    }
  };
  const filteredOptions = optionsList.filter((option) => {
    return !values?.some((value) => value?.value === option?.value);
  });

  return (
    <Autocomplete
      multiple
      id="multiple-limit-tags"
      options={filteredOptions}
      getOptionLabel={(option) => {
        return option?.label || "";
      }}
      disabled={disabled}
      value={values}
      filterSelectedOptions
      renderInput={(params) => {
        return (
          <TextField
            margin="dense"
            sx={{
              color: "#A1B0CC",
              flexDirection: "row",
              gap: "4px",
              backgroundColor: "#FFF",
              "& label#multiple-limit-tags-label": {
                top: "-10px",
              },
            }}
            placeholder={!values?.length ? placeholder : ""}
            {...params}
            type={type}
            label={inputLabel}
          />
        );
      }}
      renderTags={showTags ? undefined : () => null}
      onChange={(event, value) => {
        if (limit !== Infinity) {
          if (!(value?.length > limit || !value?.length)) {
            customInputTagHandler(event, value);
          } else {
            toast(`Sorry!!You can select upto ${limit} only`);
          }
        } else {
          customInputTagHandler(event, value);
        }
      }}
      sx={{
        minWidth: "200px",
        "& .MuiAutocomplete-inputRoot": {
          padding: "2px 8px",
          height: "36px",
          "& .MuiAutocomplete-input": {
            padding: "0px",
            height: "100%",
          },
          "& .MuiAutocomplete-tag": {
            background: "#D4E4FA",
            borderRadius: "4px",
            color: "#1C1E20",
            fontSize: "14px",
            fontWeight: "300",
            padding: "0",
            marginRight: "2px",
            height: "24px",
            "& .MuiChip-label": {
              padding: "8px",
            },
            "& .MuiChip-deleteIcon": {
              height: "18px",
              width: "18px",
              marginLeft: "1px",
            },
          },
        },
        ...sx,
      }}
      blurOnSelect
      limitTags={maxVisibleTags}
    />
  );
}
