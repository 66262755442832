import * as React from "react";

const MetaIcon = (props) => (
  <svg
    width={32}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.456 14.017c0 1.222.269 2.16.62 2.727.459.744 1.144 1.059 1.843 1.059.901 0 1.726-.224 3.315-2.422 1.273-1.761 2.773-4.234 3.783-5.784l1.709-2.626c1.187-1.824 2.562-3.852 4.137-5.226C20.15.623 21.537 0 22.933 0c2.346 0 4.579 1.359 6.288 3.907C31.091 6.698 32 10.213 32 13.841c0 2.157-.425 3.741-1.148 4.993-.7 1.211-2.061 2.42-4.353 2.42v-3.451c1.962 0 2.452-1.803 2.452-3.866 0-2.94-.686-6.203-2.196-8.534-1.071-1.654-2.46-2.664-3.988-2.664-1.652 0-2.982 1.246-4.477 3.468-.794 1.181-1.61 2.62-2.526 4.243l-1.008 1.787c-2.025 3.59-2.538 4.408-3.551 5.758-1.775 2.364-3.29 3.26-5.286 3.26-2.367 0-3.864-1.025-4.79-2.57C.371 17.427 0 15.775 0 13.893l3.456.124Z"
      fill="#0081FB"
    />
    <path
      d="M2.725 4.15C4.31 1.709 6.597 0 9.22 0c1.519 0 3.029.45 4.606 1.737 1.724 1.408 3.563 3.726 5.856 7.546l.823 1.371c1.985 3.307 3.114 5.009 3.776 5.811.85 1.03 1.445 1.338 2.218 1.338 1.962 0 2.452-1.803 2.452-3.866l3.05-.096c0 2.157-.426 3.741-1.15 4.993-.698 1.211-2.06 2.42-4.352 2.42-1.424 0-2.686-.309-4.081-1.625-1.073-1.01-2.327-2.806-3.292-4.42l-2.87-4.793c-1.44-2.406-2.76-4.2-3.525-5.012-.823-.874-1.88-1.929-3.567-1.929-1.365 0-2.525.958-3.495 2.424L2.725 4.151Z"
      fill="url(#a)"
    />
    <path
      d="M9.164 3.475c-1.365 0-2.525.958-3.495 2.424-1.372 2.071-2.213 5.156-2.213 8.118 0 1.222.269 2.16.62 2.727l-2.948 1.941C.372 17.427 0 15.775 0 13.893c0-3.421.94-6.988 2.725-9.742C4.31 1.708 6.597 0 9.22 0l-.056 3.475Z"
      fill="url(#b)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={6.788}
        y1={13.02}
        x2={28.822}
        y2={14.133}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0064E1" />
        <stop offset={0.4} stopColor="#0064E1" />
        <stop offset={0.83} stopColor="#0073EE" />
        <stop offset={1} stopColor="#0082FB" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={5.008}
        y1={15.468}
        x2={5.008}
        y2={7.345}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0082FB" />
        <stop offset={1} stopColor="#0064E0" />
      </linearGradient>
    </defs>
  </svg>
);

export default MetaIcon;
