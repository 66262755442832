import AudienceTaggingScreen from "./Audience/AudienceTaggingScreen";
import ManualTaggedAudienceScreen from "./Audience/ManualTaggedAudienceScreen";
import ImageTaggingScreen from "./Images/ImageTaggingScreen";
import TextTaggingScreen from "./Text/TextTaggingScreen";
import ManuallyTaggedTextScreen from "./Text/ManuallyTaggedTextScreen";
import VideoTaggingScreen from "./Videos/VideoTaggingScreen";
import TaggingGroupContent from "./TaggingGroupContent";

const TaggingScreens = {
  AudienceTaggingScreen,
  ManualTaggedAudienceScreen,
  ImageTaggingScreen,
  TextTaggingScreen,
  ManuallyTaggedTextScreen,
  VideoTaggingScreen,
  TaggingGroupContent,
};

export default TaggingScreens;
