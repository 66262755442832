import React, { useState } from "react";
import {
  Button,
  Dialog,
  useMediaQuery,
  Typography,
  Stack,
  Chip,
  Switch,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "../../../redux/slices/modal.slices";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { InputWithLabel } from "components/common/molecules";

export default function SaveReportModal({
  selectedFilters,
  selectedMetrics,
  selectedDate,
  dateFilterOptions,
  keywords,
  aggregateLogic,
  onClick,
  onChange,
  isReportPublic,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [reportName, setReportName] = useState();
  const [reportDescription, setReportDecription] = useState();

  function handleClose() {
    dispatch(setModal(modalInitState));
  }

  let dateType = dateFilterOptions?.filter((item) => {
    return `${item.value}` === `${selectedDate.selectionType}`;
  })?.[0]?.label;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={modal?.open && modal?.type === MODALTYPES.SAVE_REPORT_MODAL}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Stack direction="column" sx={{ padding: "16px" }} spacing={3}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: "50%",
              width: "64px",
              height: "64px",
              backgroundColor: "#7167FB20",
            }}
          >
            <SaveAltIcon sx={{ fontSize: "32px" }} />
          </Button>
        </Stack>
        <Stack direction="column" spacing={2} alignItems="flex-start">
          <InputWithLabel
            label="Save Report"
            placeholder="Please Enter Name"
            onChange={(e) => {
              setReportName(e.target.value);
            }}
          />
          <InputWithLabel
            label="Brief description about the report"
            placeholder="Enter Report Decription"
            onChange={(e) => {
              setReportName(e.target.value);
            }}
          />
          <Stack direction="column" spacing={2}>
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "20px",
                textTransform: "capitalize",
              }}
            >
              Do you want to public this ?
            </Typography>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography>No</Typography>
              <Switch checked={isReportPublic} onChange={onChange} />
              <Typography>Yes</Typography>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={3}>
            <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
              Details
            </Typography>
            <Stack direction="column" spacing={2}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={3}
                sx={{
                  background: "#F9F9F9",
                  borderRadius: "10px",
                  padding: "12px 8px",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                  Selected KPI’S
                </Typography>
                <Stack direction="row" spacing={1}>
                  {selectedMetrics.map((metric) => {
                    return <Chip label={metric.label} variant="outlined" />;
                  })}
                </Stack>
              </Stack>
              {keywords?.length > 0 && (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  sx={{
                    background: "#F9F9F9",
                    borderRadius: "10px",
                    padding: "12px 8px",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    Selected Keywords
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    {keywords.map((metric) => {
                      return <Chip label={metric} variant="outlined" />;
                    })}
                  </Stack>
                </Stack>
              )}
              {!!aggregateLogic && (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  sx={{
                    background: "#F9F9F9",
                    borderRadius: "10px",
                    padding: "12px 8px",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    Selected Aggregation Logic
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Chip label={aggregateLogic} variant="outlined" />;
                  </Stack>
                </Stack>
              )}
              <Stack
                direction="row"
                alignItems="center"
                spacing={3}
                sx={{
                  background: "#F9F9F9",
                  borderRadius: "10px",
                  padding: "12px 8px",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                  Selected Date range:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#1C1E20B2",
                  }}
                >
                  {dateType}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                spacing={1}
                sx={{
                  background: "#F9F9F9",
                  borderRadius: "10px",
                  padding: "12px 8px",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                  Selected Filters:
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  flexWrap="wrap"
                  sx={{ rowGap: "8px" }}
                  justifyContent="flex-start"
                >
                  {Object.entries(selectedFilters).map(([key, value]) => {
                    const { label, options } = value;
                    return (
                      <Chip
                        size="string"
                        sx={{
                          height: "unset",
                          ".MuiChip-label": {
                            whiteSpace: "initial",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                          },
                        }}
                        label={
                          <Stack
                            direction="row"
                            spacing={1 / 2}
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                lineHeight: "15px",
                                fontWeight: "500",
                              }}
                            >
                              {label}:
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "10px",
                                lineHeight: "15px",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {options
                                .filter((option) => {
                                  return option.default;
                                })
                                .map((option) => {
                                  return option.label;
                                })
                                .join(", ")}
                            </Typography>
                          </Stack>
                        }
                        variant="outlined"
                      />
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="center">
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            disabled={!reportName || !reportDescription}
            onClick={() => {
              onClick(reportName);
              handleClose();
            }}
          >
            Save Report
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
