import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAxios from "../../../../../../hooks/useAxios";
import { API_ROUTES } from "../../../../../../routes";

export default function useBrandLogos() {
  const [brandLogos, setBrandLogos] = useState([]);
  const { axiosOrgInstance } = useAxios();

  function getBrandLogos() {
    axiosOrgInstance.get(API_ROUTES.BRAND_KIT.BRAND_LOGO.GET).then((res) => {
      setBrandLogos(res.docs);
    });
  }

  function addBrandLogo(files) {
    const formData = new FormData();
    formData.append("brandLogoFile", files[0]);
    axiosOrgInstance
      .post(API_ROUTES.BRAND_KIT.BRAND_LOGO.CREATE, formData)
      .then((res) => {
        toast.success("Brand Logo Added Successfully");
        getBrandLogos();
      });
  }

  function deleteBrandLogo(id) {
    axiosOrgInstance
      .delete(
        API_ROUTES.BRAND_KIT.BRAND_LOGO.DELETE?.replace(":brandLogoId", id)
      )
      .then((res) => {
        toast.success("Brand Logo Deleted Successfully");
        getBrandLogos();
      });
  }

  useEffect(() => {
    getBrandLogos();
  }, []);

  return {
    brandLogos,
    deleteBrandLogo,
    addBrandLogo,
  };
}
