import React from "react";
import { Typography } from "@mui/material";

import styles from "./styles.module.scss";
import metaLogo from "./metaLogo.svg";
import useAddAccounts from "./useAdAccounts";
import { PublicContainer } from "components/layouts";
import { InputWithLabel } from "components/common/molecules";
import { Button } from "@/components/ui/button";

const Capsule = ({ name, id, handler, selected }) => {
  return (
    <div
      className={`${styles.capsule} ${selected ? styles.selected : ""}`}
      onClick={() => handler(id)}
    >
      <Typography variant="h4" sx={{ fontWeight: "700" }}>
        {name}
      </Typography>

      <Typography
        variant="h4"
        sx={{
          color: "rgba(28, 30, 32, 0.5)",
        }}
      >
        Account #: {id}
      </Typography>
    </div>
  );
};

const AddAdAccount = () => {
  const {
    state,
    handleNext,
    handleSelect,
    getSelectedAccounts,
    adAccount,
    FilterAdAccounts,
  } = useAddAccounts();
  const bodyStyles = { alignItems: "flex-start", justifyContent: "flex-start" };

  return (
    <PublicContainer bodyStyles={bodyStyles}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <img src={metaLogo} alt="" />

        <Typography variant="h2" sx={{ fontWeight: "500", marginTop: "15px" }}>
          Select multiple Ad Accounts that you want to keep as a part of the
          organisation.
        </Typography>

        <div
          style={{
            display: "flex",
            gap: "15px",
            marginTop: "20px",
            marginBottom: "40px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Typography
              variant="h4"
              sx={{ fontWeight: "400", color: "#1C1E20", marginTop: "16px" }}
            >
              Ad ID's connected with the Meta Account.
            </Typography>

            <InputWithLabel
              label=""
              name="ad-account"
              placeholder="Search Ad Account IDs"
              onChange={FilterAdAccounts}
            />
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "left",
            justifyContent: "left",
            marginTop: "30px",
          }}
        >
          <Button
            variant="outline"
            color="primary"
            sx={{
              textTransform: "none",
            }}
            onClick={handleNext}
            disabled={
              getSelectedAccounts()?.length === 0 && !!adAccount?.accessToken
            }
          >
            Next
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "1180px",
            gap: "20px",
            overflow: "auto",
          }}
        >
          {state?.map(({ name, accountId, selected, visible }) =>
            visible ? (
              <Capsule
                key={accountId}
                name={name}
                id={accountId}
                handler={handleSelect}
                selected={selected}
              />
            ) : null
          )}
        </div>
      </div>
    </PublicContainer>
  );
};

export default AddAdAccount;
