import { Stack } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { DatePicker } from "../../atoms";
import moment from "moment";

export default function DateRangePicker(props) {
  const { onChange, onStartChange, onEndChange, value } = props;
  const [startDate, setStartDate] = useState(value?.dateStart);
  const [endDate, setEndDate] = useState(value?.dateStop);
  const [minDateForEndDate, setMinDateForEndDate] = useState();

  function getNextDate(date) {
    const nextDate = dayjs(date).add(1, "day").toDate();
    return nextDate;
  }

  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: "8px",
      }}
    >
      <DatePicker
        onChange={(value) => {
          setStartDate(value);
          onStartChange && onStartChange(value);
          setMinDateForEndDate(getNextDate(value));
          setEndDate(() => moment().format("YYYY-MM-DD"));
          onChange && getNextDate(value);
        }}
        maxDate={moment().format("YYYY-MM-DD")}
        minDate={null}
        value={startDate}
      />
      <DatePicker
        onChange={(value) => {
          onEndChange && onEndChange(value);
          setEndDate(value);
        }}
        maxDate={moment().format("YYYY-MM-DD")}
        minDate={minDateForEndDate}
        value={endDate}
      />
    </Stack>
  );
}
