import { useMutation, useQuery } from "@tanstack/react-query";
import { PRESETS } from "./apiEndPoints";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { modalInitState, setModal } from "redux/slices/modal.slices";
import { useGetPresets } from "./common";
const { default: useAxios } = require("hooks/useAxios");

const useGetSpecificPresets = (id) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["specificPresets", id];

  return useQuery(
    queryKey,
    () =>
      !!id &&
      axiosOrgInstance
        .get(PRESETS.GET_SPECIFIC_PRESET.replace(":id", id))
        .then((res) => {
          // const newKpis = res[0]?.kpis?.map((kpi) => kpi?.value);
          // const new_payload = {
          //   ...selectedMetrices,
          //   value: [...newKpis],
          // };
          // dispatch(setSelectedMetrices(new_payload));
          return res;
        }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
};

const useCreatePreset = () => {
  const { axiosOrgInstance } = useAxios();
  const { refetch: getPresets } = useGetPresets(false);
  const dispatch = useDispatch();
  return useMutation(
    (payload) => {
      return axiosOrgInstance.post(PRESETS.CREATE_PRESET, payload);
    },
    {
      onSuccess: () => {
        dispatch(setModal(modalInitState));
        getPresets();
      },
    }
  );
};

const useUpdatePreset = () => {
  const { axiosOrgInstance } = useAxios();
  const { refetch: getPresets } = useGetPresets(false);
  const insightsFilterState = useSelector((state) => state.insightFilters);
  const selectedPreset = insightsFilterState?.preset;
  const dispatch = useDispatch();
  const { refetch: fetchSpecificPreset } =
    useGetSpecificPresets(selectedPreset);
  return useMutation(
    (payload) => {
      if (!selectedPreset) return;
      return axiosOrgInstance.patch(
        PRESETS.UPDATE_PRESET.replace(":id", selectedPreset),
        payload
      );
    },
    {
      onSuccess: () => {
        dispatch(setModal(modalInitState));
        fetchSpecificPreset(selectedPreset);
        getPresets();
      },
    }
  );
};

export { useGetSpecificPresets, useCreatePreset, useUpdatePreset };
