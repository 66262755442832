import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { ContentContainer } from "../../../../components/layouts";
import {
  BasicActionsModal,
  ManualTextTaggingModal,
  TextTaggingModal,
} from "../../../../components/modals";
import useTextTagging from "../useTextTagging";
import SyncIcon from "@mui/icons-material/Sync";
import { MODALTYPES } from "../../../../redux/slices/modal.slices";

export default function AITagged() {
  const {
    totalDocs,
    initGroup,
    startTagging,
    groupsList,
    handleSkipUpdate,
    addSysGeneratedTagsModalPrimaryAction,
  } = useTextTagging(false);
  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flexStart"
        sx={{ padding: "20px 0" }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            sx={{
              minWidth: "max-content",
            }}
          >
            Total Text: {totalDocs}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            style={{
              color: "rgba(28, 30, 32, 0.5)",
            }}
            onClick={initGroup}
          >
            <Typography>Refresh</Typography> <SyncIcon />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="outlined" onClick={handleSkipUpdate}>
            Skip Tagging
          </Button>
        </Stack>
      </Stack>

      <ContentContainer containerStyles={{ maxHeight: "70vh" }}>
        <Stack
          direction="column"
          spacing={4}
          sx={{ paddingRight: "33px", width: "100%" }}
        >
          {groupsList.map((text) => {
            return (
              <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                spacing={1}
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#F2F3F5",
                  padding: "16px",
                }}
                key={text?._id}
              >
                <Stack gridColumn="span 9" spacing={1}>
                  <Typography
                    variant="body2"
                    sx={{ color: "rgba(28, 30, 32, 0.5);" }}
                  >
                    {text?.groupName}
                  </Typography>
                </Stack>
                <Stack
                  gridColumn="span 3"
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button variant="outlined" onClick={() => startTagging(text)}>
                    Start Tagging
                  </Button>
                </Stack>
              </Box>
            );
          })}
        </Stack>
      </ContentContainer>
      <BasicActionsModal
        modalType={MODALTYPES.BASIC_ACTION.ADD_SYS_GENERATED_TAGS}
        primaryAction={addSysGeneratedTagsModalPrimaryAction}
      />
      <TextTaggingModal initGroup={initGroup} />
      <ManualTextTaggingModal initGroup={initGroup} />
    </div>
  );
}
