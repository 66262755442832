import React from "react";
import { Stack } from "@mui/material";
import ReactPlayer from "react-player";

const PredictionTaggingCard = ({ thumbnail, type, TypePill, EditTagsBtn }) => {
  console.log("Props thumbnail", thumbnail);
  return (
    <Stack
      sx={{
        height: "200px",
        minWidth: "250px",
        maxWidth: "250px",
        minHeight: "180px",
        maxHeight: "180px",
        position: "relative",
        overflow: "hidden",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {type === "IMAGE" ? (
        <img
          src={thumbnail}
          alt="insight card thumbnail"
          style={{ width: "250px", height: "200px", objectFit: "cover" }}
        />
      ) : (
        <ReactPlayer
          url={thumbnail}
          className="react-player"
          width="250px"
          controls={true}
          playing={false}
          height="200px"
        />
      )}

      {TypePill}
      {EditTagsBtn}
    </Stack>
  );
};

export default PredictionTaggingCard;
