import { ChromeReaderModeTwoTone } from "@mui/icons-material";
import { PrivateContainer } from "components/layouts";
import { ContactSupportModal } from "components/modals/ContactSupportModal/ContactSupportModal";
import useAppState from "hooks/useAppState";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { APP_ROUTES } from "routes";
import SEO from "utils/SEO";

import { ROLES } from "config/roles";
import useUserRole from "hooks/useUserRole";
import { BadgePlus, Headset, NotebookPen, UserPlus } from "lucide-react";
import Carousel from "react-grid-carousel";
import { getCalApi } from "@calcom/embed-react";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { updateAppState } = useAppState();
  const { userRole } = useUserRole();
  useEffect(() => {}, [userRole]);

  const navigate = useNavigate();
  const { selectedOrganisation } = useSelector((state) => state.organisation);

  const button = [
    {
      text: "Create new reports",
      subText: "Create reports and get insights",
      icon: <NotebookPen color="#84cc16" />,
      hidden: !selectedOrganisation?.name,
      onClick: () => {
        dispatch(
          setModal({
            open: true,
            type: MODALTYPES.CREATE_REPORTS,
            payload: {},
          })
        );
      },
    },
    {
      text: "Invite team members",
      subText: "Increase collaboration by adding new team members",
      icon: <UserPlus color="#06b6d4" />,
      hidden: !selectedOrganisation?.name,
      onClick: () => {
        dispatch(
          setModal({
            open: true,
            type: MODALTYPES.INVITE_USER_MODAL,
            payload: {},
          })
        );
        navigate(APP_ROUTES.SETTING, {
          state: {
            selectedTab: "manageUser",
          },
        });
      },
    },
    {
      text: "Book your 1:1 support call",
      subText: "Connect with our expert",
      icon: <Headset color="#f472b6" />,
      hidden: false,
      props: {
        "data-cal-namespace": "1-1-support-call-with-varun",
        "data-cal-link": "varun-sogani-storyteller/1-1-support-call-with-varun",
        "data-cal-config": '{"layout":"month_view"}',
      },
      onClick: () => {
        // dispatch(
        //   setModal({
        //     open: true,
        //     type: MODALTYPES.CONTACT_SUPPORT,
        //     payload: {},
        //   })
        // );
      },
    },
    {
      text: "Connect Ad Account",
      subText: "Connect your platforms",
      icon: <BadgePlus color="#aebaff" />,
      hidden: userRole === ROLES.U,
      onClick: () => navigate(APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT),
    },
  ];

  useEffect(() => {
    updateAppState();

    (async function () {
      const cal = await getCalApi({ namespace: "1-1-support-call-with-varun" });

      cal("ui", {
        styles: { branding: { brandColor: "#7167FB" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);

  const blogs = [
    {
      title: "Insights",
      description: "Storyteller insights management.",
      readTime: 5,
      image: "/blog-1.webp",
    },
    {
      title: "Prediction",
      description: "AI Powered recommendations.",
      readTime: 8,
      image: "/blog-2.webp",
    },
    {
      title: "Learn",
      description: "Leverage how storyteller works. ",
      readTime: 9,
      image: "/blog-3.webp",
    },
    {
      title: "Events",
      description: "Stay updated with storyteller.",
      readTime: 12,
      image: "/blog-4.webp",
    },
  ];

  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
      }}
      activePage={APP_ROUTES.DASHBOARD}
    >
      <SEO
        title="Dashboard"
        description="Storyteller is the only AI Powered insights and recommendation platform"
        name="Dashboard by Storyteller"
        type="Report"
      />
      <h1 className="text-xl font-semibold">Dashboard</h1>
      <div className="border-solid border-neutral-800">
        <div className="grid grid-cols-3 gap-2 mt-5">
          {button.map((b, index) =>
            b.hidden ? null : (
              <div key={index} onClick={b?.onClick} className="text-xs">
                <button
                  type="button"
                  className="text-gray-900 text-sm font-medium gap-x-2 w-[100%] h-[90%] bg-custom-gray-500 hover:bg-custom-gray-800 border border-custom-gray-800 hover:hover:border-custom-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-100 rounded-lg px-5 py-3.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2"
                  {...b?.props}
                >
                  <div className="m-2 p-2 rounded-full border border-custom-gray-800 bg-white">
                    {b.icon}
                  </div>
                  <div className="flex flex-col">
                    <p className="text-left">{b.text}</p>
                    <p className="text-left text-gray-500 text-xs">
                      {b.subText}
                    </p>
                  </div>
                </button>
              </div>
            )
          )}
        </div>
        <div className="mt-8">
          <div className="mt-5 flex items-center text-sm font-semibold">
            <h2 className="text-left text-lg mb-0">Learn Storyteller</h2>
            <span className="ml-2">
              <ChromeReaderModeTwoTone />
            </span>
          </div>
          <Carousel
            cols={4}
            rows={1}
            gap={4}
            loop
            // className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 bg-[#F8F8F8] rounded-lg mt-5 p-2 gap-2"
            containerStyle={{
              background: "#F8F8F8",
              borderRadius: "0.5rem",
              padding: "0.5rem",
              paddingTop: "0.5rem",
              gap: "0.5rem",
              marginTop: "1.25rem",
            }}
          >
            {blogs.map((blog, index) => (
              <Carousel.Item
                key={index}
                containerStyle={{
                  padding: "0.5rem",
                  borderRadius: "0.5rem",
                  cursor: "pointer",
                }}
              >
                <div className="p-2 rounded-lg hover:bg-custom-gray-900 hover:cursor-pointer">
                  <img
                    src={blog.image}
                    loading="lazy"
                    alt="Blog"
                    className="w-full h-[70%] object-cover mb-2 rounded-md"
                  />
                  <p className="font-medium flex items-center gap-x-2 text-gray-950">
                    <span className="text-sm">{blog.title}</span>
                    <span class="bg-red-100 text-red-800 items-center text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300 flex justify-center">
                      Coming Soon
                    </span>
                  </p>
                  <p className="text-xs font-medium mt-1  text-gray-500 mb-1">
                    {blog.description}
                  </p>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
      <ContactSupportModal />
    </PrivateContainer>
  );
};

export default Dashboard;
