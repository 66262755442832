import React from "react";
import PublicContainer from "../../../../components/layouts/PublicContainer";
import { Typography } from "@mui/material";
import { gathering_data } from "../../../../assets/images";
import { useNavigate } from "react-router-dom";

export default function GatheringDataScreen() {
  const navigate = useNavigate();
  React.useEffect(() => {
    setTimeout(() => {
      navigate("/onboarding-groups");
    }, 3000);
  }, [navigate]);

  return (
    <PublicContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={gathering_data} alt="gatheringGif" />
        </div>

        <Typography
          variant="h1"
          sx={{ marginBottom: "10px", fontWeight: "700", textAlign: "center" }}
        >
          Gathering Data For Your Account
        </Typography>
        <Typography
          variant="h3"
          sx={{
            color: "#7f7f7f",
            fontWeight: "500",
            textAlign: "center",
            lineHeight: 1.8,
          }}
        >
          "Convincing AI not to turn evil.."
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></div>
      </div>
    </PublicContainer>
  );
}
