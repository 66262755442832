import React from "react";

import { Button, Typography, Stack, Chip } from "@mui/material";

import { PrivateContainer } from "components/layouts";
import { APP_ROUTES } from "routes";
import { TitleBar } from "components/common/molecules";
import {
  GenerationTextCard,
  GenerationContainer,
  GenerationCreativeTypeModal,
  GenerationPill,
} from "../../Components";
import useInsights from "screens/Insights/useInsights";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { useDispatch, useSelector } from "react-redux";

export default function RecommendationScreen() {
  const { filterState, onFilterUpdate } = useInsights();
  const dispatch = useDispatch();
  const { open, type } = useSelector((state) => state?.modal);

  const dataOne = [
    { id: 1, title: "Color", label: "Pink", color: "#000" },
    { id: 2, title: "Object", label: "Table", color: "#FF6CA2" },
    { id: 3, title: "Celebrities", label: "Oppenheimer", color: "#A1B5C0" },
    { id: 4, title: "Place", label: "Indore", color: "#6C9DFC" },
    { id: 5, title: "Extra Element", label: "Table Lamp", color: "#B877D5" },
  ];

  const dataTwo = [
    { id: 1, title: "Format", subTitle: "Photograph" },
    { id: 2, title: "Object", subTitle: "A face cream" },
    { id: 3, title: "Extra Element", subTitle: "Next to levender" },
    { id: 4, title: "Perspective", subTitle: "from above" },
    { id: 5, title: "Background", subTitle: "over a marble table" },
    { id: 6, title: "Booster", subTitle: "Extremely details" },
  ];

  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
        height: "auto",
        paddingBottom: "32px",
      }}
      activePage={APP_ROUTES.GENERATION.GENERATION_STEP_2}
    >
      <Stack direction="column" spacing={3} marginBottom={3}>
        <Stack direction="column" spacing={3}>
          <TitleBar
            title="Generation ✨"
            description="Create ad copy and headlines designed to boost conversions quickly."
          />
        </Stack>
        <Stack direction="column" spacing={3}>
          <GenerationContainer>
            <Stack direction="column" spacing={1} alignItems="flex-start">
              <GenerationPill label="Step 2 / 3" />
            </Stack>
            <Typography variant="h3" textTransform="capitalize">
              list of high-conversion prompting keywords
            </Typography>
            <GenerationContainer
              sx={{
                padding: "24px 16px",
              }}
            >
              <Stack direction="row" spacing={3} flexWrap="wrap">
                {dataOne.map((item) => {
                  return (
                    <GenerationContainer
                      key={item.id}
                      sx={{
                        padding: "12px",
                        border: "1px solid #A1B0CC",
                        minWidth: "160px",
                        maxWidth: "160px",
                        marginBottom: "0",
                      }}
                    >
                      <Stack
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#1C1E20",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <GenerationPill
                          label={item.label}
                          pillBg={item.color}
                          pillColor="#fff"
                          sx={{ fontSize: "14px", lineHeight: "20px" }}
                        />
                      </Stack>
                    </GenerationContainer>
                  );
                })}
              </Stack>
            </GenerationContainer>

            <Stack direction="column" spacing={1}>
              <Typography variant="h3">Write the prompt: </Typography>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <div
                  style={{
                    padding: "8px 16px",
                    fontSize: "16px",
                    lineHeight: "1.75",
                    display: "flex",
                    borderRadius: "24px",
                    border: "1px solid #EAECF0",
                    boxShadow: "0px 8px 12px 0px rgba(0, 0, 0, .1)",
                  }}
                >
                  A wide shot photograph of a red SUV car, in the forest, in
                  autumn, cloudy day, in movement, from the front, warm colors,
                  4k, very detailed
                </div>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "100px", textTransform: "capitalize" }}
                >
                  Generate
                </Button>
              </Stack>
            </Stack>
            <Stack direction="column" spacing={3}>
              <Stack direction="column" spacing={1}>
                <Typography variant="h3">📕 Prompt Guide: </Typography>
                <Typography sx={{ color: "#1C1E2070" }}>
                  Still confuse don’t worry we have a solution for you
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="flex-start">
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    backgroundColor: "#7167FB20",
                    padding: "10px",
                    color: "#7167FB",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>Ideal Prompt:</span>
                  <p>
                    Photograph of a face cream jar, a face cream jar next to
                    lavender, from above over a marble table extremely detailed.
                  </p>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                sx={{ paddingTop: "8px" }}
              >
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  spacing={3}
                  alignItems="center"
                >
                  {dataTwo.map((item) => {
                    return <GenerationTextCard key={item.id} {...item} />;
                  })}
                </Stack>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "100px", textTransform: "capitalize" }}
                  onClick={() =>
                    dispatch(
                      setModal({
                        type: MODALTYPES.GENERATION_CREATIVE_TYPE_MODAL,
                        open: true,
                      })
                    )
                  }
                >
                  Generate
                </Button>
              </Stack>
            </Stack>
          </GenerationContainer>
        </Stack>
        {open && type === MODALTYPES.GENERATION_CREATIVE_TYPE_MODAL && (
          <GenerationCreativeTypeModal />
        )}
      </Stack>
    </PrivateContainer>
  );
}
