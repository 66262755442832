import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { TableView } from "@mui/icons-material";
import EmptyData from "components/common/empty/EmptyData";
import MetaLoader from "components/common/loaders/MetaLoader";
import StorytellerLogoLoader from "components/common/loaders/StorytellerLogoLoader";
import ChangeVisualizationTab from "components/common/Modals/ChangeVisualizationTab";
import HeaderFeatures from "components/common/molecules/HeaderFeature/HeaderFeatures";
import NewDateRangePicker from "components/common/molecules/NewDateRangePicker/DateRangePicker";
import ShadCnFilters from "components/common/molecules/NewFilters/ShadCnFilters";
import ReportDetails from "components/common/molecules/ReportInformation/ReportDetails";
import { PrivateContainer, ResponsiveBox } from "components/layouts";
import MoveReportToFolderModal from "components/modals/MoveReportToFolderModal/MoveReportToFolder";
import PresetModal from "components/modals/PresetsModal/PresetModal";
import SaveInsightsReportModal from "components/modals/SaveInsightsReportModal/SaveInsightsReportModal";
import {
  SELECTED_METRIC_COLOR,
  kpiFiltersConditions,
  kpiFiltersReverse,
} from "config/statics";
import { toBlob } from "html-to-image";
import { pick } from "lodash";
import {
  BarChart3,
  ClipboardCopy,
  Ellipsis,
  Info,
  InspectionPanel,
  LineChart,
  Maximize2,
  Minimize2,
  Plus,
  RefreshCcwDot,
  X,
} from "lucide-react";
import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MODALTYPES } from "redux/slices/modal.slices";
import { APP_ROUTES } from "routes";
import {
  BarChartMultiYAxis,
  LineChartMultiYAxis,
} from "screens/Insights/Components";
import NewInsightsCardView from "screens/Insights/Components/Card/InsightsNewCard";
import ColorTable, {
  DarkTooltip,
} from "screens/Insights/Components/ColorTable/ColorTable";
import SEO from "utils/SEO";
import useCompareDates from "./useCompareDates";

const CompareWithDates = () => {
  const {
    isLoadingSpecificPreset,
    hideSidebar,
    reportData,
    disableFilters,
    specificInsightsId,
    isMakingShortUrl,
    isBookmarkSuccess,
    isUpdatingReport,
    insightsReport,
    insightsData,
    payload,
    date,
    filterAndOptions,
    adAccountIds,
    reportableMessage,
    filterSet,
    kpiFilters,
    additionalFilterSet,
    insightState,
    smartSuggestion,
    isSmartSuggestionLoading,
    audienceSegments,
    isShowGroups,
    visualisationTab,
    selectedMetrices,
    rowSelection,
    sortedData,
    isInsightLoading,
    colorFormat,
    preset,
    presetData,
    isMakingPreset,
    specificPresetData,
    tempKpis,
    isDrawerOpen,
    resolvedKPIs,
    isCreatingReport,
    loadingReport,
    confirmVisualizationModal,
    isSelectionValid,
    updateKPIs,
    setIsDrawerOpen,
    setTempKpis,
    handleColorFormatChange,
    makePreset,
    _handleApplyFilters,
    setSelectedKPIs,
    handlePresetSelect,
    setSortedData,
    setRowSelection,
    doesShowColor,
    setVisualisationTab,
    handleAdAccountId,
    onUpdateReportData,
    onFilterValueChange,
    deleteReport,
    handleBookmarkReport,
    handleRemoveBookmark,
    handleUpdateAndSave,
    handleUrlShortener,
    onOpenFilters,
    onFilterFilterChange,
    onFilterWhereChange,
    onUpdatePayload,
    handleSearchMotionFilters,
    handleUpdateSearchExtraFilter,
    applyPayload,
    onRemovePayload,
    onUpdateKpiFilters,
    handleAddMore,
    handleAddMoreGroup,
    handleWriteGroupName,
    onDateDaysChange,
    handleUpdateSelectionType,
    handleDeleteGroup,
    handleDuplicateGroup,
    onUpdateSelectedMetrices,
    updatePreset,
    moveReportToFolder,
    saveReport,
    handleAddDateInGroup,
    handleShowGroups,
    onAdditionalFilterWhereChange,
    onAdditionalFilterValueChange,
    onAdditionalFilterFilterChange,
    onFilterSetOpen,
    handleDeleteFromFilterSet,
    handleAdditionalFiltersAddMore,
    onAdditionalFilterRemovePayload,
    handleAdBreakdown,
    changeVisualizationTab,
    closeVisualizationTabSwitch,
    confirmVisualizationTabSwitch,
  } = useCompareDates();

  const [isPerformanceMetrics, setIsPerformanceMetrics] = useState(false);
  const { open, type } = useSelector((state) => state?.modal);
  const [initialProgress, setInitialProgress] = useState(0);

  const ref = useRef();
  const handleCopyToClipboard = useCallback(() => {
    if (ref?.current) {
      toBlob(ref?.current, { cacheBust: true }).then(async (blob) => {
        const item = { [blob.type]: blob };
        const clipboardItem = new ClipboardItem(item);
        await navigator.clipboard.write([clipboardItem]);
        toast("Data Copied To Clipboard.", {
          type: "success",
        });
      });
    }
  }, [ref?.current]);

  const metricsOptions = insightState?.metrics?.map((item) => ({
    label: item?.label,
    value: item?.kpi,
  }));

  function getGroupNames() {
    const groupNames = filterSet?.map((curr, idx) => {
      return curr?.groupName ? curr?.groupName : "untitled";
    });
    return groupNames?.join(",");
  }

  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
        maxHeight: "calc(100vh - 30px)",
      }}
      activePage={APP_ROUTES.INSIGHTS.COMPARE}
      hideSidebar={hideSidebar}
    >
      <SEO
        title="Compare"
        description="Creative compare reports powered by Storyteller"
        name="Compare by Storyteller"
        type="Compare"
      />
      {loadingReport && specificInsightsId ? (
        <StorytellerLogoLoader />
      ) : (
        <>
          <div className="w-full flex h-full">
            <div
              className={`${
                isDrawerOpen ? "w-[70%]" : "w-full"
              } overflow-auto px-5`}
            >
              {/* HEADER PART */}
              <div className="sticky top-0 z-[10] flex justify-between w-full mb-4 bg-white pb-2">
                <div className="w-1/3">
                  <p className="outline-none truncate">{reportData?.name}</p>
                </div>
                <HeaderFeatures
                  specificInsightsId={specificInsightsId}
                  disableFilters={disableFilters}
                  handleUpdateAndSave={handleUpdateAndSave}
                  isMakingShortUrl={isMakingShortUrl}
                  handleUrlShortener={handleUrlShortener}
                  hideSidebar={hideSidebar}
                  isBookmarkSuccess={isBookmarkSuccess}
                  handleRemoveBookmark={handleRemoveBookmark}
                  insightsData={insightsData}
                  deleteReport={deleteReport}
                  handleBookmarkReport={handleBookmarkReport}
                  isUpdatingReport={isUpdatingReport}
                  report={insightsReport}
                  setIsDrawerOpen={setIsDrawerOpen}
                  isDrawerOpen={isDrawerOpen}
                  onFilterValueChange={onFilterValueChange}
                  handleAdAccountId={handleAdAccountId}
                  payload={payload}
                  adAccountIds={adAccountIds}
                  filterAndOptions={
                    filterAndOptions
                      ? pick(filterAndOptions, ["adAccountIds"])
                      : {}
                  }
                />
              </div>

              <div className="mt-1 h-fit">
                {/* for the report title, description and comment section */}
                <ReportDetails
                  reportData={reportData}
                  reportableMessage={reportableMessage}
                  onUpdateReportData={onUpdateReportData}
                />

                {/* FILTERS SECTION */}

                <div className="flex flex-wrap gap-x-3 gap-y-3 items-center">
                  <div className="flex gap-x-3 items-center flex-wrap">
                    {isShowGroups && (
                      <div className="flex gap-x-2">
                        <p className="text-black text-sm flex items-center">
                          Define groups of ads to compare
                        </p>
                        <Button
                          variant="outline"
                          size="icon"
                          className="h-7 w-7 flex gap-x-3 items-center"
                          onClick={handleShowGroups}
                        >
                          <Minimize2 className="h-4 w-4 text-gray-500" />
                        </Button>
                      </div>
                    )}
                    {!isShowGroups && (
                      <Button
                        variant="outline"
                        className="flex gap-x-3 items-center"
                        onClick={handleShowGroups}
                      >
                        <div className="w-full flex gap-x-2 items-center h-7">
                          <div className="flex gap-x-1 items-center">
                            <p>Comparing {filterSet?.length} groups:</p>
                            <span className="text-gray-500">
                              {getGroupNames()}
                            </span>
                          </div>
                          <Maximize2 className="h-4 w-4 text-gray-500" />
                        </div>
                      </Button>
                    )}
                  </div>

                  {isShowGroups && (
                    <div className="mt-3 border border-[#e2e2e2] rounded-xl p-4 w-full">
                      {filterSet?.map((curr, index) => (
                        <div
                          key={index}
                          className={`flex items-center justify-between`}
                        >
                          <div
                            className={`flex w-full gap-x-5 gap-y-4 items-center flex-wrap ${
                              index !== 0 ? "mt-3" : ""
                            }`}
                          >
                            <div>
                              <Input
                                onChange={(e) =>
                                  handleWriteGroupName(index, e?.target?.value)
                                }
                                placeholder="Group name"
                                className="bg-[#F3F3F3] w-48"
                                value={filterSet?.[index]?.groupName || ""}
                              />
                            </div>
                            <div>
                              <p className="text-xs font-medium text-gray-500">
                                where
                              </p>
                            </div>
                            {curr?.filters?.length > 0 &&
                              curr?.filters?.map((curr_filter, fIndex) => (
                                <NewDateRangePicker
                                  key={fIndex}
                                  date={filterSet?.[index]?.filters?.[fIndex]}
                                  onDateDaysChange={onDateDaysChange}
                                  groupIndex={index}
                                  fIndex={fIndex}
                                  handleUpdateSelectionType={
                                    handleUpdateSelectionType
                                  }
                                />
                              ))}
                            {/* <Button
                          onClick={() => handleAddDateInGroup(index)}
                          variant="secondary"
                        >
                          Add date
                        </Button> */}
                            <Separator />
                          </div>
                          <div flex="true">
                            <DropdownMenu>
                              <DropdownMenuTrigger>
                                <Ellipsis className="h-5 cursor-pointer" />
                              </DropdownMenuTrigger>
                              <DropdownMenuContent className="w-44 rounded-md mr-20 p-1">
                                <DropdownMenuGroup>
                                  <DropdownMenuItem
                                    onClick={() => handleDuplicateGroup(index)}
                                  >
                                    <span>Duplicate</span>
                                  </DropdownMenuItem>
                                  {filterSet?.length > 1 && (
                                    <DropdownMenuItem
                                      className="hover:bg-red-400"
                                      onClick={() => handleDeleteGroup(index)}
                                    >
                                      <span>Delete</span>
                                    </DropdownMenuItem>
                                  )}
                                </DropdownMenuGroup>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </div>
                        </div>
                      ))}
                      <Button
                        variant="ghost"
                        className="mt-2 font-xs font-normal items-center"
                        onClick={handleAddMoreGroup}
                      >
                        <Plus className="h-5 text-gray-500" /> Add group
                      </Button>
                    </div>
                  )}

                  {/* DATE RANGE PICKER AND FILTER */}
                  {/* <NewDateRangePicker
                date={date}
                onDateDaysChange={onDateDaysChange}
                handleUpdateSelectionType={handleUpdateSelectionType}
                groupIndex={null}
                fIndex={null}
              /> */}
                  {additionalFilterSet?.map((fSet, index) => {
                    return (
                      <ShadCnFilters
                        fIndex={index}
                        filterSet={additionalFilterSet}
                        isPerformanceMetrics={isPerformanceMetrics}
                        setIsPerformanceMetrics={setIsPerformanceMetrics}
                        metricsOptions={metricsOptions}
                        kpiFilters={kpiFilters}
                        payload={additionalFilterSet[index]}
                        disableFilters={disableFilters}
                        hideSidebar={hideSidebar}
                        filterAndOptions={filterAndOptions}
                        kpiFiltersConditions={kpiFiltersConditions}
                        kpiFiltersReverse={kpiFiltersReverse}
                        onOpenFilters={onOpenFilters}
                        onFilterWhereChange={onAdditionalFilterWhereChange}
                        onOpenKpiFilters={() => alert("yes")}
                        onFilterValueChange={onAdditionalFilterValueChange}
                        onUpdatePayload={onUpdatePayload}
                        onUpdateKpiFilters={onUpdateKpiFilters}
                        onFilterFilterChange={onAdditionalFilterFilterChange}
                        handleSearchMotionFilters={handleSearchMotionFilters}
                        handleUpdateSearchExtraFilter={
                          handleUpdateSearchExtraFilter
                        }
                        isSmartSuggestionLoading={isSmartSuggestionLoading}
                        smartSuggestions={smartSuggestion}
                        applyPayload={applyPayload}
                        onRemovePayload={onAdditionalFilterRemovePayload}
                        onFilterSetOpen={onFilterSetOpen}
                        handleDeleteFromFilterSet={handleDeleteFromFilterSet}
                        handleAddMore={handleAdditionalFiltersAddMore}
                        audienceSegments={audienceSegments}
                      />
                    );
                  })}
                  <ShadCnFilters
                    isPerformanceMetrics={false}
                    setIsPerformanceMetrics={setIsPerformanceMetrics}
                    metricsOptions={metricsOptions}
                    kpiFilters={kpiFilters}
                    payload={payload}
                    disableFilters={disableFilters}
                    hideSidebar={hideSidebar}
                    filterAndOptions={filterAndOptions}
                    kpiFiltersConditions={kpiFiltersConditions}
                    kpiFiltersReverse={kpiFiltersReverse}
                    onOpenFilters={onOpenFilters}
                    onFilterWhereChange={onFilterWhereChange}
                    onFilterValueChange={onFilterValueChange}
                    onUpdatePayload={onUpdatePayload}
                    onUpdateKpiFilters={onUpdateKpiFilters}
                    onFilterFilterChange={onFilterFilterChange}
                    handleSearchMotionFilters={handleSearchMotionFilters}
                    handleUpdateSearchExtraFilter={
                      handleUpdateSearchExtraFilter
                    }
                    isSmartSuggestionLoading={isSmartSuggestionLoading}
                    smartSuggestions={smartSuggestion}
                    applyPayload={applyPayload}
                    handleAddMore={handleAddMore}
                    onRemovePayload={onRemovePayload}
                    audienceSegments={audienceSegments}
                    isIndividualFilter={true}
                  />

                  <Button
                    className={`${
                      disableFilters
                        ? "hidden"
                        : "text-xs font-medium flex gap-x-1"
                    }`}
                    variant="outline"
                    size="default"
                    onClick={() => {
                      _handleApplyFilters();
                      setInitialProgress(0);
                    }}
                  >
                    <RefreshCcwDot className="h-4 w-4" />
                    Fetch Data
                  </Button>
                </div>
              </div>

              <div className="bg-gray-100 border-[2px] border-gray-100 w-full flex rounded-2xl p-3 mt-10 gap-x-2 justify-between items-center">
                <div className="text-xs w-[80%] flex flex-row items-center gap-x-3 h-fit">
                  <div
                    className="flex flex-row gap-x-2 flex-wrap items-center" // Updated to flex-wrap for horizontal layout
                  >
                    {resolvedKPIs?.map((metric, index) => {
                      const KPI = filterAndOptions?.kpi?.find(
                        (kpi) => kpi?.kpi === metric
                      );

                      if (!KPI) return null;

                      return (
                        <div className="flex items-center">
                          <div className="h-fit font-medium p-1 m-1 border-[1px] border-gray-300 rounded-md shadow-sm flex items-center justify-between gap-2">
                            <span
                              className={`px-2 py-1 rounded-md text-white shadow-xl flex items-center ${
                                SELECTED_METRIC_COLOR[index + 1]
                              }`}
                            >
                              {index + 1}
                            </span>
                            <span className="text-gray-600">{KPI?.label}</span>
                            <X
                              className="h-4 w-4 cursor-pointer"
                              onClick={() => {
                                updateKPIs({
                                  value: resolvedKPIs?.filter(
                                    (kpi) => kpi !== metric
                                  ),
                                });
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <Popover>
                      <PopoverTrigger
                        asChild
                        className="w-min shadow-sm cursor-pointer"
                      >
                        <DarkTooltip
                          placement="top"
                          title="Select the metrics that you want to analyse."
                        >
                          <div className="text-[#7167FB] font-medium px-2 py-2 border-[1px] border-gray-300 rounded-md shadow-sm flex items-center gap-x-1 whitespace-nowrap">
                            <Plus className="h-4 w-4 cursor-pointer" />
                            <span className="text-gray-600 font-medium">
                              Add metric
                            </span>
                          </div>
                        </DarkTooltip>
                      </PopoverTrigger>
                      <PopoverContent
                        className="w-140px p-0 flex justify-between"
                        side="right"
                        align="start"
                      >
                        <Command>
                          <CommandInput placeholder="Search metric..." />
                          <CommandEmpty>No metric found.</CommandEmpty>
                          <CommandList>
                            <CommandGroup>
                              {filterAndOptions?.kpi?.map((option) => {
                                if (
                                  resolvedKPIs.includes(option?.kpi.toString())
                                ) {
                                  return null;
                                }
                                return (
                                  <CommandItem
                                    className="flex justify-between text-xs font-medium"
                                    key={option?.label.toString()}
                                    value={option?.label.toString()}
                                    onSelect={(currentValue) => {
                                      if (!isSelectionValid("kpi")) {
                                        return;
                                      }
                                      updateKPIs({
                                        value: [
                                          ...resolvedKPIs,
                                          option?.kpi.toString(),
                                        ],
                                      });
                                    }}
                                  >
                                    <div className="flex justify-between w-full gap-x-4">
                                      <div>{option?.label}</div>
                                      <DarkTooltip
                                        title={option?.description}
                                        arrow
                                        placement="right"
                                      >
                                        <Info className="h-4 w-4 text-black" />
                                      </DarkTooltip>
                                    </div>
                                  </CommandItem>
                                );
                              })}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
                <div className="flex gap-x-4 items-center">
                  <DarkTooltip
                    placement="top"
                    title={"Copy performance card/graph to your clipboard."}
                  >
                    <Button
                      className="shadow-sm"
                      variant="outline"
                      size="icon"
                      onClick={() => handleCopyToClipboard()}
                    >
                      <ClipboardCopy className="h-5 w-5 text-gray-500" />
                    </Button>
                  </DarkTooltip>
                  <Tabs
                    defaultValue="account"
                    className="border-[1px] rounded-md border-gray-300 cursor-not-allowed"
                  >
                    <TabsList
                      className={`flex p-1 bg-gray-200 ${
                        sortedData.length ? "" : "bg-slate-200"
                      }`}
                    >
                      <TabsTrigger
                        value="tile-view"
                        onClick={() => {
                          changeVisualizationTab(0);
                        }}
                        className={`${
                          visualisationTab === 0
                            ? "bg-white"
                            : "!bg-inherit !text-inherit"
                        }`}
                      >
                        <InspectionPanel
                          className={`w-5 h-5 ${
                            visualisationTab === 0
                              ? "text-blue-500 font-extrabold"
                              : ""
                          }`}
                        />
                      </TabsTrigger>
                      <TabsTrigger
                        value="line-view"
                        onClick={() => {
                          changeVisualizationTab(1);
                        }}
                        className={`${
                          visualisationTab === 1
                            ? "bg-white"
                            : "!bg-inherit !text-inherit"
                        }`}
                      >
                        <LineChart
                          className={`w-5 h-5 ${
                            visualisationTab === 1
                              ? "text-blue-500 font-extrabold"
                              : ""
                          }`}
                        />
                      </TabsTrigger>
                      <TabsTrigger
                        value="bar-chart"
                        onClick={() => {
                          changeVisualizationTab(2);
                        }}
                        className={`${
                          visualisationTab === 2
                            ? "bg-white"
                            : "!bg-inherit !text-inherit"
                        }`}
                      >
                        <BarChart3
                          className={`w-5 h-5 ${
                            visualisationTab === 2
                              ? "text-blue-500 font-extrabold"
                              : ""
                          }`}
                        />
                      </TabsTrigger>
                    </TabsList>
                  </Tabs>
                </div>
              </div>

              <div ref={ref} className="p-5 min-h-[15%] my-4">
                {isInsightLoading ? (
                  <div class="flex items-center flex-col justify-start space-y-4 mt-28">
                    <p className="text-xs font-medium">Waiting For Meta...</p>
                    <MetaLoader
                      initialProgress={initialProgress}
                      setInitialProgress={setInitialProgress}
                    />
                  </div>
                ) : (
                  <>
                    {sortedData.length > 0 &&
                    Object.keys(rowSelection)?.length ? (
                      <>
                        {visualisationTab === 0 &&
                          (resolvedKPIs.length ? (
                            <NewInsightsCardView
                              rowSelection={rowSelection}
                              sortedData={sortedData}
                              selectedKPIs={resolvedKPIs}
                              filterAndOptions={filterAndOptions}
                              colorFormat={colorFormat}
                              doesShowColor={doesShowColor}
                            />
                          ) : (
                            <>
                              <EmptyData
                                customClass="mt-10"
                                imageUrl="/choice.png"
                                title="Select KPI"
                              />
                            </>
                          ))}
                        <ResponsiveBox>
                          {visualisationTab === 1 &&
                            (resolvedKPIs.length ? (
                              <LineChartMultiYAxis
                                insightsData={sortedData}
                                selectedMetrics={resolvedKPIs}
                                rowSelection={rowSelection}
                                filterAndOptions={filterAndOptions}
                              />
                            ) : (
                              <>
                                <EmptyData
                                  customClass="mt-10"
                                  imageUrl="/choice.png"
                                  title="Select KPI"
                                />
                              </>
                            ))}
                          {visualisationTab === 2 &&
                            (resolvedKPIs.length ? (
                              <BarChartMultiYAxis
                                insightsData={sortedData}
                                selectedMetrics={resolvedKPIs}
                                rowSelection={rowSelection}
                                filterAndOptions={filterAndOptions}
                              />
                            ) : (
                              <>
                                <EmptyData
                                  customClass="mt-10"
                                  imageUrl="/choice.png"
                                  title="Select Ad Groups"
                                />
                              </>
                            ))}
                          {visualisationTab === 3 && (
                            <TableView insightsData={insightsData} />
                          )}
                        </ResponsiveBox>
                      </>
                    ) : (
                      <EmptyData
                        customClass="mt-10"
                        imageUrl="/choice.png"
                        title="Select Ad Groups"
                      />
                    )}
                  </>
                )}
              </div>
              <div className="mb-10">
                {insightsData?.length > 0 && (
                  <ColorTable
                    isLoadingSpecificPreset={isLoadingSpecificPreset}
                    setSortedData={setSortedData}
                    filterAndOptions={filterAndOptions}
                    isSelectionValid={isSelectionValid}
                    insightsData={insightsData}
                    rowSelection={rowSelection}
                    visualisationTab={visualisationTab}
                    setRowSelection={setRowSelection}
                    selectedMetrices={resolvedKPIs}
                    updateKPIs={updateKPIs}
                    selectedKPIs={resolvedKPIs}
                    setSelectedKPIs={setSelectedKPIs}
                    handlePresetSelect={handlePresetSelect}
                    preset={preset}
                    presetData={presetData}
                    isMakingPreset={isMakingPreset}
                    makePreset={makePreset}
                    disableFilters={disableFilters}
                    hideSidebar={hideSidebar}
                    specificPresetData={specificPresetData}
                    _handleApplyFilters={_handleApplyFilters}
                    tempKpis={tempKpis}
                    setTempKpis={setTempKpis}
                    colorFormat={colorFormat}
                    handleColorFormatChange={handleColorFormatChange}
                    doesShowColor={doesShowColor}
                    handleAdBreakdown={handleAdBreakdown}
                    isShowAdBreakdownOption={false}
                  />
                )}
              </div>
            </div>
          </div>
          {open && type === MODALTYPES.SAVE_INSIGHTS_REPORT && (
            <SaveInsightsReportModal
              reportData={reportData}
              saveReport={saveReport}
              isCreatingReport={isCreatingReport}
            />
          )}
          <MoveReportToFolderModal primaryAction={moveReportToFolder} />
          <PresetModal
            preset={preset}
            handlePresetSelect={handlePresetSelect}
            presetData={presetData}
            selectedMetrices={selectedMetrices}
            specificPresetData={specificPresetData}
            filterAndOptions={filterAndOptions}
            onUpdateSelectedMetrices={onUpdateSelectedMetrices}
            updatePreset={updatePreset}
            isMakingPreset={isMakingPreset}
            makePreset={makePreset}
            tempKpis={tempKpis}
            setTempKpis={setTempKpis}
            isLoadingSpecificPreset={isLoadingSpecificPreset}
          />
          <ChangeVisualizationTab
            showModal={confirmVisualizationModal.showModal}
            closeFn={closeVisualizationTabSwitch}
            confirmFn={confirmVisualizationTabSwitch}
          />
        </>
      )}
    </PrivateContainer>
  );
};

export default CompareWithDates;
