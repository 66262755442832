import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { RefreshCcwDot } from "lucide-react";
import moment from "moment";
import { useEffect } from "react";
import { DarkTooltip } from "screens/Insights/Components/ColorTable/ColorTable";
import NewDateRangePicker from "../NewDateRangePicker/DateRangePicker";
import ShadCnFilters from "../NewFilters/ShadCnFilters";
import "./customDatePickerWidth.css";

const Filters = (props) => {
  const {
    onDateDaysChange,
    disableFilters,
    hideSidebar,
    groupBy,
    groupbyOptions,
    _handleApplyFilters,
    setInitialProgress,
    isSmartSuggestionLoading,
    smartSuggestion,
    handleUpdateSearchExtraFilter,
    handleSearchMotionFilters,
    currentFilterObj,
    isPerformanceMetrics,
    setIsPerformanceMetrics,
    setCurrentFilterObj,
    metricsOptions,
    kpiFilters,
    payload,
    filterAndOptions,
    kpiFiltersConditions,
    kpiFiltersReverse,
    changeGroupBy,
    onOpenFilters,
    onFilterWhereChange,
    onOpenKpiFilters,
    onFilterValueChange,
    onUpdatePayload,
    onUpdateKpiFilters,
    date,
    onFilterFilterChange,
    isLoadingFilters,
    applyPayload,
    filterSet,
    onRemovePayload,
    onFilterSetOpen,
    handleDeleteFromFilterSet,
    handleAddMore,
    handleUpdateSelectionType,
    handleUpdateDate,
    isExcludeGroupBy = false,
  } = props;

  const handleGetValueFromDate = () => {
    if (date?.selectionType && date?.selectionType !== 4) {
      const _startDate = moment(new Date())
        .subtract(parseInt(date?.selectiontype), "days")
        .format("YYYY-MM-DD");
      const _endDate = moment(new Date()).format("YYYY-MM-DD");
      const _updatedDate = {
        ...date,
        startDate: _startDate,
        endDate: _endDate,
      };
      handleUpdateDate(_updatedDate);
    } else {
      return date;
    }
  };

  useEffect(() => {
    handleGetValueFromDate();
  }, []);
  if (isLoadingFilters) {
    return (
      <div className="flex gap-x-5">
        <Skeleton className="h-5 w-[200px]" />
        <Skeleton className="h-5 w-[160px]" />
        <Skeleton className="h-5 w-[140px]" />
        <Skeleton className="h-5 w-[150px]" />
      </div>
    );
  }
  return (
    <div className={`flex flex-wrap gap-2 items-center text-xs mt-2`}>
      {/* <DateFilter
        disabled={disableFilters || hideSidebar}
        key={date?.startDate}
        options={DateFilterOptions}
        value={date}
        onChange={(value) => {
          onDateDaysChange(value);
        }}
      /> */}

      <div
      // className={
      //   disableFilters || hideSidebar
      //     ? "cursor-not-allowed pointer-events-none opacity-50"
      //     : ""
      // }
      >
        <NewDateRangePicker
          date={date}
          onDateDaysChange={onDateDaysChange}
          handleUpdateSelectionType={handleUpdateSelectionType}
        />
      </div>

      {/*  GROUP-BY  */}
      {!isExcludeGroupBy && (
        <Select
          className="w-min"
          onValueChange={(val) => changeGroupBy(val)}
          // disabled={disableFilters || hideSidebar}
        >
          <SelectTrigger className="w-fit flex gap-x-2 text-xs">
            <span className="text-gray-500">Group by</span>
            <span className="font-medium">{groupBy?.label}</span>
          </SelectTrigger>
          <SelectContent className="text-xs">
            {groupbyOptions?.map((option, idx) => (
              <SelectItem
                key={JSON.stringify(option)}
                value={option}
                className="text-xs"
              >
                {option?.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}

      {filterSet?.length > 0 &&
        filterSet?.map((fset, findex) => {
          return (
            <ShadCnFilters
              fIndex={findex}
              filterSet={filterSet}
              currentFilterObj={currentFilterObj}
              isPerformanceMetrics={isPerformanceMetrics}
              setIsPerformanceMetrics={setIsPerformanceMetrics}
              setCurrentFilterObj={setCurrentFilterObj}
              metricsOptions={metricsOptions}
              kpiFilters={kpiFilters}
              payload={filterSet[findex]}
              disableFilters={disableFilters}
              hideSidebar={hideSidebar}
              filterAndOptions={filterAndOptions}
              kpiFiltersConditions={kpiFiltersConditions}
              kpiFiltersReverse={kpiFiltersReverse}
              onOpenFilters={onOpenFilters}
              onFilterWhereChange={onFilterWhereChange}
              onOpenKpiFilters={onOpenKpiFilters}
              onFilterValueChange={onFilterValueChange}
              onUpdatePayload={onUpdatePayload}
              onUpdateKpiFilters={onUpdateKpiFilters}
              onFilterFilterChange={onFilterFilterChange}
              handleSearchMotionFilters={handleSearchMotionFilters}
              handleUpdateSearchExtraFilter={handleUpdateSearchExtraFilter}
              isSmartSuggestionLoading={isSmartSuggestionLoading}
              smartSuggestions={smartSuggestion}
              applyPayload={applyPayload}
              onRemovePayload={onRemovePayload}
              onFilterSetOpen={onFilterSetOpen}
              handleDeleteFromFilterSet={handleDeleteFromFilterSet}
              handleAddMore={handleAddMore}
            />
          );
        })}

      {/* FILTERS POPOVER */}
      <ShadCnFilters
        currentFilterObj={currentFilterObj}
        isPerformanceMetrics={isPerformanceMetrics}
        setIsPerformanceMetrics={setIsPerformanceMetrics}
        setCurrentFilterObj={setCurrentFilterObj}
        metricsOptions={metricsOptions}
        kpiFilters={kpiFilters}
        payload={payload}
        disableFilters={disableFilters}
        hideSidebar={hideSidebar}
        filterAndOptions={filterAndOptions}
        kpiFiltersConditions={kpiFiltersConditions}
        kpiFiltersReverse={kpiFiltersReverse}
        onOpenFilters={onOpenFilters}
        onFilterWhereChange={onFilterWhereChange}
        onOpenKpiFilters={onOpenKpiFilters}
        onFilterValueChange={onFilterValueChange}
        onUpdatePayload={onUpdatePayload}
        onUpdateKpiFilters={onUpdateKpiFilters}
        onFilterFilterChange={onFilterFilterChange}
        handleSearchMotionFilters={handleSearchMotionFilters}
        handleUpdateSearchExtraFilter={handleUpdateSearchExtraFilter}
        isSmartSuggestionLoading={isSmartSuggestionLoading}
        smartSuggestions={smartSuggestion}
        applyPayload={applyPayload}
        handleAddMore={handleAddMore}
        onRemovePayload={onRemovePayload}
      />

      {/* MAGIC EXTRA FILTERS */}
      {/* <Popover className="">
        <PopoverTrigger
          disabled={disableFilters}
          className="text-[#7167FB] flex justify-between"
        >
          <Button
            variant="outline"
            disabled={disableFilters}
            className={`${
              disableFilters ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <Wand className="text-[#7167FB] mr-1" />
            <span className="flex w-full items-center text-[#7167FB] text-sm">{`Deep search`}</span>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-max h-full max-h-[450px] overflow-scroll">
          <ExtraFilters
            state={insightState}
            fields={[
              "Ad-Name",
              "Ad-set-Name",
              "Campaign Name",
              "Group Name",
              "Audience Segment",
              "Tags",
            ]}
            handleExtraFilterSelectUpdate={handleExtraFilterSelectUpdate}
            handleSearchMotionFilters={handleSearchMotionFilters}
            handleUpdateSearchExtraFilter={handleUpdateSearchExtraFilter}
            smartSuggestions={smartSuggestion}
            isSmartSuggestionLoading={isSmartSuggestionLoading}
            handleAddNewFilter={handleAddNewFilter}
            handleRemoveNewFilter={(index) => handleRemoveNewFilter(index)}
            handleAggregateLogicChangeExtraFilters={(value) =>
              handleAggregateLogicChangeExtraFilters(value)
            }
          />

          <div className="flex items-center gap-x-2">
            <Button variant="outline" onClick={handleAddNewFilter}>
              Add more
            </Button>
            <div className="flex justify-between items-center">
              <div className="flex gap-x-2">
                <PopoverClose asChild>
                  <Button className="justify-end" variant="secondary">
                    Close
                  </Button>
                </PopoverClose>
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover> */}

      <DarkTooltip
        title="Click here after selecting your date range and applying filters."
        placement="top"
      >
        <Button
          // disabled={disableFilters}
          className={`${
            disableFilters ? "hidden" : "text-xs font-medium flex gap-x-1"
          }`}
          variant="outline"
          size="default"
          onClick={() => {
            _handleApplyFilters();
            setInitialProgress && setInitialProgress(0);
          }}
        >
          <RefreshCcwDot className="h-4 w-4" />
          Fetch Data
        </Button>
      </DarkTooltip>

      {/* Select to compare button, needs to be uncommented and worked upon later */}

      {/* {((groupBy.value === "groupId" && !isCompare) ||
        !!specificInsightsId) && (
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger>
              <Button
                disabled={hideSidebar || disableFilters}
                className={
                  hideSidebar || disableFilters
                    ? "pointer-events-none flex gap-x-2"
                    : "flex gap-x-2"
                }
                variant="secondary"
                onClick={() => onCompare()}
              >
                <SquareMousePointer
                  className={`h-4 w-4 ${
                    disableFilters ? "cursor-not-allowed" : ""
                  }`}
                />
                {groupIds.length > 0 ? "Compare" : "Select to Compare"}
              </Button>
            </TooltipTrigger>
            <TooltipContent className="w-72">
              <p>
                Select multiple groups to compare them on selected parameters
                for deeper insights about where similar or different groups
                lacks or wins
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )} */}
    </div>
  );
};

export default Filters;
