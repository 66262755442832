import React from "react";
import { Dialog } from "@mui/material";
import { modalInitState, setModal } from "../../../redux/slices/modal.slices";
import { useDispatch } from "react-redux";

export default function CustomModal(props) {
  const { children, open } = props;
  const dispatch = useDispatch();
  function handleClose() {
    dispatch(setModal(modalInitState));
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      {children}
    </Dialog>
  );
}
