import { Stack } from "@mui/system";
import React from "react";
// import FontPicker from "font-picker-react";
import { Avatar, Button, Typography } from "@mui/material";
import { deleteIcon } from "assets/icons/svg";
import ContentContainer from "components/layouts/ContentContainer";
import { FileUploadDnD } from "components/common/molecules";
import { AddOutlined } from "@mui/icons-material";
import useBrandfont from "./useBrandfont";

const FontCard = ({ font, deleteFont }) => {
  return (
    <Stack
      sx={{
        minWidth: "200px",
        minHeight: "80px",
        maxHeight: "80px",
        maxWidth: "200px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        border: "0.5px solid #A1B0CC",
        position: "relative",
      }}
    >
      <Stack sx={{ height: "46px" }}>
        <Avatar
          sx={{
            margin: "5px",
            position: "absolute",
            right: "0px",
            top: "0px",
            cursor: "pointer",
            backgroundColor: "#FFFFFF",
          }}
        >
          <img
            src={deleteIcon}
            alt=""
            onClick={() => deleteFont()}
            style={{
              height: "15px",
              width: "15px",
            }}
          />
        </Avatar>
      </Stack>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: "400",
          color: "#7167FB",
          width: "200px",
          padding: "0px 10px",
        }}
        title={font?.brandFontName}
      >
        {`${font?.brandFontName?.slice(0, 13)} ${
          font?.brandFontName?.length > 13 ? "..." : ""
        }`}
      </Typography>
    </Stack>
  );
};

const FontsListContainer = ({ children, label = "" }) => {
  return (
    <Stack
      sx={{
        gap: "28px",
        width: "100%",
        flexWrap: "wrap",
        border: "1px solid #A1B0CC",
        padding: "18px 16px",
        borderRadius: "6px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            color: "#1C1E20",
          }}
        >
          {label}
        </Typography>
      </Stack>
      <Stack sx={{ flexDirection: "row", gap: "28px", flexWrap: "wrap" }}>
        {children}
      </Stack>
    </Stack>
  );
};

const AddMoreButton = () => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: "107px",
        height: "91px",
        border: "0.5px solid #A1B0CC",
        borderRadius: "4px",
        cursor: "pointer",
      }}
    >
      <AddOutlined htmlColor="#1C1E2080" />
      <Typography
        sx={{
          fontSize: "10px",
          fontWeight: "400",
          color: "#1C1E2080",
        }}
      >
        Add More
      </Typography>
    </Stack>
  );
};

export default function BrandFonts() {
  const [activeFontFamily, setActiveFontFamily] = React.useState("Open Sans");

  const [activeFont, setActiveFont] = React.useState({});

  const { addGoogleFont, googleFonts, customFonts, deleteFont, addCustomFont } =
    useBrandfont();

  return (
    <ContentContainer
      containerStyles={{
        maxHeight: "65vh",
      }}
    >
      <Stack
        sx={{
          gap: "40px",
          width: "100%",
        }}
      >
        <Stack
          sx={{
            gap: "32px",
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              gap: "28px",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <FontsListContainer label="Google Fonts">
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: "40px",
                  width: "100%",
                }}
              >
                <Stack
                  sx={{
                    gap: "20px",
                  }}
                >
                  {/* <FontPicker
                    apiKey={process.env.REACT_APP_GOOGLE_FONT_KEY}
                    activeFontFamily={activeFontFamily}
                    onChange={(nextFont) => {
                      setActiveFontFamily(nextFont.family);
                      setActiveFont(nextFont);
                    }}
                    limit={200}
                  /> */}
                  <Typography
                    className="apply-font"
                    sx={{
                      fontSize: "20px",
                    }}
                  >
                    The font will be applied to this text.
                  </Typography>
                </Stack>

                <Button
                  onClick={() => addGoogleFont(activeFont)}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    height: "40px",
                  }}
                >
                  Add Font
                </Button>
              </Stack>

              {googleFonts?.map((font) => (
                <FontCard
                  font={font}
                  deleteFont={() => deleteFont(font?._id, 2)}
                />
              ))}
            </FontsListContainer>

            <FontsListContainer label="Custom Fonts">
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: "40px",
                }}
              >
                <FileUploadDnD
                  onUpload={(files) => addCustomFont(files)}
                  uploaderStyles={{
                    border: "none",
                  }}
                  extensions={{
                    "font/ttf": [".ttf", ".woff", ".woff2", ".otf"],
                  }}
                >
                  <AddMoreButton />
                </FileUploadDnD>
              </Stack>

              {customFonts?.map((font) => (
                <FontCard
                  font={font}
                  deleteFont={() => deleteFont(font?._id, 1)}
                />
              ))}
            </FontsListContainer>
          </Stack>
        </Stack>
      </Stack>
    </ContentContainer>
  );
}
