import { Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const RecommendationListItem = (props) => {
  const { isActive, children, listStyle = {}, onClick } = props;
  return (
    <Stack
      direction="column"
      sx={{
        borderRadius: "8px",
        padding: "10px",
        color: isActive ? "#7167FB" : "#1C1E20",
        background: isActive ? "#DAD7FE" : "#F9F9F9",
        ...listStyle,
      }}
      onClick={onClick}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <CircleIcon sx={{ fontSize: "8px" }} />
        <Stack direction="row" spacing={1 / 2} alignItems="center">
          <Typography
            sx={{
              fontWeight: "500",
            }}
          >
            {children}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RecommendationListItem;
