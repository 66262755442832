import { Button, Button as ShadButton } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  Select as ShadSelect,
} from "@/components/ui/select";
import { PopoverClose } from "@radix-ui/react-popover";
import { FILTER_CATEGORY_REVERSE_MAPPING } from "config/filters";
import { FILTER_AGGREGATORS_REVERSE_MAPPING } from "config/statics";
import { ListFilter, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { DarkTooltip } from "screens/Insights/Components/ColorTable/ColorTable";
import FilterContent from "./FilterContent";

const ShadCnFilters = (props) => {
  const {
    currentFilterObj,
    isPerformanceMetrics,
    setIsPerformanceMetrics,
    setCurrentFilterObj,
    metricsOptions,
    kpiFilters,
    payload,
    disableFilters,
    hideSidebar,
    filterAndOptions,
    kpiFiltersConditions,
    kpiFiltersReverse,
    onOpenFilters,
    onFilterWhereChange,
    onOpenKpiFilters,
    onFilterValueChange,
    onUpdatePayload,
    onUpdateKpiFilters,
    onFilterFilterChange,
    handleSearchMotionFilters,
    handleUpdateSearchExtraFilter,
    isSmartSuggestionLoading,
    smartSuggestions,
    applyPayload,
    fIndex = null,
    filterSet = null,
    onRemovePayload,
    onFilterSetOpen,
    handleDeleteFromFilterSet,
    handleAddMore,
    groupIndex,
    isIndividualFilter,
  } = props;

  useEffect(() => {
    const keyName = "adAccountIds";
    const { [keyName]: { options = [] } = {} } = filterAndOptions;
    if (options?.length > 0) {
      const updatedPayload = [...payload];
      payload.forEach((obj) => {
        const { type, value } = obj;
        if (type === "adAccountIds" && !value?.length) {
          updatedPayload?.push({
            type: keyName,
            mode: 1,
            value: [options[0]?.key],
          });
          onUpdatePayload(updatedPayload);
        }
      });
    }
  }, [filterAndOptions]);

  const [openFilters, setOpenFilters] = useState(false);
  const getFilterOptionLabelForValue = (value) => {
    let res;
    Object.entries(filterAndOptions)?.map(([key, val], idx) => {
      if (key === value) {
        res = val?.label;
        return val?.label;
      }
    });
    return res ? res : "Where";
  };

  return (
    <Popover
      open={currentFilterObj?.value || isPerformanceMetrics || openFilters}
      onOpenChange={() => {
        if (openFilters) {
          setOpenFilters(false);
          setIsPerformanceMetrics(false);
        }
        if (isPerformanceMetrics) {
          setIsPerformanceMetrics(false);
        } else {
          setIsPerformanceMetrics(false);
        }
      }}
    >
      <PopoverTrigger>
        {fIndex === null ? (
          <DarkTooltip
            title={"Filter out the data you want to analyse"}
            placement="top"
          >
            <Button
              className="flex gap-x-1 text-xs font-medium"
              variant="outline"
              onClick={() => {
                onOpenFilters(isIndividualFilter);
                setOpenFilters(true);
              }}
              // disabled={disableFilters || hideSidebar}
            >
              <ListFilter className="text-[#7167FB]" />
              <span className="flex w-full items-center font-medium text-[#7167FB]">{`Add filters`}</span>
            </Button>
          </DarkTooltip>
        ) : (
          <Button
            className="text-[#3733aa] font-medium flex gap-x-1 truncate justify-between hover:text-[#3733aa] text-xs border border-[#a2a8f3] bg-[#edefff]"
            variant="outline"
            // disabled={disableFilters || hideSidebar}
            onClick={() => {
              onFilterSetOpen();
              setOpenFilters(true);
            }}
          >
            {groupIndex === 0 || groupIndex ? (
              <span>
                {FILTER_CATEGORY_REVERSE_MAPPING?.[filterSet?.[0]?.type]
                  ?.label ||
                  getFilterOptionLabelForValue(filterSet?.[0]?.type)}{" "}
                {
                  FILTER_AGGREGATORS_REVERSE_MAPPING[filterSet?.[0]?.filter]
                    ?.label
                }
              </span>
            ) : (
              <span>
                {FILTER_CATEGORY_REVERSE_MAPPING?.[
                  filterSet?.[fIndex]?.[0]?.type
                ]?.label ||
                  getFilterOptionLabelForValue[
                    filterSet?.[fIndex]?.[0]?.type
                  ]}{" "}
                {
                  FILTER_AGGREGATORS_REVERSE_MAPPING[
                    filterSet?.[fIndex]?.[0]?.filter
                  ]?.label
                }
              </span>
            )}
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent className="mt-1 ml-40 w-auto text-xs h-full max-h-[450px] overflow-scroll">
        {!isPerformanceMetrics ? (
          <FilterContent
            payload={payload}
            filterAndOptions={filterAndOptions}
            onFilterWhereChange={onFilterWhereChange}
            fIndex={fIndex}
            onFilterFilterChange={onFilterFilterChange}
            onFilterValueChange={onFilterValueChange}
            handleSearchMotionFilters={handleSearchMotionFilters}
            handleUpdateSearchExtraFilter={handleUpdateSearchExtraFilter}
            isSmartSuggestionLoading={isSmartSuggestionLoading}
            smartSuggestions={smartSuggestions}
            handleDeleteFromFilterSet={handleDeleteFromFilterSet}
            onUpdatePayload={onUpdatePayload}
            handleAddMore={handleAddMore}
            onRemovePayload={onRemovePayload}
            setCurrentFilterObj={setCurrentFilterObj}
            applyPayload={applyPayload}
            setOpenFilters={setOpenFilters}
            groupIndex={groupIndex}
            isIndividualFilter={isIndividualFilter}
          />
        ) : (
          <>
            <div className="flex gap-x-3 items-start">
              <p className="mt-2">Where,</p>
              <div className="flex flex-col gap-y-2">
                {kpiFilters?.map((kpiFilter, index) => {
                  return (
                    <div
                      className="flex gap-x-2 items-center"
                      key={index.toString() + "kpiFilters"}
                    >
                      <ShadSelect
                        onValueChange={(val) => {
                          const updatedKpiFilters = [...kpiFilters];
                          updatedKpiFilters[index] = {
                            ...updatedKpiFilters[index],
                            aggregator: val,
                          };
                          onUpdateKpiFilters(updatedKpiFilters);
                        }}
                      >
                        <SelectTrigger className="w-[70px]">
                          <span>{kpiFilter?.aggregator}</span>
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem
                            value={"AND"}
                            className="cursor-pointer w-full"
                          >
                            AND
                          </SelectItem>
                          <SelectItem
                            value={"OR"}
                            className="cursor-pointer w-full"
                          >
                            OR
                          </SelectItem>
                        </SelectContent>
                      </ShadSelect>

                      <ShadSelect
                        onValueChange={(val) => {
                          const updatedKpiFilters = [...kpiFilters];
                          updatedKpiFilters[index] = {
                            ...updatedKpiFilters[index],
                            key: val,
                          };
                          onUpdateKpiFilters(updatedKpiFilters);
                        }}
                      >
                        <SelectTrigger className="w-[200px]">
                          <span>{kpiFilter?.key}</span>
                        </SelectTrigger>
                        <SelectContent>
                          {metricsOptions?.map((kpi, idx) => (
                            <SelectItem
                              value={kpi?.value}
                              className="cursor-pointer w-full"
                            >
                              {kpi?.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </ShadSelect>

                      <ShadSelect
                        onValueChange={(val) => {
                          const updatedKpiFilters = [...kpiFilters];
                          updatedKpiFilters[index] = {
                            ...updatedKpiFilters[index],
                            condition: val,
                          };
                          onUpdateKpiFilters(updatedKpiFilters);
                        }}
                      >
                        <SelectTrigger className="w-fit">
                          <span>{kpiFiltersReverse[kpiFilter?.condition]}</span>
                        </SelectTrigger>
                        <SelectContent>
                          {kpiFiltersConditions?.map((condition, idx) => (
                            <SelectItem
                              value={condition?.value}
                              className="cursor-pointer w-full"
                            >
                              {condition?.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </ShadSelect>

                      {kpiFilter?.condition === "between" ? (
                        <div className="flex gap-x-1">
                          <Input
                            className="w-[120px]"
                            value={kpiFilter?.value[0] || ""}
                            onChange={(e) => {
                              const updatedKpiFilters = [...kpiFilters];
                              const newValue = e.target.value;
                              updatedKpiFilters[index] = {
                                ...updatedKpiFilters[index],
                                value:
                                  updatedKpiFilters[index]?.value?.length > 0
                                    ? [newValue].concat(
                                        updatedKpiFilters[index]?.value?.slice(
                                          1
                                        )
                                      )
                                    : [newValue],
                              };
                              onUpdateKpiFilters(updatedKpiFilters);
                              // dispatch(setKpiFilters(updatedKpiFilters));
                            }}
                            placeholder="Threshold(numeric, string or float)"
                          />

                          <Input
                            className="w-[120px]"
                            value={kpiFilter?.value[1] || ""}
                            onChange={(e) => {
                              const updatedKpiFilters = [...kpiFilters];
                              const newValue = e.target.value;
                              updatedKpiFilters[index] = {
                                ...updatedKpiFilters[index],
                                value:
                                  updatedKpiFilters[index]?.value?.length > 0
                                    ? [
                                        updatedKpiFilters[index].value[0],
                                        newValue,
                                      ]
                                    : [null, newValue],
                              };
                              onUpdateKpiFilters(updatedKpiFilters);
                            }}
                            placeholder="Threshold(numeric, string or float)"
                          />
                        </div>
                      ) : (
                        <>
                          <Input
                            className="w-[140px]"
                            value={kpiFilter?.value[0] || ""}
                            onChange={(e) => {
                              const updatedKpiFilters = [...kpiFilters];
                              updatedKpiFilters[index] = {
                                ...updatedKpiFilters[index],
                                value: [e?.target?.value],
                              };
                              onUpdateKpiFilters(updatedKpiFilters);
                            }}
                            placeholder="Threshold(numeric, string or float)"
                          />
                        </>
                      )}

                      <Trash2
                        onClick={() => {
                          const _currentKpiFilters = kpiFilters?.filter(
                            (curr, idx) => idx !== index
                          );
                          onUpdateKpiFilters(_currentKpiFilters);
                        }}
                        className={`p-1 text-red-500 cursor-pointer mt-[6px] ${
                          index === 0 ? "opacity-30 cursor-not-allowed" : ""
                        }`}
                      />
                    </div>
                  );
                })}
              </div>
              {/* buttons */}
            </div>
            <div>
              <div className="flex justify-between">
                <ShadButton
                  className="mt-4 justify-end"
                  variant="outline"
                  onClick={() => {
                    const updatedKpiFilters = [...kpiFilters];
                    updatedKpiFilters?.push({
                      key: metricsOptions[0]?.value,
                      condition: "greater",
                      value: [],
                      aggregator: "AND",
                    });
                    onUpdateKpiFilters(updatedKpiFilters);
                  }}
                >
                  Add More
                </ShadButton>
                <div>
                  <PopoverClose asChild>
                    <ShadButton
                      className="mt-4 mr-2 justify-end"
                      variant="outline"
                      onClick={() => {
                        setIsPerformanceMetrics(false);
                      }}
                    >
                      Apply
                    </ShadButton>
                  </PopoverClose>
                  <ShadButton
                    className="mt-4 justify-end"
                    variant="destructive"
                    onClick={() => {
                      const updatedKpiFilters = [];
                      updatedKpiFilters?.push({
                        key: metricsOptions[0]?.value,
                        condition: "greater",
                        value: [],
                        aggregator: "AND",
                      });
                      onUpdateKpiFilters(updatedKpiFilters);
                    }}
                  >
                    Remove
                  </ShadButton>
                </div>
              </div>
            </div>
          </>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default ShadCnFilters;
