import React, { useState } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import {
  RotateCw,
  CirclePlus,
  RefreshCw,
  Loader2,
  RouteOff,
  CircleUserRound,
  Pencil,
} from "lucide-react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentContainer from "../../../../../../../../components/layouts/ContentContainer";

import { useDispatch } from "react-redux";
import {
  MODALTYPES,
  setModal,
} from "../../../../../../../../redux/slices/modal.slices";
import useOrganisationDetail from "./useOrganisationDetail";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../../../../../../routes";
import useUserRole from "hooks/useUserRole";
import axios from "axios";
import {
  SA_PERMISSIONS,
  A_PERMISSIONS,
} from "../../../../../../../../config/roles";
import useAppState from "hooks/useAppState";
import { Button } from "@/components/ui/button";
import { Label } from "@radix-ui/react-label";
import { Input } from "@/components/ui/input";
import { Select } from "@radix-ui/react-select";
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useEditorganisationDetails, useGetUploadUrls } from "apis/common";
import { Separator } from "@/components/ui/separator";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { FileUploadDnD } from "components/common/molecules";
import { setLoading } from "redux/slices/app.slices";
import PresetModal from "components/modals/PresetsModal/PresetModal";
import useInsights from "screens/Insights/useInsights";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

export default function OrganisationDetail(props) {
  const { mutateAsync: handleFilesUpload } = useGetUploadUrls();

  function getUploadUrls(file) {
    const fileNames = [file.name];
    const extensions = [file.name.split(".").pop()];
    const path = "predictions";
    const n = 1;
    const payload = {
      fileNames,
      extensions,
      path,
      n: n.toString(),
    };

    handleFilesUpload(payload).then((res) => {
      dispatch(setLoading(true));
      const urls = res?.map((item) => item?.putSignedUrl);
      const slugs = res?.map((item) => item?.key);
      //perform upload of the files to these urls
      axios
        .put(urls[0], file, {
          headers: {
            "Content-Type": file.type,
          },
        })
        .then(() => {
          const payload = {
            profilePicture: slugs[0],
          };
          updateDetails(payload);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    });
  }

  const dispatch = useDispatch();
  const { selectedOrganisation } = props;
  const {
    adAccounts,
    currencies,
    isCancellingSub,
    isDeactivatingOrg,
    handleOrganisationRefresh,
    refreshFunnelStages,
    cancelSubscription,
    deactivateOrganisation,
  } = useOrganisationDetail(selectedOrganisation);

  const {
    filterAndOptions,
    selectedMetrices,
    presetData,
    preset,
    specificPresetData,
    isMakingPreset,
    tempKpis,
    onUpdateSelectedMetrices,
    handlePresetSelect,
    makePreset,
    updatePreset,
    setTempKpis,
  } = useInsights();

  const { mutateAsync: updateDetails, isLoading: isUpdatingDetails } =
    useEditorganisationDetails();

  const { hasUserPermission, isUserSubscribed } = useUserRole();

  const navigate = useNavigate();
  const [accountsToRemove, setAccountsToRemove] = useState([]);
  const [isDeactivate, setIsDeactivate] = useState("");
  const { organisationOnBoarded } = useAppState();
  const [orgName, setOrgName] = useState(selectedOrganisation?.name);
  const [currency, setCurrency] = useState(
    selectedOrganisation?.defaultCurrency?.toString()
  );

  const handleEditProfile = () => {
    const payload = { orgName, currency };
    updateDetails(payload);
  };

  return (
    <ContentContainer
      containerStyles={{
        maxHeight: `calc(100vh - 70px)`,
        padding: "0 20px",
      }}
    >
      <Stack direction="column" spacing={3} width={"100%"}>
        <h2 className="flex font-kalam text-2xl items-center">
          <div className="mr-2 outline-none">
            <FileUploadDnD
              uploaderStyles={{
                border: "none",
                cursor: "pointer",
                width: "fit-content",
              }}
              extensions={{
                "image/jpeg": [".jpg", ".jpeg"],
                "image/png": [".png"],
              }}
              onUpload={(files) => {
                getUploadUrls(files[0]);
              }}
            >
              <Avatar className="w-10 h-10">
                <AvatarImage
                  src={selectedOrganisation?.profilePicture}
                  alt="organisation_profile_picture"
                />
                <AvatarFallback className="w-10 h-10">
                  <Tooltip
                    title="Edit Organisation Photo"
                    placement="top"
                    arrow
                  >
                    <CircleUserRound className="w-full h-full" />
                  </Tooltip>
                </AvatarFallback>
              </Avatar>
            </FileUploadDnD>
          </div>
          {selectedOrganisation?.name}
        </h2>

        <div direction="column" spacing={1}>
          <div className="flex gap-2 flex-wrap w-full">
            {hasUserPermission(A_PERMISSIONS.UPDATE_CURRENCY) && (
              <div className="flex gap-2">
                <Button
                  variant="secondary"
                  onClick={() => navigate(APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT)}
                >
                  <CirclePlus className="mr-2 h-4 w-4" /> Connect Ad Account
                </Button>
                {isUserSubscribed() ? (
                  !isCancellingSub ? (
                    <Button variant="secondary" onClick={cancelSubscription}>
                      <RouteOff className="mr-2 h-4 w-4" /> Cancel subscription
                    </Button>
                  ) : (
                    <Button disabled>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Cancelling
                    </Button>
                  )
                ) : null}
              </div>
            )}

            {hasUserPermission(SA_PERMISSIONS.CONNECT_AD_ACOUNT) ? (
              <div direction="column">
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="secondary"
                    onClick={handleOrganisationRefresh}
                  >
                    <RotateCw className="mr-2 h-4 w-4" /> Refresh
                  </Button>

                  {organisationOnBoarded && (
                    <Button variant="secondary" onClick={refreshFunnelStages}>
                      <RefreshCw className="mr-2 h-4 w-4" /> Sync Insights
                    </Button>
                  )}
                </Stack>
              </div>
            ) : null}
          </div>

          {adAccounts.map((account) => (
            <Stack direction="row" sx={{ flexWrap: "wrap", gap: "30px" }}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  border: "1px solid #A1B0CC",
                  borderRadius: "6px",
                  minWidth: "300px",
                  maxWidth: "300px",
                }}
              >
                <Stack direction="column" sx={{ padding: "20px 16px" }}>
                  <Typography variant="subtitle2">{account?.name}</Typography>
                  <Typography variant="body2">
                    {`Account #: ${account?.adAccountId}`}
                  </Typography>
                </Stack>
                <Button>
                  <DeleteOutlineOutlinedIcon
                    sx={{ color: "#000" }}
                    onClick={() =>
                      dispatch(
                        setModal({
                          open: true,
                          type: MODALTYPES.REMOVE_AD_ACCOUNT_MODAL,
                          payload: {},
                        })
                      )
                    }
                  />
                </Button>
              </Stack>
            </Stack>
          ))}
        </div>

        <Separator />
        {presetData && presetData?.length && (
          <div className="grid grid-cols-1">
            <div className="flex items-center">
              Current default preset is:
              <Button
                className="flex gap-x-2 items-center"
                variant="link"
                onClick={() => {
                  dispatch(
                    setModal({
                      open: true,
                      type: MODALTYPES.PRESETS_MODAL,
                      payload: {},
                    })
                  );
                }}
              >
                {presetData[0].name}
                <Pencil className="h-4 w-4" />
              </Button>
            </div>
          </div>
        )}

        <div className="grid grid-cols-2 gap-8">
          <div className="items-center space-x-2">
            <Label htmlFor="name">Name:</Label>
            <Input
              id="name"
              onChange={(e) => setOrgName(e?.target?.value)}
              defaultValue={selectedOrganisation?.name}
              className="inline-block w-fit"
            />
          </div>
          <div className="items-center space-x-2 flex">
            <Label htmlFor="currency">Currency:</Label>
            <Select
              id="currency"
              disabled={!hasUserPermission(A_PERMISSIONS.UPDATE_CURRENCY)}
              onValueChange={(value) => setCurrency(value)}
              defaultValue={selectedOrganisation?.defaultCurrency?.toString()}
            >
              <SelectTrigger className="flex w-max gap-x-1">
                <SelectValue placeholder="Currency" />
              </SelectTrigger>
              <SelectContent>
                {currencies?.map((currency) => (
                  <SelectItem value={currency?.toString()}>
                    {currency}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {hasUserPermission(A_PERMISSIONS.UPDATE_CURRENCY) && (
            <div>
              {isUpdatingDetails ? (
                <Button disabled={true}>Saving...</Button>
              ) : (
                <Button
                  disabled={isUpdatingDetails}
                  variant="default"
                  className="py-4 px-8 w-fit"
                  size="sm"
                  onClick={handleEditProfile}
                >
                  Save Details
                </Button>
              )}
            </div>
          )}
        </div>
        {hasUserPermission(A_PERMISSIONS.UPDATE_CURRENCY) && (
          <div>
            <CardHeader className="pl-0">
              <CardTitle className="text-lg">Deactivate Account</CardTitle>
              <CardDescription className="text-xs">
                This will deactivate your organization, To Continue, Please Type
                "DEACTIVATE". <br />
                After deactivation, your organization will be deactivated and
                you will be logged out.
                <br />
                To continue, you have to log in again.
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-2 pl-0">
              <div className="flex gap-x-3 items-center">
                <Label className="text-gray-500 text-sm" htmlFor="current">
                  Type here :
                </Label>
                <Input
                  id="current"
                  onChange={(e, v) => setIsDeactivate(e?.target?.value)}
                  className="w-fit"
                />
              </div>
            </CardContent>
            <CardFooter className="pl-0">
              <Button
                disabled={isDeactivate !== "DEACTIVATE"}
                onClick={() => deactivateOrganisation()}
                className={`py-4 px-8 ${
                  isDeactivate !== "DEACTIVATE" && "cursor-not-allowed"
                }`}
                variant="destructive"
              >
                {isDeactivatingOrg ? "Deactivating..." : "Deactivate"}
              </Button>
            </CardFooter>
          </div>
        )}
      </Stack>

      <PresetModal
        preset={preset}
        handlePresetSelect={handlePresetSelect}
        presetData={presetData}
        selectedMetrices={selectedMetrices}
        specificPresetData={specificPresetData}
        filterAndOptions={filterAndOptions}
        onUpdateSelectedMetrices={onUpdateSelectedMetrices}
        updatePreset={updatePreset}
        isMakingPreset={isMakingPreset}
        makePreset={makePreset}
        tempKpis={tempKpis}
        setTempKpis={setTempKpis}
      />
    </ContentContainer>
  );
}
