import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Stack from "@mui/material/Stack";
import { storageUrlToCdn } from "utils/helper";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function RecommendationStepper(props) {
  const { topRecommendations, activeRecommendation, setActiveRecommendation } =
    props;
  const theme = useTheme();

  const maxSteps = topRecommendations?.length ?? 0;

  const handleStepChange = (step) => {
    setActiveRecommendation(step);
  };

  return (
    <Box
      sx={{
        padding: "16px 16px 0 16px",
        background: "#F9F9F9",
        borderRadius: "6px",
        width: "100%",
      }}
    >
      {
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeRecommendation}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          sx={{ backgroundColor: "transparent" }}
        >
          {topRecommendations?.map((step, index) => (
            <div key={index}>
              {Math.abs(activeRecommendation - index) <= 2 ? (
                <Stack direction="row">
                  <Box
                    component="img"
                    sx={{
                      height: 160,
                      display: "block",
                      maxWidth: 140,
                      overflow: "hidden",
                      width: "100%",
                      backgroundColor: "transparent",
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                    src={storageUrlToCdn(step?.media?.url)}
                    alt={step?.media?.category}
                  />
                  <Paper
                    square
                    elevation={0}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: 160,
                      p: "12px",
                      flex: 1,
                      backgroundColor: "transparent",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#7167FB",
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "500",
                      }}
                    >
                      Pro Tip:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#1C1E2080",
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      {step?.recommendation ?? ""}
                    </Typography>
                  </Paper>
                </Stack>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      }
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeRecommendation}
        sx={{
          backgroundColor: "transparent",
          justifyContent: "center",
          marginTop: "16px",
          ".MuiMobileStepper-dots": { gap: "8px" },
          ".MuiMobileStepper-dot": {
            width: "12px",
            height: "12px",
            background: "#1C1E2025",
            margin: "0",
          },
          ".MuiMobileStepper-dotActive": { backgroundColor: "#7167FB" },
        }}
      />
    </Box>
  );
}

export default RecommendationStepper;
