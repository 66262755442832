import { useMutation } from "@tanstack/react-query";
import { REPORTS } from "apis/apiEndPoints";
import {
  useGetBookmarkReports,
  useGetReports,
  useMakeShortUrl,
} from "apis/reportsApi";
import useAxios from "hooks/useAxios";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setUserBookmarkReports } from "redux/slices/app.slices";

export default function useReports() {
  const dispatch = useDispatch();
  const [reportsPaginatedData, setReportsPaginatedData] = useState([]);
  const [isViewBookmark, setIsViewBookmark] = useState(true);

  const currentPageRef = useRef(1);

  const { mutateAsync: getReportsData, isLoading: isReportLoading } =
    useGetReports();

  let { data: bookmarkedReports, isLoading: isBookmarkedReportLoading } =
    useGetBookmarkReports(true);

  const totalPagesRef = useRef(1);

  const hasNextPage = currentPageRef?.current < totalPagesRef?.current;

  const { isLoadMore } = useInfiniteScroll("content-container", hasNextPage);
  const [scrollPosition, setScrollPosition] = useState(0);

  const { axiosOrgInstance, axiosInstance } = useAxios();
  const changeVisibilityMutationFn = (data) => {
    axiosOrgInstance
      .patch(
        `${REPORTS.MAKE_REPORT_PUBLIC.replace(":id", data?.id)}?visibility=${
          data?.visibility
        }`
      )
      .then((res) => {
        toast("Report visibility changed");
        getReportsData(1).then((res) => {
          currentPageRef.current = 2;
          let data = res?.data;
          const _totalPages = res?.meta?.pagination?.totalPages;
          if (_totalPages) {
            totalPagesRef.current = _totalPages;
          }
          if (data) {
            setReportsPaginatedData(data);
          }
        });
        return res;
      })
      .catch((e) => toast("Error changing visibility"));
  };

  const funny = useMutation({
    mutationFn: changeVisibilityMutationFn,
  });

  const changeReportVisibility = (id, visibility) => {
    funny.mutate({ id, visibility });
  };

  const handleChangeViewToBookmark = () => {
    setIsViewBookmark((prev) => !prev);
  };

  const { mutate: generateShortUrl } = useMakeShortUrl();
  async function handleUrlShortener(longUrl, title, reportableId) {
    try {
      generateShortUrl({
        longUrl,
        title,
        reportableId,
      });
    } catch (error) {
      toast("Failed to generate report URL");
    }
  }

  useEffect(() => {
    if (reportsPaginatedData?.length === 0) {
      getReportsData(currentPageRef.current).then((res) => {
        currentPageRef.current = currentPageRef.current + 1;
        let data = res?.data;
        const _totalPages = res?.meta?.pagination?.totalPages;
        if (_totalPages) {
          totalPagesRef.current = _totalPages;
        }
        if (data) {
          setReportsPaginatedData(data);
        }
      });
    }
  }, [getReportsData, reportsPaginatedData?.length]);

  useEffect(() => {
    if (isLoadMore && !isViewBookmark) {
      const container = document.querySelector(`.content-container`);
      const currentScrollPosition = container ? container.scrollTop : 0;
      setScrollPosition(currentScrollPosition);
      if (currentPageRef.current > totalPagesRef.current) return;
      getReportsData(currentPageRef.current).then((res) => {
        currentPageRef.current = currentPageRef.current + 1;
        let data = res?.data;
        if (data) {
          setReportsPaginatedData((prev) => [...prev, ...data]);
          // After the API call is completed, restore the scroll position
          container.scrollTop = currentScrollPosition;
        }
      });
    }
  }, [getReportsData, isLoadMore]);

  useEffect(() => {
    if (bookmarkedReports && bookmarkedReports?.length > 0) {
      bookmarkedReports = bookmarkedReports.sort(
        (a, b) => new Date(a.createdAt.value) - new Date(b.createdAt.value)
      );
      dispatch(setUserBookmarkReports([]));
      bookmarkedReports.map((curr) => {
        dispatch(setUserBookmarkReports(curr.reportableId));
      });
    }
  }, [bookmarkedReports]);

  return {
    reportsData: reportsPaginatedData,
    bookmarkedReports,
    isReportLoading,
    isBookmarkedReportLoading,
    isViewBookmark,
    changeReportVisibility,
    handleUrlShortener,
    handleChangeViewToBookmark,
  };
}
