import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { COMMON } from "apis/apiEndPoints";
import {
  appleprovider,
  auth,
  facebookprovider,
  googleProvider,
  twitterprovider,
} from "config/firebase";
import { firebaseErrorCodes } from "helpers/firebase-error-codes.helper";
import useAppState from "hooks/useAppState";
import { toast } from "react-toastify";
import { setAppState } from "redux/slices/appState.slices";
import { useLoginUser } from "utils/CometChat";
import useAxios from "../../../hooks/useAxios";
import { setUserData } from "../../../redux/slices/auth.slice";
import { API_ROUTES, APP_ROUTES } from "../../../routes";
const LoginReducer = (state, action) => {
  switch (action.type) {
    case "SET_EMAIL":
      return {
        ...state,
        email: action.payload,
      };
    case "SET_PASSWORD":
      return {
        ...state,
        password: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  email: "",
  password: "",
  rememberMe: false,
};

export default function useLogin() {
  const [form, dispatch] = useReducer(LoginReducer, initialState);
  const [isValidated, setIsValidated] = useState(false);
  const navigate = useNavigate();
  const dispatchRedux = useDispatch();
  const { updateAppState } = useAppState();
  const { axiosInstance, axiosUserInstance } = useAxios();

  //login the user to cometchat sdk
  const { initUser } = useLoginUser();

  function handleChange(event) {
    const { name, value } = event.target;
    dispatch({
      type: `SET_${name.toUpperCase()}`,
      payload: value,
    });
  }

  const handleGoogleLogin = (res) => {
    axiosInstance
      .post(API_ROUTES.AUTH.GOOGLE_LOGIN, { tokenId: res?.credential })
      .then((res) => {
        dispatchRedux(setUserData(res));
        updateAppState();
      });
  };

  function handleFbLogin(response) {
    axiosInstance
      .post(API_ROUTES.AUTH.FACEBOOK_LOGIN, {
        accessToken: response?.accessToken,
      })
      .then((res) => {
        dispatchRedux(setUserData(res));

        updateAppState();
      });
  }

  const [firebase, setFirebase] = useState([]);
  const handleFirebaseGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      setFirebase(result.user);
    } catch (error) {
      toast("Please try again");
      console.error(error.message);
    }
  };

  const handleFirebaseFacebookLogin = async () => {
    try {
      const result = await signInWithPopup(auth, facebookprovider);
      setFirebase(result.user);
    } catch (error) {
      toast("Please try again");
      console.error(error.message);
    }
  };

  const handleFirebaseTwitterLogin = async () => {
    try {
      const result = await signInWithPopup(auth, twitterprovider);
      setFirebase(result.user);
    } catch (error) {
      toast("Please try again");
      console.error(error.message);
    }
  };

  const handleFirebaseAppleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, appleprovider);
      setFirebase(result.user);
    } catch (error) {
      toast("Please try again");
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (!firebase?.uid) return;
    else {
      axiosInstance
        .post(API_ROUTES.AUTH.FIREBASE_GOOGLE_LOGIN, {
          uid: firebase?.uid,
        })
        .then((res) => {
          dispatchRedux(setUserData(res));
          axiosInstance
            .get(COMMON.APPLICATION_STATUS, {
              headers: {
                Authorization: `Bearer ${res?.tokens?.user?.token}`,
              },
            })
            .then((appStatus) => {
              dispatchRedux(setAppState(appStatus));
              initUser();
              // if (res?.user?.questions) {
              //   if (!res?.user?.accountType) {
              //     navigate(APP_ROUTES.AUTH.ACCOUNT_TYPE);
              //   } else

              if (!res?.user?.userName) {
                navigate(APP_ROUTES.AUTH.SET_USER_NAME);
              } else {
                navigate(APP_ROUTES.DASHBOARD);
              }
              // } else {
              //   navigate(APP_ROUTES.AUTH.SELECT_ROLE);
              // }
            });
        })
        .catch((err) => {
          toast("Sorry try again");
        });
    }
  }, [firebase?.uid]);

  const handleHomeRoute = () => {
    navigate(APP_ROUTES.AUTH.LOGIN);
  };

  const handleSignUpRoute = () => {
    navigate(APP_ROUTES.AUTH.SIGNUP);
  };

  const handleForgotPasswordRoute = () => {
    navigate(APP_ROUTES.AUTH.FORGOT_PASSWORD);
  };

  function handleSignup() {
    signInWithEmailAndPassword(auth, form.email, form.password)
      .then((userCredentials) => {
        const user = userCredentials.user;
        axiosInstance
          .post(API_ROUTES.AUTH.FIREBASE_GOOGLE_LOGIN, {
            uid: user?.uid,
          })
          .then((res) => {
            toast("Login successful!");
            dispatchRedux(setUserData(res));
            axiosInstance
              .get(COMMON.APPLICATION_STATUS, {
                headers: {
                  Authorization: `Bearer ${res?.tokens?.user?.token}`,
                },
              })
              .then((appStatus) => {
                dispatchRedux(setAppState(appStatus));
                initUser();
                // if (res?.user?.questions) {
                // if (!res?.user?.accountType) {
                //   navigate(APP_ROUTES.AUTH.ACCOUNT_TYPE);
                // } else
                if (!res?.user?.userName) {
                  navigate(APP_ROUTES.AUTH.SET_USER_NAME);
                } else {
                  navigate(APP_ROUTES.DASHBOARD);
                }
                // } else {
                //   navigate(APP_ROUTES.AUTH.SELECT_ROLE);
                // }
              });
          })
          .catch((err) => {
            toast("Sorry try again");
          });
      })
      .catch((err) => {
        if (firebaseErrorCodes[err.code]) {
          toast(firebaseErrorCodes[err.code]);
        } else {
          toast("Something went wrong");
        }
      });
  }

  const validateForm = useCallback(() => {
    if (form.email === "" || form.password === "") return false;
    if (form.email.length < 5 || form.password.length < 8) return false;
    if (!form.email.includes("@") || !form.email.includes(".")) return false;
    if (
      !form.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    )
      if (!form.terms_conditions) return false;
    return true;
  }, [form.email, form.password, form.terms_conditions]);

  function handleSubmit(event) {
    event.preventDefault();
    if (validateForm()) {
      handleSignup();
    } else {
    }
  }

  useEffect(() => {
    let _validated = validateForm();
    setIsValidated(_validated);
  }, [form, validateForm]);

  return {
    form,
    handleChange,
    handleSubmit,
    isValidated,
    handleGoogleLogin,
    handleFbLogin,
    handleFirebaseGoogleLogin,
    handleFirebaseFacebookLogin,
    handleFirebaseTwitterLogin,
    handleFirebaseAppleLogin,
    handleHomeRoute,
    handleSignUpRoute,
    handleForgotPasswordRoute,
  };
}
