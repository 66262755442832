import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  modalInitState,
  MODALTYPES,
  setModal,
} from "../../../redux/slices/modal.slices";
import { AutoCompleteWithTags, InputWithLabel } from "../../common/molecules";
import useImageTagging from "./useImageTagging";
import { Tags } from "../../common/atoms";
import { Close } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { storageUrlToCdn } from "utils/helper";
import AdvanceTagging from "components/common/tagging";
import EditIcon from "@mui/icons-material/Edit";

const formFieldStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "100%",
};

const labelStyles = { fontSize: "16px", fontWeight: "400px", color: "#1C1E20" };

export default function ImageTaggingModal(props) {
  const { reportableId = "" } = useParams();
  const { postOnboarding = false } = props;
  const { open, type, payload } = useSelector((state) => state?.modal);
  const [grpName, setGrpName] = useState();
  const [advanceTagging, setAdvanceTagging] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  const handleEditClick = () => {
    setIsEditing(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const handleOutsideClick = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const predictionConfigs = { reportableId, mediaId: payload?.mediaId };
  const {
    state,
    handleTagsChange,
    handleSwitch,
    handleInputChange,
    handlePrimaryAction,
    aiTagsList,
    aiTagApplied,
    isAdvanceTaggingActive,
  } = useImageTagging(payload, postOnboarding, predictionConfigs);

  const {
    primaryBtnText = "Save",
    secondaryBtnText = null,
    dangerMode = false,
    imageUrl = "",
  } = payload || {};

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  function handleClose() {
    setGrpName(null);
    if (postOnboarding) {
      dispatch(setModal(modalInitState));
    }
  }
  function handleSave() {
    handlePrimaryAction &&
      handlePrimaryAction(grpName).then(() => {
        handleClose();
      });
  }

  useEffect(() => {
    if (isAdvanceTaggingActive) {
      setAdvanceTagging(true);
    }
  }, [isAdvanceTaggingActive]);

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={false}
      open={open && type === MODALTYPES.IMAGE_TAGGING_MODAL}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div
        style={{
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
          width: "100%",
          minWidth: "962px",
          maxWidth: "962px",
          overflow: "hidden",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "50%",
            }}
          >
            {isEditing ? (
              <TextField
                inputRef={inputRef}
                defaultValue={payload?.groupName}
                onChange={(e) => setGrpName(e.target.value)}
                variant="standard"
                fullWidth
              />
            ) : (
              <Typography id="modal-title" variant="h2" className="truncate">
                {grpName ? grpName : payload?.groupName ?? ""}
              </Typography>
            )}
            {!isEditing && (
              <IconButton onClick={handleEditClick}>
                <EditIcon sx={{ height: "1.25rem", width: "1.25rem" }} />
              </IconButton>
            )}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="w-1/2 px-4"
          >
            <Stack direction="row" spacing={4} gap={12} alignItems="center">
              <div style={formFieldStyles}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Switch
                    name="advanceTaggingSwitch"
                    disabled={!isAdvanceTaggingActive}
                    value={advanceTagging}
                    checked={advanceTagging}
                    onChange={() => setAdvanceTagging(!advanceTagging)}
                  />
                  <Typography sx={{ fontSize: "1rem" }}>
                    Advance Tagging {advanceTagging ? "Enabled" : "Disabled"}
                  </Typography>
                </Stack>
              </div>
            </Stack>
            {postOnboarding && (
              <Close sx={{ cursor: "pointer" }} onClick={handleClose} />
            )}
          </Stack>
        </Stack>

        <div style={{ display: "flex", gap: "1rem", overflow: "hidden" }}>
          <div className="w-1/2 flex flex-col items-center justify-center gap-4 p-4 ">
            <div
              className="tagging-card"
              style={{
                width: "100%",
                height: "auto",
                padding: "16px",
                boxShadow: "0px  10px 10px 10px rgba(0, 0, 0, 0.12)",
                borderRadius: "12px",
                gap: "1rem",
              }}
            >
              <img
                src={storageUrlToCdn(imageUrl)}
                alt="lorem"
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "12px",
                }}
              />
            </div>
            {!postOnboarding && (
              <>
                <Typography sx={{ fontSize: "1rem" }} marginTop={"16px"}>
                  AI Tags
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  flexWrap="wrap"
                  gap="4px"
                >
                  {aiTagsList?.map((tag, index) => {
                    const modTag = {
                      id: index,
                      name: tag,
                    };
                    return (
                      <Tags
                        key={index}
                        tag={modTag}
                        sx={{
                          marginBottom: "4px!important",
                        }}
                      />
                    );
                  })}
                  {!aiTagApplied && (
                    <Stack>
                      <Typography variant="h3" margin={"16px 0"}>
                        Preparing AI tags
                      </Typography>
                      <CircularProgress />
                    </Stack>
                  )}
                </Stack>
              </>
            )}
          </div>

          <div
            className="w-1/2 flex flex-col items-center gap-4 overflow-y-scroll p-4 pt-0"
            // style={{
            //   display: "flex",
            //   flexDirection: "column",
            //   width: "488px",
            //   gap: "1rem",
            //   overflow: "scroll",
            //   padding: "1rem",
            //   paddingTop: "0",
            // }}
          >
            {advanceTagging ? (
              <>
                <AdvanceTagging
                  state={state}
                  handleTagsChange={handleTagsChange}
                  isImageModal={true}
                  formFieldStyles={formFieldStyles}
                  labelStyles={labelStyles}
                  handleSwitch={handleSwitch}
                />
              </>
            ) : (
              <>
                <AutoCompleteWithTags
                  name="combineTags"
                  label="Tags"
                  placeholder="Ex: Eyewear, Cool, Sky"
                  values={state?.combineTags}
                  onTagUpdate={(tags) =>
                    handleTagsChange({ name: "combineTags", value: tags })
                  }
                />
              </>
            )}
          </div>
        </div>

        <DialogActions
          sx={{
            display: "flex",
            width: "100%",
            padding: "16px 24px 0 16px",
            gap: "40px",
            justifyContent: "space-between",
          }}
        >
          <div className="py-4 w-1/2">
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "1rem",
                color: "grey",
              }}
            >
              (Kindly press ENTER/RETURN after entering multiple comma separated
              or individual tag to make them selected)
            </Typography>
          </div>
          {!!secondaryBtnText && (
            <Button
              sx={{
                textTransform: "capitalize",
                fontWeight: "500",
              }}
              variant="text"
              color={dangerMode ? "error" : "primary"}
              autoFocus
              onClick={handleClose}
            >
              {secondaryBtnText}
            </Button>
          )}
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              textTransform: "capitalize",
              fontWeight: "400",
            }}
            color={dangerMode ? "error" : "primary"}
            onClick={handleSave}
          >
            {primaryBtnText}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
