import React, { useEffect } from "react";
import Box from "@mui/material/Box";

const PrivateContainer = (props) => {
  const { children, bodyStyles, activePage } = props;

  useEffect(() => {
    if (activePage) {
      localStorage.setItem("activePage", activePage?.toString());
    }

    return () => {
      localStorage.removeItem("activePage");
    };
  }, [activePage]);
  return (
    <Box
      style={{
        flex: 1,
        padding: "24px 50px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100vh",
        overflowY: "auto",
        ...bodyStyles,
      }}
    >
      {children}
    </Box>
  );
};

export default PrivateContainer;
