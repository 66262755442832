import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAppState from "../../hooks/useAppState";

import useAuth from "../../hooks/useAuth";
import { APP_ROUTES } from "../../routes";
import { AuthScreens } from "../../screens/Auth";
import {
  OnboardingScreens,
  OnboardingStatusesScreens,
} from "../../screens/Onboarding";

import TaggingScreens from "../../screens/TaggingProcess";

import { useSelector } from "react-redux";
import SettingsScreen from "../../screens/Settings/SettingsScreen";
import Dashboard from "screens/Auth/LoginScreen/dashboard/Dashboard";
import useUserRole from "hooks/useUserRole";
import { ACTIVE_FEATURES } from "config/roles";
import Unauthorized from "screens/Errors/403/Unauthorized";
import NotFound from "screens/Errors/404/NotFound";
import Reports from "screens/Reports";

const PUBLIC_ROUTES_SCREENS = {
  [APP_ROUTES.LANDING_PAGE]: <AuthScreens.LoginScreen />,
  [APP_ROUTES.AUTH.LOGIN]: <AuthScreens.LoginScreen />,
  [APP_ROUTES.AUTH.FORGOT_PASSWORD]: <AuthScreens.ForgetPasswordScreen />,
  [APP_ROUTES.UNAUTHORIZED]: <Unauthorized />,
  [APP_ROUTES.ALL]: <NotFound />,
};

const USER_ROUTES_SCREENS = {
  [APP_ROUTES.DASHBOARD]: <Dashboard />,
  [APP_ROUTES.AUTH.SELECT_ROLE]: <AuthScreens.RoleSelectScreen />,

  //adding them extra
  [APP_ROUTES.SETTING]: <SettingsScreen />,
  [APP_ROUTES.AUTH.ADD_ORGANIZATION]: <AuthScreens.AddOrganisationScreen />,
  [APP_ROUTES.AUTH.ORG_SELECTION_SCREEN]: <AuthScreens.OrgSelectionScreen />,
  [APP_ROUTES.ONBOARDING.LOADING]: <OnboardingStatusesScreens.LoadingScreen />,
  [APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT]: <AuthScreens.AdAccountScreen />,
  [APP_ROUTES.AUTH.MULTIPLE_ADD_ACCOUNT]: (
    <AuthScreens.MultipleAdAccountScreen />
  ),
  [APP_ROUTES.REPORTS.default]: <Reports />,

  //these two flows are originally but gone during new singup flow user onboarding
  // [APP_ROUTES.AUTH.ADD_ORGANIZATION]: <AuthScreens.AddOrganisationScreen />, //both
  // [APP_ROUTES.AUTH.ORG_SELECTION_SCREEN]: <AuthScreens.OrgSelectionScreen />,
};

const ORG_ROUTES_SCREENS = {
  // [APP_ROUTES.SETTINGS]: <SettingsScreen />,
  // [APP_ROUTES.AUTH.ADD_ORGANIZATION]: <AuthScreens.AddOrganisationScreen />,
  // [APP_ROUTES.AUTH.ORG_SELECTION_SCREEN]: <AuthScreens.OrgSelectionScreen />,
  [APP_ROUTES.ONBOARDING.LOADING]: <OnboardingStatusesScreens.LoadingScreen />,
  // [APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT]: <AuthScreens.AdAccountScreen />,
  // [APP_ROUTES.AUTH.MULTIPLE_ADD_ACCOUNT]: (
  // <AuthScreens.MultipleAdAccountScreen />
  // ),
  [APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS]: (
    <OnboardingScreens.CreativeGroupsScreen />
  ),
  [APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS_CREATIVES]: (
    <OnboardingScreens.GroupContentScreen />
  ),
  [APP_ROUTES.DASHBOARD]: <Dashboard />,

  [APP_ROUTES.TAGGING.TEXT_TAGGING]: <TaggingScreens.TextTaggingScreen />,
  [APP_ROUTES.TAGGING.AUDIENCE_TAGGING]: (
    <TaggingScreens.AudienceTaggingScreen />
  ),
};

const ORG_FEATURE_SCREENS = {
  [APP_ROUTES.INSIGHTS.default]: ACTIVE_FEATURES.INSIGHTS,
  [APP_ROUTES.INSIGHTS.default + "/"]: ACTIVE_FEATURES.INSIGHTS,
  [APP_ROUTES.INSIGHTS.COMPARE]: ACTIVE_FEATURES.INSIGHTS,
  [APP_ROUTES.INSIGHTS.COMPARE + "/"]: ACTIVE_FEATURES.INSIGHTS,
  [APP_ROUTES.INSIGHTS.COMPARE_DATE]: ACTIVE_FEATURES.COMPARE,
  [APP_ROUTES.INSIGHTS.COMPARE_DATE + "/"]: ACTIVE_FEATURES.COMPARE,
  [APP_ROUTES.INSIGHTS.AD_BREAKDOWN]: ACTIVE_FEATURES.INSIGHTS,
  [APP_ROUTES.INSIGHTS.AD_BREAKDOWN + "/"]: ACTIVE_FEATURES.INSIGHTS,
  [APP_ROUTES.INSIGHTS.CREATIVE_INSIGHT]: ACTIVE_FEATURES.INSIGHTS,
  [APP_ROUTES.INSIGHTS.CREATIVE_MOMENTUM_FATIGUE]:
    ACTIVE_FEATURES.CREATIVE_MOMENTUM_FATIGUE,
  [APP_ROUTES.INSIGHTS.CREATIVE_MOMENTUM_FATIGUE + "/"]:
    ACTIVE_FEATURES.CREATIVE_MOMENTUM_FATIGUE,
  [APP_ROUTES.INSIGHTS.CREATIVE_INSIGHT + "/"]: ACTIVE_FEATURES.INSIGHTS,
  [APP_ROUTES.PREDICTION.default]: ACTIVE_FEATURES.PREDICTION,
  [APP_ROUTES.PREDICTION.default + "/"]: ACTIVE_FEATURES.PREDICTION,
  [APP_ROUTES.GENERATION.default]: ACTIVE_FEATURES.GENERATION,
  [APP_ROUTES.GENERATION.default + "/"]: ACTIVE_FEATURES.GENERATION,
  [APP_ROUTES.GENERATION.GENERATION_STEP_2]: ACTIVE_FEATURES.GENERATION,
  [APP_ROUTES.GENERATION.GENERATION_STEP_2 + "/"]: ACTIVE_FEATURES.GENERATION,
  [APP_ROUTES.GENERATION.GENERATION_STEP_3]: ACTIVE_FEATURES.GENERATION,
  [APP_ROUTES.GENERATION.GENERATION_STEP_3 + "/"]: ACTIVE_FEATURES.GENERATION,
  [APP_ROUTES.PREDICTION.PREDICTION_STEP_2]: ACTIVE_FEATURES.PREDICTION,
  [APP_ROUTES.PREDICTION.PREDICTION_STEP_2 + "/"]: ACTIVE_FEATURES.PREDICTION,
  [APP_ROUTES.PREDICTION.PREDICTION_STEP_3]: ACTIVE_FEATURES.PREDICTION,
  [APP_ROUTES.PREDICTION.PREDICTION_STEP_3 + "/"]: ACTIVE_FEATURES.PREDICTION,
  [APP_ROUTES.RECOMMENDATION.default]: ACTIVE_FEATURES.RECOMMENDATION,
  [APP_ROUTES.RECOMMENDATION.CREATIVE_RECOMMENDATION]:
    ACTIVE_FEATURES.RECOMMENDATION,
  [APP_ROUTES.RECOMMENDATION.VIDEO_RECOMMENDATION]:
    ACTIVE_FEATURES.RECOMMENDATION,
  [APP_ROUTES.RECOMMENDATION.CREATIVE_RECOMMENDATION + "/"]:
    ACTIVE_FEATURES.RECOMMENDATION,
  [APP_ROUTES.RECOMMENDATION.default + "/"]: ACTIVE_FEATURES.RECOMMENDATION,
  [APP_ROUTES.RECOMMENDATION.VIDEO_RECOMMENDATION + "/"]:
    ACTIVE_FEATURES.RECOMMENDATION,
};

export function CustomRoute({ children }) {
  const location = useLocation();
  const { isUserAuthenticated, isOrganisationAuthenticated } = useAuth();
  const { organisationOnBoarded: ORG_OBOARDED } = useAppState();
  const { hasUserActiveFeature } = useUserRole();
  const { appState } = useSelector((state) => state?.appState);
  const { auth } = useSelector((state) => state);
  let NONE_AUTHENTICATED = !isUserAuthenticated && !isOrganisationAuthenticated;
  let ONLY_USER_AUTHENTICATED =
    isUserAuthenticated && !isOrganisationAuthenticated;
  let BOTH_AUTHENTICATED = isUserAuthenticated && isOrganisationAuthenticated;

  //get keys of all the routes
  let Public_Routes = Object.keys(PUBLIC_ROUTES_SCREENS);
  let User_Onboarding_Routes = Object.keys(USER_ROUTES_SCREENS);
  let Org_Onboarding_Routes = Object.keys(ORG_ROUTES_SCREENS);
  let Org_feature_screens = Object.keys(ORG_FEATURE_SCREENS);

  function resumeUserOnboarding(currentRoute, children) {
    return children;
    // if (!appState?.isUserTypeDefined) {
    //   return currentRoute === APP_ROUTES.AUTH.SELECT_ROLE ? (
    //     children
    //   ) : (
    //     <Navigate to={APP_ROUTES.AUTH.SELECT_ROLE} />
    //   );
    // } else {
    //   return children;
    // }
  }

  function resumeOrgOnboarding(currentRoute, children) {
    if (appState?.isOrganizationCustomAudiencesTaggingDone) {
      if (
        !(
          appState?.isOrganizationImagesManualGroupingDone &&
          appState?.isOrganizationVideosManualGroupingDone
        )
      ) {
        return currentRoute === APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS ? (
          children
        ) : (
          <Navigate to={APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS} />
        );
      }

      if (!appState?.isOrganizationTextsTaggingDone) {
        return currentRoute === APP_ROUTES.TAGGING.TEXT_TAGGING ? (
          children
        ) : (
          <Navigate to={APP_ROUTES.TAGGING.TEXT_TAGGING} />
        );
      }

      return <Navigate to={APP_ROUTES.DASHBOARD} />;
    }

    if (appState?.isOrganizationTextsTaggingDone) {
      return currentRoute === APP_ROUTES.TAGGING.AUDIENCE_TAGGING ? (
        children
      ) : (
        <Navigate to={APP_ROUTES.TAGGING.AUDIENCE_TAGGING} />
      );
    } else if (
      appState?.isOrganizationImagesManualGroupingDone &&
      appState?.isOrganizationVideosManualGroupingDone
    ) {
      return currentRoute === APP_ROUTES.TAGGING.TEXT_TAGGING ? (
        children
      ) : (
        <Navigate to={APP_ROUTES.TAGGING.TEXT_TAGGING} />
      );
    } else if (
      appState?.isOrganizationImagesAiGroupingDone ||
      appState?.isOrganizationVideosAiGroupingDone
    ) {
      return currentRoute === APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS ? (
        children
      ) : (
        <Navigate to={APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS} />
      );
    } else if (appState?.isPlatformAddedToOrganization) {
      return currentRoute === APP_ROUTES.ONBOARDING.LOADING ? (
        children
      ) : (
        <Navigate to={APP_ROUTES.ONBOARDING.LOADING} />
      );
    } else {
      if (currentRoute === APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT) {
        if (auth?.addAccount?.accessToken) {
          return <Navigate to={APP_ROUTES.AUTH.MULTIPLE_ADD_ACCOUNT} />;
        } else {
          return children;
        }
      } else if (currentRoute === APP_ROUTES.AUTH.MULTIPLE_ADD_ACCOUNT) {
        return children;
      } else if (
        currentRoute === APP_ROUTES.PLATFORMS.PLATFORM_CONNECT_ACCOUNTS
      ) {
        return children;
      }
      return <Navigate to={APP_ROUTES.DASHBOARD} />;
    }
  }

  if (NONE_AUTHENTICATED) {
    return Public_Routes.includes(location.pathname) ? (
      children
    ) : (
      <Navigate to={APP_ROUTES.AUTH.LOGIN} />
    );
  } else if (ONLY_USER_AUTHENTICATED) {
    if (
      location.pathname === APP_ROUTES.LANDING_PAGE ||
      Org_Onboarding_Routes.includes(location.pathname) ||
      location.pathname === APP_ROUTES.AUTH.LEADIE ||
      location.pathname === APP_ROUTES.AUTH.LOGIN ||
      location.pathname === APP_ROUTES.AUTH.SIGNUP ||
      location.pathname === APP_ROUTES.AUTH.SIGNUP + "/" ||
      location.pathname === APP_ROUTES.UPGRADE_PLAN ||
      location.pathname === APP_ROUTES.UPGRADE_PLAN + "/" ||
      Org_feature_screens.includes(location.pathname)
    ) {
      if (Org_feature_screens.includes(location.pathname)) {
        return <Navigate to={APP_ROUTES.UNAUTHORIZED} />;
      }
      return <Navigate to={APP_ROUTES.DASHBOARD} />;
    }
    return User_Onboarding_Routes.includes(location.pathname)
      ? resumeUserOnboarding(location.pathname, children)
      : children;
  } else if (BOTH_AUTHENTICATED) {
    if (
      location.pathname === APP_ROUTES.DASHBOARD ||
      location.pathname === APP_ROUTES.REPORTS.default
    ) {
      return children;
    }
    if (
      location.pathname === APP_ROUTES.AUTH.LOGIN ||
      location.pathname === APP_ROUTES.AUTH.SIGNUP
    ) {
      return <Navigate to={APP_ROUTES.DASHBOARD} />;
    }
    if (location.pathname === APP_ROUTES.LANDING_PAGE) {
      return <Navigate to={APP_ROUTES.DASHBOARD} />;
    }
    if (ORG_OBOARDED) {
      //check if feature available in pack
      if (Org_feature_screens.includes(location.pathname)) {
        if (hasUserActiveFeature(ORG_FEATURE_SCREENS[location.pathname])) {
          return children;
        }
        // toast("You do not have access to this feature");
        return <Navigate to={APP_ROUTES.UNAUTHORIZED} />;
      }
      if (Org_Onboarding_Routes.includes(location.pathname)) {
        return resumeOrgOnboarding(location.pathname, children);
      }
      return children;
    } else {
      if (location.pathname === APP_ROUTES.LANDING_PAGE) {
        return <Navigate to={APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS} />;
      }
      if (Org_feature_screens.includes(location.pathname)) {
        // toast("You do not have access to this feature");
        return <Navigate to={APP_ROUTES.UNAUTHORIZED} />;
      }
      if (
        location.pathname === APP_ROUTES.SETTING ||
        location.pathname === APP_ROUTES.ADD_AD_ACCOUNT ||
        location.pathname === APP_ROUTES.AUTH.CONNECT_ADD_ACCOUNT ||
        /\/platforms\/[a-zA-Z0-9]+\/accounts$/.test(location.pathname) ||
        location.pathname === APP_ROUTES.AUTH.ADD_ORGANIZATION ||
        location.pathname === APP_ROUTES.AUTH.ORG_SELECTION_SCREEN ||
        location.pathname === APP_ROUTES.UPGRADE_PLAN
      ) {
        return children;
      }
      return Org_Onboarding_Routes.includes(location.pathname) ? (
        resumeOrgOnboarding(location.pathname, children)
      ) : (
        <Navigate to={location.pathname} />
      );
    }
  }
  return <Navigate to={APP_ROUTES.AUTH.LOGIN} />;
}
