import React from "react";

const adjustColorBrightness = (color, amount) => {
  let usePound = false;

  if (color[0] === "#") {
    color = color.slice(1);
    usePound = true;
  }

  let num = parseInt(color, 16);
  let r = (num >> 16) + amount;
  let g = ((num >> 8) & 0x00ff) + amount;
  let b = (num & 0x0000ff) + amount;

  r = r > 255 ? 255 : r < 0 ? 0 : r;
  g = g > 255 ? 255 : g < 0 ? 0 : g;
  b = b > 255 ? 255 : b < 0 ? 0 : b;

  return (
    (usePound ? "#" : "") +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
  );
};

export const Gauge = ({
  value,
  size = "medium",
  showValue = true,
  color = "#83D882", // Default color
  bgcolor = "#333", // Default background color
  label = "Hook score",
  unit = "%",
}) => {
  const radius = 45; // Radius of the semicircle
  const circumference = Math.PI * radius; // Circumference of the semicircle
  const valueInCircumference = (value / 100) * circumference; // Filled arc length
  const strokeDasharray = `${valueInCircumference} ${circumference}`; // Stroke pattern

  const sizes = {
    small: {
      width: "120",
      height: "60",
      textSize: "text-sm",
    },
    medium: {
      width: "240",
      height: "120",
      textSize: "text-xl",
    },
    large: {
      width: "480",
      height: "240",
      textSize: "text-3xl",
    },
  };

  // Adjust the color if it's too light
  const adjustedColor = adjustColorBrightness(color, -50);

  return (
    <div className="flex flex-col items-center justify-center relative bg-black text-white rounded-lg shadow-lg">
      <svg
        className="-mt-3"
        fill="none"
        height={sizes[size].height}
        width={sizes[size].width}
        viewBox="0 0 100 50"
      >
        <path
          d={`M 10,50 A 40,40 0 0,1 90,50`}
          stroke={bgcolor}
          strokeWidth="3" // Reduced stroke width
          fill="none"
        />
        <path
          className=""
          d={`M 10,50 A 40,40 0 0,1 90,50`}
          stroke={adjustedColor}
          strokeWidth="3" // Reduced stroke width
          fill="none"
          strokeDasharray={strokeDasharray}
          strokeLinecap="round"
        />
      </svg>
      {showValue && (
        <div className="absolute top-1/2 transform flex flex-col items-center">
          <div className="flex gap-x-1">
            <p className={`font-bold text-xl ${sizes[size].textSize}`}>
              {value}
            </p>
            <p className={`font-bold text-xl ${sizes[size].textSize}`}>
              {unit}
            </p>
          </div>
          <p className="text-base">{label}</p>
        </div>
      )}
    </div>
  );
};
