export const API_ROUTES = {
  AUTH: {
    GOOGLE_LOGIN: "authentication/google/login",
    FACEBOOK_LOGIN: "authentication/facebook/login",
    CREATE_ORG: "/organization",
    GET_AD_ACCOUNTS: "/facebook/:facebookUserId/ad-accounts",
    ADD_PLATFORMS: "/organization/platform/:platformId/connect",
    CREATE_NEW_SOURCE: "/organization/platform/:platformId/oauth/sources",
    ADD_NEW_AD_ACCOUNT:
      "/organization/platform/:platformId/oauth-source/:oAuthSourceId/addAccount/:accountId",
    DELETE_ACCOUNT:
      "/organization/platform/:platformId/oauth-source/:oAuthSourceId/addAccount/:accountId",
    GET_PLATFORM_OAUTH_SOURCES:
      "/organization/platform/:platformId/oauth/sources",
    AUTHENTICATE_PLATFORM_OAUTH_SOURCES:
      "/organization/platform/:platformId/connect/oauth/:oAuthSourceId/authenticate",
    SAVE_PLATFORM_OAUTH_SOURCES_ACCOUNTS:
      "/organization/platform/:platformId/oauth-source/:oAuthSourceId/accounts/save",
    GET_PLATFORM: "/platform",
    LIST_ORG: "organization?page=1&limit=20",
    GET_ORG_TOKEN: "auth-token/organization/:organizationId",
    CONNECT_AD_ACCOUNTS: "/ad-account/many",
    GET_FB_ADD_ID: "facebook/app-id",
    ADD_INTERNAL_PLATFORM_CONNECT_CALLBACK:
      "/organization/add-platform/internal/connect",
    FIREBASE_GOOGLE_LOGIN: "authentication/firebase/login",
  },

  USER: {
    GET_USER: "/user/profile",
    UPDATE_USER: "user/:userId",
    DEACTIVATE_USER: "/user/deactivate",
    REGISTER_DEVICE: "/user/devices",
    CHECK_USER_EXISTS: "/user/exists/:email",
  },
  ONBOARDING: {
    LIST_ORG_GROUPS: "group/organization", //groupEntity=1(image)/2(text)/3(video)&isManualGroupingDone=0(false)/1(true)
    LIST_GROUP_ENTITY: "group/:groupId/entity",
    LIST_CUSTOM_AUDIENCE: "/custom-audience",
    UPDATE_GROUP_IMAGES: "/group/:groupId/entity",
    UPDATE_GROUP_ENTITY: "/group/:groupId/entity",
    MERGE_GROUPS: "/group/merge",
    SKIP_MANUAL_GROUPING: "/group/skip-manual-grouping",
    UPDATE_MANUAL_GROUPING: "/group/:groupId/update-manual-grouping",
    SKIP_MANUAL_TAGS: "/group/skip-manual-tagging",
  },
  COMMON: {
    APPLICATION_STATUS: "application/status",
    LIST_ORG_GROUPS: "/group/organization", //groupEntity=1(image)/2(text)/3(video)
    LIST_ORG_GROUPS_DROPDOWN: "/group/organization?isDropDownView=1", //groupEntity=1(image)/2(text)/3(video)
    GET_AVAILABLE_CURRENCIES: "/commons/currencies",
    GET_ORG_MODEL_KPIS: "/organization/modelKPIs",
  },
  TAGGING: {
    CREATE_IMAGE_GROUP_TAGS: "/group/:groupId/tag/image",
    CREATE_VIDEO_GROUP_TAGS: "/group/:groupId/tag/video",
    CREATE_TEXT_GROUP_TAGS: "/group/:groupId/tag/text",
    CREATE_CUSTOM_AUDIENCE_TAGS: "/group/:groupId/tag/custom-audience",
    GET_ORGANIZATION_TAGS: "/organization/tag/search", // ?search=tag
    GET_IMAGE_AI_TAGS: "/group/:groupId/tag/ai/image",
    GET_VIDEO_AI_TAGS: "/group/:groupId/tag/ai/video", //?segmentType=1
    GET_TEXT_AI_TAGS: "/group/:groupId/tag/ai/text",
  },
  UNGROUPED: {
    LIST_UNGROUPED_ENTITIES: "/ungrouped/entities", //groupEntity=1(image)/2(text)/3(video)
    CREATE_GROUP: "/group",
  },
  INSIGHTS: {
    INSIGHTS_FILTERS: "/insight/filters",
    GET_ORG_LEVEL_INSIGHTS: "/insight/organization",
    GROUP_LEVEL_INSIGHTS: "/insight/group",
  },
  GET_GROUP_TAGS: {
    GET_IMAGE_GROUP_TAGS: "/group/:groupId/tag/image",
    GET_VIDEO_GROUP_TAGS: "/group/:groupId/tag/video",
    GET_TEXT_GROUP_TAGS: "/group/:groupId/tag/text",
    GET_CUSTOM_AUDIENCE_TAGS: "/group/:groupId/tag/custom-audience",
  },
  ORGANISATION: {
    ADD_USER: "/organization/add-user",
    REMOVE_USER: "/organization/remove-user",
    GET_AD_ACCOUNTS: "/organization/ad-accounts",
    GET_USERS: "/organization/users",
    UPDATE_DEFAULT_CURRENCY: "/organization/currency/:id/:new",
    REMOVE_AD_ACCOUNTS: "/organization/remove-ad-accounts",
    SMART_FILTERS: "/commons/smartFilters",
    AUDIENCE_SEGMENT: "organization/audience-segment",
    EDIT_DETAILS: "/organization",
    ORGANIZAITON_FOLDERS: "/organization/folder",
  },

  BRAND_KIT: {
    BRAND_LOGO: {
      GET: "/brand-logo",
      CREATE: "/brand-logo",
      DELETE: "/brand-logo/:brandLogoId",
    },
    BRAND_COLOR: {
      GET: "/brand-color",
      CREATE: "/brand-color",
      UPDATE: "/brand-color/:brandColorId",
      DELETE: "/brand-color/:brandColorId",
    },

    BRAND_FONT: {
      GET: "/brand-font",
      CREATE: "/brand-font",
      DELETE: "/brand-font/:brandFontId",
    },
  },

  PREDICTION: {
    default: "/prediction",
  },

  GROUP: {
    UPDATE_GROUP_NAME: "/group",
  },
};
