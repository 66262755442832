import { CometChat } from "@cometchat-pro/chat";
import { CometChatUIKit } from "@cometchat/chat-uikit-react";
import {
  useCreateInsightsReport,
  useGetInsightFilters,
  useGetInsightsData,
  useGetSpecificInsight,
  useGetVideoBreakdown,
  useMoveReportToFolder,
  useUpdateInsightsReport,
} from "apis/insightsApi";
import {
  useBookmarkReport,
  useDeleteReport,
  useGetBookmarkReports,
  useMakeShortUrl,
  useRemoveBookmark,
} from "apis/reportsApi";
import useAppState from "hooks/useAppState";
import useAxios from "hooks/useAxios";
import useReports from "hooks/useReports";

import {
  useGetGroupMediaAsset,
  useGetPresets,
  useGetReportMessage,
} from "apis/common";
import {
  useCreatePreset,
  useGetSpecificPresets,
  useUpdatePreset,
} from "apis/presets";
import { FILTER_CATEGORY_REVERSE_MAPPING } from "config/filters";
import { SELECTION_TYPE_MAPPING, groupbyOptions } from "config/statics";
import _, { isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setAdAccountIds,
  setDateNew,
  setFilterSet,
  setGroupBy,
  setGroupIds,
  setKpiFilters,
  setPayload,
  setPreset,
  setSelectedMetrices,
  setTableKpis,
} from "redux/slices/creativeInsights.slice";
import {
  addExtraFilter,
  initExtraFilters,
  removeExtraFilterField,
  setAggregateLogic,
  setExtraFilters,
  setFilters,
  setIsReportPublic,
  setMetrics,
  setSelectedKeywords,
  setSelectedMetrics,
  setSortBy,
  updateExtraFilterAggregateLogic,
  updateExtraFilterFieldSelect,
} from "redux/slices/insights.slice";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { API_ROUTES, APP_ROUTES } from "routes";

export default function useCreativeInsights() {
  const { axiosOrgInstance } = useAxios();
  const specificInsightsId = useParams()?.reportId;
  const platforms = useSelector(
    (state) => state?.organisation?.selectedOrganisation?.platforms
  );

  const insightsFilterState = useSelector(
    (state) => state.creativeInsightFilters
  );
  const date = insightsFilterState?.date;
  const groupIds = insightsFilterState?.groupIds;
  const kpiFilters = insightsFilterState?.kpiFilters;
  const payload = insightsFilterState?.payload || [];
  const groupBy = insightsFilterState?.groupBy;
  const selectedMetrices = insightsFilterState?.selectedMetrices;
  const filterSet = insightsFilterState?.filterSet;
  const adAccountIds = insightsFilterState?.adAccountIds;
  const preset = insightsFilterState?.preset;
  const tableKpis = insightsFilterState?.tableKpis;
  const [shareMessage, setShareMessage] = useState("");
  const [isSmartSuggestionLoading, setIsSmartSuggestionLoading] =
    useState(false);
  const [smartSuggestion, setSmartSuggestions] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedKPIs, setSelectedKPIs] = useState(new Set());

  const [filterAndOptions, setFiltersAndOptions] = useState([]);

  const [currentFilterObj, setCurrentFilterObj] = useState({});

  const insightState = useSelector((state) => state.insights);
  const { tags } = useSelector((state) => state.organisation);
  const userData = useSelector((state) => state.auth.userData);
  const [colorFormat, setColorFormat] = useState(2); //1 no-color 2 green only 3 red only 4 both
  const motion = insightState?.extraFilters;

  const dispatch = useDispatch();

  const { organisationOnBoarded } = useAppState();

  const { hideSidebar, disableFilters, currentReportOrgId } = useReports();

  const { data: insightFilters, isLoading: isLoadingFilters } =
    useGetInsightFilters(organisationOnBoarded, currentReportOrgId);

  const { data: presetData } = useGetPresets(true, currentReportOrgId);
  const { mutate: makePreset, isLoading: isMakingPreset } = useCreatePreset();
  const { mutate: updatePreset } = useUpdatePreset();
  const [tempKpis, setTempKpis] = useState([]);

  const [reportInfo, setReportInfo] = useState({
    title: "",
    description: "",
  });

  const {
    mutateAsync: getInsightsData,
    data: insightsData,
    isLoading: isInsightLoading,
  } = useGetInsightsData(specificInsightsId, currentReportOrgId);
  const {
    mutateAsync: getVideoBreakdownData,
    data: videoBreakdownData,
    isLoading: isVideoBreakdownLoading,
  } = useGetVideoBreakdown(specificInsightsId, currentReportOrgId);

  const { mutateAsync: updateReport, isLoading: isUpdatingReport } =
    useUpdateInsightsReport(specificInsightsId);

  const { data: insightsSpecificData, isLoading: loadingReport } =
    useGetSpecificInsight(
      insightFilters,
      specificInsightsId,
      currentReportOrgId
    );

  const { data: reportableMessage } = useGetReportMessage(
    specificInsightsId,
    currentReportOrgId
  );

  const { mutateAsync: createInsightsReport, isLoading: isCreatingReport } =
    useCreateInsightsReport();
  const { refetch: fetchBookmarks, isLoading: isFetchingBookmarks } =
    useGetBookmarkReports(false);
  const {
    mutateAsync: bookmarkReport,
    isSuccess: isBookmarkSuccess,
    isLoading: isBookmarking,
  } = useBookmarkReport(fetchBookmarks);

  const { mutateAsync: moveReport } = useMoveReportToFolder();

  function handleBookmarkReport(inputs) {
    const payload = {
      reportId: specificInsightsId,
    };
    bookmarkReport(payload);
  }

  function getFilterStateFromFiltersObject() {
    Object.entries(insightFilters)?.forEach(([key, value]) => {
      if (key !== "sortOrder" || key !== "metrics" || key !== "kpi") {
        filterAndOptions[key] = value;
      }
      if (key === "kpi") {
        filterAndOptions[key] = value;
      }
    });
  }

  const doesShowColor = (colorRange) => {
    if (colorFormat === 1) {
      return false;
    }
    switch (colorRange) {
      case 1:
        //show green color
        if (colorFormat === 2 || colorFormat === 4) {
          return true;
        }
        return false;
      case -1:
        //show red color only
        if (colorFormat === 3 || colorFormat === 4) {
          return true;
        }
        return false;
      default:
        return false;
    }
  };

  function makeBody(page = 1) {
    const body = {
      filters: filterSet,
    };

    //add kpis/selectedMetrices in _filters
    body["adAccountIds"] =
      disableFilters || hideSidebar
        ? insightsSpecificData?.[0]?.report?.adAccountIds
        : adAccountIds;
    body["kpis"] = selectedMetrices;
    body["name"] = reportData?.name;
    body["description"] = reportData?.description;
    body["icon"] = reportData?.icon;

    body["groupBy"] = {
      type: "groupBy",
      mode: 1,
      value: [groupBy?.value],
    };
    body["isSingleCreativeReport"] = true;
    body["groupIds"] = groupIds;

    body["platformIds"] =
      platforms?.length > 0
        ? platforms?.map((item) => item?.platformId)
        : insightsSpecificData
        ? insightsSpecificData?.[0]?.report?.platformIds
        : [];
    body["date"] = {
      selectionType: date?.selectionType,
      startDate: date?.startDate?.value
        ? date?.startDate?.value
        : date?.startDate,
      endDate: date?.endDate?.value ? date?.endDate?.value : date?.endDate,
    };
    if (kpiFilters?.length === 0 || kpiFilters?.[0]?.value?.length === 0) {
      body["kpiFilters"] = [];
    } else if (kpiFilters?.length > 0 && kpiFilters?.[0]?.value?.length > 0) {
      body["kpiFilters"] = kpiFilters;
    }
    body["groupIds"] = groupIds;
    body["page"] = 1;
    body["perPage"] = 10000000;
    body["sortOrder"] = insightsSpecificData?.[0]?.report?.sortOrder;
    body["sortBy"] = insightsSpecificData?.[0]?.report?.sortBy;

    return body;
  }

  function getVideoBreakdown() {
    const _body = makeBody();
    delete _body["isSingleCreativeReport"];
    _body["isVideoBreakdown"] = true;
    getVideoBreakdownData(_body);
  }

  function applyPayload() {
    const _updatedFilterSet = [...filterSet, payload];

    dispatch(setFilterSet(_updatedFilterSet));
    dispatch(setPayload([]));
  }

  function onRemovePayload(fIndex) {
    if (fIndex !== null) {
      const _updatedFilterSet = filterSet?.filter(
        (fset, idx) => idx !== fIndex
      );
      dispatch(setFilterSet(_updatedFilterSet));
    } else {
      dispatch(
        setPayload([
          {
            type: "optimizationGoals",
            mode: 1,
            is: [],
            isNot: [],
            filter: "is",
          },
        ])
      );
    }
  }

  function _handleApplyFilters(page = 1) {
    if (adAccountIds?.length > 0) {
      const body = makeBody(page);
      getInsightsData(body);
      if (mediaAsset?.videoUrl) {
        getVideoBreakdown();
      }
    } else {
      toast.info("Please select an Ad-Account in filters");
    }
  }

  function saveReport(saveReportInfo) {
    const body = makeBody();
    body["name"] =
      saveReportInfo?.name || insightsSpecificData?.[0]?.report?.name;
    body["description"] =
      saveReportInfo?.description ||
      insightsSpecificData?.[0]?.report?.description;
    body["isPublic"] = saveReportInfo?.isPublic;
    createInsightsReport(body);
  }

  function handleUpdateAndSave() {
    if (!specificInsightsId) {
      dispatch(
        setModal({
          open: true,
          type: MODALTYPES.SAVE_INSIGHTS_REPORT,
          payload: {},
        })
      );
    } else {
      const _body = makeBody();
      updateReport(_body);
    }
  }

  function handleExtraFilterSelectUpdate(index, value, key) {
    setSmartSuggestions([]);
    dispatch(updateExtraFilterFieldSelect({ index, value, key }));
  }

  function handleUpdateSearchExtraFilter(index, value, filter, fIndex) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    // filter for is/isNot contains/notContains
    if (fIndex !== null) {
      const updatedFilterSet = _.cloneDeep(filterSet);
      updatedFilterSet[fIndex][index] = {
        ...updatedFilterSet[fIndex][index],
        is: [],
        isNot: [],
        [filter]: value,
      };
      dispatch(setFilterSet(updatedFilterSet));
    } else {
      const updatedPayload = [...payload];
      updatedPayload[index] = {
        ...updatedPayload[index],
        [filter]: value,
      };
      dispatch(setPayload(updatedPayload));
    }
  }

  const [currPresetId, setPresetId] = useState(null);
  const { data: specificPresetData, isLoading: isLoadingSpecificPreset } =
    useGetSpecificPresets(currPresetId);
  function handlePresetSelect(presetId) {
    setPresetId(presetId);
    dispatch(setPreset(presetId));
  }

  useEffect(() => {
    if (
      tableKpis?.length > 0 &&
      Array.isArray(specificPresetData) &&
      specificPresetData?.length > 0
    ) {
      dispatch(setTableKpis([]));
      const kpis = [];
      tableKpis?.map((kpi) => {
        if (!kpis?.includes(kpi)) {
          kpis?.push(kpi);
        }
      });
      specificPresetData[0].kpis.map((kpi) => kpis.push(kpi));
      setTempKpis(kpis);
    } else if (
      Array.isArray(specificPresetData) &&
      specificPresetData.length > 0 &&
      !tableKpis?.length
    ) {
      const kpis = [];
      specificPresetData[0].kpis.map((kpi) => kpis.push(kpi));
      setTempKpis(kpis);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificPresetData, preset]);

  //to set default preset selected
  useEffect(() => {
    if (!currPresetId && !preset) {
      setPresetId(presetData?.[0]?.id);
      dispatch(setPreset(presetData?.[0]?.id));
    }
    if (preset) {
      setPresetId(preset);
    }
  }, [presetData, specificInsightsId]);

  const handleFetchSmartSuggestions = (q, key) => {
    setIsSmartSuggestionLoading(true);
    axiosOrgInstance
      .get(`${API_ROUTES.ORGANISATION.SMART_FILTERS}?q=${q}&type=${key}`)
      .then((res) => {
        let _temp = [];
        setIsSmartSuggestionLoading(false);
        res?.map((curr) => {
          _temp.push(curr[key]);
        });
        setSmartSuggestions(_temp || ["Nothing Found...."]);
      });
  };

  function handleSearchMotionFilters(type, q) {
    setSmartSuggestions([]);
    if (q?.trim()?.length === 0) {
      setSmartSuggestions([]);
      setIsSmartSuggestionLoading(false);
    } else if (type === "keywords") {
      setSmartSuggestions(tags);
      setIsSmartSuggestionLoading(false);
    } else {
      handleFetchSmartSuggestions(q, type);
    }
  }

  function handleAggregateLogicChangeExtraFilters(logic) {
    dispatch(updateExtraFilterAggregateLogic({ logic }));
  }

  function handleAddNewFilter() {
    dispatch(addExtraFilter());
  }

  function handleRemoveNewFilter(index) {
    dispatch(removeExtraFilterField({ index }));
  }

  const [paginatedInsightsData, setPaginatedInsightsData] = useState([]);
  const [visualisationTab, setVisualisationTab] = useState(0);
  const prevInsightState = useRef();
  const [isSaveReportEnable, setIsShareReportEnable] = useState(
    !specificInsightsId
  ); // Add a flag to track whether the component has mounted

  function willFilterEmpty(options) {
    const _options = options?.filter((option) => option?.default);
    if (_options?.length === 1) {
      return true;
    }
    return false;
  }

  function onFilterUpdate(key, selectedOption) {
    let _filter = { ...insightState?.filters };
    Object.entries(insightState?.filters).forEach(([filterKey, value]) => {
      if (key === filterKey) {
        _filter[key] = {
          ...value,
          options: value?.options?.map((item) => {
            if (item.key === selectedOption.key) {
              if (
                item?.default &&
                willFilterEmpty(value?.options) &&
                key !== "targetingCountries"
              ) {
                toast("Minimum one filter is required");
                return { ...item, default: true };
              } else {
                return { ...item, default: !item.default };
              }
            }
            return item;
          }),
        };
      }
    });

    dispatch(setFilters(_filter));
  }

  function onRemoveSelectedMetrics(index) {
    const _selectedMetrics = insightState?.selectedMetrics?.filter(
      (metric, idx) => idx !== index
    );
    if (_selectedMetrics?.length > 0) {
      dispatch(setSelectedMetrics(_selectedMetrics));
    } else {
      toast("Minimum one KPI selection is mandatory");
    }
  }

  function onDateDaysChange(config) {
    const _date = _.cloneDeep(date);
    _date["selectionType"] = null;
    const _newDate = {
      ..._date,
      ...config,
    };
    dispatch(setDateNew(_newDate));
  }

  function handleSetReportPublicChange() {
    dispatch(setIsReportPublic(!insightState?.isReportPublic));
  }

  function handleKeywordAddition(keyword) {
    if (keyword?.length <= 0) {
      dispatch(setAggregateLogic(null));
    }
    if (keyword.length === 2) {
      dispatch(
        setModal({
          type: MODALTYPES.KEYWORD_AGGREGATION_LOGIC_MODAL,
          open: true,
        })
      );
    }
    dispatch(setSelectedKeywords(keyword));
  }

  function handleAggregateChange(aggregate) {
    dispatch(setAggregateLogic(aggregate));
  }

  const { mutateAsync: removeBookmarkMutate, isLoading: isRemovingBookmark } =
    useRemoveBookmark();
  function handleRemoveBookmark() {
    removeBookmarkMutate(specificInsightsId);
  }

  // Function to join a group
  const [currentGroup, setCurrentGroup] = useState(null);
  async function joinGroup(groupId, triedOnce = false) {
    try {
      await CometChat.joinGroup(groupId, CometChat.GROUP_TYPE.PUBLIC, "");
      CometChat.getGroup(groupId).then((group) => setCurrentGroup(group));
    } catch (error) {
      if (error?.code === "ERR_ALREADY_JOINED") {
        CometChat.getGroup(groupId).then((group) => setCurrentGroup(group));
      }

      if (error?.code === "USER_NOT_LOGED_IN") {
        if (triedOnce) {
          return;
        }

        if (!userData?.user) return;
        const authKey = process.env.REACT_APP_COMETCHAT_AUTH_KEY;
        const uid = userData?.user?._id;

        CometChatUIKit.login(uid, authKey)
          .then((loggedInUser) => {
            joinGroup(groupId, true);
          })
          .catch((loginError) => {
            console.log("Login Error:", loginError);
          });
      }
    }
  }

  function moveReportToFolder(folderId, reportId) {
    moveReport({ reportableId: reportId || specificInsightsId, folderId });
  }

  const { mutateAsync: generateShortUrl, isLoading: isMakingShortUrl } =
    useMakeShortUrl();

  const { mutateAsync: deleteReport, isLoading: deleteReportLoading } =
    useDeleteReport();

  async function handleUrlShortener(reportMessage) {
    try {
      const report = insightsSpecificData?.[0]?.report;
      const viewRedirect =
        APP_ROUTES.INSIGHTS.CREATIVE_INSIGHT + `/${specificInsightsId}`;
      const longUrl = `${process.env.REACT_APP_URL}${viewRedirect}?orgId=${report?.organizationId}`;
      generateShortUrl({
        longUrl,
        title: report?.name,
        reportableId: specificInsightsId,
        message: reportMessage,
      });
    } catch (error) {
      toast("Failed to generate report URL");
    }
  }

  const [reportData, setReportData] = useState({
    name: "",
    description: "",
    icon: null,
  });

  const onUpdateReportData = (value, type, limit) => {
    // if (value.length > limit) {
    //   toast(`Report ${type} should be less than ${limit} characters`, {
    //     toastId: type + "@33ldsdsk",
    //   });
    //   return;
    // }

    setReportData({
      ...reportData,
      [type]: value,
    });
  };

  const updateReportInfo = useCallback((key, value) => {
    setReportInfo({
      ...reportInfo,
      [key]: value,
    });
  }, []);

  function handleColorFormatChange(format) {
    setColorFormat(format);
  }

  function changeGroupBy(val) {
    dispatch(setGroupBy(val));
  }

  function onOpenKpiFilters(key) {
    const updatedKpiFilters = [...kpiFilters];
    updatedKpiFilters?.push({
      key,
      condition: "greater",
      value: [],
      aggregator: "AND",
    });
    dispatch(setKpiFilters(updatedKpiFilters));
  }

  function onOpenFilters() {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    dispatch(
      setPayload([
        {
          type: "adName",
          mode: 2,
          contains: [],
          notContains: [],
          filter: "contains",
        },
      ])
    );
  }

  function onFilterFilterChange(index, val, fIndex) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    if (fIndex !== null) {
      const updatedFilterSet = _.cloneDeep(filterSet);
      const filter = updatedFilterSet[fIndex]?.[index]?.filter;
      if (filter === "is" || filter === "isNot") {
        updatedFilterSet[fIndex][index] = {
          ...updatedFilterSet[fIndex][index],
          is: [],
          isNot: [],
          filter: val,
        };
      } else {
        updatedFilterSet[fIndex][index] = {
          ...updatedFilterSet[fIndex][index],
          contains: [],
          notContains: [],
          filter: val,
        };
      }
      dispatch(setFilterSet(updatedFilterSet));
    } else {
      const _currentFilterAtIndex = payload?.[index]?.filter;
      if (_currentFilterAtIndex === "is" || _currentFilterAtIndex === "isNot") {
        const updatedPayload = [...payload];
        updatedPayload[index] = {
          ...updatedPayload[index],
          filter: val,
          is: [],
          isNot: [],
        };
        dispatch(setPayload(updatedPayload));
      } else {
        const updatedPayload = [...payload];
        updatedPayload[index] = {
          ...updatedPayload[index],
          filter: val,
          contains: [],
          notContains: [],
        };
        dispatch(setPayload(updatedPayload));
      }
    }
  }

  function onFilterSetOpen(fIndex) {
    dispatch(setPayload(filterSet[fIndex]));
  }

  function onFilterWhereChange(index, val, fIndex) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    if (fIndex !== null) {
      if (Object.keys(FILTER_CATEGORY_REVERSE_MAPPING).includes(val)) {
        const updatedFilterSet = _.cloneDeep(filterSet);
        if (FILTER_CATEGORY_REVERSE_MAPPING?.[val]?.type === "dropdown") {
          updatedFilterSet[fIndex][index] = {
            ...updatedFilterSet[fIndex][index],
            type: val,
            mode: 1,
            is: [],
            isNot: [],
            filter: "is",
          };
        } else {
          updatedFilterSet[fIndex][index] = {
            ...updatedFilterSet[fIndex][index],
            type: val,
            mode: 2,
            is: [],
            isNot: [],
            filter: "contains",
          };
        }
        dispatch(setFilterSet(updatedFilterSet));
      } else {
        const updatedFilterSet = _.cloneDeep(filterSet);
        updatedFilterSet[fIndex][index] = {
          type: val,
          is: [],
          isNot: [],
          filter: "is",
          mode: 1,
        };
        dispatch(setFilterSet(updatedFilterSet));
      }
    } else {
      if (Object.keys(FILTER_CATEGORY_REVERSE_MAPPING).includes(val)) {
        const updatedPayload = [...payload];
        if (FILTER_CATEGORY_REVERSE_MAPPING?.[val]?.type === "dropdown") {
          updatedPayload[index] = {
            type: val,
            mode: 1,
            is: [],
            isNot: [],
            filter: "is",
          };
        } else {
          updatedPayload[index] = {
            type: val,
            mode: 2,
            contains: [],
            notContains: [],
            filter: "contains",
          };
        }
        dispatch(setPayload(updatedPayload));
      } else {
        const updatedPayload = [...payload];
        updatedPayload[index] = {
          type: val,
          mode: 1,
          is: [],
          isNot: [],
          filter: "is",
        };
        dispatch(setPayload(updatedPayload));
      }
    }
  }

  function handleAdAccountId(id) {
    if (adAccountIds?.includes(id)) {
      const _updatedAdAccountIds = adAccountIds?.filter((curr) => curr !== id);
      dispatch(setAdAccountIds(_updatedAdAccountIds));
    } else {
      dispatch(setAdAccountIds([...adAccountIds, id]));
    }
  }

  function onFilterValueChange(
    index,
    val,
    fIndex,
    isAdAccount,
    isUpdateAsItIS
  ) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    if (fIndex !== null) {
      const updatedFilterSet = _.cloneDeep(filterSet);
      const fSet = updatedFilterSet?.[fIndex];
      const filter = fSet?.[index]?.filter;
      if (isUpdateAsItIS) {
        fSet[index] = {
          ...fSet[index],
          [filter]: val,
        };
      } else if (fSet?.[index]?.[filter]?.includes(val)) {
        fSet[index] = {
          ...fSet[index],
          [filter]: fSet?.[index]?.[filter]?.filter((curr) => curr !== val),
        };
      } else {
        fSet[index] = {
          ...fSet[index],
          [filter]: [...fSet?.[index]?.[filter], val],
        };
      }
      dispatch(setFilterSet(updatedFilterSet));
    } else {
      const filter = payload?.[index]?.filter;
      const updatedPayload = [...payload];
      if (isUpdateAsItIS) {
        updatedPayload[index] = {
          ...updatedPayload[index],
          [filter]: val,
        };
      } else if (payload[index]?.[filter]?.includes(val)) {
        updatedPayload[index] = {
          ...updatedPayload[index],
          [filter]: payload?.[index]?.[filter]?.filter((curr) => curr !== val),
        };
      } else {
        updatedPayload[index] = {
          ...updatedPayload[index],
          [filter]: [...payload[index]?.[filter], val],
        };
      }
      dispatch(setPayload(updatedPayload));
    }
  }

  function onUpdatePayload(_currentPayload) {
    dispatch(setPayload(_currentPayload));
  }

  function handleDeleteFromFilterSet(fIndex, index) {
    const updatedFilterSet = _.cloneDeep(filterSet);
    updatedFilterSet[fIndex] = updatedFilterSet[fIndex]?.filter(
      (curr, idx) => idx !== index
    );
    dispatch(setFilterSet(updatedFilterSet));
  }

  function handleAddMore(load, fIndex) {
    setSmartSuggestions([]);
    setIsSmartSuggestionLoading(false);
    //you might have felt why i am using findex !== null and not just if fIndex it's because fIndex can be 0 also
    const parent_element = document.getElementById(`parent-filter-content`);
    const element = document.getElementById(`filter-content`);
    if (fIndex !== null) {
      const updatedFilterSet = _.cloneDeep(filterSet);
      updatedFilterSet[fIndex]?.push(load);
      dispatch(setFilterSet(updatedFilterSet));
    } else {
      const _updatedPayload = [...payload, load];
      dispatch(setPayload(_updatedPayload));
    }
    //waiting for the dom updation
    setTimeout(() => {
      element.scrollTop = parent_element.scrollHeight;
    }, 300);
  }

  function onUpdateKpiFilters(updatedKpiFilters) {
    dispatch(setKpiFilters(updatedKpiFilters));
  }

  function onUpdateSelectedMetrices(payload) {
    dispatch(setSelectedMetrices(payload));
  }

  function handleUpdateDate(_date) {
    dispatch(setDateNew(_date));
  }
  function handleUpdateSelectionType(selectionType) {
    const _startDate = moment(new Date())
      .subtract(parseInt(SELECTION_TYPE_MAPPING[selectionType]), "days")
      .format("YYYY-MM-DD");
    const _endDate = moment(new Date()).format("YYYY-MM-DD");
    const _updatedDate = {
      ...date,
      startDate: _startDate,
      endDate: _endDate,
      selectionType: SELECTION_TYPE_MAPPING[selectionType],
    };
    dispatch(setDateNew(_updatedDate));
  }

  function handleSetFirstAdAccountSelected(insightFilters) {
    if (insightFilters) {
      Object.entries(insightFilters).map(([key, val], index) => {
        if (key === "adAccountIds") {
          if (val?.options && val?.options?.length > 0) {
            dispatch(setAdAccountIds([val?.options?.[0]?.key]));
          }
        }
      });
    }
  }

  function getGroupByLabel(value) {
    const val = groupbyOptions?.filter((option) => option?.value === value);
    return val[0]?.label;
  }

  function makeBodyFromReport(
    _payload,
    _extraFilters,
    _date,
    _kpiFilters,
    _platformIds,
    _adAccountIds,
    _groupBy,
    _kpis,
    _groupIds
  ) {
    const __filters = _payload;

    const body = {
      filters: __filters,
    };

    body["adAccountIds"] = _adAccountIds;
    body["groupIds"] = _groupIds;
    body["isAdBreakdownReport"] = true;

    body["groupBy"] = {
      type: "groupBy",
      mode: 1,
      value: [_groupBy?.value],
    };

    if (_kpis?.length > 0) {
      body["kpis"] = {
        type: "kpis",
        value: _kpis,
        mode: 1,
      };
    }

    body["platformIds"] = _platformIds || [];
    body["date"] = {
      endDate: _date?.endDate?.value ? _date?.endDate?.value : _date?.endDate,
      selectionType: _date?.selectionType,
      startDate: _date?.startDate?.value
        ? _date?.startDate?.value
        : _date?.startDate,
    };
    body["page"] = 1;
    body["perPage"] = 10000000;
    body["kpiFilters"] = _kpiFilters;
    body["sortOrder"] = insightsSpecificData?.[0]?.report?.sortOrder;
    body["sortBy"] = insightsSpecificData?.[0]?.report?.sortBy;
    getInsightsData(body);
  }

  useEffect(() => {
    const todaysDate = moment().format("YYYY-MM-DD");
    if (!!specificInsightsId) {
      dispatch(setPayload([]));
      dispatch(setFilterSet([]));
      dispatch(setSelectedMetrics([]));
      dispatch(
        setDateNew({
          startDate: todaysDate,
          endDate: todaysDate,
          selectionType: null,
        })
      );
      dispatch(
        setSelectedMetrices({
          type: "kpis",
          value: [],
          mode: 1,
        })
      );
      dispatch(setAdAccountIds([]));
    }

    if (insightFilters) {
      if (!!specificInsightsId) {
        handleSetFirstAdAccountSelected(insightFilters);
      }
      getFilterStateFromFiltersObject();
      const filters = {};
      const kpis = [];

      Object.entries(insightFilters).forEach(([key, value]) => {
        if (value?.options?.length > 0) {
          filters[key] = {
            ...value,
            options: value?.options?.map((item, index) => {
              if (index === 0) {
                return { ...item, default: true };
              }
              return item;
            }),
          };
        } else if (key === "kpi") {
          kpis.push(value?.[0]?.kpi);
          const selectedMetrics = [
            {
              label: value?.[0]?.label,
              value: value?.[0]?.kpi,
            },
          ];

          dispatch(setMetrics(value));
          dispatch(setSelectedMetrics(selectedMetrics));
        }
      });
      dispatch(setFilters(filters));
      dispatch(setSortBy(kpis[0]));

      if (!!specificInsightsId) return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightFilters]);

  const {
    data: mediaAsset,
    isLoading: isGettingMediaAsset,
    refetch: getMediaAsset,
  } = useGetGroupMediaAsset(groupIds[0]);

  useEffect(() => {
    if (
      insightFilters &&
      !specificInsightsId &&
      !insightsSpecificData &&
      mediaAsset
    ) {
      _handleApplyFilters();
    }
  }, [insightFilters, mediaAsset]);

  useEffect(() => {
    if (insightsSpecificData) {
      const report = insightsSpecificData?.[0]?.report;

      if (!report) return;

      // Check if the group already exists
      // const groupId = report?.reportableId;
      // const groupName = report?.name;
      // checkIfGroupExists(groupId).then((existingGroup) => {
      //   if (existingGroup) {
      //     // If group exists, join the user to the group
      //     joinGroup(groupId);
      //   } else {
      //     // If group does not exist, create the group and then join the user
      //     createGroup(groupId, groupName)
      //       .then((newGroup) => {
      //         if (newGroup) {
      //           // Group created successfully, now join the user
      //           joinGroup(groupId);
      //         }
      //       })
      //       .catch((err) => {
      //         console.log("Error creating group me", err);
      //       });
      //   }
      // });

      const payload = JSON.parse(report?.filters);
      const extraFilters = report?.extraFilters
        ? JSON.parse(report?.extraFilters)
        : [];
      const date = report?.date;
      const kpiFilters = report?.kpiFilters
        ? JSON.parse(report?.kpiFilters)
        : [];
      const platformIds = report?.platformIds;
      const _groupIds = report?.groupIds;
      let adAccountIds = report?.adAccountIds;
      if (!adAccountIds || adAccountIds?.length === 0) {
        handleSetFirstAdAccountSelected(insightFilters);
        adAccountIds = insightsFilterState?.adAccountIds;
      }
      let kpis = [];

      if (report?.kpis && report?.kpis?.length > 0) {
        kpis = report?.kpis;
        dispatch(
          setSelectedMetrices({
            type: "kpis",
            value: report?.kpis,
            mode: 1,
          })
        );
      }
      // dispatch(setPayload(payload));
      dispatch(setFilterSet(payload));
      const _groupBy = {
        label: getGroupByLabel(JSON.parse(report?.groupBy)?.value[0]),
        value: JSON.parse(report?.groupBy)?.value[0],
      };
      dispatch(setGroupBy(_groupBy));
      dispatch(setKpiFilters(kpiFilters));
      extraFilters?.length === 0
        ? dispatch(initExtraFilters())
        : dispatch(setExtraFilters(extraFilters));
      const _date = {
        endDate: date?.endDate?.value ? date?.endDate?.value : date?.endDate,
        selectionType: date?.selectionType ? date?.selectionType : 4,
        startDate: date?.startDate?.value
          ? date?.startDate?.value
          : date?.startDate,
      };
      dispatch(setDateNew(_date));
      dispatch(setSelectedKeywords([]));
      dispatch(setAggregateLogic(null));
      dispatch(setAdAccountIds(adAccountIds));
      dispatch(setGroupIds(_groupIds));
      setReportInfo({
        title: report?.name,
        description: report?.description,
        icon: report?.icon,
      });

      setReportData({
        name: insightsSpecificData?.[0]?.report?.name || "",
        description: insightsSpecificData?.[0]?.report?.description || "",
        icon: report?.icon || null,
      });

      makeBodyFromReport(
        payload,
        extraFilters,
        date,
        kpiFilters,
        platformIds,
        adAccountIds,
        _groupBy,
        kpis,
        _groupIds
      );
    }
  }, [insightsSpecificData]);

  useMemo(() => {
    setPaginatedInsightsData(insightsData?.docs);
    setRowSelection({});
  }, [insightsData]);

  // useEffect(() => {
  //   if (isLoadMore && hasNextPage && visualisationTab === 0) {
  //     const { filters, selectedMetrics, sortOrder, date } = insightState;
  //     let payload = getInsightsPayloadFromInsightState(
  //       filters,
  //       selectedMetrics?.map((item) => item.value),
  //       sortOrder,
  //       date,
  //       platforms,
  //       currentPage.current + 1,
  //       insightState?.sortBy
  //     );
  //     // payload["keywords"] = insightState?.keywords;
  //     // payload["aggregateLogic"] = insightState?.aggregateLogic;
  //     getInsightsData(payload).then((res) => {
  //       currentPage.current = currentPage.current + 1;
  //       setPaginatedInsightsData([...paginatedInsightsData, ...res?.docs]);
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoadMore]);

  useEffect(() => {
    if (prevInsightState?.current) {
      if (!isEqual(prevInsightState?.current, insightState)) {
        setIsShareReportEnable(true);
      } else {
        setIsShareReportEnable(false);
      }
    }
  }, [insightState]);

  return {
    insightState,
    videoBreakdownData,
    isVideoBreakdownLoading,
    isLoadingSpecificPreset,
    isMakingShortUrl,
    motion,
    filterAndOptions,
    insightsData: paginatedInsightsData,
    isInsightLoading,
    isSaveReportEnable,
    visualisationTab,
    disableFilters,
    currentReportOrgId,
    isBookmarkSuccess,
    specificInsightsId,
    smartSuggestion,
    isSmartSuggestionLoading,
    hideSidebar,
    isReportPublic: insightState?.isReportPublic,
    currentGroup,
    currentFilterObj,
    payload,
    selectedMetrices,
    groupBy,
    kpiFilters,
    insightFilters,
    insightsReport: insightsSpecificData?.[0]?.report,
    reportableMessage,
    deleteReportLoading,
    sortedData,
    rowSelection,
    selectedKPIs,
    isUpdatingReport,
    groupIds,
    isCreatingReport,
    date,
    isLoadingFilters,
    preset,
    presetData,
    specificPresetData,
    isMakingPreset,
    isRemovingBookmark,
    isBookmarking,
    isFetchingBookmarks,
    reportInfo,
    tempKpis,
    reportData,
    filterSet,
    adAccountIds,
    colorFormat,
    mediaAsset,
    isGettingMediaAsset,
    loadingReport,
    handleColorFormatChange,
    handleUpdateSelectionType,
    handleUpdateDate,
    setReportInfo,
    updateReportInfo,
    makePreset,
    updatePreset,
    handleUpdateAndSave,
    saveReport,
    _handleApplyFilters,
    setCurrentFilterObj,
    setShareMessage,
    onFilterUpdate,
    handleUrlShortener,
    onDateDaysChange,
    setVisualisationTab,
    handleSetReportPublicChange,
    handleBookmarkReport,
    moveReportToFolder,
    handleRemoveBookmark,
    handleKeywordAddition,
    handleAggregateChange,
    handleSearchMotionFilters,
    handleUpdateSearchExtraFilter,
    handleExtraFilterSelectUpdate,
    handleAddNewFilter,
    handleRemoveNewFilter,
    handleAggregateLogicChangeExtraFilters,
    onRemoveSelectedMetrics,
    deleteReport,
    setSortedData,
    setRowSelection,
    setSelectedKPIs,
    changeGroupBy,
    onOpenFilters,
    onOpenKpiFilters,
    onFilterWhereChange,
    onFilterValueChange,
    onUpdatePayload,
    onUpdateKpiFilters,
    onUpdateSelectedMetrices,
    handlePresetSelect,
    onUpdateReportData,
    setTempKpis,
    onFilterFilterChange,
    applyPayload,
    onRemovePayload,
    onFilterSetOpen,
    handleAdAccountId,
    handleDeleteFromFilterSet,
    handleAddMore,
    doesShowColor,
  };
}
