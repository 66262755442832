export const availableRoles = { SA: "SA", A: "A", U: "U" };

export const ROLES = {
  SA: availableRoles.SA,
  A: availableRoles.A,
  U: availableRoles.U,
};

export const RolesMapping = {
  [ROLES.SA]: "Super Admin",
  [ROLES.A]: "Admin",
  [ROLES.U]: "User",
};

export const SA_PERMISSIONS = {
  CONNECT_AD_ACOUNT: "CONNECT:ADD_ACCOUNT",
  GROUPING: "GROUPING",
  MANNUAL_GROUPING: "GROUPING:MANNUAL",
  ADD_USER: "USER:ADD",
};
export const A_PERMISSIONS = {
  CONNECT_AD_ACOUNT: "CONNECT:ADD_ACCOUNT",
  UPDATE_CURRENCY: "CURRENCY:UPDATE",
  HANDLE_PAYMENTS: "HANDLE:PAYMENTS",
  VIEW_ACCOUNT: "MY_ACCOUNT:VIEW",
  VIEW_MODEL_KPIS: "MODEL_KPIS:VIEW",
  GROUPING: "GROUPING",
  MANNUAL_GROUPING: "GROUPING:MANNUAL",
};
export const U_PERMISSIONS = ["USER:MANAGE"];

export const ACTIVE_FEATURES = {
  INSIGHTS: "INSIGHTS",
  COMPARE: "COMPARE",
  CREATIVE_MOMENTUM_FATIGUE: "CREATIVE_MOMENTUM_FATIGUE",
  CONVERSATIONAL_INSIGHTS: "CONVERSATIONAL_INSIGHTS",
  RECOMMENDATION: "RECOMMENDATION",
  PREDICTION: "PREDICTION",
  GENERATION: "GENERATION",
  CHAT: "CHAT",
  ADVANCE_TAGGING: "ADVANCE_TAGGING",
};

export const CHANGE_ROLES = {
  SUPER_ADMIN: "SA",
  ADMIN: "A",
  USER: "U",
};
export const ALL_ROLES = ["SUPER ADMIN", "ADMIN", "USER"];
export const ROLES_TYPE_MAPPING = { SUPER_ADMIN: "SA", ADMIN: "A", USER: "U" };
export const ROLES_TYPE_REVERSE_MAPPING = {
  SA: "SUPER_ADMIN",
  A: "ADMIN",
  U: "USER",
};
