import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import useAxios from "../../../../../../hooks/useAxios";
import { API_ROUTES } from "../../../../../../routes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { modalInitState, setModal } from "redux/slices/modal.slices";
import { setUserData } from "redux/slices/auth.slice";
import { setLoading } from "redux/slices/app.slices";

export default function useProfileDetails() {
  const [profileDetails, setProfileDetails] = useState({});
  const { axiosUserInstance } = useAxios();
  const [isEdit, setIsEdit] = useState(false);
  const backUpRef = useRef({});
  const { user } = useSelector((state) => state?.auth?.userData) || {
    user: {},
  };
  const { auth } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  function getProfileDetails() {
    if (!user?._id) return;
    axiosUserInstance?.get(API_ROUTES.USER.GET_USER).then((res) => {
      setProfileDetails(res);
      dispatch(setUserData({ tokens: auth?.userData?.tokens, user: res }));
      dispatch(setLoading(false));
      backUpRef.current = res;
    });
  }

  function updateUserDetails(payload) {
    dispatch(setLoading(true));
    axiosUserInstance
      .patch(
        API_ROUTES.USER.UPDATE_USER?.replace(":userId", user?._id),
        payload
      )
      .then((res) => {
        getProfileDetails();
        toast.success("Profile updated successfully");
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function updateBasicDetails() {
    const data = {
      userName: profileDetails?.userName,
      accountType: profileDetails?.accountType,
    };

    axiosUserInstance
      .patch(API_ROUTES.USER.UPDATE_USER?.replace(":userId", user?._id), data)
      .then((res) => {
        toast.success("Profile updated successfully");
        setIsEdit(false);
        dispatch(setModal(modalInitState));
      })
      .catch((err) => {
        toast.error(err);
        setIsEdit(false);
        setProfileDetails(backUpRef.current);
      });
  }

  useEffect(() => {
    getProfileDetails();
  }, [user?._id]);

  useEffect(() => {
    if (isEdit) {
      backUpRef.current = profileDetails;
    }
  }, [isEdit]);

  return {
    profileDetails,
    isLoading,
    user,
    isEdit,
    backUpRef,
    getProfileDetails,
    setIsEdit,
    setProfileDetails,
    updateBasicDetails,
    updateUserDetails,
  };
}
