import * as React from "react";
import { Check, X } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import useOrganizationTagSelector from "./useOrganizationTagSelector";

export function OrganizationTagSelector(props) {
  const {
    options = [],
    tags = [],
    open,
    setOpen,
    value,
    setValue,
    searchGlobalTags,
  } = useOrganizationTagSelector();

  const { onChange } = props;

  const onSelect = (currentValue) => {
    let res = [];
    if (value.length != 0) {
      const temp = new Set(value);
      if (temp.has(currentValue)) {
        temp.delete(currentValue);
      } else {
        temp.add(currentValue);
      }
      res = [...temp];
    } else {
      res = [currentValue];
    }
    setValue(res);
    onChange && onChange(res);
  };

  const handleTags = () => {
    if (options.length !== 0) {
      return options;
    }

    return tags;
  };

  const commandListOptions = handleTags();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {value.length > 0 ? (
            <div className="flex flex-row justify-between w-full">
              <div>{value.length} tags are selected</div>
              <X
                className="inline-block ml-2 h-4 w-4 shrink-0 opacity-50"
                onClick={() => {
                  setValue([]);
                  onChange([]);
                }}
              />
            </div>
          ) : (
            <span className="opacity-50">Filter by tags ...</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput
            placeholder="Search tags..."
            onValueChange={searchGlobalTags}
          />
          <CommandEmpty>No tags found.</CommandEmpty>
          <CommandList>
            <CommandGroup className="h-[90%]">
              {commandListOptions &&
                commandListOptions.map((tag) => (
                  <CommandItem key={tag} value={tag} onSelect={onSelect}>
                    {tag}
                    {value.includes(tag) && (
                      <Check className={cn("ml-2 h-4 w-4")} />
                    )}
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
