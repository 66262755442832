import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_ROUTES, APP_ROUTES } from "../../../routes";
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth, facebookprovider, googleProvider } from "config/firebase";
import { toast } from "react-toastify";
import useAxios from "hooks/useAxios";
import { useDispatch } from "react-redux";
import { setUserData } from "redux/slices/auth.slice";
import { setAppState } from "redux/slices/appState.slices";
import { COMMON } from "apis/apiEndPoints";
import { firebaseErrorCodes } from "helpers/firebase-error-codes.helper";
import { useLoginUser } from "utils/CometChat";

const SignupReducer = (state, action) => {
  switch (action.type) {
    case "SET_NAME":
      return {
        ...state,
        name: action.payload,
      };

    case "SET_EMAIL":
      return {
        ...state,
        email: action.payload,
      };
    case "SET_PASSWORD":
      return {
        ...state,
        password: action.payload,
      };

    case "SET_RE_PASSWORD":
      return {
        ...state,
        re_password: action.payload,
      };

    case "SET_TERMS_CONDITIONS":
      return {
        ...state,
        terms_conditions: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  name: "",
  email: "",
  password: "",
  re_password: "",
  terms_conditions: false,
};

export default function useSignup() {
  const { initUser } = useLoginUser();
  const { axiosInstance } = useAxios();
  const dispatchRedux = useDispatch();
  const [form, dispatch] = useReducer(SignupReducer, initialState);
  const [isValidated, setIsValidated] = useState(false);
  const [continueWithEmail, setContinueWithEmail] = useState(false);
  const navigate = useNavigate();
  function handleChange(event) {
    const { name, value } = event.target;
    if (name === "terms_conditions")
      dispatch({
        type: "SET_TERMS_CONDITIONS",
        payload: !form.terms_conditions,
      });
    else
      dispatch({
        type: `SET_${name.toUpperCase()}`,
        payload: value,
      });
  }

  function validateForm() {
    if (!form.terms_conditions) return false;
    if (form.email === "" || form.password === "") return false;
    if (form.email.length < 5 || form.password.length < 8) return false;
    if (!form.email.includes("@") || !form.email.includes(".")) return false;
    if (
      !form.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    )
      if (!form.terms_conditions) return false;
    return true;
  }

  const [isLoading, setIsLoading] = useState(false);

  function handleSignup() {
    //api call
    setIsLoading(true);
    createUserWithEmailAndPassword(auth, form.email, form.password)
      .then((userCredentials) => {
        const user = userCredentials?.user;
        axiosInstance
          .post(API_ROUTES.AUTH.FIREBASE_GOOGLE_LOGIN, {
            uid: user?.uid,
          })
          .then((res) => {
            toast("Signup successful!");
            dispatchRedux(setUserData(res));

            const eventPayload = {
              event: "sign_up",
              User_Details: {
                user_id: res?.user?._id,
                email: res?.user?.email,
                first_name: res?.user?.firstName,
                last_name: res?.user?.lastName,
                username: res?.user?.userName,
                user_type: res?.user?.userType,
                created_at: res?.user?.createdAt,
              },
            };

            if (window.dataLayer) window.dataLayer.push(eventPayload);

            axiosInstance
              .get(COMMON.APPLICATION_STATUS, {
                headers: {
                  Authorization: `Bearer ${res?.tokens?.user?.token}`,
                },
              })
              .then((appStatus) => {
                setIsLoading(false);
                dispatchRedux(setAppState(appStatus));
                initUser();
                // if (res?.user?.questions) {
                // if (!res?.user?.accountType) {
                //   navigate(APP_ROUTES.AUTH.ACCOUNT_TYPE);
                // } else

                if (!res?.user?.userName) {
                  navigate(APP_ROUTES.AUTH.SET_USER_NAME);
                } else {
                  navigate(APP_ROUTES.DASHBOARD);
                }

                // } else {
                //   navigate(APP_ROUTES.AUTH.SELECT_ROLE);
                // }
              })
              .catch((err) => {
                setIsLoading(false);
                toast("Sorry try again");
              });
          })
          .catch((err) => {
            setIsLoading(false);
            toast("Sorry try again");
          });
      })
      .catch((err) => {
        setIsLoading(false);
        if (firebaseErrorCodes[err.code]) {
          toast(firebaseErrorCodes[err.code]);
        } else {
          toast("Something went wrong");
        }
      });
  }

  const [firebase, setFirebase] = useState([]);
  const handleFirebaseGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      setFirebase(result.user);
    } catch (error) {
      toast("Please try again");
      console.error(error.message);
    }
  };

  const handleFirebaseFacebookLogin = async () => {
    try {
      const result = await signInWithPopup(auth, facebookprovider);
      setFirebase(result.user);
    } catch (error) {
      toast("Please try again");
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (!firebase?.uid) return;
    else {
      axiosInstance
        .post(API_ROUTES.AUTH.FIREBASE_GOOGLE_LOGIN, {
          uid: firebase?.uid,
        })
        .then((res) => {
          dispatchRedux(setUserData(res));
          axiosInstance
            .get(COMMON.APPLICATION_STATUS, {
              headers: {
                Authorization: `Bearer ${res?.tokens?.user?.token}`,
              },
            })
            .then((appStatus) => {
              dispatchRedux(setAppState(appStatus));
              initUser();
              // if (res?.user?.questions) {
              // if (!res?.user?.accountType) {
              //   navigate(APP_ROUTES.AUTH.ACCOUNT_TYPE);
              // } else
              if (!res?.user?.userName) {
                navigate(APP_ROUTES.AUTH.SET_USER_NAME);
              } else {
                navigate(APP_ROUTES.DASHBOARD);
              }
              // } else {
              //   navigate(APP_ROUTES.AUTH.SELECT_ROLE);
              // }
            });
        })
        .catch((err) => {
          toast("Sorry try again");
        });
    }
  }, [firebase?.uid]);

  function handleSubmit(event) {
    event.preventDefault();
    if (validateForm()) {
      handleSignup();
    } else {
      navigate(APP_ROUTES.AUTH.VERIFICATION_LINK_EXP);
    }
  }

  const handleHomeRoute = () => {
    navigate(APP_ROUTES.AUTH.LOGIN);
  };
  useEffect(() => {
    if (validateForm(form)) {
      setIsValidated(true);
    } else {
      setIsValidated(false);
    }
  }, [form]);
  return {
    form,
    isValidated,
    isLoading,
    continueWithEmail,
    setContinueWithEmail,
    handleChange,
    handleSubmit,
    handleFirebaseFacebookLogin,
    handleFirebaseGoogleLogin,
    handleHomeRoute,
  };
}
