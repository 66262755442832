import React from "react";

const Spinner = (props) => {
  const { spinnerClass } = props;
  return (
    <div className={`flex items-center justify-center`}>
      <div
        className={`animate-spin rounded-full border-4 border-solid border-t-transparent ${spinnerClass}`}
      ></div>
    </div>
  );
};

export default Spinner;
