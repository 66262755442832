import React from "react";

function Leadsie({ org }) {
  const url = `${process.env.REACT_APP_LEADSIE_ENDPOINT}${org._id}`;

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <iframe
        src={url}
        title="Leadsie Iframe"
        style={{
          height: "100vh",
          width: "100vw",
        }}
      ></iframe>
    </div>
  );
}

export default Leadsie;
