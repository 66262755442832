import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import useAxios from "hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";

const CreateAudienceSegmentModal = (props) => {
  const {
    handleCreateSegment,
    currentAudienceSegment,
    primaryAction,
    isEdit = false,
    handleExitEdit,
  } = props;
  const [audienceName, setAudienceName] = useState(
    currentAudienceSegment?.name || ""
  );
  const [metaAudiences, setMetaAudiences] = useState([]);
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const [items, setItems] = useState(
    currentAudienceSegment?.items || [
      {
        refId: "",
        aggregationLogic: "or",
        criteria: "includes",
      },
    ]
  );
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
      },
    },
  };

  const { axiosOrgInstance } = useAxios();
  const fetchMetaAudiences = async () => {
    axiosOrgInstance.get("/organization/meta-audiences").then((res) => {
      setMetaAudiences(res);
    });
  };
  useEffect(() => {
    fetchMetaAudiences();
  }, []);

  const handleAddItem = () => {
    const newItem = {
      refId: "",
      aggregationLogic: "or",
      metaRefId: "",
      criteria: "includes",
    };
    setItems([...items, newItem]);
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleItemChange = (index, field, value) => {
    if (field === "refId") {
      const updatedItems = [...items];
      updatedItems[index][field] = value;
      const metaAudience = metaAudiences.find((elem) => elem?._id === value);
      if (metaAudience) {
        updatedItems[index].metaRefId = metaAudience.refId;
      } else {
        updatedItems[index]["metaRefId"] = "";
      }
      setItems(updatedItems);
    } else {
      const updatedItems = [...items];
      updatedItems[index][field] = value;
      setItems(updatedItems);
    }
  };

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setModal(modalInitState)); // Close the modal
  };

  const handleSave = () => {
    const payload = {
      name: audienceName,
      // organizationId: selectedOrganisation?._id,
      organizationId:
        selectedOrganisation?._id || currentAudienceSegment?.organizationId,
      items: items,
    };
    if (isEdit) {
      handleCreateSegment({ ...payload, _id: currentAudienceSegment?._id });
    } else {
      handleCreateSegment(payload);
    }
    handleClose();
  };
  const { open, type } = useSelector((state) => state.modal);
  return (
    <div>
      <Dialog
        open={open && type === MODALTYPES.CREATE_AUDIENCE_SEGMENT}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Create Audience Segment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details for the audience segment.
          </DialogContentText>

          {/* Input for Audience Name */}
          <TextField
            autoFocus
            margin="dense"
            label="Audience Name"
            type="text"
            fullWidth
            value={audienceName}
            onChange={(e) => setAudienceName(e.target.value)}
          />

          {/* Dropdowns for selecting items */}
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {items.map((item, index) => {
              if (item === "metaRefId") {
                return null;
              } else {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      rowGap: "15px",
                    }}
                  >
                    <FormControl
                      style={{
                        width: "30%",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <InputLabel id={`test-select-label-condition-${index}`}>
                        Filter By
                      </InputLabel>
                      <Select
                        labelId={`test-select-label-condition-${index}`}
                        value={item?.criteria}
                        label="Filter By"
                        onChange={(e) =>
                          handleItemChange(index, "criteria", e.target.value)
                        }
                      >
                        <MenuItem value="includes">INCLUDE</MenuItem>
                        <MenuItem value="excludes">EXCLUDE</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      style={{
                        width: "30%",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <InputLabel id={`test-select-label-aggregator-${index}`}>
                        Aggregator Logic
                      </InputLabel>
                      <Select
                        labelId={`test-select-label-aggregator-${index}`}
                        value={item?.aggregationLogic}
                        label="Aggregator Logic"
                        onChange={(e) =>
                          handleItemChange(
                            index,
                            "aggregationLogic",
                            e.target.value
                          )
                        }
                      >
                        <MenuItem value="or">OR</MenuItem>
                        <MenuItem value="and">AND</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      style={{
                        marginRight: "10px",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <InputLabel id={`test-select-label-meta-${index}`}>
                        Meta Audience
                      </InputLabel>
                      <Select
                        labelId={`test-select-label-meta-${index}`}
                        value={item?.refId}
                        label="Meta Audience"
                        onChange={(e) => {
                          handleItemChange(index, "refId", e.target.value);
                        }}
                        MenuProps={MenuProps}
                      >
                        {metaAudiences.map((metaAudience) => (
                          <MenuItem
                            key={metaAudience?._id}
                            value={metaAudience?._id}
                          >
                            {metaAudience?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteItem(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );
              }
            })}
          </div>

          {/* Button to add more items */}
          <Button variant="outlined" onClick={handleAddItem}>
            Add Item
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateAudienceSegmentModal;
