import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { API_ROUTES } from "../../../../../routes";
import useAxios from "../../../../../hooks/useAxios";
import {
  MODALTYPES,
  setModal,
  modalInitState,
} from "../../../../../redux/slices/modal.slices";
import useAppState from "../../../../../hooks/useAppState";

export default function useAddAccounts() {
  // const navigate = useNavigate();
  const { axiosOrgInstance } = useAxios();
  const [state, setState] = React.useState([]);
  const { adAccount } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { updateAppState } = useAppState();

  const listMultipleAddAccounts = useCallback(() => {
    //this is axios organsisation instance
    axiosOrgInstance
      .get(
        API_ROUTES.AUTH.GET_AD_ACCOUNTS.replace(
          ":facebookUserId",
          adAccount?.userID
        ),
        {
          params: {
            accessToken: adAccount?.accessToken,
          },
        }
      )
      .then((res) => {
        let data = res?.map((e) => {
          return {
            ...e,
            selected: false,
            visible: true,
          };
        });
        setState(data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSelect(id) {
    let data = [...state];
    let updatedData = data.map((e) => {
      return e.accountId === id ? { ...e, selected: !e.selected } : e;
    });
    setState(updatedData);
  }

  function handleNext() {
    axiosOrgInstance
      .post(API_ROUTES.AUTH.CONNECT_AD_ACCOUNTS, {
        accessToken: adAccount?.accessToken,
        adAccounts: getSelectedAccounts()?.map((account) => {
          let _account = { ...account }; //for immutability
          delete _account.selected;
          delete _account.visible;
          return {
            ..._account,
            balance: +_account.balance,
          };
        }),
      })
      .then((res) => {
        let length = getSelectedAccounts()?.length;
        dispatch(
          setModal({
            open: true,
            type: MODALTYPES.SUCCESS,
            props: {
              success_msg: `You Successfully Added ${length} Ad Account${
                length > 0 ? "s" : ""
              }`,
            },
          })
        );

        setTimeout(() => {
          dispatch(setModal(modalInitState));
          updateAppState();
        }, 2000);
      });
  }

  useEffect(() => {
    if (adAccount?.userID) {
      listMultipleAddAccounts();
    }
  }, [listMultipleAddAccounts, adAccount?.userID]);

  const getSelectedAccounts = useCallback(() => {
    let data = [...state];
    let updatedData = data.filter((e) => {
      return e.selected;
    });
    return updatedData;
  }, [state]);

  const FilterAdAccounts = useCallback(
    (e) => {
      let data = [...state];
      let updatedData = data.map((item) => {
        let name = item?.name ? item.name.toLowerCase() : "";
        return {
          ...item,
          visible: name.includes(e.target.value.toLowerCase()),
        };
      });
      setState(updatedData);
    },
    [state]
  );
  return {
    state,
    handleSelect,
    getSelectedAccounts,
    handleNext,
    adAccount,
    FilterAdAccounts,
  };
}
