import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { add_tags } from "../../../assets/images";
import useAppState from "../../../hooks/useAppState";
import useAxios from "../../../hooks/useAxios";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import { MODALTYPES, setModal } from "../../../redux/slices/modal.slices";
import { API_ROUTES, APP_ROUTES } from "../../../routes";
import { GROUP_ENTITY } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";

export default function useAudienceTagging(isManuallyTagged) {
  const { axiosOrgInstance } = useAxios();
  const [groups, setGroups] = React.useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const { isLoadMore } = useInfiniteScroll("content-container", hasNextPage);
  const { updateAppState } = useAppState();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  function initGroups() {
    axiosOrgInstance
      .get(API_ROUTES.ONBOARDING.LIST_ORG_GROUPS, {
        params: {
          groupEntity: GROUP_ENTITY.CUSTOM_AUDIENCE,
          isManualGroupingDone: 1,
          isGroupManualTaggingDone: isManuallyTagged ? 1 : 0,
        },
      })
      .then((res) => {
        setGroups(res?.docs);
        setHasNextPage(res?.hasNextPage);
        setPage(res?.page);
        setTotalDocs(res?.totalDocs);
        if (res?.totalDocs === 0) {
          updateAppState();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleSkipUpdate() {
    dispatch(
      setModal({
        type: MODALTYPES.BASIC_ACTION.ADD_SYS_GENERATED_TAGS,
        open: true,
        payload: {
          action_sure_msg: `Do You Want To Add System Generated Tags?`,
          action_warning_msg:
            "In This AI Automatically Adds Tags In Your Audience Groups",
          primaryBtnText: "Yes",
          secondaryBtnText: "Cancel",
          dangerMode: false,
          image: add_tags,
        },
      })
    );
  }

  function addSysGeneratedTagsModalPrimaryAction() {
    axiosOrgInstance
      .post(API_ROUTES.ONBOARDING.SKIP_MANUAL_TAGS, {
        groupEntity: GROUP_ENTITY.CUSTOM_AUDIENCE,
      })
      .then((res) => {
        toast.success("System Generated Tags Added Successfully");
        navigate(APP_ROUTES.ONBOARDING.ONBOARDING_GROUPS);
        initGroups();
      });
  }

  useEffect(() => {
    initGroups();
  }, []);

  useEffect(() => {
    initGroups();
  }, [isManuallyTagged]);

  useEffect(() => {
    if (isLoadMore && hasNextPage) {
      axiosOrgInstance
        .get(API_ROUTES.ONBOARDING.LIST_ORG_GROUPS, {
          params: {
            groupEntity: GROUP_ENTITY.CUSTOM_AUDIENCE,
            isManualGroupingDone: 1,
            isGroupManualTaggingDone: isManuallyTagged ? 1 : 0,
            page: page + 1,
          },
        })
        .then((res) => {
          setHasNextPage(res?.hasNextPage);
          setPage(res?.page);
          setGroups((prev) => [...prev, ...res?.docs]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadMore]);
  return {
    groups,
    totalDocs,
    initGroups,
    handleSkipUpdate,
    addSysGeneratedTagsModalPrimaryAction,
  };
}
