import {
  LayoutDashboard,
  Brain,
  SquareActivity,
  Tags,
  Video,
  ScanEye,
  BadgeCheck,
  File,
  CirclePlus,
  Users,
  Plus,
  SquareArrowOutUpRight,
  LogOut,
  Settings,
  User,
  BookMarked,
  Receipt,
  RefreshCcwDot,
  Search,
  PanelRight,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

import ModernSidebar, { SidebarItem } from "./NewSidebar";
import { APP_ROUTES } from "routes";
import { useSelector } from "react-redux";
import useUserRole from "hooks/useUserRole";
import useAppState from "hooks/useAppState";
import { ACTIVE_FEATURES } from "config/roles";
import { useNavigate } from "react-router-dom";
import { setSelectedOrganisation } from "redux/slices/organisation.slice";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import moment from "moment";
import { CircularProgress, Divider, Tooltip } from "@mui/material";
import SidebarFolders from "../FolderTree/FolderTree";
import MoveReportToFolderModal from "components/modals/MoveReportToFolderModal/MoveReportToFolder";
import { useMoveReportToFolder } from "apis/insightsApi";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import useAuth from "hooks/useAuth";
import { toast } from "@/components/ui/use-toast";
import { useRefreshOrganization } from "apis/common";
import ResizableSidebar from "./ResizeableSidebar";
import { setSidebarOpen } from "redux/slices/appState.slices";
import { Button } from "@/components/ui/button";
import _ from "lodash";

const subMenuOptions = [
  {
    label: "Creative Feature",
    value: APP_ROUTES.RECOMMENDATION.CREATIVE_RECOMMENDATION,
  },
  {
    label: "Video Intelligence",
    value: APP_ROUTES.RECOMMENDATION.VIDEO_RECOMMENDATION,
  },
];

const orgOnBoardedSidebarList = [
  {
    label: "Dashboard",
    value: APP_ROUTES.DASHBOARD,
    icon: <LayoutDashboard size={16} />,
  },
  {
    label: "Generation",
    value: APP_ROUTES.GENERATION.default,
    icon: <SquareActivity size={16} />,
  },
  {
    label: "Bookmarked Reports",
    value: APP_ROUTES.REPORTS.default,
    icon: <File size={16} />,
  },
  {
    label: "Create report",
    value: null,
    openModal: MODALTYPES.CREATE_REPORTS,
    icon: <CirclePlus size={16} />,
  },
  {
    label: "Groups & Tags",
    value: APP_ROUTES.GROUPS_TAGS.default,
    icon: <Tags size={16} />,
  },
];

const employeeOptions = [
  {
    label: "Dashboard",
    value: APP_ROUTES.DASHBOARD,
    icon: <LayoutDashboard size={16} />,
  },
  {
    label: "Generation",
    value: APP_ROUTES.GENERATION.default,
    icon: <SquareActivity size={16} />,
  },
  {
    label: "Bookmarked Reports",
    value: APP_ROUTES.REPORTS.default,
    icon: <File size={16} />,
  },
  {
    label: "Create report",
    value: null,
    openModal: MODALTYPES.CREATE_REPORTS,
    icon: <CirclePlus size={16} />,
  },
];

const OrgNotOnBoardedList = [
  {
    label: "Dashboard",
    value: APP_ROUTES.DASHBOARD,
    icon: <LayoutDashboard size={16} />,
  },
  {
    label: "Bookmarked Reports",
    value: APP_ROUTES.REPORTS.default,
    icon: <File size={16} />,
  },
];

const GroupsTags = [
  APP_ROUTES.GROUPS_TAGS.default,
  ...Object.values(APP_ROUTES.GROUPS_TAGS.TAGGING),
  ...Object.values(APP_ROUTES.GROUPS_TAGS.UNGROUPED),
  ...Object.values(APP_ROUTES.GROUPS_TAGS.UNTAGGED),
];

function MainSidebar(props) {
  const { mutateAsync: moveReport, isLoading: moveReportToFolderLoading } =
    useMoveReportToFolder();

  // const {
  //   profileDetails,
  //   setProfileDetails,
  //   updateBasicDetails,
  //   getProfileDetails,
  //   isLoading,
  // } = useProfileDetails();
  const { user } = useSelector((state) => state?.auth?.userData) || {
    user: {},
  };

  const moveReportToFolder = (folderId, reportId) => {
    moveReport({ reportableId: reportId, folderId });
  };
  const { activePage } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isUserAuthenticated } = useAuth();
  const { mutate: refreshOrganisation } = useRefreshOrganization();

  const { allOrganisations, selectedOrganisation } = useSelector(
    (state) => state.organisation
  );

  const { organisationOnBoarded } = useAppState();
  const { hasUserPermission, hasUserActiveFeature, isOrgInTrialMode } =
    useUserRole();
  const { appState } = useSelector((state) => state?.appState);
  const [showNavbar, setShowNavbar] = useState(true);
  const open = showNavbar;

  const activeFeatures = appState?.activeFeatures;

  useEffect(() => {
    if (isUserAuthenticated) {
      if (allOrganisations && !selectedOrganisation?.name) {
        if (allOrganisations?.length > 0) {
          organisationChangeHandler(allOrganisations?.[0]);
        } else {
          navigate(APP_ROUTES.AUTH.ADD_ORGANIZATION);
        }
      }
    }
  }, [allOrganisations]);

  const shouldHideTab = (menu, activeFeatures, enableSidebar) => {
    if (menu.label === "Recommendations") {
      return (
        !hasUserActiveFeature(ACTIVE_FEATURES.RECOMMENDATION) || !enableSidebar
      );
    }
    if (menu.label === "Insights") {
      return !hasUserActiveFeature(ACTIVE_FEATURES.INSIGHTS) || !enableSidebar;
    }
    if (menu.label === "Prediction") {
      return (
        !hasUserActiveFeature(ACTIVE_FEATURES.PREDICTION) || !enableSidebar
      );
    }
    if (menu.label === "Generation") {
      return (
        !hasUserActiveFeature(ACTIVE_FEATURES.GENERATION) || !enableSidebar
      );
    }

    return false;
  };

  const InsightsSubMenus = Object.values(APP_ROUTES.INSIGHTS);
  const RecommendationSubMenus = Object.values(APP_ROUTES.RECOMMENDATION);

  let enableSidebar = true;
  const isActiveLink = (activePage, menu, enableSidebar) => {
    switch (menu?.value) {
      case APP_ROUTES.GROUPS_TAGS.default:
        return (
          GroupsTags.includes(activePage) &&
          activePage === menu?.value &&
          enableSidebar
        );

      case APP_ROUTES.INSIGHTS.default:
        return InsightsSubMenus.includes(activePage) && enableSidebar;

      case APP_ROUTES.RECOMMENDATION.default:
        return RecommendationSubMenus.includes(activePage) && enableSidebar;
      default:
        return activePage === menu?.value && enableSidebar;
    }
  };

  const handleOrganisationRefresh = () => {
    if (selectedOrganisation?.platforms?.length === 0) {
      return toast("Error! No platform added to be synced");
    }
    let connector = [];
    if (Array.isArray(selectedOrganisation?.platforms)) {
      connector = selectedOrganisation?.platforms?.filter(
        (curr) => !curr?.isInternal
      );
    } else {
      if (!selectedOrganisation?.platforms?.isInternal) {
        connector = [...selectedOrganisation?.platforms];
      }
    }
    if (connector?.length === 0) {
      return toast("Error! Fivetran connector not connected");
    }
    const requestPayload = {
      created: "2023-10-15T14:23:53.422Z",
      connector_type: "",
      connector_id: connector?.[0]?.connectorId,
      data: {
        status: "SUCCESSFUL",
        reason: "",
        rescheduledAt: "",
      },
      destination_group_id: "",
      event: "sync_end",
      sync_id: "",
    };
    refreshOrganisation(requestPayload);
  };

  function organisationChangeHandler(org) {
    navigate(APP_ROUTES.DASHBOARD);
    dispatch(setSelectedOrganisation(org));
  }

  function TrialCountdown() {
    const trialEndingIn = selectedOrganisation?.trialEndingOn;

    if (!trialEndingIn) return false;
    const now = moment().toDate();

    const differenceInDays = moment(trialEndingIn).diff(now, "days");
    let message;
    if (differenceInDays === 1 || differenceInDays === 0) {
      message = `Trial ending today`;
    } else if (differenceInDays > 1) {
      message = `Trial ending in ${differenceInDays} days`;
    }
    return message;
  }

  const [isOrgOpen, setIsOrgOpen] = useState(false);

  function handleOrgChange(orgName) {
    allOrganisations &&
      allOrganisations?.map((organization) => {
        if (organization?.name === orgName) {
          organisationChangeHandler(organization);
        }
      });
    setIsOrgOpen(false);
  }
  let lastUpdatedAt;
  if (
    selectedOrganisation?.lastUpdatedAt &&
    Array.isArray(selectedOrganisation?.lastUpdatedAt)
  ) {
    let temp = _.cloneDeep(selectedOrganisation?.lastUpdatedAt);
    lastUpdatedAt = temp.sort(
      (a, b) => new Date(a?.lastUpdated) - new Date(b?.lastUpdated)
    );
  }

  return (
    <>
      <div className="flex">
        <div className="min-w-[60px] max-w-[60px] bg-gray-200 flex justify-center p-2 border-r-1">
          <div class="relative w-32 ">
            <div class="absolute left-1 top-1 w-16 grid  grid-cols-1 gap-6">
              <Popover open={isOrgOpen}>
                <PopoverTrigger
                  asChild
                  className="w-fit shadow-sm cursor-pointer text-xs font-medium"
                  onClick={() => setIsOrgOpen(true)}
                >
                  <div className="flex justify-center items-center shadow-2xl h-7 mt-4 cursor-pointer text-xs font-medium">
                    {selectedOrganisation?.profilePicture ? (
                      <div className="rounded-full">
                        <Avatar>
                          <AvatarImage
                            src={selectedOrganisation?.profilePicture}
                            alt="organisation_profile_picture"
                          />
                        </Avatar>
                      </div>
                    ) : (
                      <p className="bg-[#7167FB] text-white py-3 px-4 font-semibold w-10 h-10 flex items-center justify-center rounded-full">
                        {selectedOrganisation?.name?.slice(0, 2)?.toUpperCase()}
                      </p>
                    )}
                  </div>
                </PopoverTrigger>

                <PopoverContent
                  className="w-fit p-0 text-xs font-medium"
                  side="right"
                  align="start"
                  onFocusOutside={() => {
                    setIsOrgOpen(false);
                  }}
                  onInteractOutside={() => {
                    setIsOrgOpen(false);
                  }}
                >
                  <Command>
                    <CommandInput placeholder="Search organisation..." />
                    <CommandEmpty>No organisation found.</CommandEmpty>
                    <CommandList>
                      <CommandGroup className="h-[90%]">
                        {allOrganisations &&
                          allOrganisations?.map((org) => (
                            <CommandItem
                              className="cursor-pointer text-xs font-medium"
                              onSelect={(val) => handleOrgChange(val)}
                              defaultValue={selectedOrganisation?.name}
                              key={org?.id}
                              name={org?.name}
                              value={org?.name}
                            >
                              {org?.profilePicture ? (
                                <div className="rounded-full">
                                  <Avatar className="w-6 h-6 mr-3">
                                    <AvatarImage
                                      src={org?.profilePicture}
                                      alt="organisation_profile_picture"
                                    />
                                  </Avatar>
                                </div>
                              ) : (
                                <p className="bg-[#7167FB] text-white p-3 text-[10px] font-medium w-1 h-1 flex items-center justify-center rounded-full mr-3">
                                  {org?.name?.slice(0, 2)?.toUpperCase()}
                                </p>
                              )}
                              {org?.name}
                              {org?.isDataPullCompleted && (
                                <BadgeCheck
                                  className="ml-2 h-5 w-5"
                                  color="white"
                                  fill="blue"
                                />
                              )}
                            </CommandItem>
                          ))}
                      </CommandGroup>
                      <CommandSeparator />
                      <button
                        type="link"
                        className="cursor-pointer p-2 ml-2 text-sm text-indigo-500 rounded-sm bg-white"
                        onClick={() =>
                          navigate(APP_ROUTES.AUTH.ADD_ORGANIZATION)
                        }
                      >
                        + Add Organization
                      </button>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              <div className="items-center cursor-pointer ml-[10px] mt-2">
                <Tooltip
                  title={showNavbar ? `Collapse sidebar` : `Expand sidebar`}
                  arrow
                  placement="right"
                  offset={0}
                >
                  <PanelRight
                    size={18}
                    onClick={() => {
                      setShowNavbar((prevState) => !prevState);
                      dispatch(setSidebarOpen(!appState.isSidebarOpen));
                    }}
                  />
                </Tooltip>
              </div>

              {selectedOrganisation?.heartbeat?.isOrgSyncIsRunning ? (
                <div>
                  <svg width={1} height={1}>
                    <defs>
                      <linearGradient
                        id="my_gradient"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                      >
                        <stop offset="0%" stopColor="#e01cd5" />
                        <stop offset="100%" stopColor="#1CB5E0" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <Tooltip
                    title="Data fetching in progress"
                    arrow
                    placement="right"
                    offset={0}
                  >
                    <CircularProgress
                      sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
                      size={18}
                      className="ml-[10px] mt-2"
                      thickness={3.6}
                    />
                  </Tooltip>
                </div>
              ) : (
                <AlertDialog>
                  <AlertDialogTrigger className="w-full ml-[10px] mt-2">
                    <div className="items-center cursor-pointer">
                      <Tooltip
                        title={`Fetch Latest data? ${
                          lastUpdatedAt && lastUpdatedAt[0]
                            ? `Latest data fetched at ${moment(
                                lastUpdatedAt[0]?.lastUpdated
                              ).format("DD MMM YYYY, hh:mm A")}`
                            : ""
                        } `}
                        arrow
                        placement="right"
                        offset={0}
                      >
                        <RefreshCcwDot size={18} />
                      </Tooltip>
                    </div>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        Are you absolutely sure?
                      </AlertDialogTitle>
                      <AlertDialogDescription>
                        This action cannot be undone. This will fetch latest
                        data from cloud, This will take time and run in
                        background.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction onClick={handleOrganisationRefresh}>
                        Continue
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              )}

              <div className="item-center ml-1 mt-1 cursor-pointer">
                <Tooltip
                  onClick={() => {
                    dispatch(
                      setModal({
                        open: true,
                        type: MODALTYPES.TYPEAHEAD_MODAL,
                        payload: {
                          organizationId: selectedOrganisation._id,
                        },
                      })
                    );
                  }}
                  title="Search"
                  arrow
                  placement="right"
                >
                  <Search className="ml-2 w-5 h-5" />
                </Tooltip>
              </div>
            </div>

            <div class="absolute grid gap-6 grid-cols-1 bottom-0 left-0 w-16 ">
              <div>
                <Tooltip
                  onClick={() => {
                    navigate(APP_ROUTES.AUTH.ADD_ORGANIZATION);
                  }}
                  title="Add Organization"
                  arrow
                  placement="right"
                >
                  <div
                    className="items-center cursor-pointer"
                    style={{ width: "fit-content" }}
                  >
                    <Plus size={16} className="ml-4" />
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  onClick={() => {
                    dispatch(
                      setModal({
                        open: true,
                        type: MODALTYPES.INVITE_USER_MODAL,
                        payload: {},
                      })
                    );
                    navigate(APP_ROUTES.SETTING, {
                      state: {
                        selectedTab: "manageUser",
                      },
                    });
                  }}
                  title="Invite Users"
                  arrow
                  placement="right"
                >
                  <div
                    className="items-center cursor-pointer"
                    style={{ width: "fit-content" }}
                  >
                    <Users size={16} className="ml-4" />
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  onClick={() => {
                    navigate(APP_ROUTES.SETTING);
                  }}
                  title="Organisation Settings"
                  arrow
                  placement="right"
                >
                  <div
                    className="items-center cursor-pointer"
                    style={{ width: "fit-content" }}
                  >
                    <Settings size={16} className="ml-4" />
                  </div>
                </Tooltip>
              </div>
              <div>
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <Avatar className="ml-1">
                      <AvatarImage
                        src={user?.profilePicture}
                        alt="user profile pic"
                      />
                      <AvatarFallback className="capitalize text-2xl text-center">
                        {user?.userName?.[0]}
                      </AvatarFallback>
                    </Avatar>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-56 z-[99990] ml-10 text-xs">
                    <DropdownMenuLabel className="text-xs">
                      {selectedOrganisation?.name?.slice(0, 20)?.toUpperCase()}
                      {selectedOrganisation?.name?.length > 20 ? "..." : ""}
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                      <DropdownMenuItem
                        className="text-xs font-medium"
                        onClick={() => {
                          navigate(APP_ROUTES.SETTING, {
                            state: {
                              selectedTab: "account",
                            },
                          });
                        }}
                      >
                        <User className="mr-2 h-4 w-4" />
                        <span>Profile</span>
                        <DropdownMenuShortcut>
                          <SquareArrowOutUpRight className="p-2" />
                        </DropdownMenuShortcut>
                      </DropdownMenuItem>
                      {/* {hasUserPermission(A_PERMISSIONS.VIEW_ACCOUNT) ? (
                        <DropdownMenuItem
                          className="text-xs font-medium"
                          onClick={() => navigate(APP_ROUTES.SETTING)}
                        >
                          <Settings className="mr-2 h-4 w-4" />
                          <span>Organization Settings</span>
                          <DropdownMenuShortcut>
                            <SquareArrowOutUpRight className="p-2" />
                          </DropdownMenuShortcut>
                        </DropdownMenuItem>
                      ) : null} */}
                      {/* {hasUserPermission(SA_PERMISSIONS.ADD_USER) ? (
                        <DropdownMenuItem
                          className="text-xs font-medium"
                          onClick={() => {
                            dispatch(
                              setModal({
                                open: true,
                                type: MODALTYPES.INVITE_USER_MODAL,
                                payload: {},
                              })
                            );
                            navigate(APP_ROUTES.SETTING, {
                              state: {
                                selectedTab: "manageUser",
                              },
                            });
                          }}
                        >
                          <Users className="mr-2 h-4 w-4" />
                          <span>Add Member</span>
                          <DropdownMenuShortcut>
                            <SquareArrowOutUpRight className="p-2" />
                          </DropdownMenuShortcut>
                        </DropdownMenuItem>
                      ) : null} */}
                      <DropdownMenuItem
                        className="text-xs font-medium"
                        onClick={() => navigate(APP_ROUTES.DASHBOARD)}
                      >
                        <BookMarked className="mr-2 h-4 w-4" />
                        <span>Learn More</span>
                        <DropdownMenuShortcut>
                          <SquareArrowOutUpRight className="p-2" />
                        </DropdownMenuShortcut>
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      className="text-xs font-medium"
                      onClick={() => navigate(APP_ROUTES.AUTH.LOGOUT)}
                    >
                      <LogOut className="mr-2 h-4 w-4" />
                      <span>Log out</span>
                      <DropdownMenuShortcut>
                        <SquareArrowOutUpRight className="p-2" />
                      </DropdownMenuShortcut>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </div>
        <ResizableSidebar showNavbar={showNavbar}>
          <div className="bg-gray-100 w-full h-full">
            <ModernSidebar open={showNavbar}>
              <div className="h-[15%]">
                {(organisationOnBoarded
                  ? hasUserPermission("GROUPING")
                    ? orgOnBoardedSidebarList
                    : employeeOptions
                  : OrgNotOnBoardedList
                )?.map((menu) => {
                  if (shouldHideTab(menu, activeFeatures, enableSidebar)) {
                    return null;
                  }
                  return (
                    <>
                      {!open && menu?.label === "Recommendations" ? (
                        <DropdownMenu>
                          <DropdownMenuTrigger
                            asChild
                            className="text-xs font-medium"
                          >
                            <div>
                              <SidebarItem
                                open={showNavbar}
                                key={menu?.label}
                                onClick={() => navigate(menu?.value)}
                                icon={menu?.icon}
                                text={menu?.label}
                                active={isActiveLink(
                                  activePage,
                                  menu,
                                  enableSidebar
                                )}
                              />
                            </div>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="w-56 p-4 ml-20 text-xs font-medium">
                            <DropdownMenuLabel>My Account</DropdownMenuLabel>
                            <DropdownMenuGroup>
                              <DropdownMenuItem
                                onClick={() =>
                                  navigate(
                                    APP_ROUTES.RECOMMENDATION
                                      .CREATIVE_RECOMMENDATION
                                  )
                                }
                              >
                                <ScanEye className="mr-2 h-4 w-4" />
                                <span>Creative Intelligence</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() =>
                                  navigate(
                                    APP_ROUTES.RECOMMENDATION
                                      .VIDEO_RECOMMENDATION
                                  )
                                }
                              >
                                <Video className="mr-2 h-4 w-4" />
                                <span>Video Intelligence</span>
                              </DropdownMenuItem>
                            </DropdownMenuGroup>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      ) : (
                        <SidebarItem
                          open={showNavbar}
                          key={menu?.label}
                          onClick={() => {
                            if (menu?.openModal) {
                              dispatch(
                                setModal({
                                  open: true,
                                  type: menu.openModal,
                                  payload: {},
                                })
                              );
                              return;
                            }
                            navigate(menu?.value);
                          }}
                          icon={menu?.icon}
                          text={menu?.label}
                          active={
                            menu?.label === "Recommendations"
                              ? false
                              : isActiveLink(activePage, menu, enableSidebar)
                          }
                        />
                      )}

                      {/* these are the accordion type sub-types of recommendation */}
                      {isActiveLink(
                        activePage,
                        {
                          label: "Recommendations",
                          value: APP_ROUTES.RECOMMENDATION.default,
                          icon: <Brain />,
                        },
                        enableSidebar
                      ) &&
                        open &&
                        menu?.label === "Recommendations" && (
                          <>
                            {subMenuOptions?.map((submenu) => (
                              <SidebarItem
                                open={showNavbar}
                                key={submenu?.label}
                                onClick={() => navigate(submenu?.value)}
                                text={submenu?.label}
                                active={isActiveLink(
                                  activePage,
                                  submenu,
                                  enableSidebar
                                )}
                              />
                            ))}
                          </>
                        )}
                    </>
                  );
                })}
              </div>

              {/* for the upgrade plan section */}
              {/* {(hasUserPermission(A_PERMISSIONS.VIEW_ACCOUNT) &&
            hasUserPermission(A_PERMISSIONS.HANDLE_PAYMENTS)
              ? !isUserSubscribed()
                ? [
                    {
                      label: "Upgrade Plan",
                      value: APP_ROUTES.UPGRADE_PLAN,
                      icon: <CircleDollarSign />,
                    },
                  ]
                : []
              : []
            )?.map((menu) => (
              <SidebarItem
                key={menu?.label}
                onClick={() => navigate(menu?.value)}
                icon={menu?.icon}
                text={menu?.label}
                active={isActiveLink(activePage, menu, enableSidebar)}
                alert={true}
                danger={true}
              />
            ))} */}

              {selectedOrganisation?.name && <Divider />}

              <div className="h-[75%] flex flex-col justify-between">
                {/* Folder part start */}
                {selectedOrganisation?.name && (
                  <SidebarFolders
                    open={open}
                    moveReportToFolderLoading={moveReportToFolderLoading}
                  />
                )}
                {/* folder part ends */}
              </div>
              {open && isOrgInTrialMode() && (
                <div className="absolute bottom-5 flex w-fit gap-x-3 ml-5 justify-center max-h-min border border-[#bcc1f9] bg-[#f5f6ff] p-2 rounded-[6px] shadow-md">
                  <div className="flex flex-col">
                    <p className="text-xs text-black font-medium">
                      Trial plan ends
                    </p>
                    <p className="text-gray-500 text-xs font-medium">
                      {TrialCountdown(selectedOrganisation?.trialEndingOn)}
                    </p>
                  </div>
                  <div>
                    <Receipt className="h-8 w-9" />
                    {/* <Button className="bg-blue-500 hover:bg-blue-400 flex items-center text-xs h-7 w-min"> */}
                    {/* Manage */}
                    {/* </Button> */}
                  </div>
                </div>
              )}
              {open && selectedOrganisation?.heartbeat?.isUpdateAvailable && (
                <div className="absolute bottom-5 flex w-min ml-2 justify-center max-h-min border border-[#bcc1f9] bg-[#f5f6ff] p-2 rounded-[6px] shadow-md">
                  <Button
                    variant="ghost"
                    className="text-xs h-5 w-min"
                    onClick={() => {
                      const heartbeat =
                        selectedOrganisation?.heartbeat?.isUpdateAvailable;
                      delete heartbeat["isUpdateAvailable"];
                      localStorage.setItem(
                        heartbeat,
                        JSON.stringify(heartbeat)
                      );
                      window.location.reload(true);
                    }}
                  >
                    Update available. Refresh now!
                  </Button>
                </div>
              )}
            </ModernSidebar>
            <MoveReportToFolderModal primaryAction={moveReportToFolder} />
          </div>
        </ResizableSidebar>
      </div>
    </>
  );
}

export default MainSidebar;
