import { onMessageListener } from "config/firebase";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

const CustomNotification = ({ title, body }) => (
  <div>
    <h3>{title}</h3>
    <p>{body}</p>
  </div>
);

const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });

  const notify = () => {
    toast.info(
      <CustomNotification
        title={notification.title}
        body={notification.body}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      }
    );
  };

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));
  return null;
};

export default Notification;
