import React from "react";
import PrivateContainer from "../../../components/layouts/PrivateContainer";
import ManualTagged from "./ManualTagged/ManualTagged";
import { useLocation } from "react-router-dom";

export default function ManualTaggedAudienceScreen() {
  const { pathname } = useLocation();
  return (
    <PrivateContainer
      activePage={pathname}
      bodyStyles={{ justifyContent: "flex-start", padding: "24px" }}
    >
      <ManualTagged />
    </PrivateContainer>
  );
}
