import { useEffect, useState } from "react";

export default function useProductShoots() {
  const [productShoots, setProductShoots] = useState([]);

  function getProductShoots() {
    setProductShoots([]);
  }

  function downloadProductsTemplate() {}

  function uploadUproducts() {}

  useEffect(() => {
    getProductShoots();
  }, []);

  return { productShoots, downloadProductsTemplate, uploadUproducts };
}
