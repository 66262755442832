import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { TabNav } from "components/common/molecules";
import { PrivateContainer } from "components/layouts";

import ImageTab from "./ImageTab";
import VideoTab from "./VideoTab";

export default function CreativeGroupsScreen(props) {
  const { appState } = useSelector((state) => state?.appState);

  let [activeTab, setActiveTab] = useState(0);
  const location = useLocation();

  const disabledTabsIndex = [];
  if (!appState.isOrganizationImagesAiGroupingDone) {
    disabledTabsIndex.push(0);
  }
  if (!appState.isOrganizationVideosAiGroupingDone) {
    disabledTabsIndex.push(1);
  }
  const tabs = [
    {
      name: "Image",
      component: <ImageTab />,
    },
    {
      name: "Videos",
      component: <VideoTab />,
    },
  ];

  useEffect(() => {
    if (appState?.isOrganizationImagesManualGroupingDone) {
      setActiveTab(1);
      return;
    }
    let _tab = location?.state?.from === "video" ? 1 : 0;
    setActiveTab(_tab);
  }, []);

  return (
    <PrivateContainer>
      <div style={{ height: "100%" }}>
        <TabNav
          tabs={tabs}
          disabledTabsIndex={disabledTabsIndex}
          activeTabIndex={activeTab}
          disableInactiveTabs={true}
        />
      </div>
    </PrivateContainer>
  );
}
