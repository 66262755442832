"use client";

import * as React from "react";
import { CSVLink } from "react-csv";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ChevronDown,
  Settings,
  Check,
  ChevronsUpDown,
  Download,
  ChevronLeft,
  ChevronRight,
  CirclePlus,
  GripVertical,
  X,
  Plus,
  ListFilter,
  ArrowDownZA,
  ArrowUpAZ,
  Equal,
  BetweenHorizontalEnd,
  Loader2,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useState } from "react";
import { useEffect } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { Separator } from "@/components/ui/separator";
import { useDispatch } from "react-redux";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import _ from "lodash";
import moment from "moment";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { GROUP_BY_COLUMNS, REPORTABLE_TYPE } from "utils/constants";
import {
  LinearProgress,
  Tooltip as MuiTooltip,
  linearProgressClasses,
  styled,
  tooltipClasses,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { createPortal } from "react-dom";
import { twJoin } from "tailwind-merge";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { PopoverArrow, PopoverClose } from "@radix-ui/react-popover";
import { Gauge } from "@/components/ui/gauge";
import { getColorForProgress } from "utils/helper";
import {
  AD_BREAKDOWN_FIELDS,
  AD_BREAKDOWN_FIELDS_REVERSE,
} from "config/statics";

const colToBeRemoved = [
  "groupName",
  "thumbnailUrl",
  "totalNoOfAds",
  "type",
  "lastUpdatedAt",
  "adName",
  "headline",
  "landingPageUrl",
  "groupId",
  "body",
];
export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  zIndex: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    borderRadius: 5,
    border: "2px",
    borderColor: "red",
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 100 : 800],
  },
}));

export const DarkTooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "black",
    padding: "10px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "black",
  },
}));

const ColorTable = (props) => {
  const {
    setSortedData,
    visualisationTab,
    insightsData: data,
    rowSelection,
    setRowSelection,
    selectedMetrices,
    updateKPIs,
    isSelectionValid,
    handlePresetSelect,
    preset,
    presetData,
    specificPresetData,
    filterAndOptions,
    tempKpis,
    setTempKpis,
    colorFormat,
    doesShowColor,
    handleColorFormatChange,
    handleAdBreakdown,
    isShowAdBreakdownOption = true,
    reportableType,
    handleCreativeInsight,
    isLoadingSpecificPreset,
  } = props;

  const Portal = ({ children }) => {
    if (typeof document === "undefined") return null;
    const mount = document.getElementById("portal-root");
    return createPortal(children, mount);
  };

  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnToBeShowed, setColumnToBeShowed] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const DarkTooltip = styled(({ className, ...props }) => (
    <MuiTooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "black",
      padding: "10px",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "black",
    },
  }));

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
  }));

  const COLOR_FORMATING_OPTIONS = [
    {
      src: "/nocolorrange.png",
      onClick: () => handleColorFormatChange(1),
    },
    {
      src: "/greenOnly.svg",
      onClick: () => handleColorFormatChange(2),
    },
    {
      src: "/redRange.svg",
      onClick: () => handleColorFormatChange(3),
    },
    {
      src: "/bothRange.svg",
      onClick: () => handleColorFormatChange(4),
    },
  ];

  const handleSetPerPage = (value) => {
    const total_records = data?.length;
    const _currentPage = pagination?.pageIndex;
    const _total_used_records = _currentPage * value;
    const available_records = total_records - _total_used_records;
    if (available_records < 0 || available_records < value) {
      return false;
    }
    return true;
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedKpis = Array.from(columnToBeShowed);
    const [movedKpi] = reorderedKpis.splice(result.source.index, 1);
    reorderedKpis.splice(result.destination.index, 0, movedKpi);
    setColumnToBeShowed(reorderedKpis);
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) =>
        visualisationTab !== 0 ? (
          <TooltipProvider delayDuration={0} className="z-50">
            <Tooltip>
              <TooltipTrigger asChild>
                <Checkbox
                  className="z-[200]"
                  checked={
                    table.getIsAllPageRowsSelected() ||
                    (table.getIsSomePageRowsSelected() && "indeterminate")
                  }
                  onCheckedChange={(value) =>
                    table.toggleAllRowsSelected(!!value)
                  }
                  aria-label="Select all"
                  disabled={visualisationTab !== 0}
                />
              </TooltipTrigger>
              <TooltipContent className="z-[100] mb-4" sideOffset={5}>
                <p className="z-[100]">You can select only 6 ad groups</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) => table.toggleAllRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            if (!row.getIsSelected() && !isSelectionValid("row")) {
              return;
            }
            row.toggleSelected(!!value);
          }}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
      meta: {
        className: "sticky left-0 bg-white z-10",
        noFilter: true,
      },
    },
    {
      accessorKey: "Ads Group",
      header: ({ column }) => (
        <div
          className={`flex items-center ml-4 hover:border-r-2 flex-wrap text-xs`}
        >
          Ads Group
        </div>
      ),
      cell: ({ row }) => (
        <div
          className={`flex ${
            reportableType === REPORTABLE_TYPE.AD_BREAKDOWN ? "w-28" : "w-64"
          } ml-2 max-h-16 text-xs group`}
        >
          {row.original["objectType"] === "url" ? (
            <div className="flex gap-x-1">
              <img
                className="w-10 h-10 rounded-full cursor-pointer"
                src={row.original["thumbnailUrl"]}
                onClick={() => {
                  if (row?.original?._id) {
                    dispatch(
                      setModal({
                        type: MODALTYPES.MEDIA_ASSET_PREVIEW,
                        open: true,
                        payload: {
                          groupId: row?.original?._id,
                          handleCreativeInsight: handleCreativeInsight,
                        },
                      })
                    );
                  }
                }}
                alt=""
              />
              <div className="ps-1 w-full flex flex-col gap-x-1 gap-y-1">
                <div className="ps-1 font-medium mt-[9px] mr-1 text-blue-800 text-xs">
                  {row.original["totalNoOfAds"] + " ads"}
                </div>
                <div className="flex gap-x-1">
                  <div
                    onClick={() => handleAdBreakdown(row.original["_id"])}
                    className={`ps-1 w-fit text-blue-500 mr-1 cursor-pointer flex justify-center ${
                      !isShowAdBreakdownOption
                        ? "hidden group-hover:hidden"
                        : "group-hover:inline-block"
                    }`}
                  >
                    <p className="hover:underline">Ad breakdown</p>
                  </div>
                  <div
                    onClick={() => handleCreativeInsight(row.original["_id"])}
                    className={`ps-1 w-fit text-blue-500 mr-1 cursor-pointer flex justify-center ${
                      !isShowAdBreakdownOption
                        ? "hidden group-hover:hidden"
                        : "group-hover:inline-block"
                    }`}
                  >
                    <p className="hover:underline">Creative insights</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="ps-3 w-full">
                <div className="text-base font-semibold w-full">
                  {GROUP_BY_COLUMNS.filter((key) => row.original[key]).map(
                    (key) => (
                      <MuiTooltip
                        title={
                          row.original[key]?.length > 8 ? row.original[key] : ""
                        }
                        arrow
                        placement="right"
                      >
                        <p className="truncate">{row.original[key] || "NA"}</p>
                      </MuiTooltip>
                    )
                  )}
                </div>
                <div className="flex gap-x-1">
                  <div className="font-normal text-blue-800">
                    {row.original["totalNoOfAds"] + " ads"}
                  </div>
                  {/* {isShowAdBreakdownOption && (
                    <div
                      onClick={() => handleAdBreakdown(row.original["_id"])}
                      className={`ps-1 w-fit text-blue-500 mr-1 cursor-pointer flex justify-center ${
                        !isShowAdBreakdownOption
                          ? "hidden group-hover:hidden"
                          : "group-hover:inline-block"
                      }`}
                    >
                      <p className="hover:underline">Ad breakdown</p>
                    </div>
                  )} */}
                </div>
              </div>
            </>
          )}
        </div>
      ),
      enableHiding: false,
      meta: {
        className: "sticky left-8 bg-white z-10",
        noFilter: true,
      },
    },
    ...(reportableType === REPORTABLE_TYPE.AD_BREAKDOWN
      ? AD_BREAKDOWN_FIELDS.map((curr) => {
          return {
            id: curr,
            accessorKey: curr,
            header: ({ column }) => (
              <div className="flex items-center ml-4 hover:border-r-2 flex-wrap text-xs">
                {AD_BREAKDOWN_FIELDS_REVERSE[curr]}
              </div>
            ),
            cell: ({ row }) => (
              <DarkTooltip arrow placement="top" title={row.original[curr]}>
                <div className="flex text-wrap w-52 ml-2 max-h-16 text-xs font-medium">
                  <span className="text-gray-600 w-fit break-words break-all text-wrap ">
                    {row.original[curr]}
                  </span>
                </div>
              </DarkTooltip>
            ),
            enableHiding: false,
            meta: {
              className: "sticky left-12 bg-white z-10",
              noFilter: true,
            },
          };
        })
      : []),
    ...data?.[0]?.columns
      .filter((col) => !colToBeRemoved?.includes(col.key))
      .filter((col) => {
        if (!columnToBeShowed) return true;
        return columnToBeShowed?.includes(col.key);
      })
      .sort((a, b) => {
        const indexA = columnToBeShowed?.indexOf(a.key);
        const indexB = columnToBeShowed?.indexOf(b.key);
        return indexA - indexB;
      })
      .map((col) => {
        return {
          accessorKey: col?.key,
          header: ({ column }) => (
            <div className="flex items-center text-xs w-max">
              <div className="flex-shrink-0 mt-1 mr-2">
                <Checkbox
                  checked={selectedMetrices?.includes(col?.key)}
                  onCheckedChange={(value) => {
                    if (selectedMetrices?.includes(col?.key)) {
                      updateKPIs({
                        value: selectedMetrices?.filter(
                          (kpi) => kpi !== col?.key
                        ),
                      });
                    } else {
                      if (!isSelectionValid("kpi")) {
                        return;
                      }
                      updateKPIs({
                        value: [...selectedMetrices, col?.key],
                      });
                    }
                  }}
                />
              </div>
              <div className="flex items-center">
                <img
                  src={
                    filterAndOptions?.kpi?.find((k) => k?.kpi === col?.key)
                      ?.icon
                  }
                  className="w-4 h-4 object-cover"
                  alt="default-kpi-icon"
                />
                <div className="mx-2 flex-shrink-0 truncate">{col?.label}</div>
              </div>
            </div>
          ),
          cell: ({ row }) => (
            <div className={`-z-0 capitalize text-xs flex justify-center`}>
              {row.getValue(col?.key)?.display?.type === "progressBar" ? (
                <div className="z-0 flex w-full items-center gap-x-2 justify-center">
                  <DarkTooltip
                    arrow
                    placement="top"
                    title={
                      <div className="w-full h-full flex flex-col gap-y-2 justify-center">
                        <Gauge
                          value={row.getValue(col?.key)?.display?.value}
                          size="medium"
                          showValue={true}
                          color={getColorForProgress(
                            row.getValue(col?.key)?.display?.value
                          )}
                          label={col.key?.toUpperCase()}
                          unit={row.getValue(col?.key)?.display?.unit}
                        />
                        <div className="bg-[#232323] p-2 mt-1 text-sm font-medium">
                          <span>
                            {
                              row.getValue(col?.key)?.display?.tooltip?.[0]
                                ?.text
                            }
                          </span>{" "}
                          {row.getValue(col?.key)?.display?.tooltip?.[1]
                            ?.text && (
                            <span
                              style={{
                                color: row.getValue(col?.key)?.display
                                  ?.tooltip?.[1]?.color,
                              }}
                            >
                              {
                                row.getValue(col?.key)?.display?.tooltip?.[1]
                                  ?.text
                              }
                            </span>
                          )}
                        </div>
                      </div>
                    }
                  >
                    <div className="flex gap-x-2 w-full justify-center items-center">
                      <BorderLinearProgress
                        variant="determinate"
                        value={row.getValue(col?.key)?.display?.value}
                        sx={{
                          zIndex: 0,
                          [`& .${linearProgressClasses.bar}`]: {
                            borderRadius: 5,
                            backgroundColor: getColorForProgress(
                              row.getValue(col?.key)?.display?.value
                            ),
                          },
                          width: "65%",
                          height: "5px",
                        }}
                      />
                      <span>
                        {row.getValue(col?.key)?.display?.value}
                        {row.getValue(col?.key)?.display?.unit}
                      </span>
                    </div>
                  </DarkTooltip>
                </div>
              ) : (
                <DarkTooltip
                  arrow
                  placement="top"
                  title={
                    <div>
                      <span>
                        {row.getValue(col?.key)?.display?.tooltip?.[0]?.text}
                      </span>{" "}
                      {row.getValue(col?.key)?.display?.tooltip?.[1]?.text && (
                        <span
                          style={{
                            color: row.getValue(col?.key)?.display?.tooltip?.[1]
                              ?.color,
                          }}
                        >
                          {row.getValue(col?.key)?.display?.tooltip?.[1]?.text}
                        </span>
                      )}
                    </div>
                  }
                >
                  <span>
                    {typeof row.getValue(col?.key) === "object"
                      ? `${row.getValue(col?.key)?.display?.value} ${
                          row.getValue(col?.key)?.display?.unit
                        }`
                      : row.getValue(col?.key)}
                  </span>
                </DarkTooltip>
              )}
            </div>
          ),
          sortingFn: (a, b) =>
            a.original[col?.key]?.display?.value -
            b.original[col?.key]?.display?.value,
          filterFn: "myCustomFilter",
        };
      }),
    {
      id: "addMetrics",
      header: ({ table }) => (
        <Button
          className="text-xs"
          variant="Link"
          onClick={() => {
            dispatch(
              setModal({
                open: true,
                type: MODALTYPES.PRESETS_MODAL,
                payload: {},
              })
            );
          }}
        >
          <CirclePlus className="h-4 w-4 cursor-pointer mr-2" color="blue" />
          Add Metrics
        </Button>
      ),
      cell: ({ row }) => <></>,
      enableSorting: false,
      enableHiding: false,
      meta: {
        className: "sticky left-0 bg-white",
        noFilter: true,
      },
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    enableColumnResizing: true,
    filterFns: {
      myCustomFilter: (rows, columnIds, filterValue) => {
        const lessThan = filterValue ? filterValue[0] : null;
        const graterThen = filterValue ? filterValue[1] : null;
        let decision = true;

        if (lessThan) {
          decision =
            decision && rows.original[columnIds].display.value >= lessThan;
        }

        if (graterThen) {
          decision =
            decision && rows.original[columnIds].display.value <= graterThen;
        }

        return decision;
      },
    },
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
  });

  useEffect(() => {
    setSortedData(table?._getSortedRowModel().rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, data]);

  useEffect(() => {
    if (Array.isArray(specificPresetData) && specificPresetData.length > 0) {
      const kpis = [];
      specificPresetData[0].kpis.map((kpi) => kpis.push(kpi.value));
      setColumnToBeShowed(kpis);
      setTempKpis([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificPresetData, preset]);

  useEffect(() => {
    const _kpi = [];
    if (tempKpis?.length) {
      tempKpis.forEach((t) => _kpi.push(t.value));
      setColumnToBeShowed(_kpi);
    }
  }, [tempKpis]);

  useEffect(() => {
    setColumnToBeShowed((prevColumns) => {
      const columnsSet = new Set(prevColumns);
      selectedMetrices.forEach((metric) => {
        columnsSet.add(metric);
      });
      return Array.from(columnsSet);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMetrices]);

  // useEffect(() => {
  //   console.log(Object.keys(rowSelection)?.length > 6);
  //   if (Object.keys(rowSelection)?.length > 6) {
  //     table.toggleAllRowsSelected(false);
  //   }
  // }, [visualisationTab]);

  const dispatch = useDispatch();

  const [headers, setHeaders] = useState();
  const [payload, setPayload] = useState();

  function Export2CsvSetPayload(done) {
    const head = table
      .getHeaderGroups()[0]
      ?.headers?.map((head) => head?.id)
      .slice(1);
    setHeaders(head);

    const data = table.getRowModel().rows?.map((row) => {
      const _orginal = row?.original;
      const picked = _.pick(_orginal, head);
      const group = _.pick(_orginal, [
        "thumbnailUrl",
        "body",
        "landingPageUrl",
        "headline",
        "adName",
      ]);
      return {
        [head[0]]: group?.[Object.keys(group)?.[0]],
        ..._.mapValues(picked, (nestedObj) => nestedObj.value),
      };
    });
    setPayload(data);
    if (done) {
      done(true);
    }
  }

  useEffect(() => {
    Export2CsvSetPayload();
  }, []);

  return (
    <div className="w-full text-xs">
      <div className="flex gap-x-5 mb-5">
        <div className="flex items-center">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                // disabled={disableFilters || hideSidebar}
                variant="outline"
                role="combobox"
                className="w-[250px] px-2 overflow-hidden truncate flex justify-between"
              >
                <span className="text-black font-medium text-xs">
                  {preset
                    ? presetData?.filter(
                        (curr, index) => curr.id === preset
                      )?.[0]?.name
                    : "Select preset"}
                </span>
                <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-70" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[250px] p-0">
              <Command>
                <CommandInput placeholder="Search filter..." />
                <CommandEmpty>No filter found.</CommandEmpty>

                <CommandList>
                  <CommandGroup>
                    {presetData?.map((curr, index) => (
                      <CommandItem
                        className="text-xs font-medium"
                        key={curr?.id}
                        value={curr?.id}
                        onSelect={(val) => {
                          handlePresetSelect(val);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            curr?.id?.toString() === preset?.toString()
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {curr?.name}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>

                <Separator />

                <CommandItem
                  className="px-8 py-2"
                  key={"custom"}
                  value={"custom"}
                  onSelect={(val) => {
                    dispatch(
                      setModal({
                        open: true,
                        type: MODALTYPES.PRESETS_MODAL,
                        payload: {},
                      })
                    );
                  }}
                >
                  Customize columns
                </CommandItem>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
        {isLoadingSpecificPreset && (
          <div className="flex items-center">
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          </div>
        )}
        {payload && headers && (
          <DarkTooltip title="Export this data as a CSV" placement="top">
            <Button variant="outline" className="text-xs font-medium">
              <CSVLink
                data={payload}
                headers={headers}
                filename={`${moment().toDate().getTime()}.csv`}
                onClick={(event, done) => {
                  Export2CsvSetPayload(done);
                }}
                className="flex gap-x-1 items-center"
              >
                <Download className="mr-2 h-4 w-4" />
                Export
              </CSVLink>
            </Button>
          </DarkTooltip>
        )}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto text-xs font-medium">
              <div className="mr-2">
                <Settings className="h-4 w-4" />
              </div>
              Table Settings <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-72 text-xs font-medium">
            <div className="px-2 my-1">
              <div className="flex justify-between mb-3 items-center">
                <p className="w-[55%]">Color formatting</p>
                <div className="flex gap-x-2">
                  {COLOR_FORMATING_OPTIONS?.map((option, index) => {
                    return (
                      <div
                        className={`border hover:border-blue-500 ${
                          colorFormat === index + 1 ? "border-blue-500" : ""
                        } rounded-lg`}
                      >
                        <img
                          src={option?.src}
                          alt="color formatting"
                          className={`w-[30px] object-fill h-full rounded-lg`}
                          onClick={option?.onClick}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="flex items-center relative">
                <p>Results per page</p>

                <Select
                  onValueChange={(value) => {
                    if (handleSetPerPage(value)) {
                      setPagination({
                        ...pagination,
                        pageSize: value,
                      });
                    } else {
                      setPagination({
                        pageIndex: 0,
                        pageSize: value,
                      });
                    }
                  }}
                >
                  <SelectTrigger className="w-[80px] h-8 ml-auto">
                    <SelectValue placeholder={pagination.pageSize} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value={10}>10</SelectItem>
                      <SelectItem value={100}>100</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <DropdownMenuSeparator />
            <div className="px-2 h-64 overflow-y-auto">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="columns" direction="vertical">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {columnToBeShowed?.map((col, index) => {
                        const _kpi = filterAndOptions?.kpi?.find(
                          (k) => k?.kpi === col
                        );
                        return (
                          <Draggable
                            key={_kpi?.id}
                            draggableId={_kpi?.id}
                            index={index}
                          >
                            {(provided, snapshot) => {
                              const child = (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={twJoin(
                                    "flex items-center hover:bg-[#FAFAFA] py-3 rounded mb-2",
                                    snapshot.isDragging ? "dragging" : ""
                                  )}
                                  style={{
                                    ...provided.draggableProps.style,
                                    boxShadow: snapshot.isDragging
                                      ? "0 2px 8px rgba(0, 0, 0, 0.1)"
                                      : "none",
                                  }}
                                >
                                  <div className="flex justify-between items-center w-full">
                                    <GripVertical className="h-5 w-5" />
                                    <img
                                      src={_kpi.icon}
                                      className="w-4 h-4 object-cover"
                                      alt="default-kpi-icon"
                                    />
                                    <div className="px-2 mr-auto  capitalize">
                                      <p className="">{_kpi?.label}</p>
                                    </div>
                                    <X
                                      className="h-4 w-4 cursor-pointer mr-2"
                                      onClick={() => {
                                        const _kpis = columnToBeShowed?.filter(
                                          (k) => k !== _kpi?.kpi
                                        );
                                        setColumnToBeShowed(_kpis);
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                              return snapshot.isDragging ? (
                                <Portal>{child}</Portal>
                              ) : (
                                child
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <DropdownMenuSeparator />
            <div className="my-1 px-2 className='' w-full">
              <button
                onClick={() => {
                  dispatch(
                    setModal({
                      open: true,
                      type: MODALTYPES.PRESETS_MODAL,
                      payload: {},
                    })
                  );
                }}
                className="text-gray-800 h-9 border  border-black p-2 hover:bg-gray-100 py-2 rounded-lg inline-flex items-center"
              >
                <Plus />
                <span>Add Metrics</span>
              </button>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="rounded-md">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className={` ${
                        header.column.columnDef.meta?.className ?? ""
                      }`}
                    >
                      <div className="inline-flex">
                        <div className="inline-block">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </div>
                        <div className="inline-block self-center">
                          {header.column.getCanFilter() ? (
                            <Filter
                              column={header.column}
                              setSorting={setSorting}
                            />
                          ) : null}
                        </div>
                      </div>
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => {
                    const customStyle =
                      cell?.row?.original[cell?.column?.id]?.display?.color &&
                      doesShowColor(
                        cell?.row?.original[cell?.column?.id]?.display
                          ?.colorType
                      ) &&
                      cell?.row?.original[cell?.column?.id]?.display?.type !==
                        "progressBar"
                        ? {
                            //handle doesShowColor() and show color conditionally
                            backgroundColor:
                              cell?.row?.original[cell?.column?.id]?.display
                                ?.color,
                          }
                        : {
                            backgroundColor: "white",
                          };

                    return (
                      <TableCell
                        key={cell.id}
                        className={cell.column.columnDef.meta?.className}
                        style={{ ...customStyle }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1  text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getRowCount()} row(s) selected.
        </div>
        <div className="inline-flex rounded-md shadow-sm gap-x-2" role="group">
          <button
            type="button"
            onClick={() =>
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex - 1,
              })
            }
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-2  font-medium disabled:hover:bg-gray-100 disabled:text-black disabled:cursor-not-allowed text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700"
          >
            <ChevronLeft className="inline" size={16} />
          </button>
          <button
            type="button"
            disabled
            className="px-2 py-2  font-medium text-gray-900 bg-white border border-gray-200"
          >
            {pagination.pageIndex + 1}/{table?.getPageCount()}
          </button>
          <button
            type="button"
            onClick={() =>
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex + 1,
              })
            }
            disabled={!table.getCanNextPage()}
            className="px-2 py-2  font-medium text-gray-900 disabled:cursor-not-allowed disabled:hover:bg-gray-100 disabled:text-black bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700"
          >
            <ChevronRight className="inline" size={16} />
          </button>
        </div>
      </div>
    </div>
  );
};

const Filter = ({ column, setSorting }) => {
  const filterValue = column.getFilterValue();
  const { noFilter } = column.columnDef.meta ?? {};

  if (noFilter) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <ListFilter size={14} />
      </PopoverTrigger>
      <PopoverContent className="text-xs">
        <div className="grid gap-4 text-xs">
          <div
            className="cursor-pointer grid-cols-1"
            onClick={() => {
              setSorting([
                {
                  id: column?.id,
                  desc: false,
                },
              ]);
            }}
          >
            <ArrowUpAZ className="inline mr-2" size={14} />
            Low to High
          </div>

          <div
            className="cursor-pointer grid-cols-1"
            onClick={() => {
              setSorting([
                {
                  id: column?.id,
                  desc: true,
                },
              ]);
            }}
          >
            <ArrowDownZA className="inline mr-2" size={14} />
            High to Low
          </div>

          <Separator />

          <div className="grid grid-cols-2 items-center gap-2 text-xs">
            <Label htmlFor="graterThen">
              Less than
              <ChevronLeft className="inline ml-2" size={14} />
              <Equal className="inline" size={14} />
            </Label>
            <DebouncedInput
              id="graterThen"
              placeholder="value"
              className="h-6"
              type="number"
              value={filterValue?.[1] ?? ""}
              onChange={(value) => {
                column.setFilterValue((old) => {
                  return [old?.[0], value];
                });
              }}
            />
          </div>

          <Separator />

          <div className="grid grid-cols-2 items-center gap-2 text-xs">
            <Label htmlFor="lessThen">
              Greater than
              <ChevronRight className="inline ml-2" size={14} />
              <Equal className="inline" size={14} />
            </Label>
            <DebouncedInput
              id="lessThen"
              className="h-6"
              type="number"
              placeholder="value"
              value={filterValue?.[0] ?? ""}
              onChange={(value) => {
                column.setFilterValue((old) => {
                  return [value, old?.[1]];
                });
              }}
            />
          </div>

          <Separator />

          <Label htmlFor="inBetween" className="text-xs">
            <BetweenHorizontalEnd className="inline mr-2" size={14} />
            In Between
          </Label>
          <div className="grid grid-cols-2 items-center gap-2">
            <DebouncedInput
              className="inline h-6"
              placeholder="min"
              type="number"
              value={filterValue?.[0] ?? ""}
              onChange={(value) => {
                column.setFilterValue((old) => [value, old?.[1]]);
              }}
            />

            <DebouncedInput
              className="inline h-6"
              placeholder="max"
              type="number"
              value={filterValue?.[1] ?? ""}
              onChange={(value) => {
                column.setFilterValue((old) => [old?.[0], value]);
              }}
            />
          </div>
        </div>
        <PopoverClose
          className="rounded-full h-[25px] w-[25px] inline-flex items-center justify-center text-violet11 absolute top-[5px] right-[5px] hover:bg-violet4 focus:shadow-[0_0_0_2px] focus:shadow-violet7 outline-none cursor-default"
          aria-label="Close"
        >
          <X size={18} />
        </PopoverClose>
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  );
};

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Input
      className="text-xs"
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export default ColorTable;
