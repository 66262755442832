import React from "react";
import {
  Typography,
  Stack,
  Button,
  Switch,
  Chip,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { ContentContainer, MainContainer } from "components/layouts";
import { AutoCompleteWithChips, DateFilter } from "components/common/molecules";

import MultipleValuesFilter from "components/common/atoms/CustomSelect/MultipleValuesFilter";

import { GrayHeader } from "screens/Prediction/Components";
import { DateFilterOptions } from "components/common/molecules/DateFilter";
import usePredictionStep3 from "./usePredictionStep3";
import ThresholdValues, {
  ThresholdValueFilterOptions,
} from "components/common/molecules/ThresholdValues";
import ExtraFilters from "screens/Insights/Components/ExtraFilters/ExtraFilters";
import { useSelector } from "react-redux";

function PredictionStep3() {
  const {
    predictionState,
    onThresholdValueChange,
    onDateDaysChange,
    onFilterUpdate,
    onSelectedMetricsUpdate,
    updatePredictionFilters,
    handleIsReportPublicChange,
    onRemoveSelectedMetrics,
    handleSearchMotionFilters,
    handleUpdateSearchExtraFilter,
    handleExtraFilterSelectUpdate,
    handleAddNewFilter,
    handleRemoveNewFilter,
    handleAggregateLogicChangeExtraFilters,
    smartSuggestion,
    isSmartSuggestionLoading,
  } = usePredictionStep3();
  const metricsOptions = predictionState?.metrics?.map((item) => ({
    label: item?.label,
    value: item?.kpi,
  }));
  const predictionsReduxState = useSelector((state) => state?.prediction);
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{ gap: "32px", height: "100%", width: "100%" }}
    >
      <MainContainer header={<GrayHeader step={3} />}>
        <ContentContainer
          containerStyles={{
            maxHeight: "calc(100vh - 240px)",
            padding: "0 16px 32px 16px",
            marginTop: "0",
          }}
        >
          <Stack direction="column" spacing={3}>
            <Typography
              variant="h3"
              sx={{ textTransform: "capitalize", fontWeight: "500" }}
            >
              select the following parameter to predict the best Prediction
            </Typography>

            <Stack direction="column" spacing={2}>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "20px",
                  textTransform: "capitalize",
                }}
              >
                Do you want to public this ?
              </Typography>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography>No</Typography>
                <Switch
                  checked={predictionState?.isReportPublic}
                  onChange={handleIsReportPublicChange}
                />
                <Typography>Yes</Typography>
              </Stack>
            </Stack>

            <Stack direction="column" spacing={3}>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  borderRadius: "10px",
                  padding: "16px",
                  background: "#F9F9F9",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#1C1E20",
                    fontWeight: "500",
                  }}
                >
                  Select Date Range & Threshold Values:
                </Typography>
                <Stack direction="row" spacing={2}>
                  <DateFilter
                    options={DateFilterOptions}
                    value={predictionState?.date}
                    onChange={(value) => {
                      onDateDaysChange(value);
                    }}
                  />
                  <ThresholdValues
                    options={ThresholdValueFilterOptions}
                    value={predictionState?.ThresholdValue}
                    onChange={(value) => {
                      onThresholdValueChange(value);
                    }}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  borderRadius: "10px",
                  padding: "16px",
                  background: "#F9F9F9",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#1C1E20",
                    fontWeight: "500",
                  }}
                >
                  Select Filters:
                </Typography>
                <Stack direction="row">
                  <Stack direction="row" gap={2} flexWrap={"wrap"}>
                    {Object.entries(predictionState?.filters).map(
                      ([key, value], index) => {
                        if (key === "dateRange" || key === "sortOrder")
                          return null;
                        let defaultOption =
                          value?.options?.filter((item) => item.default) || [];
                        return (
                          <MultipleValuesFilter
                            key={key}
                            id={key}
                            options={value?.options}
                            label={value?.label}
                            selectedOption={defaultOption}
                            onChange={(selectedOption) => {
                              onFilterUpdate(key, selectedOption);
                            }}
                            multiple={false}
                          />
                        );
                      }
                    )}
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  borderRadius: "10px",
                  padding: "16px",
                  background: "#F9F9F9",
                }}
              >
                <AutoCompleteWithChips
                  multiple={false}
                  optionsList={metricsOptions}
                  inputLabel="Search Metrics"
                  onTagUpdate={(value) => {
                    onSelectedMetricsUpdate(value);
                  }}
                  values={predictionState?.selectedMetrics}
                  limit={5}
                />
                <Stack
                  direction="row"
                  gap={0}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    maxWidth: "370px",
                  }}
                >
                  {predictionState?.selectedMetrics?.map((metric, index) => {
                    const selectedMetric = predictionState?.metrics?.find(
                      (curr) => curr.label === metric.label
                    );
                    return (
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">
                              {metric?.label}
                            </Typography>
                            <b>{selectedMetric?.description}</b>
                          </React.Fragment>
                        }
                      >
                        <Chip
                          label={metric?.label}
                          variant="outlined"
                          onDelete={() => {
                            onRemoveSelectedMetrics(index);
                          }}
                        />
                      </HtmlTooltip>
                    );
                  })}
                </Stack>
              </Stack>
              {/* <Stack
                direction="column"
                sx={{
                  borderRadius: "10px",
                  padding: "10px",

                  background: "#F9F9F9",
                }}
              > */}
              {/* {true && (
                  <ExtraFilters
                    state={predictionsReduxState}
                    handleExtraFilterSelectUpdate={
                      handleExtraFilterSelectUpdate
                    }
                    handleSearchMotionFilters={handleSearchMotionFilters}
                    handleUpdateSearchExtraFilter={
                      handleUpdateSearchExtraFilter
                    }
                    smartSuggestions={smartSuggestion}
                    isSmartSuggestionLoading={isSmartSuggestionLoading}
                    handleAddNewFilter={handleAddNewFilter}
                    handleRemoveNewFilter={(index) =>
                      handleRemoveNewFilter(index)
                    }
                    handleAggregateLogicChangeExtraFilters={(value) =>
                      handleAggregateLogicChangeExtraFilters(value)
                    }
                  />
                )} */}
              {/* </Stack> */}
            </Stack>
            <Stack direction="row" justifyContent="flex-start">
              <Button
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                onClick={updatePredictionFilters}
              >
                Start
              </Button>
            </Stack>
          </Stack>
        </ContentContainer>
      </MainContainer>
    </Stack>
  );
}

export default PredictionStep3;
