import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  useMediaQuery,
  Typography,
  Stack,
  Chip,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "../../../../redux/slices/modal.slices";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { InputWithLabel } from "../../../../components/common/molecules";
import PredictionReportCard from "../PredictionReportCard";
import { BlurredBgBtnWIcon } from "components/common/atoms";
import { Image, PlayCircleOutline } from "@mui/icons-material";

const predictionResultData = [
  {
    id: "1",
    type: "Video",
    thumbnail: "https://picsum.photos/seed/picsum/200/300",
    predicted: "1.5365%",
    confidence: "89.54%",
    score: "89",
    optimisation:
      "Based on the tags and prediction, suggest a few actionable steps: “Considering the predicted CTR, you might want to increase the budget allocation for this creative.",
  },
  {
    id: "2",
    type: "Image",
    thumbnail: "https://picsum.photos/seed/picsum/200/300",
    predicted: "1.5365%",
    confidence: "89.54%",
    score: "89",
    optimisation:
      "Based on the tags and prediction, suggest a few actionable steps: “Considering the predicted CTR, you might want to increase the budget allocation for this creative.",
  },
  {
    id: "3",
    type: "Image",
    thumbnail: "https://picsum.photos/seed/picsum/200/300",
    predicted: "1.5365%",
    confidence: "89.54%",
    score: "89",
    optimisation:
      "Based on the tags and prediction, suggest a few actionable steps: “Considering the predicted CTR, you might want to increase the budget allocation for this creative.",
  },
  {
    id: "4",
    type: "Image",
    thumbnail: "https://picsum.photos/seed/picsum/200/300",
    predicted: "1.5365%",
    confidence: "89.54%",
    score: "89",
    optimisation:
      "Based on the tags and prediction, suggest a few actionable steps: “Considering the predicted CTR, you might want to increase the budget allocation for this creative.",
  },
  {
    id: "5",
    type: "Video",
    thumbnail: "https://picsum.photos/seed/picsum/200/300",
    predicted: "1.5365%",
    confidence: "89.54%",
    score: "89",
    optimisation:
      "Based on the tags and prediction, suggest a few actionable steps: “Considering the predicted CTR, you might want to increase the budget allocation for this creative.",
  },
  {
    id: "6",
    type: "Image",
    thumbnail: "https://picsum.photos/seed/picsum/200/300",
    predicted: "1.5365%",
    confidence: "89.54%",
    score: "89",
    optimisation:
      "Based on the tags and prediction, suggest a few actionable steps: “Considering the predicted CTR, you might want to increase the budget allocation for this creative.",
  },
  {
    id: "7",
    type: "Image",
    thumbnail: "https://picsum.photos/seed/picsum/200/300",
    predicted: "1.5365%",
    confidence: "89.54%",
    score: "89",
    optimisation:
      "Based on the tags and prediction, suggest a few actionable steps: “Considering the predicted CTR, you might want to increase the budget allocation for this creative.",
  },
  {
    id: "8",
    type: "Video",
    thumbnail: "https://picsum.photos/seed/picsum/200/300",
    predicted: "1.5365%",
    confidence: "89.54%",
    score: "89",
    optimisation:
      "Based on the tags and prediction, suggest a few actionable steps: “Considering the predicted CTR, you might want to increase the budget allocation for this creative.",
  },
];

export default function PredictionReportCardModal(props) {
  const { reportId } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();

  function handleClose() {
    dispatch(setModal(modalInitState));
  }

  const foundPredictionReportCard = props;
  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Stack direction="column" sx={{ padding: "16px" }} spacing={3}>
        <PredictionReportCard
          key={foundPredictionReportCard?.id}
          TypePill={
            <BlurredBgBtnWIcon
              Icon={
                foundPredictionReportCard?.type === "Image" ? (
                  <Image sx={{ fontSize: "20px" }} />
                ) : (
                  <PlayCircleOutline sx={{ fontSize: "20px" }} />
                )
              }
              sx={{ top: "8px", left: "8px" }}
              title={foundPredictionReportCard?.type}
            />
          }
          {...foundPredictionReportCard}
        />
      </Stack>
    </Dialog>
  );
}
