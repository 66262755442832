import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Shapes,
  Video,
  Brain,
  BringToFront,
  Sparkles,
  AudioLines,
  ScatterChart,
  Columns2,
  Lock,
  Copy,
  CalendarSearch,
} from "lucide-react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { ACTIVE_FEATURES } from "config/roles";
import useUserRole from "hooks/useUserRole";
import { styled } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { twJoin } from "tailwind-merge";
import { toast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

const CreateReportsModal = () => {
  const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    localStorage?.removeItem("REPORT_FOLDER");
    dispatch(setModal(modalInitState));
  };

  const { hasUserActiveFeature } = useUserRole();

  const reports_options = [
    {
      text: "Insights",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.INSIGHTS),
      subText:
        "See which creatives are performing best and enhance your cretive building strategies",
      icon: <Shapes color="#dd82ee" />,
      aiIcon: false,
      onClick: (isActive) => {
        if (isActive) {
          navigate(APP_ROUTES.INSIGHTS.default);
          handleClose();
        }
      },
    },
    {
      text: "Compare Creatives",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.COMPARE),
      subText:
        "Compare multiple creative groups with multiple different filters for each group",
      icon: <Columns2 color="#A8D26D" />,
      aiIcon: false,
      comingSoon: false,
      beta: false,
      onClick: (isActive) => {
        if (isActive) {
          navigate(APP_ROUTES.INSIGHTS.COMPARE);
          handleClose();
        }
      },
    },
    {
      text: "Compare Creatives with dates",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.COMPARE),
      subText:
        "Compare your creatives groups in different date ranges for better insights",
      icon: <CalendarSearch color="#A8D26D" />,
      aiIcon: false,
      comingSoon: false,
      beta: false,
      onClick: (isActive) => {
        if (isActive) {
          navigate(APP_ROUTES.INSIGHTS.COMPARE_DATE);
          handleClose();
        }
      },
    },
    {
      text: "Creative momentum fatigue",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.CREATIVE_MOMENTUM_FATIGUE),
      subText:
        "Get Creative momentum fatigue before posting your creatives online",
      icon: <ScatterChart color="#ffa9a9" />,
      aiIcon: false,
      comingSoon: false,
      beta: false,
      onClick: (isActive) => {
        if (isActive) {
          navigate(APP_ROUTES.INSIGHTS.CREATIVE_MOMENTUM_FATIGUE);
          handleClose();
        }
      },
    },
    {
      text: "Conversational Insights",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.CONVERSATIONAL_INSIGHTS),
      subText:
        "Get AI powered conversational insights before posting your creatives online",
      icon: <AudioLines color="#55d0ff" />,
      aiIcon: true,
      comingSoon: true,
      beta: true,
      onClick: (isActive) => {
        if (isActive) {
          navigate(APP_ROUTES.PREDICTION.default);
          handleClose();
        }
      },
    },
    {
      text: "Recommendation: Creative Feature",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.RECOMMENDATION),
      subText:
        "Get AI powered recommendations on your creatives and boost your performance over next creatives",
      icon: <Brain color="#886E58" />,
      aiIcon: true,
      onClick: (isActive) => {
        if (isActive) {
          navigate(APP_ROUTES.RECOMMENDATION.CREATIVE_RECOMMENDATION);
          handleClose();
        }
      },
    },
    {
      text: "Recommendation: Video Intelligence",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.RECOMMENDATION),
      subText: "Get AI powered recommendations on your video creatives",
      icon: <Video color="#cbb2ff" />,
      aiIcon: true,
      onClick: (isActive) => {
        if (isActive) {
          navigate(APP_ROUTES.RECOMMENDATION.VIDEO_RECOMMENDATION);
          handleClose();
        }
      },
    },
    {
      text: "Predictions",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.PREDICTION),
      subText:
        "Get AI powered predictions before posting your creatives online",
      icon: <BringToFront color="#ff9912" />,
      aiIcon: true,
      onClick: (isActive) => {
        if (isActive) {
          navigate(APP_ROUTES.PREDICTION.default);
          handleClose();
        }
      },
    },
  ];

  return (
    <Dialog
      open={modal?.open && modal?.type === MODALTYPES.CREATE_REPORTS}
      onOpenChange={handleClose}
    >
      <DialogContent className="sm:max-w-[500px] sm:max-h-[600px] sm:overflow-scroll sm:overflow-x-hidden">
        <DialogHeader>
          <DialogTitle>Create New Report</DialogTitle>
          <DialogDescription>
            Explore the world of storytelling by creating different types of
            reports available.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-y-3">
          {reports_options.map((b, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  b?.onClick(b.isActive);
                }}
              >
                <LightTooltip
                  title={
                    !b.isActive ? (
                      <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <svg
                          class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
                        </svg>
                        <a href="#">
                          <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                            Request Access
                          </h5>
                        </a>
                        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">
                          Please contact from Admin to activate this feature.
                        </p>
                        {/* <a
                          href="mailto:varun@thestoryteller.tech"
                          class="inline-flex font-medium items-center text-blue-600 hover:underline"
                        >
                          Contact Admin
                          <svg
                            class="w-3 h-3 ms-2.5 rtl:rotate-[270deg]"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 18 18"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
                            />
                          </svg>
                        </a> */}
                        <div className="flex items-center space-x-2">
                          <div className="grid flex-1 gap-2">
                            <Input
                              id="link"
                              defaultValue="varun@thestoryteller.tech"
                              readOnly
                            />
                          </div>
                          <Button
                            type="submit"
                            variant="outline"
                            size="sm"
                            className="px-3"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                "varun@thestoryteller.tech"
                              );
                              toast({
                                description:
                                  "Email Address Copied To Clipboard.",
                              });
                            }}
                          >
                            <span className="sr-only">Copy</span>
                            <Copy className="h-4 w-4" />
                          </Button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  }
                  placement="right"
                  arrow
                >
                  <button
                    type="button"
                    disabled={!b.isActive}
                    className={twJoin(
                      "relative gap-x-2 text-gray-900 w-[100%] h-[90%] bg-gray-100 hover:bg-gray-200 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-3.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2 disabled:cursor-not-allowed"
                    )}
                  >
                    <div className="m-2">{b.icon}</div>
                    <div className="flex flex-col">
                      <p className="text-left">
                        {b.text}{" "}
                        {b.comingSoon && (
                          <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            Coming Soon
                          </span>
                        )}
                        {b.beta && (
                          <span class="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-purple-900 dark:text-purple-300">
                            Beta
                          </span>
                        )}
                      </p>
                      <p className="text-left text-sm text-gray-500">
                        {b.subText}
                      </p>
                    </div>
                    {!b.isActive && (
                      <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-red-500 bg-red-100 border-2 border-white rounded-full -top-2 -start-2 dark:border-gray-900">
                        <Lock color="red" size={12} />
                      </div>
                    )}
                    {b.aiIcon && (
                      <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-yellow-500 bg-yellow-50 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
                        <Sparkles color="#b48811" size={14} />
                      </div>
                    )}
                  </button>
                </LightTooltip>
              </div>
            );
          })}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateReportsModal;
