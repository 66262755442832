import React, { useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { Stack, Typography, Button } from "@mui/material";
import { PrivateContainer } from "../../components/layouts";
import ContentContainer from "../../components/layouts/ContentContainer";
import { useSelector } from "react-redux";
import PaymentIcon from "@mui/icons-material/Payment";
import { makeStyles } from "@mui/styles";
import { APP_ROUTES } from "routes";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  icon: {
    fontSize: 64,
    color: "#1C1E20",
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

export default function UpgradePlan({ onCreatePaymentLink }) {
  const { appState } = useSelector((state) => state?.appState);
  const { selectedOrganisation } = useSelector((state) => state.organisation);
  const stripeBuyButtonRef = useRef();
  useEffect(() => {
    if (appState?.paymentLink) {
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/buy-button.js";
      script.async = true;
      script.onload = () => {
        const stripeBuyButton = document.createElement("stripe-buy-button");
        stripeBuyButton.setAttribute("buy-button-id", appState?.paymentLink);
        stripeBuyButton.setAttribute(
          "publishable-key",
          process.env.REACT_APP_PUBLISHABLE_KEY
        );
        stripeBuyButton.setAttribute(
          "client-reference-id",
          selectedOrganisation?._id
        );
        stripeBuyButtonRef?.current?.appendChild(stripeBuyButton);
      };
      stripeBuyButtonRef?.current?.appendChild(script);
    }
  }, []);

  return (
    <PrivateContainer
      bodyStyles={{
        height: "100%",
      }}
      activePage={APP_ROUTES.UPGRADE_PLAN}
    >
      <ContentContainer
        containerStyles={{
          maxHeight: "100%",
          padding: "0",
          margin: "0",
          flexWrap: "nowrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              width: "50%",
              justifyContent: "center",
            }}
            ref={stripeBuyButtonRef}
          ></div>
        </div>
      </ContentContainer>
    </PrivateContainer>
  );
}
