import React from "react";
import {
  Button,
  Dialog,
  Typography,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "../../../redux/slices/modal.slices";
import styles from "./styles.module.scss";
import useAxios from "../../../hooks/useAxios";
import { API_ROUTES } from "../../../routes";
import { toast } from "react-toastify";

export default function TextTaggingModal(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { open, type, payload } = useSelector((state) => state?.modal);
  const { axiosOrgInstance } = useAxios();
  const dispatch = useDispatch();

  function handleAiTagging() {
    axiosOrgInstance
      .post(
        API_ROUTES.TAGGING.CREATE_TEXT_GROUP_TAGS?.replace(
          ":groupId",
          payload?.group?._id
        ),
        { useAiTags: true }
      )
      .then((res) => {
        toast.success(
          `Successful placements of tags in '${payload?.group?.groupName?.slice(
            0,
            20
          )} ${payload?.group?.groupName?.length > 20 ? "..." : ""}`
        );
        handleClose();
        props?.initGroup && props?.initGroup();
      });
  }

  function handleClose() {
    dispatch(setModal(modalInitState));
  }
  return (
    <Dialog
      open={open && type === MODALTYPES.TEXT_TAGGING_MODAL}
      fullScreen={fullScreen}
      onClose={handleClose}
    >
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        className={styles.text_tag_modal_wrapper}
      >
        <Typography className={styles.text_tag_modal_head} variant="h3">
          Do You Want To Add Tags?
        </Typography>
        <Typography className={styles.text_tag_modal_subhead} variant="body1">
          text tagging is essential part of process, by this you will get best
          text combination
        </Typography>
        <Stack
          direction="row"
          spacing={3}
          className={styles.text_tag_modal_actions}
        >
          <Button variant="outlined" onClick={handleAiTagging}>
            AI Tagging
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              dispatch(
                setModal({
                  open: true,
                  type: MODALTYPES.MANUAL_TEXT_TAGGING_MODAL,
                  payload: payload,
                })
              )
            }
          >
            Manual tagging
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
