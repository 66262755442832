import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

import {
  PositiveAndNegativeBarChart,
  RecommendationContainer,
  RecommendationListItem,
  RecommendationStepper,
} from "../Components";
import { HorizontalValueSelect } from "components/common/atoms";
import SEO from "utils/SEO";
import ForumIcon from "@mui/icons-material/Forum";
import {
  CometChatMessages,
  ListItemStyle,
  MessageHeaderConfiguration,
  MessagesStyle,
} from "@cometchat/chat-uikit-react";

export default function CreativeFeature(props) {
  const {
    categories = [],
    isLoading,
    recommendationData,
    activeRecommendation,
    setActiveRecommendation,
    loadingRecommendationData,
    currentGroup = null,
    isDrawerOpen = false,
  } = props;
  const [mappedCategories, setMappedCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState();

  useEffect(() => {
    if (!categories?.length) return;
    const _mappedCategories = categories?.map((item) => ({
      label: item?.key,
      value: item?.breakup?.map((item) => item?.statement),
    }));
    setSelectedCategory(_mappedCategories?.[0]);

    setMappedCategories(_mappedCategories);
  }, [categories]);
  const lStyle = new ListItemStyle({
    background: "transparent",
  });

  const mhConfig = new MessageHeaderConfiguration({
    listItemStyle: lStyle,
    display: "none",
  });

  const hasWindow = typeof window !== "undefined";

  const mStyle = new MessagesStyle({
    width: "390px",
    height: hasWindow ? window.innerHeight - 20 : "760px",
    overflowY: "scroll",
    display: "flex",
    justifyContent: "right",
  });
  console.log(currentGroup, "<<<<CURENT GROUP");
  return (
    <>
      <SEO
        title="Storyteller Creative Feature Recommendations"
        description="AI powered Creative Feature recommendations on your creatives to boost market reach"
        name="Recommendation by Storyteller"
        type="Report"
      />
      <Stack>
        <Stack
          direction="column"
          spacing={1}
          width={isDrawerOpen ? "100%" : "100%"}
        >
          <Stack width={"100%"}>
            <RecommendationContainer title="Top Recommendation">
              {recommendationData?.topRecommendations?.length > 0 ? (
                <Stack direction="row" spacing={3} width={"100%"}>
                  <Stack direction="column" spacing={2} sx={{ flex: 1 }}>
                    {recommendationData?.topRecommendations?.map(
                      (item, index) => (
                        <RecommendationListItem
                          key={index}
                          isActive={index === activeRecommendation}
                          onClick={() => setActiveRecommendation(index)}
                        >
                          {item?.recommendation}
                        </RecommendationListItem>
                      )
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{ maxWidth: "400px", minWidth: "400px" }}
                  >
                    {recommendationData?.topRecommendations && (
                      <RecommendationStepper
                        topRecommendations={
                          recommendationData?.topRecommendations
                        }
                        activeRecommendation={activeRecommendation}
                        setActiveRecommendation={setActiveRecommendation}
                      />
                    )}
                  </Stack>
                </Stack>
              ) : (
                <Typography variant="body1" sx={{ color: "#000000" }}>
                  {loadingRecommendationData ? "Loading..." : "No Data Found"}
                </Typography>
              )}
            </RecommendationContainer>
            <RecommendationContainer title="Category Influence Graph">
              {categories?.length > 0 ? (
                <PositiveAndNegativeBarChart categories={categories ?? []} />
              ) : (
                <Typography>
                  {isLoading ? "Loading..." : "No Data Found"}
                </Typography>
              )}
            </RecommendationContainer>

            <RecommendationContainer
              title="Actionable Feature Impact Analysis"
              RightTitle={
                <Stack
                  direction="row"
                  paddingLeft={2}
                  sx={{
                    color: "#7167FB",
                  }}
                >
                  {mappedCategories && mappedCategories.length > 0 && (
                    <HorizontalValueSelect
                      key={mappedCategories?.length}
                      options={mappedCategories}
                      label="Category"
                      onSelect={(value) => setSelectedCategory(value)}
                      selectedValue={selectedCategory}
                    />
                  )}
                </Stack>
              }
            >
              {selectedCategory?.value?.length > 0 ? (
                <TableContainer
                  sx={{
                    border: "1px solid #A1B0CC",
                  }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderBottom: "1px solid #A1B0CC" }}>
                          Analysis
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <Box
                      sx={{
                        height: "300px",
                        overflowY: "auto",
                      }}
                    >
                      <TableBody>
                        {selectedCategory?.value?.map((category, index) => (
                          <TableRow key={index}>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                border: "0",
                                paddingTop: "0",
                                paddingBottom: "0",
                              }}
                            >
                              <RecommendationListItem
                                listStyle={{ background: "transparent" }}
                              >
                                {category ?? ""}
                              </RecommendationListItem>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Box>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>
                  {isLoading ? "Loading..." : "No Data Found"}
                </Typography>
              )}
            </RecommendationContainer>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
