import React, { useEffect, useRef } from "react";
import {
  Dialog,
  Typography,
  Stack,
  useMediaQuery,
  Button,
  Input,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import styles from "./styles.module.scss";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "../../../redux/slices/modal.slices";
import { InputWithLabel } from "../../common/molecules";
import useAxios from "../../../hooks/useAxios";
import { API_ROUTES } from "../../../routes";
import { debounce } from "lodash";
import { GROUP_ENTITY } from "../../../utils/constants";
import ReactPlayer from "react-player";
import { storageUrlToCdn } from "utils/helper";

export default function GroupImagesModal(props) {
  const { entityType, moveToSuggestedGroup, onCreateNewGroup } = props;

  const theme = useTheme();
  const { axiosOrgInstance } = useAxios();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { open, type, payload } = useSelector((state) => state?.modal);
  const dispatch = useDispatch();
  const { title, btnText } = payload || {};
  const [items, setGroups] = React.useState([]);
  const [grpName, setGrpName] = React.useState();

  const originalGroupsList = useRef([]);
  function handleClose() {
    dispatch(setModal(modalInitState));
  }
  const selectedImgList = [];

  for (let i = 0; i < items; i++) {
    selectedImgList.push(`Image ${i}`);
  }

  function getOrganisationGroups() {
    axiosOrgInstance
      .get(API_ROUTES.COMMON.LIST_ORG_GROUPS_DROPDOWN, {
        params: {
          groupEntity: entityType,
        },
      })
      .then((res) => {
        originalGroupsList.current = res?.docs;
        setGroups(res?.docs);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  function searchGroupByName(value) {
    const filteredGroups = originalGroupsList.current.filter((group) => {
      return group?.groupName?.toLowerCase().includes(value?.toLowerCase());
    });
    setGroups(filteredGroups);
  }

  const debouncedSearchGroupByName = debounce(
    (value) => searchGroupByName(value),
    500
  );

  useEffect(() => {
    getOrganisationGroups();
  }, []);

  return (
    <Dialog
      open={open && type === MODALTYPES.GROUP_IMAGES_MODAL}
      fullScreen={fullScreen}
      onClose={handleClose}
    >
      <Stack
        direction="column"
        spacing={2}
        className={styles.group_img_modal_wrap}
      >
        <Typography variant="h4" className={styles.group_img_modal_head}>
          {title}
        </Typography>
        <Stack className={styles.group_img_modal_input}>
          <InputWithLabel
            styles={{ gap: "8px", width: "100%" }}
            label=""
            placeholder="Search Group By Name"
            onChange={(e) => {
              const { value } = e.target;
              debouncedSearchGroupByName(value);
            }}
          />
        </Stack>

        <Stack direction="column" className={styles.group_img_modal_list}>
          {items?.map((entity) => {
            return (
              <Card
                sx={{ maxWidth: 345 }}
                key={entity?._id}
                onClick={() => {
                  moveToSuggestedGroup({
                    groupId: entity?._id,
                    groupName: grpName,
                  });
                  dispatch(setModal(modalInitState));
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={storageUrlToCdn(entity?.groupThumbnailUrl)}
                  />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {entity?.groupName}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}
        </Stack>
        <Stack className={styles.group_img_modal_action}>
          <Button
            variant="text"
            className={styles.group_img_modal_btn}
            onClick={onCreateNewGroup}
          >
            {btnText}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
