import React from "react";
import PrivateContainer from "../../components/layouts/PrivateContainer";
import styles from "./styles.module.scss";
import {
  Typography,
  Stack,
  Button,
  Menu,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Sync, Tune } from "@mui/icons-material";
import ImagesTab from "./ImageTab";
import VideosTab from "./VideoTab";
import { BottomBarTabs } from "components/common/molecules";
import { APP_ROUTES } from "routes";

export default function GroupAnalytics() {
  const tabs = [
    {
      name: "Image",
      component: <ImagesTab />,
    },
    {
      name: "Video",
      component: <VideosTab />,
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <PrivateContainer
      bodyStyles={{ justifyContent: "flex-start" }}
      activePage={APP_ROUTES.INSIGHTS.GROUP_ANALYTICS}
    >
      <Stack direction="column">
        <Typography variant="h2">Group Analytics</Typography>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h2" sx={{ fontSize: "16px" }}>
              Total Creative Group: 20
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              style={{
                color: "rgba(28, 30, 32, 0.5)",
              }}
            >
              <Typography>Refresh</Typography> <Sync />
            </Stack>
          </Stack>
          {/* FILTER  */}
          <Button
            className={styles.filter}
            variant="outlined"
            endIcon={<Tune />}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ textTransform: "capitalize" }}
          >
            Filter
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              sx={{ padding: "0 140px 0 16px" }}
            >
              <Stack direction="column">
                <Typography className={styles.filter_category}>
                  By Creation Date
                </Typography>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Today" />
                  <FormControlLabel control={<Checkbox />} label="This Week" />
                  <FormControlLabel control={<Checkbox />} label="This Month" />
                </FormGroup>
              </Stack>
              <Stack direction="column">
                <Typography className={styles.filter_category}>
                  By Performance
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Highest ROAS"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Highest Impression"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Highest Reach"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Highest Cost"
                  />
                </FormGroup>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Button className={styles.filter_btn} variant="contained">
                  Apply
                </Button>
                <Button className={styles.filter_btn} variant="outlined">
                  Clear
                </Button>
              </Stack>
            </Stack>
          </Menu>
        </Stack>
      </Stack>

      <BottomBarTabs tabs={tabs} />
    </PrivateContainer>
  );
}
