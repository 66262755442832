import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Stack } from "@mui/system";
import React from "react";
import RemoveUserModal from "../../../../../../components/modals/RemoveUserModal/RemoveUserModal";
import useManageUser from "./useManageUser";
import useUserRole from "hooks/useUserRole";
import {
  RolesMapping,
  SA_PERMISSIONS,
  availableRoles,
} from "../../../../../../config/roles";
import TableLoader from "components/common/loaders/TableLoader";
import { Avatar, AvatarFallback, AvatarImage } from "@radix-ui/react-avatar";

import { InviteUserModal } from "components/modals";
import { ContentContainer } from "components/layouts";

export default function ManageUser(props) {
  const { inviteUser } = props;
  const {
    state,
    createSortHandler,
    onRemoveUser,
    handleRoleChange,
    getOrgUsers,
    isOrgUsersLoading,
    users,
  } = useManageUser();
  const { hasUserPermission } = useUserRole();

  const handleChange = (role, userId) => {
    handleRoleChange({ userId, type: role, refetchOrgUser: getOrgUsers });
  };

  function isInternalEmail(email) {
    const regex = /^[^@]+@(thestoryteller\.tech|tealbox\.digital)$/;
    return regex.test(email);
  }

  return (
    <ContentContainer
      containerStyles={{
        maxHeight: `calc(100vh - 70px)`,
        padding: "0 20px",
      }}
    >
      <Stack direction="column" spacing={4} sx={{ width: "100%" }}>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 500,
            }}
          >
            Manage User
          </Typography>
          {inviteUser}
        </Stack>
        <TableContainer
          sx={{
            width: "100%",
            maxHeight: `calc(100vh - 155px)`,
          }}
          className="content-container"
        >
          <Table size="medium" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#000",
                  }}
                >
                  User
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#000",
                  }}
                >
                  Invite Date
                </TableCell>

                <TableCell
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#000",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isOrgUsersLoading ? (
                <TableLoader rowsNum={3} colsNum={3} />
              ) : (
                <>
                  {users?.map((row) => {
                    let userId = row?.userId;
                    if (isInternalEmail(row?.email)) return null;
                    return (
                      <TableRow key={row.email}>
                        <TableCell key={row.name} component="th" scope="row">
                          <div className="flex items-center justify-between space-x-4">
                            <div className="flex items-center space-x-4">
                              <Avatar className="h-8 w-8" variant="circular">
                                <AvatarImage
                                  src={row?.profilePicture}
                                  alt="user profile pic"
                                  className="object-cover rounded-full"
                                />
                                <AvatarFallback className="capitalize text-2xl text-center">
                                  {(row?.name.trim() !== "" &&
                                    row?.name?.[0]) ||
                                    row?.userName?.[0]}
                                </AvatarFallback>
                              </Avatar>
                              <div>
                                <p className="text-base font-medium leading-none">
                                  {row.name}
                                </p>
                                <p className="text-sm text-muted-foreground">
                                  {row.email}
                                </p>
                                <p className="text-xs text-muted-foreground">
                                  @{row?.userName}
                                </p>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        {Object.keys(row).map((key) => {
                          let color = "#1C1E2080";
                          if (key === "invite_date") {
                            if (row[key] === "Active") {
                              color = "#00B87C";
                            } else if (row[key] === "Inactive") {
                              color = "#FF0000";
                            } else if (row[key] === "Pending") {
                              color = "#FFB200";
                            }
                          }
                          if (key === "inviteDate") {
                            return (
                              <TableCell key={key} component="th" scope="row">
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    color: color,
                                  }}
                                >
                                  {row[key]}
                                </Typography>
                              </TableCell>
                            );
                          }

                          return null;
                        })}
                        <TableCell
                          key={row.name + "_role"}
                          component="th"
                          scope="row"
                        >
                          <Select
                            disabled={
                              !hasUserPermission(SA_PERMISSIONS.ADD_USER)
                            }
                            value={row?.user_role?.[0]?.type || "SA"}
                            onValueChange={(value) =>
                              handleChange(value, row?.userId)
                            }
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Assign Role" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                <SelectLabel>Assigned Role</SelectLabel>
                                {Object.keys(availableRoles)?.map((name) => (
                                  <SelectItem
                                    key={name}
                                    value={name?.toString()}
                                  >
                                    {RolesMapping[name] || name}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                              <SelectGroup>
                                <Typography
                                  sx={{
                                    fontWeight: 300,
                                    textTransform: "none",
                                    cursor: "pointer",
                                    color: "red",
                                    marginLeft: "auto",
                                  }}
                                  onClick={() => onRemoveUser(userId)}
                                >
                                  <SelectLabel className="cursor-pointer font-normal">
                                    Remove User
                                  </SelectLabel>
                                </Typography>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <RemoveUserModal getOrgUsers={getOrgUsers} />
        <InviteUserModal getOrgUsers={getOrgUsers} />
      </Stack>
    </ContentContainer>
  );
}
