import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  Dialog,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "../../../redux/slices/modal.slices";
import { Loader2, X } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";

export default function SaveInsightsReportModal(props) {
  const { saveReport, isCreatingReport = false, reportData } = props;
  const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setModal(modalInitState));
  };

  const [saveReportInfo, setSaveReportInfo] = useState({
    name: "",
    description: "",
    isPublic: false,
  });

  useEffect(() => {
    setSaveReportInfo({
      ...saveReportInfo,
      name: reportData?.name || "",
      description: reportData?.description || "",
    });
  }, [reportData?.name, reportData?.description]);

  const folder = localStorage?.getItem("REPORT_FOLDER");
  const [folderInfo, setFolderInfo] = useState(null);

  const fetchFolderInfo = () => {
    if (JSON.parse(localStorage?.getItem("REPORT_FOLDER"))) {
      const folderName = JSON.parse(
        localStorage?.getItem("REPORT_FOLDER")
      )?.[0];
      setFolderInfo(folderName);
    } else {
      setFolderInfo(null);
    }
  };

  useEffect(() => {
    fetchFolderInfo();
  }, []);

  return (
    <Dialog
      sx={{ padding: "40px" }}
      className="z-[9990]"
      open={modal?.open && modal?.type === MODALTYPES.SAVE_INSIGHTS_REPORT}
      onOpenChange={handleClose}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Save Report</DialogTitle>
          <DialogDescription>
            <div>
              Save your report for future purposes and to share with others.
            </div>
            {folderInfo && (
              <div className="mt-2 text-sm italic flex gap-x-2 text-black items-center">
                <p>{`*(This report will go into folder ${folderInfo?.name})`}</p>
                <X
                  className="h-4 w-4 cursor-pointer"
                  onClick={() => {
                    localStorage.removeItem("REPORT_FOLDER");
                    fetchFolderInfo();
                  }}
                />
              </div>
            )}
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="">
              Name
            </Label>
            <Input
              id="name"
              onChange={(e) =>
                setSaveReportInfo({ ...saveReportInfo, name: e.target.value })
              }
              className="col-span-3"
              placeholder="Type your report name here"
              value={saveReportInfo?.name}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="">
              Description
            </Label>
            <Textarea
              placeholder="Type your description here."
              id="name"
              onChange={(e) =>
                setSaveReportInfo({
                  ...saveReportInfo,
                  description: e.target.value,
                })
              }
              className="col-span-3"
              value={saveReportInfo?.description}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="">
              Public?
            </Label>
            <div className="col-span-3">
              <Switch
                checked={saveReportInfo?.isPublic}
                onCheckedChange={(val) =>
                  setSaveReportInfo({ ...saveReportInfo, isPublic: val })
                }
              />
            </div>
          </div>
        </div>
        <DialogFooter>
          {isCreatingReport ? (
            <Button variant="outline" disabled>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Saving...
            </Button>
          ) : (
            <Button
              variant="outline"
              disabled={
                saveReportInfo?.name === "" ||
                saveReportInfo?.description === ""
              }
              onClick={() => saveReport(saveReportInfo)}
            >
              Save
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
