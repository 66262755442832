import { useEffect, useState } from "react";

export default function useInfiniteScroll(containerScollClass, hasNextPage) {
  const [isLoadMore, setIsLoadMore] = useState(false);

  useEffect(() => {
    let container = null;
    const handleScroll = () => {
      if (
        container.scrollTop + container.clientHeight >=
        container.scrollHeight - 100
      ) {
        setIsLoadMore(true);
      } else {
        setIsLoadMore(false);
      }
    };

    let attempts = 0;
    let maxAttempts = 10;
    let timer = 1000;
    let interval = setInterval(() => {
      if (!container) {
        container = document.querySelector(`.${containerScollClass}`);
        attempts++;
        if (container) {
          container.addEventListener("scroll", handleScroll);
          if (container.scrollHeight <= container.clientHeight && hasNextPage) {
            setIsLoadMore(true);
            setTimeout(() => {
              setIsLoadMore(false);
              timer = timer + 1000;
            }, timer);
          }
        }
        if (attempts === maxAttempts || !hasNextPage) {
          clearInterval(interval);
          timer = 1000;
        }
      } else {
        if (container.scrollHeight <= container.clientHeight && hasNextPage) {
          setIsLoadMore(true);
          setTimeout(() => {
            setIsLoadMore(false);
          }, timer);
        }
      }
    }, 1000);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [containerScollClass, hasNextPage]);

  return { isLoadMore };
}
