import React from "react";
import { clearAllTokens } from "../../../helpers/auth.helper";

import { useDispatch } from "react-redux";
import { setUserData } from "../../../redux/slices/auth.slice";
import { intialState } from "../../../redux/slices/app.slices";
import { resetOrganisations } from "../../../redux/slices/organisation.slice";
import MainLoader from "components/common/loaders/MainLoader";
import { Backdrop } from "@mui/material";
import { resetAppState, setAppState } from "redux/slices/appState.slices";
export default function Logout() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    clearAllTokens();
    window.location.href = "/";
    // Clear the persisted state
    dispatch(setUserData(null));
    dispatch(setAppState(intialState));
    dispatch(resetOrganisations());
    dispatch(resetAppState());
  }, [dispatch]);
  return (
    <div>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 999 }}
        open={true}
      >
        <MainLoader />
      </Backdrop>
    </div>
  );
}
