import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAxios from "../../../hooks/useAxios";
import {
  MODALTYPES,
  setModal,
  modalInitState,
} from "../../../redux/slices/modal.slices";
import { API_ROUTES, APP_ROUTES } from "../../../routes";

const initialState = {
  labels: [],
  colors: [],
  contentThemes: [],
  discounts: [],
  entities: [],
  keywords: [],
  brands: [],
  persons: [],
  sentiments: [],
};

export default function useManualTextTagging(
  payload,
  initGroup,
  postOnboarding
) {
  const { axiosOrgInstance } = useAxios();
  const { open, type } = useSelector((state) => state?.modal);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let intervalId = useRef(null);

  const [state, setState] = React.useState({
    ...initialState,
  });

  function handleTagsChange(event) {
    const { name, value } = event;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function updateStateFromAITags(tags) {
    //add tags to existing state
    setState((prevState) => ({
      ...prevState,
      labels: [...prevState?.labels, ...(tags?.labels || [])],
      colors: [...prevState?.colors, ...(tags?.colors || [])],
      contentThemes: [
        ...prevState?.contentThemes,
        ...(tags?.contentThemes || []),
      ],
      discounts: [...prevState?.discounts, ...(tags?.discounts || [])],
      entities: [...prevState?.entities, ...(tags?.entities || [])],
      keywords: [...prevState?.keywords, ...(tags?.keywords || [])],
      brands: [...prevState?.brands, ...(tags?.brands || [])],
      persons: [...prevState?.persons, ...(tags?.persons || [])],
      sentiments: [...prevState?.sentiments, ...(tags?.sentiments || [])],
    }));
  }

  function resetModal() {
    dispatch(setModal(modalInitState));
  }

  function getGroupTags() {
    axiosOrgInstance
      .get(
        API_ROUTES.TAGGING.CREATE_TEXT_GROUP_TAGS.replace(
          ":groupId",
          payload?.group?._id
        )
      )
      .then((res) => {
        updateStateFromAITags(res);
      });
  }
  function fetchAiTags() {
    axiosOrgInstance
      .get(
        API_ROUTES.TAGGING.GET_TEXT_AI_TAGS.replace(
          ":groupId",
          payload?.group?._id
        )
      )
      .then((res) => {
        updateStateFromAITags(res);
      });
  }

  function getAITaggingStatus() {
    axiosOrgInstance
      .get(API_ROUTES.COMMON.APPLICATION_STATUS, {
        params: {
          groupId: payload?.group?._id,
        },
      })
      .then((res) => {
        if (res?.isGroupAiTaggingDone) {
          clearInterval(intervalId?.current);
          fetchAiTags();
        }
      });
  }

  function handlePrimaryAction(group) {
    axiosOrgInstance
      .post(
        API_ROUTES.TAGGING.CREATE_TEXT_GROUP_TAGS?.replace(
          ":groupId",
          group?._id
        ),
        state
      )
      .then((res) => {
        toast.success(
          `Successful placements of tags in '${group?.groupName?.slice(
            0,
            20
          )} ${group?.groupName?.length > 20 ? "..." : ""}`
        );
        resetModal();
        initGroup();
        if (postOnboarding) return;
        navigate(APP_ROUTES.TAGGING.TEXT_TAGGING);
      });
  }
  function handleClose() {
    dispatch(setModal(modalInitState));
    setState({ ...initialState });
  }

  useEffect(() => {
    if (open && type === MODALTYPES.MANUAL_TEXT_TAGGING_MODAL) {
      console.log("postOnboarding", postOnboarding);
      if (postOnboarding) {
        getGroupTags();
        return;
      }
      getAITaggingStatus();
      intervalId.current = setInterval(() => {
        getAITaggingStatus();
      }, 5000);
    }
  }, [open, postOnboarding, type]);

  return {
    handleClose,
    state,
    handleTagsChange,
    handlePrimaryAction,
  };
}
