const Organisation = ({ isActive }) => {
  const color = isActive ? "#7167FB" : "#858383";
  return (
    <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m13.969 7.454-3.08-1.417V1.974a.444.444 0 0 0-.267-.4L7.29.041a.444.444 0 0 0-.373 0L3.582 1.574a.445.445 0 0 0-.258.405V6.04L.258 7.454A.445.445 0 0 0 0 7.86v4.347a.444.444 0 0 0 .258.404l3.333 1.533a.444.444 0 0 0 .373 0l3.147-1.449 3.147 1.45a.444.444 0 0 0 .373 0l3.333-1.534a.444.444 0 0 0 .258-.404V7.859a.444.444 0 0 0-.253-.405Zm-3.52 1.44L8.178 7.86l2.27-1.045L12.72 7.86l-2.271 1.035Zm-.445-2.862L7.142 7.366V3.948a.444.444 0 0 0 .156-.036L10 2.672v3.37l.004-.01ZM7.111.93l2.271 1.044-2.27 1.045-2.268-1.045L7.111.93ZM3.778 6.81l2.27 1.044-2.27 1.04L1.507 7.86l2.27-1.049Zm2.889 5.107-2.89 1.333V9.832a.444.444 0 0 0 .179-.049L6.658 8.54l.009 3.378Zm6.666 0-2.87 1.333V9.832a.444.444 0 0 0 .16-.049l2.701-1.244.01 3.378Z"
        fill={color}
      />
    </svg>
  );
};

export default Organisation;
