export const SELECTION_TYPE_MAPPING = {
  "Last 7 days": 7,
  "Last 14 days": 14,
  "Last 30 days": 30,
  "Last 90 days": 90,
  "Last 365 days": 365,
  "Last 24 hours": 1,
};

export const REPORTABLE_TYPE = {
  INSIGHTS: "insights",
  COMPARE: "compare",
  COMAPRE_MULTIPLE_DATE: "compare-dates",
};

export const groupbyOptions = [
  {
    label: "Ad-Name",
    value: "adName",
  },
  {
    label: "Headline",
    value: "headline",
  },
  {
    label: "Landing Page",
    value: "landingPageUrl",
  },
  {
    label: "Copy",
    value: "body",
  },
  {
    label: "Creative",
    value: "groupId",
  },
];

export const kpiFiltersConditions = [
  {
    label: ">",
    value: "greater",
  },
  {
    label: "<",
    value: "lesser",
  },
  {
    label: "is between",
    value: "between",
  },
];

export const FILTER_AGGREGATORS_REVERSE_MAPPING = {
  isNot: {
    label: "is not",
    value: "isNot",
  },
  is: {
    label: "is",
    value: "is",
  },
  contains: {
    label: "contains",
    value: "contains",
  },
  notContains: {
    label: "does not contains",
    value: "notContains",
  },
};

export const kpiFiltersReverse = {
  [kpiFiltersConditions[0]?.value]: kpiFiltersConditions[0]?.label,
  [kpiFiltersConditions[1]?.value]: kpiFiltersConditions[1]?.label,
  [kpiFiltersConditions[2]?.value]: kpiFiltersConditions[2]?.label,
};

export const SELECTED_METRIC_COLOR = {
  1: "bg-red-500",
  2: "bg-yellow-500",
  3: "bg-indigo-400",
  4: "bg-pink-400",
  5: "bg-green-400",
  6: "bg-purple-400",
  7: "bg-blue-400",
  8: "bg-lime-400",
  9: "bg-violet-400",
  10: "bg-emerald-400",
  11: "bg-[#7167FB]",
  12: "bg-blue-400",
};

export const SELECTION_TYPE_MAPPING_REVERSE = {
  7: "Last 7 days",
  14: "Last 14 days",
  30: "Last 30 days",
  90: "Last 90 days",
  365: "Last 365 days",
  1: "Last 24 hours",
};

export const REPORT_TYPE_ICONS = {
  insights: "/insights.png",
  compare: "/compare.png",
  "compare-dates": "/compare-dates.png",
  "ad-breakdown": "/ad-breakdown.png",
  "ad breakdown": "/ad-breakdown.png",
  "creative insight": "/creative-insights.png",
  "creative-insight": "/creative-insights.png",
  predictions: "/predictions.png",
  recommendations: "/recommendations.png",
  "creative-momentum": "/creative-momentum-fatigue.png",
};

export const AD_BREAKDOWN_FIELDS = ["adSetName", "campaignName", "adName"];

export const AD_BREAKDOWN_FIELDS_REVERSE = {
  [AD_BREAKDOWN_FIELDS[2]]: "Ad Name",
  [AD_BREAKDOWN_FIELDS[0]]: "Ad Set Name",
  [AD_BREAKDOWN_FIELDS[1]]: "Campaign Name",
};
