import { Box, CircularProgress } from "@mui/material";
import React from "react";

const _containerStyles = {
  display: "flex",
  flexWrap: "wrap",
  marginTop: "40px",
  paddingRight: "24px",
  paddingBottom: "40px",
  gap: "32px",
  width: "100%",
  maxHeight: "60vh",
  height: "100%",
  overflowY: " auto",
};
export default function ContentContainer(props) {
  const {
    children,
    containerStyles = {},
    className,
    isLoading = false,
  } = props;
  const classes = ["content-container", className].join(" ");
  return (
    <div
      className={classes}
      id="scrollable-content-container"
      style={{ ..._containerStyles, ...containerStyles }}
    >
      {children}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginX: "auto",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>
    </div>
  );
}
