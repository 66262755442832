import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { SuccessModal } from "../../modals";
import PublicNavbar from "../PublicNavbar/PublicNavbar";
import MainLoader from "components/common/loaders/MainLoader";

export default function PublicContainer(props) {
  const { children, bodyStyles, showCta = true } = props;
  const { loading } = useSelector((state) => state?.app);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        height: "100vh",
      }}
    >
      <PublicNavbar showMessage={showCta} />
      <div
        className="public-container__body"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "50px 6.25%",
          width: "100%",
          alignItems: "center",
          height: "calc(100% - 12%)",
          overflowY: "auto",
          ...bodyStyles,
        }}
      >
        {children}
      </div>
      <SuccessModal />
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading?.length > 0}
      >
        <MainLoader />
      </Backdrop>
    </div>
  );
}
