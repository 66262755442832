import React from "react";

const Settings = ({ isActive }) => {
  const color = isActive ? "#7167FB" : "#858383";
  return (
    <svg width={15} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m14.713 9.876-1.151-.984a6.32 6.32 0 0 0 0-2.032l1.15-.985a.563.563 0 0 0 .164-.618l-.015-.046a7.802 7.802 0 0 0-1.401-2.424l-.032-.037a.564.564 0 0 0-.617-.167l-1.43.508A6.194 6.194 0 0 0 9.63 2.08L9.353.584A.563.563 0 0 0 8.9.133l-.048-.01a7.941 7.941 0 0 0-2.795 0l-.047.01a.563.563 0 0 0-.454.451L5.28 2.086c-.632.239-1.22.58-1.74 1.009l-1.44-.512a.562.562 0 0 0-.617.167l-.032.037a7.84 7.84 0 0 0-1.4 2.424l-.017.046a.565.565 0 0 0 .164.618l1.165.995a6.185 6.185 0 0 0 0 2.008l-1.165.995a.563.563 0 0 0-.164.619l.016.045a7.82 7.82 0 0 0 1.401 2.424l.032.037a.565.565 0 0 0 .617.167l1.44-.511c.523.43 1.109.771 1.74 1.009l.277 1.5a.563.563 0 0 0 .454.453l.047.008a7.9 7.9 0 0 0 2.795 0l.048-.008a.563.563 0 0 0 .453-.452l.276-1.494a6.148 6.148 0 0 0 1.753-1.013l1.429.508a.563.563 0 0 0 .617-.167l.032-.037a7.841 7.841 0 0 0 1.4-2.424l.016-.046a.56.56 0 0 0-.163-.615Zm-2.4-2.809a4.98 4.98 0 0 1 0 1.621l-.116.705 1.314 1.123c-.2.459-.45.893-.75 1.294l-1.63-.579-.553.454c-.42.345-.887.615-1.393.805l-.67.252-.315 1.705a6.642 6.642 0 0 1-1.494 0l-.315-1.709-.664-.255a4.916 4.916 0 0 1-1.383-.803l-.552-.455-1.642.583a6.642 6.642 0 0 1-.749-1.294l1.327-1.133-.114-.704a5.06 5.06 0 0 1-.065-.8c0-.268.02-.537.065-.8l.114-.702L1.401 5.24c.199-.459.45-.891.749-1.294l1.642.584.552-.456a4.918 4.918 0 0 1 1.383-.803l.666-.251.315-1.709a6.636 6.636 0 0 1 1.494 0l.315 1.705.67.252c.504.19.973.46 1.393.805l.552.453 1.632-.578c.298.402.548.837.748 1.294L12.2 6.366l.114.701ZM7.457 4.608a3.094 3.094 0 1 0 0 6.188 3.094 3.094 0 0 0 0-6.188Zm1.392 4.486a1.962 1.962 0 0 1-1.392.577 1.962 1.962 0 0 1-1.82-1.215 1.962 1.962 0 0 1 .427-2.146 1.954 1.954 0 0 1 1.393-.577c.525 0 1.02.204 1.392.577a1.962 1.962 0 0 1 .576 1.392c0 .525-.205 1.02-.576 1.392Z"
        fill={color}
      />
    </svg>
  );
};

export default Settings;
