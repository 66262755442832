import { Stack } from "@mui/system";
import React from "react";
import useBrandLogos from "./useBrandLogos";
import { deleteIcon, information } from "assets/icons/svg";
import { AddOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import ContentContainer from "components/layouts/ContentContainer";
import { FileUploadDnD } from "components/common/molecules";

const NoDataTemplate = ({ uploadBrandLogo }) => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        flex: 1,
        paddingTop: "7%",
      }}
    >
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          gap: "6px",
        }}
      >
        <img
          src={information}
          alt=""
          style={{
            height: "20px",
            width: "20px",
          }}
        />
        <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
          No Logo Have Been Added Yet
        </Typography>
      </Stack>
      <Typography
        sx={{
          fontSize: "10px",
          fontWeight: "400",
          color: "#1C1E2080",
          textAlign: "center",
          textTransform: "capitalize",
          marginTop: "15px",
        }}
      >
        your brand logos will appear here when you uploaded
      </Typography>

      <Button
        variant="outlined"
        sx={{ marginTop: "25px", textTransform: "none" }}
        onClick={uploadBrandLogo}
      >
        Upload
      </Button>
    </Stack>
  );
};

const BrandLogoCard = ({ brandLogo, deleteBrandLogo }) => {
  return (
    <Stack
      key={brandLogo.id}
      sx={{
        minWidth: "224px",
        minHeight: "91px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "0.5px solid #A1B0CC",
        borderRadius: "4px",
        position: "relative",
      }}
    >
      <Stack sx={{ width: "140px", height: "46px" }}>
        <div
          style={{
            padding: "12px",
            position: "absolute",
            right: "0px",
            top: "0px",
            cursor: "pointer",
          }}
        >
          <img
            src={deleteIcon}
            alt=""
            onClick={() => deleteBrandLogo(brandLogo._id)}
          />
        </div>
        <img
          src={brandLogo?.brandLogoUrl}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "cover",
          }}
          alt=""
        />
      </Stack>
    </Stack>
  );
};

const AddMoreButton = ({ addBrandLogo }) => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: "107px",
        height: "91px",
        border: "0.5px solid #A1B0CC",
        borderRadius: "4px",
        cursor: "pointer",
      }}
      onClick={addBrandLogo}
    >
      <AddOutlined htmlColor="#1C1E2080" />
      <Typography
        sx={{
          fontSize: "10px",
          fontWeight: "400",
          color: "#1C1E2080",
        }}
      >
        Add More
      </Typography>
    </Stack>
  );
};

export default function BrandLogos() {
  const { brandLogos, addBrandLogo, deleteBrandLogo } = useBrandLogos();
  return (
    <ContentContainer
      containerStyles={{
        maxHeight: "65vh",
      }}
    >
      {!!brandLogos.length ? (
        <Stack
          sx={{
            gap: "32px",
          }}
        >
          <FileUploadDnD
            onUpload={(files) => addBrandLogo(files)}
            uploaderStyles={{
              border: "none",
            }}
          >
            <AddMoreButton />
          </FileUploadDnD>
          <Stack
            direction={"row"}
            sx={{
              gap: "28px",
              flexWrap: "wrap",
            }}
          >
            {brandLogos.map((brandLogo) => (
              <BrandLogoCard
                key={brandLogo.id}
                brandLogo={brandLogo}
                deleteBrandLogo={deleteBrandLogo}
              />
            ))}
          </Stack>
        </Stack>
      ) : (
        <FileUploadDnD
          onUpload={(files) => addBrandLogo(files)}
          uploaderStyles={{
            border: "none",
          }}
        >
          <NoDataTemplate uploadBrandLogo={addBrandLogo} />
        </FileUploadDnD>
      )}
    </ContentContainer>
  );
}
