import * as React from "react";

const PillIcon = (props) => {
  const { isActive, ...rest } = props;

  const color = isActive ? "#7167FB" : "#1C1E20";
  const opacity = isActive ? "1" : "0.22";

  return (
    <svg
      width={40}
      height={8}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect opacity={opacity} width={40} height={8} rx={4} fill={color} />
    </svg>
  );
};

export default PillIcon;
