import {
  CirclePlus,
  Eye,
  EyeOff,
  FolderOpen,
  FolderOpenDot,
  RefreshCcw,
} from "lucide-react";
import { twJoin } from "tailwind-merge";
import { SidebarItem } from "../ModernSidebar/NewSidebar";
import Spinner from "components/common/loaders/Spinner";
import { setSidebarOpen } from "redux/slices/appState.slices";
import CommonAlertModal from "components/modals/CommonAlert/CommonAlert";
import { FolderRootItem } from "./Component/FolderRootItem";
import useFolder from "./useFolder";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import ellipsis from "@/../../public/ellipsis.png";
import { isOrganizationOnboarded } from "utils/helper";
import { useSelector } from "react-redux";

const SidebarFolders = ({ open }) => {
  const {
    inputRef,
    showLoader,
    showInput,
    openRenameInput,
    selectedFolderName,
    folderIdForEdit,
    appState,
    folderTree,
    openFolderIds,
    dropdownRef,
    showArchiveFolder,
    dispatch,
    setSelectedFolderName,
    handleFolderAction,
    addFolder,
    handlePlusClick,
    isOrgInTrialMode,
    toggleFolder,
    setFolderForEdit,
    setOpenRenameInput,
    duplicateReport,
    checkIfActionAllowed,
    handleRefresh,
    toggleShowArchiveFolder,
    deleteReport,
    HandleChangeVisibility,
  } = useFolder();

  const { selectedOrganisation } = useSelector((state) => state?.organisation);

  return (
    <>
      <div className="h-screen">
        <div ref={inputRef}>
          {open && (
            <p className="flex items-center justify-between py-4 px-2">
              <div className="flex items-center text-xs font-medium text-gray-600">
                <FolderOpen size={16} className="mr-3" />
                <span className="mr-2">Folders</span>
              </div>
              {/* <div className="flex gap-2 justify-center items-center text-gray-500">
                <RefreshCcw
                  onClick={() => handleRefresh(true)}
                  size={16}
                  className=" cursor-pointer"
                />
                {!showLoader ? (
                  <CirclePlus size={16} onClick={handlePlusClick} />
                ) : (
                  <Spinner spinnerClass="h-4 w-4 border-[#89CFF0]" />
                )}
              </div> */}
              <DropdownMenu>
                <DropdownMenuTrigger asChild className="cursor-pointer">
                  <img width="16" alt="" src={ellipsis} />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-max">
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      className="text-xs flex gap-2 items-center cursor-pointer"
                      onClick={() => handleRefresh(true)}
                    >
                      {!showLoader ? (
                        <RefreshCcw size={12} className=" cursor-pointer" />
                      ) : (
                        <Spinner spinnerClass="h-4 w-4 border-[#89CFF0]" />
                      )}
                      <span>Refresh</span>
                    </DropdownMenuItem>

                    <DropdownMenuItem
                      className="text-xs flex gap-2 items-center cursor-pointer"
                      onClick={handlePlusClick}
                    >
                      <CirclePlus size={12} />
                      <span>Add Folder</span>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      className="text-xs flex gap-2 items-center cursor-pointer"
                      onClick={() => {
                        dispatch(toggleShowArchiveFolder());
                      }}
                    >
                      {showArchiveFolder ? (
                        <EyeOff size={12} />
                      ) : (
                        <Eye size={12} />
                      )}
                      <span>
                        {showArchiveFolder
                          ? "Hide Archive Folder"
                          : "Show Archive Folder"}
                      </span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </p>
          )}
          {open && (showInput || openRenameInput) && (
            <div className="relative">
              <input
                type="text"
                placeholder="Enter folder name"
                className={`border border-blue-500 text-xs mb-1 px-4 py-2 rounded-xl shadow-lg outline-none w-[100%] h-[40px] z-10 ${
                  showLoader ? "opacity-50 cursor-not-allowed" : ""
                }`}
                value={openRenameInput ? selectedFolderName : null}
                onChange={(e) => setSelectedFolderName(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !showLoader) {
                    openRenameInput
                      ? handleFolderAction(
                          "rename",
                          folderIdForEdit,
                          selectedFolderName
                        )
                      : addFolder(event);
                  }
                }}
                disabled={showLoader}
              />
            </div>
          )}
        </div>

        {open ? (
          <div
            className={twJoin(
              "max-w-[500px] overflow-auto scroll-smooth",
              isOrganizationOnboarded(selectedOrganisation)
                ? "h-[calc(100vh-280px)]"
                : "h-[calc(100vh-220px)]"
            )}
          >
            {folderTree?.map(({ children, ...folder }) => {
              if (
                folder?.name?.toLowerCase() === "archive" &&
                folder?.isArchive &&
                !showArchiveFolder
              ) {
                return null;
              }
              return (
                <FolderRootItem
                  children={children}
                  folder={folder}
                  toggleFolder={toggleFolder}
                  openFolderIds={new Set(openFolderIds)}
                  setFolderForEdit={setFolderForEdit}
                  folderIdForEdit={folderIdForEdit}
                  setSelectedFolderName={setSelectedFolderName}
                  setOpenRenameInput={setOpenRenameInput}
                  handleFolderAction={handleFolderAction}
                  duplicateReport={duplicateReport}
                  dropdownRef={dropdownRef}
                  showLoader={showLoader}
                  checkIfActionAllowed={checkIfActionAllowed}
                  deleteReport={deleteReport}
                  HandleChangeVisibility={HandleChangeVisibility}
                />
              );
            })}
          </div>
        ) : (
          <SidebarItem
            key={"folders"}
            onClick={() => dispatch(setSidebarOpen(!appState.isSidebarOpen))}
            icon={<FolderOpenDot size={16} />}
            text={"Folders"}
            active={false}
          />
        )}
      </div>
      <CommonAlertModal />
    </>
  );
};

export default SidebarFolders;
