import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { ContentContainer, PrivateContainer } from "components/layouts";
import { ManualTextTaggingModal, TextTaggingModal } from "components/modals";
import styles from "./styles.module.scss";
import SyncIcon from "@mui/icons-material/Sync";
import useTextTagging from "./useTextTagging";
import { APP_ROUTES } from "routes";
import { BackButton } from "components/common/atoms";

export default function UnTaggedText() {
  const { totalDocs, initGroup, startTagging, groupsList } =
    useTextTagging(false);
  return (
    <PrivateContainer
      activePage={APP_ROUTES.GROUPS_TAGS.UNTAGGED.TEXT}
      bodyStyles={{ justifyContent: "flex-start" }}
    >
      <BackButton
        to={APP_ROUTES.GROUPS_TAGS.default}
        btnText="Untagged Text"
        from={APP_ROUTES.GROUPS_TAGS.TAGGING}
      />
      <div className={styles.image_tab__container}>
        <div className={styles.header}>
          <div className={styles.left}>
            <span className="text-sm">Total Groups: {totalDocs}</span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "rgba(28, 30, 32, 0.5)",
                cursor: "pointer",
              }}
              onClick={initGroup}
            >
              Refresh <SyncIcon sx={{ padding: "0.25rem" }} />
            </span>
          </div>
        </div>

        <ContentContainer containerStyles={{ maxHeight: "70vh" }}>
          <Stack
            direction="column"
            spacing={4}
            sx={{ paddingRight: "33px", width: "100%" }}
          >
            {groupsList.map((text) => {
              return (
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  spacing={1}
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "#F2F3F5",
                    padding: "16px",
                  }}
                  key={text?._id}
                >
                  <Stack gridColumn="span 9" spacing={1}>
                    <Typography variant="h3">Text:</Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "rgba(28, 30, 32, 0.5);" }}
                    >
                      {text?.groupName}
                    </Typography>
                  </Stack>
                  <Stack
                    gridColumn="span 3"
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button
                      variant="outlined"
                      onClick={() => startTagging(text)}
                    >
                      Start Tagging
                    </Button>
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </ContentContainer>
        <TextTaggingModal initGroup={initGroup} />
        <ManualTextTaggingModal initGroup={initGroup} />
      </div>
    </PrivateContainer>
  );
}
