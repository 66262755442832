export const reportData = [
  {
    id: "1",
    type: "Report One Type",
    reportList: [
      {
        id: "1",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "2",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "3",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "4",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "5",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "6",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "7",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "8",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "9",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "10",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "11",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "12",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "13",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "14",
        thumbnail: "https://picsum.photos/id/237/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
    ],
  },
  {
    id: "2",
    type: "Report Two Type",
    reportList: [
      {
        id: "1",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "2",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "3",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "4",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "5",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "6",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "7",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "8",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "9",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "10",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "11",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "12",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "13",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "14",
        thumbnail: "https://picsum.photos/seed/picsum/200/300",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
    ],
  },
  {
    id: "3",
    type: "Report Three Type",
    reportList: [
      {
        id: "1",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "2",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "3",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "4",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "5",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "6",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "7",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "8",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "9",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "10",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "11",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "12",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "13",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
      {
        id: "14",
        thumbnail: "https://picsum.photos/200/300?grayscale",
        roas: "3.4X (24% Better)",
        impression: "10000",
        ads: "14",
      },
    ],
  },
];
