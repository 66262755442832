import React from "react";
import { TabNav } from "components/common/molecules";
import { BrandLogos, BrandColors, BrandFonts, ProductShoots } from "./Tabs";

const tabs = [
  {
    name: "Brand Logos",
    component: <BrandLogos />,
  },
  {
    name: "Brand Colors",
    component: <BrandColors />,
  },
  {
    name: "Brand Fonts",
    component: <BrandFonts />,
  },
  {
    name: "Product Shoots",
    component: <ProductShoots />,
  },
];
export default function BrandKit() {
  return (
    <TabNav
      tabs={tabs}
      buttonStyles={{
        borderRadius: "0px",
      }}
    />
  );
}
