import React, { useEffect, useRef, useState } from "react";
import { pick } from "lodash";
import { Slider, linearProgressClasses } from "@mui/material";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import ReactPlayer from "react-player";
import HeaderFeatures from "components/common/molecules/HeaderFeature/HeaderFeatures";
import { PrivateContainer } from "components/layouts";
import { APP_ROUTES } from "routes";
import useCreativeInsights from "./UseCreativeInsight";
import SEO from "utils/SEO";
import ReportDetails from "components/common/molecules/ReportInformation/ReportDetails";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { toPng } from "html-to-image";
import {
  groupbyOptions,
  kpiFiltersConditions,
  kpiFiltersReverse,
} from "config/statics";
import Filters from "components/common/molecules/Filters/Filters";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { useDispatch } from "react-redux";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { cn } from "@/lib/utils";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import {
  Check,
  ChevronDown,
  ChevronsUpDown,
  Download,
  Loader2,
} from "lucide-react";
import PresetModal from "components/modals/PresetsModal/PresetModal";
import MoveReportToFolderModal from "components/modals/MoveReportToFolderModal/MoveReportToFolder";
import axios from "axios";
import {
  BorderLinearProgress,
  DarkTooltip,
} from "screens/Insights/Components/ColorTable/ColorTable";
import { Gauge } from "@/components/ui/gauge";
import { getColorForProgress } from "utils/helper";
import MetaLoader from "components/common/loaders/MetaLoader";
import SaveInsightsReportModal from "components/modals/SaveInsightsReportModal/SaveInsightsReportModal";
import { useSelector } from "react-redux";
import { Skeleton } from "@/components/ui/skeleton";
import EmptyData from "components/common/empty/EmptyData";
import StorytellerLogoLoader from "components/common/loaders/StorytellerLogoLoader";

const CreativeInsight = () => {
  const {
    videoBreakdownData,
    isVideoBreakdownLoading,
    isLoadingSpecificPreset,
    filterAndOptions,
    insightState,
    insightsData = [],
    mediaAsset,
    isGettingMediaAsset,
    isInsightLoading,
    isSaveReportEnable,
    visualisationTab,
    hideSidebar,
    groupBy,
    isBookmarkSuccess,
    specificInsightsId,
    isCompareView,
    isMakingShortUrl,
    currentFilterObj,
    payload,
    selectedMetrices,
    isCreatingReport,
    kpiFilters,
    sortedData,
    rowSelection,
    isUpdatingReport,
    disableFilters,
    groupIds,
    date,
    isLoadingFilters,
    smartSuggestion,
    isSmartSuggestionLoading,
    currentGroup,
    insightsReport,
    reportableMessage,
    selectedKPIs,
    presetData,
    preset,
    specificPresetData,
    isMakingPreset,
    pagination,
    setPagination,
    tempKpis,
    reportData,
    filterSet,
    adAccountIds,
    audienceSegments,
    colorFormat,
    loadingReport,
    handleColorFormatChange,
    deleteReport,
    setSortedData,
    setRowSelection,
    handleExtraFilterSelectUpdate,
    handleSearchMotionFilters,
    changeGroupBy,
    onOpenFilters,
    onOpenKpiFilters,
    onFilterWhereChange,
    onFilterValueChange,
    onUpdatePayload,
    onUpdateKpiFilters,
    onUpdateSelectedMetrices,
    handleUpdateSearchExtraFilter,
    onSelectedMetricsUpdate,
    handleAggregateChange,
    handleUpdateAndSave,
    onCompare,
    setSelectedKPIs,
    handlePresetSelect,
    makePreset,
    onDateDaysChange,
    handleAddNewFilter,
    setVisualisationTab,
    _handleApplyFilters,
    saveReport,
    handleRemoveNewFilter,
    handleAggregateLogicChangeExtraFilters,
    handleBookmarkReport,
    handleGroupIdCHange,
    handleRemoveBookmark,
    moveReportToFolder,
    handleUrlShortener,
    setCurrentFilterObj,
    updatePreset,
    onUpdateReportData,
    setTempKpis,
    doesShowColor,
    onFilterFilterChange,
    applyPayload,
    onRemovePayload,
    handleDeleteFromFilterSet,
    onFilterSetOpen,
    handleAdAccountId,
    handleAddMore,
    handleUpdateSelectionType,
    handleUpdateDate,
  } = useCreativeInsights();

  const dispatch = useDispatch();

  const { open, type } = useSelector((state) => state?.modal);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [graphChoice, setGraphChoice] = useState("videoDropOff");
  const [isPerformanceMetrics, setIsPerformanceMetrics] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [colToBeShown, setColumnToBeShowed] = useState([]);
  const [fifteenPercentIntervals, setFifteenPercentIntervals] = useState(null);
  const playerRef = useRef(null);
  const imageRef = useRef();

  const GRAPH_CHOICE_REVERSE = {
    audienceRetention: "Audience retention",
    videoDropOff: "Drop off",
  };

  const handleDuration = (duration) => {
    setVideoDuration(duration);
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    if (playerRef.current) {
      playerRef.current.seekTo(newValue, "seconds");
    }
  };

  const handleProgress = (state) => {
    const { playedSeconds } = state;
    setSliderValue(playedSeconds);
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleDownload = async (url) => {
    try {
      const response = await axios.get(url, {
        responseType: "blob",
      });
      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "creative" || "download");

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const metricsOptions = insightState?.metrics?.map((item) => ({
    label: item?.label,
    value: item?.kpi,
  }));

  useEffect(() => {
    if (videoBreakdownData) {
      const totalVideoDuration =
        videoBreakdownData?.[graphChoice]?.at(-1)?.duration;
      let _fifteenPercentIntervals = videoBreakdownData?.[graphChoice].filter(
        (item) => item.duration % 4 === 0
      );

      // check if the last item is not included and add it
      const lastItem = videoBreakdownData?.[graphChoice].at(-1);
      if (lastItem && !_fifteenPercentIntervals.includes(lastItem)) {
        _fifteenPercentIntervals = [..._fifteenPercentIntervals, lastItem];
      }

      setFifteenPercentIntervals(_fifteenPercentIntervals);
    }
  }, [videoBreakdownData, graphChoice]);

  useEffect(() => {
    const _kpi = [];
    if (tempKpis?.length) {
      tempKpis.forEach((t) => _kpi.push(t.value));
      setColumnToBeShowed(_kpi);
    }
  }, [tempKpis]);

  const CustomTooltip = (props) => {
    const data = props?.payload?.[0]?.payload;
    if (data && props?.active) {
      return (
        <div className="bg-white border border-gray-300 shadow-xl rounded-lg p-4 gap-y-2">
          <div>{formatTime(data?.duration)}</div>
          <div>
            {GRAPH_CHOICE_REVERSE?.[graphChoice]}: {data?.value?.toFixed(2)}
          </div>
        </div>
      );
    }
  };

  return (
    <PrivateContainer
      bodyStyles={{
        justifyContent: "flex-start",
        maxHeight: "calc(100vh - 300px)",
      }}
      activePage={APP_ROUTES.INSIGHTS.default}
      hideSidebar={hideSidebar}
    >
      <SEO
        title="Creative Insight"
        description="Creative insight reports powered by Storyteller"
        name="Insights by Storyteller"
        type="Report"
      />
      {loadingReport && specificInsightsId ? (
        <StorytellerLogoLoader />
      ) : (
        <div className="w-full flex h-full text-xs">
          <div
            className={`overflow-auto px-5 ${
              isDrawerOpen ? "w-[70%]" : "w-full"
            }`}
          >
            <div className="sticky top-0 z-[50] flex justify-between w-full mb-4 bg-white pb-2">
              <div className="w-1/3">
                <p className="outline-none truncate">{reportData?.name}</p>
              </div>
              <HeaderFeatures
                specificInsightsId={specificInsightsId}
                disableFilters={disableFilters}
                isSaveReportEnable={isSaveReportEnable}
                handleUpdateAndSave={handleUpdateAndSave}
                isMakingShortUrl={isMakingShortUrl}
                handleUrlShortener={handleUrlShortener}
                hideSidebar={hideSidebar}
                isBookmarkSuccess={isBookmarkSuccess}
                handleRemoveBookmark={handleRemoveBookmark}
                insightsData={insightsData}
                deleteReport={deleteReport}
                handleBookmarkReport={handleBookmarkReport}
                isUpdatingReport={isUpdatingReport}
                report={insightsReport}
                setIsDrawerOpen={setIsDrawerOpen}
                isDrawerOpen={isDrawerOpen}
                onFilterValueChange={onFilterValueChange}
                handleAdAccountId={handleAdAccountId}
                payload={payload}
                adAccountIds={adAccountIds}
                filterAndOptions={
                  filterAndOptions
                    ? pick(filterAndOptions, ["adAccountIds"])
                    : {}
                }
              />
            </div>
            <div className="mt-1 h-full">
              <ReportDetails
                reportData={reportData}
                reportableMessage={reportableMessage}
                onUpdateReportData={onUpdateReportData}
              />
              <Filters
                handleUpdateDate={handleUpdateDate}
                handleUpdateSelectionType={handleUpdateSelectionType}
                filterSet={filterSet}
                insightState={insightState}
                onDateDaysChange={onDateDaysChange}
                disableFilters={disableFilters}
                hideSidebar={hideSidebar}
                groupBy={groupBy}
                groupbyOptions={groupbyOptions}
                specificInsightsId={specificInsightsId}
                onCompare={onCompare}
                groupIds={groupIds}
                _handleApplyFilters={_handleApplyFilters}
                handleAggregateLogicChangeExtraFilters={
                  handleAggregateLogicChangeExtraFilters
                }
                handleRemoveNewFilter={handleRemoveNewFilter}
                handleAddNewFilter={handleAddNewFilter}
                isSmartSuggestionLoading={isSmartSuggestionLoading}
                smartSuggestion={smartSuggestion}
                handleUpdateSearchExtraFilter={handleUpdateSearchExtraFilter}
                handleSearchMotionFilters={handleSearchMotionFilters}
                handleExtraFilterSelectUpdate={handleExtraFilterSelectUpdate}
                currentFilterObj={currentFilterObj}
                isPerformanceMetrics={isPerformanceMetrics}
                setIsPerformanceMetrics={setIsPerformanceMetrics}
                setCurrentFilterObj={setCurrentFilterObj}
                metricsOptions={metricsOptions}
                kpiFilters={kpiFilters}
                payload={payload}
                filterAndOptions={filterAndOptions}
                kpiFiltersConditions={kpiFiltersConditions}
                kpiFiltersReverse={kpiFiltersReverse}
                changeGroupBy={changeGroupBy}
                onOpenFilters={onOpenFilters}
                onOpenKpiFilters={onOpenKpiFilters}
                onFilterWhereChange={onFilterWhereChange}
                onFilterValueChange={onFilterValueChange}
                onUpdatePayload={onUpdatePayload}
                onUpdateKpiFilters={onUpdateKpiFilters}
                date={date}
                isLoadingFilters={isLoadingFilters}
                onFilterFilterChange={onFilterFilterChange}
                applyPayload={applyPayload}
                onRemovePayload={onRemovePayload}
                onFilterSetOpen={onFilterSetOpen}
                handleDeleteFromFilterSet={handleDeleteFromFilterSet}
                handleAddMore={handleAddMore}
                audienceSegments={audienceSegments}
              />
              <div className="flex gap-x-10 mt-7 w-full justify-around">
                <div className="relative h-fit left-0 border mt-6 mb-4 border-gray-200 rounded-2xl flex flex-col items-center w-[40%] shadow-xl overflow-hidden bg-white">
                  {isGettingMediaAsset ? (
                    <div className="flex justify-start">
                      <Skeleton className="h-[390px] w-[280px] rounded-xl" />
                    </div>
                  ) : (
                    <div className="max-h-[400px] sticky top-0 left-0">
                      {mediaAsset?.imageUrl ? (
                        <>
                          <img
                            ref={imageRef}
                            className="max-h-[350px] object-contain"
                            src={mediaAsset?.imageUrl}
                            alt=""
                          />
                        </>
                      ) : (
                        <div>
                          <ReactPlayer
                            ref={playerRef}
                            url={mediaAsset?.imageUrl || mediaAsset?.videoUrl}
                            controls={true}
                            className="rounded-2xl react-player max-h-[350px]"
                            playing={false}
                            onProgress={handleProgress}
                            onDuration={handleDuration}
                            width="100%"
                            height="350px"
                            audio={false}
                          />
                        </div>
                      )}
                      <div className="w-full flex justify-center my-2">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              // className="w-full bg-black flex justify-center"
                              className="px-2"
                              variant="outline"
                              // size="icon"
                            >
                              <Download className="w-4 h-4" />
                              <ChevronDown className="w-4 h-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="w-56 ml-40">
                            <DropdownMenuGroup>
                              <DropdownMenuItem
                                onClick={() =>
                                  handleDownload(
                                    mediaAsset?.imageUrl || mediaAsset?.videoUrl
                                  )
                                }
                              >
                                <span>Download creative</span>
                              </DropdownMenuItem>
                              {mediaAsset?.videoUrl && (
                                <DropdownMenuItem
                                  onClick={() =>
                                    handleDownload(
                                      insightsData[0]?.thumbnailUrl
                                    )
                                  }
                                >
                                  <span>Download gif</span>
                                </DropdownMenuItem>
                              )}
                            </DropdownMenuGroup>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </div>
                  )}
                </div>

                <div className="w-[60%] h-fit max-h-[700px] overflow-scroll">
                  {mediaAsset?.videoUrl ? (
                    isVideoBreakdownLoading ? (
                      <div className="flex justify-start mt-10 ml-10">
                        <Skeleton className="h-[340px] w-[550px] rounded-xl" />
                      </div>
                    ) : videoBreakdownData ? (
                      <>
                        <div className="mt-3 ml-8 flex items-center gap-x-2 mb-3">
                          <p className="text-sm font-medium">Video Analysis</p>
                          <Select onValueChange={(val) => setGraphChoice(val)}>
                            <SelectTrigger className="w-[170px]">
                              <SelectValue
                                placeholder={GRAPH_CHOICE_REVERSE[graphChoice]}
                              />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                <SelectItem value="videoDropOff">
                                  Drop off
                                </SelectItem>
                                <SelectItem value="audienceRetention">
                                  Audience retention
                                </SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="relative w-full">
                          <ResponsiveContainer
                            width="100%"
                            height={300}
                            style={{ fontSize: "14px" }}
                          >
                            <AreaChart
                              width={500}
                              height={400}
                              data={fifteenPercentIntervals}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                              }}
                            >
                              <defs>
                                <linearGradient
                                  id="colorUv"
                                  x1="0"
                                  y1="0"
                                  x2="0"
                                  y2="1"
                                >
                                  <stop
                                    offset="5%"
                                    stopColor="#8884d8"
                                    stopOpacity={0.8}
                                  />
                                  <stop
                                    offset="95%"
                                    stopColor="#8884d8"
                                    stopOpacity={0}
                                  />
                                </linearGradient>
                                <linearGradient
                                  id="colorPv"
                                  x1="0"
                                  y1="0"
                                  x2="0"
                                  y2="1"
                                >
                                  <stop
                                    offset="5%"
                                    stopColor="#82ca9d"
                                    stopOpacity={0.8}
                                  />
                                  <stop
                                    offset="95%"
                                    stopColor="#82ca9d"
                                    stopOpacity={0}
                                  />
                                </linearGradient>
                              </defs>
                              <CartesianGrid
                                horizontal={true}
                                vertical={false}
                                className="-z-10"
                              />
                              <XAxis
                                dataKey="duration"
                                tickFormatter={(value) => formatTime(value)}
                              />
                              <YAxis />
                              <Area
                                type="monotone"
                                stroke="#8884d8"
                                strokeWidth={2}
                                fillOpacity={1}
                                fill="url(#colorUv)" // Reference the gradient by ID here
                                dataKey="value"
                              />
                              <Tooltip content={<CustomTooltip />} />
                            </AreaChart>
                          </ResponsiveContainer>

                          <div
                            style={{
                              position: "absolute",
                              width: "86.5%",
                              bottom: "12px",
                              left: "55px",
                            }}
                          >
                            <Slider
                              aria-label="Video Progress"
                              value={sliderValue}
                              max={videoDuration}
                              onChange={handleSliderChange}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )
                  ) : null}

                  {isInsightLoading ? (
                    <div className="flex justify-start mt-10 ml-10">
                      <Skeleton className="h-[340px] w-[550px] rounded-xl" />
                    </div>
                  ) : insightsData?.length > 0 ? (
                    <div className="flex items-center flex-col w-full h-full">
                      <div
                        className={`flex gap-x-3 mb-5 ${
                          mediaAsset?.videoUrl ? "mt-10" : ""
                        } justify-start items-center w-[90%]`}
                      >
                        <p className="text-sm font-medium">
                          Performance Metrics
                        </p>
                        <Popover>
                          <PopoverTrigger asChild>
                            <Button
                              // disabled={disableFilters || hideSidebar}
                              variant="outline"
                              role="combobox"
                              className="w-[250px] px-2 overflow-hidden truncate flex justify-between"
                            >
                              <span className="text-black font-medium text-xs">
                                {preset
                                  ? presetData?.filter(
                                      (curr, index) => curr.id === preset
                                    )?.[0]?.name
                                  : "Select preset"}
                              </span>
                              <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-70" />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-[250px] p-0">
                            <Command>
                              <CommandInput placeholder="Search filter..." />
                              <CommandEmpty>No filter found.</CommandEmpty>

                              <CommandList>
                                <CommandGroup>
                                  {presetData?.map((curr, index) => (
                                    <CommandItem
                                      className="text-xs font-medium"
                                      key={curr?.id}
                                      value={curr?.id}
                                      onSelect={(val) => {
                                        handlePresetSelect(val);
                                      }}
                                    >
                                      <Check
                                        className={cn(
                                          "mr-2 h-4 w-4",
                                          curr?.id?.toString() ===
                                            preset?.toString()
                                            ? "opacity-100"
                                            : "opacity-0"
                                        )}
                                      />
                                      {curr?.name}
                                    </CommandItem>
                                  ))}
                                </CommandGroup>
                              </CommandList>

                              <Separator />

                              <CommandItem
                                className="px-8 py-2"
                                key={"custom"}
                                value={"custom"}
                                onSelect={(val) => {
                                  dispatch(
                                    setModal({
                                      open: true,
                                      type: MODALTYPES.PRESETS_MODAL,
                                      payload: {},
                                    })
                                  );
                                }}
                              >
                                Customize columns
                              </CommandItem>
                            </Command>
                          </PopoverContent>
                        </Popover>
                        {isLoadingSpecificPreset && (
                          <div className="flex items-center">
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col items-center gap-y-5 w-[90%]">
                        <Table className="border border-gray-100 rounded-2xl">
                          <TableHeader className="sticky top-0 bg-gray-100">
                            <TableRow>
                              <TableHead className="w-[65%]">Metric</TableHead>
                              <TableHead className="w-[35%]">
                                Performance
                              </TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {insightsData[0]?.columns.map((curr) => {
                              if (!colToBeShown?.includes(curr?.key))
                                return null;
                              return (
                                <TableRow key={curr?.key}>
                                  <TableCell className="font-medium text-gray-500">
                                    {curr.label}
                                  </TableCell>
                                  <TableCell
                                  // style={{
                                  //   backgroundColor:
                                  //     insightsData[0]?.[curr?.key]?.display
                                  //       ?.type === "progressBar"
                                  //       ? ""
                                  //       : insightsData[0]?.[curr?.key]?.display
                                  //           ?.color,
                                  // }}
                                  >
                                    {insightsData[0]?.[curr?.key]?.display
                                      ?.type === "progressBar" ? (
                                      <div className="z-0 flex w-full items-center gap-x-2 justify-center">
                                        <DarkTooltip
                                          arrow
                                          placement="top"
                                          title={
                                            <div className="w-full h-full flex flex-col gap-y-2 justify-center">
                                              <Gauge
                                                value={
                                                  insightsData[0]?.[curr?.key]
                                                    ?.display?.value
                                                }
                                                size="medium"
                                                showValue={true}
                                                color={getColorForProgress(
                                                  insightsData[0]?.[curr?.key]
                                                    ?.display?.value
                                                )}
                                                label={curr?.key?.toUpperCase()}
                                                unit={
                                                  insightsData[0]?.[curr?.key]
                                                    ?.display?.unit
                                                }
                                              />
                                              <div className="bg-[#232323] p-2 mt-1 text-sm font-medium">
                                                <span>
                                                  {
                                                    insightsData[0]?.[curr?.key]
                                                      ?.display?.tooltip?.[0]
                                                      ?.text
                                                  }
                                                </span>{" "}
                                                {insightsData[0]?.[curr?.key]
                                                  ?.display?.tooltip?.[1]
                                                  ?.text && (
                                                  <span
                                                    style={{
                                                      color:
                                                        insightsData[0]?.[
                                                          curr?.key
                                                        ]?.display?.tooltip?.[1]
                                                          ?.color,
                                                    }}
                                                  >
                                                    {
                                                      insightsData[0]?.[
                                                        curr?.key
                                                      ]?.display?.tooltip?.[1]
                                                        ?.text
                                                    }
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          }
                                        >
                                          <div className="flex gap-x-2 w-full justify-start items-center">
                                            <BorderLinearProgress
                                              variant="determinate"
                                              value={
                                                insightsData[0]?.[curr?.key]
                                                  ?.display?.value
                                              }
                                              sx={{
                                                zIndex: 0,
                                                [`& .${linearProgressClasses.bar}`]:
                                                  {
                                                    borderRadius: 5,
                                                    backgroundColor:
                                                      getColorForProgress(
                                                        insightsData[0]?.[
                                                          curr?.key
                                                        ]?.display?.value
                                                      ),
                                                  },
                                                width: "65%",
                                                height: "5px",
                                              }}
                                            />
                                            <span>
                                              {
                                                insightsData[0]?.[curr?.key]
                                                  ?.display?.value
                                              }
                                              {
                                                insightsData[0]?.[curr?.key]
                                                  ?.display?.unit
                                              }
                                            </span>
                                          </div>
                                        </DarkTooltip>
                                      </div>
                                    ) : (
                                      <>
                                        {insightsData[0]?.[curr?.key]?.display
                                          ?.value +
                                          " " +
                                          insightsData[0]?.[curr?.key]?.display
                                            ?.unit}
                                      </>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  ) : (
                    <div className="min-h-full w-full flex items-center justify-center">
                      <EmptyData
                        customClass="mt-10"
                        imageUrl="/choice.png"
                        title="Nothing found"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <MoveReportToFolderModal primaryAction={moveReportToFolder} />
          <PresetModal
            preset={preset}
            handlePresetSelect={handlePresetSelect}
            presetData={presetData}
            selectedMetrices={selectedMetrices}
            specificPresetData={specificPresetData}
            filterAndOptions={filterAndOptions}
            onUpdateSelectedMetrices={onUpdateSelectedMetrices}
            updatePreset={updatePreset}
            isMakingPreset={isMakingPreset}
            makePreset={makePreset}
            tempKpis={tempKpis}
            setTempKpis={setTempKpis}
            isLoadingSpecificPreset={isLoadingSpecificPreset}
          />
          {open && type === MODALTYPES.SAVE_INSIGHTS_REPORT && (
            <SaveInsightsReportModal
              reportData={reportData}
              saveReport={saveReport}
              isCreatingReport={isCreatingReport}
            />
          )}
        </div>
      )}
    </PrivateContainer>
  );
};

export default CreativeInsight;
