import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { MODALTYPES, setModal } from "redux/slices/modal.slices";
import { ImageTaggingModal, VideoTaggingModal } from "components/modals";
import { storageUrlToCdn } from "utils/helper";

const ThumbnailRowRenderer = ({ row }) => {
  return (
    <div style={{ width: "200px", height: "200px" }}>
      <img
        src={storageUrlToCdn(row?.thumbnailUrl)}
        alt="thumbnail"
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "4px",
          objectFit: "cover",
        }}
      />
    </div>
  );
};

function createRowsAndColumns(insightsData, openTaggingModal) {
  const ignoreColumnsList = ["groupName"];
  const columns = insightsData?.[0]?.columns?.filter(
    (column) => !ignoreColumnsList.includes(column?.key)
  );

  const creativeColumnIndex = columns?.findIndex(
    (column) => column?.key === "thumbnailUrl"
  );

  if (creativeColumnIndex !== -1) {
    const creativeColumn = columns?.splice(creativeColumnIndex, 1);
    columns?.unshift(creativeColumn[0]);
  }
  const rows = insightsData?.map((row) => {
    const modalType = row?.columns?.find(
      (column) => column?.key === "thumbnailUrl"
    )?.columnValueType;

    const { _id: groupId, groupName } = row;

    const thumbnailUrl = row?.thumbnailUrl;

    const rowData = [];
    columns?.forEach((column) => {
      if (column?.key === "thumbnailUrl") {
        rowData.push(
          <ThumbnailRowRenderer row={row} value={row[column?.key]} />
        );
        return;
      } else if (column?.key === "totalNoOfAds") {
        rowData.push(
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#1C1E20",
              }}
            >
              {`Total ${row[column?.key]} ads`}
            </Typography>
            |
            <Button
              onClick={() =>
                openTaggingModal(modalType, groupId, thumbnailUrl, groupName)
              }
            >
              Edit Tags
            </Button>
          </div>
        );
      }
      rowData.push(row[column?.key]?.value);
    });
    return rowData;
  });
  return { rows, columns };
}
function TableView(props) {
  const dispatch = useDispatch();
  const { open, type } = useSelector((state) => state?.modal);

  function openTaggingModal(modalType, groupId, thumbnailUrl, groupName) {
    dispatch(
      setModal({
        open: true,
        type:
          modalType === "IMAGE"
            ? MODALTYPES.IMAGE_TAGGING_MODAL
            : MODALTYPES.VIDEO_TAGGING_MODAL,
        payload: {
          groupId,
          groupName,
          ...(modalType === "IMAGE"
            ? { imageUrl: thumbnailUrl }
            : { videoUrl: thumbnailUrl }),
        },
      })
    );
  }

  const { insightsData = {} } = props;
  const { rows, columns } = createRowsAndColumns(
    insightsData,
    openTaggingModal
  );

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", borderRadius: "0" }}
    >
      <Table
        sx={{ minWidth: 650, height: "max-content" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "#FAFAFC",
              borderBottom: "1px solid #EAECF0",
            }}
          >
            {columns?.map((column, index) => {
              return (
                <TableCell
                  sx={{ width: column?.width ?? "100" }}
                  key={`${column?.label}${index}`}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      color: "#1C1E20",
                    }}
                  >
                    {column?.label}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, rowIndex) => {
            return (
              <TableRow key={rowIndex}>
                {row?.map((value, index, arr) => {
                  return (
                    <TableCell sx={{ width: "100" }} key={`${value}${index}`}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "20px",
                          color: "#1C1E20",
                          borderRight: "1px solid #EAECF0",
                          ...(index !== arr.length - 1 && {
                            borderRight: "1px solid #EAECF0",
                          }),
                        }}
                      >
                        {value}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {open && type === MODALTYPES.IMAGE_TAGGING_MODAL && (
        <ImageTaggingModal postOnboarding={true} />
      )}
      {open && type === MODALTYPES.VIDEO_TAGGING_MODAL && (
        <VideoTaggingModal postOnboarding={true} />
      )}
    </TableContainer>
  );
}

export default TableView;
