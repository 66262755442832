export * from "./AdAccountScreen";
export { default } from "./AdAccountScreen";

export const platformsList = [
  {
    id: "facebook_ads",
    name: "Facebook Ads",
    type: "Marketing",
    description:
      "Facebook Ads provides a history of the settings of your Ad Account, Campaigns, Ad Sets, Ads, and Ad Creatives. Facebook Ads also fetches Ad Insights data.",
    iconUrl:
      "https://fivetran.com/integrations/facebook/resources/facebook.svg",
    isDisabled: false,
    comingSoon: { isComingSoon: false, percentageCompleted: 100 },
    isConnectedWithOrganization: false,
  },
  {
    id: "facebook_pages",
    name: "Facebook Pages",
    type: "Marketing",
    description:
      "Sync your Facebook pages and posts, as well as insights about them, with the Facebook Pages connector.",
    iconUrl:
      "https://fivetran.com/integrations/facebook/resources/facebook.svg",
    isDisabled: false,
    comingSoon: { isComingSoon: false, percentageCompleted: 100 },
    isConnectedWithOrganization: false,
  },
  {
    id: "dropbox",
    name: "Dropbox",
    type: "File",
    description:
      "Dropbox is a cloud storage provider. Fivetran supports syncing files from your Dropbox account to your destination. You can sync multiple files as unique tables to your destination.",
    iconUrl: "https://fivetran.com/integrations/dropbox/resources/dropbox.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "dynamodb",
    name: "DynamoDB",
    type: "Database",
    description:
      "Amazon DynamoDB is a fully-managed, proprietary NoSQL database service that is offered as part of Amazon Web Services (AWS).",
    iconUrl:
      "https://fivetran.com/integrations/dynamodb/resources/dynamodb.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "elastic_cloud",
    name: "Elastic Cloud",
    type: "Database",
    description: "Elastic Cloud is a NoSql Document Database",
    iconUrl:
      "https://fivetran.com/integrations/elasticsearch/resources/logos/elastic_cloud.png",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "eloqua",
    name: "Eloqua",
    type: "Marketing",
    description:
      "Oracle Eloqua equips marketers with best-in-class lead and campaign management tools that help marketers engage the right audience at the right time in the buyer's journey while providing real-time reporting and insights.",
    iconUrl: "https://fivetran.com/integrations/eloqua/resources/eloqua.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "email",
    name: "Email",
    type: "File",
    description:
      "Email CSV and JSON files to your destination with the Email Connector. The Email Connector extracts one or more attachments from the email, parses the attachment data and uploads them into your warehouse. Emails are deleted once attachments are extracted from it. This is designed for schedule email reports.",
    iconUrl: "https://fivetran.com/integrations/email/resources/email.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "ftp",
    name: "FTP",
    type: "File",
    description:
      "Fivetran supports syncing files from an FTP server to your destination.",
    iconUrl: "https://fivetran.com/integrations/ftp/resources/ftp.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "facebook_ad_account",
    name: "Facebook Ad Account",
    type: "Marketing",
    description:
      "Facebook Ad Account provides a history of the settings of your Facebook Ad Accounts, Campaigns, Ad Sets, Ads, and Ad Creatives.",
    iconUrl:
      "https://fivetran.com/integrations/facebook/resources/facebook.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: false, percentageCompleted: 100 },
    isConnectedWithOrganization: false,
  },
  {
    id: "facebook",
    name: "Facebook Ad Insights",
    type: "Marketing",
    description:
      "Facebook Ad Insights gives you information about the performance of your Facebook ads.",
    iconUrl:
      "https://fivetran.com/integrations/facebook/resources/facebook.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: false, percentageCompleted: 100 },
    isConnectedWithOrganization: false,
  },

  {
    id: "financial_force",
    name: "FinancialForce",
    type: "API",
    description:
      "FinancialForce is an ERP and service automation tool built on Salesforce.",
    iconUrl:
      "https://fivetran.com/integrations/salesforce/resources/financial_force.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "fivetran_log",
    name: "Fivetran Log",
    type: "Free",
    description:
      "Fivetran generates structured log events from connectors, dashboard user actions, and Fivetran API calls. The Fivetran Log Connector delivers your logs and account metadata to a schema in your destination. This metadata includes granular Fivetran consumption information.",
    iconUrl:
      "https://fivetran.com/integrations/fivetran_log/resources/fivetran.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "freightview",
    name: "Freightview",
    type: "Productivity",
    description: "Freightview is an online transportation management system.",
    iconUrl:
      "https://fivetran.com/integrations/coil_connectors/resources/freightview/resources/freightview.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "freshdesk",
    name: "Freshdesk",
    type: "Support",
    description:
      "Freshdesk is a SaaS customer support software and help desk ticketing system. The solution also includes features like Knowledge Base, Community Platform, Live Chat, Phone Support, etc.",
    iconUrl:
      "https://fivetran.com/integrations/freshdesk/resources/freshdesk.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "freshservice",
    name: "Freshservice",
    type: "Engineering",
    description: "Freshservice is a IT service management platform.",
    iconUrl:
      "https://fivetran.com/integrations/freshservice/resources/freshservice.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "freshservice_lite",
    name: "Freshservice_lite",
    type: "Engineering",
    description: "Freshservice is a IT service management platform.",
    iconUrl:
      "https://fivetran.com/integrations/coil_connectors/resources/freshservice_lite/resources/freshservice_lite.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "front",
    name: "Front",
    type: "Support",
    description:
      "Front simplifies how your team manages email lists and customer communications. It provides services to centralize all your communication in one place and optimize how your team handles every message with powerful automations. It also connects your inbox with all the apps you rely on.",
    iconUrl: "https://fivetran.com/integrations/front/resources/front.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "gainsight",
    name: "Gainsight",
    type: "Sales",
    description: "Gainsight is a revenue optimization platform.",
    iconUrl:
      "https://fivetran.com/integrations/coil_connectors/resources/gainsight/resources/gainsight.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "gainsight_customer_success",
    name: "Gainsight Customer Success",
    type: "Productivity",
    description: "Gainsight Customer Success is a customer success platform.",
    iconUrl:
      "https://fivetran.com/integrations/gainsight_customer_success/resources/gainsight_customer_success.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "github",
    name: "GitHub",
    type: "Engineering",
    description:
      "GitHub is a platform for developers to build personal projects, support their businesses, and work together on open source technologies. Fivetran helps you export data about commits, users, pull-requests, and other GitHub data.",
    iconUrl: "https://fivetran.com/integrations/github/resources/github.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "gocardless",
    name: "GoCardless",
    type: "Finance",
    description:
      "GoCardless is a payment platform that facilitates payment processing for recurring payments.",
    iconUrl:
      "https://fivetran.com/integrations/coil_connectors/resources/gocardless/resources/gocardless_logo.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "gongio",
    name: "Gong",
    type: "Productivity",
    description: "Gong is a revenue intelligence platform.",
    iconUrl:
      "https://fivetran.com/integrations/coil_connectors/resources/gongio/resources/gongio.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "double_click_publishers",
    name: "Google Ad Manager",
    type: "Marketing",
    description:
      "Google Ad Manager is a hosted ad serving platform to streamline ad management, whether you deliver ads to websites, mobile web pages, mobile apps, games, or a combination. It offers an integrated platform to manage all direct and programmatic sales, and to streamline all ad sales across your web and app inventory.",
    iconUrl:
      "https://fivetran.com/integrations/google_ad_manager/resources/google_ad_manager.png",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "google_ads",
    name: "Google Ads",
    type: "Marketing",
    description: "Google Ads is an online advertising platform",
    iconUrl:
      "https://fivetran.com/integrations/google_ads/resources/google-ads.png",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
  {
    id: "google_analytics",
    name: "Google Analytics",
    type: "Marketing",
    description:
      "Google Analytics lets you analyze data about customer engagement with your website or application.",
    iconUrl:
      "https://fivetran.com/integrations/google_analytics/resources/google_analytics.svg",
    isDisabled: true,
    comingSoon: { isComingSoon: true, percentageCompleted: 0 },
    isConnectedWithOrganization: false,
  },
];
