import { Typography } from "@mui/material";
import React from "react";
import { InputWithLabel } from "components/common/molecules";
import PublicContainer from "components/layouts/PublicContainer";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import useForgotPassword from "./useForgotPassword";
import { Button } from "@/components/ui/button";

const formStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "18px",
  width: "100%",
  marginTop: "35px",
};

export default function ForgetPasswordScreen() {
  const navigate = useNavigate();

  const { email, setEmail, handlePasswordRest } = useForgotPassword();

  const regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <PublicContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <img
          src={"/ForgotPassword.png"}
          alt="forgot password"
          className="w-[350px] h-[340px] -mt-24"
        />
        <Typography
          variant="h4"
          sx={{
            marginTop: "20px",
            color: "#7f7f7f",
            fontWeight: "300",
            textAlign: "center",
            lineHeight: 1.2,
          }}
        >
          Don’t worry !! we are here to help you, just put down your mail in box
          and <br /> we are sending you a reset password link.
        </Typography>

        <div className="login-screen__form" style={formStyles}>
          <InputWithLabel
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            label="Enter Your Registered Email Id"
            name="email"
            placeholder="Enter Your Email"
          />
        </div>

        <div
          style={{
            marginTop: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "15px",
            width: "100%",
            maxWidth: "488px",
          }}
        >
          <Button
            variant="secondary"
            onClick={() => navigate(APP_ROUTES.AUTH.LOGIN)}
          >
            Back To Login
          </Button>
          <Button onClick={handlePasswordRest} disabled={!regEmail.test(email)}>
            Send
          </Button>
        </div>
      </div>
    </PublicContainer>
  );
}
