import React from "react";
import PrivateContainer from "../../../components/layouts/PrivateContainer";
import AITagged from "./AITagged";
import { useLocation } from "react-router-dom";

export default function TextTaggingScreen() {
  const { pathname } = useLocation();
  return (
    <PrivateContainer
      activePage={pathname}
      bodyStyles={{ justifyContent: "flex-start" }}
    >
      <AITagged />
    </PrivateContainer>
  );
}
