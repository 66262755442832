import React, { useCallback, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { debounce } from "@mui/material/utils";
import useAxios from "../../../../hooks/useAxios";
import { API_ROUTES } from "../../../../routes";

export default function AutoCompleteWithTags(props) {
  const {
    type = "text",
    tags = [],
    label = "tags",
    placeholder = "",
    values = [],
    onTagUpdate,
    disabled,
    multiple = true,
  } = props;
  const { axiosOrgInstance } = useAxios();
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState(tags);

  const fetchOrganizaionTags = useCallback((query) => {
    axiosOrgInstance
      .get(API_ROUTES.TAGGING.GET_ORGANIZATION_TAGS, {
        params: {
          search: query,
        },
      })
      .then((res) => {
        let tags = res?.tags ?? [];
        setOptions(tags);
        return res?.tags;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchGlobalTags = React.useMemo(
    () => debounce((query) => fetchOrganizaionTags(query), 500),
    [fetchOrganizaionTags]
  );

  const customInputTagHandler = (event, value) => {
    let latestValue = value.at(-1);
    if (type === "number") latestValue = parseFloat(latestValue);
    if (typeof value.at(-1) === "string") {
      if (!multiple) {
        return onTagUpdate && onTagUpdate([value]);
      }
      onTagUpdate && onTagUpdate([...value.slice(0, -1), latestValue]);
    } else {
      onTagUpdate && onTagUpdate(value);
    }
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "Tab": {
        if (inputValue.length > 0) {
          event.preventDefault();
          customInputTagHandler(event, values.concat([inputValue]));
          //reset input value after adding tag
          setInputValue("");
        }
        break;
      }
      default:
    }
  };
  useEffect(() => {
    if (inputValue) {
      searchGlobalTags(inputValue);
    }
  }, [inputValue, searchGlobalTags]);

  return (
    <Autocomplete
      multiple={multiple}
      id="multiple-limit-tags"
      options={options}
      getOptionLabel={(option) => {
        return <span title={option}>{option}</span>;
      }}
      freeSolo
      disabled={disabled}
      value={values}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      includeInputInList
      filterSelectedOptions
      renderInput={(params) => {
        params.inputProps.onKeyDown = handleKeyDown;

        return (
          <TextField
            {...params}
            type={type}
            label={label}
            placeholder={!values?.length ? placeholder : ""}
            inputProps={{
              ...params.inputProps,
              value: inputValue,
            }}
          />
        );
      }}
      onChange={(event, value) => {
        customInputTagHandler(event, value);
      }}
      sx={{ minWidth: 350, width: "100%" }}
    />
  );
}
