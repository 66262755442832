import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Dialog,
  useMediaQuery,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";
import { APP_ROUTES } from "routes";

export default function SaveReportModal() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleClose() {
    dispatch(setModal(modalInitState));
  }
  return (
    <Dialog
      fullScreen={fullScreen}
      open={
        modal?.open && modal?.type === MODALTYPES.GENERATION_CREATIVE_TYPE_MODAL
      }
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Stack direction="column" sx={{ padding: "32px 48px" }} spacing={3}>
        <FormControl
          sx={{ display: "flex", flexDirection: "column", gap: "24px" }}
        >
          <FormLabel sx={{ fontSize: "20px", color: "#1C1E20" }}>
            What type of creative did you want?
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="AI generated"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Stock Images"
            />
          </RadioGroup>
        </FormControl>
        <Stack direction="row">
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              navigate(APP_ROUTES.GENERATION.GENERATION_STEP_3);
              handleClose();
            }}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
