import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { CutEye, OpenEye } from "../../../../assets/icons/react";

const formFieldStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "33%",
  minWidth: "488px",
};

const EndAdornment = (props) => {
  const { showPassword, setShowPassword } = props;
  return (
    <InputAdornment
      sx={{ cursor: "pointer" }}
      position="end"
      onClick={() => setShowPassword(!showPassword)}
    >
      {showPassword ? <CutEye /> : <OpenEye />}
    </InputAdornment>
  );
};
export default function InputWithLabel(props) {
  const {
    label = "label",
    type = "text",
    styles,
    disabled = false,
    ...rest
  } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  let inputType = type === "password" && showPassword ? "text" : type;
  return (
    <div className="form_field" style={{ ...formFieldStyles, ...styles }}>
      <label
        htmlFor="email"
        style={{ fontSize: "16px", fontWeight: "400px", color: "#1C1E20" }}
      >
        {label}
      </label>
      <TextField
        type={inputType}
        fullWidth
        id="fullWidth"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: disabled ? "#F2F2F2" : "#FFFFFF",
            input: {
              height: "16px",
            },
          },
        }}
        InputProps={{
          endAdornment: type === "password" && (
            <EndAdornment
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
          ),
        }}
        disabled={disabled}
        {...rest}
      />
    </div>
  );
}
