import React from "react";
import { Stack } from "@mui/material";

const GenerationPill = ({
  label = "label",
  pillColor = "#7167FB",
  pillBg = "#7167FB10",
  sx,
}) => {
  return (
    <span
      style={{
        display: "inline-block",
        backgroundColor: pillBg,
        color: pillColor,
        borderRadius: "8px",
        padding: "8px 12px",
        ...sx,
      }}
    >
      {label}
    </span>
  );
};

export default GenerationPill;
