import { Typography } from "@mui/material";
import ModelKPIList from "./ModelKPIList";
import { Stack } from "@mui/system";
import React from "react";
import useUserRole from "hooks/useUserRole";
import { ACTIVE_FEATURES, A_PERMISSIONS } from "../../../../../../config/roles";
import { ContentContainer } from "components/layouts";
import useOrganisationDetail from "../ManageOrganisation/Components/OrganisationDetail/useOrganisationDetail";

import {
  Shapes,
  Video,
  Brain,
  BringToFront,
  Sparkles,
  AudioLines,
  ScatterChart,
  Columns2,
  Lock,
  Copy,
  MessagesSquare,
  Tags,
} from "lucide-react";
import { twJoin } from "tailwind-merge";
import { useSelector } from "react-redux";
import { useToast } from "@/components/ui/use-toast";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

export default function ManageFeatures(props) {
  const { hasUserActiveFeature, hasUserPermission } = useUserRole();
  const { selectedOrganisation: reduxSelectedOrg } = useSelector(
    (state) => state?.organisation
  );
  const { isModelKPIsLoading, modelKPIs } =
    useOrganisationDetail(reduxSelectedOrg);

  const reports_options = [
    {
      text: "Insights",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.INSIGHTS),
      subText:
        "See which creatives are performing best and enhance your cretive building strategies",
      icon: <Shapes color="#dd82ee" />,
      aiIcon: false,
    },
    {
      text: "Compare Creatives",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.COMPARE),
      subText:
        "See which creatives are performing best and enhance your creative building strategies",
      icon: <Columns2 color="#A8D26D" />,
      aiIcon: false,
      comingSoon: true,
      beta: true,
    },
    {
      text: "Creative momentum fatigue",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.CREATIVE_MOMENTUM_FATIGUE),
      subText:
        "Get Creative momentum fatigue before posting your creatives online",
      icon: <ScatterChart color="#ffa9a9" />,
      aiIcon: false,
      comingSoon: true,
      beta: true,
    },
    {
      text: "Conversational Insights",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.CONVERSATIONAL_INSIGHTS),
      subText:
        "Get AI powered conversational insights before posting your creatives online",
      icon: <AudioLines color="#55d0ff" />,
      aiIcon: true,
      comingSoon: true,
      beta: true,
    },
    {
      text: "Recommendation: Creative Feature",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.RECOMMENDATION),
      subText:
        "Get AI powered recommendations on your creatives and boost your performance over next creatives",
      icon: <Brain color="#886E58" />,
      aiIcon: true,
    },
    {
      text: "Recommendation: Video Intelligence",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.RECOMMENDATION),
      subText: "Get AI powered recommendations on your video creatives",
      icon: <Video color="#cbb2ff" />,
      aiIcon: true,
    },
    {
      text: "Predictions",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.PREDICTION),
      subText:
        "Get AI powered predictions before posting your creatives online",
      icon: <BringToFront color="#ff9912" />,
      aiIcon: true,
    },
    {
      text: "Chat",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.CHAT),
      subText: "Get Chat feature on reports",
      icon: <MessagesSquare color="#7AA2E3" />,
      aiIcon: false,
    },
    {
      text: "Advance Tagging ",
      isActive: hasUserActiveFeature(ACTIVE_FEATURES.ADVANCE_TAGGING),
      subText: "Get Advance Tagging on reports",
      icon: <Tags color="#FF6969" />,
      aiIcon: false,
    },
  ];

  const { toast } = useToast();

  return (
    <ContentContainer
      containerStyles={{
        maxHeight: `calc(100vh - 70px)`,
        padding: "0 20px",
      }}
    >
      <Stack direction="column" spacing={4} sx={{ width: "100%" }}>
        <div
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 500,
            }}
          >
            Manage Features
          </Typography>
        </div>

        <div class="grid grid-cols-2 p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div>
            <svg
              class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3 inline-block"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
            </svg>
            <a href="#">
              <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                Request Access
              </h5>
            </a>
            <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">
              Please contact from Admin to activate features.
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <div className="grid flex-1 gap-2">
              <Input
                id="link"
                defaultValue="varun@thestoryteller.tech"
                readOnly
              />
            </div>
            <Button
              type="submit"
              variant="outline"
              size="sm"
              className="px-3"
              onClick={() => {
                navigator.clipboard.writeText("varun@thestoryteller.tech");
                toast({
                  description: "Email Address Copied To Clipboard.",
                });
              }}
            >
              <span className="sr-only">Copy</span>
              <Copy className="h-4 w-4" />
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {reports_options.map((b, index) => {
            return (
              <div key={index}>
                <div
                  className={twJoin(
                    "relative gap-x-2 text-gray-900 w-[100%] h-[90%] bg-gray-100 hover:bg-gray-200 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-3.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2 disabled:cursor-not-allowed"
                  )}
                >
                  <div className="m-2">{b.icon}</div>
                  <div className="flex flex-col">
                    <p className="text-left">
                      {b.text}{" "}
                      {b.comingSoon && (
                        <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                          Coming Soon
                        </span>
                      )}
                      {b.beta && (
                        <span class="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-purple-900 dark:text-purple-300">
                          Beta
                        </span>
                      )}
                    </p>
                    <p className="text-left text-sm text-gray-500">
                      {b.subText}
                    </p>
                  </div>
                  {!b.isActive && (
                    <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-red-500 bg-red-100 border-2 border-white rounded-full -top-2 -start-2 dark:border-gray-900">
                      <Lock color="red" size={12} />
                    </div>
                  )}
                  {b.aiIcon && (
                    <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-yellow-500 bg-yellow-50 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
                      <Sparkles color="#b48811" size={14} />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {hasUserPermission(A_PERMISSIONS.VIEW_MODEL_KPIS) ? (
          <div className="-mt-5 w-full">
            <Typography
              variant="h3"
              sx={{ fontWeight: "500", marginBottom: "20px" }}
            >
              Model Organisation KPIs
            </Typography>
            <ModelKPIList
              isModelKPIsLoading={isModelKPIsLoading}
              modelKPIs={modelKPIs}
            />
          </div>
        ) : null}
      </Stack>
    </ContentContainer>
  );
}
