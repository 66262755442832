import useAppState from "hooks/useAppState";
import useAxios from "hooks/useAxios";
import useUser from "hooks/useUser";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserData } from "redux/slices/auth.slice";
import { API_ROUTES, APP_ROUTES } from "routes";

export default function useAccountType() {
  const RolesOptions = [
    { id: "1", value: "Brand Owner", label: "Brand Owner" },
    {
      id: "2",
      value: "Agency",
      label: "Agency Profile",
    },
  ];
  const [selectedRole, setSelectedRole] = useState(RolesOptions[0]?.value);
  const { axiosUserInstance } = useAxios();
  const { updateAppState } = useAppState();
  const navigate = useNavigate();
  const { userId } = useUser();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const handleSubmit = () => {
    axiosUserInstance
      .patch(API_ROUTES.USER.UPDATE_USER?.replace(":userId", userId), {
        accountType: selectedRole,
      })
      .then((res) => {
        dispatch(setUserData({ tokens: auth?.userData?.tokens, user: res }));
        updateAppState();
        navigate(APP_ROUTES.AUTH.SET_USER_NAME);
      });
  };

  return { selectedRole, RolesOptions, setSelectedRole, handleSubmit };
}
