import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import React, { useEffect } from "react";

export default function MultipleValuesFilter(props) {
  const {
    selectStyles = {},
    optionStyles = {},
    options = [],
    label = "label",
    onChange,
    selectedOption = {},
    id,
    multiple = true,
  } = props;

  return (
    <FormControl
      sx={{ minWidth: "10%", display: options?.length > 1 ? "flex" : "none" }}
    >
      <InputLabel
        sx={{
          color: "#A1B0CC",
          flexDirection: "row",
          gap: "4px",
          backgroundColor: "#FFF",
        }}
        id={id}
      >
        {label}
      </InputLabel>
      <Select
        labelId={id}
        sx={{
          height: "36px",
          minWidth: "10%",
          border: 0,
          borderRadius: "8px",
          backgroundColor: "#FFF",

          //fieldset is the border of the select
          "& fieldset": {
            border: 1,
            borderColor: "#A1B0CC",
          },
          ...selectStyles,
        }}
        autoWidth
        value={selectedOption}
        multiple={multiple}
        renderValue={(value) => (
          <Stack
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#1C1E20",
              flexDirection: "row",
              gap: "4px",
            }}
          >
            <div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
              {value?.length === 0 && (
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#A1B0CC",
                    pointerEvents: "none",
                  }}
                >
                  {label}
                </span>
              )}
              {value?.slice(0, 1)?.map((item) => (
                <span
                  style={{
                    fontSize: "auto",
                    fontWeight: "400",
                    color: "#5E48E8",
                    display: "block",
                    textOverflow: "ellipsis",
                  }}
                  key={item?.label}
                >
                  {item?.label}
                  {/* {item?.label?.slice(0, 10)}
                {item?.label?.length > 10 && "..."} */}
                </span>
              ))}
              {value?.length > 1 && (
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#5E48E8",
                    backgroundColor: "#F0F0F0",
                    borderRadius: "4px",
                    padding: "0 4px",
                  }}
                >
                  {`+${value?.length - 1}`}
                </span>
              )}
            </div>
          </Stack>
        )}
        displayEmpty
        title={label}
      >
        {options?.map((option, index) => {
          return (
            <MenuItem
              key={index}
              value={option}
              sx={{ ...optionStyles }}
              onClick={() => {
                onChange(option);
              }}
            >
              {option?.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
