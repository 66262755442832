import {
  useGetPredictionFilters,
  useUpdateSpecificPrediction,
} from "apis/predictionApi";
import useAxios from "hooks/useAxios";
import moment from "moment";
import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addExtraFilter,
  initExtraFilters,
  removeExtraFilterField,
  updateExtraFilterAggregateLogic,
  updateExtraFilterFieldSelect,
} from "redux/slices/prediction.slice";
import { API_ROUTES } from "routes";

const todaysDate = moment().format("YYYY-MM-DD");
const initialPredictionState = {
  filters: {},
  metrics: [],
  selectedMetrics: [],
  sortOrder: "asc",
  date: { selectionType: 4, startDate: todaysDate, endDate: todaysDate },
  sortBy: "",
  thresholdValues: { thresholdType: "4", thresholdValue: "0" },
  isReportPublic: false,
};

function getInsightsPayloadFromInsightState(
  filters,
  modelKpi,
  sortOrder,
  date,
  platforms,
  page = 1,
  sortBy = "",
  thresholdValues
) {
  const payload = {};
  Object.entries(filters).forEach(([key, value]) => {
    if (key !== "sortOrder" || key !== "metrics") {
      payload[key] = value?.options
        ?.filter((item) => item.default)
        ?.map((item) => item.key);
    }
  });
  payload.sortOrder = sortOrder;
  payload.sortBy = sortBy;
  payload.kpis = modelKpi;
  payload.date = date;
  payload.platformIds = platforms?.map((item) => item?.platformId);
  payload.thresholdValues = thresholdValues;
  return payload;
}

const filtersStateReducer = (state, action) => {
  switch (action.type) {
    case "SET_ALL":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case "SET_METRICS":
      return {
        ...state,
        metrics: action.payload,
        sortBy: action.payload?.[0]?.kpi,
      };

    case "SET_SELECTED_METRICS":
      return {
        ...state,
        selectedMetrics: action.payload,
      };

    case "SET_SORT_ORDER":
      return {
        ...state,
        sortOrder: action.payload,
      };

    case "SET_THRESHOLD_VALUES":
      return {
        ...state,
        thresholdValues: action.payload,
      };

    case "SET_SORT_BY":
      return {
        ...state,
        sortBy: action.payload,
      };

    case "SET_DATE":
      return {
        ...state,
        date: {
          ...action.payload,
          selectionType: parseInt(action?.payload?.selectionType),
        },
      };
    case "SET_IS_REPORT_PUBLIC":
      return {
        ...state,
        isReportPublic: !state.isReportPublic,
      };
    default:
      return state;
  }
};

export default function usePredictionStep3() {
  const { reportableId = "" } = useParams();
  const { data: insightFilters } = useGetPredictionFilters();
  const { mutateAsync: updatePredictionREportFilters } =
    useUpdateSpecificPrediction();
  const platforms = useSelector(
    (state) => state?.organisation?.selectedOrganisation?.platforms
  );
  const [predictionState, dispatchPredictionState] = useReducer(
    (state, action) => filtersStateReducer(state, action),
    initialPredictionState
  );

  function onDateDaysChange(config) {
    dispatchPredictionState({
      type: "SET_DATE",
      payload: config,
    });
  }

  function onThresholdValueChange(config) {
    dispatchPredictionState({
      type: "SET_THRESHOLD_VALUES",
      payload: config,
    });
  }

  function willFilterEmpty(options) {
    const _options = options?.filter((option) => option?.default);
    if (_options?.length === 1) {
      return true;
    }
    return false;
  }

  function onFilterUpdate(key, selectedOption) {
    let _filter = { ...predictionState?.filters };

    Object.entries(predictionState?.filters).forEach(([filterKey, value]) => {
      if (key === filterKey) {
        _filter[key] = {
          ...value,
          options: value?.options?.map((item) => {
            if (item.key === selectedOption.key) {
              if (item?.default && willFilterEmpty(value?.options)) {
                toast("Minimum one filter is required");
                return { ...item, default: true };
              } else {
                return { ...item, default: !item.default };
              }
            }
            return item;
          }),
        };
      }
    });

    dispatchPredictionState({
      type: "SET_FILTERS",
      payload: _filter,
    });
  }

  function onRemoveSelectedMetrics(index) {
    const _selectedMetrics = predictionState?.selectedMetrics?.filter(
      (metric, idx) => idx !== index
    );
    if (_selectedMetrics?.length > 0) {
      dispatchPredictionState({
        type: "SET_SELECTED_METRICS",
        payload: _selectedMetrics,
      });
    } else {
      toast("Minimum one KPI selection is mandatory");
    }
  }
  function onSelectedMetricsUpdate(selectedMetrics) {
    dispatchPredictionState({
      type: "SET_SELECTED_METRICS",
      payload: selectedMetrics,
    });
  }

  const dispatch = useDispatch();
  function handleExtraFilterSelectUpdate(index, value, key) {
    setSmartSuggestions([]);
    dispatch(updateExtraFilterFieldSelect({ index, value, key }));
  }

  function handleUpdateSearchExtraFilter(index, value, key) {
    dispatch(updateExtraFilterFieldSelect({ index, value, key }));
  }

  function handleAggregateLogicChangeExtraFilters(logic) {
    dispatch(updateExtraFilterAggregateLogic({ logic }));
  }

  function handleAddNewFilter() {
    dispatch(addExtraFilter());
  }

  function handleRemoveNewFilter(index) {
    dispatch(removeExtraFilterField({ index }));
  }

  const { axiosOrgInstance } = useAxios();
  const [isSmartSuggestionLoading, setIsSmartSuggestionLoading] =
    useState(false);
  const [smartSuggestion, setSmartSuggestions] = useState([]);
  const handleFetchSmartSuggestions = (q, key) => {
    setIsSmartSuggestionLoading(true);
    axiosOrgInstance
      .get(`${API_ROUTES.ORGANISATION.SMART_FILTERS}?q=${q}&type=${key}`)
      .then((res) => {
        let _temp = [];
        setIsSmartSuggestionLoading(false);
        res?.map((curr) => {
          _temp.push(curr[key]);
        });
        setSmartSuggestions(_temp || ["Nothing Found...."]);
      });
  };
  function handleSearchMotionFilters(type, q) {
    const key_enum = {
      1: "adName",
      2: "adSetName",
      3: "campaignName",
      4: "groupName",
    };
    switch (type) {
      case 1:
        setSmartSuggestions([]);
        handleFetchSmartSuggestions(q, key_enum[type]);
        break;
      case 2:
        setSmartSuggestions([]);
        handleFetchSmartSuggestions(q, key_enum[type]);
        break;
      case 3:
        setSmartSuggestions([]);
        handleFetchSmartSuggestions(q, key_enum[type]);
        break;
      case 4:
        setSmartSuggestions([]);
        handleFetchSmartSuggestions(q, key_enum[type]);
        break;
      default:
        break;
    }
  }

  const predictionsReduxState = useSelector((state) => state?.prediction);

  function updatePredictionFilters() {
    let payload = {
      data: getInsightsPayloadFromInsightState(
        predictionState?.filters,
        predictionState?.selectedMetrics?.map((item) => item.value),
        predictionState?.sortOrder,
        predictionState?.date,
        platforms,
        1,
        predictionState?.sortBy,
        predictionState?.thresholdValues
      ),
      action: "updateFilters",
    };
    payload.data["isPublic"] = predictionState?.isReportPublic;
    // payload.data["extraFilters"] = predictionsReduxState?.extraFilters;
    updatePredictionREportFilters({
      reportableId,
      payload,
    });
  }

  useEffect(() => {
    if (insightFilters) {
      //set The first option as default if the option exist
      const filters = {};
      const modelKpi = [];

      Object.entries(insightFilters).forEach(([key, value]) => {
        if (value?.options?.length > 0) {
          filters[key] = {
            ...value,
            options: value?.options?.map((item, index) => {
              if (index === 0) {
                return { ...item, default: true };
              }
              return item;
            }),
          };
        } else if (key === "modelKpi") {
          modelKpi.push(value?.[0]?.kpi);
          const selectedMetrics = [
            {
              label: value?.[0]?.label,
              value: value?.[0]?.kpi,
            },
          ];

          dispatchPredictionState({
            type: "SET_METRICS",
            payload: value,
          });
          dispatchPredictionState({
            type: "SET_SELECTED_METRICS",
            payload: selectedMetrics,
          });
        }
      });

      dispatchPredictionState({
        type: "SET_FILTERS",
        payload: filters,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightFilters]);

  function handleIsReportPublicChange() {
    dispatchPredictionState({
      type: "SET_IS_REPORT_PUBLIC",
    });
  }
  return {
    predictionState,
    onThresholdValueChange,
    onDateDaysChange,
    onFilterUpdate,
    onSelectedMetricsUpdate,
    updatePredictionFilters,
    handleIsReportPublicChange,
    handleSearchMotionFilters,
    handleUpdateSearchExtraFilter,
    handleExtraFilterSelectUpdate,
    handleAddNewFilter,
    handleRemoveNewFilter,
    handleAggregateLogicChangeExtraFilters,
    smartSuggestion,
    isSmartSuggestionLoading,
    onRemoveSelectedMetrics,
  };
}
