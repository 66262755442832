/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  Dialog,
  DialogTitle,
} from "@/components/ui/dialog";
import { Folder } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import {
  MODALTYPES,
  modalInitState,
  setModal,
} from "redux/slices/modal.slices";
import { useGetOrgFolders } from "apis/common";
import { useGetSpecificFolder } from "apis/folderApi";
import { toast } from "react-toastify";
import { ArrowBack } from "@mui/icons-material";
import Spinner from "components/common/loaders/Spinner";

const MoveReportToFolderModal = (props) => {
  // states
  const [folders, setFolders] = useState([]);
  const [navigationStack, setNavigationStack] = useState([]);
  const { primaryAction } = props;
  const { modal } = useSelector((state) => state);
  const {
    reportId = null,
    actionType = "moveReport",
    action,
    title = "",
    description = "",
    folderToMove = "",
  } = modal?.payload || {};
  const dispatch = useDispatch();

  const handleSubmit = (folderId) => {
    if (actionType === "moveReport") {
      if (reportId) {
        primaryAction(folderId, reportId);
      } else primaryAction(folderId);
    } else if (actionType === "moveFolder") {
      action({
        id: folderToMove,
        newParentId: folderId,
        action: actionType,
      });
    }
  };

  // react query
  const {
    data: specificFolder,
    mutateAsync: fetchSpecificFolder,
    isLoading: specificFolderLoading,
  } = useGetSpecificFolder();
  const { isLoading: rootFoldersLoading, data: rootFolders } =
    useGetOrgFolders(false);

  // methods
  function handleClose() {
    dispatch(setModal(modalInitState));
  }

  function handleBack() {
    const newStack = [...navigationStack];
    newStack.pop();
    const previousFolder = newStack[newStack.length - 1];
    setNavigationStack(newStack);
    if (previousFolder) {
      fetchSpecificFolder(previousFolder.id);
    } else {
      setFolders(rootFolders);
    }
  }

  async function handleFolderClick(folderId, folderName) {
    const response = await fetchSpecificFolder(folderId);
    if (response?.folders?.length) {
      setNavigationStack([
        ...navigationStack,
        { id: folderId, name: folderName },
      ]);
    } else {
      toast("This folder has no subfolders.");
    }
  }

  // useEffects
  useEffect(() => {
    if (rootFolders?.length) {
      setFolders(rootFolders);
    }
  }, [rootFolders]);

  useEffect(() => {
    if (specificFolder?.folders?.length) {
      setFolders(specificFolder.folders);
    }
  }, [specificFolder]);

  const backFolderName =
    navigationStack[navigationStack.length - 1]?.name || "Root";

  return (
    <Dialog
      sx={{ padding: "40px" }}
      className="z-[9990]"
      open={modal?.open && modal?.type === MODALTYPES.MOVE_REPORT_TO_FOLDER}
      onOpenChange={handleClose}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{title || "Move Report"}</DialogTitle>
          <DialogDescription>
            {description ||
              "Move Report To A New Workspace To Better Organize Your Reports And Enhance Reports Visibility"}
          </DialogDescription>
        </DialogHeader>
        <div className="overflow-y-auto scroll-smooth max-h-[40vh]">
          <div
            className="flex items-center gap-2 justify-start"
            onClick={handleBack}
          >
            {navigationStack.length !== 0 && (
              <ArrowBack className="disabled:hover:cursor-not-allowed hover:cursor-pointer w-4 h-4" />
            )}
            <p className="text-sm text-gray-500 hover:cursor-pointer">
              {backFolderName}
            </p>
            {(rootFoldersLoading || specificFolderLoading) && (
              <Spinner spinnerClass="w-4 h-4 border-gray-600" />
            )}
          </div>
          {folders
            ?.filter((folder) => folder?._id !== folderToMove || "")
            .map((folder) => (
              <div
                key={folder._id}
                className="flex justify-between group px-2 text-xs hover:bg-gray-100 rounded-lg gap-4 my-2 py-1 w-full"
              >
                <div className="flex gap-2 items-center justify-center">
                  <Folder className="w-4 h-4" />
                  <div
                    className="cursor-pointer"
                    onClick={() => handleFolderClick(folder._id, folder.name)}
                  >
                    <p>{folder.name}</p>
                  </div>
                </div>
                <button
                  onClick={() => handleSubmit(folder._id)}
                  className="invisible text-center flex px-1 py-[2px] items-center justify-center group-hover:visible mr-2 border-[1px] rounded-md border-black"
                >
                  <p>Move</p>
                </button>
              </div>
            ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MoveReportToFolderModal;
