import {
  CometChatUIKit,
  UIKitSettingsBuilder,
} from "@cometchat/chat-uikit-react";
import { CometChat } from "@cometchat-pro/chat";

async function initializeApp() {
  try {
    const UIKitSettings = new UIKitSettingsBuilder()
      .setAppId(process.env.REACT_APP_COMETCHAT_APP_ID)
      .setRegion(process.env.REACT_APP_COMETCHAT_REGION)
      .setAuthKey(process.env.REACT_APP_COMETCHAT_AUTH_KEY)
      .subscribePresenceForFriends()
      .build();

    await CometChatUIKit.init(UIKitSettings);

    let appSetting = new CometChat.AppSettingsBuilder().setRegion("IN").build();
    await CometChat.init(process.env.REACT_APP_COMETCHAT_APP_ID, appSetting);
  } catch (error) {}
}

initializeApp();
