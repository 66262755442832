import { createSlice } from "@reduxjs/toolkit";

const initialPredictionState = {
  extraFiltersAggregator: "AND",
  extraFilters: [
    {
      type: 1,
      condition: 1,
      tags: [],
      aggregator: "AND",
    },
  ],
};

const predictionSlice = createSlice({
  name: "predictions",
  initialState: initialPredictionState,
  reducers: {
    initExtraFilters: (state, action) => {
      state.extraFilters = action.payload;
    },
    addExtraFilter: (state, action) => {
      return {
        ...state,
        extraFilters: [
          ...state?.extraFilters,
          {
            type: 1,
            condition: 1,
            tags: [],
            aggregator: state?.extraFiltersAggregator,
          },
        ],
      };
    },
    removeExtraFilterField: (state, action) => {
      return {
        ...state,
        extraFilters: [
          ...state?.extraFilters?.filter(
            (elem, idx) => idx !== action.payload.index
          ),
        ],
      };
    },
    updateExtraFilterFieldSelect: (state, action) => {
      const { index, key, value } = action.payload;
      const updatedExtraFilters = [...state.extraFilters];
      updatedExtraFilters[index] = {
        ...updatedExtraFilters[index],
        [key]: value,
      };
      return {
        ...state,
        extraFilters: updatedExtraFilters,
      };
    },
    updateExtraFilterAggregateLogic: (state, action) => {
      state.extraFiltersAggregator = action.payload.logic;
      state.extraFilters = [
        {
          type: 1,
          condition: 1,
          tags: [],
          aggregator: action.payload.logic,
        },
      ];
    },
  },
});

export const {
  addExtraFilter,
  updateExtraFilterFieldSelect,
  removeExtraFilterField,
  updateExtraFilterAggregateLogic,
  initExtraFilters,
} = predictionSlice.actions;

export default predictionSlice.reducer;
