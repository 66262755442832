import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import useAxios from "hooks/useAxios";
import { PREDICTION } from "./apiEndPoints";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { CACHE_TIME } from "utils/constants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const fiveMinutes = 1000 * 60 * 5;

const useGetPredictionFilters = (isPostOnboarding) => {
  const { axiosOrgInstance } = useAxios();
  const organisationID = useSelector(
    (state) => state?.organisation?.selectedOrganisation?._id
  );
  const queryKey = ["predictionFilters"];

  if (organisationID) {
    queryKey.push(organisationID);
  }

  const data = useQuery(
    queryKey,
    () =>
      axiosOrgInstance
        .get(
          `/commons/filters?include="adAccountIds,targetingLocations,optimizationGoals,objectives,funnelStages,modelKpi,platformIds"`
        )
        .then((res) => {
          const payload = {
            objectives: res.objectives,
            optimizationGoals: res.optimizationGoals,
            funnelStages: res.funnelStages,
            targetingCountries: res.targetingLocations,
            adAccountIds: res.adAccountIds,
            ...res,
          };
          delete payload.targetingLocations;
          return payload;
        }),
    {
      enabled: isPostOnboarding,
      staleTime: CACHE_TIME,
      cacheTime: CACHE_TIME,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );
  return data;
};

const useGetPredictions = () => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["predictions"];
  return useMutation(
    queryKey,
    (page = 1) =>
      axiosOrgInstance.get(PREDICTION.GET_PREDICTION_REPORTS, {
        params: {
          page,
          perPage: 30,
          include: "report",
          sort: "createdAt:desc",
        },
      }),
    {
      onSuccess: () => {},
      queryKey: queryKey,
    }
  );
};

const useCreatePrediction = () => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["createPrediction"];
  const queryClient = useQueryClient();
  return useMutation(
    queryKey,
    (payload) => axiosOrgInstance.post(PREDICTION.CREATE_PREDICTION, payload),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["predictions"], { exact: true });
        await queryClient.refetchQueries(["predictions"], {
          active: true,
          exact: true,
        });
        toast.success(`Prediction Created Successfully`);
      },
    }
  );
};

const useUpdateSpecificPrediction = (navigateUrl = null) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["updateSpecificPrediction"];
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  return useMutation(
    queryKey,
    ({ reportableId, payload }) => {
      return axiosOrgInstance
        .put(
          PREDICTION.UPDATE_SPECIFIC_PREDICTION?.replace(
            ":reportableId",
            reportableId
          ),
          payload
        )
        .then((response) => response.data);
    },
    {
      onSuccess: async (data, { reportableId }) => {
        toast.success(`Prediction updated Successfully`);
        queryClient.invalidateQueries(["predictionReport", reportableId]);
        queryClient.refetchQueries(["predictionReport", reportableId]);

        if (!navigateUrl) {
          await queryClient.invalidateQueries(["predictions"], { exact: true });
          await queryClient.refetchQueries(["predictions"], {
            active: true,
            exact: true,
          });
        }

        setTimeout(() => {
          navigate(APP_ROUTES.PREDICTION.default);
        }, 1500);
      },
    }
  );
};

const useGetSpecificPrediction = (reportableId, currentReportOrgId) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["predictionReport", reportableId];
  return useQuery(
    queryKey,
    () =>
      axiosOrgInstance.get(
        PREDICTION.GET_SPECIFIC_PREDICTION?.replace(
          ":reportableId",
          reportableId
        ),
        {
          params: {
            include: "report",
            orgId: currentReportOrgId,
          },
        }
      ),
    {
      enabled: !!reportableId,
      staleTime: fiveMinutes,
    }
  );
};

const useGetMediaForPrediction = (reportableId) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["predictionMedia", reportableId];
  return useQuery(
    queryKey,
    () =>
      axiosOrgInstance.get(
        PREDICTION.GET_MEDIA_FOR_PREDICTION?.replace(
          ":reportableId",
          reportableId
        )
      ),
    {
      enabled: !!reportableId,
      staleTime: fiveMinutes,
    }
  );
};

const useGetPaginatedMediaForPrediction = (
  reportableId,
  currentReportOrgId
) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["predictionMedia", reportableId];
  return useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) =>
      axiosOrgInstance.get(
        PREDICTION.GET_MEDIA_FOR_PREDICTION?.replace(
          ":reportableId",
          reportableId
        ),
        {
          params: {
            page: pageParam,
            perPage: 30,
            orgId: currentReportOrgId,
          },
        }
      ),
    {
      enabled: !!reportableId,
      getNextPageParam: (lastPage) => {
        const { pagination = {} } = lastPage?.meta;
        const { currentPage = 1, totalPages = 1 } = pagination;

        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      staleTime: fiveMinutes,
    }
  );
};
const useGetPredictionMediaTags = ({ reportableId, mediaId }) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["predictionMediaTags", reportableId, mediaId];

  return useQuery(
    queryKey,
    () => {
      return axiosOrgInstance.get(
        PREDICTION.GET_PREDICTION_MEDIA_TAGS?.replace(
          ":reportableId",
          reportableId
        ).replace(":mediaId", mediaId),
        {
          params: {
            include: "tags",
          },
        }
      );
    },
    {
      enabled: !!reportableId && !!mediaId,
      // staleTime: fiveMinutes,
    }
  );
};

const useUpdatePredictionMediaTags = ({ reportableId, mediaId }) => {
  const { axiosOrgInstance } = useAxios();
  const queryKey = ["updatePredictionMediaTags", reportableId, mediaId];
  const queryClient = useQueryClient();
  return useMutation(
    queryKey,
    (payload) =>
      axiosOrgInstance.put(
        PREDICTION.UPDATE_PREDICTION_MEDIA_TAGS?.replace(
          ":reportableId",
          reportableId
        ).replace(":mediaId", mediaId),
        payload
      ),
    {
      onSuccess: () => {
        toast.success(`Prediction Media Tags Updated Successfully`);
        queryClient.invalidateQueries([
          "predictionMediaTags",
          reportableId,
          mediaId,
        ]);
      },
    }
  );
};

export {
  useGetPredictionFilters,
  useGetPredictions,
  useCreatePrediction,
  useUpdateSpecificPrediction,
  useGetSpecificPrediction,
  useGetMediaForPrediction,
  useGetPredictionMediaTags,
  useUpdatePredictionMediaTags,
  useGetPaginatedMediaForPrediction,
};
