import { Avatar, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Button as ButtonShadcn } from "@/components/ui/button";
import styles from "./styles.module.scss";
import SyncIcon from "@mui/icons-material/Sync";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ImageTaggingModal } from "components/modals";
import { MODALTYPES } from "redux/slices/modal.slices";
import { APP_ROUTES } from "routes";
import { ContentContainer, PrivateContainer } from "components/layouts";
import useImageTagging from "./useImageTagging";
import { BackButton } from "components/common/atoms";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
import MergeMultipleGroupsModal from "components/modals/MergeMultipleGroupsModal";
import { storageUrlToCdn } from "utils/helper";
import { OrganizationTagSelector } from "../components";

const Card = (props) => {
  const {
    _id,
    groupName,
    groupThumbnailUrl,
    isMerging,
    isSelected,
    handleSelect,
    handleUpdateTags,
  } = props;

  const navigate = useNavigate();

  function handleClick() {
    if (!isMerging) {
      navigate(
        APP_ROUTES.GROUPS_TAGS.TAGGING.GROUP_CONTENT?.replace(
          `:groupEntity`,
          "image"
        )?.replace(`:groupId`, _id),
        {
          option: { state: { groupName: groupName } },
        }
      );
    } else {
      handleSelect(_id);
    }
  }
  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        padding: "1.5em",
        background: isSelected ? "#7167FB30" : "#F2F3F5",
        borderRadius: "8px",
        cursor: "pointer",
        height: "227px",
        width: "227px",
        gap: "8px",
        position: "relative",
      }}
      onClick={() => {
        handleClick();
      }}
    >
      {isMerging && (
        <Avatar
          sx={{
            backgroundColor: "#fff",
            height: "24px",
            width: "24px",
            position: "absolute",
            top: "16px",
            right: "16px",
            border: "1px solid #3f51b5",
            zIndex: 1,
          }}
          onClick={handleClick}
        >
          {isSelected && <CheckCircleIcon color="primary" />}
        </Avatar>
      )}
      <Stack
        className={styles.thumbnail}
        style={{
          height: "160px",
          width: "200px",
          minHeight: "160px",
          minWidth: "200px",
        }}
      >
        <img
          src={storageUrlToCdn(groupThumbnailUrl)}
          alt="thumbnail"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "4px",
            objectFit: "cover",
          }}
        />
      </Stack>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          className="truncate"
          sx={{
            textTransform: "capitalize",
            width: "50%",
            paddingRight: "0.5rem",
            fontSize: "0.8rem",
          }}
          title={groupName}
        >
          {groupName?.slice(0, 25) + (groupName?.length > 25 ? "..." : "")}
        </Typography>
        <Button
          variant="text"
          sx={{
            textTransform: "none",
            fontSize: "0.8rem",
            width: "50%",
            "&:hover": {
              fontWeight: "700",
              background: "none",
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleUpdateTags({
              groupId: _id,
              groupName: groupName,
              imageUrl: groupThumbnailUrl,
            });
          }}
        >
          Update Tags
        </Button>
      </Stack>
    </Stack>
  );
};

export default function ImageTaggingScreen() {
  const {
    setFilterTags,
    sort,
    setSort,
    isMerging,
    toggleMerging,
    groups,
    handleSelect,
    handleMerge,
    handleUpdateTags,
    initGroups,
    mergeModalPrimaryAction,
    totalDocs,
  } = useImageTagging();

  const { pathname } = useLocation();
  const { open, type } = useSelector((state) => state?.modal);

  return (
    <PrivateContainer
      activePage={pathname}
      bodyStyles={{
        justifyContent: "flex-start",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <BackButton
          to={APP_ROUTES.GROUPS_TAGS.default}
          btnText="Image Tagging"
          from={APP_ROUTES.GROUPS_TAGS.TAGGING}
        />
        <div className="flex gap-2">
          <OrganizationTagSelector onChange={setFilterTags} />
          <Select
            onValueChange={(value) => {
              setSort(value);
              initGroups(value);
            }}
            value={sort}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Sort By" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={JSON.stringify({ theta: 1, distance: 1 })}>
                Default
              </SelectItem>
              <SelectItem value={JSON.stringify({ createdAt: 1 })}>
                Created At (Oldest first)
              </SelectItem>
              <SelectItem value={JSON.stringify({ createdAt: -1 })}>
                Created At (Newest first)
              </SelectItem>
              <SelectItem value={JSON.stringify({ updatedAt: 1 })}>
                Updated At (Oldest first)
              </SelectItem>
              <SelectItem value={JSON.stringify({ updatedAt: -1 })}>
                Updated At (Newest first)
              </SelectItem>
              <SelectItem value={JSON.stringify({ groupEntityMaxLength: 1 })}>
                Total Group Entities (Low to High)
              </SelectItem>
              <SelectItem value={JSON.stringify({ groupEntityMaxLength: -1 })}>
                Total Group Entities (High to Low)
              </SelectItem>
            </SelectContent>
          </Select>
          <ButtonShadcn
            variant="secondary"
            onClick={toggleMerging}
            sx={{ textTransform: "none", fontSize: "1rem" }}
          >
            {!isMerging ? "Merge Groups" : "Cancel Merge"}
          </ButtonShadcn>
          {isMerging && (
            <ButtonShadcn
              onClick={handleMerge}
              sx={{
                textTransform: "none",
                marginLeft: "1rem",
                fontSize: "1rem",
              }}
            >
              Update Merging
            </ButtonShadcn>
          )}
        </div>
      </Stack>
      <div className={styles.image_tab__container}>
        <div className={styles.header}>
          <div className={styles.left}>
            <span className="text-sm">Total Groups: {totalDocs}</span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "rgba(28, 30, 32, 0.5)",
                cursor: "pointer",
              }}
              onClick={initGroups}
            >
              Refresh <SyncIcon sx={{ padding: "0.25rem" }} />
            </span>
          </div>
        </div>
        <ContentContainer
          className={styles.content}
          containerStyles={{
            maxHeight: "calc(100vh - 180px)",
            gap: "1.5rem",
            height: "auto",

            // display: "grid"
            // gridTemplateColumns: "repeat(auto-fill, minmax(230px, 1fr))",
          }}
        >
          {groups.map((group) => (
            <Card
              isMerging={isMerging}
              handleSelect={handleSelect}
              key={group?.groupId}
              handleUpdateTags={handleUpdateTags}
              {...group}
            />
          ))}
        </ContentContainer>
        {/* <BasicActionsModal
          modalType={MODALTYPES.BASIC_ACTION.MERGE_GROUPS}
          primaryAction={mergeModalPrimaryAction}
        /> */}

        {open && type === MODALTYPES.IMAGE_TAGGING_MODAL && (
          <ImageTaggingModal postOnboarding={true} />
        )}

        {open && type === MODALTYPES.MERGE_MULTIPLE_GROUPS_MODAL && (
          <MergeMultipleGroupsModal primaryAction={mergeModalPrimaryAction} />
        )}
      </div>
    </PrivateContainer>
  );
}
